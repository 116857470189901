import React, { useEffect, useRef } from "react";
import { register } from "swiper/element/bundle";

/**
 * Component to render a Swiper container.
 * @param {Object} props - The props object containing Swiper-related data.
 * @param {ReactNode} props.children - The child elements to be rendered inside the Swiper container.
 * @param {...any} rest - Additional props to be passed to the Swiper container.
 * @returns {React.Element} - Returns JSX for the Swiper component.
 */
export const Swiper = (props) => {
  const swiperRef = useRef(null);
  const { children, ...rest } = props;

  useEffect(() => {
    // Register Swiper web component
    register();

    // pass component props to parameters
    const params = {
      ...rest,
    };

    // Assign it to swiper element
    Object.assign(swiperRef.current, params);

    // initialize swiper
    swiperRef.current.initialize();
  }, [rest]);

  return (
    <swiper-container
      style={{
        "--swiper-pagination-color": "#85355f",
      }}
      class="swiper-container"
      init="false"
      ref={swiperRef}
    >
      {children}
    </swiper-container>
  );
};

/**
 * Component to render a Swiper slide.
 * @param {Object} props - The props object containing Swiper slide-related data.
 * @param {ReactNode} props.children - The child elements to be rendered inside the Swiper slide.
 * @param {...any} rest - Additional props to be passed to the Swiper slide.
 * @returns {React.Element} - Returns JSX for the SwiperSlide component.
 */
export const SwiperSlide = (props) => {
  const { children, ...rest } = props;
  return (
    <swiper-slide class={`swiper-slide ${props.gridClass}`} {...rest}>
      {children}
    </swiper-slide>
  );
};
