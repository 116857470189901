import React from "react";
import { Link } from "react-router-dom";
import { Container, Image } from "react-bootstrap";
import { useErrorBoundary } from "react-error-boundary";

import { SupportTeamEmail } from "../../constants";
import internalServerError from "../../resources/images/error.png";

/**
 * Component for displaying an internal server error message.
 * @param error - The error object representing the internal server error.
 * @returns {React.Element} - Returns JSX for displaying the internal server error message.
 */
const InternalServerError = ({ error }) => {
  // Reset the error boundary state to its initial state.
  const { resetBoundary } = useErrorBoundary();

  return (
    <main className="d-flex align-items-center justify-content-center min-vh-100 flex-column bg-white">
      <Container className="text-center internal-server-error">
        <h3 className="text-secondary fw-semibold mb-3">
          Oops, Something went wrong.
        </h3>
        {!error && (
          <p className="mb-2">
            We're very sorry, however something went wrong when trying to load
            <br className="d-none d-md-block" /> this page. Please try again or,{" "}
            <Link
              to={`mailto:${SupportTeamEmail}`}
              title="support"
              className="text-decoration-none link-primary"
            >
              contact support.
            </Link>
          </p>
        )}
        {error && <p className="mb-2">{error?.message}</p>}
        <div>
          <Link
            to="/login"
            title="Login"
            className="btn btn-secondary btn-md fw-semibold mt-3"
            onClick={resetBoundary}
          >
            RETURN HOME
          </Link>
        </div>
        <Image src={internalServerError} alt="Forbidden" fluid />
      </Container>
    </main>
  );
};

export default InternalServerError;
