import React, { useState, useReducer, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  Spinner,
  Alert,
  FloatingLabel,
  Image,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  isPossiblePhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";

import { transformProfilePicture } from "../../components/transformFileURL";

import { refreshToken } from "../../services/accountService";
import { postData, getData } from "../../services/apiService";
import { notify, initialState } from "../../store/notification";
import { useAuthContext } from "../../context/authProvider";
import { decodeToken } from "../../utils/session";

import ImageCrop from "../../components/imageCrop";

/**
 * Component for updating the profile of the currently logged-in user.
 * @returns {JSX.Element} - Returns JSX for updating user profile.
 */
const UpdateProfile = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    trigger,
    formState: { errors },
    watch,
  } = useForm();
  // Watch for changes in the phoneNumber field
  const phoneNumber = watch("phoneNumber");
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object and `saveToken function from the 'useAuthContext()' hook
  const { userSession, saveToken } = useAuthContext();
  // Decode the user session token to extract user information
  const session = decodeToken(userSession);
  const [isLoading, setIsLoading] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [maxLength, setMaxLength] = useState(20);

  // File Upload
  const fileInputRef = useRef(null); // Create a ref for the file input
  const [selectedFile, setSelectedFile] = useState({});
  const [showCropModal, setShowCropModal] = useState(false);
  const [cropInfo, setCropInfo] = useState({});
  const [fileToDelete, setFileToDelete] = useState(null);

  // Functions for controlling the Crop Modal
  const closeCropModal = () => setShowCropModal(false);
  const openCropModal = () => setShowCropModal(true);

  useEffect(() => {
    /**
     * Prevent the user from adding in more numbers to this field if they exceed the limit of required digits
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    if (phoneNumber) {
      const formattedPhone = formatPhoneNumberIntl(phoneNumber);

      // Update maxLength if the cleaned phone number is valid
      if (isPossiblePhoneNumber(formattedPhone))
        setMaxLength(formattedPhone.length);
    }
  }, [phoneNumber, maxLength]);

  /**
   * Function to handle the change event of the file input element.
   * @param {object} event - The change event object.
   */
  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];
      const maxSize = 1024 * 1024; // 1 MB in bytes
      if (!allowedTypes.includes(file.type)) {
        showNotification(
          "danger",
          "Image format is incompatible. Only JPEG and PNG formats are supported.",
          5000
        );
        return;
      }
      if (file.size > maxSize) {
        showNotification(
          "danger",
          "Your file is too large, maximum allowed size is: 1 MB",
          5000
        );
        return;
      }
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedFile({
            name: event.target.files?.[0].name,
            file: event.target.files[0],
            src: reader.result,
            url: URL.createObjectURL(event.target.files[0]),
          });
          openCropModal();
        };
        reader.readAsDataURL(file);
      }
    }
  };

  /**
   * Function to update the state with cropped image information and removes the existing profile picture.
   * @param {string} croppedImage - The cropped image data.
   * @param {object} croppedAreaPixels - The position of the cropped area in pixels.
   * @param {number} rotation - The rotation angle of the cropped image.
   */
  const updateAvatar = (croppedImage, croppedAreaPixels, rotation) => {
    setCropInfo({
      image: croppedImage,
      position: croppedAreaPixels,
      rotation: rotation,
    });
    removeProfilePicture();
  };

  /**
   * Function to reset the file input and clears the state related to the selected file
   * and profile picture.
   */
  const resetFileInput = () => {
    setCropInfo({});
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset the file input value
    }
    setSelectedFile({});
  };

  /**
   * Function is responsible for preparing the removal of the profile picture.
   * It sets the fileToDelete state to the current profile picture URL
   * so that it can be deleted when the form is submitted or updated.
   */
  const removeProfilePicture = () => {
    setFileToDelete(profilePicture);
    setProfilePicture(null);
    setValue("profilePicture", "");
  };

  useEffect(() => {
    /**
     * Fetches the user's profile data by their ID and populate the fields.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getById = async () => {
      try {
        const response = await getData(
          `/api/account/getMyProfile/${session.nameid}`,
          null,
          userSession
        );
        //Calling the reset function with an object (e.g. reset(user)) will update the defaultValues of the
        //form with the values from the object, so subsequent calls to reset() (without params) will use the new default values.
        setProfilePicture(response.profilePicture);
        reset(response);
      } catch (error) {
        // Show a notification if an error occurs
        showNotification("danger", error, 5000);
      }
    };
    getById();
  }, [userSession, session.nameid, reset]);

  /**
   * Function to upload a cropped image.
   * @returns {string|null} Returns the URL of the uploaded file if successful, or null if an error occurs.
   */
  const upload = async () => {
    const formData = new FormData();
    formData.append("File", selectedFile.file);
    formData.append("cropX", parseInt(cropInfo.position.x));
    formData.append("cropY", parseInt(cropInfo.position.y));
    formData.append("cropWidth", parseInt(cropInfo.position.width));
    formData.append("cropHeight", parseInt(cropInfo.position.height));
    formData.append("cropRotation", parseInt(cropInfo.rotation));
    formData.append("type", "Profile");
    if (profilePicture != null)
      formData.append("profilePicture", profilePicture);
    try {
      const response = await postData("/api/image/crop", formData, userSession);
      return response;
    } catch (error) {
      showNotification("danger", error, 5000);
      setIsLoading(false);
      resetFileInput();
      return null;
    }
  };

  /**
   * Function to update the user profile information.
   * @param {Object} data - The user profile data to be updated.
   */
  const handleUpdateProfile = async (data) => {
    setIsLoading(true);
    if (selectedFile.file) {
      const response = await upload();
      if (response) {
        data.profilePicture = response;
        setProfilePicture(response);
      } else return;
    }

    // Set the file to delete if the user wants to remove the current profile picture
    data.fileToDelete = fileToDelete;
    data.phoneNumber = formatPhoneNumberIntl(data.phoneNumber);
    try {
      const response = await postData(
        `/api/account/updateProfile`,
        data,
        userSession
      );
      setIsLoading(false);
      reset(data);
      resetFileInput();
      showNotification("success", response, 5000);

      // Refresh the user token after profile update
      const tokenResponse = await refreshToken(null, userSession);
      saveToken(tokenResponse.token);
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setIsLoading(false);
    }
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };
  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-semibold mb-0">My Profile</h6>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <Form onSubmit={handleSubmit(handleUpdateProfile)}>
              <Form.Control
                type="hidden"
                defaultValue={session.nameid}
                {...register("userId")}
              />
              <Form.Control type="hidden" {...register("profilePicture")} />
              <Row>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  className="mb-4"
                >
                  <InputGroup>
                    <FloatingLabel controlId="txtFirstName" label="First Name">
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        {...register("firstName", {
                          required: "First name is required",
                          pattern: {
                            value:
                              /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                            message:
                              "First name shouldn't contain HTML or Script tags",
                          },
                          maxLength: {
                            value: 50,
                            message: "First name cannot exceed 50 characters",
                          },
                        })}
                      />
                    </FloatingLabel>
                    <InputGroup.Text>
                      <i className="flaticon-profile fs-4"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.firstName && (
                    <Form.Text className="text-danger">
                      {errors.firstName.message}
                    </Form.Text>
                  )}
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  className="mb-4"
                >
                  <InputGroup>
                    <FloatingLabel controlId="txtLastName" label="Last Name">
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        {...register("lastName", {
                          required: "Last name is required",
                          pattern: {
                            value:
                              /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                            message:
                              "First name shouldn't contain HTML or Script tags",
                          },
                          maxLength: {
                            value: 50,
                            message: "Last name cannot exceed 50 characters",
                          },
                        })}
                      />
                    </FloatingLabel>
                    <InputGroup.Text>
                      <i className="flaticon-profile fs-4"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.lastName && (
                    <Form.Text className="text-danger">
                      {errors.lastName.message}
                    </Form.Text>
                  )}
                </Col>

                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  className="mb-4"
                >
                  <InputGroup>
                    <FloatingLabel
                      label="Phone Number"
                      controlId="txtPhoneNumber"
                      htmlFor="txtPhoneNumber"
                    >
                      <PhoneInputWithCountry
                        name="phoneNumber"
                        id="txtPhoneNumber"
                        defaultCountry="US"
                        international={true}
                        withCountryCallingCode={true}
                        control={control}
                        onChange={(val) => {
                          trigger("phoneNumber");
                        }}
                        maxLength={maxLength}
                        rules={{
                          required: "Phone number is required",
                          validate: (value) => {
                            if (!isPossiblePhoneNumber(value)) {
                              setMaxLength(20);
                              return "Invalid phone number";
                            }
                            return true;
                          },
                        }}
                        className="form-control d-flex"
                      />
                    </FloatingLabel>
                    <InputGroup.Text>
                      <i className="flaticon-smartphone fs-3"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  <Form.Text muted className="d-block mt-1">
                    Only Visible to Benevolent Staff
                  </Form.Text>
                  {errors.phoneNumber && (
                    <Form.Text className="text-danger">
                      {errors.phoneNumber.message}
                    </Form.Text>
                  )}
                </Col>
                <Col xs={12} md={6} className="mb-4">
                  <InputGroup>
                    <Form.Control
                      type="file"
                      id="fileInput"
                      onChange={handleFileChange}
                      className="d-none"
                      ref={fileInputRef}
                      accept="image/jpeg, image/png"
                    />
                    <Form.Control
                      type="text"
                      placeholder="No file chosen"
                      defaultValue={selectedFile.name}
                      readOnly
                      className="rounded-start"
                    />
                    <InputGroup.Text className="p-0 border-0">
                      <Button
                        as="label"
                        variant="light"
                        htmlFor="fileInput"
                        className="btn-md rounded-0"
                      >
                        Browse
                      </Button>
                      <Button
                        variant="dark"
                        className="btn-md rounded-start-0"
                        onClick={() => {
                          resetFileInput();
                          removeProfilePicture();
                        }}
                      >
                        Remove
                      </Button>
                    </InputGroup.Text>
                  </InputGroup>
                  <Form.Text muted className="d-block mt-1">
                    Recommended size: 1024x576. Max file size: 1MB. Supported
                    file formats: JPG and PNG
                  </Form.Text>
                </Col>
                <Col xs={12} md={6} className="mb-4">
                  <Form.Label>Preview: </Form.Label>
                  <Image
                    fluid
                    src={
                      !cropInfo.image
                        ? transformProfilePicture(profilePicture)
                        : cropInfo.image
                    }
                    alt="Profile Picture"
                    className="ms-3 align-middle avatar"
                    roundedCircle
                    thumbnail
                  />
                </Col>
                <Col xs={12} className="mb-3">
                  <FloatingLabel controlId="txtBiography" label="About you">
                    <Form.Control
                      as="textarea"
                      className="scroll"
                      placeholder="About you"
                      {...register("biography", {
                        pattern: {
                          value:
                            /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>)[\s\S])*$/i,
                          message:
                            "Biography shouldn't contain HTML or Script tags",
                        },
                        maxLength: {
                          value: 2500,
                          message: "Biography cannot exceed 2500 characters",
                        },
                      })}
                    />
                  </FloatingLabel>
                  {errors.biography && (
                    <Form.Text className="text-danger">
                      {errors.biography.message}
                    </Form.Text>
                  )}
                </Col>
                <Col xs={12} className="mb-2">
                  <Form.Check
                    type="checkbox"
                    label="Always take me to the dashboard after signing in"
                    className="fw-light text-dark-emphasis d-inline-block"
                    {...register("autoRedirectToDashboard", {
                      disabled: false,
                    })}
                  />
                </Col>
                <Col xs={12} className="mb-4">
                  <Form.Check
                    type="checkbox"
                    label="Email me notifications and updates related to Benevolent operations"
                    className="fw-light text-dark-emphasis d-inline-block"
                    {...register("isNotificationEnabled", {
                      disabled: false,
                    })}
                  />
                </Col>
                <Col xs={12}>
                  <Button
                    variant="primary"
                    disabled={isLoading}
                    type="submit"
                    className="btn-md"
                  >
                    {isLoading ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "Save"
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    type="button"
                    className="ms-3 btn-md"
                    onClick={() => {
                      reset();
                      resetFileInput();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <ImageCrop
        showCropModal={showCropModal}
        closeCropModal={closeCropModal}
        selectedFile={selectedFile}
        updateAvatar={updateAvatar}
        resetFileInput={resetFileInput}
      />
    </main>
  );
};

export default UpdateProfile;
