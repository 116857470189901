import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Image } from "react-bootstrap";

import { Host, SupportTeamEmail } from "../../../constants";
import Success from "../../../resources/images/success.png";

/**
 * Component for rendering the congratulatory message after need submission.
 * @param {object} props - Component props.
 * @param {string} props.needStatus - Status of the need.
 * @param {string} props.referenceId - Reference ID of the need.
 * @param {number} props.needId - ID of the need.
 * @param {array} props.roles - Array containing user roles.
 * @returns {React.Element} - Returns JSX for congratulation view
 */
const Congratulation = (props) => {
  const { needStatus, referenceId, needId } = props;

  return (
    <main className="text-center mt-5 mb-3">
      <Image src={Success} alt="Success" fluid></Image>
      <h3 className="title mt-3 mb-2">Thank You!</h3>
      {(props.roles.includes("SuperAdmin") ||
        props.roles.includes("Staff")) && (
        <Fragment>
          {needStatus === "Draft" && (
            <Fragment>
              <p className="mb-2 text-dark-emphasis fw-light">
                Need has been saved successfully.{" "}
                <Link
                  title="Manage Needs"
                  to="/needs"
                  className="text-decoration-none link-secondary fw-normal"
                >
                  Click here
                </Link>{" "}
                <br />
                to administer the need.
              </p>
            </Fragment>
          )}
          {needStatus === "Awaiting Approval" && (
            <Fragment>
              <p className="mb-2 text-dark-emphasis fw-light">
                Need has been successfully saved and is now awaiting <br />
                approval.{" "}
                <Link
                  title="Manage Needs"
                  to="/needs"
                  className="text-decoration-none link-secondary fw-normal"
                >
                  Click here
                </Link>{" "}
                to administer the need.
              </p>
            </Fragment>
          )}
          {needStatus === "Published" && (
            <Fragment>
              <p className="mb-2 text-dark-emphasis fw-light">
                Your need has been published successfully.
              </p>
              <p className="mb-2 text-dark-emphasis fw-light">
                You can now view and share the need using the following link:
              </p>
              <Link
                to={`${Host}/profile/need/${referenceId}/${needId}`}
                className="link-primary"
              >
                {Host}/profile/need/{referenceId}/{needId}
              </Link>
            </Fragment>
          )}
        </Fragment>
      )}
      {needStatus === "Awaiting Approval" &&
        props.roles.includes("Validator") && (
          <Row className="justify-content-center">
            <Col xs={11} sm={11} md={8} lg={8} xl={7} xxl={5}>
              <p className="mb-2 text-dark-emphasis fw-light">
                We'll send email notifications to you and the recipient once
                your need is posted. Typically, we complete the review within 48
                hours.
              </p>
              <p className="text-dark-emphasis fw-light">
                If you have any questions, please don't hesitate to{" "}
                <Link
                  title="Support"
                  to={`mailto:${SupportTeamEmail}`}
                  className="text-decoration-none link-secondary"
                >
                  contact us
                </Link>
                .
              </p>
              <div className="mt-4">
                <Link
                  title="Manage Needs"
                  to="/needs"
                  className="btn btn-primary btn-md"
                >
                  Manage Needs
                </Link>
              </div>
            </Col>
          </Row>
        )}
    </main>
  );
};

export default Congratulation;
