import React, { useState } from "react";
import { Card, Button, Image, Spinner } from "react-bootstrap";

import { logout, refreshToken } from "../services/accountService";
import tokenExpiration from "../resources/images/token-expiration.png";

/**
 * Component to display confirmation popup to handle token expiration and
 * provides options to stay logged in or logout.
 * @param {Object} props - Component props.
 * @param {Function} props.onClose - Function to handle closing the popup.
 * @param {Function} props.onSaveToken - Function to save the refreshed token.
 * @param {Function} props.onClearToken - Function to clear the token upon logout.
 * @param {Object} props.session - User session information.
 * @returns {React.Element} - Returns JSX for the token expiration popup.
 */
const TokenExpirationPopup = ({
  onClose,
  onSaveToken,
  onClearToken,
  session,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Function to send a request via backend API to refresh the JWT token and
   * updates the token in the client's browser.
   */
  const handleRefreshToken = async () => {
    setIsLoading(true);
    try {
      const response = await refreshToken(null, session);
      setIsLoading(false);
      // Persist the JWT token in the client's browser using local storage.
      onSaveToken(response.token);
    } catch (error) {
      setIsLoading(false);
      await logout();
      await onClearToken();
    }
  };

  return (
    <Card className="border-0 shadow rounded-3 token-expiration">
      <Card.Body className="p-4 text-center">
        <Image src={tokenExpiration} alt="Token Expiration" fluid />
        <h5 className="mt-4 module-title fw-semibold">Session Timeout!</h5>
        <div className="mt-2 fw-light">
          To continue, please click the button
          <br />
          below to stay signed in.
        </div>
      </Card.Body>
      <Card.Footer className="bg-transparent py-4 text-end">
        <Button
          title="Logout"
          variant="link"
          className="link-danger fw-semibold"
          onClick={async () => {
            try {
              await logout();
              await onClearToken();
            } catch (error) {}
            onClose();
          }}
        >
          Logout
        </Button>
        <Button
          variant="danger"
          className="ms-4 btn-md"
          onClick={async () => {
            await handleRefreshToken();
            onClose();
          }}
        >
          {isLoading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            " Stay Logged In"
          )}
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default TokenExpirationPopup;
