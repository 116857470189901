import { Avatars } from "./avatarImporter";

import { APIURL } from "../constants";
import avatar from "../resources/images/avatar.png";

/**
 * Transform profile picture data to full URLs or avatar images.
 * @param {Object|Array} data - Profile picture or array of profile picture
 * @param {Object} options - Transformation options
 * @param {boolean} options.isThumbnail - Indicates whether to fetch thumbnail version (default: true)
 * @param {boolean} options.isFallbackAvatar - Indicates whether to use fallback avatar image if profile picture is not available (default: true)
 * @returns {Object|Array} Transformed profile picture data
 */
export const transformProfilePicture = (
  data,
  { isThumbnail = true, isFallbackAvatar = true } = {}
) => {
  if (Array.isArray(data)) {
    // If data is an array
    return data.map((item) =>
      item.profilePicture
        ? /\/Uploads/.test(item.profilePicture)
          ? {
              ...item,
              profilePicture: `${APIURL}${item.profilePicture}`, // Transform to full URL
            }
          : {
              ...item,
              profilePicture: Avatars.find(
                (x) => x.name === item.profilePicture
              )?.image, // Use corresponding avatar image
            }
        : {
            ...item,
            profilePicture: isFallbackAvatar ? avatar : null, // Use fallback avatar if profile picture is not available
          }
    );
  } else {
    // If data is not an array
    return data
      ? /\/Uploads/.test(data)
        ? isThumbnail // Transform to full URL
          ? `${APIURL}${data}`
          : `${APIURL}${data.replace("130x130", "400x400")}`
        : Avatars.find((x) => x.name === data)?.image // Use corresponding avatar image
      : isFallbackAvatar
      ? avatar // Return fallback avatar
      : null;
  }
};

/**
 * Transform attachment data to full URLs.
 * @param {string} data - Attachment data
 * @returns {string} Transformed attachment URL
 */
export const transformAttachments = (data) => `${APIURL}${data}`;
