import React, { Fragment, useState, useEffect, useReducer, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
  Card,
  Spinner,
  Alert,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import {
  isPossiblePhoneNumber,
  isSupportedCountry,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/react-hook-form-input";

import { postData } from "../../../services/apiService";
import { notify, initialState } from "../../../store/notification";


// By defining defaultGender and defaultEthnicityoutside the component, they remain constant and do not cause re-renders.
// This approach ensures that our defaultGender and defaultEthnicity are static and the useEffect dependencies are correctly set, 
// avoiding any unnecessary warnings or re-renders.
const defaultGender = ["Male", "Female"];
const defaultEthnicity = [
  "Asian",
  "Black",
  "Latino",
  "Native American/Alaskan",
  "Native Hawaiian/Pacific Islander",
  "White",
];

/**
 * Component for rendering recipient information form.
 * @param {object} props - Component props.
 * @param {object} props.userSession - User session object.
 * @param {object} props.need - Object containing need information.
 * @param {function} props.processResult - Function to process form result.
 * @param {function} props.handleActiveTab - Function to handle active tab.
 * @returns {React.Element} - Returns JSX for recipient information form
 */
const RecipientInfo = (props) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      "address.country": "United States",
    },
  });
  // Watch for changes in the phoneNumber field
  const phoneNumber = watch("address.phoneNumber");

  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  const { userSession } = props;
  const [selectedCountry, setSelectedCountry] = useState("United States");
  const [loadingStates, setLoadingStates] = useState({});
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedEthnicity, setSelectedEthnicity] = useState([]);
  const [selectedCircumstances, setSelectedCircumstances] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("US");
  const [maxLength, setMaxLength] = useState(20);

  /**
   * Function to format the date obtained from props and sets it in the form field.
   */
  const formattedDate = useCallback(() => {
    const date = new Date(props.need?.dob);

    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    setValue("dob", `${year}-${month}-${day}`);
  }, [props.need?.dob, setValue]);

  useEffect(() => {
    /**
     * Prevent the user from adding in more numbers to this field if they exceed the limit of required digits
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    if (phoneNumber) {
      const formattedPhone = formatPhoneNumberIntl(phoneNumber);

      // Update maxLength if the cleaned phone number is valid
      if (isPossiblePhoneNumber(formattedPhone))
        setMaxLength(formattedPhone.length);
    }
  }, [phoneNumber, maxLength]);

  useEffect(() => {
    /**
     * Function to filter fields from the need object.
     * @param  {...string} keys - Keys to be included in the filtered object.
     * @returns {object} - Filtered object containing specified keys.
     */
    const filterFields = (...keys) => {
      const targetObject = Object.assign({});
      Object.keys(props.need).forEach((key) => {
        if (keys.includes(key)) {
          targetObject[key] = props.need[key];
        }
      });
      return targetObject;
    };


    // Reset form fields
    reset(
      filterFields(
        "needId",
        "needStatus",
        "firstName",
        "lastName",
        "displayName",
        "emailAddress",
        "gender",
        "ethnicity",
        "dob",
        "adultsInHousehold",
        "childrenInHousehold",
        "employmentStatus",
        "education",
        "householdAnnualIncome",
        "personalAnnualIncome",
        "specialCircumstances",
        "address"
      )
    );
    formattedDate();

    if (props.need?.ethnicity) {
      const chosenEthnicity = props.need?.ethnicity.split(",");
      const ethnicity = defaultEthnicity.some((item) =>
        chosenEthnicity.includes(item)
      )
        ? chosenEthnicity
        : ["Other"];

      if (ethnicity.includes("Other"))
        setValue("ethnicityInfo", chosenEthnicity[0]);
      setSelectedEthnicity(ethnicity);
    } else setSelectedEthnicity([]);

    setSelectedCircumstances(
      props.need?.specialCircumstances
        ? props.need?.specialCircumstances?.split(",")
        : []
    );

    setSelectedCountry(
      props.need?.address?.country
        ? props.need?.address?.country
        : "United States"
    );

    const chosenGender = props.need?.gender;
    if (chosenGender) {
      const gender = defaultGender.includes(chosenGender)
        ? chosenGender
        : "Other";
      if (gender === "Other") setValue("genderInfo", chosenGender);
      setSelectedGender(gender);
    } else setSelectedGender("");
  }, [reset, props.need, setValue, formattedDate]);


  /**
   * Function to handle the change event of the gender radio button.
   * @param {Object} event - The event object containing information about the radio button change.
   */
  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  /**
   * Function to handle the change event of the ethnicity checkboxes.
   * @param {Object} event - The event object containing information about the checkbox change.
   */
  const handleEthnicityChange = (event) => {
    const { value, checked } = event.target;
    // Clear selectedEthnicity array if "Other" is checked
    if (value === "Other") setSelectedEthnicity([]);
    // Update the selectedEthnicity array based on checkbox changes
    if (checked) {
      setSelectedEthnicity((prevSelectedEthnicity) => [
        ...prevSelectedEthnicity.filter((item) => item !== "Other"),
        value,
      ]);
    } else {
      setSelectedEthnicity((prevSelectedEthnicity) =>
        prevSelectedEthnicity.filter((item) => item !== value)
      );
    }
  };

  /**
   * Function to handle the change event of the circumstances checkboxes.
   * @param {Object} event - The event object containing information about the checkbox change.
   */
  const handleCircumstancesChange = (event) => {
    const { value, checked } = event.target;
    // Update the selectedCircumstances array based on checkbox changes
    if (checked) {
      setSelectedCircumstances((prevSelectedCircumstance) => [
        ...prevSelectedCircumstance,
        value,
      ]);
    } else {
      setSelectedCircumstances((prevSelectedCircumstance) =>
        prevSelectedCircumstance.filter((item) => item !== value)
      );
    }
  };

  /**
   * Function to update the recipient information using the backend API
   * @param {Object} data - The form data.
   * @param {Object} e - The event object containing information about the form submission.
   */
  const onSubmit = async (data, e) => {
    data.specialCircumstances = selectedCircumstances.join(",");
    data.gender = data.gender === "Other" ? data.genderInfo : data.gender;
    data.ethnicity = selectedEthnicity.includes("Other")
      ? data.ethnicityInfo
      : selectedEthnicity.join(",");
    data.address.phoneNumber = formatPhoneNumberIntl(data.address.phoneNumber);

    // Extract the button type from the submit event
    const buttonType = e.nativeEvent.submitter.dataset.buttonType;
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [buttonType]: true,
    }));
    try {
      const response = await postData(
        "/api/need/upsert-recipient-info",
        data,
        userSession
      );
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [buttonType]: false,
      }));

      // Update the need data and submission status
      setValue("needId", response.needId);
      data.needId = response.needId;
      props.processResult({
        need: data,
        hasBeenSubmitted: false,
      });

      // Determine the next active tab based on button type
      if (buttonType === "Draft")
        showNotification("success", response.message, 5000);
      else props.handleActiveTab("validation-statement");
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [buttonType]: false,
      }));
    }
  };

  useEffect(() => {
    /**
     * Function to fetch the country code
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const matchedCountry = CountryRegionData.find(
      (country) => country[0] === selectedCountry
    );

    setSelectedCountryCode(matchedCountry ? matchedCountry[1] : "");
  }, [selectedCountry]);

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Control type="hidden" {...register("needId")} />
        <Form.Control type="hidden" {...register("needStatus")} />
        <Row className="align-items-center my-4">
          <Col xs="auto">
            <h6>
              Fundraising Profile Info{" "}
              <small className="text-light-emphasis fw-light">
                (Visible to the public)
              </small>
            </h6>
          </Col>
          <Col>
            <hr className="m-0" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} lg={4} className="mb-4">
            <InputGroup>
              <FloatingLabel
                label="Display Name"
                controlId="txtDisplayName"
                htmlFor="txtDisplayName"
              >
                <Form.Control
                  type="text"
                  placeholder="Display Name"
                  {...register("displayName", {
                    required: "Display Name is required",
                    pattern: {
                      value:
                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                      message:
                        "Display Name shouldn't contain HTML or Script tags",
                    },
                    maxLength: {
                      value: 50,
                      message: "Display Name cannot exceed 50 characters",
                    },
                  })}
                />
              </FloatingLabel>
              <InputGroup.Text>
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={
                    <Popover className="border-0">
                      <Popover.Body className="shadow rounded text-dark-emphasis fw-light">
                        For added privacy, we recommend using a pseudonym that
                        doesn't reveal the recipient's real identity. Consider
                        names like Roger or Jetaun, rather than Superstar or
                        Kittycat12.
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <i className="flaticon-question-mark fs-5"></i>
                </OverlayTrigger>
              </InputGroup.Text>
              <InputGroup.Text>
                <i className="flaticon-profile fs-4"></i>
              </InputGroup.Text>
            </InputGroup>
            {errors.displayName && (
              <Form.Text className="text-danger">
                {errors.displayName.message}
              </Form.Text>
            )}
          </Col>
          <Col xs={12} sm={6} lg={4} className="mb-4">
            <FloatingLabel
              label="Country"
              controlId="ddCountry"
              htmlFor="ddCountry"
            >
              <Controller
                control={control}
                name="address.country"
                id="ddCountry"
                rules={{ required: "Country is required" }}
                render={({ field: { onChange, value } }) => (
                  <CountryDropdown
                    value={value || ""}
                    classes="form-select"
                    onChange={(val) => {
                      onChange(val);
                      setSelectedCountry(val);
                      setValue("address.phoneNumber", "");
                    }}
                  />
                )}
              />
            </FloatingLabel>
            {errors.address && errors.address.country && (
              <Form.Text className="text-danger">
                {errors.address.country.message}
              </Form.Text>
            )}
          </Col>
          <Col xs={12} sm={6} lg={4} className="mb-4">
            <FloatingLabel label="State" controlId="ddState" htmlFor="ddState">
              <Controller
                control={control}
                name="address.state"
                id="ddState"
                render={({ field: { onChange, value } }) => (
                  <RegionDropdown
                    country={selectedCountry}
                    value={value || ""}
                    classes="form-select"
                    onChange={(val) => onChange(val)}
                  />
                )}
              />
            </FloatingLabel>
            {errors.address && errors.address.state && (
              <Form.Text className="text-danger">
                {errors.address.state.message}
              </Form.Text>
            )}
          </Col>
          <Col xs={12} sm={6} lg={4} className="mb-4">
            <InputGroup>
              <FloatingLabel label="City" controlId="txtCity" htmlFor="txtCity">
                <Form.Control
                  type="text"
                  placeholder="City"
                  {...register("address.city", {
                    required: "City is required",
                    pattern: {
                      value:
                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                      message: "City shouldn't contain HTML or Script tags",
                    },
                    maxLength: {
                      value: 50,
                      message: "City cannot excezipced 50 characters",
                    },
                  })}
                />
              </FloatingLabel>
              <InputGroup.Text>
                <i className="flaticon-location fs-4"></i>
              </InputGroup.Text>
            </InputGroup>
            {errors.address && errors.address.city && (
              <Form.Text className="text-danger">
                {errors.address.city.message}
              </Form.Text>
            )}
          </Col>
        </Row>
        <Row className="align-items-center mb-4">
          <Col xs="auto">
            <h6>
              Contact Info{" "}
              <small className="text-light-emphasis fw-light">
                (Not visible to the public)
              </small>
            </h6>
          </Col>
          <Col>
            <hr className="m-0" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} lg={4} className="mb-4">
            <InputGroup>
              <FloatingLabel
                label="Firstname"
                controlId="txtFirstname"
                htmlFor="txtFirstname"
              >
                <Form.Control
                  type="text"
                  placeholder="Firstname"
                  {...register("firstName", {
                    required: "Firstname is required",
                    pattern: {
                      value:
                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                      message:
                        "Firstname shouldn't contain HTML or Script tags",
                    },
                    maxLength: {
                      value: 50,
                      message: "Firstname cannot exceed 50 characters",
                    },
                  })}
                />
              </FloatingLabel>
              <InputGroup.Text>
                <i className="flaticon-profile fs-4"></i>
              </InputGroup.Text>
            </InputGroup>
            {errors.firstName && (
              <Form.Text className="text-danger">
                {errors.firstName.message}
              </Form.Text>
            )}
          </Col>
          <Col xs={12} sm={6} lg={4} className="mb-4">
            <InputGroup>
              <FloatingLabel
                label="Lastname"
                controlId="txtLastname"
                htmlFor="txtLastname"
              >
                <Form.Control
                  type="text"
                  placeholder="Lastname"
                  {...register("lastName", {
                    required: "Lastname is required",
                    pattern: {
                      value:
                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                      message: "Lastname shouldn't contain HTML or Script tags",
                    },
                    maxLength: {
                      value: 50,
                      message: "Lastname cannot exceed 50 characters",
                    },
                  })}
                />
              </FloatingLabel>
              <InputGroup.Text>
                <i className="flaticon-profile fs-4"></i>
              </InputGroup.Text>
            </InputGroup>
            {errors.lastName && (
              <Form.Text className="text-danger">
                {errors.lastName.message}
              </Form.Text>
            )}
          </Col>
          <Col xs={12} sm={6} lg={4} className="mb-4">
            <InputGroup>
              <FloatingLabel
                label="Email"
                controlId="txtEmail"
                htmlFor="txtEmail"
              >
                <Form.Control
                  type="email"
                  placeholder="Email"
                  {...register("emailAddress", {
                    required: "Email is required",
                    maxLength: 256,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
              </FloatingLabel>
              <InputGroup.Text>
                <i className="flaticon-mail fs-4"></i>
              </InputGroup.Text>
            </InputGroup>
            {errors.emailAddress && (
              <Form.Text className="text-danger">
                {errors.emailAddress.message}
              </Form.Text>
            )}
          </Col>
          <Col xs={12} sm={6} lg={4} className="mb-4">
            <InputGroup>
              <FloatingLabel
                label="Phone Number"
                controlId="txtPhoneNumber"
                htmlFor="txtPhoneNumber"
              >
                <PhoneInput
                  name="address.phoneNumber"
                  id="txtPhoneNumber"
                  control={control}
                  country={
                    isSupportedCountry(selectedCountryCode)
                      ? selectedCountryCode
                      : ""
                  }
                  international={true}
                  withCountryCallingCode={true}
                  onChange={(val) => {
                    trigger("address.phoneNumber");
                  }}
                  rules={{
                    required: "Phone number is required",
                    validate: (value) => {
                      if (!selectedCountry) return "Country is required";
                      if (!isPossiblePhoneNumber(value, selectedCountryCode)) {
                        setMaxLength(20);
                        return "Invalid phone number";
                      }
                      return true;
                    },
                  }}
                  className="form-control"
                />
              </FloatingLabel>
              <InputGroup.Text>
                <i className="flaticon-smartphone fs-3"></i>
              </InputGroup.Text>
            </InputGroup>
            {errors.address && errors.address.phoneNumber && (
              <Form.Text className="text-danger">
                {errors.address.phoneNumber.message}
              </Form.Text>
            )}
          </Col>
          <Col xs={12} sm={6} lg={4} className="mb-4">
            <InputGroup>
              <FloatingLabel
                label="Zip Code"
                controlId="txtZipCode"
                htmlFor="txtZipCode"
              >
                <Form.Control
                  type="number"
                  placeholder="Zip Code"
                  {...register("address.zipCode", {
                    pattern: {
                      value:
                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                      message: "Zip code shouldn't contain HTML or Script tags",
                    },
                    maxLength: {
                      value: 20,
                      message: "Zip code cannot exceed 20 characters",
                    },
                  })}
                />
              </FloatingLabel>
              <InputGroup.Text>
                <i className="flaticon-location fs-4"></i>
              </InputGroup.Text>
            </InputGroup>
            {errors.address && errors.address.zipCode && (
              <Form.Text className="text-danger">
                {errors.address.zipCode.message}
              </Form.Text>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Card className="border-0 border-start border-5 border-secondary bg-light mb-4">
              <Card.Body>
                <h6 className="fw-semibold">We take privacy seriously</h6>
                <small className="d-block mb-2">
                  Only the recipient's display name and story will appear on the
                  Benevolent website [
                  <Link
                    to="/"
                    title="FAQ"
                    className="text-decoration-none fw-semibold"
                  >
                    ?
                  </Link>
                  ].
                </small>
                <Link
                  to="/"
                  title="Privacy Policy"
                  className="text-decoration-none link-secondary fs-small fw-semibold"
                >
                  Privacy Policy
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="align-items-center mb-4">
          <Col xs="auto">
            <h6>
              Personal Info{" "}
              <small className="text-light-emphasis fw-light">
                (Not visible to the public)
              </small>
            </h6>
          </Col>
          <Col>
            <hr className="m-0" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={8} lg={6} xl={4} className="mb-4">
            <Form.Label className="d-block">Gender</Form.Label>
            <Controller
              name="gender"
              control={control}
              rules={{ required: "Gender is required." }} // Add your validation rules here
              render={({ field }) => (
                <Fragment>
                  <Form.Check
                    {...field}
                    inline
                    label="Male"
                    value="Male"
                    type="radio"
                    checked={selectedGender === "Male"}
                    onClick={handleGenderChange}
                  />
                  <Form.Check
                    {...field}
                    inline
                    label="Female"
                    value="Female"
                    checked={selectedGender === "Female"}
                    type="radio"
                    onClick={handleGenderChange}
                  />
                  <Form.Check
                    {...field}
                    inline
                    label="Other"
                    type="radio"
                    value="Other"
                    checked={selectedGender === "Other"}
                    onClick={handleGenderChange}
                  />
                  {selectedGender === "Other" && (
                    <Fragment>
                      <FloatingLabel
                        label="Write in"
                        controlId="txtGender"
                        htmlFor="txtGender"
                        className="mt-2"
                      >
                        <Form.Control
                          type="text"
                          size="sm"
                          placeholder="Write in"
                          {...register("genderInfo", {
                            required: "This field is required",
                            pattern: {
                              value:
                                /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                              message:
                                "This field shouldn't contain HTML or Script tags",
                            },
                            maxLength: {
                              value: 20,
                              message: "This field cannot exceed 20 characters",
                            },
                          })}
                        />
                      </FloatingLabel>

                      {errors.genderInfo && (
                        <Form.Text className="text-danger">
                          {errors.genderInfo.message}
                        </Form.Text>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}
            />
            {errors.gender && (
              <Form.Text className="text-danger d-block">
                {errors.gender.message}
              </Form.Text>
            )}
          </Col>
          <Col xs={12} sm={12} lg={6} xl={8} className="mb-4">
            <Form.Label className="d-block">
              Race/Ethnicity <small>[Check all that apply]</small>
            </Form.Label>
            <Controller
              name="ethnicity"
              control={control}
              rules={{ required: "Ethnicity is required." }} // Add your validation rules here
              render={({ field }) => (
                <Fragment>
                  <Form.Check
                    {...field}
                    inline
                    className="mb-2"
                    label="Asian"
                    value="Asian"
                    type="checkbox"
                    checked={selectedEthnicity.includes("Asian")}
                    onClick={handleEthnicityChange}
                  />
                  <Form.Check
                    {...field}
                    inline
                    className="mb-2"
                    label="Black"
                    value="Black"
                    type="checkbox"
                    checked={selectedEthnicity.includes("Black")}
                    onClick={handleEthnicityChange}
                  />
                  <Form.Check
                    {...field}
                    inline
                    className="mb-2"
                    label="Latino"
                    value="Latino"
                    type="checkbox"
                    checked={selectedEthnicity.includes("Latino")}
                    onClick={handleEthnicityChange}
                  />
                  <Form.Check
                    {...field}
                    inline
                    className="mb-2"
                    label="Native American/Alaskan"
                    value="Native American/Alaskan"
                    type="checkbox"
                    checked={selectedEthnicity.includes(
                      "Native American/Alaskan"
                    )}
                    onClick={handleEthnicityChange}
                  />
                  <Form.Check
                    {...field}
                    inline
                    className="mb-2"
                    label="Native Hawaiian/Pacific Islander"
                    value="Native Hawaiian/Pacific Islander"
                    type="checkbox"
                    checked={selectedEthnicity.includes(
                      "Native Hawaiian/Pacific Islander"
                    )}
                    onClick={handleEthnicityChange}
                  />
                  <Form.Check
                    {...field}
                    inline
                    className="mb-2"
                    label="White"
                    value="White"
                    type="checkbox"
                    checked={selectedEthnicity.includes("White")}
                    onClick={handleEthnicityChange}
                  />
                  <Form.Check
                    {...field}
                    inline
                    className="mb-2"
                    label="Other"
                    value="Other"
                    type="checkbox"
                    checked={selectedEthnicity.includes("Other")}
                    onClick={handleEthnicityChange}
                  />
                  {selectedEthnicity.includes("Other") && (
                    <Fragment>
                      <FloatingLabel
                        label="Write in"
                        controlId="txtEthnicity"
                        htmlFor="txtEthnicity"
                        className="mt-2"
                      >
                        <Form.Control
                          type="text"
                          size="sm"
                          placeholder="Write in"
                          {...register("ethnicityInfo", {
                            required: "This field is required",
                            pattern: {
                              value:
                                /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                              message:
                                "This field shouldn't contain HTML or Script tags",
                            },
                            maxLength: {
                              value: 20,
                              message: "This field cannot exceed 20 characters",
                            },
                          })}
                        />
                      </FloatingLabel>

                      {errors.ethnicityInfo && (
                        <Form.Text className="text-danger">
                          {errors.ethnicityInfo.message}
                        </Form.Text>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}
            />
            {errors.ethnicity && (
              <Form.Text className="text-danger d-block">
                {errors.ethnicity.message}
              </Form.Text>
            )}
          </Col>
          <Col xs={12} sm={6} lg={4} className="mb-4">
            <FloatingLabel
              label="Date of Birth"
              controlId="txtDOB"
              htmlFor="txtDOB"
            >
              <Form.Control
                type="date"
                placeholder="Date of Birth"
                {...register("dob", {
                  required: "Date of birth is required",
                })}
              />
            </FloatingLabel>
            {errors.dob && (
              <Form.Text className="text-danger">
                {errors.dob.message}
              </Form.Text>
            )}
          </Col>
          <Col xs={12} sm={6} lg={4} className="mb-4">
            <FloatingLabel controlId="ddEducation" label="Education">
              <Form.Select
                name="education"
                aria-label="Education"
                {...register("education", {
                  required: "Education is required",
                })}
              >
                <option value="">Select degree</option>
                <option value="Elementary/Middle School">
                  Elementary/Middle School
                </option>
                <option value="Some High School">Some High School</option>
                <option value="High School Degree">High School Degree</option>
                <option value="GED">GED</option>
                <option value="Some College">Some College</option>
                <option value="Associate's Degree">Associate's Degree</option>
                <option value="Bachelor's Degree">Bachelor's Degree</option>
                <option value="Master's Degree">Master's Degree</option>
                <option value="Doctorate">Doctorate</option>
              </Form.Select>
            </FloatingLabel>
            {errors.education && (
              <Form.Text className="text-danger">
                {errors.education.message}
              </Form.Text>
            )}
          </Col>
          <Col xs={12} sm={6} lg={4} className="mb-4">
            <FloatingLabel
              controlId="ddEmploymentStatus"
              label="Employment Status"
            >
              <Form.Select
                name="employmentStatus"
                aria-label="Employment Status"
                {...register("employmentStatus", {
                  required: "Employment status is required",
                })}
              >
                <option value="">Select status</option>
                <option value="Working full time">Working full time</option>
                <option value="Working part time">Working part time</option>
                <option value="Unemployed">Unemployed</option>
              </Form.Select>
            </FloatingLabel>
            {errors.employmentStatus && (
              <Form.Text className="text-danger">
                {errors.employmentStatus.message}
              </Form.Text>
            )}
          </Col>
          <Col xs={12} sm={6} xl={4} className="mb-4">
            <InputGroup>
              <FloatingLabel
                label="Personal Annual Income"
                controlId="txtPersonalAnnualIncome"
                htmlFor="txtPersonalAnnualIncome"
              >
                <Form.Control
                  type="number"
                  placeholder="Personal Annual Income"
                  {...register("personalAnnualIncome", {
                    required: "Personal annual income is required",
                  })}
                />
              </FloatingLabel>
              <InputGroup.Text>
                <i className="fs-4 flaticon-dollar"></i>
              </InputGroup.Text>
            </InputGroup>
            <Form.Text muted>Before taxes</Form.Text>
            {errors.personalAnnualIncome && (
              <Form.Text className="text-danger d-block">
                {errors.personalAnnualIncome.message}
              </Form.Text>
            )}
          </Col>
          <Col xs={12} xl={8} className="mb-4">
            <Form.Label className="d-block">
              Special Circumstances <small>[Check all that apply]</small>
            </Form.Label>
            <Controller
              name="specialCircumstances"
              control={control}
              render={({ field }) => (
                <Fragment>
                  <Form.Check
                    {...field}
                    inline
                    className="mb-2"
                    label="Abuse survivor"
                    value="Abuse survivor"
                    type="checkbox"
                    checked={selectedCircumstances.includes("Abuse survivor")}
                    onClick={handleCircumstancesChange}
                  />
                  <Form.Check
                    {...field}
                    inline
                    className="mb-2"
                    label="Short-term disability/medical disability"
                    value="Short-term disability/medical disability"
                    type="checkbox"
                    checked={selectedCircumstances.includes(
                      "Short-term disability/medical disability"
                    )}
                    onClick={handleCircumstancesChange}
                  />
                  <Form.Check
                    {...field}
                    inline
                    className="mb-2"
                    label="Long-term disability/medical disability"
                    value="Long-term disability/medical disability"
                    type="checkbox"
                    checked={selectedCircumstances.includes(
                      "Long-term disability/medical disability"
                    )}
                    onClick={handleCircumstancesChange}
                  />
                  <Form.Check
                    {...field}
                    inline
                    className="mb-2"
                    label="Ex-offender"
                    value="Ex-offender"
                    type="checkbox"
                    checked={selectedCircumstances.includes("Ex-offender")}
                    onClick={handleCircumstancesChange}
                  />
                  <Form.Check
                    {...field}
                    inline
                    className="mb-2"
                    label="Former foster youth"
                    value="Former foster youth"
                    type="checkbox"
                    checked={selectedCircumstances.includes(
                      "Former foster youth"
                    )}
                    onClick={handleCircumstancesChange}
                  />
                  <Form.Check
                    {...field}
                    inline
                    className="mb-2"
                    label="Homeless"
                    value="Homeless"
                    type="checkbox"
                    checked={selectedCircumstances.includes("Homeless")}
                    onClick={handleCircumstancesChange}
                  />
                  <Form.Check
                    {...field}
                    inline
                    className="mb-2"
                    label="Immigrant"
                    value="Immigrant"
                    type="checkbox"
                    checked={selectedCircumstances.includes("Immigrant")}
                    onClick={handleCircumstancesChange}
                  />
                  <Form.Check
                    {...field}
                    inline
                    className="mb-2"
                    label="LGBTQ"
                    value="LGBTQ"
                    type="checkbox"
                    checked={selectedCircumstances.includes("LGBTQ")}
                    onClick={handleCircumstancesChange}
                  />
                  <Form.Check
                    {...field}
                    inline
                    className="mb-2"
                    label="Veteran"
                    value="Veteran"
                    type="checkbox"
                    checked={selectedCircumstances.includes("Veteran")}
                    onClick={handleCircumstancesChange}
                  />
                </Fragment>
              )}
            />
            {errors.specialCircumstances && (
              <Form.Text className="text-danger d-block">
                {errors.specialCircumstances.message}
              </Form.Text>
            )}
          </Col>
        </Row>
        <Row className="align-items-center mb-4">
          <Col xs="auto">
            <h6>
              Household Info{" "}
              <small className="text-light-emphasis fw-light">
                (Not visible to the public)
              </small>
            </h6>
          </Col>
          <Col>
            <hr className="m-0" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} xl={4} className="mb-4">
            <InputGroup>
              <FloatingLabel
                label="Adults In Household (18 or older)"
                controlId="txtAdultsInHousehold"
                htmlFor="txtAdultsInHousehold"
              >
                <Form.Control
                  type="number"
                  placeholder="Adults In Household (18 or older)"
                  {...register("adultsInHousehold", {
                    required: "Adults in household is required",
                  })}
                />
              </FloatingLabel>
              <InputGroup.Text>
                <i className="fs-4 flaticon-profile"></i>
              </InputGroup.Text>
            </InputGroup>
            {errors.adultsInHousehold && (
              <Form.Text className="text-danger d-block">
                {errors.adultsInHousehold.message}
              </Form.Text>
            )}
          </Col>
          <Col xs={12} sm={12} md={6} xl={4} className="mb-4">
            <InputGroup>
              <FloatingLabel
                label="Children In Household (18 or younger)"
                controlId="txtChildrenInHousehold"
                htmlFor="txtChildrenInHousehold"
              >
                <Form.Control
                  type="number"
                  placeholder="Children In Household (18 or younger)"
                  {...register("childrenInHousehold", {
                    required: "Children in household is required",
                  })}
                />
              </FloatingLabel>
              <InputGroup.Text>
                <i className="fs-4 flaticon-kid"></i>
              </InputGroup.Text>
            </InputGroup>
            {errors.childrenInHousehold && (
              <Form.Text className="text-danger d-block">
                {errors.childrenInHousehold.message}
              </Form.Text>
            )}
          </Col>
          <Col xs={12} sm={12} md={6} xl={4} className="mb-4">
            <InputGroup>
              <FloatingLabel
                label="Household Annual Income"
                controlId="txtHouseholdAnnualIncome"
                htmlFor="txtHouseholdAnnualIncome"
              >
                <Form.Control
                  type="number"
                  placeholder="Household Annual Income"
                  {...register("householdAnnualIncome", {
                    required: "Household annual income is required",
                  })}
                />
              </FloatingLabel>
              <InputGroup.Text>
                <i className="fs-4 flaticon-dollar"></i>
              </InputGroup.Text>
            </InputGroup>
            <Form.Text muted>Before taxes</Form.Text>
            {errors.householdAnnualIncome && (
              <Form.Text className="text-danger d-block">
                {errors.householdAnnualIncome.message}
              </Form.Text>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button
              type="submit"
              variant="primary"
              data-button-type="Continue"
              disabled={loadingStates["Continue"]}
              className="btn-md me-3"
            >
              {loadingStates["Continue"] ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Save & Continue"
              )}
            </Button>
            <Button
              variant="secondary"
              type="submit"
              data-button-type="Draft"
              disabled={loadingStates["Draft"]}
              className="btn-md"
            >
              {loadingStates["Draft"] ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Save & Finish Later"
              )}
            </Button>
          </Col>
        </Row>
      </Form>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
    </Fragment>
  );
};

export default RecipientInfo;
