import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Card,
  Modal,
  Spinner,
} from "react-bootstrap";

import NeedCard from "./widgets/_needCard";
import Pagination from "../../components/pagination";
import { transformProfilePicture } from "../../components/transformFileURL";

import { getData, postData } from "../../services/apiService";
import { OrgProfileURL } from "../../constants";

import noData from "../../resources/images/no-data.png";
import dot from "../../resources/images/dot.png";
import square from "../../resources/images/square.png";

/**
 * Component for displaying validator profile information.
 * This component renders information about the validator and the needs validated by them.
 * @returns {React.Element} - Returns JSX for validator profile.
 */
const ValidatorProfile = () => {
  const { referenceId } = useParams();
  const location = useLocation();
  const [isMounted, setIsMounted] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [validator, setValidator] = useState({});
  const [needs, setNeeds] = useState([]);
  const [page, setPage] = useState({ PageNumber: 1, PageSize: 12 });
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    /**
     * Fetches validator profile from the backend API.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getValidatorProfile = async () => {
      setIsPageLoading(true);
      try {
        const response = await getData(
          `/api/profile/validator/${referenceId}`,
          null
        );
        if (response) setValidator(response);
        setIsMounted(true);
        setIsPageLoading(false);
      } catch (error) {
        setIsMounted(true);
        setIsPageLoading(false);
      }
    };
    getValidatorProfile();
  }, [referenceId]);

  useEffect(() => {
    /**
     * Fetches validated needs from the backend API.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getValidatedNeeds = async () => {
      try {
        const response = await postData(`/api/profile/getValidatedNeeds`, {
          referenceId,
          type: "Validator",
          page,
          searchTerm: "",
        });

        const updatedNeeds = transformProfilePicture(response.needs);
        setNeeds(updatedNeeds);
        if (response.count != null) setTotalRecords(response.count);
        setIsPageLoading(false);
      } catch (error) {
        setIsPageLoading(false);
      }
    };
    if (referenceId && Object.keys(validator).length > 0) getValidatedNeeds();
  }, [page, referenceId, validator]);

  /**
   * Function to handle pagination change.
   * @param {number} pageNumber - The new page number.
   * @param {number} pageSize - The new page size.
   */
  const handlePageChange = (pageNumber, pageSize) => {
    setIsPageLoading(true);
    setNeeds([]);
    setPage({
      PageNumber: pageNumber,
      PageSize: pageSize,
    });
  };

  return (
    <Container className="validator-profile">
      {Object.keys(validator).length > 0 && (
        <Row className="align-items-center justify-content-center mt-4">
          {location.key !== "default" && (
            <Col xs={12} className="text-end">
              <Link
                to={-1}
                className="text-decoration-none fw-semibold"
                title="Back"
              >
                <i className="flaticon-back pe-1 fs-5"></i>
                Back to Prior Page
              </Link>
            </Col>
          )}
          <Col xs={12} className="text-center mb-5 pb-4 page-title">
            <span className="text-primary tag-line">VALIDATOR</span>
            <h1 className="title mt-1">
              <span className="position-relative fw-medium">
                {validator.lastName}
                <span className="title-divider"></span>
              </span>
            </h1>
          </Col>
          <Col xs={8} sm={8} md={6} lg={5} xl={4} xxl={3} className="p-0">
            <div className="profile-picture bg-secondary rounded-5 position-relative ms-auto">
              <Image src={dot} alt="Dot" className="dot" />
              <Image src={square} alt="Square" className="square" />
              <Image
                src={transformProfilePicture(validator.profilePicture, {
                  isThumbnail: false,
                })}
                alt="Validator"
                fluid
                className="rounded-5 ps-2"
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={5} className="p-0">
            <div className="content p-4">
              <h4 className="title">
                <span>About {validator.firstName}</span>{" "}
                <span className="fw-normal">from </span>
                <Link
                  to={`${OrgProfileURL}${validator.orgReferenceId}`}
                  title="Profile"
                  className="link-secondary text-decoration-none"
                >
                  {validator.organization}
                </Link>
              </h4>
              <p className="mb-0 fs-small text-dark">{validator.biography}</p>
            </div>
          </Col>
          {needs.length > 0 && (
            <Col xs={12} className="mt-5 pt-5">
              <h2 className="title text-center mb-5">
                <span className="fw-medium">Needs</span> Validated
              </h2>
              <Row className="justify-content-center">
                {needs.map((need) => (
                  <Col
                    xs={8}
                    sm={6}
                    md={6}
                    lg={4}
                    xl={3}
                    xxl={3}
                    key={need.referenceId}
                    className="grid-list"
                  >
                    <NeedCard need={need} />
                  </Col>
                ))}
              </Row>
              <Pagination
                totalRecords={totalRecords}
                page={page}
                onPageChange={handlePageChange}
              />
            </Col>
          )}
        </Row>
      )}
      {isMounted && Object.keys(validator).length === 0 && (
        <Row className="justify-content-center text-center">
          <Col xs={10} sm={12} md={9} lg={7} xl={6} xxl={5}>
            <Card className="border-0 shadow p-4 rounded-4 text-center">
              <Card.Body>
                <Image src={noData} alt="Invalid Profile" fluid />
                <h4 className="title mt-3">Invalid Profile</h4>
                <p className="mb-0 fw-light text-dark-emphasis">
                  It looks like the profile you are trying to reach is not
                  <br className="d-none d-sm-inline-block" />
                  active. If you believe you've reached this page in error,
                  <br className="d-none d-sm-inline-block" />
                  please contact the person who sent you the link.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
export default ValidatorProfile;
