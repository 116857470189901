import React, { useState, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Form,
  Button,
  InputGroup,
  Spinner,
  Alert,
} from "react-bootstrap";
import { useForm } from "react-hook-form";

import { forgotPassword } from "../../services/accountService";
import { useAuthContext } from "../../context/authProvider";
import { notify, initialState } from "../../store/notification";
import { SITEURL } from "../../constants";

import logo from "../../resources/images/logo.svg";

/**
 * Component to handle password recovery process.
 * This component allows users to initiate the password recovery process by submitting their registered email.
 * Upon submission, an email with password reset instructions will be sent to the provided email address.
 * @returns {React.ReactElement} A component representing the password recovery interface.
 */
/**
 * Component for password recovery.
 * This component allows users to request a password reset by providing their email address.
 * Upon submission, an email with password reset instructions will be sent to the provided email address.
 * @returns {JSX.Element} - Returns JSX for password recovery.
 */
const RecoveryPassword = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Destructure the 'returnURL' object from the 'useAuthContext()' hook
  const { returnURL } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);

  /**
   * Function to handle form submission for password recovery.
   * @param {Object} data - The data containing user email for password recovery.
   */
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      await forgotPassword(data.email);
      setIsLoading(false);

      navigate(
        returnURL
          ? `/recovery-password-confirmation?returnUrl=${returnURL}`
          : "/recovery-password-confirmation",
        {
          replace: true, // Replace the current URL in history with the new one
          state: { email: data.email },
        }
      );
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setIsLoading(false);
    }
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main className="d-flex align-items-center justify-content-center min-vh-100 flex-column">
      <Container>
        <Row className="justify-content-center">
          <Col xs={10} sm={9} md={8} lg={6} xl={5} xxl={4}>
            <div className="text-center mb-5">
              <Link to={SITEURL} title="Benevolent">
                <Image src={logo} alt="Benevolent" className="logo" fluid />
              </Link>
            </div>
            <Card className="border-0 shadow p-4 rounded-4">
              <Card.Body>
                <h4 className="title text-center mb-3">Forgot Password?</h4>
                <p className="mb-4 fw-light text-dark-emphasis text-center">
                  Enter your registered email below to receive your password
                  reset instruction
                </p>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group className="mb-4">
                    <InputGroup>
                      <Form.Floating>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid email address",
                            },
                          })}
                        />
                        <Form.Label>Email</Form.Label>
                      </Form.Floating>
                      <InputGroup.Text>
                        <i className="flaticon-mail fs-4"></i>
                      </InputGroup.Text>
                    </InputGroup>
                    {errors.email && (
                      <Form.Text className="text-danger">
                        {errors.email.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <div className="d-grid gap-2">
                    <Button
                      variant="primary"
                      disabled={isLoading}
                      type="submit"
                      size="lg"
                    >
                      {isLoading ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        "SEND"
                      )}
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
            <div className="text-center mt-4">
              <p className="mb-0 text-dark-emphasis">
                Remember password?{" "}
                <Link
                  to={returnURL ? `/?returnUrl=${returnURL}` : "/"}
                  title="Login"
                  className="text-decoration-none link-secondary fw-semibold"
                >
                  Login
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Notification alerts */}
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
    </main>
  );
};

export default RecoveryPassword;
