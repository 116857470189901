import React, { useRef, useEffect } from "react";
import { Row, Col, Image, Badge } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";

import { transformProfilePicture } from "../../components/transformFileURL";
import { SupportTeamEmail, SupportTeamPhoneNumber } from "../../constants";
import logo from "../../resources/images/logo-small.svg";

/**
 * Component is used to display a receipt for completed transactions.
 * @param {object} props - The properties passed to the component.
 * @returns {JSX.Element} - Returns JSX for displaying the receipt.
 */
const Receipt = (props) => {
  const receiptRef = useRef();

  /**
   * Function to handle printing of receipt content.
   */
  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
    onAfterPrint: () => {
      props.setHasToPrint(false);
    },
  });

  useEffect(() => {
    // Executes when there is a change in the values of the dependencies in the useEffect hook.
    if (props.hasToPrint) handlePrint();
  }, [props.hasToPrint, handlePrint]);

  /**
   * Function to binds the payment status to a corresponding badge component with appropriate background color.
   * @param {string} status - The payment status.
   * @returns {React.Element} - Returns JSX for displaying payment status.
   */
  const bindPaymentStatus = (status) => {
    let background;
    switch (status) {
      case "Initiated":
        background = "light";
        break;
      case "Success":
        background = "success";
        break;
      case "Processing":
        background = "info";
        break;
      case "Failed":
        background = "danger";
        break;
      case "Canceled":
        background = "warning";
        break;
      default:
        background = "dark";
        break;
    }
    return (
      <Badge className="fw-semibold text-dark" bg={background}>
        {status.toUpperCase()}
      </Badge>
    );
  };

  /**
   * Function to formats the address components into a single string.
   * @param {object} address - The address object containing address components.
   * @returns {string} - The formatted address string.
   */
  const formatAddress = (address) => {
    const addressComponents = [
      address.address1,
      address.address2,
      address.city,
      address.state,
      address.country,
      address.zipCode && `- ${props.receipt.address.zipCode}`,
    ];
    return addressComponents.filter(Boolean).join(", ");
  };

  return (
    <div className="receipt shadow rounded-4 m-auto bg-white" ref={receiptRef}>
      <div className="bg rounded-top-4">
        <div className="logo d-table-cell align-middle text-center">
          <Image
            src={
              props.receipt.logo
                ? transformProfilePicture(props.receipt.logo)
                : logo
            }
            alt="Receipt"
            fluid
            roundedCircle
            className="bg-light text-center border-white"
          />
        </div>
      </div>
      <div className="p-4 pt-0 body">
        <div className="text-center mb-5">
          <h4 className="title mb-1">Receipt from Benevolent</h4>
          <p className="fw-light text-light-emphasis mb-0">
            Ref Number: {props.receipt.referenceId}
          </p>
        </div>
        <Row className="fs-small">
          <Col xs={6} sm={4} className="mb-4">
            <span className="fw-light text-light-emphasis">NAME</span>
            <p className="mt-1 mb-0 fw-semibold">{props.receipt.fullName}</p>
          </Col>
          <Col xs={6} sm={5} className="mb-4">
            <span className="fw-light text-light-emphasis">EMAIL</span>
            <p className="mt-1 mb-0 fw-semibold text-break">
              {props.receipt.email}
            </p>
          </Col>
          <Col xs={6} sm={3} className="mb-4">
            <span className="fw-light text-light-emphasis">PHONE NUMBER</span>
            <p className="mt-1 mb-0 fw-semibold">
              {props.receipt.address.phoneNumber}
            </p>
          </Col>

          <Col xs={6} sm={4} className="mb-4">
            <span className="fw-light text-light-emphasis">DATE PAID</span>
            <p className="mt-1 mb-0 fw-semibold">{props.receipt.paidOn}</p>
          </Col>
          <Col xs={6} sm={5} className="mb-4">
            <span className="fw-light text-light-emphasis">SOURCE</span>
            <p className="mt-1 mb-0 fw-semibold">{props.receipt.source}</p>
          </Col>
          <Col xs={6} sm={3} className="mb-4">
            <span className="fw-light text-light-emphasis">PAYMENT METHOD</span>
            <p className="mt-1 mb-0 fw-semibold">
              {props.receipt.paymentMethod}
            </p>
          </Col>
          <Col xs={12} sm={8}>
            <span className="fw-light text-light-emphasis">
              BILLING ADDRESS
            </span>
            <p className="mt-1 mb-0 fw-semibold text-break">
              {formatAddress(props.receipt.address)}
            </p>
          </Col>
          <Col xs={12} sm={4}>
            <span className="fw-light text-light-emphasis">STATUS</span>
            <p className="mt-1 mb-0 fw-semibold">
              {bindPaymentStatus(props.receipt.status)}
            </p>
          </Col>
        </Row>
        <h4 className="title my-4">Summary</h4>
        <div className="bg-light p-4 rounded-4 mt-4 fs-small">
          <div className="d-flex justify-content-between mb-2">
            <span>Additional Gift</span>
            <span>${props.receipt.contribution}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span>Donation Amount</span>
            <span>${props.receipt.donationAmount}</span>
          </div>
          <hr />
          <div className="d-flex justify-content-between fw-semibold">
            <span>Total</span>
            <span>${props.receipt.transactionAmount}</span>
          </div>
        </div>
        <hr className="my-4" />
        <small className="text-dark-emphasis d-block mb-2">
          Benevolent, NFP is an exempt organization under section 501(c)3 of the
          Internal Revenue Code. (EIN: 45-3136367) No goods or services were
          provided to you in connection with this donation.
        </small>
        <small className="text-dark-emphasis d-block mb-1">
          If you have any questions, contact us at{" "}
          <a
            title="Mail Us"
            className="link-dark text-decoration-none fw-semibold"
            href={`mailto:${SupportTeamEmail}`}
          >
            {SupportTeamEmail}
          </a>{" "}
          or call at{" "}
          <a
            title="Call Us"
            className="link-dark text-decoration-none fw-semibold"
            href={`tel:${SupportTeamPhoneNumber}`}
          >
            {SupportTeamPhoneNumber}
          </a>
          .
        </small>
        <small className="text-dark-emphasis">
          This email will serve as your receipt for tax purposes.
        </small>
      </div>
    </div>
  );
};

export default Receipt;
