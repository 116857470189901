import React, { useState, useEffect, useReducer } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Card,
  Image,
  Button,
  Dropdown,
  ListGroup,
  Modal,
  Alert,
  Spinner,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import DataTable from "../../components/table";
import { transformAttachments } from "../../components/transformFileURL";

import { getData, putData } from "../../services/apiService";
import { useAuthContext } from "../../context/authProvider";
import { notify, initialState } from "../../store/notification";
import confirmation from "../../resources/images/confirmation.png";

/**
 * Component for managing receipts for a need.
 * @returns {React.Element} - Returns JSX for managing receipts.
 */
const ManageReceipts = () => {
  // Extract the need ID from the URL parameters for getting
  // receipts details
  const { needId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [receipts, setReceipts] = useState([]);
  const [files, setFiles] = useState([]);
  const [need] = useState({
    title: state?.title,
    recipient: state?.recipient,
  });

  // State and functions to control the visibility of the download modal.
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const closeDownloadModal = () => setShowDownloadModal(false);
  const openDownloadModal = () => setShowDownloadModal(true);

  // Columns configuration for the receipt table
  const columns = [
    {
      header: "Receipts",
      cell: (cellProps) => {
        return (
          <Button
            variant="link"
            className="px-0"
            onClick={() => downloadReceipt(cellProps.row.original.id)}
          >
            Download
          </Button>
        );
      },
    },
    {
      header: "Comments",
      accessorKey: "description",
    },
    {
      header: "Submitted By",
      accessorKey: "submittedBy",
    },
    {
      header: "Submitted On",
      accessorKey: "submittedOn",
    },
    {
      header: "Actions",
      accessorKey: "id",
      cell: ({ getValue }) => (
        <Dropdown drop="down" className="more-menu">
          <Dropdown.Toggle variant="light" size="sm">
            <i className="flaticon-more"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => showConfirmation(getValue())}>
              <i className="flaticon-delete fs-6 me-1 align-text-bottom"></i>
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];

  /**
   * Function to navigate to the upload receipts page.
   */
  const navigateToUploadReceipts = () => {
    navigate(`/needs/receipts/upload/${needId}`, {
      replace: true,
      state: { title: need.title, recipient: need.recipient },
    });
  };

  /**
   * Function to download a receipt by its ID.
   * @param {string} receiptId - The ID of the receipt to be downloaded.
   */
  const downloadReceipt = async (receiptId) => {
    const filteredData = receipts.filter((receipt) => receipt.id === receiptId);
    if (filteredData.length > 0) {
      let data = [];
      const fileURLs = filteredData[0].receipts;

      fileURLs.forEach((fileURL) => {
        // Split the URL by '/' to get an array of parts
        const parts = fileURL.split("/");
        // Get the last part of the URL, which should be the filename with extension
        data.push({
          name: parts[parts.length - 1],
          downloadURL: transformAttachments(fileURL),
        });
      });

      setFiles(data);
      openDownloadModal(true);
    }
  };

  /**
   *  Function to delete a receipt by its ID, including its associated files
   * @param {string} receiptId - The ID of the receipt to be deleted.
   */
  const deleteReceipt = async (receiptId) => {
    let fileResideAt = "";
    const filteredData = receipts.filter((receipt) => receipt.id === receiptId);
    // Extract the file location from the receipt data
    if (filteredData.length > 0) {
      const parts = filteredData[0].receipts[0].split("/");
      fileResideAt = parts[parts.length - 2];
    }
    if (!fileResideAt) return;
    setIsPageLoading(true);
    try {
      const response = await putData(
        `/api/need/delete-receipt/${receiptId}/${fileResideAt}`,
        null,
        userSession
      );
      setReceipts(receipts.filter((receipt) => receipt.id !== receiptId));
      setIsPageLoading(false);
      showNotification("success", response, 5000);
    } catch (error) {
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  /**
   * Function to display a confirmation dialog for deleting a receipt.
   * @param {number} id - The ID of the receipt to be deleted.
   */
  const showConfirmation = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Card className="border-0 shadow rounded-3">
            <Card.Body className="p-4 text-center">
              <Image src={confirmation} alt="Delete Confirmation" fluid />
              <div className="mt-3 fw-light">
                Do you really want to delete this receipt? <br />
                This process can't be undone.
              </div>
            </Card.Body>
            <Card.Footer className="bg-transparent py-4 text-center">
              <Button
                title="Cancel"
                variant="link"
                className="link-danger fw-semibold"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                className="ms-4 btn-md"
                onClick={async () => {
                  try {
                    await deleteReceipt(id);
                  } catch (error) {
                    // showNotification("danger", error, 5000);
                  }
                  onClose();
                }}
              >
                Yes, Delete
              </Button>
            </Card.Footer>
          </Card>
        );
      },
    });
  };

  useEffect(() => {
    /**
     * Fetches the receipts for a specific need from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getAll = async () => {
      setIsLoading(true);
      try {
        const response = await getData(
          `/api/need/receipts/${needId}`,
          null,
          userSession
        );
        setReceipts(response);
        setIsLoading(false);
      } catch (error) {
        showNotification("danger", error, 5000);
        setReceipts([]);
        setIsLoading(false);
      }
    };

    getAll();

    // Return a cleanup function
    return () => {};
  }, [needId, userSession]);

  /**
   * Function to downloads a file from a given URL and saves it with the specified filename.
   * @param {string} fileURL - The URL of the file to be downloaded.
   * @param {string} fileName - The name to save the downloaded file as.
   */
  const downloadFile = async (fileURL, fileName) => {
    const response = await fetch(fileURL);
    if (!response.ok) {
      setShowDownloadModal(false);
      showNotification(
        "danger",
        "The requested file could not be found.",
        5000
      );
      return;
    }
    // Convert the response into a blob
    const blob = await response.blob();
    // Create a URL for the blob
    const downloadURL = window.URL.createObjectURL(blob);
    // Create a temporary link element
    const link = document.createElement("a");
    link.href = downloadURL;
    link.setAttribute("download", fileName);
    // Append the link to the body
    document.body.appendChild(link);
    // Programmatically click the link to trigger the download
    link.click();
    // Remove the link from the document
    link.parentNode.removeChild(link);
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-semibold mb-0">Receipts</h6>
          <div>
            <Link to={`/needs`} className="ms-3 btn btn-secondary" title="Back">
              Back
            </Link>
            <Button
              onClick={navigateToUploadReceipts}
              variant="secondary"
              className="ms-3"
            >
              + Upload Receipt
            </Button>
          </div>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <h6 className="d-flex lh-sm text-dark-emphasis">
              <span className="fw-semibold me-2">Need: </span>
              <span>{need.title}</span>
            </h6>
            <DataTable
              columns={columns}
              data={receipts}
              totalRecords={receipts.length}
              loadingState={isLoading}
            />
          </Card.Body>
        </Card>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Modal
        show={showDownloadModal}
        aria-labelledby="Download Modal"
        className="modal-download"
        dialogClassName="modal-feature"
        centered
      >
        <Modal.Body className="text-center">
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="fw-semibold mb-0">Download Receipts</h6>
            <Button
              className="btn-close fs-small"
              variant="link"
              aria-label="Close"
              onClick={closeDownloadModal}
            ></Button>
          </div>
          <hr />
          <ListGroup as="ul" className="mt-4">
            {files.map((file, index) => (
              <ListGroup.Item
                as="li"
                key={file.name}
                className="d-flex justify-content-between align-items-center bg-transparent p-3"
              >
                <div className="fs-small text-dark-emphasis text-start">
                  {file.name}
                </div>
                <Button
                  type="button"
                  aria-label="Download"
                  variant="link"
                  size="sm"
                  onClick={() => downloadFile(file.downloadURL, file.name)}
                >
                  <i className="flaticon-downloads fs-5"></i>
                </Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
      </Modal>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default ManageReceipts;
