import React, { useState, useEffect, useReducer, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  TabContainer,
  Tab,
  Nav,
  Form,
  Button,
  InputGroup,
  FloatingLabel,
  Alert,
} from "react-bootstrap";
import { useForm } from "react-hook-form";

import UserPreferences from "./_userPreferences";
import ImageCrop from "../../../components/imageCrop";
import { transformProfilePicture } from "../../../components/transformFileURL";

import { getData, postData } from "../../../services/apiService";
import { notify, initialState } from "../../../store/notification";
import { useAuthContext } from "../../../context/authProvider";
import { useCallback } from "react";

/**
 * Component for creating a new user giving page.
 * It allows users to input basic information and select needs for their giving page.
 * @returns {React.Element} - JSX for creating a new user giving page.
 */
const CreateUserGivingPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
  } = useForm();

  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("basic-info");
  const [preferences, setUserPreferences] = useState({
    data: [],
    reset: false,
  });
  const [isPublic, setIsPublic] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  // File Upload
  const [profilePicture, setProfilePicture] = useState(null);
  const fileInputRef = useRef(null); // Create a ref for the file input
  const [selectedFile, setSelectedFile] = useState({});
  const [showCropModal, setShowCropModal] = useState(false);
  const [cropInfo, setCropInfo] = useState({});

  const closeCropModal = () => setShowCropModal(false);
  const openCropModal = () => setShowCropModal(true);

  /**
   * Function to update the active tab.
   */
  const handleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  /**
   * Function to handles the change event of the file input.
   * @param {object} event - The change event object.
   */
  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];
      const maxSize = 1024 * 1024; // 1 MB in bytes
      if (!allowedTypes.includes(file.type)) {
        showNotification(
          "danger",
          "Image format is incompatible. Only JPEG and PNG formats are supported.",
          5000
        );
        return;
      }
      if (file.size > maxSize) {
        showNotification(
          "danger",
          "Your file is too large, maximum allowed size is: 1 MB",
          5000
        );
        return;
      }
      if (file) {
        // Read the selected file using FileReader and set its information.
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedFile({
            name: event.target.files?.[0].name,
            file: event.target.files[0],
            src: reader.result,
            url: URL.createObjectURL(event.target.files[0]),
          });
          openCropModal();
        };
        reader.readAsDataURL(file);
      }
    }
  };

  /**
   * Function to update the state after cropping.
   * @param {string} croppedImage - The cropped image data.
   * @param {object} croppedAreaPixels - The cropped area in pixels.
   * @param {number} rotation - The rotation angle of the cropped image.
   */
  const updateAvatar = (croppedImage, croppedAreaPixels, rotation) => {
    setCropInfo({
      image: croppedImage,
      position: croppedAreaPixels,
      rotation: rotation,
    });
  };

  /**
   * Function to handle form submission.
   * Combines form data with chosen needs and calls the addOrUpdate function.
   * @param {object} chosenNeeds - The chosen needs to be associated with the form data.
   */
  const onSubmit = async (chosenNeeds) => {
    const formData = Object.assign({}, getValues(), chosenNeeds);
    formData.profilePicture = profilePicture;
    await addOrUpdate(formData);
  };

  /**
   * Function to add or update a user giving page.
   * @param {object} formData - The form data containing information about the giving page.
   */
  const addOrUpdate = async (formData) => {
    setIsLoading(true);
    if (selectedFile.file) {
      const response = await upload();
      if (response) {
        formData.profilePicture = response;
        setProfilePicture(response);
      } else return;
    }
    formData.fileToDelete = fileToDelete;
    try {
      const response = await postData(
        "/api/fundraising/upsertUserGivingPage",
        formData,
        userSession
      );
      setIsLoading(false);
      resetForm(true);
      showNotification("success", response, 5000);
    } catch (error) {
      // Show a notification if an error occurs during add or update the giving page
      showNotification("danger", error, 5000);
      setIsLoading(false);
    }
  };

  /**
   * Function to uploads the cropped image file using the backend API.
   */
  const upload = async () => {
    const formData = new FormData();
    formData.append("File", selectedFile.file);
    formData.append("cropX", parseInt(cropInfo.position.x));
    formData.append("cropY", parseInt(cropInfo.position.y));
    formData.append("cropWidth", parseInt(cropInfo.position.width));
    formData.append("cropHeight", parseInt(cropInfo.position.height));
    formData.append("cropRotation", parseInt(cropInfo.rotation));
    formData.append("type", "Profile");
    if (profilePicture != null)
      formData.append("profilePicture", profilePicture);
    try {
      const response = await postData("/api/image/crop", formData, userSession);
      return response;
    } catch (error) {
      // Show a notification if an error occurs while file upload
      showNotification("danger", error, 5000);
      setIsLoading(false);
      resetFileInput();
      return null;
    }
  };

  /**
   * Function is responsible for preparing the removal of the profile picture.
   * It sets the fileToDelete state to the current profile picture URL
   * so that it can be deleted when the form is submitted or updated.
   */
  const removeProfilePicture = () => {
    setFileToDelete(profilePicture);
    setProfilePicture("");
  };

  /** Function to reset the file inputs */
  const resetFileInput = () => {
    setCropInfo({});
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset the file input value
    }
    setSelectedFile({});
  };

  /** Function to reset the form */
  const resetForm = useCallback((resetPreferences) => {
    resetFileInput();
    reset({
      id: 0,
      status: "Active",
      title: "",
      description: "",
    });
    setProfilePicture("");
    setUserPreferences((prevState) => ({
      ...prevState,
      data: [],
      reset: resetPreferences,
    }));
    setIsPublic(false);
  }, [reset]);

  useEffect(() => {
    /**
     * Fetches user giving page from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getById = async () => {
      try {
        const response = await getData(
          `/api/fundraising/getUserGivingPageById/${id.toString()}`,
          null,
          userSession
        );
        setUserPreferences((prevState) => ({
          ...prevState,
          data: response.needs,
        }));
        setProfilePicture(response.profilePicture);
        // Reset form values with fetched data
        reset({
          id: response.id,
          status: response.status,
          title: response.title,
          description: response.description,
        });

        setIsPublic(response.status === "Active");
      } catch (error) {
        // Show a notification if an error occurs any
        showNotification("danger", error, 5000);
      }
    };
    if (id > 0) getById();
    else resetForm(false);
  }, [id, userSession, reset, resetForm]);

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-bold mb-0">
            {id > 0 ? "Edit Giving Page" : "Create Giving Page"}
          </h6>
          <Link
            to="/giving-page/user"
            className="ms-3 btn btn-secondary"
            title="Back"
          >
            <i className="flaticon-line-arrow-left pe-2"></i>
            Back
          </Link>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <TabContainer
              id="enrollment-tabs"
              defaultActiveKey="basic-info"
              activeKey={activeTab}
              onSelect={(tab) => setActiveTab(tab)}
            >
              <Nav variant="tabs" fill className="border-bottom">
                <Nav.Item>
                  <Nav.Link eventKey="basic-info" className="fw-semibold">
                    <i className="flaticon-id-card pe-2 fs-3"></i>
                    <span className="align-text-bottom">Basic Info</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="needs"
                    className="fw-semibold"
                    disabled={activeTab !== "organization-info"}
                  >
                    <i className="flaticon-need pe-2 fs-3"></i>
                    <span>Needs</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="p-4">
                <Tab.Pane eventKey="basic-info">
                  <Form
                    onSubmit={handleSubmit((data) => handleActiveTab("needs"))}
                  >
                    <Form.Control type="hidden" {...register("id")} />
                    <Form.Control type="hidden" {...register("status")} />
                    <Row>
                      <Col xs={12} md={6} className="mb-4">
                        <FloatingLabel
                          label="Title"
                          controlId="txtTitle"
                          htmlFor="txtTitle"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Title"
                            {...register("title", {
                              required: "Title is required",
                              pattern: {
                                value:
                                  /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                message:
                                  "Title shouldn't contain html or script tags",
                              },
                              maxLength: {
                                value: 100,
                                message: "Title cannot exceed 100 characters",
                              },
                            })}
                          />
                        </FloatingLabel>
                        <Form.Text muted className="d-block">
                          Example: Wish List
                        </Form.Text>
                        {errors.title && (
                          <Form.Text className="text-danger">
                            {errors.title.message}
                          </Form.Text>
                        )}
                      </Col>
                      <Col xs={12} md={6} className="mb-4">
                        <FloatingLabel
                          controlId="txtDescription"
                          label="Summarize the giving page's goal"
                        >
                          <Form.Control
                            as="textarea"
                            className="scroll"
                            placeholder="Summarize the giving page's goal"
                            {...register("description", {
                              required: "Description is required",
                              pattern: {
                                value:
                                  /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>)[\s\S])*$/i,
                                message:
                                  "Description shouldn't contain html or script tags",
                              },
                              maxLength: {
                                value: 2500,
                                message:
                                  "Description cannot exceed 2500 characters",
                              },
                            })}
                          />
                        </FloatingLabel>
                        {errors.description && (
                          <Form.Text className="text-danger">
                            {errors.description.message}
                          </Form.Text>
                        )}
                      </Col>
                      <Col xs={12} md={6} className="mb-4">
                        <InputGroup>
                          <Form.Control
                            type="file"
                            id="fileInput"
                            onChange={handleFileChange}
                            className="d-none"
                            ref={fileInputRef}
                            accept="image/jpeg, image/png"
                          />
                          <Form.Control
                            type="text"
                            placeholder="No file chosen"
                            defaultValue={selectedFile.name}
                            readOnly
                            className="rounded-start"
                          />
                          <InputGroup.Text className="p-0 border-0">
                            <Button
                              as="label"
                              variant="light"
                              htmlFor="fileInput"
                              className="btn-md rounded-0"
                            >
                              Browse
                            </Button>
                            <Button
                              variant="dark"
                              className="btn-md rounded-start-0"
                              onClick={() => {
                                resetFileInput();
                                removeProfilePicture();
                              }}
                            >
                              Remove
                            </Button>
                          </InputGroup.Text>
                        </InputGroup>
                        <Form.Text muted className="d-block mt-1">
                          Recommended size: 1024x576. Max file size: 1MB.
                          Supported file formats: JPG and PNG
                        </Form.Text>
                      </Col>
                      <Col xs={12} md={6} className="mb-4">
                        <div className="d-flex align-items-center h-100">
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="Make it Public"
                            className="text-muted"
                            checked={isPublic}
                            onChange={(e) => {
                              setValue(
                                "status",
                                e.target.checked ? "Active" : "Inactive"
                              );
                              setIsPublic(e.target.checked);
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={12} className="mb-4">
                        <Form.Label>Preview: </Form.Label>
                        <Image
                          fluid
                          src={
                            !cropInfo.image
                              ? transformProfilePicture(profilePicture)
                              : cropInfo.image
                          }
                          alt="Profile Picture"
                          className="ms-3 align-middle avatar"
                          roundedCircle
                          thumbnail
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-end">
                      <Col xs={3} xl={2} className="mt-4 d-grid">
                        <Button
                          variant="primary"
                          type="submit"
                          className="btn-md"
                        >
                          NEXT
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Tab.Pane>
                <Tab.Pane eventKey="needs">
                  <UserPreferences
                    preferences={preferences}
                    handleActiveTab={handleActiveTab}
                    onSubmit={onSubmit}
                    isLoading={isLoading}
                  />
                </Tab.Pane>
              </Tab.Content>
            </TabContainer>
          </Card.Body>
        </Card>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <ImageCrop
        showCropModal={showCropModal}
        closeCropModal={closeCropModal}
        selectedFile={selectedFile}
        updateAvatar={updateAvatar}
        resetFileInput={resetFileInput}
      />
    </main>
  );
};

export default CreateUserGivingPage;
