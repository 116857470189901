import { useEffect } from "react";

// Threshold for token expiration check (5 minutes in milliseconds)
const TokenExpirationThreshold = 300000;
/**
 * Custom React hook to handle token expiration
 * @param {Object} user - The user object containing token expiration information
 * @param {Function} showTokenExpirationPopup - Function to show the token expiration popup
 */
const useTokenExpiration = (user, showTokenExpirationPopup) => {
  useEffect(() => {
    const nowInSeconds = Math.floor(Date.now() / 1000);
    // Calculate remaining time until token expiration
    const tokenExpirationTime = user?.exp - nowInSeconds;
    // Calculate time until expiration in milliseconds
    const timeUntilExpiration =
      tokenExpirationTime > TokenExpirationThreshold
        ? (tokenExpirationTime - TokenExpirationThreshold) * 1000
        : tokenExpirationTime * 1000;
    let timeoutId = null;

    // Check if the token has already expired
    if (nowInSeconds > user?.exp) {
      // Show token expiration popup if user is not a recipient
      if (!user?.roles.includes("Recipient")) showTokenExpirationPopup();
    }

    // Set up a timeout to show the token expiration popup
    if (timeUntilExpiration > 0) {
      timeoutId = setTimeout(() => {
        // Show token expiration popup if user is not a recipient
        if (!user?.roles.includes("Recipient")) showTokenExpirationPopup();
      }, timeUntilExpiration);
    }

    // Cleanup: Clear the timeout when the component unmounts
    return () => {
      if (timeoutId != null) {
        clearTimeout(timeoutId);
      }
    };
  }, [user, showTokenExpirationPopup]);
};

export default useTokenExpiration;
