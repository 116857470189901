import React, { Fragment, useState, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Image,
  Card,
  Badge,
  Button,
  Dropdown,
  ListGroup,
  Alert,
  Modal,
  Spinner,
} from "react-bootstrap";
import key from "weak-key";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import DataTable from "../../components/table";
import Export from "../../components/export";
import Receipt from "../donation/receipt";
import { transformProfilePicture } from "../../components/transformFileURL";

import { getData, postData } from "../../services/apiService";
import { useAuthContext } from "../../context/authProvider";
import { notify, initialState } from "../../store/notification";
import { OrgProfileURL, NeedProfileURL } from "../../constants";

/**
 * Component for managing transactions.
 * @returns {React.Element} - Returns JSX for managing transactions.
 * @access Accessible by SuperAdmin and Staff.
 */
const ManageTransactions = () => {
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [transaction, setTransaction] = useState({});
  const [receipt, setReceipt] = useState({});
  const [page, setPage] = useState({ PageNumber: 1, PageSize: 25 });
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  // State and functions for controlling the Export Modal
  const [showExportModal, setShowExportModal] = useState(false);
  const closeExportModal = () => setShowExportModal(false);
  const openExportModal = () => setShowExportModal(true);

  // State and functions for controlling the Allocation Modal
  const [displayAllocationModal, setDisplayAllocationModal] = useState(false);
  const closeAllocationModal = () => setDisplayAllocationModal(false);
  const openAllocationModal = () => setDisplayAllocationModal(true);

  // State and functions for controlling the Receipt Modal
  const [displayReceiptModal, setDisplayReceiptModal] = useState(false);
  const closeReceiptModal = () => setDisplayReceiptModal(false);
  const openReceiptModal = () => setDisplayReceiptModal(true);

  // State to control whether the transaction receipt  should be opened for printing
  const [hasToPrint, setHasToPrint] = useState(false);

  // Columns configuration for the transaction table
  const columns = [
    {
      header: "Date",
      accessorKey: "paidOn",
    },
    {
      header: "Order Id",
      accessorKey: "transactionId",
    },
    {
      header: "Origin",
      accessorKey: "origin",
    },
    // {
    //   header: "Source",
    //   accessorKey: "source",
    // },
    {
      header: "Need Id",
      accessorKey: "needId",
    },
    {
      header: "Donor",
      accessorKey: "donor",
    },
    {
      header: "Recipient",
      cell: (cellProps) => {
        return (
          <Fragment>
            <Link
              to={`${NeedProfileURL}${cellProps.row.original.needReferenceId}/${cellProps.row.original.needId}`}
              className="text-decoration-none"
            >
              {cellProps.row.original.needTitle}
            </Link>{" "}
            {cellProps.row.original.needId > 0 ? "for " : "Additional Gift"}
            <span className="fw-semibold">
              {cellProps.row.original.recipient}
            </span>
          </Fragment>
        );
      },
    },
    {
      header: "Organization",
      cell: (cellProps) => {
        return (
          <Link
            to={`${OrgProfileURL}${cellProps.row.original.organizationReferenceId}`}
            className="text-decoration-none"
          >
            {cellProps.row.original.organizationName}
          </Link>
        );
      },
      accessorKey: "organizationName",
    },
    {
      header: "Donation Amount",
      cell: (cellProps) => {
        return <Fragment> ${cellProps.row.original.donationAmount}</Fragment>;
      },
    },
    // {
    //   header: "Additional Gift",
    //   cell: (cellProps) => {
    //     return <Fragment> ${cellProps.row.original.contribution}</Fragment>;
    //   },
    // },
    // {
    //   header: "Processing Fee",
    //   cell: (cellProps) => {
    //     return <Fragment> ${cellProps.row.original.processingFee}</Fragment>;
    //   },
    // },
    {
      header: "Transaction Amount",
      cell: (cellProps) => {
        return (
          <Fragment> ${cellProps.row.original.transactionAmount}</Fragment>
        );
      },
    },
    // {
    //   header: "Net Amount",
    //   cell: (cellProps) => {
    //     return <Fragment> ${cellProps.row.original.netAmount}</Fragment>;
    //   },
    // },
    // {
    //   header: "Tribute",
    //   cell: (cellProps) => {
    //     return (
    //       <Fragment>
    //         {cellProps.row.original.hasTribute && (
    //           <i className="flaticon-tick fs-3 d-inline-block text-center bg-secondary text-white rounded-circle hasTribute"></i>
    //         )}
    //       </Fragment>
    //     );
    //   },
    // },
    {
      header: "Status",
      cell: (cellProps) => {
        let background;
        switch (cellProps.row.original.status) {
          case "Initiated":
            background = "light";
            break;
          case "Success":
            background = "success";
            break;
          case "Processing":
            background = "info";
            break;
          case "Failed":
            background = "danger";
            break;
          case "Canceled":
            background = "warning";
            break;
          default:
            background = "dark";
            break;
        }
        return (
          <Badge className="fw-semibold text-dark" bg={background}>
            {cellProps.row.original.status.toUpperCase()}
          </Badge>
        );
      },
    },
    {
      header: "Actions",
      accessorKey: "id",
      cell: (cellProps) => {
        return (
          <Fragment>
            {/* {(cellProps.row.original.status === "Success" ||
              cellProps.row.original.status === "Processing") && ( */}
            <Dropdown drop="down" className="more-menu">
              <Dropdown.Toggle variant="light" size="sm">
                <i className="flaticon-more"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    viewDonationDetails(
                      cellProps.row.original.referenceId,
                      cellProps.row.original.status
                    )
                  }
                >
                  <i className="flaticon-dollar-circle fs-5 me-2"></i>Donation
                  Details
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    viewReceipt(cellProps.row.original.referenceId)
                  }
                >
                  <i className="flaticon-invoice fs-5 me-2"></i>
                  Receipt
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* )} */}
          </Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    /**
     * Fetches the transactions from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getTransactions = async () => {
      setIsLoading(true);
      try {
        const response = await postData(
          `/api/payment/transactions`,
          {
            page,
            searchTerm,
            fromDate: fromDate ? convertLocalToUTCDate(fromDate) : fromDate,
            toDate: toDate ? convertLocalToUTCDate(toDate) : toDate,
          },
          userSession
        );
        setTransactions(response.transactions);
        if (response.count != null) setTotalRecords(response.count);
        setIsLoading(false);
      } catch (error) {
        // Show a notification if an error occurs
        showNotification("danger", error, 5000);
        setTransactions([]);
        setIsLoading(false);
      }
    };

    getTransactions();

    // Return a cleanup function
    return () => {};
  }, [searchTerm, page, userSession, fromDate, toDate]);

  /**
   * Function to handle page change event.
   * @param {number} pageNumber - The new page number.
   * @param {number} pageSize - The new page size.
   */
  const handlePageChange = (pageNumber, pageSize) => {
    setTransactions([]);
    setPage({
      PageNumber: pageNumber,
      PageSize: pageSize,
    });
  };

  /**
   * Function to handle search action.
   * @param {string} value - The search term entered by the user.
   */
  const handleSearch = (value) => {
    handlePageChange(1, page.PageSize);
    setSearchTerm(value);
  };

  /**
   * Convert a local date to UTC.
   * @param {string} date - The local date to be converted.
   * @returns {Date} The equivalent UTC date.
   */
  const convertLocalToUTCDate = (date) => {
    if (!date) return date;

    date = new Date(date);
    date = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    return date;
  };

  /**
   * Constructs the search query object.
   * @returns {string} The JSON stringified search query object.
   */
  const constructSearchQuery = () => {
    const query = {};
    if (showExportModal) {
      if (fromDate) query.fromDate = convertLocalToUTCDate(fromDate);
      if (toDate) query.toDate = convertLocalToUTCDate(toDate);
    }
    return JSON.stringify(query);
  };

  /**
   * Function to validate the 'toDate' field.
   * @param {Date} date - The value of 'toDate' to be validated.
   */
  const validateToDate = (date) => {
    if (!date) {
      setToDate(date);
      return;
    }
    if (fromDate <= date) {
      handlePageChange(1, page.PageSize);
      setToDate(date);
    }
  };

  /**
   * Function to fetch and view donation details.
   * @param {string} referenceId - The reference ID of the transaction.
   * @param {string} transactionStatus - The status of the transaction.
   */
  const viewDonationDetails = async (referenceId, transactionStatus) => {
    setIsPageLoading(true);
    setTransaction({});

    try {
      const response = await getData(
        `/api/payment/get-allocation-details/${referenceId}`,
        null,
        userSession
      );
      if (response) {
        response.allocations = transformProfilePicture(response.allocations);
        setTransaction(response);
        openAllocationModal();
      }
      // Show a notification if transaction is not to be found
      else
        showNotification(
          "danger",
          "At the moment, there isn't any information available regarding donations for viewing.",
          5000
        );
      setIsPageLoading(false);
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setTransaction({});
      setIsPageLoading(false);
    }
  };

  /**
   * Function to fetch and view receipt details.
   * @param {string} referenceId - The reference ID of the transaction.
   */
  const viewReceipt = async (referenceId) => {
    setIsPageLoading(true);
    setReceipt({});
    try {
      const response = await getData(
        `/api/payment/get-receipt/${referenceId}`,
        null,
        userSession
      );
      if (Object.keys(response).length > 0) {
        setReceipt(response);
        openReceiptModal();
      }
      setIsPageLoading(false);
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setReceipt({});
      setIsPageLoading(false);
    }
  };

  /**
   * Function to bind a status to a corresponding badge with appropriate background color.
   * @param {string} status - The status to be bound to a badge.
   * @returns {JSX.Element} - A Badge component representing the status with appropriate background color.
   */
  const bindNeedStatus = (status) => {
    let background;
    switch (status) {
      case "Draft":
        background = "light";
        break;
      case "Awaiting Approval":
        background = "warning";
        break;
      case "Published":
        background = "info";
        break;
      case "Funded":
        background = "success";
        break;
      case "Expired":
      case "Closed":
        background = "danger";
        break;
      default:
        background = "dark";
        break;
    }
    return (
      <Badge className="fw-semibold text-dark" bg={background}>
        {status.toUpperCase()}
      </Badge>
    );
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main>
      <Container fluid className="transactions">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h6 className="module-title fw-semibold mb-0">Transactions</h6>
          <Button variant="primary" onClick={openExportModal}>
            <i className="flaticon-downloads me-2"></i>Export
          </Button>
        </div>

        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <Row className="align-items-center justify-content-center">
              <Col
                xs={12}
                sm={3}
                md={"auto"}
                className="text-center text-sm-end text-md-start my-1"
              >
                <span className="form-label">Select Date Range: </span>
              </Col>
              <Col xs={5} sm={"auto"} className="my-1">
                <InputGroup className="date-range-picker">
                  <InputGroup.Text className="bg-transparent border-end-0">
                    <i className="flaticon-calendar"></i>
                  </InputGroup.Text>
                  {/* Date picker component */}
                  <DatePicker
                    selected={fromDate}
                    onChange={(date) => {
                      handlePageChange(1, page.PageSize);
                      setFromDate(date);
                    }}
                    selectsStart
                    startDate={fromDate}
                    endDate={toDate}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="From Date"
                    isClearable
                    className="form-control border-start-0 rounded-start-0"
                  />
                </InputGroup>
              </Col>
              <Col xs={5} sm={"auto"} className="my-1">
                <InputGroup className="date-range-picker">
                  <InputGroup.Text className="bg-transparent border-end-0">
                    <i className="flaticon-calendar"></i>
                  </InputGroup.Text>
                  {/* Date picker component */}
                  <DatePicker
                    selected={toDate}
                    onChange={(date) => {
                      validateToDate(date);
                    }}
                    selectsEnd
                    startDate={fromDate}
                    endDate={toDate}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="To Date"
                    isClearable
                    className="form-control border-start-0 rounded-start-0"
                  />
                </InputGroup>
              </Col>
              <Col
                xs={"auto"}
                sm={4}
                md={3}
                lg={3}
                xl={3}
                xxl={2}
                className="my-1"
              >
                <InputGroup>
                  <InputGroup.Text className="bg-transparent border-end-0">
                    <i className="flaticon-search"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    className="bg-transparent border-start-0"
                    placeholder="Search..."
                    onKeyUp={(e) => {
                      if (e.target.value.length > 3 || !e.target.value)
                        handleSearch(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
            </Row>

            <hr className="my-4" />
            {/* DataTable component for listing transactions */}
            <DataTable
              columns={columns}
              data={transactions}
              totalRecords={totalRecords}
              page={page}
              onPageChange={handlePageChange}
              loadingState={isLoading}
            />
          </Card.Body>
        </Card>
      </Container>
      {/* Component for exporting  transactions */}
      <Export
        featureName="Transactions"
        searchParams={constructSearchQuery()}
        showExportModal={showExportModal}
        closeExportModal={closeExportModal}
        showNotification={showNotification}
      />
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
      {/* Modal for displaying allocation details for donation */}
      <Modal
        show={displayAllocationModal}
        aria-labelledby="Donation Details Modal"
        className="modal-donation-details"
        dialogClassName="modal-feature"
        centered
      >
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="fw-semibold mb-0">Donation Details</h6>
            <Button
              className="btn-close fs-small"
              variant="link"
              aria-label="Close"
              onClick={closeAllocationModal}
            ></Button>
          </div>
          <hr />
          <ListGroup as="ul" className="mt-4">
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-center bg-transparent px-3 py-2 fs-small"
            >
              <span>Reference ID:</span>
              <span>{transaction.referenceId}</span>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-center bg-transparent px-3 py-2 fs-small"
            >
              <span>Total Donation Amount:</span>
              <span>${transaction.donationAmount}</span>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-center bg-transparent px-3 py-2 fs-small"
            >
              <span>Additional Gift:</span>
              <span>${transaction.contribution}</span>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-center bg-transparent px-3 py-2 fs-small"
            >
              <span>Total Transaction Amount:</span>
              <span>${transaction.transactionAmount}</span>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-center bg-transparent px-3 py-2 fs-small"
            >
              <span>Processing Fee:</span>
              <span>${transaction.processingFee || 0}</span>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-center bg-transparent px-3 py-2 fs-small"
            >
              <span>Total Net Amount:</span>
              <span>${transaction.netAmount}</span>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-center bg-transparent px-3 py-2 fs-small"
            >
              <span>Source: </span>
              <span>{transaction.source}</span>
            </ListGroup.Item>
            {transaction.hasTribute && (
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-center bg-transparent px-3 py-2 fs-small"
              >
                <span>Tribute:</span>
                <span>
                  <i className="flaticon-tick fs-3 d-inline-block text-center bg-secondary text-white rounded-circle hasTribute"></i>
                </span>
              </ListGroup.Item>
            )}
            {transaction.allocations?.map((allocation) => (
              <ListGroup.Item
                as="li"
                key={key(allocation)}
                className="d-flex justify-content-between align-items-center bg-transparent p-3"
              >
                <div className="d-flex align-items-center">
                  <Image
                    src={allocation.profilePicture}
                    alt="Recipient"
                    roundedCircle
                    thumbnail
                    fluid
                    className="avatar"
                  />
                  <Link
                    to={`${NeedProfileURL}${allocation.referenceId}/${allocation.needId}`}
                    title={allocation.title}
                    className="text-decoration-none mx-3 text-dark fw-semibold"
                  >
                    {allocation.recipient}
                    <small className="d-block fw-light mt-1">
                      {allocation.title}
                    </small>
                    <small className="d-block fw-light mt-1">
                      [Donation Amount: <b>${allocation.donationAmount}</b> Net
                      Amount: <b>${allocation.netAmount}</b>]
                    </small>
                  </Link>
                </div>
                <div>{bindNeedStatus(allocation.needStatus)}</div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
      </Modal>
      {/* Modal for displaying the donation receipt */}
      <Modal
        show={displayReceiptModal}
        aria-labelledby="Receipt Modal"
        className="modal-receipt"
        dialogClassName="modal-feature"
        contentClassName="bg-transparent border-0"
        centered
      >
        <Modal.Body className="p-0">
          <Receipt
            receipt={receipt}
            hasToPrint={hasToPrint}
            setHasToPrint={setHasToPrint}
          />
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="primary"
            onClick={closeReceiptModal}
            className="btn-md"
          >
            Close
          </Button>
          <Button
            variant="secondary"
            onClick={() => setHasToPrint(true)}
            className="mx-3 btn-md"
          >
            Print Receipt
          </Button>
        </Modal.Footer>
      </Modal>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
    </main>
  );
};

export default ManageTransactions;
