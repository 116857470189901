import React, { Fragment, useState, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Carousel,
  ListGroup,
  Form,
  FloatingLabel,
  Button,
  Image,
  Alert,
  Spinner,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

import { transformProfilePicture } from "../../../components/transformFileURL";

import { getData, postData } from "../../../services/apiService";
import { notify, initialState } from "../../../store/notification";

import { SITEURL } from "../../../constants";

/**
 * Component for rendering validation statement form.
 * @param {object} props - Component props.
 * @param {object} props.userSession - User session object.
 * @param {object} props.need - Object containing need information.
 * @param {function} props.processResult - Function to process form result.
 * @param {function} props.handleActiveTab - Function to handle active tab.
 * @returns {React.Element} - Returns JSX for validation statement form
 */
const ValidationStatement = (props) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  const { userSession } = props;
  const [loadingStates, setLoadingStates] = useState({});
  const [validators, setValidators] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const numSlides = 3;
  const [index, setIndex] = useState(0); // State to track the active slide index

  useEffect(() => {
   /**
   * Function to filter fields from the need object.
   * @param  {...string} keys - Keys to be included in the filtered object.
   * @returns {object} - Filtered object containing specified keys.
   */
    const filterFields = (...keys) => {
      const targetObject = Object.assign({});
      Object.keys(props.need).forEach((key) => {
        if (keys.includes(key)) {
          targetObject[key] = props.need[key];
        }
      });
      return targetObject;
    };

    // Reset form fields
    reset(
      filterFields(
        "needId",
        "needStatus",
        "validationStatement",
        "fundTransferType",
        "notes",
        "validatorId",
        "organizationId",
        "validatorProfilePicture"
      )
    );
    setValidators([
      {
        label: props.need.validatorName,
        value: props.need.validatorId,
        profilePicture: transformProfilePicture(
          props.need.validatorProfilePicture
        ),
        organizationId: props.need.organizationId,
        organizationName: props.need.organizationName,
      },
    ]);
    if (!props.need.fundTransferType)
      setValue("fundTransferType", "Electronic prepaid card (recommended)");
  }, [ props.need, reset, setValue]);

  /**
   * Function to update the validation statement using the backend API
   * @param {Object} data - The form data.
   * @param {Object} e - The event object containing information about the form submission.
   */
  const onSubmit = async (data, e) => {
    // Extract the button type from the submit event
    const buttonType = e.nativeEvent.submitter.dataset.buttonType;
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [buttonType]: true,
    }));
    try {
      const response = await postData(
        "/api/need/upsert-validation-statement",
        data,
        userSession
      );
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [buttonType]: false,
      }));

      // Update the need data and submission status
      setValue("needId", response.needId);
      data.needId = response.needId;
      props.processResult({
        need: data,
        hasBeenSubmitted: false,
      });

      // Determine the next active tab based on button type
      if (buttonType === "Draft")
        showNotification("success", response.message, 5000);
      else props.handleActiveTab("photos");
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [buttonType]: false,
      }));
    }
  };

  useEffect(() => {
    /**
     * Fetches the validators from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const searchValidators = async () => {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        "search-validator": true,
      }));
      try {
        const response = await getData(
          `/api/validator/search?searchTerm=${searchTerm}`,
          null,
          userSession
        );
        const updatedValidators = transformProfilePicture(response);
        setValidators(updatedValidators);
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          "search-validator": false,
        }));
      } catch (error) {
        showNotification("danger", error, 5000);
        setValidators([]);
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          "search-validator": false,
        }));
      }
    };
    if (searchTerm) searchValidators();
    else setValidators([]);
  }, [searchTerm, userSession]);

  /**
   * Function to update the search term state.
   * @param {string} newValue - The new value entered by the user in the search input.
   */
  const handleSearchInput = (newValue) => {
    setSearchTerm(newValue);
  };

  /**
   * Custom filter function for filtering options in the validator dropdown.
   * @param {Object} option - The option to be filtered.
   * @param {string} searchText - The text to search for.
   * @returns {boolean} - Indicates whether the option matches the search text.
   */
  const customFilterOption = (option, searchText) => {
    return option.data.label.toLowerCase().includes(searchText.toLowerCase());
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Control type="hidden" {...register("needId")} />
        <Form.Control type="hidden" {...register("needStatus")} />
        <Form.Control type="hidden" {...register("organizationId")} />
        <Row className="align-items-center my-4">
          <Col xs="auto">
            <h6>
              Validation Statement{" "}
              <small className="text-light-emphasis fw-light">
                (Visible to the public)
              </small>
            </h6>
          </Col>
          <Col>
            <hr className="m-0" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            {props.roles.includes("SuperAdmin") ? (
              <Form.Group className="mb-4">
                <Controller
                  control={control}
                  name="validatorId"
                  id="ddValidator"
                  rules={{ required: "Validator is required" }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      // menuIsOpen
                      isSearchable={true}
                      isClearable={true}
                      isLoading={loadingStates["search-validator"]}
                      placeholder="Validators"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      onInputChange={handleSearchInput}
                      options={validators}
                      value={validators.find((c) => c.value === value)}
                      onChange={(val) => {
                        onChange(val?.value || "");
                        setValue("organizationId", val?.organizationId || "");
                      }}
                      noOptionsMessage={() => "No results found"}
                      filterOption={customFilterOption}
                      getOptionLabel={(e) => (
                        <Fragment>
                          <Image
                            src={e.profilePicture}
                            alt={e.label}
                            roundedCircle
                            fluid
                            width="35"
                            className="me-2 align-middle"
                          />
                          <span className="fw-semibold">
                            {e.label}{" "}
                            <small className="fw-light">
                              {" "}
                              from {e.organizationName}
                            </small>
                          </span>
                        </Fragment>
                      )}
                    />
                  )}
                />
                {errors.validatorId && (
                  <Form.Text className="text-danger">
                    {errors.validatorId.message}
                  </Form.Text>
                )}
              </Form.Group>
            ) : (
              <Form.Control type="hidden" {...register("validatorId")} />
            )}
            <Form.Group className="mb-4">
              <FloatingLabel
                controlId="txtValidationStatement"
                htmlFor="txtValidationStatement"
                label="Statement"
              >
                <Form.Control
                  as="textarea"
                  className="scroll"
                  placeholder="Statement"
                  {...register("validationStatement", {
                    required: "Validation statement is required",
                    pattern: {
                      value:
                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>)[\s\S])*$/i,
                      message:
                        "Validation statement shouldn't contain HTML or Script tags",
                    },
                    maxLength: {
                      value: 3000,
                      message:
                        "Validation statement cannot exceed 3000 characters",
                    },
                  })}
                />
              </FloatingLabel>
              <Form.Text muted>
                Describe how you know and have been working with the recipient.
                Remember to use the recipient's display name.
              </Form.Text>
              {errors.validationStatement && (
                <Form.Text className="text-danger d-block">
                  {errors.validationStatement.message}
                </Form.Text>
              )}
            </Form.Group>
            <h6 className="fw-semibold">Sending you grant funds</h6>
            <Form.Group className="my-4">
              <FloatingLabel
                controlId="ddFundTransferType"
                label="Fund Transfer Type"
              >
                <Form.Select
                  name="fundTransferType"
                  aria-label="Fund Transfer Type"
                  {...register("fundTransferType", {
                    required: "Fund transfer type is required",
                  })}
                >
                  <option value="Electronic prepaid card (recommended)">
                    Electronic prepaid card (recommended)
                  </option>
                  <option value="Physical prepaid card (mailed to you at your organization)">
                    Physical prepaid card (mailed to you at your organization)
                  </option>
                  <option value="ACH Deposit (deposited into your organization's bank account)">
                    ACH Deposit (deposited into your organization's bank
                    account)
                  </option>
                  <option value="Check (mailed to your organization)">
                    Check (mailed to your organization)
                  </option>
                </Form.Select>
              </FloatingLabel>
              <Form.Text muted>
                If we raise the funds, how should we send the grant to you for
                your client's need?
              </Form.Text>
              {errors.fundTransferType && (
                <Form.Text className="text-danger d-block">
                  {errors.fundTransferType.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-4">
              <FloatingLabel
                controlId="txtNotes"
                htmlFor="txtNotes"
                label="Notes"
              >
                <Form.Control
                  as="textarea"
                  className="scroll"
                  placeholder="Notes"
                  {...register("notes", {
                    pattern: {
                      value:
                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>)[\s\S])*$/i,
                      message: "Notes shouldn't contain HTML or Script tags",
                    },
                    maxLength: {
                      value: 3000,
                      message: "Notes cannot exceed 3000 characters",
                    },
                  })}
                />
              </FloatingLabel>
              <Form.Text muted>
                Tell us why you need an ACH deposit! Does you chosen vendor not
                accept credit cards? (Please be sure your organization is signed
                up for ACH with Benevolent)
              </Form.Text>
              {errors.notes && (
                <Form.Text className="text-danger d-block">
                  {errors.notes.message}
                </Form.Text>
              )}
            </Form.Group>
            <div>
              <Button
                type="submit"
                variant="primary"
                data-button-type="Continue"
                disabled={loadingStates["Continue"]}
                className="btn-md me-3"
              >
                {loadingStates["Continue"] ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  "Save & Continue"
                )}
              </Button>
              <Button
                variant="secondary"
                type="submit"
                data-button-type="Draft"
                disabled={loadingStates["Draft"]}
                className="btn-md"
              >
                {loadingStates["Draft"] ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  "Save & Finish Later"
                )}
              </Button>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <Card className="border-0 mb-4">
              <ListGroup
                variant="flush"
                className="border-start border-5 border-secondary fs-small"
              >
                <ListGroup.Item className="border-0 pt-3 pb-1 fw-semibold text-dark-emphasis">
                  Tips:
                </ListGroup.Item>
                <ListGroup.Item className="border-0 pb-3">
                  <Link
                    to={`${SITEURL}/faq/#client-support?q=7`}
                    title="FAQ"
                    className="text-decoration-none"
                  >
                    What's a validation statement?
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </Card>
            <Card className="mb-4 story-carousel">
              <Card.Header className="mx-3 px-0 bg-transparent text-dark-emphasis fw-semibold">
                Examples
              </Card.Header>
              <Card.Body>
                <Carousel
                  variant="dark"
                  interval={null}
                  indicators={false}
                  controls={false}
                  activeIndex={index}
                >
                  <Carousel.Item>
                    <Carousel.Caption className="text-dark-emphasis fs-small scroll">
                      <p className="mb-2">
                        I have known Al through the employment project here at
                        Inspiration Corporation; it's a 4-week job readiness
                        workshop. In the program, you’re assigned a career
                        specialist who works with you to get you ready, so I
                        work with anyone who needs it. I worked with Al on his
                        housing goals, helped him get glasses, and now I want to
                        help him get the uniform he needs. He is so close to
                        getting the position with this banquet company and all
                        he needs is a tuxedo. Al has always been so honest and
                        clear about what he’s looking for, and it would be great
                        to see him receive this help.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Carousel.Caption className="text-dark-emphasis fs-small scroll">
                      <p className="mb-2">
                        I have known Jetaun for over a year since she first
                        enrolled in JARC’s welding training program. Jetaun
                        continues to be in touch with JARC staff and involved
                        with our services and programs.
                      </p>

                      <p className="mb-2">
                        I think meeting this need is important because Jetaun
                        has the skills and determination to be self-sufficient
                        but she just needs a little help to get on her feet. I
                        can’t wait to see her in her very own apartment, taking
                        care of her son and providing stability for both of
                        them!
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Carousel.Caption className="text-dark-emphasis fs-small scroll">
                      <p className="mb-2">
                        I have known Shavon for a little under a year. I first
                        met Shavon when she came in for an interview and
                        assessment at a CNA job fair. It’s been a blessing to
                        meet her.
                      </p>
                      <p className="mb-2">
                        I think it is vitally important for Shavon to not only
                        meet the financial needs of her family but to ensure
                        that they have a bright future. By securing employment,
                        Shavon will stabilize her life. All she needs to do that
                        is to get her Nursing Aide License back.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </Card.Body>
              <Card.Footer className="bg-transparent">
                <Button
                  type="button"
                  variant="link"
                  size="sm"
                  onClick={() =>
                    setIndex((prevIndex) =>
                      prevIndex === 0 ? numSlides - 1 : prevIndex - 1
                    )
                  }
                >
                  <i className="flaticon-back fs-5 me-1"></i>PREVIOUS
                </Button>
                <Button
                  type="button"
                  variant="link"
                  size="sm"
                  onClick={() =>
                    setIndex((prevIndex) =>
                      prevIndex === numSlides - 1 ? 0 : prevIndex + 1
                    )
                  }
                >
                  NEXT<i className="flaticon-next fs-5 ms-1 align-middle"></i>
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
    </Fragment>
  );
};

export default ValidationStatement;
