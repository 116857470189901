import React, { useState, useReducer, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Form,
  Button,
  InputGroup,
  Spinner,
  Alert,
  FloatingLabel,
} from "react-bootstrap";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import Select from "react-select";

import { transformProfilePicture } from "../../components/transformFileURL";

import { getData, postData } from "../../services/apiService";
import { notify, initialState } from "../../store/notification";
import { useAuthContext } from "../../context/authProvider";

/**
 * Component for recommending validators.
 * Allows validator to recommend more validators from the organizations they belong to or other organizations.
 * @returns {React.Element} - Returns JSX for recommending validators.
 * @access Accessible by Validator
 */
const RecommendValidator = () => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      validator: [{}],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "validator",
  });
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  const [loadingStates, setLoadingStates] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    /**
     * Function to fetch list of organizations from the backend API
     * For Validator, get organizations belonging to them
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getOrganizationsForValidator = async () => {
      try {
        const response = await getData(
          `/api/organization/getOrganizations`,
          null,
          userSession
        );
        setOrganizations(response);
        // If there are organizations, select the first one by default
        if (response.length > 0) {
          setValue(`validator.${0}.organizationName`, response[0].label || "");
          setValue(`validator.${0}.organizationId`, response[0].value || "");
        }
      } catch (error) {}
    };

    getOrganizationsForValidator();
  }, [userSession, setValue]);

  /**
   * Function to handle form submission for recommending validators.
   * API sends confirmation email for all valid recommendations.
   * @param {Object} data - The form data contains the recommended validators.
   */
  const onSubmit = async (data) => {
    var inputs = Object.entries(data);
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      isSubmitted: true,
    }));
    try {
      const response = await postData(
        `/api/invitation/recommend-validators`,
        inputs[0][1],
        userSession
      );
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        isSubmitted: false,
      }));
      reset();
      // Select the organization for the first validator after resetting the form
      setValue(`validator.${0}.organizationName`, organizations[0].label || "");
      setValue(`validator.${0}.organizationId`, organizations[0].value || "");
      showNotification("success", response, 5000);
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        isSubmitted: false,
      }));
    }
  };

  /**
   * Function to check the availability of the provided email address using the backend API
   * @param {string} value - The email address to check availability for.
   * @returns {boolean|Error} - Returns true if the email is available, or an Error object if the email is not available or an error occurs.
   */
  const verifyEmailAvailability = async (value) => {
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      isValidEmail: true,
    }));
    try {
      await postData(
        `/api/invitation/verify-email-availability/${value}`,
        null,
        userSession
      );
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        isValidEmail: false,
      }));
      return true;
    } catch (error) {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        isValidEmail: false,
      }));
      return error;
    }
  };

  useEffect(() => {
    /**
     * Fetches the organizations from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const searchOrganization = async () => {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        inSearch: true,
      }));
      try {
        const response = await getData(
          `/api/organization/search?searchTerm=${searchTerm}`,
          null,
          null
        );

        setOrganizations(response);
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          inSearch: false,
        }));
      } catch (error) {
        showNotification("danger", error, 5000);
        setOrganizations([]);
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          inSearch: false,
        }));
      }
    };
    if (searchTerm) searchOrganization();
    else setOrganizations([]);
  }, [searchTerm]);

  /**
   * Function to update the search term state.
   * @param {string} newValue - The new value entered by the user in the search input.
   */
  const handleSearchInput = (newValue) => {
    setSearchTerm(newValue);
  };

  /**
   * Custom filter function for filtering options in the organization dropdown.
   * @param {Object} option - The option to be filtered.
   * @param {string} searchText - The text to search for.
   * @returns {boolean} - Indicates whether the option matches the search text.
   */
  const customFilterOption = (option, searchText) => {
    return option.data.label.toLowerCase().includes(searchText.toLowerCase());
  };

  /**
   * Adds a new validator field to the form and initializes it with the default organization values.
   */
  const addValidatorField = () => {
    append({});
    // Select the default organization for the validator at the specified index
    setValue(
      `validator.${fields.length}.organizationName`,
      organizations[0].label || ""
    );
    setValue(
      `validator.${fields.length}.organizationId`,
      organizations[0].value || ""
    );
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-bold mb-0">Recommend Validator</h6>
          <Link to="/needs" className="ms-3 btn btn-secondary" title="Back">
            <i className="flaticon-line-arrow-left pe-2"></i>
            Back
          </Link>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="align-items-center">
                <Col>
                  <Form.Text>
                    Encourage your colleagues to join Benevolent! Who else do
                    you think would benefit from utilizing Benevolent? Please
                    share your suggestions with us
                  </Form.Text>
                </Col>
                <Col xs={12} sm={6} lg={4} xl={3} className="text-end">
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => addValidatorField()}
                  >
                    + Add colleague
                  </Button>
                </Col>
              </Row>
              <hr className="my-4" />

              <Row className="align-items-center">
                {fields.map((field, index) => {
                  return (
                    <Col xs={12} xl={6} key={field.id}>
                      <Row className="align-items-center mb-4">
                        <Col xs="auto">
                          <h6 className="fw-semibold">
                            Validator {index + 1}{" "}
                            {index !== 0 && (
                              <Button
                                variant="link"
                                type="button"
                                className="link-secondary fw-semibold p-1"
                                onClick={() => remove(index)}
                              >
                                [Remove]
                              </Button>
                            )}
                          </h6>
                        </Col>
                        <Col>
                          <hr className="m-0" />
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Col xs={12} sm={6} className="mb-4">
                          <Form.Control
                            type="hidden"
                            {...register(`validator.${index}.organizationName`)}
                          />
                          <InputGroup>
                            <FloatingLabel
                              label="Firstname"
                              controlId="txtFirstName"
                              htmlFor="txtFirstName"
                            >
                              <Form.Control
                                type="text"
                                placeholder="First"
                                {...register(`validator.${index}.firstName`, {
                                  required: "First name is required",
                                  pattern: {
                                    value:
                                      /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                    message:
                                      "First name shouldn't contain HTML or script tags",
                                  },
                                  maxLength: {
                                    value: 50,
                                    message:
                                      "First name cannot exceed 50 characters",
                                  },
                                })}
                              />
                            </FloatingLabel>
                            <InputGroup.Text>
                              <i className="flaticon-profile fs-4"></i>
                            </InputGroup.Text>
                          </InputGroup>
                          {errors.validator?.[index]?.firstName && (
                            <Form.Text className="text-danger">
                              {errors.validator?.[index]?.firstName?.message}
                            </Form.Text>
                          )}
                        </Col>
                        <Col xs={12} sm={6} className="mb-4">
                          <InputGroup>
                            <FloatingLabel
                              label="Lastname"
                              controlId="txtLastName"
                              htmlFor="txtLastName"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Lastname"
                                {...register(`validator.${index}.lastName`, {
                                  required: "Last name is required",
                                  pattern: {
                                    value:
                                      /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                    message:
                                      "Last name shouldn't contain HTML or script tags",
                                  },
                                  maxLength: {
                                    value: 50,
                                    message:
                                      "Last name cannot exceed 50 characters",
                                  },
                                })}
                              />
                            </FloatingLabel>
                            <InputGroup.Text>
                              <i className="flaticon-profile fs-4"></i>
                            </InputGroup.Text>
                          </InputGroup>
                          {errors.validator?.[index]?.lastName && (
                            <Form.Text className="text-danger">
                              {errors.validator?.[index]?.lastName?.message}
                            </Form.Text>
                          )}
                        </Col>

                        <Col xs={12} sm={6} className="mb-4">
                          <InputGroup>
                            <FloatingLabel
                              label="Email"
                              controlId="txtEmail"
                              htmlFor="txtEmail"
                            >
                              <Form.Control
                                type="email"
                                placeholder="Email"
                                {...register(
                                  `validator.${index}.emailAddress`,
                                  {
                                    required: "Email is required",
                                    maxLength: 256,
                                    pattern: {
                                      value:
                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: "Invalid email address",
                                    },
                                    validate: verifyEmailAvailability,
                                  }
                                )}
                              />
                            </FloatingLabel>
                            {loadingStates["isValidEmail"] ? (
                              <InputGroup.Text>
                                <Spinner animation="border" role="status">
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </Spinner>
                              </InputGroup.Text>
                            ) : (
                              <InputGroup.Text>
                                <i className="flaticon-mail fs-4"></i>
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                          {errors.validator?.[index]?.emailAddress && (
                            <Form.Text className="text-danger">
                              {errors.validator?.[index]?.emailAddress?.message}
                            </Form.Text>
                          )}
                        </Col>

                        <Col xs={12} sm={6} className="mb-4">
                          <InputGroup>
                            <FloatingLabel
                              label="Job Title"
                              controlId="txtJobTitle"
                              htmlFor="txtJobTitle"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Job Title"
                                {...register(`validator.${index}.jobTitle`, {
                                  required: "Job title is required",
                                  pattern: {
                                    value:
                                      /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                    message:
                                      "Job title shouldn't contain HTML or script tags",
                                  },
                                  maxLength: {
                                    value: 100,
                                    message:
                                      "Job title cannot exceed 100 characters",
                                  },
                                })}
                              />
                            </FloatingLabel>
                            <InputGroup.Text>
                              <i className="flaticon-suitcase fs-4"></i>
                            </InputGroup.Text>
                          </InputGroup>
                          {errors.validator?.[index]?.jobTitle && (
                            <Form.Text className="text-danger">
                              {errors.validator?.[index]?.jobTitle?.message}
                            </Form.Text>
                          )}
                        </Col>
                        <Col xs={12} sm={6} className="mb-4">
                          <Controller
                            control={control}
                            name={`validator.${index}.organizationId`}
                            id={`ddOrg${index}`}
                            rules={{ required: "Organization is required" }}
                            render={({ field: { onChange, value } }) => (
                              <Select
                                // menuIsOpen
                                isSearchable={true}
                                isClearable={true}
                                isDisabled
                                isLoading={loadingStates["inSearch"]}
                                placeholder="Organization"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                onInputChange={handleSearchInput}
                                options={organizations}
                                value={organizations.find(
                                  (c) => c.value === value
                                )}
                                onChange={(val) => {
                                  onChange(val?.value || "");
                                  setValue(
                                    `validator.${index}.organizationName`,
                                    val?.label || ""
                                  );
                                }}
                                noOptionsMessage={() => "No results found"}
                                filterOption={customFilterOption}
                                getOptionLabel={(e) => (
                                  <Fragment>
                                    <Image
                                      src={transformProfilePicture(
                                        e.profilePicture
                                      )}
                                      alt={e.label}
                                      roundedCircle
                                      fluid
                                      width="35"
                                      className="me-2 align-middle"
                                    />
                                    <span>{e.label}</span>
                                  </Fragment>
                                )}
                              />
                            )}
                          />
                          {errors.validator?.[index]?.organizationId && (
                            <Form.Text className="text-danger">
                              {
                                errors.validator?.[index]?.organizationId
                                  ?.message
                              }
                            </Form.Text>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
              </Row>

              <div className="d-block mt-4">
                <Button
                  variant="primary"
                  disabled={loadingStates["isSubmitted"]}
                  type="submit"
                  className="btn-md"
                >
                  {loadingStates["isSubmitted"] ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    "Recommend"
                  )}
                </Button>

                <Button
                  variant="secondary"
                  type="button"
                  className="ms-3 btn-md"
                  onClick={() => reset()}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
    </main>
  );
};

export default RecommendValidator;
