import React, { useState, useEffect, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Card,
  Nav,
  TabContainer,
  Tab,
  Modal,
  Spinner,
} from "react-bootstrap";

import ManageValidators from "./widgets/_validators";
import ManageNeeds from "./widgets/_needs";
import { transformProfilePicture } from "../../components/transformFileURL";

import { getData } from "../../services/apiService";
import { useAuthContext } from "../../context/authProvider";
import { OrgProfileURL } from "../../constants";

/**
 * Component for displaying organization's private profile.
 * This profile is accessible exclusively to SuperAdmins and Staff members who have logged in.
 * @returns {React.Element} - Returns JSX for organization's private profile.
 */
const OrganizationPrivateProfile = () => {
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();

  const [isPageLoading, setIsPageLoading] = useState({});
  const [activeTab, setActiveTab] = useState("validators");
  const [organization, setOrganization] = useState("");

  const { referenceId } = useParams();

  /**
   * Function to handle the activation of a tab.
   * @param {string} tab - The identifier of the tab to activate.
   */
  const handleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    /**
     * Fetches the organization's profile information from the backend API.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getOrgProfile = async () => {
      setIsPageLoading(true);
      try {
        const response = await getData(
          `/api/organization/getByIdOrReferenceId/${referenceId}`,
          null,
          userSession
        );
        if (response.address) {
          response.formattedAddress = Object.entries(response.address)
            .filter(
              ([key, value]) =>
                key !== "phoneNumber" && value !== null && value !== ""
            )
            .map(([key, value]) => value)
            .join(", ");
        }
        setOrganization(response);
        setIsPageLoading(false);
      } catch (error) {
        setOrganization({});
        setIsPageLoading(false);
      }
    };
    if (referenceId) getOrgProfile();
  }, [referenceId, userSession]);

  return (
    <main>
      <Container fluid className="organization-private-profile">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-bold mb-0">Profile</h6>
          <div>
            <Link
              to="/organization"
              className="ms-3 btn btn-secondary"
              title="Back"
            >
              <i className="flaticon-line-arrow-left pe-2"></i>
              Back
            </Link>
          </div>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <Row>
              <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={3}>
                <Card className="basic-info shadow-sm">
                  <Image
                    src={transformProfilePicture(organization.profilePicture)}
                    alt="Profile"
                    roundedCircle
                    fluid
                  />
                  <Card.Body className="pt-0">
                    <Nav className="justify-content-center my-2">
                      <Link
                        to={organization.website}
                        target="_blank"
                        className="nav-link"
                      >
                        <i className="flaticon-link fs-4"></i>
                      </Link>
                      <Link
                        title="Public Profile"
                        to={`${OrgProfileURL}${referenceId}`}
                        className="nav-link"
                      >
                        <i className="flaticon-internet fs-4"></i>
                      </Link>
                      <Link
                        title="Edit Profile"
                        to={`/organization/edit/${organization.organizationId}`}
                        className="nav-link"
                      >
                        <i className="flaticon-edit fs-4"></i>
                      </Link>
                    </Nav>
                    <h6 className="mb-2">Primary Contact</h6>
                    <div className="fw-light fs-small mb-3">
                      <p className="mb-1">
                        <i className="flaticon-kid me-2 fs-4"></i>
                        {organization.primaryContact}
                      </p>
                      <p className="mb-1">
                        <Link
                          className="text-decoration-none"
                          href={`mailto:${organization.primaryEmail}`}
                        >
                          <i className="flaticon-envelope me-2 fs-5"></i>
                          {organization.primaryEmail}
                        </Link>
                      </p>
                      <p className="mb-0">
                        <Link
                          className="text-decoration-none"
                          href={`tel:${organization.address?.phoneNumber}`}
                        >
                          <i className="flaticon-smartphone me-2 fs-5"></i>
                          {organization.address?.phoneNumber}
                        </Link>
                      </p>
                    </div>
                    <hr className="divider my-4" />
                    <h6 className="mb-2">Address</h6>
                    <div className="fw-light fs-small mb-3 d-flex">
                      <i className="flaticon-location me-2 fs-5"></i>
                      <p className="mb-0">{organization.formattedAddress}</p>
                    </div>

                    {(organization.accountantName ||
                      organization.accountantEmail) && (
                      <Fragment>
                        <hr className="divider my-4" />
                        <h6 className="mb-2">Accounting Contact Info</h6>
                        <div className="fw-light fs-small mb-3">
                          {organization.accountantName && (
                            <p className="mb-1">
                              <i className="flaticon-kid me-2 fs-4"></i>
                              {organization.accountantName}
                            </p>
                          )}
                          {organization.accountantEmail && (
                            <Link
                              className="text-decoration-none"
                              to={`mailto:${organization.accountantEmail}`}
                            >
                              <i className="flaticon-envelope me-2 fs-5"></i>
                              {organization.accountantEmail}
                            </Link>
                          )}
                        </div>
                      </Fragment>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={8}
                xl={8}
                xxl={9}
                className="mt-3"
              >
                <Row className="align-items-center mb-3">
                  <Col xs="auto">
                    <h4 className="title mb-0">About {organization.name}</h4>
                  </Col>
                  <Col>
                    <hr className="m-0" />
                  </Col>
                </Row>
                <div>
                  <p className="text-dark-emphasis fs-small biography">
                    {organization.description}
                  </p>
                </div>
                <TabContainer
                  id="organization-tabs"
                  defaultActiveKey="need-info"
                  activeKey={activeTab}
                  onSelect={(tab) => handleActiveTab(tab)}
                >
                  <Nav variant="tabs" fill className="border-bottom">
                    <Nav.Item>
                      <Nav.Link eventKey="validators" className="fw-semibold">
                        <i className="flaticon-user-list pe-2 fs-3"></i>
                        <span className="align-text-bottom">Validators</span>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="needs" className="fw-semibold">
                        <i className="flaticon-need pe-2 fs-3"></i>
                        <span className="align-text-bottom">Needs</span>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="validators" className="py-4">
                      <ManageValidators
                        organizationId={organization?.organizationId}
                        organizationName={organization?.name}
                        organizationProfilePicture={
                          organization?.profilePicture
                        }
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="needs" className="py-4">
                      <ManageNeeds referenceId={referenceId} />
                    </Tab.Pane>
                  </Tab.Content>
                </TabContainer>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default OrganizationPrivateProfile;
