import React, { Fragment, useState, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Card,
  Dropdown,
  Button,
  Alert,
  Modal,
  Spinner,
  Image,
  Badge,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import DataTable from "../../components/table";
import { transformProfilePicture } from "../../components/transformFileURL";

import { postData } from "../../services/apiService";
import { useAuthContext } from "../../context/authProvider";
import { notify, initialState } from "../../store/notification";

import confirmation from "../../resources/images/confirmation.png";

/**
 * Component for managing organizations (add, edit, delete).
 * @returns {React.Element} - Returns JSX for managing organizations.
 */
const ManageOrganizations = () => {
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [page, setPage] = useState({ PageNumber: 1, PageSize: 25 });
  const [organizations, setOrganizations] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  // Columns configuration for the organization table
  const columns = [
    {
      header: "Name",
      accessorKey: "name",
      cell: (cellProps) => {
        return (
          <Fragment>
            <Link
              to={`/organization/profile/${cellProps.row.original.referenceId}`}
              className="text-decoration-none d-flex align-items-center text-start"
              title="Organization Profile"
            >
              <div className="flex-shrink-0">
                <Image
                  src={transformProfilePicture(
                    cellProps.row.original.profilePicture
                  )}
                  alt="Benevolent"
                  roundedCircle
                  thumbnail
                  fluid
                  className="avatar"
                />
              </div>
              <div className="flex-grow-1 ms-3 text-dark">
                {cellProps.row.original.name}
              </div>
            </Link>
          </Fragment>
        );
      },
    },
    {
      header: "Contact",
      accessorKey: "contact",
      cell: (cellProps) => {
        return (
          <Fragment>
            <div>{cellProps.row.original.primaryContact}</div>
            <small className="fw-light text-light-emphasis">
              {cellProps.row.original.phoneNumber
                ? `${cellProps.row.original.phoneNumber}, `
                : ""}
              {cellProps.row.original.primaryEmail}
            </small>
          </Fragment>
        );
      },
    },
    {
      header: "Validators",
      accessorKey: "totalValidators",
    },
    {
      header: "Needs",
      accessorKey: "totalNeeds",
    },
    {
      header: "Created On",
      accessorKey: "createdOn",
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: (cellProps) => {
        let background;
        switch (cellProps.row.original.status) {
          case "Inactive":
            background = "warning";
            break;
          case "Active":
            background = "success";
            break;
          case "Deleted":
            background = "danger";
            break;
          default:
            background = "light";
            break;
        }
        return (
          <Badge className="fw-semibold text-dark" bg={background}>
            {cellProps.row.original.status.toUpperCase()}
          </Badge>
        );
      },
    },
    {
      header: "Actions",
      accessorKey: "organizationId",
      cell: (cellProps) => (
        <Dropdown drop="down" className="more-menu">
          <Dropdown.Toggle variant="light" size="sm">
            <i className="flaticon-more"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              to={`/organization/edit/${cellProps.row.original.organizationId}`}
              as={Link}
            >
              <i className="flaticon-edit fs-4 me-2"></i>Edit
            </Dropdown.Item>
            <Dropdown.Item
              to={`/organization/profile/${cellProps.row.original.referenceId}`}
              as={Link}
            >
              <i className="flaticon-layers fs-5 me-2"></i>View Profile
            </Dropdown.Item>

            {cellProps.row.original.status === "Active" && (
              <Dropdown.Item
                onClick={() =>
                  showConfirmation(
                    cellProps.row.original.organizationId,
                    "Deactivate",
                    "Inactive"
                  )
                }
              >
                <i className="flaticon-block-user fs-4 me-2"></i>
                Deactivate
              </Dropdown.Item>
            )}
            {cellProps.row.original.status === "Inactive" && (
              <Dropdown.Item
                onClick={() =>
                  showConfirmation(
                    cellProps.row.original.organizationId,
                    "Activate",
                    "Active"
                  )
                }
              >
                <i className="flaticon-approve-user fs-4 me-2"></i>
                Activate
              </Dropdown.Item>
            )}
            {cellProps.row.original.status !== "Delete" && (
              <Dropdown.Item
                onClick={() =>
                  showConfirmation(
                    cellProps.row.original.organizationId,
                    "Delete",
                    "Deleted"
                  )
                }
              >
                <i className="flaticon-delete fs-4 me-2"></i>
                Delete
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];

  /**
   * Function to handle pagination change.
   * @param {number} pageNumber - The new page number.
   * @param {number} pageSize - The new page size.
   */
  const handlePageChange = (pageNumber, pageSize) => {
    setOrganizations([]);
    setPage({
      PageNumber: pageNumber,
      PageSize: pageSize,
    });
  };

  /**
   * Function to handle search action.
   * @param {string} value - The search term entered by the user.
   */
  const handleSearch = (value) => {
    handlePageChange(1, page.PageSize);
    setSearchTerm(value);
  };

  /**
   * Function to show a confirmation dialog for updating the organization status.
   * @param {string} id - The ID of the organization.
   * @param {string} action - The action to perform (e.g., "Delete", "Activate", "Deactivate").
   * @param {string} status - The status to set for the organization (e.g., "Active", "Inactive", "Deleted").
   */
  const showConfirmation = (id, action, status) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Card className="border-0 shadow rounded-3">
            <Card.Body className="p-4 text-center">
              <Image src={confirmation} alt="Status Confirmation" fluid />
              <div className="mt-3 fw-light">
                Do you really want to {action.toLowerCase()} this user? <br />
                {status === "Deleted" && "This process can't be undone."}
              </div>
            </Card.Body>
            <Card.Footer className="bg-transparent py-4 text-center">
              <Button
                title="Cancel"
                variant="link"
                className="link-danger fw-semibold"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                className="ms-4 btn-md"
                onClick={async () => {
                  try {
                    await handleUpdateStatus(id, status);
                  } catch (error) {
                    // showNotification("danger", error, 5000);
                  }
                  onClose();
                }}
              >
                Yes, {action}
              </Button>
            </Card.Footer>
          </Card>
        );
      },
    });
  };

  /**
   * Function to update the organization status.
   * @param {number} id - The ID of the organization.
   * @param {string} status - The status to set for the organization (e.g., "Active", "Inactive", "Deleted").
   */
  const handleUpdateStatus = async (id, status) => {
    setIsPageLoading(true);
    try {
      const response = await postData(
        `/api/organization/update-status/${id}/${status}`,
        null,
        userSession
      );
      setIsPageLoading(false);
      showNotification("success", response, 5000);
      setPage((prevPage) => ({
        ...prevPage,
        pageNumber: prevPage.pageNumber,
      }));
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    /**
     * Fetches the organizations from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getAll = async () => {
      setIsLoading(true);
      try {
        const response = await postData(
          `/api/organization/getByPage?searchTerm=${searchTerm}`,
          page,
          userSession
        );
        setOrganizations(response.organizations);
        if (response.count != null) setTotalRecords(response.count);
        setIsLoading(false);
      } catch (error) {
        // Show a notification if an error occurs
        showNotification("danger", error, 5000);
        setOrganizations([]);
        setIsLoading(false);
      }
    };

    getAll();

    // Return a cleanup function
    return () => {};
  }, [searchTerm, page, userSession]);

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-semibold mb-0">
            Manage Organizations
          </h6>
          <Link
            to="/organization/create"
            className="ms-3 btn btn-secondary"
            title="Create Organization"
          >
            + Add Organization
          </Link>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <DataTable
              columns={columns}
              data={organizations}
              totalRecords={totalRecords}
              page={page}
              onPageChange={handlePageChange}
              searchRecords={handleSearch}
              loadingState={isLoading}
            />
          </Card.Body>
        </Card>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default ManageOrganizations;
