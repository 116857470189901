import React, {
  Fragment,
  useState,
  useEffect,
  useReducer,
  useCallback,
} from "react";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  Tab,
  TabContainer,
  Nav,
  Button,
  Alert,
  Modal,
  Spinner,
} from "react-bootstrap";

import NeedInfo from "./widgets/_storyOfTheNeed";
import RecipientInfo from "./widgets/_recipientInfo";
import ValidationStatement from "./widgets/_validationStatement";
import Photo from "./widgets/_photo";
import Preview from "./widgets/_preview";
import Congratulation from "./widgets/_congratulation";
import RecipientInvitation from "../invitation/recipientInvitation";

import { getData } from "../../services/apiService";
import { notify, initialState } from "../../store/notification";
import { useAuthContext } from "../../context/authProvider";
import { decodeToken } from "../../utils/session";

/**
 * Component for posting a need.
 * Manages the creation of a need in the system, including recipient information, validation statement, photos, and preview.
 * @returns {React.Element} - Returns JSX for posting a need.
 * @access Accessible by SuperAdmin, Staff, and Validator
 */
const PostNeed = () => {
  /* Default values for a new need */
  const defaultValues = {
    needId: 0,
    referenceId: "",
    needStatus: "Draft",
    title: "",
    summary: "",
    neededOn: "",
    categories: [],
    amountNeeded: 0,
    biography: "",
    goals: "",
    aboutNeed: "",
    reasonForNeed: "",
    firstName: "",
    lastName: "",
    displayName: "",
    emailAddress: "",
    gender: "",
    ethnicity: "",
    dob: "",
    adultsInHousehold: "",
    childrenInHousehold: "",
    employmentStatus: "",
    education: "",
    householdAnnualIncome: "",
    personalAnnualIncome: "",
    specialCircumstances: "",
    address: {
      phoneNumber: "",
      city: "",
      state: "",
      country: "United States",
      zipCode: "",
    },
    validatorId: 0,
    organizationId: 0,
    validationStatement: "",
    fundTransferType: "Electronic prepaid card (recommended)",
    notes: "",
    profilePicture: "",
    validatorProfilePicture: "",
    isFeatured: false,
    accessCode: "",
    raised: 0,
    totalSupporters: 0,
    daysLeft: 0,
    totalFollowers: 0,
    userAgreement: false,
  };
  const [notification, dispatch] = useReducer(notify, initialState);
  const { userSession } = useAuthContext();
  const user = decodeToken(userSession);
  const roles = Array.isArray(user.roles) ? user.roles : [user.roles];
  if (roles.includes("Validator"))
    defaultValues.validatorProfilePicture = user.ProfilePicture;
  const { id } = useParams();

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [need, setNeed] = useState({});
  const [tabInfo, setTabInfo] = useState({
    activeTab: "need-info",
    displayInvitePage: false,
    hasBeenSubmitted: false,
  });
  const [result, setResult] = useState({});

  /**
   * Function to set the active tab in the tab info state.
   * @param {string} tab - The tab to set as active.
   */
  const handleActiveTab = useCallback((tab) => {
    setTabInfo((prevTabInfo) => ({
      ...prevTabInfo, // Preserve existing properties
      activeTab: tab, // Update the activeTab property
    }));
  }, []);

  /**
   * Function to toggle the visibility of the recipient's invitation and optionally resets the access code.
   * Shows a notification if basic information for the Need is not provided before generating the code.
   * @param {boolean} isVisible - Whether the recipient's invitation should be displayed.
   * @param {boolean} hasToReset - Whether to reset the access code.
   */
  const toggleInvitation = (isVisible, hasToReset = false) => {
    if (need.needId === 0)
      showNotification(
        "danger",
        "Please complete the Recipient Information, Validation Statement, and Photos before inviting your client to write their story.",
        5000
      );
    else {
      setTabInfo({
        ...tabInfo,
        displayInvitePage: isVisible,
      });
      if (hasToReset) {
        setNeed({
          ...need,
          accessCode: "",
        });
      }
    }
  };

  /**
   * Collects data from tab to update the need state when the user moves from one tab to another.
   * Resets the need state and updates the result state based on the API response.
   * @param {object} data - The data received from the tab or the API response.
   */
  const processResult = (data) => {
    if (!data.hasBeenSubmitted) {
      setNeed((prevNeed) => ({
        ...prevNeed,
        ...data.need,
      }));
    } else {
      setNeed(defaultValues);
      setResult({
        needStatus: data.status,
        referenceId: need.referenceId,
        needId: need.needId,
      });
    }
    setTabInfo({
      ...tabInfo,
      hasBeenSubmitted: data.hasBeenSubmitted,
    });
  };

  useEffect(() => {
    /**
     * Fetches the need from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getById = async () => {
      setIsPageLoading(true);
      const apiURL = roles.includes("Recipient")
        ? `/api/need/get-recipient-story/${user.nameid}`
        : `/api/need/getById/${id}`;
      try {
        const response = await getData(apiURL, null, userSession);
        response.fundedOn = formatDate(response.fundedOn);
        setNeed(response);       
        if (roles.includes("Validator") && response.accessCode) {
          setTabInfo((prevTabInfo) => ({
            ...prevTabInfo,
            displayInvitePage: true,
          }));
        }
        setIsPageLoading(false);
      } catch (error) {
        setIsPageLoading(false);
        showNotification("danger", error, 5000);
      }
    };
    if (id) getById();
    else {
      setNeed(defaultValues);
      handleActiveTab("need-info");
    }
  }, [id, userSession, user.nameid, handleActiveTab]);

  /**
   * Function to format a date.
   * @param {string} fundedOn - The date to format.
   * @returns {string} - The formatted date string in 'MM/dd/yyyy' format, or '_' if the date is DateTime.MinValue.
   */
  const formatDate = (fundedOn) => {
    // Parse the date string from backend
    const date = new Date(fundedOn);

    // Check if the date is DateTime.MinValue
    // DateTime.MinValue is '0001-01-01T00:00:00Z' which is equivalent to '0001-01-01'
    if (date.getFullYear() === 1) return "_";

    // Format the date
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    }).replace(/\//g, '-'); // Replace '/' with '-'
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main className="create-need">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h6 className="module-title fw-semibold mb-0">Post a Need</h6>
        <div>
          {!roles.includes("Recipient") && (
            <Link to="/needs" className="ms-3 btn btn-secondary" title="Back">
              <i className="flaticon-line-arrow-left pe-2"></i>
              Back
            </Link>
          )}
          {tabInfo.activeTab === "need-info" && roles.includes("Validator") && (
            <div
              className="ms-2 d-inline-flex align-items-center"
              onClick={() => toggleInvitation(true)}
            >
              <Button variant="primary">
                <i className="flaticon-line-arrow-right"></i>
              </Button>

              <div className="ms-2 text-dark">
                <small className="d-block text-dark-emphasis fw-light">
                  Allow Recipient
                </small>
                To fill this form
              </div>
            </div>
          )}
        </div>
      </div>
      <Card className="border-0 rounded-3">
        <Card.Body className="p-4">
          <TabContainer
            id="need-tabs"
            defaultActiveKey="need-info"
            activeKey={tabInfo.activeTab}
            onSelect={(tab) => handleActiveTab(tab)}
          >
            <Nav variant="tabs" fill className="border-bottom">
              <Nav.Item>
                <Nav.Link eventKey="need-info" className="fw-semibold">
                  <i className="flaticon-tale pe-2 fs-3"></i>
                  <span className="align-text-bottom">Story of the Need</span>
                </Nav.Link>
              </Nav.Item>
              {!roles.includes("Recipient") && (
                <Fragment>
                  <Nav.Item>
                    <Nav.Link eventKey="recipient-info" className="fw-semibold">
                      <i className="flaticon-user-info pe-2 fs-3"></i>
                      <span className="align-text-bottom">
                        Recipient Information
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="validation-statement"
                      className="fw-semibold"
                    >
                      <i className="flaticon-guarantee pe-2 fs-3"></i>
                      <span className="align-text-bottom">
                        Validation Statement
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="photos" className="fw-semibold">
                      <i className="flaticon-add-photo pe-2 fs-3"></i>
                      <span className="align-text-bottom">Photos</span>
                    </Nav.Link>
                  </Nav.Item>
                </Fragment>
              )}
              <Nav.Item>
                <Nav.Link eventKey="preview" className="fw-semibold">
                  <i className="flaticon-preview pe-2 fs-3"></i>
                  <span className="align-text-bottom">Preview</span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="need-info" className="p-4">
                {!tabInfo.displayInvitePage && (
                  <NeedInfo
                    handleActiveTab={handleActiveTab}
                    processResult={processResult}
                    need={need}
                    roles={roles}
                    userSession={userSession}
                  />
                )}
                {tabInfo.displayInvitePage && (
                  <RecipientInvitation
                    toggleInvitation={toggleInvitation}
                    need={need}
                    roles={roles}
                    userSession={userSession}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="recipient-info" className="p-4">
                <RecipientInfo
                  handleActiveTab={handleActiveTab}
                  processResult={processResult}
                  need={need}
                  userSession={userSession}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="validation-statement" className="p-4">
                <ValidationStatement
                  handleActiveTab={handleActiveTab}
                  processResult={processResult}
                  need={need}
                  roles={roles}
                  userSession={userSession}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="photos" className="p-4">
                <Photo
                  handleActiveTab={handleActiveTab}
                  processResult={processResult}
                  need={need}
                  userSession={userSession}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="preview" className="py-4">
                {!tabInfo.hasBeenSubmitted && (
                  <Preview
                    handleActiveTab={handleActiveTab}
                    processResult={processResult}
                    need={need}
                    roles={roles}
                    userSession={userSession}
                  />
                )}
                {tabInfo.hasBeenSubmitted && (
                  <Congratulation
                    needStatus={result.needStatus}
                    referenceId={result.referenceId}
                    needId={result.needId}
                    roles={roles}
                  />
                )}
              </Tab.Pane>
            </Tab.Content>
          </TabContainer>
        </Card.Body>
      </Card>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default PostNeed;
