import React, { useEffect, useReducer } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card, Button, Image, Alert } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useCookies } from "react-cookie";

import { putData } from "../../services/apiService";
import { notify, initialState } from "../../store/notification";
import confirmation from "../../resources/images/confirmation.png";

/**
 * Component for displaying transaction cancellation confirmation.
 * @param {string} cartId - The ID of the cart related to the transaction.
 * @param {boolean} showTransactionConfirmation - Indicates whether the transaction cancellation confirmation should be shown.
 * @param {function} setShowTransactionConfirmation - Function to control the visibility of the transaction cancellation confirmation.
 * @param {function} setIsPageLoading - Function to set the loading state of the page.
 * @returns {React.Element} - Returns JSX for confirmation modal.
 */
const TransactionCancellationConfirmation = ({
  cartId,
  showTransactionConfirmation,
  setShowTransactionConfirmation,
  setIsPageLoading,
}) => {
  const [cookies, , removeCookie] = useCookies();
  const navigate = useNavigate();
  const location = useLocation();

  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);

  /**
   *  Function to cancel a payment transaction associated with the current cart.
   */
  const cancelPayment = async () => {
    setIsPageLoading(true);
    try {
      const response = await putData(
        `/api/cart/clear-all/${cartId}`,
        null,
        null
      );
      setIsPageLoading(false);
      // Get the current URL's query string
      const urlParams = new URLSearchParams(window.location.search);

      if (response.hasTransactions) {
        // Redirect to donation status page
        const redirectURL = `/donation/status/${response.referenceId}${urlParams.has("frameLayout") ? `?${urlParams.toString()}` : ""
          }`;
        navigate(redirectURL);
      } else {
        // If the cart has been deleted, cookie should be removed
        // the method to update the needs chosen count
        // in the header will be triggered automatically.

        const cookieNames = Object.keys(cookies);
        cookieNames
          .filter((cookieName) => cookieName.startsWith("_benevolent"))
          .forEach((cookieName) =>
            removeCookie(cookieName, {
              path: "/",
              sameSite: "none",
              secure: true,
            })
          );

        const redirectURL = `/donation/checkout${urlParams.has("frameLayout") ? `?${urlParams.toString()}` : ""
          }`;

        // If you're already on the checkout page and you're attempting to navigate to the
        // same checkout page using navigate("/checkout"), it might not trigger a re-render
        // or a navigation change because you're essentially trying to navigate to the
        // same location you're already at.
        !location.pathname.includes("/checkout")
          ? navigate(redirectURL)
          : navigate(-1);
      }
    } catch (error) {
      // Show a notification if an error occurs any
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
      setShowTransactionConfirmation(false);
    }
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  useEffect(() => {
    /**
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     * Function to display a confirmation dialog for canceling the transaction.
    */
    const showConfirmation = () => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Card className="border-0 shadow rounded-3">
              <Card.Body className="p-4 text-center">
                <Image src={confirmation} alt="Delete Confirmation" fluid />
                <div className="mt-3 fw-light">
                  Do you really want to cancel this transaction? <br />
                  This process can't be undone.
                </div>
              </Card.Body>
              <Card.Footer className="bg-transparent py-4 text-center">
                <Button
                  title="Continue"
                  variant="link"
                  className="link-primary fw-semibold"
                  onClick={() => {
                    onClose();
                    setShowTransactionConfirmation(false);
                  }}
                >
                  No, Continue
                </Button>
                <Button
                  variant="danger"
                  className="ms-4 btn-md"
                  onClick={async () => {
                    try {
                      await cancelPayment();
                    } catch (error) { }
                    onClose();
                  }}
                >
                  Yes, Cancel
                </Button>
              </Card.Footer>
            </Card>
          );
        },
      });
    };

    if (showTransactionConfirmation)
      showConfirmation();

  }, [showTransactionConfirmation, setShowTransactionConfirmation]);

  return (
    <div className={`notification ${notification.variant && "show"}`}>
      {/* Display notification component */}
      {notification.variant && (
        <Alert
          variant={notification.variant}
          onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
          dismissible
        >
          {notification.message}
        </Alert>
      )}
    </div>
  );
};

export default TransactionCancellationConfirmation;
