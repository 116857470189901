import React, {
  Fragment,
  useState,
  useReducer,
  useRef,
  useEffect,
} from "react";
import {
  Row,
  Col,
  Form,
  Card,
  Button,
  Image,
  Spinner,
  Alert,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import RecipientProfile from "../../profile/widgets/_recipientProfile";
import NeedInfo from "../../profile/widgets/_needInfo";
import RecentDonations from "../../profile/widgets/_recentDonations";
import Fundraising from "../../profile/widgets/_fundraising";

import { postData } from "../../../services/apiService";
import { notify, initialState } from "../../../store/notification";
import Verification from "../../../resources/images/verification-success.png";

/**
 * Component for rendering the preview of need information.
 * @param {object} props - Component props.
 * @param {object} props.userSession - User session object.
 * @param {object} props.need - Object containing need information.
 * @param {array} props.roles - Array containing user roles.
 * @param {function} props.handleActiveTab - Function to handle active tab.
 * @param {function} props.processResult - Function to process form result.
 * @returns {React.Element} - Returns JSX for preview of need
 */
const Preview = (props) => {
  const [notification, dispatch] = useReducer(notify, initialState);
  const { userSession, isLoggedIn } = props;
  const [loadingStates, setLoadingStates] = useState({});
  const [stepsCompleted, setStepsCompleted] = useState(false);
  const checkboxRef = useRef(null);

  useEffect(() => {
    // Story of the Need - 'aboutNeed' is a property that should contain information about the need.
    // Recipient Information - 'displayName' should contain the display name of the recipient.
    // Validation Statement - 'validationStatement' should have a validation statement for the need.
    // Photos- 'profilePicture' is a property that should contain the profile picture of the recipient.

    // If all properties are defined, 'stepsCompleted' will be true, indicating that all steps are completed.
    setStepsCompleted(
      props.need.aboutNeed &&
      props.need.displayName &&
      props.need.validationStatement &&
      props.need.profilePicture
    );

    if (checkboxRef?.current != null)
      checkboxRef.current.checked = props.need.isFeatured;
  }, [props.need]);

  /**
   * Function to update the status of a need using the backend API
   * @param {string} status - The status ("Draft", "Awaiting Approval", "Published") to update the need to.
   */
  const updateStatus = async (status) => {
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [status]: true,
    }));
    try {
      await postData(
        `/api/need/update-status/`,
        {
          id: props.need.needId,
          status: status,
          isFeatured:
            checkboxRef?.current != null ? checkboxRef.current.checked : null,
        },
        userSession
      );
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [status]: false,
      }));
      props.processResult({
        hasBeenSubmitted: true,
        status: status,
      });
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [status]: false,
      }));
    }
  };

  /**
   * Function to send story updates to the validator via the backend API.
   * @param {string} element - The loading state element to update during the process.
   */
  const sendStoryUpdatesToValidator = async (element) => {
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [element]: true,
    }));
    try {
      const response = await postData(
        "/api/need/send-story-updates-to-validator",
        props.need,
        userSession
      );
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [element]: false,
      }));
      showNotification("success", response, 5000);
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [element]: false,
      }));
    }
  };

  /**
   * Function to show a confirmation dialog to the validator.
   * It displays a modal allowing the user to review their changes and prompts them to click 'Submit'
   * to send everything to the Benevolent team for their final review.
   */
  const confirmNeedSubmission = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Card className="border-0 shadow rounded-3 token-expiration">
            <Card.Body className="p-4 text-center">
              <Image src={Verification} alt="Token Expiration" fluid />
              <h5 className="my-3 module-title fw-semibold">
                Review your changes!
              </h5>
              <div className="mt-2 fw-light">
                Edit if needed using the pencil icon <br />
                next to the section you want to modify.
              </div>
              <div className="mt-2 fw-light">
                Click 'Submit' to send everything to the <br />
                Benevolent team for their final review.
              </div>
            </Card.Body>
            <Card.Footer className="bg-transparent py-4 text-end">
              <Button
                title="Cancel"
                variant="link"
                className="link-secondary fw-semibold"
                onClick={async () => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="ms-4 btn-md"
                disabled={loadingStates["Awaiting Approval"]}
                onClick={async () => {
                  if (stepsCompleted && props.need.needId > 0)
                    await updateStatus("Awaiting Approval");
                  else
                    showNotification(
                      "success",
                      "Incomplete Form for Approval: Please complete all required fields before requesting approval.",
                      5000
                    );

                  onClose();
                }}
              >
                {loadingStates["Awaiting Approval"] ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  "Submit"
                )}
              </Button>
            </Card.Footer>
          </Card>
        );
      },
    });
  };

  /**
   * Function to display a confirmation dialog for the recipient.
   * It presents a dialog with information about reviewing the story and making changes before sending it to the validator.
   * The recipient can cancel the submission or proceed with sending the updates to the validator.
   */
  const confirmStoryUpdatesSubmission = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Card className="border-0 shadow rounded-3 token-expiration">
            <Card.Body className="p-4 text-center">
              <Image src={Verification} alt="Token Expiration" fluid />
              <h5 className="my-3 module-title fw-semibold">
                Review your story!
              </h5>
              <div className="mt-2 fw-light">
                Examine it before sending it to <br />
                <span className="fw-semibold">
                  {props.need.validatorName}
                </span>{" "}
                from{" "}
                <span className="fw-semibold">
                  {props.need.organizationName}
                </span>
                .
              </div>
              <div className="mt-2 fw-light">
                You can make changes by clicking the <br /> pencil icon next to
                any section. When
                <br /> you're ready, click 'Send' to submit.
              </div>
            </Card.Body>
            <Card.Footer className="bg-transparent py-4 text-end">
              <Button
                title="Cancel"
                variant="link"
                className="link-secondary fw-semibold"
                onClick={async () => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="ms-4 btn-md"
                disabled={loadingStates["isSendUpdates"]}
                onClick={async () => {
                  if (props.need.needId > 0)
                    await sendStoryUpdatesToValidator("isSendUpdates");
                  else
                    showNotification(
                      "success",
                      "Incomplete Form for Approval: Please complete all required fields before sending updates to the validator.",
                      5000
                    );
                  onClose();
                }}
              >
                {loadingStates["isSendUpdates"] ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  "Send"
                )}
              </Button>
            </Card.Footer>
          </Card>
        );
      },
    });
  };

  /**
   * Function to check if the administrator has completed the pre-publish information.
   * It checks whether the admin has provided the required information or not, and then updates the status if all conditions are met.
   * Otherwise, it shows a notification reminding the user to fill in all mandatory fields before publishing or drafting the need.
   * @param {string} status - The status to update if the pre-publish information is complete.
   */
  const isAdminPrePublishInformationComplete = async (status) => {
    if (
      props.need.title &&
      props.need.categories.length > 0 &&
      props.need.needId > 0
    ) {
      if (new Date(props.need.neededOn).getTime() <= new Date().getTime()) {
        showNotification(
          "danger",
          "The 'Needed By' date must be in the future.",
          5000
        );
        return;
      }
      await updateStatus(status);
    } else
      showNotification(
        "danger",
        "Before publishing or drafting the need, make sure to fill in all mandatory fields to complete the form for approval.",
        5000
      );
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main className="fundraising-profile">
      <Row>
        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
          <RecipientProfile
            isPreview
            handleActiveTab={props.handleActiveTab}
            need={props.need}
            roles={props.roles}
            isLoggedIn={isLoggedIn}
          />
        </Col>

        <Col xs={12} sm={12} md={6} lg={5} xl={5} className="mt-3">
          <NeedInfo
            isPreview
            handleActiveTab={props.handleActiveTab}
            need={props.need}
            roles={props.roles}
          />
          {(props.roles.includes("SuperAdmin") ||
            props.roles.includes("Staff")) && (
              <Form.Check
                type="checkbox"
                label="Featured"
                className="fw-light text-dark-emphasis d-inline-block"
                ref={checkboxRef}
              />
            )}
        </Col>

        <Col xs={12} sm={12} md={12} lg={3} xl={3} className="mt-3">
          <Card className="bg-tertiary border-0 mb-3">
            <Card.Body>
              <div className="text-center mb-3 fw-light">
                Needed:{" "}
                <span className="fw-semibold text-dark">
                  ${props.need?.amountNeeded}
                </span>
              </div>
              <Fundraising
                raised={props.need?.raised ?? 0}
                needed={props.need?.amountNeeded}
                status={props.need.needStatus}
              />
              <div className="text-center">
                <Button
                  variant="secondary"
                  className="btn-md mt-3"
                  disabled={props.need.needStatus !== "Published"}
                >
                  DONATE NOW
                </Button>
              </div>
            </Card.Body>
          </Card>
          <RecentDonations
            needId={props.need?.needId}
            needStatus={props.need.needStatus}
          />
        </Col>
        <Col xs={12} className="text-center mt-4">
          {(props.roles.includes("SuperAdmin") ||
            props.roles.includes("Staff")) && (
              <Fragment>
                <Button
                  variant="primary"
                  className="me-3 mb-2 btn-md"
                  disabled={loadingStates["Draft"]}
                  onClick={() => isAdminPrePublishInformationComplete("Draft")}
                >
                  {loadingStates["Draft"] ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    " Save as Draft"
                  )}
                </Button>
                <Button
                  variant="secondary"
                  className="me-3 mb-2 btn-md"
                  disabled={loadingStates["Published"]}
                  onClick={() =>
                    isAdminPrePublishInformationComplete("Published")
                  }
                >
                  {loadingStates["Published"] ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    " Publish"
                  )}
                </Button>
                <Button
                  variant="outline-secondary"
                  className="me-3 mb-2 btn-md"
                  onClick={() =>
                    isAdminPrePublishInformationComplete("Awaiting Approval")
                  }
                >
                  {loadingStates["Awaiting Approval"] ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    "Change to Awaiting Approval"
                  )}
                </Button>
              </Fragment>
            )}
          {props.roles.includes("Validator") &&
            props.need.needStatus === "Draft" && (
              <Button
                variant="outline-secondary"
                onClick={() => confirmNeedSubmission()}
                className="me-3 mb-2 btn-md"
              >
                Request Approval
              </Button>
            )}
          {props.roles.includes("Recipient") && (
            <Button
              variant="outline-secondary"
              onClick={() => confirmStoryUpdatesSubmission()}
              className="me-3 mb-2 btn-md"
            >
              Notify Validator
            </Button>
          )}
        </Col>
      </Row>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
    </main>
  );
};

export default Preview;
