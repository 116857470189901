import { Link } from "react-router-dom";
import { Image, Card } from "react-bootstrap";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";

import { OrgProfileURL } from "../../../constants";

// responsiveHOC is a Higher Order Component (HOC) used for responsive behavior,
// and LinesEllipsis is a component for truncating text with ellipsis.
const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

/**
 * Component for displaying organization details in a card format.
 * @param {Object} props - The props object.
 * @param {Object} props.organization - The organization object containing details like name, profile picture, address, description, website, and reference ID.
 * @returns {React.Element} - Returns JSX for the organization card.
 */
const OrganizationCard = ({ organization }) => {
  return (
    <Card className="border-0 shadow-sm rounded-4 h-100 organization">
      <Card.Body>
        <Image
          src={organization.profilePicture}
          alt="Profile Picture"
          className="profile-pic"
          fluid
          roundedCircle
        />
        <h5 className="mt-5 pt-3 mb-0 title">{organization.name}</h5>
        <p className="text-light-emphasis mb-1">
          <small>
            {organization.address.city}, {organization.address.state} -{" "}
            {organization.address.zipCode}
          </small>
        </p>
        <div className="mb-2 text-dark-emphasis fs-small">
          <ResponsiveEllipsis
            text={organization.description}
            maxLine="3"
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        </div>
        <p className="mb-0 fs-small">
          <Link
            to={organization.website}
            title="Profile"
            className="link-secondary text-decoration-none"
          >
            Visit Website
          </Link>
        </p>
      </Card.Body>
      <Card.Footer className="bg-transparent border-0 py-0 pe-0 text-end">
        <Link
          className="mt-3 btn btn-secondary btn-arrow"
          to={`${OrgProfileURL}${organization.referenceId}`}
        >
          <i className="flaticon-line-arrow-right fs-5"></i>
        </Link>
      </Card.Footer>
    </Card>
  );
};

export default OrganizationCard;
