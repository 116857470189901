import { RequireAuth } from "./requireAuth";
import { ProtectedRoute } from "./protectedRoute";

import PublicLayout from "../layout/publicLayout";
import PrivateLayout from "../layout/privateLayout";
import ChangePassword from "../features/account/changePassword";
import UserProfile from "../features/profile/user";
import UpdateProfile from "../features/users/updateProfile";
import Dashboard from "../features/dashboard";
import ManageCategory from "../features/category";
import ManageUsers from "../features/users/";
import CreateUser from "../features/users/createUser";
import EditUser from "../features/users/editUser";
import ManageTemplate from "../features/template";
import ManageValidators from "../features/validator";
import ValidatorInvitations from "../features/validator/invitation";
import CreateValidator from "../features/validator/create";
import RecommendValidator from "../features/validator/recommendValidator";
import ManageOrganizations from "../features/organization";
import CreateOrganization from "../features/organization/create";
import OrganizationPrivateProfile from "../features/organization/profile";
import ManageNeeds from "../features/need/manage";
import PostNeed from "../features/need/create";
import ManageFeaturedNeeds from "../features/need/featured";
import ManageReceipts from "../features/need/receipts";
import UploadReceipt from "../features/need/uploadReceipt";
import ManageNeedUpdates from "../features/need/updates";
import PostUpdate from "../features/need/postUpdate";
import SendNote from "../features/need/sendNote";
import NeedsByCategory from "../features/need/needsByCategory";
import ManageEmbedCodes from "../features/embedCode";
import CreateEmbedCode from "../features/embedCode/create";
import ManageUserGivingPages from "../features/givingPage/user/index";
import CreateUserGivingPage from "../features/givingPage/user/create";
import ManagePaymentOptions from "../features/paymentOption";
import ManageGivingHistory from "../features/givingHistory";
import ManageTransactions from "../features/transaction";
import Reallocation from "../features/transaction/reallocation";

// Components are wrapped with higher-order components (RequireAuth and ProtectedRoute)
// for authentication and role-based access control.
const PrimaryRoutes = [
  {
    element: (
      <RequireAuth>
        <PrivateLayout />
      </RequireAuth>
    ),
    path: "/",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        icon: "flaticon-dashboard fs-5 me-3",
        roles: ["SuperAdmin", "Staff", "Validator", "Donor"],
        element: (
          <ProtectedRoute
            allowedRoles={["SuperAdmin", "Staff", "Validator", "Donor"]}
          >
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: "/category",
        name: "Categories",
        icon: "flaticon-category fs-5 me-3",
        roles: ["SuperAdmin", "Staff"],
        element: null,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute allowedRoles={["SuperAdmin", "Staff"]}>
                <ManageCategory />
              </ProtectedRoute>
            ),
          },
          {
            path: "needs/:categoryId",
            element: (
              <ProtectedRoute allowedRoles={["SuperAdmin", "Staff"]}>
                <NeedsByCategory />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "/user",
        name: "Manage Users",
        icon: "flaticon-profile fs-5 me-3",
        element: null,
        roles: ["SuperAdmin"],
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute allowedRoles={["SuperAdmin"]}>
                <ManageUsers />
              </ProtectedRoute>
            ),
          },
          {
            path: "create",
            element: (
              <ProtectedRoute allowedRoles={["SuperAdmin"]}>
                <CreateUser />
              </ProtectedRoute>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <ProtectedRoute allowedRoles={["SuperAdmin"]}>
                <EditUser />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "/transactions",
        name: "Transactions",
        icon: "flaticon-card fs-5 me-3",
        element: (
          <ProtectedRoute allowedRoles={["SuperAdmin", "Staff"]}>
            <ManageTransactions />
          </ProtectedRoute>
        ),
        roles: ["SuperAdmin", "Staff"],
      },
      {
        path: "/reallocation",
        name: "Reallocations",
        icon: "flaticon-maximize fs-5 me-3",
        element: (
          <ProtectedRoute allowedRoles={["SuperAdmin", "Staff"]}>
            <Reallocation />
          </ProtectedRoute>
        ),
        roles: ["SuperAdmin", "Staff"],
      },
      {
        path: "/template",
        name: "Templates",
        icon: "flaticon-template fs-5 me-3",
        element: (
          <ProtectedRoute allowedRoles={["SuperAdmin"]}>
            <ManageTemplate />
          </ProtectedRoute>
        ),
        roles: ["SuperAdmin"],
      },
      {
        path: "/organization",
        name: "Organizations",
        icon: "flaticon-layers fs-5 me-3",
        element: null,
        roles: ["SuperAdmin", "Staff"],
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute allowedRoles={["SuperAdmin", "Staff"]}>
                <ManageOrganizations />
              </ProtectedRoute>
            ),
          },
          {
            path: "create",
            element: (
              <ProtectedRoute allowedRoles={["SuperAdmin", "Staff"]}>
                <CreateOrganization />
              </ProtectedRoute>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <ProtectedRoute allowedRoles={["SuperAdmin", "Staff"]}>
                <CreateOrganization />
              </ProtectedRoute>
            ),
          },
          {
            path: "profile/:referenceId",
            element: (
              <ProtectedRoute allowedRoles={["SuperAdmin", "Staff"]}>
                <OrganizationPrivateProfile />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "/validator",
        name: "Validators",
        icon: "flaticon-user-list fs-5 me-3",
        element: null,
        roles: ["SuperAdmin", "Staff"],
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute allowedRoles={["SuperAdmin", "Staff"]}>
                <ManageValidators />
              </ProtectedRoute>
            ),
          },
          {
            path: "create",
            element: (
              <ProtectedRoute allowedRoles={["SuperAdmin", "Staff"]}>
                <CreateValidator />
              </ProtectedRoute>
            ),
          },
          {
            path: "edit/:code",
            element: (
              <ProtectedRoute allowedRoles={["SuperAdmin", "Staff"]}>
                <CreateValidator />
              </ProtectedRoute>
            ),
          },
          {
            path: "invitations",
            element: (
              <ProtectedRoute allowedRoles={["SuperAdmin", "Staff"]}>
                <ValidatorInvitations />
              </ProtectedRoute>
            ),
          },
          {
            path: "recommend-validator",
            element: <RecommendValidator />,
          },
        ],
      },
      {
        path: "/needs",
        name: "Manage Needs",
        icon: "flaticon-need fs-3 me-2",
        element: null,
        roles: ["SuperAdmin", "Staff", "Validator"],
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute
                allowedRoles={["SuperAdmin", "Staff", "Validator"]}
              >
                <ManageNeeds />
              </ProtectedRoute>
            ),
          },
          {
            path: "create",
            element: (
              <ProtectedRoute allowedRoles={["Validator"]}>
                <PostNeed />
              </ProtectedRoute>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <ProtectedRoute
                allowedRoles={["SuperAdmin", "Staff", "Validator"]}
              >
                <PostNeed />
              </ProtectedRoute>
            ),
          },
          {
            path: "featured",
            element: (
              <ProtectedRoute allowedRoles={["SuperAdmin", "Staff"]}>
                <ManageFeaturedNeeds />
              </ProtectedRoute>
            ),
          },
          {
            path: "receipts/:needId",
            element: (
              <ProtectedRoute
                allowedRoles={["SuperAdmin", "Staff", "Validator"]}
              >
                <ManageReceipts />
              </ProtectedRoute>
            ),
          },
          {
            path: "receipts/upload/:needId",
            element: (
              <ProtectedRoute
                allowedRoles={["SuperAdmin", "Staff", "Validator"]}
              >
                <UploadReceipt />
              </ProtectedRoute>
            ),
          },
          {
            path: "updates/:needId",
            element: (
              <ProtectedRoute
                allowedRoles={["SuperAdmin", "Staff", "Validator"]}
              >
                <ManageNeedUpdates />
              </ProtectedRoute>
            ),
          },
          {
            path: "updates/post/:needId/:updateId?",
            element: (
              <ProtectedRoute
                allowedRoles={["SuperAdmin", "Staff", "Validator"]}
              >
                <PostUpdate />
              </ProtectedRoute>
            ),
          },
          {
            path: "send-note/:needId",
            element: (
              <ProtectedRoute allowedRoles={["Donor"]}>
                <SendNote />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "/embed-code",
        name: "Embed Code",
        icon: "flaticon-coding fs-5 me-3",
        element: null,
        roles: ["SuperAdmin"],
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute allowedRoles={["SuperAdmin"]}>
                <ManageEmbedCodes />
              </ProtectedRoute>
            ),
          },
          {
            path: "create",
            element: (
              <ProtectedRoute allowedRoles={["SuperAdmin"]}>
                <CreateEmbedCode />
              </ProtectedRoute>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <ProtectedRoute allowedRoles={["SuperAdmin"]}>
                <CreateEmbedCode />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "/giving-history",
        name: "Giving History",
        icon: "flaticon-gift fs-5 me-3",
        roles: ["Donor"],
        element: (
          <ProtectedRoute allowedRoles={["Donor"]}>
            <ManageGivingHistory />
          </ProtectedRoute>
        ),
      },
    ],
  },
];

const SecondaryRoutes = [
  {
    element: (
      <RequireAuth>
        <PrivateLayout />
      </RequireAuth>
    ),
    path: "/",
    children: [
      {
        path: "change-password",
        element: (
          <ProtectedRoute allowedRoles={[]}>
            <ChangePassword />
          </ProtectedRoute>
        ),
      },
      {
        path: "profile/user",
        element: (
          <ProtectedRoute allowedRoles={[]}>
            <UserProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: "update-profile",
        element: (
          <ProtectedRoute allowedRoles={[]}>
            <UpdateProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: "payment-options",
        element: (
          <ProtectedRoute allowedRoles={[]}>
            <ManagePaymentOptions />
          </ProtectedRoute>
        ),
      },
      {
        path: "/giving-page/user",
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute allowedRoles={[]}>
                <ManageUserGivingPages />
              </ProtectedRoute>
            ),
          },
          {
            path: "create",
            element: (
              <ProtectedRoute allowedRoles={[]}>
                <CreateUserGivingPage />
              </ProtectedRoute>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <ProtectedRoute allowedRoles={[]}>
                <CreateUserGivingPage />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
  {
    element: (
      <RequireAuth>
        <PublicLayout />
      </RequireAuth>
    ),
    path: "/",
    children: [
      {
        path: "update-my-story/:id",
        element: (
          <ProtectedRoute allowedRoles={["Recipient"]}>
            <PostNeed />
          </ProtectedRoute>
        ),
      },
    ],
  },
];

export { PrimaryRoutes, SecondaryRoutes };
