import axios from "axios";
import { APIURL } from "../constants";

/**
 * It controls whether or not Axios will send cookies with cross-site Access-Control requests. 
 * By default, this property is set to false
 * This will tell Axios to include any cookies associated with the domain of the request in the request headers.
 */
axios.defaults.withCredentials = true;

/**
 * Function To authenticate user
 * @param {Object} data - The data to be sent to the server for authentication
 * @returns {Promise<Object>} Returns a promise that resolves with the data received from the response
 */
export const authenticate = async (data) => {
  return await axios.post(`${APIURL}/api/account/authenticate`, data).then(
    (response) => {
      return response.data;
    },
    (error) => {
      handleError(error);
    }
  );
};

/**
 * Function To create a new user account
 * @param {Object} data - The data to be sent to the server for account creation
 * @returns {Promise<Object>} Returns a promise that resolves with the data received from the response
 */
export const createAccount = async (data) => {
  return await axios.post(`${APIURL}/api/account/register`, data).then(
    (response) => {
      return response.data;
    },
    (error) => {
      handleError(error);
    }
  );
};

/**
 * Function to authenticate user with a social media provider (Facebook and Google)
 * @param {Object} data - The data to be sent to the server for social media authentication
 * @returns {Promise<Object>} Returns a promise that resolves with the data received from the response
 */
export const authenticateWithSocialProvider = async (data) => {
  return await axios.post(`${APIURL}/api/account/external-login`, data).then(
    (response) => {
      return response.data;
    },
    (error) => {
      handleError(error);
    }
  );
};

/**
 * Function to confirm email
 * @param {string} endPoint - The endpoint for confirming email
 * @returns {Promise<Object>} Returns a promise that resolves with the data received from the response
 */
export const confirmEmail = async (endPoint) => {
  return await axios.post(`${APIURL}${endPoint}`).then(
    (response) => {
      return response.data;
    },
    (error) => {
      handleError(error);
    }
  );
};

/**
 * Function to resend verification email
 * @param {string} endPoint - The endpoint for resending verification email
 * @returns {Promise<Object>} Returns a promise that resolves with the data received from the response
 */
export const resendVerificationEmail = async (endPoint) => {
  return await axios.post(`${APIURL}${endPoint}`).then(
    (response) => {
      return response.data;
    },
    (error) => {
      handleError(error);
    }
  );
};

/**
 * Function to send verification code
 * @param {string} email - The email address to send the verification code to
 * @returns {Promise<Object>} Returns a promise that resolves with the data received from the response
 */
export const sendVerificationCode = async (email) => {
  return await axios
    .post(`${APIURL}/api/account/send-verification-code/?email=${email}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        handleError(error);
      }
    );
};

/**
 * Function to verify code
 * @param {string} code - The verification code to be verified
 * @returns {Promise<Object>} Returns a promise that resolves with the data received from the response
 */
export const verifyCode = async (code) => {
  return await axios
    .post(`${APIURL}/api/account/verify-code/?code=${code}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        handleError(error);
      }
    );
};

/**
 * Function to initiate the password reset process by sending a reset link to the provided email
 * @param {string} email - The email address for which the password reset process should be initiated
 * @returns {Promise<Object>} Returns a promise that resolves with the data received from the response
 */
export const forgotPassword = async (email) => {
  return await axios
    .post(`${APIURL}/api/Account/forgot-password?email=${email}`)
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        handleError(error);
      }
    );
};

/**
 * Function to reset password
 * @param {Object} data - The data containing the reset password information
 * @returns {Promise<Object>} Returns a promise that resolves with the data received from the response
 */
export const resetPassword = async (data) => {
  return await axios.post(`${APIURL}/api/account/reset-password`, data).then(
    (response) => {
      return response.data;
    },
    (error) => {
      handleError(error);
    }
  );
};

/**
 * Function to update password
 * @param {Object} data - The data containing the new password information
 * @param {string} token - The authorization token
 * @returns {Promise<Object>} Returns a promise that resolves with the data received from the response
 */
export const updatePassword = async (data, token) => {
  return await axios
    .post(`${APIURL}/api/account/update-password`, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        handleError(error);
      }
    );
};

/**
 * Function to validate former password
 * @param {string} data - The former password to be validated
 * @param {string} token - The authorization token
 * @returns {Promise<Object>} Returns a promise that resolves with the data received from the response
 */
export const validateFormerPassword = async (data, token) => {
  return await axios
    .post(`${APIURL}/api/account/validate-former-password`, null, {
      params: { password: data },
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        handleError(error);
      }
    );
};

/**
 * Function to check the validity of an email address
 * @param {string} data - The email address to be checked for validity
 * @returns {Promise<Object>} Returns a promise that resolves with the data received from the response
 */
export const checkEmailValidity = async (data) => {
  return await axios
    .post(`${APIURL}/api/account/check-email-validity`, null, {
      params: { email: data },
    })
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        handleError(error);
      }
    );
};

/**
 * Function to get the count of users
 * @returns {Promise<number>} Returns a promise that resolves with the count of users
 */
export const getUsersCount = async () => {
  return await axios.get(`${APIURL}/api/user/count`).then(
    (response) => {
      return response.data;
    },
    (error) => {
      throw error.response.data.ErrorMessage;
    }
  );
};

/**
 * Function to get users based on search term and request parameters
 * @param {Object} request - The request parameters for filtering users
 * @param {string} searchTerm - The search term to filter users
 * @returns {Promise<Object>} Returns a promise that resolves with the data received from the response
 */
export const getUsers = async (request, searchTerm) => {
  return await axios
    .post(`${APIURL}/api/user/getAll/`, request, {
      params: { searchTerm: searchTerm },
    })
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        throw error.response.data.ErrorMessage;
      }
    );
};

/**
 * Function to logout the user
 * @returns {Promise<Object>} Returns a promise that resolves with the data received from the response
 */
export const logout = async () => {
  return await axios.get(`${APIURL}/api/account/logout`).then(
    (response) => {
      return response.data;
    },
    (error) => {
      handleError(error);
    }
  );
};

/**
 * Function to logout a recipient
 * @param {string} code - The code associated with the recipient for logout
 * @returns {Promise<Object>} Returns a promise that resolves with the data received from the response
 */
export const logoutRecipient = async (code) => {
  return await axios.get(`${APIURL}/api/account/logout-recipient/${code}`).then(
    (response) => {
      return response.data;
    },
    (error) => {
      handleError(error);
    }
  );
};

/**
 * Function to refresh the authentication token
 * @param {Object} data - The data containing information needed for token refresh
 * @param {string} token - The current authentication token
 * @returns {Promise<Object>} Returns a promise that resolves with the data received from the response
 */
export const refreshToken = async (data, token) => {
  return await axios
    .post(`${APIURL}/api/account/refresh-token`, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        handleError(error);
      }
    );
};

/**
 * Function to handle errors that occur during Axios requests
 * @param {Object} error - The error object received from Axios
 * @throws {string} Throws an exception containing error details
 */
const handleError = (error) => {
  // Check if the error status is 403 (Forbidden)
  if (error.response.status === 403) {
    // Redirect to access-denied page
    window.location.href = "/access-denied";
  }

  // Extract error details from the response data
  var exception = error.response.data;

  // Handle Asp.Net Identity Errors
  try {
    // Parse the error message if it exists
    exception = exception.hasOwnProperty("ErrorMessage")
      ? JSON.parse(exception.ErrorMessage)
      : exception.errors;

    // Convert the error object into a string format
    exception = Object.entries(exception)
      .map(([key, value]) => `${key}: ${value.join(", ")}`)
      .join(", ");
  } catch {
    // If parsing fails, use the original error message
    exception = exception.hasOwnProperty("ErrorMessage")
      ? exception.ErrorMessage
      : exception;
  }

  // Throw the exception
  throw exception;
};
