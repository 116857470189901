import React, { useState, useEffect, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import { Container, Row, Col, Modal, Spinner } from "react-bootstrap";

import NeedCard from "../profile/widgets/_needCard";
import Pagination from "../../components/pagination";
import Filters from "./widgets/_filters";
import { transformProfilePicture } from "../../components/transformFileURL";

import { postData } from "../../services/apiService";

/**
 * Component for searching needs.
 * Manages the search functionality for needs based on various filters.
 * @returns {React.Element} - Returns JSX for searching needs.
 */
const SearchNeeds = () => {
  // Get the current URL's query string
  const [searchParams] = useSearchParams();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [needs, setNeeds] = useState([]);
  const [page, setPage] = useState({ PageNumber: 1, PageSize: 12 });
  const [totalRecords, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState({
    searchTerm: "",
    sortBy: searchParams.has("type") ? searchParams.get("type") : "Published",
    categories: searchParams.has("category")
      ? [searchParams.get("category")]
      : [],
    cities: [],
    countries: [],
  });

  /**
   * Function to trigger a search based on the provided form data.
   * @param {object} formData - The form data containing search filters.
   */
  const triggerSearch = (formData) => {
    setPage((prevPage) => ({
      ...prevPage,
      PageNumber: 1,
    }));
    setFilters(formData);
  };

  useEffect(() => {
    /**
     * Hook to perform a search for needs via the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const searchNeeds = async () => {
      setIsPageLoading(true);
      try {
        filters.page = page;
        const response = await postData(`/api/need/search`, filters, null);

        const updatedNeeds = transformProfilePicture(response.needs);
        setNeeds(updatedNeeds);
        if (response.count != null) setTotalRecords(response.count);
        setIsPageLoading(false);
      } catch (error) {
        setNeeds([]);
        setIsPageLoading(false);
      }
    };
    searchNeeds();
  }, [filters, page]);

  /**
   * Function to handle page change event.
   * @param {number} pageNumber - The new page number.
   * @param {number} pageSize - The new page size.
   */
  const handlePageChange = (pageNumber, pageSize) => {
    setNeeds([]);
    setPage((prevPage) => ({
      ...prevPage,
      PageNumber: pageNumber,
      PageSize: pageSize,
    }));
  };

  return (
    <Fragment>
      <Container className="search-needs my-4">
        <Row>
          <Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={2}>
            <Filters
              triggerSearch={triggerSearch}
              sortBy={filters.sortBy}
              categories={filters.categories}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={9} xl={9} xxl={10} className="px-4">
            <h3 className="title page-title">
              <span className="position-relative fw-medium">
                Our
                <span className="title-divider"></span>
              </span>{" "}
              <span className="text-secondary">Needs</span>
            </h3>
            {totalRecords > 0 && (
              <p className="text-light-emphasis my-4">
                We found <span className="fw-semibold">{totalRecords}</span>{" "}
                {totalRecords > 1 ? "results" : "result"}
              </p>
            )}
            {totalRecords > 0 ? (
              <Row className="justify-content-center justify-content-sm-start">
                {needs.map((need) => (
                  <Col
                    xs={8}
                    sm={6}
                    md={6}
                    lg={5}
                    xl={4}
                    xxl={3}
                    key={need.referenceId}
                    className="grid-list"
                  >
                    <NeedCard need={need} />
                  </Col>
                ))}
                <Pagination
                  totalRecords={totalRecords}
                  page={page}
                  onPageChange={handlePageChange}
                />
              </Row>
            ) : (
              <p className="text-light-emphasis my-4">
                Currently, there are no results matching your search criteria.
              </p>
            )}
          </Col>
        </Row>
      </Container>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default SearchNeeds;
