import React, { useState, useReducer, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  FloatingLabel,
  Button,
  InputGroup,
  Spinner,
  Alert,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  isPossiblePhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";

import { postData, getData } from "../../services/apiService";
import { notify, initialState } from "../../store/notification";
import { useAuthContext } from "../../context/authProvider";

/**
 * Component for editing an existing user.
 * @returns {React.Element} - Returns JSX for editing a user.
 */
const EditUser = () => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    trigger,
    formState: { errors },
    watch,
  } = useForm();
  // Watch for changes in the phoneNumber field
  const phoneNumber = watch("phoneNumber");

  const navigate = useNavigate();

  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  // Extract the user ID from the URL parameters for pre-populating
  // user details for editing
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [maxLength, setMaxLength] = useState(20);

  useEffect(() => {
    /**
     * Prevent the user from adding in more numbers to this field if they exceed the limit of required digits
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    if (phoneNumber) {
      const formattedPhone = formatPhoneNumberIntl(phoneNumber);

      // Update maxLength if the cleaned phone number is valid
      if (isPossiblePhoneNumber(formattedPhone))
        setMaxLength(formattedPhone.length);
    }
  }, [phoneNumber, maxLength]);

  useEffect(() => {
    /**
     * Fetches the user data by user ID
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getById = async () => {
      try {
        const response = await getData(
          `/api/user/getById/${id}`,
          null,
          userSession
        );
        //Calling the reset function with an object (e.g. reset(user)) will update the defaultValues of the
        //form with the values from the object, so subsequent calls to reset() (without params) will use the new default values.
        reset(response);
      } catch (error) {
        showNotification("danger", error, 5000);
      }
    };
    if (id) getById();
  }, [id, userSession, reset]);

  /**
   * Function to handle the update of an existing user.
   * @param {Object} data - The updated data of the user.
   */
  const handleUpdateUser = async (data) => {
    setIsLoading(true);
    data.phoneNumber = formatPhoneNumberIntl(data.phoneNumber);
    try {
      const response = await postData(
        `/api/user/update/${id}`,
        data,
        userSession
      );
      setIsLoading(false);
      reset(data);
      showNotification("success", response, 5000);
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setIsLoading(false);
    }
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };
  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-bold mb-0">Edit User</h6>
          <Button
            onClick={() => navigate(-1)}
            variant="secondary"
            className="ms-3"
          >
            <i className="flaticon-line-arrow-left pe-2"></i>
            Back
          </Button>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <Form onSubmit={handleSubmit(handleUpdateUser)}>
              <Row className="align-items-center mb-4">
                <Col xs="auto">
                  <span className="fw-medium">Basic Info</span>
                </Col>
                <Col>
                  <hr className="m-0" />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={4}
                  className="mb-4"
                >
                  <InputGroup>
                    <Form.Floating>
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        {...register("firstName", {
                          required: "First name is required",
                          pattern: {
                            value:
                              /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                            message:
                              "First name shouldn't contain html or script tags",
                          },
                          maxLength: {
                            value: 50,
                            message: "First name cannot exceed 50 characters",
                          },
                        })}
                      />
                      <Form.Label>First Name</Form.Label>
                    </Form.Floating>
                    <InputGroup.Text>
                      <i className="flaticon-profile fs-4"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.firstName && (
                    <Form.Text className="text-danger">
                      {errors.firstName.message}
                    </Form.Text>
                  )}
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={4}
                  className="mb-4"
                >
                  <InputGroup>
                    <Form.Floating>
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        {...register("lastName", {
                          required: "Last name is required",
                          pattern: {
                            value:
                              /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                            message:
                              "Last name shouldn't contain html or script tags",
                          },
                          maxLength: {
                            value: 50,
                            message: "Last name cannot exceed 50 characters",
                          },
                        })}
                      />
                      <Form.Label>Last Name</Form.Label>
                    </Form.Floating>
                    <InputGroup.Text>
                      <i className="flaticon-profile fs-4"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.lastName && (
                    <Form.Text className="text-danger">
                      {errors.lastName.message}
                    </Form.Text>
                  )}
                </Col>

                <Col xs={12} sm={6} md={6} lg={6} xl={4} xxl={4}>
                  <InputGroup>
                    <Form.Floating>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        {...register("email", {
                          required: "Email is required",
                          maxLength: 256,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      <Form.Label>Email</Form.Label>
                    </Form.Floating>
                    <InputGroup.Text>
                      <i className="flaticon-mail fs-4"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.email && (
                    <Form.Text className="text-danger">
                      {errors.email.message}
                    </Form.Text>
                  )}
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={4} xxl={4}>
                  <InputGroup>
                    <FloatingLabel
                      label="Phone Number"
                      controlId="txtPhoneNumber"
                      htmlFor="txtPhoneNumber"
                    >
                      <PhoneInputWithCountry
                        name="phoneNumber"
                        id="txtPhoneNumber"
                        defaultCountry="US"
                        international={true}
                        withCountryCallingCode={true}
                        control={control}
                        onChange={(val) => {
                          trigger("phoneNumber");
                        }}
                        maxLength={maxLength}
                        rules={{
                          required: "Phone number is required",
                          validate: (value) => {
                            if (!isPossiblePhoneNumber(value)) {
                              setMaxLength(20);
                              return "Invalid phone number";
                            }
                            return true;
                          },
                        }}
                        className="form-control d-flex"
                      />
                    </FloatingLabel>
                    <InputGroup.Text>
                      <i className="flaticon-smartphone fs-3"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.phoneNumber && (
                    <Form.Text className="text-danger">
                      {errors.phoneNumber.message}
                    </Form.Text>
                  )}
                </Col>
              </Row>

              <Row>
                <Col xs={12} className="mt-5 mb-3">
                  <Form.Check
                    type="checkbox"
                    label="Designate as SuperAdmin"
                    className="fw-light text-dark-emphasis d-inline-block"
                    {...register("hasSuperAdminPrivileges")}
                  />
                </Col>
                <Col xs={12} className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label="Always take me to the dashboard after signing in"
                    className="fw-light text-dark-emphasis d-inline-block"
                    {...register("autoRedirectToDashboard")}
                  />
                </Col>
                <Col xs={12} className="mb-5">
                  <Form.Check
                    type="checkbox"
                    label="Force user to change their password the next time they sign in"
                    className="fw-light text-dark-emphasis d-inline-block"
                    {...register("isForceToChangePwd")}
                  />
                </Col>
                <div className="d-block">
                  <Button
                    variant="primary"
                    disabled={isLoading}
                    type="submit"
                    className="btn-md"
                  >
                    {isLoading ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "Save"
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    type="button"
                    className="ms-3 btn-md"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                </div>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
    </main>
  );
};

export default EditUser;
