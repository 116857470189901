import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Card,
  Modal,
  Spinner,
} from "react-bootstrap";

import { transformProfilePicture } from "../../components/transformFileURL";
import ValidatorCard from "../profile/widgets/_validatorCard";

import { getData, postData } from "../../services/apiService";

import noData from "../../resources/images/no-data.png";

/**
 * Component for listing all active validators associated with an organization.
 * The page is open to all users and can be viewed without the need for authentication.
 * @returns {React.Element} - Return JSX for viewing validators.
 */
const ValidatorList = () => {
  const { referenceId } = useParams();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [organization, setOrganization] = useState({});
  const [validators, setValidators] = useState([]);
  const [page, setPage] = useState({ PageNumber: 1, PageSize: 25 });
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    /**
     * Fetches the organization profile.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getOrgProfile = async () => {
      try {
        const response = await getData(
          `/api/profile/organization/${referenceId}`,
          null
        );
        if (response) setOrganization(response);
        else setIsPageLoading(false);
      } catch (error) {
        setIsPageLoading(false);
      }
    };
    getOrgProfile();
  }, [referenceId]);

  useEffect(() => {
    /**
     * Fetches the validators associated with an organization from the backend API.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getValidators = async () => {
      try {
        const response = await postData(`/api/profile/getValidators`, {
          organizationId: organization.organizationId,
          status: "Active",
          page: page,
          searchTerm: "",
        });

        const updatedValidators = transformProfilePicture(response.validators);
        setValidators((prevValidators) => [
          ...prevValidators,
          ...updatedValidators,
        ]);
        if (response.count != null) setTotalRecords(response.count);

        if (page.PageNumber === 1) setIsPageLoading(false);
        else setIsLoading(false);
      } catch (error) {
        if (page.PageNumber === 1) setIsPageLoading(false);
        else setIsLoading(false);
      }
    };
    if (organization?.organizationId > 0) getValidators();
  }, [page, organization]);

  /**
   * useEffect hook to handle lazy loading of more data when scrolling.
   * Attaches a scroll event listener to the provided element and fetches more data
   * when the user scrolls to the bottom of the element.
   * Removes the event listener when the component unmounts.
   * Executes when there is a change in the values of the dependencies in the useEffect hook.
   */
  useEffect(() => {
    // Event handler for scrolling
    const handleScroll = () => {
      // Check if the user has scrolled to the bottom of the page
      const isAtBottom =
        window.innerHeight + document.documentElement.scrollTop + 100 >=
        document.documentElement.offsetHeight;

      if (isAtBottom && !isLoading) {
        const totalPages = Math.ceil(totalRecords / page.PageSize);
        if (page.PageNumber < totalPages) {
          setIsLoading(true);
          // Increment the page number to load more validators
          setPage((prevPage) => ({
            ...prevPage,
            PageNumber: prevPage.PageNumber + 1,
          }));
        }
      }
    };
    // Add event listener for scroll events
    window.addEventListener("scroll", handleScroll);
    // Remove event listener on cleanup to avoid memory leaks
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page, totalRecords, isLoading]);

  return (
    <Container className="validator-list-view">
      {validators.length > 0 && (
        <Row className="justify-content-center">
          {location.key !== "default" && (
            <Col xs={12} className="text-end">
              <Link
                to={-1}
                className="text-decoration-none fw-semibold"
                title="Back"
              >
                <i className="flaticon-back pe-1 fs-5"></i>
                Back to Prior Page
              </Link>
            </Col>
          )}
          <Col xs={12} className="text-center pt-4 pb-5 page-title">
            <span className="text-primary tag-line">VALIDATORS</span>
            <h1 className="title mt-1 fw-medium">{organization.name}</h1>
          </Col>
          {validators.map((validator) => (
            <Col
              xs={8}
              sm={6}
              md={6}
              lg={4}
              xl={3}
              xxl={3}
              key={validator.referenceId}
              className="grid-list"
            >
              <ValidatorCard validator={validator} isEllipsisReady={true} />
            </Col>
          ))}
          {isLoading && (
            <Col xs={12} className="text-center my-5" aria-labelledby="Loading">
              <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          )}
        </Row>
      )}

      {!isPageLoading && Object.keys(validators).length === 0 && (
        <Row className="justify-content-center text-center">
          <Col xs={10} sm={12} md={9} lg={7} xl={6} xxl={5}>
            <Card className="border-0 shadow p-4 rounded-4 text-center">
              <Card.Body>
                <Image src={noData} alt="No Data Found" fluid />
                <h4 className="title mt-3">No Data Found</h4>
                <p className="mb-0 fw-light text-dark-emphasis">
                  There are no validators to show here right now.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ValidatorList;
