import React, { useReducer, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Image,
  Card,
  Nav,
  Popover,
  OverlayTrigger,
  Spinner,
  Alert,
  Modal,
} from "react-bootstrap";

import { transformProfilePicture } from "../../../components/transformFileURL";

import { postData } from "../../../services/apiService";
import { notify, initialState } from "../../../store/notification";
import { useAuthContext } from "../../../context/authProvider";

import { Host } from "../../../constants";

/**
 * Component for displaying recipient profile information.
 * @param {object} props - The props containing recipient profile data.
 * @returns {React.Element} - JSX for displaying recipient profile.
 */
const RecipientProfile = (props) => {
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isSubscription, setIsSubscription] = useState(
    props.need.isSubscription
  );

  const profilePicture = transformProfilePicture(props.need?.profilePicture, {
    isThumbnail: false,
  });

  /**
   * Function to checks if the user has permission to follow the need
   */
  const hasPermissionToFollowNeed = () =>
    !(
      props.need?.needStatus === "Published" ||
      props.need?.needStatus === "Funded"
    );

  /**
   * Function to handles the subscription of the need
   */
  const handleNeedSubscription = async () => {
    try {
      if (props.need.needId > 0) {
        setIsPageLoading(true);
        const response = await postData(
          "/api/profile/toggleNeedSubscription",
          { needId: props.need.needId },
          userSession
        );
        setIsSubscription(response);
        setIsPageLoading(false);
        if (response)
          showNotification(
            "success",
            "Thank you for following the need! We appreciate your support and look forward to providing you with valuable updates and content. Stay tuned for the latest news and offerings.",
            25000
          );
        else
          showNotification(
            "success",
            "We've received your request for unsubscribing. Your subscription will be canceled shortly.",
            10000
          );
      } else {
        showNotification(
          "danger",
          "In order to subscribe, the need must first be published.",
          5000
        );
      }
    } catch (error) {
      // Show error notification if an error occurs
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };
  return (
    <Fragment>
      <Card className="recipient-info bg-secondary">
        <div className="position-relative text-center">
          <Image src={profilePicture} alt="Profile" roundedCircle fluid />
        </div>
        <Card.Body>
          <h2 className="title text-center text-white mb-0">
            {props.need?.displayName}
            {props.isPreview && !props.roles?.includes("Recipient") && (
              <i
                className="flaticon-edit fs-5 ms-2"
                onClick={() => props.handleActiveTab("recipient-info")}
                role="button"
              ></i>
            )}
          </h2>

          <p className="text-center text-white fw-light fs-small">
            {[props.need?.address?.city, props.need?.address?.country]
              .filter(Boolean)
              .join(", ")}
          </p>
          <hr className="divider-sm m-auto border-light" />
          <Nav className="justify-content-center mt-2">
            <Nav.Link
              className="link-light"
              href={`https://www.facebook.com/sharer/sharer.php?&u=${encodeURI(
                `${Host}/profile/need/${props.need?.referenceId}/${props.need?.needId}`
              )} `}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="flaticon-facebook-outline fs-4"></i>
            </Nav.Link>
            <Nav.Link
              className="link-light"
              href={`https://twitter.com/intent/tweet?url=${encodeURI(
                `${Host}/profile/need/${props.need?.referenceId}/${props.need?.needId}`
              )} `}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="flaticon-twitter fs-4"></i>
            </Nav.Link>
            <Nav.Link
              className="link-light"
              disabled={hasPermissionToFollowNeed()}
            >
              {!props.isLoggedIn ? (
                // Display registration prompt if user is not logged in
                <OverlayTrigger
                  trigger="click"
                  placement="right"
                  overlay={
                    <Popover className="border-0 rounded-0">
                      <Popover.Body className="shadow text-dark-emphasis fw-light">
                        To follow needs, you must create a Benevolent account.{" "}
                        <Link
                          to="/register"
                          title="Register"
                          className="text-decoration-none link-secondary fw-semibold"
                        >
                          Register now »
                        </Link>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <i className="flaticon-add-user fs-4" title="Follow Need"></i>
                </OverlayTrigger>
              ) : isSubscription ? (
                <i
                  onClick={() => handleNeedSubscription()}
                  className="flaticon-delete-account fs-3"
                  title="Unfollow Need"
                ></i>
              ) : (
                <i
                  onClick={() => handleNeedSubscription()}
                  className="flaticon-add-user fs-4"
                  title="Follow Need"
                ></i>
              )}
            </Nav.Link>
            {props.need?.needUpdates?.length > 0 && (
              <Nav.Link className="link-light" href="#needUpdates">
                <i className="flaticon-refresh fs-3" title="Updates"></i>
              </Nav.Link>
            )}
          </Nav>
          <hr className="border-light mx-auto divider" />
          <div className="analytics text-center mt-4 mb-3">
            <div className="box pb-3 border-bottom">
              <i className="flaticon-dollar-circle fs-1"></i>
              <div className="ms-3 text-light">
                <div className="data">
                  ${props.need?.raised ? props.need?.raised : 0}
                  {props.isPreview && (
                    <i
                      className="flaticon-edit fs-5 ms-1"
                      role="button"
                      onClick={() => props.handleActiveTab("need-info")}
                    ></i>
                  )}
                </div>
                <small className="fw-light">Raised</small>
              </div>
            </div>
            <div className="box border-start border-bottom pb-3 ps-4">
              <i className="flaticon-help fs-1"></i>
              <div className="ms-3 text-light">
                <div className="data">
                  {props.need?.totalSupporters
                    ? props.need?.totalSupporters
                    : 0}
                </div>
                <small className="fw-light">Supporters</small>
              </div>
            </div>
            <div className="box pt-3">
              <i className="flaticon-calendar fs-2"></i>
              <div className="ms-3 text-light">
                <div className="data">
                  {props.need?.needStatus === "Funded"
                    ? props.need?.fundedOn
                    : props.need?.daysLeft
                    ? props.need?.daysLeft
                    : 0}
                </div>
                <small className="fw-light">
                  {props.need?.needStatus === "Funded"
                    ? "Need Met On"
                    : "Days Left"}
                </small>
              </div>
            </div>
            <div className="box border-start pt-3 ps-4">
              <i className="flaticon-users fs-1"></i>
              <div className="ms-3 text-light">
                <div className="data">
                  {props.need?.totalFollowers ? props.need?.totalFollowers : 0}
                </div>
                <small className="fw-light">Followers</small>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
export default RecipientProfile;
