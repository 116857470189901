import React, { useState, useReducer, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  FloatingLabel,
  InputGroup,
  Image,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";
import { useForm } from "react-hook-form";

import { notify, initialState } from "../../store/notification";
import invitation from "../../resources/images/invitation-dark.png";
import { useAuthContext } from "../../context/authProvider";
import { postData } from "../../services/apiService";

/**
 * Component for handling recipient login functionality.
 * Renders a login form for recipients to authenticate.
 * @returns {React.Element} - JSX for recipient login form.
 */
const RecipientLogin = () => {
  // Destructure useForm() hook to access form-related functions and state
  const {
    register, // Function to register form inputs
    handleSubmit, // Function to handle form submission
    formState: { errors }, // Errors object containing form validation errors
  } = useForm();
  const { saveToken } = useAuthContext();
  const [notification, dispatch] = useReducer(notify, initialState);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

 /**
 * Function  to handles the submission of recipient login data.
 * Sends a request to the server to authenticate the recipient.
 * @param {object} data - The data containing recipient login information.
 */
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await postData(
        `/api/account/recipient-login/${data.code}`,
        null
      );
      setIsLoading(false);
      saveToken(response.token); // Persist the JWT token in the client's browser using local storage.
    } catch (error) {
      // Show a notification if an error occurs during recipient login
      showNotification("danger", error, 5000);
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Container className="recipient-login">
        <Row className="justify-content-center">
          <Col xs={11} sm={10} md={8} lg={6} xl={5} xxl={4}>
            <Card className="border-0 shadow p-4 rounded-4">
              <Card.Body>
                <Image
                  src={invitation}
                  alt="Invitation"
                  fluid
                  className="m-auto d-block"
                />
                <h4 className="title mt-4 text-center">Tell your story</h4>
                <p className="mb-4 fw-light text-dark-emphasis text-center">
                  In the next few screens, you will describe what you need so
                  you can tell your story. Enter your code below. If you didn't
                  have a code, check with person who's helping you.
                </p>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <InputGroup size="sm" className="mb-2">
                    <InputGroup.Text>
                      <i className="flaticon-invoice fs-3"></i>
                    </InputGroup.Text>
                    <FloatingLabel
                      label="Invite Code"
                      controlId="txtCode"
                      htmlFor="txtCode"
                    >
                      <Form.Control
                        type="password"
                        placeholder="Invite Code"
                        {...register("code", {
                          required: "Invite code is required",
                          pattern: {
                            value:
                              /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                            message:
                              "Invite code shouldn't contain HTML or script tags",
                          },
                        })}
                      />
                    </FloatingLabel>
                    <InputGroup.Text className="p-0">
                      <Button
                        type="submit"
                        disabled={isLoading}
                        variant="secondary"
                        className="btn-md rounded-1"
                      >
                        {isLoading ? (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          "Begin"
                        )}
                      </Button>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.password && (
                    <Form.Text className="text-danger">
                      {errors.password.message}
                    </Form.Text>
                  )}
                </Form>
              </Card.Body>
            </Card>
            <div className="text-center mt-4">
              <p className="mb-0 text-dark-emphasis fw-light fs-small">
                This page for recipients to describe their needs while working
                with a Benevolent validating partner.{" "}
                <Link
                  to="/"
                  title="FAQ"
                  className="text-decoration-none link-secondary fw-semibold"
                >
                  Learn how Benevolent works
                </Link>
                .
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
    </Fragment>
  );
};

export default RecipientLogin;
