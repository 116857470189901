import { createContext, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useLocalStorage, decodeToken } from "../utils/session";
import { PRIMARY_SITES } from "../constants";

// Create the authentication context
// Context provides a way to share data between components without passing props
// explicitly at every level of the component tree. It is especially useful when you have
// data that needs to be accessed by multiple components at different levels.
const AuthContext = createContext();

/**
 * Custom React hook to access the authentication context
 * @returns {Object} Returns the authentication context
 */
export const useAuthContext = () => useContext(AuthContext);

// Create a provider component for the authentication context
const AuthProvider = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useLocalStorage("isLoggedIn", false);
  const [returnURL, setReturnURL] = useLocalStorage("returnURL", "");
  const [userSession, setUserSession] = useLocalStorage("session", null);
  const navigate = useNavigate();
  const location = useLocation();

  const saveToken = (token, fromLogin = false) => {
    // Persist the JWT token in the client's browser using local storage.
    setUserSession(token);
    setLoggedIn(true);

    // Retrieve the payload by decoding the token.
    const user = decodeToken(token);

    if (user === null) navigate("/unauthorized");
    else {
      if (fromLogin) {
        // If the user possesses only one role, the data type of role will be a string; however,
        // if the user has multiple roles, the role will be an array.
        // if (!Array.isArray(user.roles) && user.roles.includes("Donor"))
        //   navigate("/giving-history");
        if (user.RedirectToDashboard === "True") navigate("/dashboard");
        // Extract the return URL from useState
        else if (returnURL) navigate(decodeURIComponent(returnURL));
      } else if (location.pathname) navigate(location.pathname);
      else if (user.roles.includes("Recipient"))
        navigate(`/update-my-story/${user.needId}`);
      else {
        if (
          user.roles.includes("SuperAdmin") ||
          user.roles.includes("Staff") ||
          user.roles.includes("Validator") ||
          user.roles.includes("Donor")
        )
          navigate("/dashboard");

        // if (user.roles.includes("Donor")) navigate("/giving-history");
      }
    }
  };

  // This function is responsible for initiating the logout process,
  // setting the userSession to null and performing any cleanup tasks associated with logging out.
  const clearToken = () => {
    setUserSession(null);
    setLoggedIn(false);
    setReturnURL(encodeURIComponent(location.pathname));

    // For explore authentication widget
    // Get the parent window URL of the iframe
    const parentURL = document.referrer;

    // Function to check if a URL belongs to the primary site
    const isPrimarySite = (url) => {
      if (!url) return false;
      const parentHostname = new URL(url).hostname.toLowerCase();
      return PRIMARY_SITES.some((site) => parentHostname === site);
    };

    // Check if the current site is the primary site (WordPress Site)
    const isCurrentSitePrimary = isPrimarySite(parentURL);

    // If the current site is not the primary site, navigate to the login page
    // with the returnUrl parameter set to the current path
    if (!isCurrentSitePrimary) {
      navigate(`/?returnUrl=${encodeURIComponent(location.pathname)}`, {
        replace: true,
      });
    } else {
      // Send a message to the parent window
      window.parent.postMessage("reload", "*");
    }
  };

  useEffect(() => {
    // Each browser tab maintains its own instance of localStorage.
    // Therefore, changes made to localStorage in one tab will not automatically propagate to other tabs.
    // To ensure that the authentication state is synchronized across multiple tabs, we need a mechanism
    // for communicating changes made to localStorage from one tab to all other tabs.
    // One common approach is to use the storage event, which is fired when a storage area changes

    // Listen for changes to localStorage in other tabs
    const handleStorageChange = (event) => {
      if (event.key === "session") {
        const newSession = JSON.parse(event.newValue);
        setLoggedIn(newSession != null);
        // It synchronizes the local state with changes made to the local storage,
        // ensuring consistency across different browser instances.
        setUserSession(newSession);
      }
      // Handle other keys as needed...
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [setLoggedIn, setUserSession]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        userSession,
        saveToken,
        clearToken,
        returnURL,
        setReturnURL,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
