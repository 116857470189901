import React, { Fragment, useState, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Card,
  Dropdown,
  Button,
  Alert,
  Modal,
  Spinner,
  Image,
  Badge,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { postData } from "../../services/apiService";
import { useAuthContext } from "../../context/authProvider";
import { notify, initialState } from "../../store/notification";
import DataTable from "../../components/table";
import confirmation from "../../resources/images/confirmation.png";

/**
 * Component for managing validator invitations.
 * @returns {React.Element} - Returns JSX for managing validator invitations.
 */
const ValidatorInvitations = () => {
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [page, setPage] = useState({ PageNumber: 1, PageSize: 25 });
  const [invitations, setInvitations] = useState([]);
  const [totalRecords, setTotalRecords] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  // Columns configuration for the invitation table
  const columns = [
    {
      header: "Name",
      accessorKey: "name",
      cell: (cellProps) => {
        return (
          <Fragment>
            <div className="mb-0">
              {cellProps.row.original.firstName}{" "}
              {cellProps.row.original.lastName}
            </div>
            <small className="fw-light text-light-emphasis">
              {" "}
              {cellProps.row.original.emailAddress}
            </small>
          </Fragment>
        );
      },
    },
    {
      header: "Organization",
      accessorKey: "organizationName",
    },
    {
      header: "Request Type",
      accessorKey: "type",
    },
    {
      header: "Suggested By",
      accessorKey: "suggestedBy",
    },
    {
      header: "Date Requested On",
      accessorKey: "createdOn",
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: (cellProps) => {
        let background;
        switch (cellProps.row.original.status) {
          case "Draft":
            background = "light";
            break;
          case "Pending":
            background = "warning";
            break;
          case "Confirmed":
            background = "info";
            break;
          case "Accepted":
            background = "success";
            break;
          case "Declined":
            background = "danger";
            break;
          default:
            background = "dark";
            break;
        }
        return (
          <Badge className="fw-semibold text-dark" bg={background}>
            {cellProps.row.original.status.toUpperCase()}
          </Badge>
        );
      },
    },
    {
      header: "Actions",
      accessorKey: "invitationId",
      cell: (cellProps) => {
        const data = cellProps.row.original;
        return (
          <Dropdown drop="down" className="more-menu">
            <Dropdown.Toggle variant="light" size="sm">
              <i className="flaticon-more"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {data.status === "Draft" && (
                <Dropdown.Item to={`/validator/edit/${data.code}`} as={Link}>
                  <i className="flaticon-user-edit fs-4 me-2"></i>
                  Edit
                </Dropdown.Item>
              )}
              {(data.status === "Confirmed" || data.status === "Draft") && (
                <Dropdown.Item
                  onClick={() => showConfirmation(data.invitationId, "Accept")}
                >
                  <i className="flaticon-approve-user fs-4 me-2"></i>
                  Accept
                </Dropdown.Item>
              )}
              {data.status !== "Declined" && (
                <Dropdown.Item
                  onClick={() => showConfirmation(data.invitationId, "Decline")}
                >
                  <i className="flaticon-block-user fs-4 me-2"></i>
                  Decline
                </Dropdown.Item>
              )}
              {data.status === "Accepted" && (
                <Dropdown.Item
                  onClick={() => resendInvitation(data.emailAddress)}
                >
                  <i className="flaticon-user-list fs-4 me-2"></i>
                  Reinvite
                </Dropdown.Item>
              )}
              {(data.status === "Draft" || data.status === "Declined") && (
                <Dropdown.Item
                  onClick={() => showConfirmation(data.code, "Delete")}
                >
                  <i className="flaticon-delete fs-4 me-2"></i>
                  Delete
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  /**
   * Function to show a confirmation modal for updating the invitation status.
   * @param {string} id - The ID of the invitation.
   * @param {string} status - The status to set for the invitation (e.g., 'Accept' or 'Decline' or 'Delete').
   */
  const showConfirmation = (id, status) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Card className="border-0 shadow rounded-3">
            <Card.Body className="p-4 text-center">
              <Image src={confirmation} alt="Delete Confirmation" fluid />
              <div className="mt-3 fw-light">
                Do you really want to {status.toLowerCase()} this user? <br />
                {status === "Delete" && "This process can't be undone."}
              </div>
            </Card.Body>
            <Card.Footer className="bg-transparent py-4 text-center">
              <Button
                title="Cancel"
                variant="link"
                className="link-danger fw-semibold"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                className="ms-4 btn-md"
                onClick={async () => {
                  try {
                    if (status === "Delete") await handleDeleteInvitation(id);
                    else await handleUpdateStatus(id, status);
                  } catch (error) {
                    // showNotification("danger", error, 5000);
                  }
                  onClose();
                }}
              >
                Yes, {status}
              </Button>
            </Card.Footer>
          </Card>
        );
      },
    });
  };

  /**
   * Function to resend an invitation to the specified email address.
   * @param {string} email - The email address to which the invitation will be resent.
   */
  const resendInvitation = async (email) => {
    setIsPageLoading(true);
    try {
      const response = await postData(
        `/api/invitation/resend/${email}`,
        null,
        userSession
      );
      setIsPageLoading(false);
      showNotification("success", response, 5000);
      setPage((prevPage) => ({
        ...prevPage,
        pageNumber: prevPage.pageNumber,
      }));
    } catch (error) {
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  /**
   * Function to handle the update of invitation status.
   * @param {string} id - The ID of the invitation.
   * @param {string} status - The new status for the invitation ('Accept' or 'Decline').
   */
  const handleUpdateStatus = async (id, status) => {
    setIsPageLoading(true);
    try {
      const apiURL =
        status === "Accept"
          ? `/api/invitation/accept/${id}`
          : `/api/invitation/decline/${id}`;
      const response = await postData(apiURL, null, userSession);
      setIsPageLoading(false);
      showNotification("success", response, 5000);
      setPage((prevPage) => ({
        ...prevPage,
        pageNumber: prevPage.pageNumber,
      }));
    } catch (error) {
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  /**
   * Function to handle the deletion of an invitation in draft status.
   * @param {string} code - The code of the invitation to be deleted.
   */
  const handleDeleteInvitation = async (code) => {
    setIsPageLoading(true);
    try {
      const response = await postData(
        `/api/invitation/delete/${code}`,
        null,
        userSession
      );
      setIsPageLoading(false);
      showNotification("success", response, 5000);
      setPage((prevPage) => ({
        ...prevPage,
        pageNumber: prevPage.pageNumber,
      }));
    } catch (error) {
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    /**
     * Fetches the invitations from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getAll = async () => {
      setIsLoading(true);
      try {
        const response = await postData(
          `/api/invitation/getByPage?searchTerm=${searchTerm}`,
          page,
          userSession
        );
        setInvitations(response.invitations);
        if (response.count != null) setTotalRecords(response.count);
        setIsLoading(false);
      } catch (error) {
        showNotification("danger", error, 5000);
        //setInvitations([]);
        setIsLoading(false);
      }
    };

    getAll();

    // Return a cleanup function
    return () => {};
  }, [searchTerm, page, userSession]);

  /**
   * Function to handle page change event.
   * @param {number} pageNumber - The new page number.
   * @param {number} pageSize - The new page size.
   */
  const handlePageChange = (pageNumber, pageSize) => {
    setInvitations([]);
    setPage({
      PageNumber: pageNumber,
      PageSize: pageSize,
    });
  };

  /**
   * Function to handle search action.
   * @param {string} value - The search term entered by the user.
   */
  const handleSearch = (value) => {
    handlePageChange(1, page.PageSize);
    setSearchTerm(value);
  };

  /**
   * Function to show a confirmation dialog for updating the validator status.
   * @param {string} id - The ID of the validator.
   * @param {string} action - The action to perform (e.g., "Delete", "Activate", "Deactivate").
   * @param {string} status - The status to set for the validator (e.g., "Active", "Inactive", "Deleted").
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-semibold mb-0">Invitations</h6>
          <div>
            <Link
              to="/validator/create"
              className="ms-3 btn btn-primary"
              title="Create Validator"
            >
              + Add Validator
            </Link>
            <Link
              to="/validator"
              className="ms-3 btn btn-secondary"
              title="Back"
            >
              <i className="flaticon-line-arrow-left pe-2"></i>
              Back
            </Link>
          </div>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <DataTable
              columns={columns}
              data={invitations}
              totalRecords={totalRecords}
              page={page}
              onPageChange={handlePageChange}
              searchRecords={handleSearch}
              loadingState={isLoading}
            />
          </Card.Body>
        </Card>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default ValidatorInvitations;
