import React, { useState, useEffect, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  TabContainer,
  Tab,
  Nav,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
  Alert,
  Spinner,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

import {
  isPossiblePhoneNumber,
  isSupportedCountry,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/react-hook-form-input";

import { postData } from "../../services/apiService";
import { notify, initialState } from "../../store/notification";
import { SITEURL } from "../../constants";

import logo from "../../resources/images/logo.svg";

/**
 * Component for partner enrollment.
 * Allows registration of a new organization in the system and also requests a validator account for that organization.
 * @returns {JSX.Element} - Returns JSX for partner enrollment.
 */
const PartnerEnrollment = () => {
  // Initialize two forms using the useForm hook
  const {
    register: registerForm1,
    handleSubmit: handleForm1,
    formState: { errors: errorsForm1 },
    getFieldState: getFieldStateForm1,
  } = useForm();
  const {
    register: registerForm2,
    handleSubmit: handleForm2,
    formState: { errors: errorsForm2, isValid: isForm2Valid },
    control,
    trigger,
    setValue,
    setValue: setValueForm2,
    watch,
  } = useForm({
    defaultValues: {
      country: "United States",
    },
  });
  // Watch for changes in the phoneNumber field
  const phoneNumber = watch("phoneNumber");
  const navigate = useNavigate();
  const [fieldState, setFieldState] = useState(
    getFieldStateForm1("emailAddress")
  );

  const [notification, dispatch] = useReducer(notify, initialState);
  const [activeTab, setActiveTab] = useState("basic-info");
  const [selectedCountry, setSelectedCountry] = useState("United States");
  const [formData, setFormData] = useState({});
  const [loadingStates, setLoadingStates] = useState({});
  const [selectedCountryCode, setSelectedCountryCode] = useState("US");
  const [maxLength, setMaxLength] = useState(20);

  useEffect(() => {
    /**
     * Prevent the user from adding in more numbers to this field if they exceed the limit of required digits
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    if (phoneNumber) {
      const formattedPhone = formatPhoneNumberIntl(phoneNumber);

      // Update maxLength if the cleaned phone number is valid
      if (isPossiblePhoneNumber(formattedPhone))
        setMaxLength(formattedPhone.length);
    }
  }, [phoneNumber, maxLength]);

  /**
   * Function to update the formData state and switches to the next tab if specified.
   * @param {Object} data - The form data to be submitted.
   * @param {string} tab - The name of the tab to move to after submission (optional).
   */
  const onSubmit = (data, tab) => {
    // Move to the next tab if validation passes
    setFormData((prevData) => ({ ...prevData, ...data }));
    if (tab) setActiveTab(tab);
  };

  useEffect(() => {
    /**
     * Function to handle the enrollment process for inviting a validator with new organization
     * registration and sends a confirmation email to verify the authenticity of the invitation.
     */
    const handleEnrollment = async () => {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        isSubmitted: true,
      }));
      try {
        formData.phoneNumber = formatPhoneNumberIntl(formData.phoneNumber);

        const response = await postData(
          "/api/invitation/partner-enrollment",
          formData,
          null
        );
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          isSubmitted: false,
        }));
        navigate("/partner/email-verification", {
          replace: true,
          state: {
            email: formData.emailAddress,
            isValid: response?.isNewValidator,
          },
        });
      } catch (error) {
        // Show a notification if an error occurs
        showNotification("danger", error, 5000);
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          isSubmitted: false,
        }));
      }
    };

    // Execute the enrollment process when Form 2 is valid
    if (isForm2Valid) handleEnrollment();
  }, [formData, navigate]);

  /**
   * Updates the field state with the latest state of the 'emailAddress' field
   * whenever the 'getFieldState' function changes.
   * This effect ensures that the field state stays synchronized with the form state.
   */
  useEffect(() => {
    setFieldState(getFieldStateForm1("emailAddress"));
  }, [getFieldStateForm1]);

  /**
   * Function to check the availability of the provided email address using the backend API
   * @param {string} value - The email address to check availability for.
   * @returns {boolean|Error} - Returns true if the email is available, or an Error object if the email is not available or an error occurs.
   */
  const handleEmailAvailability = async (value) => {
    if (!fieldState.invalid) {
      try {
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          isValidEmail: true,
        }));
        await postData(
          `/api/invitation/verify-email-availability/${value}`,
          null,
          null
        );
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          isValidEmail: false,
        }));
        return true;
      } catch (error) {
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          isValidEmail: false,
        }));
        return error;
      }
    }
  };

  /**
   * Function to handle changes in the website input field.
   * It appends 'https://' to the input value if it doesn't start with 'http://' or 'https://'.
   * @param {Object} event - The event object containing information about the input field change.
   */
  const handleWebsiteChange = (event) => {
    let inputWebsite = event.target.value.trim();
    if (!inputWebsite) {
      setValueForm2("website", "");
      return;
    }
    // Check if the inputWebsite starts with 'http://' or 'https://'
    if (!inputWebsite.match(/^(ftp|http|https):\/\//)) {
      // If not, prepend 'http://'
      inputWebsite = "https://" + inputWebsite;
      // Update the form value using setValue
      setValueForm2("website", inputWebsite);
    }
  };
  useEffect(() => {
    /**
     * Function to fetch the country code
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const matchedCountry = CountryRegionData.find(
      (country) => country[0] === selectedCountry
    );

    setSelectedCountryCode(matchedCountry ? matchedCountry[1] : "");
  }, [selectedCountry]);

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main className="d-flex align-items-center justify-content-center min-vh-100 flex-column">
      <Container>
        <Row className="justify-content-center my-4">
          <Col xs={11} sm={11} md={9} lg={7} xl={6} xxl={5}>
            <div className="text-center mb-4">
              <Link to={SITEURL} title="Benevolent">
                <Image src={logo} alt="Benevolent" className="logo" fluid />
              </Link>
              <h3 className="title mt-4 mb-3">Apply to become a partner</h3>
              <p className="text-dark-emphasis mb-1">
                We're excited that you want to become a Benevolent partner.{" "}
              </p>
              <p className="text-dark-emphasis mb-0">
                To begin, we need to learn more about you and your organization.
              </p>
            </div>
            <Card className="border-0 shadow rounded-4">
              <Card.Body className="px-0">
                <TabContainer
                  id="enrollment-tabs"
                  defaultActiveKey="basic-info"
                  activeKey={activeTab}
                  onSelect={(tab) => setActiveTab(tab)}
                >
                  <Nav variant="tabs" fill className="border-bottom">
                    <Nav.Item>
                      <Nav.Link eventKey="basic-info" className="fw-semibold">
                        <i className="flaticon-id-card pe-2 fs-4"></i>
                        <span className="align-text-bottom">Basic Info</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="organization-info"
                        className="fw-semibold"
                        disabled={activeTab !== "organization-info"}
                      >
                        <i className="flaticon-user-info pe-2 fs-4"></i>
                        <span className="align-text-bottom">
                          Organization Info
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content className="p-4">
                    <Tab.Pane eventKey="basic-info">
                      <Form
                        onSubmit={handleForm1((data) =>
                          onSubmit(data, "organization-info")
                        )}
                      >
                        <Row>
                          <Col className="mb-4">
                            <InputGroup>
                              <FloatingLabel
                                label="Firstname"
                                controlId="txtFirstname"
                                htmlFor="txtFirstname"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Firstname"
                                  {...registerForm1("firstName", {
                                    required: "First name is required",
                                    pattern: {
                                      value:
                                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                      message:
                                        "First name shouldn't contain HTML or script tags",
                                    },
                                    maxLength: {
                                      value: 50,
                                      message:
                                        "First name cannot exceed 50 characters",
                                    },
                                  })}
                                />
                              </FloatingLabel>
                              <InputGroup.Text>
                                <i className="flaticon-profile fs-4"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            {errorsForm1.firstName && (
                              <Form.Text className="text-danger">
                                {errorsForm1.firstName.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col className="mb-4">
                            <InputGroup>
                              <FloatingLabel
                                label="Lastname"
                                controlId="txtLastname"
                                htmlFor="txtLastname"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Lastname"
                                  {...registerForm1("lastName", {
                                    required: "Last name is required",
                                    pattern: {
                                      value:
                                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                      message:
                                        "Last name shouldn't contain HTML or script tags",
                                    },
                                    maxLength: {
                                      value: 50,
                                      message:
                                        "Last name cannot exceed 50 characters",
                                    },
                                  })}
                                />
                              </FloatingLabel>
                              <InputGroup.Text>
                                <i className="flaticon-profile fs-4"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            {errorsForm1.lastName && (
                              <Form.Text className="text-danger">
                                {errorsForm1.lastName.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={12} className="mb-4">
                            <InputGroup>
                              <FloatingLabel
                                label="Email"
                                controlId="txtEmail"
                                htmlFor="txtEmail"
                              >
                                <Form.Control
                                  type="email"
                                  placeholder="Email"
                                  {...registerForm1("emailAddress", {
                                    required: "Email is required",
                                    maxLength: 256,
                                    pattern: {
                                      value:
                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: "Invalid email address",
                                    },
                                    validate: handleEmailAvailability,
                                  })}
                                />
                              </FloatingLabel>
                              {loadingStates["isValidEmail"] ? (
                                <InputGroup.Text>
                                  <Spinner animation="border" role="status">
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </Spinner>
                                </InputGroup.Text>
                              ) : (
                                <InputGroup.Text>
                                  <i className="flaticon-mail fs-4"></i>
                                </InputGroup.Text>
                              )}
                            </InputGroup>
                            {errorsForm1.emailAddress && (
                              <Form.Text className="text-danger">
                                {errorsForm1.emailAddress.message}
                              </Form.Text>
                            )}
                          </Col>
                          {/* <Col xs={12} className="mb-4">
                            <InputGroup>
                              <FloatingLabel
                                label="Job Title"
                                controlId="txtJobTitle"
                                htmlFor="txtJobTitle"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Job Title"
                                  {...registerForm1("jobTitle", {
                                    required: "Job title is required",
                                    pattern: {
                                      value:
                                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                      message:
                                        "Job title shouldn't contain HTML or script tags",
                                    },
                                    maxLength: {
                                      value: 100,
                                      message:
                                        "Job title cannot exceed 100 characters",
                                    },
                                  })}
                                />
                              </FloatingLabel>
                              <InputGroup.Text>
                                <i className="flaticon-suitcase fs-4"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            {errorsForm1.jobTitle && (
                              <Form.Text className="text-danger">
                                {errorsForm1.jobTitle.message}
                              </Form.Text>
                            )}
                          </Col> */}
                          <Col className="d-grid mt-4">
                            <Button variant="primary" type="submit" size="lg">
                              NEXT
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="organization-info">
                      <Form
                        onSubmit={handleForm2((data) => onSubmit(data, null))}
                      >
                        <Row>
                          <Col xs={12} className="mb-4">
                            <InputGroup>
                              <FloatingLabel
                                label="Organization Name"
                                controlId="txtOrgName"
                                htmlFor="txtOrgName"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Organization Name"
                                  {...registerForm2("organizationName", {
                                    required: "Organization name is required",
                                    pattern: {
                                      value:
                                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                      message:
                                        "Organization name shouldn't contain HTML or script tags",
                                    },
                                    maxLength: {
                                      value: 250,
                                      message:
                                        "Organization name  cannot exceed 250 characters",
                                    },
                                  })}
                                />
                              </FloatingLabel>
                              <InputGroup.Text>
                                <i className="flaticon-layers fs-4"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            {errorsForm2.organizationName && (
                              <Form.Text className="text-danger">
                                {errorsForm2.organizationName.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={12} className="mb-4">
                            <InputGroup>
                              <FloatingLabel
                                label="Street Address"
                                controlId="txtAddressLine1"
                                htmlFor="txtAddressLine1"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Street Address"
                                  {...registerForm2("address1", {
                                    required: "Street address is required",
                                    pattern: {
                                      value:
                                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                      message:
                                        "Street address shouldn't contain HTML or script tags",
                                    },
                                    maxLength: {
                                      value: 50,
                                      message:
                                        "Street address cannot exceed 50 characters",
                                    },
                                  })}
                                />
                              </FloatingLabel>
                              <InputGroup.Text>
                                <i className="flaticon-location fs-4"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            {errorsForm2.address1 && (
                              <Form.Text className="text-danger">
                                {errorsForm2.address1.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={12} className="mb-4">
                            <InputGroup>
                              <FloatingLabel
                                label="Address Line 2"
                                controlId="txtAddressLine2"
                                htmlFor="txtAddressLine2"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Address Line 2"
                                  {...registerForm2("address2", {
                                    pattern: {
                                      value:
                                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                      message:
                                        "Address Line2 shouldn't contain HTML or script tags",
                                    },
                                    maxLength: {
                                      value: 50,
                                      message:
                                        "Address Line 2 cannot exceed 50 characters",
                                    },
                                  })}
                                />
                              </FloatingLabel>
                              <InputGroup.Text>
                                <i className="flaticon-location fs-4"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            {errorsForm2.address2 && (
                              <Form.Text className="text-danger">
                                {errorsForm2.address2.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={6} className="mb-4">
                            <FloatingLabel
                              label="Country"
                              controlId="ddCountry"
                              htmlFor="ddCountry"
                            >
                              <Controller
                                control={control}
                                name="country"
                                id="ddCountry"
                                rules={{ required: "Country is required" }}
                                render={({ field: { onChange, value } }) => (
                                  <CountryDropdown
                                    value={value || ""}
                                    classes="form-select"
                                    onChange={(val) => {
                                      onChange(val);
                                      setSelectedCountry(val);
                                      setValue("phoneNumber", "");
                                    }}
                                  />
                                )}
                              />
                            </FloatingLabel>
                            {errorsForm2.country && (
                              <Form.Text className="text-danger">
                                {errorsForm2.country.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={6} className="mb-4">
                            <FloatingLabel
                              label="State"
                              controlId="ddState"
                              htmlFor="ddState"
                            >
                              <Controller
                                control={control}
                                name="state"
                                id="ddState"
                                rules={{ required: "State is required" }}
                                render={({ field: { onChange, value } }) => (
                                  <RegionDropdown
                                    country={selectedCountry}
                                    value={value || ""}
                                    classes="form-select"
                                    onChange={(val) => onChange(val)}
                                  />
                                )}
                              />
                            </FloatingLabel>
                            {errorsForm2.state && (
                              <Form.Text className="text-danger">
                                {errorsForm2.state.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={6} className="mb-4">
                            <InputGroup>
                              <FloatingLabel
                                label="City"
                                controlId="txtCity"
                                htmlFor="txtCity"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="City"
                                  {...registerForm2("city", {
                                    required: "City is required",
                                    pattern: {
                                      value:
                                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                      message:
                                        "City shouldn't contain HTML or script tags",
                                    },
                                    maxLength: {
                                      value: 50,
                                      message:
                                        "City cannot exceed 50 characters",
                                    },
                                  })}
                                />
                              </FloatingLabel>
                              <InputGroup.Text>
                                <i className="flaticon-location fs-4"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            {errorsForm2.city && (
                              <Form.Text className="text-danger">
                                {errorsForm2.city.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={6} className="mb-4">
                            <InputGroup>
                              <FloatingLabel
                                label="Zip Code"
                                controlId="txtZipCode"
                                htmlFor="txtZipCode"
                              >
                                <Form.Control
                                  type="number"
                                  placeholder="Zip Code"
                                  {...registerForm2("zipCode", {
                                    required: "Zip code is required",
                                    pattern: {
                                      value:
                                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                      message:
                                        "Zip code shouldn't contain HTML or script tags",
                                    },
                                    maxLength: {
                                      value: 20,
                                      message:
                                        "Zip code cannot exceed 20 characters",
                                    },
                                  })}
                                />
                              </FloatingLabel>
                              <InputGroup.Text>
                                <i className="flaticon-location fs-4"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            {errorsForm2.zipCode && (
                              <Form.Text className="text-danger">
                                {errorsForm2.zipCode.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={12} className="mb-4">
                            <InputGroup>
                              <FloatingLabel
                                label="Phone Number"
                                controlId="txtPhoneNumber"
                                htmlFor="txtPhoneNumber"
                              >
                                <PhoneInput
                                  name="phoneNumber"
                                  id="txtPhoneNumber"
                                  control={control}
                                  country={
                                    isSupportedCountry(selectedCountryCode)
                                      ? selectedCountryCode
                                      : ""
                                  }
                                  international={true}
                                  withCountryCallingCode={true}
                                  maxLength={maxLength}
                                  onChange={(val) => {
                                    trigger("phoneNumber");
                                  }}
                                  rules={{
                                    required: "Phone number is required",
                                    validate: (value) => {
                                      if (!selectedCountry)
                                        return "Country is required";
                                      if (
                                        !isPossiblePhoneNumber(
                                          value,
                                          selectedCountryCode
                                        )
                                      ) {
                                        setMaxLength(20);
                                        return "Invalid phone number";
                                      }
                                      return true;
                                    },
                                  }}
                                  className="form-control"
                                />
                              </FloatingLabel>
                              <InputGroup.Text>
                                <i className="flaticon-smartphone fs-3"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            {errorsForm2.phoneNumber && (
                              <Form.Text className="text-danger">
                                {errorsForm2.phoneNumber.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={12} className="mb-4">
                            <InputGroup>
                              <FloatingLabel
                                label="Website"
                                controlId="txtWebsite"
                                htmlFor="txtWebsite"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Website"
                                  {...registerForm2("website", {
                                    required: "Website is required",
                                    pattern: {
                                      value: /^(ftp|http|https):\/\/[^ "]+$/i,
                                      message: "Enter a valid URL",
                                    },
                                    maxLength: {
                                      value: 1000,
                                      message:
                                        "Website cannot exceed 1000 characters",
                                    },
                                    onChange: (e) => {
                                      handleWebsiteChange(e);
                                    },
                                  })}
                                />
                              </FloatingLabel>
                              <InputGroup.Text>
                                <i className="flaticon-link fs-4"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            {errorsForm2.website && (
                              <Form.Text className="text-danger">
                                {errorsForm2.website.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={6} className="mt-4">
                            <Button
                              variant="primary"
                              type="button"
                              size="lg"
                              className="w-100"
                              onClick={() => setActiveTab("basic-info")}
                            >
                              BACK
                            </Button>
                          </Col>
                          <Col xs={6} className="mt-4">
                            <Button
                              type="submit"
                              variant="primary"
                              size="lg"
                              className="w-100"
                              disabled={loadingStates["isSubmitted"]}
                            >
                              {loadingStates["isSubmitted"] ? (
                                <Spinner animation="border" role="status">
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </Spinner>
                              ) : (
                                "SUBMIT"
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Tab.Pane>
                  </Tab.Content>
                </TabContainer>
              </Card.Body>
            </Card>
            <div className="text-center mt-4 text-dark-emphasis">
              Already Registered?{" "}
              <Link
                to="/"
                title="Sign In"
                className="text-decoration-none link-secondary fw-semibold"
              >
                Sign In
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
    </main>
  );
};

export default PartnerEnrollment;
