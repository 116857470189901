import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";

import StatusElement from "./statusElement";

/**
 * Component for displaying transaction status.
 * @returns {JSX.Element} - Returns JSX for displaying transaction status.
 */
const Status = () => {
  const { referenceId } = useParams();
  const [cookies, , removeCookie] = useCookies();

  useEffect(() => {
    // If the transaction concludes with any status, the cookie should be deleted.
    const cookieNames = Object.keys(cookies);
    cookieNames
      .filter((cookieName) => cookieName.startsWith("_benevolent"))
      .forEach((cookieName) =>
        removeCookie(cookieName, { path: "/", sameSite: "none", secure: true })
      );
  }, [cookies, removeCookie]);

  // Render the StatusElement component with the reference ID
  return <StatusElement referenceId={referenceId} />;
};

export default Status;
