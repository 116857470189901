import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { Container, Modal, Spinner } from "react-bootstrap";

import Need from "./_orgPreferences";

import { postData } from "../../services/apiService";
import { transformProfilePicture } from "../../components/transformFileURL";

/**
 * Component for previewing organization giving page.
 * This component allows users to preview how the organization giving page will appear.
 * while changing the primary and secondary color.
 * @returns {React.Element} - JSX for previewing organization giving page.
 */
const PreviewOrgGivingPage = () => {
  const params = useParams();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [givingPage] = useState(params);
  const [needs, setNeeds] = useState([]);

  useEffect(() => {
    /**
     * Function to fetch needs for an organization from the backend API.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getNeeds = async () => {
      setIsPageLoading(true);
      try {
        const response = await postData(
          "/api/need/getNeedsByType",
          {
            organizationId: params.id,
            type: params.needType,
            page: { PageNumber: 1, PageSize: 4 },
          },
          null
        );
        const updatedNeeds = transformProfilePicture(response.needs);
        setNeeds(updatedNeeds);
        setIsPageLoading(false);
      } catch (error) {
        setNeeds([]);
        setIsPageLoading(false);
      }
    };
    getNeeds();
  }, [params.id, params.needType]);

  return (
    <Fragment>
      {needs.length > 0 && (
        <Container>
          <Need
            needs={needs}
            givingPage={givingPage}
            isWhiteLabel={false}
            isPreview={true}
          />
        </Container>
      )}
      {needs.length === 0 && (
        <div className="d-flex min-vh-100 align-items-center justify-content-center text-dark-emphasis fw-semibold">
          Currently, there is no needs.
        </div>
      )}

      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="light">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
export default PreviewOrgGivingPage;
