import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Row, Col, Card, Image, Spinner } from "react-bootstrap";
import Select from "react-select";

import { transformProfilePicture } from "../../../components/transformFileURL";
import { getData } from "../../../services/apiService";

import NoData from "../../../resources/images/no-data.png";


// By defining defaultReport the component, they remain constant and do not cause re-renders.
// This approach ensures that our defaultReport are static and the useEffect dependencies are correctly set, 
// avoiding any unnecessary warnings or re-renders.
const defaultReport = {
  totalActiveNeeds: 0,
  totalDonors: 0,
  totalRaised: 0,
  totalFundedNeeds: 0,
  needsFundedPercentage: 0,
  donorAverageContribution: 0,
};

/**
 * Component for displaying organization summary report.
 * This component provides a summary report for organizations including total active needs, funded needs,
 * donors, and raised funds.
 * @param {object} props - The properties passed to the component.
 * @returns {React.Element} - Returns JSX for displaying organization summary report.
 * @access Accessible by SuperAdmin, Staff, or Validator.
 */
const OrgSummaryReport = (props) => {

  const [organizations, setOrganizations] = useState([]);
  const [report, setReport] = useState(defaultReport);
  const [selectedOrg, setSelectedOrg] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    /**
     * Function to fetch list of organizations from the backend API
     * If user is a SuperAdmin or Staff, get all active organizations from the system
     * For Validator, get organizations belonging to them
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getOrganizations = async () => {
      try {
        const response = await getData(
          `/api/organization/getOrganizations`,
          null,
          props.userSession
        );
        setOrganizations(response);
        // If there are organizations, select the first one by default
        if (response.length > 0) setSelectedOrg(response[0]);
      } catch (error) {
        setOrganizations([]);
      }
    };

    getOrganizations();
  }, [props]);

  /**
   * Fetches summary report data for a specific organization from the backend API.
   * @param {string} orgId - The ID of the organization for which to fetch the summary report.
   */
  const getOrgSummaryReport = useCallback(async (orgId) => {
    setIsLoading(true);
    try {
      const response = await getData(
        `/api/dashboard/getOrgSummaryReport/${orgId}`,
        null,
        props.userSession
      );

      if (response) setReport(response);
      else setReport(defaultReport);
      setIsLoading(false);
    } catch (error) {
      setReport(defaultReport);
      setIsLoading(false);
    }
  },[props.userSession]);

  useEffect(() => {
    /**
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    if (Object.keys(selectedOrg).length > 0)
      getOrgSummaryReport(selectedOrg.organizationId);
    else setReport(defaultReport);
  }, [selectedOrg, getOrgSummaryReport]);



  /**
   * Function to handle the selection of an organization.
   * @param {object} value - The selected organization value.
   */
  const handleSelectedOrganization = (value) => {
    setSelectedOrg(value || {});
  };

  /**
   * Custom filter function to filter options in a dropdown/select component based on search text.
   * @param {object} option - The option object to filter.
   * @param {string} searchText - The search text entered by the user.
   * @returns {boolean} - Indicates whether the option matches the search text.
   */
  const customFilterOption = (option, searchText) => {
    return option.data.label.toLowerCase().includes(searchText.toLowerCase());
  };

  return (
    <Card className="border-0 rounded-3 my-2">
      <Card.Body className="p-4 org-summary-report">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h6 className="fw-semibold mb-0">Org Summary Report</h6>
          <div>
            {organizations.length > 0 && (
              <Select
                isSearchable={true}
                isClearable={true}
                placeholder="Organization"
                className="react-select-container custom-select"
                classNamePrefix="react-select"
                defaultValue={selectedOrg}
                onChange={(val) => handleSelectedOrganization(val)}
                options={organizations}
                noOptionsMessage={() => "No results found"}
                filterOption={customFilterOption}
                getOptionLabel={(e) => (
                  <Fragment>
                    <Image
                      src={transformProfilePicture(e.profilePicture)}
                      alt={e.label}
                      roundedCircle
                      fluid
                      width="35"
                      className="me-2 align-middle"
                    />

                    <span>{e.label}</span>
                  </Fragment>
                )}
              />
            )}
          </div>
        </div>
        {organizations.length > 0 && !isLoading && (
          <Row>
            <Col xs={12} sm={6} md={12} lg={6} className="my-2">
              <div className="h-100 d-flex align-items-center justify-content-between p-3 rounded-3 border">
                <div>
                  <small className="text-dark-emphasis">ACTIVE NEEDS</small>
                  <div className="fs-5 fw-semibold mt-1">
                    {report.totalActiveNeeds}
                  </div>
                </div>
                <div className="ms-2 icon active-needs">
                  <i className="flaticon-star fs-1"></i>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} md={12} lg={6} className="my-2">
              <div className="h-100 d-flex align-items-center justify-content-between p-3 rounded-3 border">
                <div>
                  <small className="text-dark-emphasis">FUNDED NEEDS</small>
                  <div className="fs-5 fw-semibold mt-1">
                    {report.totalFundedNeeds}
                  </div>
                </div>
                <div className="ms-2 icon funded-needs">
                  <i className="flaticon-feature fs-1"></i>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} md={12} lg={6} className="my-2">
              <div className="h-100 d-flex align-items-center justify-content-between p-3 rounded-3 border">
                <div>
                  <small className="text-dark-emphasis">
                    PERCENTAGE OF NEEDS <br /> FUNDED
                  </small>
                  <div className="fs-5 fw-semibold mt-1">
                    {report.needsFundedPercentage}%
                  </div>
                </div>
                <div className="ms-2 icon needs-funded-in-percentage">
                  <i className="flaticon-dollar-circle fs-1"></i>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} md={12} lg={6} className="my-2">
              <div className="h-100 d-flex align-items-center justify-content-between p-3 rounded-3 border">
                <div>
                  <small className="text-dark-emphasis">
                    AVERAGE AMOUNT GIVEN BY EACH DONOR
                  </small>
                  <div className="fs-5 fw-semibold mt-1">
                    ${report.donorAverageContribution}
                  </div>
                </div>
                <div className="ms-2 icon avg-amount">
                  <i className="flaticon-money fs-1"></i>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} md={12} lg={6} className="my-2">
              <div className="h-100 d-flex align-items-center justify-content-between p-3 rounded-3 border">
                <div>
                  <small className="text-dark-emphasis">DONORS</small>
                  <div className="fs-5 fw-semibold mt-1">
                    {report.totalDonors}
                  </div>
                </div>
                <div className="ms-2 icon donors">
                  <i className="flaticon-user-list fs-1"></i>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} md={12} lg={6} className="my-2">
              <div className="h-100 d-flex align-items-center justify-content-between p-3 rounded-3 border">
                <div>
                  <small className="text-dark-emphasis">
                    TOTAL RAISED AMOUNT
                  </small>
                  <div className="fs-5 fw-semibold mt-1">
                    ${report.totalRaised}
                  </div>
                </div>
                <div className="ms-2 icon raised-amount">
                  <i className="flaticon-dollar-circle fs-1"></i>
                </div>
              </div>
            </Col>
          </Row>
        )}
        {isLoading && (
          <div className="text-center">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        {organizations.length === 0 && !isLoading && (
          <div className="text-center text-dark-emphasis">
            <Image src={NoData} alt="No Data Found" fluid />
            <p className="mb-0 fw-semibold">No Data Found</p>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default OrgSummaryReport;
