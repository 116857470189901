import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

import { getData } from "../services/apiService";

/**
 * Component to display the number of needs chosen by the user.
 * Retrieves the quantity of items in the user's cart and displays it as "Needs Chosen".
 * @param {Object} props - The props object containing the userId.
 * @param {number} props.userId - The ID of the user.
 * @returns {JSX.Element} - Returns JSX for displaying the number of needs chosen.
 */
const NeedsChosen = ({ userId }) => {
  const [cookies, setCookie] = useCookies();
  const cartId = cookies["_benevolent_cartId"] ?? 0;
  const needsChosen = cookies["_benevolent_needs_chosen"] ?? 0;

  useEffect(() => {
    /**
     * Retrieves the quantity of items in the user's cart.
     */
    const getCartQuantity = async () => {
      try {
        const response = await getData(
          `/api/cart/get-quantity/${cartId}/${userId}`,
          null,
          null
        );
        if (response.cartId > 0) {
          const expirationDate = new Date();
          expirationDate.setDate(new Date().getDate() + 1);
          setCookie("_benevolent_cartId", response.cartId, {
            path: "/",
            expires: expirationDate,
            sameSite: "none",
            secure: true,
          });
          setCookie("_benevolent_needs_chosen", response.quantity, {
            path: "/",
            expires: expirationDate,
            sameSite: "none",
            secure: true,
          });
        }
      } catch (error) {}
    };

    // Call getCartQuantity function if cartId or userId is valid
    if (cartId > 0 || userId) getCartQuantity();
    // Return a cleanup function
    return () => {
      // Cleanup logic
      // You can add more cleanup actions here if needed
    };
  }, [cartId, userId, setCookie]);

  return (
    <Link to="/donation/checkout" className="text-decoration-none text-dark fs-small">
      <span className="cart-quantity d-inline-block me-2 rounded-circle text-center">
        {needsChosen}
      </span>
      Needs Chosen
    </Link>
  );
};

export default NeedsChosen;
