import axios from "axios";
import { APIURL, Job_APIURL } from "../constants";

/**
 * It controls whether or not Axios will send cookies with cross-site Access-Control requests. 
 * By default, this property is set to false
 * This will tell Axios to include any cookies associated with the domain of the request in the request headers.
 */
axios.defaults.withCredentials = true;

/**
 * Function to fetch data from an API endpoint
 * @param {string} endPoint - The endpoint to fetch data from
 * @param {Object} data - The data to be sent with the request
 * @param {string} token - The authentication token
 * @returns {Promise<Object>} Returns a promise that resolves with the data received from the response
 */
export const getData = async (endPoint, data, token) => {
  const appendEndPoint = endPoint.startsWith("/api/")
    ? `${APIURL}${endPoint}`
    : `${Job_APIURL}${endPoint}`;
  return await axios
    .get(appendEndPoint, {
      params: data,
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        handleError(error);
      }
    );
};

/**
 * Function to post data to an API endpoint
 * @param {string} endPoint - The endpoint to post data to
 * @param {Object} data - The data to be sent with the request
 * @param {string} token - The authentication token
 * @returns {Promise<Object>} Returns a promise that resolves with the data received from the response
 */
export const postData = async (endPoint, data, token) => {
  const appendEndPoint = endPoint.startsWith("/api/")
    ? `${APIURL}${endPoint}`
    : `${Job_APIURL}${endPoint}`;
  return await axios
    .post(appendEndPoint, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        handleError(error);
      }
    );
};

/**
 * Function to update data using PUT request to an API endpoint
 * @param {string} endPoint - The endpoint to update data at
 * @param {Object} data - The data to be sent with the request
 * @param {string} token - The authentication token
 * @returns {Promise<Object>} Returns a promise that resolves with the data received from the response
 */
export const putData = async (endPoint, data, token) => {
  const appendEndPoint = endPoint.startsWith("/api/")
    ? `${APIURL}${endPoint}`
    : `${Job_APIURL}${endPoint}`;

  return await axios
    .put(appendEndPoint, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        handleError(error);
      }
    );
};

/**
 * Function to delete data using DELETE request to an API endpoint
 * @param {string} endPoint - The endpoint to delete data from
 * @param {Object} data - The data to be sent with the request
 * @param {string} token - The authentication token
 * @returns {Promise<Object>} Returns a promise that resolves with the data received from the response
 */
export const deleteData = async (endPoint, data, token) => {
  const appendEndPoint = endPoint.startsWith("/api/")
    ? `${APIURL}${endPoint}`
    : `${Job_APIURL}${endPoint}`;

  return await axios
    .delete(appendEndPoint, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        handleError(error);
      }
    );
};

/**
 * Function to handle errors that occur during Axios requests
 * @param {Object} error - The error object received from Axios
 * @throws {string} Throws an exception containing error details
 */
const handleError = (error) => {
  // Check if the error status is 403 (Forbidden)
  if (error.response.status === 403) {
    // Redirect to access-denied page
    window.location.href = "/access-denied";
  }

  // Extract error details from the response data
  var exception = error.response.data;

  // Handle Asp.Net Identity Errors
  try {
    // Parse the error message if it exists
    exception = exception.hasOwnProperty("ErrorMessage")
      ? JSON.parse(exception.ErrorMessage)
      : exception.errors;

    // Convert the error object into a string format
    exception = Object.entries(exception)
      .map(([key, value]) => `${key}: ${value.join(", ")}`)
      .join(", ");
  } catch {
    // If parsing fails, use the original error message
    exception = exception.hasOwnProperty("ErrorMessage")
      ? exception.ErrorMessage
      : exception;
  }

  // Throw the exception
  throw exception;
};
