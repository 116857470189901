import React, { useState, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Form,
  Button,
  InputGroup,
  Spinner,
  Alert,
  Modal,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";

import {
  createAccount,
  authenticateWithSocialProvider,
} from "../../services/accountService";
import { notify, initialState } from "../../store/notification";
import { useAuthContext } from "../../context/authProvider";
import { Facebook_AppId, Google_ClientId, SITEURL } from "../../constants";

import logo from "../../resources/images/logo.svg";
import google from "../../resources/images/google.png";

/**
 * Component for donor registration.
 * @returns {React.Element} - Returns JSX for donor registration.
 * @access Accessible by Donor
 */
const DonorRegistration = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const password = watch("password");

  const navigate = useNavigate();
  // Destructure the 'saveToken' object from the 'useAuthContext()' hook
  const { saveToken } = useAuthContext();
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setPageLoading] = useState(false);

  /**
   * Function to handle user registration and redirect to their appropriate pages.
   * @param {object} data - The user registration data.
   */
  const handleRegister = async (data) => {
    setIsLoading(true);
    try {
      await createAccount(data);
      setIsLoading(false);
      navigate("/email-verification", {
        replace: true,
        state: { email: data.email },
      });
    } catch (error) {
      // Show error notification if an error occurs
      showNotification("danger", error, 5000);
      setIsLoading(false);
    }
  };

  /**
   * Function to register a user using social media provider.
   * @param {object} data - The data object containing user information from the social media provider.
   */
  const registerWithSocialProvider = async (data) => {
    setPageLoading(true);
    // Extract user data from the response and format it
    var user = {
      email: data.data.email,
      provider: data.provider,
      firstName:
        data.provider === "google"
          ? data.data.given_name
          : data.data.first_name,
      lastName:
        data.provider === "google"
          ? data.data.family_name
          : data.data.last_name,
      providerKey: data.provider === "google" ? data.data.sub : data.data.id,
      accessToken:
        data.provider === "google"
          ? data.data.access_token
          : data.data.accessToken,
    };
    try {
      const result = await authenticateWithSocialProvider(user);
      // Persist the JWT token in the client's browser using local storage.
      // True parameter indicates it is from the login page
      saveToken(result, true);
    } catch (error) {
      setPageLoading(false);
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
    }
  };

  /**
   * Function to handle authentication rejection from social media provider.
   * @param {object} err - The error object received from the authentication process.
   */
  const handleAuthReject = (err) => {
    if (err.authResponse != null) {
      showNotification(
        "danger",
        "An error occurred during the login process.",
        5000
      );
    }
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main className="d-flex align-items-center justify-content-center min-vh-100 flex-column">
      <Container>
        <Row className="justify-content-center">
          <Col xs={11} sm={11} md={9} lg={9} xl={6} xxl={5}>
            <div className="text-center mb-4">
              <Link to={SITEURL} title="Benevolent">
                <Image src={logo} alt="Benevolent" className="logo" fluid />
              </Link>
            </div>
            <Card className="border-0 shadow p-4 rounded-4">
              <Card.Body>
                <div className="d-flex gap-2 mb-4 justify-content-between">
                  {/* Register via Facebook */}
                  <LoginSocialFacebook
                    appId={Facebook_AppId}
                    onResolve={registerWithSocialProvider}
                    onReject={handleAuthReject}
                  >
                    <Button className="d-flex align-items-center btn btn-facebook">
                      <div className="flex-shrink-0 icon">
                        <i className="flaticon-facebook-filled fs-4"></i>
                      </div>
                      <div className="flex-grow-1 ms-3 text-start">
                        Sign up with Facebook
                      </div>
                      {/* )} */}
                    </Button>
                  </LoginSocialFacebook>
                  {/* Register via Google */}
                  <LoginSocialGoogle
                    client_id={Google_ClientId}
                    scope="profile email"
                    onResolve={registerWithSocialProvider}
                    onReject={handleAuthReject}
                  >
                    <Button className="d-flex align-items-center btn btn-google">
                      <div className="flex-shrink-0 icon">
                        <Image src={google} alt="Sign in with Google" fluid />
                      </div>
                      <div className="flex-grow-1 ms-3 text-start">
                        Sign up with Google
                      </div>
                    </Button>
                  </LoginSocialGoogle>
                </div>
                <Row className="align-items-center">
                  <Col>
                    <hr />
                  </Col>
                  <Col xs={4} className="text-center p-0">
                    <small className="text-light-emphasis fw-light">
                      or Sign up with Email
                    </small>
                  </Col>
                  <Col>
                    <hr />
                  </Col>
                </Row>
                <Form onSubmit={handleSubmit(handleRegister)}>
                  <Row className="mt-4">
                    <Col className="mb-3">
                      <InputGroup>
                        <Form.Floating>
                          <Form.Control
                            type="text"
                            placeholder="Firstname"
                            {...register("firstName", {
                              required: "First name is required",
                              pattern: {
                                value:
                                  /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                message:
                                  "First name shouldn't contain HTML or Script tags",
                              },
                              maxLength: {
                                value: 50,
                                message:
                                  "First name cannot exceed 50 characters",
                              },
                            })}
                          />
                          <Form.Label>Firstname</Form.Label>
                        </Form.Floating>
                        <InputGroup.Text>
                          <i className="flaticon-profile fs-4"></i>
                        </InputGroup.Text>
                      </InputGroup>
                      {errors.firstName && (
                        <Form.Text className="text-danger">
                          {errors.firstName.message}
                        </Form.Text>
                      )}
                    </Col>
                    <Col className="mb-3">
                      <InputGroup>
                        <Form.Floating>
                          <Form.Control
                            type="text"
                            placeholder="Lastname"
                            {...register("lastName", {
                              required: "Last name is required",
                              pattern: {
                                value:
                                  /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                message:
                                  "Last name shouldn't contain HTML or Script tags",
                              },
                              maxLength: {
                                value: 50,
                                message:
                                  "Last name cannot exceed 50 characters",
                              },
                            })}
                          />
                          <Form.Label>Lastname</Form.Label>
                        </Form.Floating>
                        <InputGroup.Text>
                          <i className="flaticon-profile fs-4"></i>
                        </InputGroup.Text>
                      </InputGroup>
                      {errors.lastName && (
                        <Form.Text className="text-danger">
                          {errors.lastName.message}
                        </Form.Text>
                      )}
                    </Col>
                    <Col xs={12} className="text-end mb-3">
                      <small className="fw-light text-dark-emphasis">
                        Not sold or shared with others.
                        <Link
                          to={`${SITEURL}/about/privacy-policy`}
                          title="Privacy Policy"
                          className="text-decoration-none fw-semibold"
                          target="_blank"
                        >
                          {" "}
                          We promise
                        </Link>
                      </small>
                    </Col>
                    <Col xs={12} className="mb-4">
                      <InputGroup>
                        <Form.Floating>
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            {...register("email", {
                              required: "Email is required",
                              maxLength: 256,
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address",
                              },
                            })}
                          />
                          <Form.Label>Email</Form.Label>
                        </Form.Floating>
                        <InputGroup.Text>
                          <i className="flaticon-mail fs-4"></i>
                        </InputGroup.Text>
                      </InputGroup>
                      {errors.email && (
                        <Form.Text className="text-danger">
                          {errors.email.message}
                        </Form.Text>
                      )}
                    </Col>
                    <Col className="mb-4">
                      <InputGroup>
                        <Form.Floating>
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            {...register("password", {
                              required: "Password is required",
                              pattern: {
                                value:
                                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@@$!%*?&])[A-Za-z\d$@@$!%*?&]{6,}/,
                                message:
                                  "Password must contain at least 6 characters, including at least one uppercase letter, one lowercase letter, one special character, and one number.",
                              },
                            })}
                          />
                          <Form.Label>Password</Form.Label>
                        </Form.Floating>
                        <InputGroup.Text>
                          <i className="flaticon-password fs-3"></i>
                        </InputGroup.Text>
                      </InputGroup>
                      {errors.password && (
                        <Form.Text className="text-danger">
                          {errors.password.message}
                        </Form.Text>
                      )}
                    </Col>
                    <Col className="mb-4">
                      <InputGroup>
                        <Form.Floating>
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            {...register("confirmPassword", {
                              required: "Confirm Password is required",
                              validate: (value) =>
                                value === password || "Passwords do not match",
                            })}
                          />
                          <Form.Label>Re-type Password</Form.Label>
                        </Form.Floating>
                        <InputGroup.Text>
                          <i className="flaticon-password fs-3"></i>
                        </InputGroup.Text>
                      </InputGroup>
                      {errors.confirmPassword && (
                        <Form.Text className="text-danger">
                          {errors.confirmPassword.message}
                        </Form.Text>
                      )}
                    </Col>
                    <Col xs={12}>
                      <Form.Check
                        type="checkbox"
                        label={
                          <span>
                            By registering, you agree to our{" "}
                            <Link
                              to={`${SITEURL}/about/terms-of-use`}
                              target="_blank"
                              title="Terms of Use"
                              className="text-decoration-none fw-semibold"
                            >
                              terms of use
                            </Link>
                          </span>
                        }
                        className="fw-light text-dark-emphasis"
                        {...register("privacy", {
                          required: "Agree to the terms and conditions.",
                        })}
                      />
                    </Col>
                    {errors.privacy && (
                      <Form.Text className="text-danger">
                        {errors.privacy.message}
                      </Form.Text>
                    )}
                  </Row>
                  <div className="d-grid gap-2 mt-4">
                    <Button
                      variant="primary"
                      disabled={isLoading}
                      type="submit"
                      size="lg"
                    >
                      {isLoading ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        "REGISTER"
                      )}
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
            <div className="text-center mt-4">
              <p className="mb-0 text-dark-emphasis">
                Already Registered?{" "}
                <Link
                  to="/"
                  title="Sign In"
                  className="text-decoration-none link-secondary fw-semibold"
                >
                  Sign In
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Notification alerts */}
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>

      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default DonorRegistration;
