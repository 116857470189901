import React, {
  useState,
  useEffect,
  useReducer,
  useRef,
  Fragment,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Image,
  Card,
  Button,
  Spinner,
  Modal,
  Alert,
} from "react-bootstrap";
import { useCookies } from "react-cookie";

import { postData } from "../../services/apiService";
import { notify, initialState } from "../../store/notification";
import {
  NeedProfileURL,
  ValidatorProfileURL,
  OrgProfileURL,
} from "../../constants";

/**
 * Component for listing needs for a giving page of a particular organization.
 * Renders a list of needs, such as newly added needs, attention required needs, etc.
 * @param {array} props.needs - The array of needs to be displayed.
 * @param {string} props.givingPage - The giving page of the organization.
 * @param {boolean} props.isWhiteLabel - Indicates whether the organization is using white labeling.
 * @param {boolean} props.isPreview - Indicates whether the needs are being previewed.
 * @returns {React.Element} - JSX for displaying a list of needs.
 */
const Need = ({ needs, givingPage, isWhiteLabel, isPreview }) => {
  const navigate = useNavigate();

  const needRef = useRef();
  const [notification, dispatch] = useReducer(notify, initialState);
  const [cookies, setCookie] = useCookies();

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [cartId] = useState(cookies["_benevolent_cartId"] ?? 0);

  /**
   * Fucntion to apply frame styles to need cards based on search parameters
   * @param {NodeList} frameContent - NodeList of card elements within the frame
   */
  const applyFrameStylesFromSearchParams = (frameContent) => {
    Array.from(frameContent).forEach((content) => {
      const card = content.querySelector(".need");
      if (card) {
        const cardBody = card.querySelector(".card-body");
        if (cardBody) {
          const labels = cardBody.querySelectorAll(".text-secondary");
          if (labels) {
            labels.forEach((labelStyle) => {
              applyColorProperty(labelStyle, "TEXT", "secondaryColor");
            });
          }         

          const progressBar = cardBody.querySelector(".progress-bar");
          if (progressBar)
            applyColorProperty(progressBar, "PROGRESS BAR", "secondaryColor");
        }
        const cardFooter = card.querySelector(".card-footer");
        if (cardFooter) {
          const button = cardFooter.querySelector("button");
          if (button) applyColorProperty(button, "BUTTON", "primaryColor");
        }
      }
    });
  };

  /**
   * Function to apply color properties to specified element types
   * @param {HTMLElement} element - The HTML element to apply styles to
   * @param {string} elementType - Type of element (e.g., BUTTON, TEXT, PROGRESS BAR)
   * @param {string} colorParam - Name of the color parameter from the givingPage object
   */
  const applyColorProperty = (element, elementType, colorParam) => {
    const color = givingPage[colorParam];
    if (elementType === "BUTTON") {
      element.style.setProperty("background-color", color, "important");
      element.style.setProperty("border-color", color, "important");
    }
    if (elementType === "PROGRESS BAR") {
      element.style.setProperty("background-color", color, "important");
      element.style.setProperty("border-color", color, "important");
    }
    if (elementType === "TEXT")
      element.style.setProperty("color", color, "important");
  };

  useEffect(() => {
    if (isWhiteLabel || isPreview) {
      const frameContent = needRef.current ? needRef.current.children : null;
      if (frameContent && frameContent?.length > 0)
        applyFrameStylesFromSearchParams(frameContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [givingPage, needs, isWhiteLabel, isPreview]);

  /**
   * Fucntion to create a new cart for the specified need
   * @param {number} needId - ID of the need to add to the cart
   */
  const createCart = async (needId) => {
    if (isPreview) return;
    setIsPageLoading(true);
    try {
      const response = await postData(
        `/api/cart/create`,
        {
          cartId,
          needId: needId,
          orgGivingPageId: isWhiteLabel ? givingPage.id : null,
        },
        null
      );
      setIsPageLoading(false);
      const expirationDate = new Date();
      expirationDate.setDate(new Date().getDate() + 1);

      setCookie("_benevolent_cartId", response, {
        path: "/", // Set the cookie to be valid across the entire domain by specifying the path as "/"
        expires: expirationDate, // Set the expiration date for the cookie
        sameSite: "none", // Set the sameSite attribute to "none" for cross-site requests
        secure: true, // Require the cookie to be sent over secure (HTTPS) connections
      });

      if (isWhiteLabel) {
        navigate(
          `/donation/checkout?frameLayout=plain&code=${
            givingPage.code
          }&primaryColor=${encodeURIComponent(
            givingPage.primaryColor
          )}&secondaryColor=${encodeURIComponent(givingPage.secondaryColor)}`
        );
      } else navigate("/donation/checkout");
    } catch (error) {
      // Show a notification if an error occurs during cart creation
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <Fragment>
      <Row
        className="mt-5 justify-content-center justify-content-sm-start"
        ref={needRef}
      >
        {needs.map((need) => (
          <Col
            xs={8}
            sm={6}
            md={6}
            lg={4}
            xl={3}
            xxl={3}
            key={need.referenceId}
            className="grid-list"
          >
            <Card className="border-0 shadow-sm rounded-4 h-100 need">
              <Card.Body>
                <Link
                  to={`${NeedProfileURL}${need.referenceId}/${need.needId}`}
                  title="Need Profile"
                  className="profile-pic"
                  target="_blank"
                >
                  <Image
                    src={need.profilePicture}
                    alt="Profile Picture"
                    fluid
                    className="rounded-circle"
                  ></Image>
                </Link>
                <p className="mt-5 pt-2 mb-2 fs-small">
                  <Link
                    to={`${NeedProfileURL}${need.referenceId}/${need.needId}`}
                    className="text-decoration-none text-secondary fw-semibold"
                    title="Need Profile"
                    target="_blank"
                  >
                    {need.displayName}
                  </Link>
                  <span className="text-secondary fw-light">
                    {" "}
                    from {need.city}, {need.country}
                  </span>
                </p>
                <h5 className="title mb-3">
                  <Link
                    to={`${NeedProfileURL}${need.referenceId}/${need.needId}`}
                    className="text-decoration-none link-dark"
                    target="_blank"
                    title="Need Profile"
                  >
                    {need.title}
                  </Link>
                </h5>
                <p className="mb-2">
                  <span className="fw-bold fs-5">${need.raised}</span>
                  <span className="fs-6"> of ${need.amountNeeded}</span>
                </p>
                <div
                  className={`progress ${
                    need.needStatus === "Funded" &&
                    need.percentageCompleted < 100
                      ? "bg-secondary-subtle"
                      : ""
                  }`}
                  role="progressbar"
                  aria-label="Percentage of Need Funded"
                >
                  <div
                    className={`progress-bar ${
                      need.percentageCompleted > 100
                        ? "bg-primary"
                        : "bg-secondary"
                    }`}
                    style={{ width: `${need.percentageCompleted}%` }}
                  />
                </div>
                <p className="d-flex justify-content-between mt-2">
                  <small className="fw-light">
                    <span className="fw-semibold">{need.totalSupporters}</span>{" "}
                    Supporters
                  </small>
                  <small className="fw-light">
                    {need.needStatus !== "Funded" && (
                      <span className="fw-semibold">{need.daysLeft}</span>
                    )}
                    {need.needStatus === "Funded"
                      ? "Funded On: "
                      : " Days Left"}
                    {need.needStatus === "Funded" && (
                      <span className="fw-semibold">{need.fundedOn}</span>
                    )}
                  </small>
                </p>
                <p className="fs-small text-dark-emphasis mb-2">
                  Validated by{" "}
                  <Link
                    to={`${ValidatorProfileURL}${need.validatorReferenceId}`}
                    title="Validator Profile"
                    className="link-dark fw-semibold text-decoration-none"
                  >
                    {need.validatorName}
                  </Link>{" "}
                  from{" "}
                  <Link
                    to={`${OrgProfileURL}${need.organizationReferenceId}`}
                    title="Organization Profile"
                    className="link-dark fw-semibold text-decoration-none"
                  >
                    {need.organizationName}
                  </Link>
                </p>
              </Card.Body>
              <Card.Footer className="bg-transparent border-0 pb-3 pt-0">
                <Button
                  variant="primary"
                  className="btn-md"
                  onClick={() => createCart(need.needId)}
                >
                  Support
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner
            animation="border"
            role="status"
            variant={!isWhiteLabel ? "primary" : "light"}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default Need;
