import React, { useState, useEffect, useReducer, Fragment } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Card,
  Button,
  Alert,
  Modal,
  Spinner,
} from "react-bootstrap";
import { useCookies } from "react-cookie";

import RecipientProfile from "./widgets/_recipientProfile";
import NeedInfo from "./widgets/_needInfo";
import RecentDonations from "./widgets/_recentDonations";
import Fundraising from "./widgets/_fundraising";

import { getData, postData } from "../../services/apiService";
import { useAuthContext } from "../../context/authProvider";
import { notify, initialState } from "../../store/notification";
import { decodeToken } from "../../utils/session";
import noData from "../../resources/images/no-data.png";

/**
 * Component for displaying need profile information.
 * This component renders information about the need, such as title, description, etc.
 * @returns {React.Element} - Returns JSX for need profile.
 */
const NeedProfile = () => {
  // Get the 'referenceId' parameter from the URL
  const { referenceId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies, setCookie] = useCookies();
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession, isLoggedIn } = useAuthContext();
  // Decode the user session token
  const user = decodeToken(userSession);
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // State to track if the component is mounted
  const [isMounted, setIsMounted] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [need, setNeed] = useState({});
  const [cartId] = useState(cookies["_benevolent_cartId"] ?? 0);

  useEffect(() => {
    /**
     * Fetched the fundraising profile data from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getFundraisingProfile = async () => {
      setIsPageLoading(true);
      try {
        const response = await getData(
          `/api/profile/need/${referenceId}/${user?.nameid ?? ""}`,
          null
        );
        if (response) setNeed(response);
        setIsPageLoading(false);
        setIsMounted(true);
      } catch (error) {
        setIsMounted(true);
        setIsPageLoading(false);
      }
    };
    getFundraisingProfile();
  }, [referenceId, user?.nameid]);

  /**
   * Function to create a new cart for a specific need and user.
   */
  const createCart = async () => {
    setIsPageLoading(true);
    try {
      // Send a request to create a new cart
      const response = await postData(
        `/api/cart/create`,
        {
          cartId,
          needId: need.needId,
          userId: user?.nameid, // User ID (if available)
        },
        null
      );
      setIsPageLoading(false);

      // If the cartId cookie doesn't exist, create it
      if (!cookies["_benevolent_cartId"]) {
        // Calculate the expiration date for the cookie (1 day from now)
        const expirationDate = new Date();
        expirationDate.setDate(new Date().getDate() + 1);

        // Set the cartId cookie with the provided response value
        setCookie("_benevolent_cartId", response, {
          path: "/", // Set the cookie to be valid across the entire domain by specifying the path as "/"
          expires: expirationDate, // Set the expiration date for the cookie
          sameSite: "none", // Set the sameSite attribute to "none" for cross-site requests
          secure: true, // Require the cookie to be sent over secure (HTTPS) connections
        });
      }
      navigate("/donation/checkout");
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <Fragment>
      <Container className="fundraising-profile">
        {Object.keys(need).length > 0 && (
          <Row>
            {location.key !== "default" && (
              <Col xs={12} className="text-end mt-3">
                <Link
                  to={-1}
                  className="text-decoration-none fw-semibold"
                  title="Back"
                >
                  <i className="flaticon-back pe-1 fs-5"></i>
                  Back to Prior Page
                </Link>
              </Col>
            )}
            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <RecipientProfile
                isPreview={false}
                need={need}
                isLoggedIn={isLoggedIn}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={5} xl={5} className="mt-3">
              <NeedInfo
                isPreview={false}
                need={need}
                isLoggedIn={isLoggedIn}
                roles={user?.roles}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={3} xl={3} className="mt-3">
              <div className="donation-info">
                <Card className="bg-tertiary border-0 mb-3">
                  <Card.Body>
                    <div className="text-center mb-3 fw-light">
                      Needed:{" "}
                      <span className="fw-semibold text-dark">
                        ${need?.amountNeeded}
                      </span>
                    </div>
                    <Fundraising
                      raised={need?.raised}
                      needed={need?.amountNeeded}
                      status={need.needStatus}
                    />
                    <div className="text-center">
                      {need.needStatus === "Funded" ? (
                        <Fragment>
                          <h5 className="title mt-2 mb-1">NEED FUNDED!</h5>
                          <p className="mb-0 fs-small text-dark-emphasis">
                            Thank you for helping to fund this need.
                          </p>
                        </Fragment>
                      ) : (
                        <Button
                          variant="secondary"
                          className="btn-md mt-3"
                          onClick={createCart}
                          disabled={need.needStatus !== "Published"}
                        >
                          DONATE NOW
                        </Button>
                      )}
                    </div>
                  </Card.Body>
                </Card>
                <RecentDonations needId={need.needId} needStatus={need.needStatus} />
              </div>
            </Col>
          </Row>
        )}
        {isMounted && Object.keys(need).length === 0 && (
          <Row className="justify-content-center text-center">
            <Col xs={10} sm={12} md={9} lg={7} xl={6} xxl={5}>
              <Card className="border-0 shadow p-4 rounded-4 text-center">
                <Card.Body>
                  <Image src={noData} alt="Invalid Profile" fluid />
                  <h4 className="title mt-3">Invalid Profile</h4>
                  <p className="mb-0 fw-light text-dark-emphasis">
                    It looks like the profile you are trying to reach is not yet
                    <br className="d-none d-sm-inline-block" />
                    published. If you believe you've reached this page in error,
                    <br className="d-none d-sm-inline-block" />
                    please contact the person who sent you the link.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
export default NeedProfile;
