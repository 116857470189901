import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useReducer,
  useCallback,
} from "react";
import {
  Row,
  Col,
  Form,
  Card,
  Image,
  Button,
  ListGroup,
  Alert,
  Spinner,
  Modal,
} from "react-bootstrap";
import { useForm } from "react-hook-form";

import ImageCrop from "../../../components/imageCrop";
import { Avatars } from "../../../components/avatarImporter";
import { transformProfilePicture } from "../../../components/transformFileURL";

import { postData } from "../../../services/apiService";
import { notify, initialState } from "../../../store/notification";
import { APIURL } from "../../../constants";

import Photo1 from "../../../resources/images/1_photo.png";
import Photo2 from "../../../resources/images/2_photo.png";
import Photo3 from "../../../resources/images/3_photo.png";

/**
 * Component for rendering photo upload form.
 * @param {object} props - Component props.
 * @param {object} props.userSession - User session object.
 * @param {object} props.need - Object containing need information.
 * @param {function} props.processResult - Function to process form result.
 * @param {function} props.handleActiveTab - Function to handle active tab.
 * @returns {React.Element} - Returns JSX for photo upload form
 */
const Photo = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    reset,
  } = useForm();
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  const { userSession } = props;
  const [loadingStates, setLoadingStates] = useState({});
  const [field, setField] = useState("");
  const [title] = useState({
    profilePicture: "Recipient",
    validatorProfilePicture: "Validator",
  });

  /**
   * State variable to hold the original profile picture values.
   * It is used to reset the form when the user cancels the modifications.
   */
  const [profile, setProfile] = useState({
    profilePicture: "",
    validatorProfilePicture: "",
  });

  /**
   * State variable to hold the reference profile picture values.
   * This state variable contains any modifications made by the user, such as selecting a new file URL or custom avatar.
   * These values are used for uploading the modified profile pictures to the server.
   */
  const [reference, setReference] = useState({
    profilePicture: "",
    validatorProfilePicture: "",
  });

  /**
   *  It offers a selection of avatars that users can choose to represent themselves.
   */
  // State and function to control the visibility of the avatar modal
  const [showAvatarModal, setShowAvatarModal] = useState(false);
  const closeAvatarModal = () => setShowAvatarModal(false);
  const openAvatarModal = (type) => {
    // Clear the manual error when an avatar is selected
    clearErrors(type);
    resetFileInput();
    setField(type);
    setActiveAvatar(profile[type]);
    setShowAvatarModal(true);
  };
  // State to manage the selected avatar
  const [activeAvatar, setActiveAvatar] = useState(null);
  // Allowing users to upload their own images from their devices,
  // State to manage file input references. The references can be extracted using the respective keys.
  // Keys: profilePicture and validatorProfilePicture
  const [fileInputRefs] = useState({
    profilePicture: useRef(null),
    validatorProfilePicture: useRef(null),
  });
  /**
   * State variable to hold the selected files for validator and recipient profile pictures.
   * The files can be extracted using the respective keys.
   * Keys: profilePicture and validatorProfilePicture
   */
  const [selectedFile, setSelectedFile] = useState({});
  const [cropInfo, setCropInfo] = useState({});
  // State and functions to control the visibility of the crop modal
  const [showCropModal, setShowCropModal] = useState(false);
  const closeCropModal = () => setShowCropModal(false);
  const openCropModal = () => setShowCropModal(true);


  /**
   * Function to reset file input reference.
   * @param {string} type - Type of file input to reset (profilePicture and validatorProfilePicture).
   */
  const resetFileInput = useCallback((type) => {
    if (type) {
      setReference((prevRefStates) => ({
        ...prevRefStates,
        [type]: "",
      }));
      setCropInfo((prevCropStates) => ({
        ...prevCropStates,
        [type]: {},
      }));
      setSelectedFile((prevFileStates) => ({
        ...prevFileStates,
        [type]: {},
      }));

      if (fileInputRefs[type].current) fileInputRefs[type].current.value = null; // Reset the file input value
    }

    setActiveAvatar(null);
  }, [fileInputRefs]);

  /**
   * Function to reset the input and its reference based on their types.
   * @param {string} type - Type of profile to reset (profilePicture and validatorProfilePicture).
   */
  const resetProfile = useCallback((type) => {
    resetFileInput();
    setReference((prevRefStates) => ({
      ...prevRefStates,
      [type]: profile[type],
    }));
    clearErrors(type);
  }, [clearErrors, profile, resetFileInput]);

  useEffect(() => {
    /**
     * Function to filter fields from the need object.
     * @param  {...string} keys - Keys to be included in the filtered object.
     * @returns {object} - Filtered object containing specified keys.
     */
    const filterFields = (...keys) => {
      const targetObject = Object.assign({});
      Object.keys(props.need).forEach((key) => {
        if (keys.includes(key)) {
          targetObject[key] = props.need[key];
        }
      });
      return targetObject;
    };

    // Reset form fields
    reset(filterFields("needId", "needStatus"));
    setProfile((prevProfileStates) => ({
      ...prevProfileStates,
      profilePicture: transformProfilePicture(props.need?.profilePicture, {
        isFallbackAvatar: false,
      }),

      validatorProfilePicture: transformProfilePicture(
        props.need?.validatorProfilePicture,
        {
          isFallbackAvatar: false,
        }
      ),
    }));
  }, [reset, props.need]);

  useEffect(() => {
    // Reset the reference after uploading files and when pre-populating it during editing.
    const keys = Object.keys(profile);
    for (const key of keys) {
      resetProfile(key);
    }
  }, [profile, resetProfile]);

  /**
   * Function to handle file change event.
   * @param {Event} event - File change event.
   * @param {string} field - Field associated with the file change event (profilePicture and validatorProfilePicture).
   */
  const handleFileChange = (event, field) => {
    // Clear the manual error when the file is selected
    clearErrors(field);
    setField(field);
    const file = event.target.files?.[0];
    if (file) {
      const isValid = validateFile(file, field);
      if (!isValid) return;
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedFile((prevFileStates) => ({
          ...prevFileStates,
          [field]: {
            name: event.target.files?.[0].name,
            file: event.target.files[0],
            src: reader.result,
            url: URL.createObjectURL(event.target.files[0]),
          },
        }));
        openCropModal();
      };
      reader.readAsDataURL(file);
    }
  };

  /**
   * Function to update the state with cropped image information.
   * @param {string} croppedImage - The cropped image data.
   * @param {object} croppedAreaPixels - The position of the cropped area in pixels.
   * @param {number} rotation - The rotation angle of the cropped image.
   */
  const updateAvatar = (croppedImage, croppedAreaPixels, rotation) => {
    setCropInfo((prevCropStates) => ({
      ...prevCropStates,
      [field]: {
        position: croppedAreaPixels,
        rotation: rotation,
      },
    }));
    setReference((prevRefStates) => ({
      ...prevRefStates,
      [field]: croppedImage,
    }));
  };


  /**
   * Function to update the active avatar when a user clicks on an avatar.
   * @param {string} avatar - The selected avatar.
   */
  const handleActiveAvatar = (avatar) => {
    setActiveAvatar(avatar);
  };

  /**
   * Function to update the reference with the selected avatar and closes the avatar modal
   * when a user clicks the `Choose Avatar` button.
   */
  const chooseAvatar = () => {
    setReference((prevRefStates) => ({
      ...prevRefStates,
      [field]: activeAvatar,
    }));
    closeAvatarModal();
  };

  /**
   * Function to validate the selected file.
   * @param {File} file - Selected file.
   * @param {string} field - Field associated with the file (profilePicture and validatorProfilePicture)..
   * @returns {boolean} - Returns true if the file is valid, otherwise false.
   */
  const validateFile = (file, field) => {
    const allowedFileTypes = ["image/jpeg", "image/png"];
    if (!allowedFileTypes.includes(file.type)) {
      setError(field, {
        type: "manual",
        message:
          "Image format is incompatible. Only JPEG and PNG formats are supported.",
      });
      return false;
    }

    const maxSize = 1024 * 1024; // 1 MB in bytes
    if (file.size > maxSize) {
      setError(field, {
        type: "manual",
        message: "Your file is too large, maximum allowed size is: 1 MB",
      });
      return false;
    }

    return true;
  };

  /**
   * Function to verify if custom profile avatars are selected.
   * @returns {boolean} - Returns true if custom profile avatars are selected, otherwise false.
   */
  const verifyProfileAvatar = () => {
    const avatarTypes = ["profilePicture", "validatorProfilePicture"];
    let isValid = true;
    for (const avatarType of avatarTypes) {
      if (!reference[avatarType]) {
        setError(avatarType, {
          type: "manual",
          message: `You must upload a profile picture.`,
        });
        isValid = false;
      }
    }
    return isValid;
  };

  /**
   * Function to initiate the process of file upload.
   * It iterates over each file reference in the reference state, uploads the file if it starts with "blob",
   * and sets the result in the result object. If the upload fails, it resets the original value from the profile state.
   * If the file is a custom avatar, it assigns the avatar name instead of the image to save in the database.
   * @returns {Object} - Result object containing uploaded file references.
   */
  const uploadFiles = async () => {
    const result = {};
    const keys = Object.keys(reference);
    for (const key of keys) {
      let response;
      if (reference[key]?.startsWith("blob")) {
        response = await upload(key);

        // This condition is used to verify if the file upload has failed; in case of failure, it resets the original value from the profile state.
        response = response ? response : profile[key];

        // If it's a custom avatar, we should assign the avatar name instead of the image to save the values in the database.
        result[key] = response.startsWith("data:image")
          ? Avatars.find((x) => x.image === response).name
          : response;
      } else if (reference[key]?.startsWith("data:image")) {
        response = reference[key];
        result[key] = Avatars.find((x) => x.image === reference[key]).name;
      } else {
        response = reference[key]
          ? new URL(reference[key], APIURL).pathname
          : "";
        result[key] = response;
      }
    }
    return result;
  };

  /**
   * Function to upload a file.
   * @param {string} key - Key associated with the file (profilePicture and validatorProfilePicture).
   * @returns {string|null} - Returns the URL of the uploaded file or null if upload fails.
   */
  const upload = async (key) => {
    const formData = new FormData();
    formData.append("File", selectedFile[key].file);
    formData.append("cropX", parseInt(cropInfo[key].position.x));
    formData.append("cropY", parseInt(cropInfo[key].position.y));
    formData.append("cropWidth", parseInt(cropInfo[key].position.width));
    formData.append("cropHeight", parseInt(cropInfo[key].position.height));
    formData.append("cropRotation", parseInt(cropInfo[key].rotation));
    formData.append("type", "Profile");
    if (profile[key] && /\/Uploads/.test(profile[key]))
      formData.append("profilePicture", new URL(profile[key], APIURL).pathname);
    try {
      const response = await postData("/api/image/crop", formData, userSession);
      return response;
    } catch (error) {
      showNotification("danger", `${title[key]}: ${error}`, 5000);
      resetFileInput(key);
      return null;
    }
  };

  /**
   * Function to update the media information using the backend API
   * @param {Object} data - The form data.
   * @param {Object} e - The event object containing information about the form submission.
   */
  const onSubmit = async (data, e) => {
    const isValid = verifyProfileAvatar();
    if (isValid) {
      // Extract the button type from the submit event
      const buttonType = e.nativeEvent.submitter.dataset.buttonType;
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [buttonType]: true,
      }));

      const files = await uploadFiles();
      const result = Object.assign({}, data, files);

      try {
        const response = await postData(
          `/api/need/upsert-media-info`,
          result,
          userSession
        );
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          [buttonType]: false,
        }));

        // Update the need information and submission status
        setValue("needId", response.needId);
        result.needId = response.needId;
        props.processResult({
          need: result,
          hasBeenSubmitted: false,
        });

        // Determine the next active tab based on button type
        if (buttonType === "Draft")
          showNotification("success", response.message, 5000);
        else props.handleActiveTab("preview");
      } catch (error) {
        // Show a notification if an error occurs
        showNotification("danger", error, 5000);
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          [buttonType]: false,
        }));
      }
    }
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Control type="hidden" {...register("needId")} />
        <Form.Control type="hidden" {...register("needStatus")} />
        <Row className="recipient-profile-avatar">
          <Col xs={12} lg={6}>
            {Object.entries(reference).map(([prop, value]) => (
              <Fragment key={prop}>
                <h6 className="fw-semibold">
                  {title[prop]}
                  {profile[prop] && [
                    <Button
                      variant="link"
                      className="fw-light fs-small link-secondary p-1"
                      onClick={() => resetProfile(prop)}
                      key={prop}
                    >
                      [Reset]
                    </Button>,
                  ]}
                </h6>
                <div className="my-4 position-relative text-light-emphasis">
                  {value && (
                    <Fragment>
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => resetFileInput(prop)}
                      ></button>
                      <Image
                        fluid
                        src={value}
                        alt="Profile Picture"
                        className="border-dark-subtle preview"
                        thumbnail
                      />
                    </Fragment>
                  )}
                  {!value && (
                    <Fragment>
                      <label className="file-upload border-dark-subtle">
                        <i className="flaticon-upload fs-3"></i>
                        <small className="d-block">Click here to upload</small>
                        <input
                          type="file"
                          name={prop}
                          id="fileInput"
                          onChange={(e) => handleFileChange(e, prop)}
                          className="d-none"
                          ref={fileInputRefs[prop]}
                          accept="image/jpeg, image/png"
                        />
                      </label>
                      {prop !== "validatorProfilePicture" && (
                        <Fragment>
                          <span className="px-3">OR</span>
                          <div
                            className="custom-avatar border-dark-subtle bg-light"
                            onClick={() => openAvatarModal(prop)}
                          >
                            <i className="flaticon-kid fs-2"></i>
                            <small className="d-block">Select avatar</small>
                          </div>
                        </Fragment>
                      )}
                      {errors[prop] && (
                        <Form.Text className="text-danger d-block">
                          {errors[prop].message}
                        </Form.Text>
                      )}
                    </Fragment>
                  )}
                </div>
              </Fragment>
            ))}
          </Col>
          <Col xs={12} lg={6}>
            <Card className="mb-4 story-carousel">
              <Card.Header className="mx-3 px-0 bg-transparent text-light-emphasis fw-semibold">
                Tips
              </Card.Header>
              <Card.Body className="px-0">
                <ListGroup variant="flush">
                  <ListGroup.Item className="border-0 bg-transparent">
                    <Image
                      alt="Sample 1"
                      fluid
                      src={Photo1}
                      className="me-2 mb-2"
                    />
                    <Image
                      alt="Sample 2"
                      fluid
                      src={Photo2}
                      className="me-2 mb-2"
                    />
                    <Image
                      alt="Sample 3"
                      fluid
                      src={Photo3}
                      className="me-2 mb-2"
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="border-0 bg-transparent">
                    <span className="fw-semibold text-secondary">
                      <i className="flaticon-next fs-5 "></i>Find a Good Spot:{" "}
                    </span>
                    <span>
                      Well-lit, in front of a solid-color wall (not a window) to
                      help your face stand out
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="border-0 bg-transparent">
                    <span className="fw-semibold text-secondary">
                      <i className="flaticon-next fs-5 "></i>Check the Camera:{" "}
                    </span>
                    <span>Can they see your face clearly?</span>
                  </ListGroup.Item>
                  <ListGroup.Item className="border-0 bg-transparent">
                    <span className="fw-semibold text-secondary">
                      <i className="flaticon-next fs-5 "></i>Take the Picture:{" "}
                    </span>
                    <span>
                      Smile! Remember only you should be in your picture
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="border-0 bg-transparent">
                    <span className="fw-semibold text-secondary">
                      <i className="flaticon-next fs-5 "></i>
                    </span>
                    <span>
                      Make sure that the image file that you upload is no larger
                      than 1 MB
                    </span>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} className="mt-4">
            <Button
              type="submit"
              variant="primary"
              data-button-type="Continue"
              disabled={loadingStates["Continue"]}
              className="btn-md me-3"
            >
              {loadingStates["Continue"] ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Save & Continue"
              )}
            </Button>
            <Button
              variant="secondary"
              type="submit"
              data-button-type="Draft"
              disabled={loadingStates["Draft"]}
              className="btn-md"
            >
              {loadingStates["Draft"] ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Save & Finish Later"
              )}
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal
        show={showAvatarModal}
        aria-labelledby="Loading"
        centered
        className="modal-feature modal-avatar"
      >
        <Modal.Header>
          <Modal.Title as="h6" className="fw-bold m-auto module-title">
            Avatar
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-3 mb-4 avatars-container scroll">
            {Avatars.map((item, index) => (
              <Fragment key={item.name}>
                <Image
                  src={item.image}
                  alt={item.name}
                  roundedCircle
                  thumbnail
                  width="60"
                  className={`m-2 ${activeAvatar === item.image ? "border-primary" : ""
                    }`}
                  onClick={() => handleActiveAvatar(item.image)}
                />
              </Fragment>
            ))}
          </div>
          <div className="text-end">
            <Button
              type="submit"
              variant="primary"
              className="btn-md me-3"
              onClick={chooseAvatar}
            >
              Choose Avatar
            </Button>
            <Button
              variant="secondary"
              onClick={closeAvatarModal}
              className="btn-md"
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <ImageCrop
        showCropModal={showCropModal}
        closeCropModal={closeCropModal}
        selectedFile={selectedFile[field]}
        updateAvatar={updateAvatar}
        resetFileInput={resetFileInput}
      />
    </Fragment>
  );
};

export default Photo;
