import { Navigate } from "react-router-dom";

import { useAuthContext } from "../context/authProvider";

/**
 * Component for protecting routes that require authentication.
 * @param {Object} children - The content to be rendered within the protected route.
 * @returns {React.Element} - Returns JSX element representing the protected route.
 */
export const RequireAuth = ({ children }) => {
  // Accessing user session from authentication context
  const { userSession } = useAuthContext();

  // If the userSession is undefined or null or false,
  // indicating that the user is not authenticated, redirect to the "/" route.
  if (userSession === null) return <Navigate to="/" />;

  // If the user is authenticated, render the component.
  return children;
};
