/**
 * Function to create an image element with the given URL.
 * @param {string} url - The URL of the image
 * @returns {Promise<HTMLImageElement>} Promise that resolves with the loaded image element
 */
const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

/**
 * Function to convert degree value to radian angle.
 * @param {number} degreeValue - The degree value to convert
 * @returns {number} Radian angle equivalent to the given degree value
 */
function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Function to rotate the size of an image based on rotation angle.
 * @param {number} width - Original width of the image
 * @param {number} height - Original height of the image
 * @param {number} rotation - Rotation angle in degrees
 * @returns {Object} Object containing the width and height of the rotated image
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

/**
 * Function to crop and optionally rotates and flips an image.
 * @param {string} imageSrc - Source URL of the image
 * @param {Object} pixelCrop - Object containing coordinates and dimensions of the cropped area
 * @param {number} [rotation=0] - Rotation angle in degrees (default: 0)
 * @param {Object} [flip={ horizontal: false, vertical: false }] - Object indicating whether to flip the image horizontally or vertically (default: { horizontal: false, vertical: false })
 * @returns {Promise<string>} Promise that resolves with a Blob URL of the cropped image
 */
export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  ctx.drawImage(image, 0, 0);

  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(data, 0, 0);

  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, "image/jpeg");
  });
}
