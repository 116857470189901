export const Host = process.env.REACT_APP_PORTAL_HOST;
export const APIURL = process.env.REACT_APP_API_URL;
export const Job_APIURL = process.env.REACT_APP_BGJob_URL;
export const SITEURL = process.env.REACT_APP_SITE_URL;
export const PRIMARY_SITES = JSON.parse(process.env.REACT_APP_PRIMARY_SITES || '[]');
export const SupportTeamEmail = process.env.REACT_APP_SUPPORT_TEAM_EMAIL;
export const SupportTeamPhoneNumber = process.env.REACT_APP_SUPPORT_TEAM_PHONE_NUMBER;

export const Facebook_AppId = process.env.REACT_APP_FACEBOOK_APP_ID;
export const Google_ClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const PayPal_ClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
export const PayPal_ClientSecret = process.env.REACT_APP_PAYPAL_CLIENT_SECRET;

export const Stripe_PublishableKey =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const Stripe_SecretKey = process.env.REACT_APP_STRIPE_SECRET_KEY;

export const Square_ApplicationId = process.env.REACT_APP_SQUARE_APPLICATION_ID;
export const Square_LocationId = process.env.REACT_APP_SQUARE_LOCATION_ID;

export const DefaultPaymentGateway =
  process.env.REACT_APP_DEFAULT_PAYMENT_GATEWAY;

export const DefaultOrigin = process.env.REACT_APP_DEFAULT_ORIGIN;

export const IsDevelopment = process.env.REACT_APP_ENV !== "prod";

export const NeedProfileURL = "/profile/need/";
export const OrgProfileURL = "/profile/organization/";
export const ValidatorProfileURL = "/profile/validator/";
