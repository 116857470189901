import React, {
  useState,
  useEffect,
  useReducer,
  Fragment,
  useRef,
} from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Image,
  Form,
  ToggleButtonGroup,
  ToggleButton,
  InputGroup,
  Button,
  Alert,
  Modal,
  Spinner,
  Collapse,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import key from "weak-key";
import "rc-slider/assets/index.css";
import { useCookies } from "react-cookie";

import { TooltipSlider } from "../../components/tooltipSlider";
import { transformProfilePicture } from "../../components/transformFileURL";
import TransactionCancellationConfirmation from "./transactionCancellationConfirmation";

import { getData, putData } from "../../services/apiService";
import { useAuthContext } from "../../context/authProvider";
import { decodeToken } from "../../utils/session";
import { notify, initialState } from "../../store/notification";

import noData from "../../resources/images/no-data.png";

/**
 * Component for managing the donation checkout process.
 * @returns {JSX.Element} - Returns JSX for managing donation checkout.
 */
const Checkout = () => {
  const navigate = useNavigate();
  // Get the current URL's query string
  const [searchParams] = useSearchParams();
  // This reference is used to interact with a element in component.
  const pageRef = useRef();
  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [cookies, setCookie] = useCookies();
  const defaultAmounts = [10, 20, 35, 100, 200];
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  // Decode the user session token
  const user = decodeToken(userSession);
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  const [cartId] = useState(cookies["_benevolent_cartId"] ?? 0);

  const customContributionRef = useRef(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [contributionPercentage, setContributionPercentage] = useState(0);
  const [contribution, setContribution] = useState(0);
  const [showCustomTip, setShowCustomTip] = useState(false);
  const [customDonationAmounts, setCustomDonationAmounts] = useState({});
  const [cartItems, setCartItems] = useState([]);
  const [showTransactionConfirmation, setShowTransactionConfirmation] =
    useState(false);
  const [isHelpTextVisible, setHelpTextVisibility] = useState(false);  
  // To determine the origin of the iframe and whether it is a white-label integration.
  const [isWhiteLabel] = useState(window.self !== window.top);

  // frameContent refers to the children of the current pageRef,
  // which represents the content of the iframe.
  const frameContent = pageRef.current ? pageRef.current.children : null;

  /**
   * Function to apply styles to elements in the iframe based on search parameters.
   */
  const applyFrameStylesFromSearchParams = () => {
    Array.from(frameContent).forEach((content) => {
      // Update styles for elements with class 'cart'
      const cart = content.querySelector("div.cart");
      adjustLinkAppearance(cart);
      if (cart) {
        const labels = cart.querySelectorAll("label.btn-secondary");
        if (labels) {
          labels.forEach((labelStyle) => {
            applyColorProperty(labelStyle, "BUTTON", "secondaryColor");
          });
        }

        const links = cart.querySelectorAll(".link-primary");
        if (links) {
          links.forEach((linkStyle) => {
            applyColorProperty(linkStyle, "LINK", "primaryColor");
          });
        }
      }

      // Update styles for elements with class 'summary'
      const summary = content.querySelector("div.summary");
      if (summary) {
        const buttons = summary.querySelectorAll("button");
        if (buttons) {
          buttons.forEach((buttonStyle) => {
            applyColorProperty(buttonStyle, "BUTTON", "primaryColor");
          });
        }

        if (summary.classList.contains("bg-secondary"))
          applyColorProperty(summary, "BACKGROUND", "secondaryColor");
      }

      // Update style for the caution message
      const cautionMessage = content.querySelector(".caution-message");
      if (cautionMessage) {
        const link = cautionMessage.querySelector(".btn-primary");
        if (link) applyColorProperty(link, "BUTTON", "primaryColor");
      }
    });
  };

  /**
   * Function to applies color properties to the specified element based on the provided color parameter.
   * @param {HTMLElement} element - The HTML element to apply the color properties to.
   * @param {string} elementType - The type of element, e.g., "BUTTON".
   * @param {string} colorParam - The name of the search parameter containing the color value.
   */
  const applyColorProperty = (element, elementType, colorParam) => {
    const color = searchParams.get(colorParam);
    if (elementType === "BUTTON") {
      element.style.setProperty("background-color", color, "important");
      element.style.setProperty("border-color", color, "important");
    }
    if (elementType === "BACKGROUND")
      element.style.setProperty("background-color", color, "important");

    if (elementType === "LINK") {
      element.style.setProperty("color", color, "important");
      element.style.setProperty("text-decoration-color", color, "important");
    }
  };

  /**
   * Function to adjusts the appearance of radio buttons.
   * @param {HTMLElement} element - The radio button element.
   */
  const adjustRadioAppearance = (element) => {
    if (frameContent && frameContent?.length > 0 && isWhiteLabel) {
      Array.from(frameContent).forEach((content) => {
        // Update styles for elements with class 'cart'
        const cart = content.querySelector("div.cart");
        if (cart) {
          const radioButtons = cart.querySelectorAll("input.btn-check");
          if (radioButtons.length > 0) {
            radioButtons.forEach((radioButton) => {
              const label = document.querySelector(
                `label[for="${radioButton.id}"]`
              );
              if (label) {
                label.style.removeProperty("background-color");
                label.style.removeProperty("border-color");
              }
            });
          }
        }
      });
    }
    if (element) applyColorProperty(element, "BUTTON", "secondaryColor");
  };

  /**
   * Function to adjusts the appearance of links.
   * @param {HTMLElement} cart - The cart element containing links.
   */
  const adjustLinkAppearance = (cart) => {
    if (cart) {
      const links = cart.querySelectorAll(".link-secondary");
      if (links.length > 0) {
        links.forEach((linkStyle) => {
          applyColorProperty(linkStyle, "LINK", "secondaryColor");
        });
      }
    }
  };

  useEffect(() => {
    /**
     * It triggers when the dependencies change (in this case, when showCustomTip changes).
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    if (frameContent && frameContent?.length > 0 && isWhiteLabel) {
      Array.from(frameContent).forEach((content) => {
        const cart = content.querySelector("div.cart");
        if (cart) adjustLinkAppearance(cart);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCustomTip, isWhiteLabel, frameContent]);

  useEffect(() => {
    /**
     * Hook to apply frame styles.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    if (frameContent && frameContent?.length > 0 && isWhiteLabel)
      applyFrameStylesFromSearchParams();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems, isWhiteLabel, isPageLoading, frameContent]);

  /**
   * Function to determines the variant of a cart item.
   * @param {Object} cartItem - The cart item object.
   * @param {number} amount - The selected donation amount.
   * @returns {string} - The variant ('secondary' or 'light').
   */
  const determineVariant = (cartItem, amount) => {
    const hasCustomAmount =
      customDonationAmounts &&
      customDonationAmounts[`customDonationInput${cartItem.cartItemId}`];

    const isDefaultAmountSelected =
      cartItem.amount === amount &&
      !(
        hasCustomAmount &&
        !Object.values(hasCustomAmount).includes(cartItem.amount)
      );

    return isDefaultAmountSelected ? "secondary" : "light";
  };

  /**
   * Function to determines the value  of a cart item.
   * @param {Object} cartItem - The cart item object.
   * @returns {number|string} - The value of the cart item.
   */
  const determineValue = (cartItem) => {
    const customInput =
      customDonationAmounts &&
      customDonationAmounts[`customDonationInput${cartItem.cartItemId}`];

    const defaultValue =
      !defaultAmounts.includes(cartItem.amount) && (cartItem.amount || "");

    return customInput || defaultValue;
  };

  /**
   * Function to validates the donation amount.
   * @returns {boolean} - Indicates whether the donation amount is valid.
   */
  const validateDonationAmount = () => {
    let isValidDonation = true;
    // Iterate through cart items to validate donation amount
    for (const cartItem of cartItems) {
      if (parseFloat(cartItem.amount) <= 0) {
        setError(`customDonationInput${cartItem.cartItemId}`, {
          type: "manual",
          message: `Invalid amount.`,
        });
        isValidDonation = false;
      }
    }
    let isValidContribution = true;
    // Validate custom tip amount if applicable
    if (customContributionRef.current && showCustomTip) {
      if (parseFloat(customContributionRef.current.value) < 0) {
        setError("customTip", {
          type: "manual",
          message: `Invalid amount.`,
        });
        isValidContribution = false;
      }
    }
    // Check if both donation and contribution amounts are valid
    const isValid = isValidDonation && isValidContribution;
    return isValid;
  };

  /**
   * Function to handles setting a default donation amount for a specific item in the cart.
   * @param {string} itemId - The ID of the item in the cart.
   * @param {number} amount - The default donation amount.
   */
  const handleDefaultAmount = (itemId, amount) => {
    clearErrors();
    // Clone the cart items array to avoid mutating the state directly
    const clonedItems = [...cartItems];
    // Find the index of the item in the cloned array
    const index = clonedItems.findIndex((x) => x.cartItemId === itemId);
    clonedItems[index].amount = parseFloat(amount) || 0;
    setCartItems(clonedItems);
    setCustomDonationAmounts({
      ...customDonationAmounts,
      [`customDonationInput${itemId}`]: 0,
    });
  };

  /**
   * Function to handles changes in the custom donation amount for a specific item in the cart.
   * @param {string} itemId - The ID of the item in the cart.
   * @param {Object} e - The event object containing the target element.
   */
  const handleCustomAmountChange = (itemId, e) => {
    clearErrors();
    // Destructure the name and value from the event target
    const { name, value } = e.target;
    setCustomDonationAmounts({
      ...customDonationAmounts,
      [name]: parseFloat(value) || 0,
    });
    const clonedItems = [...cartItems];
    // Find the index of the item in the cloned array
    const index = clonedItems.findIndex((x) => x.cartItemId === itemId);
    clonedItems[index].amount = parseFloat(value) || 0;
    setCartItems(clonedItems);
  };

  /**
   * Function to calculates the total donation amount for all items in the cart.
   * @returns {number} - The total donation amount.
   */
  const calculateDonationAmount = () => {
    return cartItems.reduce((total, item) => total + item.amount, 0);
  };

  /**
   * Function to calculates the total transaction amount including both donation amount and contribution.
   * @returns {number} - The total transaction amount.
   */
  const calculateTotalTransactionAmount = () => {
    // Calculate the total donation amount using the reduce method
    return parseFloat(calculateDonationAmount() + contribution).toFixed(2);
  };

  /**
   * Function to calculates the contribution amount based on the specified value and type.
   * @param {number} value - The value of the contribution.
   * @param {string} type - The type of contribution ("default" or "custom").
   * @returns {string} - The formatted contribution amount.
   */

  const calculateContribution = (value, type) => {
    if (type === "default") {
      const tip = ((value / 100) * calculateDonationAmount()).toFixed(2);
      return `${value}% ($${tip})`;
    } else {
      clearErrors();
      setContributionPercentage(0);
      setContribution(value || 0);
    }
  };

  /**
   * Function to reset the contribution.
   * @param {boolean} value - Indicates whether to show the custom tip.
   */
  const resetContribution = (value) => {
    setShowCustomTip(value);
    setContribution(0);
    setContributionPercentage(!value ? 16 : 0);
  };

  /**
   * Function to remove a cart item from the cart.
   * @param {string} itemId - The ID of the item to be removed from the cart.
   */
  const removeCartItem = async (itemId) => {
    setIsPageLoading(true);
    try {
      await putData(`/api/cart/remove-item/${cartId}/${itemId}`, null, null);
      const clonedItems = [...cartItems];
      setCartItems(clonedItems.filter((x) => x.cartItemId !== itemId));
      const expirationDate = new Date();
      expirationDate.setDate(new Date().getDate() + 1);
      setCookie("_benevolent_needs_chosen", cartItems.length - 1, {
        path: "/", // Set the cookie to be valid across the entire domain by specifying the path as "/"
        expires: expirationDate, // Set the expiration date for the cookie
        sameSite: "none", // Set the sameSite attribute to "none" for cross-site requests
        secure: true, // Require the cookie to be sent over secure (HTTPS) connections
      });
      setIsPageLoading(false);

      // the method update the needs chosen count in the header
      // will be triggered automatically while updating the cookie.
    } catch (error) {
      // Show a notification if an error occurs any
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  /**
   * Function to update the cart with the current donation details.
   */
  const updateCart = async () => {
    // Validate the donation amount
    let isValid = validateDonationAmount();
    if (!isValid) return;
    setIsPageLoading(true);
    try {
      await putData(
        `/api/cart/update/`,
        {
          cartId: cartId,
          userId: user?.nameid,
          donationAmount: calculateDonationAmount(),
          contributionPercentage: contributionPercentage,
          contribution: contribution,
          totalAmount: calculateTotalTransactionAmount(),
          quantity: cartItems.length,
          cartItemInfo: cartItems,
        },
        null
      );
      setIsPageLoading(false);
      const redirectURL = `/donation/request/${cartId}${
        isWhiteLabel ? `?${searchParams.toString()}` : ""
      }`;
      navigate(redirectURL);
    } catch (error) {
      // Show a notification if an error occurs any
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    /**
     * Fetches cart from the backend API on component mount.
     */
    const getCart = async () => {
      setIsPageLoading(true);
      try {
        const response = await getData(
          `/api/cart/get/${cartId}/${user?.nameid ?? ""}`,
          null,
          null
        );
        setContributionPercentage(
          response.contributionPercentage != null
            ? response.contributionPercentage
            : 16 // Default Additional Gift
        );
        setContribution(
          response.contribution != null ? response.contribution : 0
        );
        setShowCustomTip(
          (response.contributionPercentage == null ||
            response.contributionPercentage === 0) &&
            response.contribution > 0
        );
        if (response.cartItemInfo) {
          if (response.cartItemInfo) {
            const updatedCartItemInfo = transformProfilePicture(
              response.cartItemInfo
            );
            setCartItems(updatedCartItemInfo);
            const expirationDate = new Date();
            expirationDate.setDate(new Date().getDate() + 1);
            setCookie("_benevolent_needs_chosen", response.quantity, {
              path: "/", // Set the cookie to be valid across the entire domain by specifying the path as "/"
              expires: expirationDate, // Set the expiration date for the cookie
              sameSite: "none", // Set the sameSite attribute to "none" for cross-site requests
              secure: true, // Require the cookie to be sent over secure (HTTPS) connections
            });
          }
        }
        setIsPageLoading(false);
      } catch (error) {
        // Show a notification if an error occurs any
        showNotification("danger", error, 5000);
        setCartItems([]);
        setIsPageLoading(false);
      }
    };

    if (cartId > 0 || user?.nameid) getCart();
    else setIsPageLoading(false);
   
    // Return a cleanup function
    return () => {};
  }, [cartId, user?.nameid, setCookie]);

  // Issue: Cannot update a component (`Checkout`) while rendering a different component (`HandleTooltip`). To locate the bad setState() call inside `HandleTooltip`
  // To prevent performance issues related to useState during the initial page load,
  // consider the following strategies.
  useEffect(() => {
    /**
     * Calculates the contribution amount based on the contribution percentage and donation amount.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const tip = (
      (contributionPercentage / 100) *
      calculateDonationAmount()
    ).toFixed(2);
    setContribution(parseFloat(tip));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contributionPercentage, cartItems]);

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main className="checkout my-4 mx-auto" ref={pageRef}>
      {cartItems.length > 0 && (
        <Row className="justify-content-center">
          <Col xs={12} className="text-center mb-5 page-title">
            <h1 className="title">
              <span className="position-relative fw-medium">
                Checkout<span className="title-divider"></span>
              </span>
            </h1>
          </Col>
          <Form onSubmit={handleSubmit(updateCart)}>
            <Row className="justify-content-center">
              <Col xs={11} md={8} lg={7} xl={7} xxl={7}>
                <Card className="border-0 shadow-sm p-4 rounded-4 cart">
                  <Card.Body>
                    <div className="text-end mb-2">
                      <Button
                        variant="link"
                        title="Clear All"
                        className="link-primary"
                        onClick={() => setShowTransactionConfirmation(true)}
                      >
                        Clear All
                      </Button>
                    </div>
                    {/** Render cart items */}
                    {cartItems.map((cartItem) => (
                      <div
                        className="d-flex align-items-center border mb-4 rounded-3 p-3"
                        key={key(cartItem)}
                      >
                        <Image
                          src={cartItem.profilePicture}
                          alt="Profile Picture"
                          fluid
                          roundedCircle
                          style={{ maxWidth: "100px" }}
                        ></Image>
                        <div className="ms-3 w-100 position-relative">
                          <h4 className="title mb-0">{cartItem.recipient}</h4>
                          <Button
                            className="btn-close fs-small position-absolute top-0 end-0"
                            variant="link"
                            title="Clear"
                            onClick={() => removeCartItem(cartItem.cartItemId)}
                          ></Button>
                          <p className="mb-2">{cartItem.needTitle}</p>
                          {/* Toggle button group for default donation amounts */}
                          <ToggleButtonGroup
                            type="radio"
                            name={`btn-group-${cartItem.cartItemId}`}
                            className="align-top"
                          >
                            {defaultAmounts.map((amount, index) => (
                              <ToggleButton
                                key={`${index}`}
                                id={`btn-group-${cartItem.cartItemId}-radio-${index}`}
                                size="sm"
                                value={amount}
                                variant={determineVariant(cartItem, amount)}
                                className={`me-2 mb-2 rounded-1 ${
                                  cartItem.amount !== amount &&
                                  "text-dark-emphasis"
                                }`}
                                onClick={(e) => {
                                  adjustRadioAppearance(e.target);
                                  handleDefaultAmount(
                                    cartItem.cartItemId,
                                    amount
                                  );
                                }}
                              >
                                ${amount}
                              </ToggleButton>
                            ))}
                          </ToggleButtonGroup>
                          {/* Input group for custom donation amount */}
                          <InputGroup
                            size="sm"
                            className="d-inline-flex mb-2 customDonationInputGroup"
                          >
                            <InputGroup.Text className="border-0">
                              <i className="flaticon-dollar fs-6"></i>
                            </InputGroup.Text>
                            <InputGroup.Text className="border-0 p-1">
                              <div className="input-divider border h-75"></div>
                            </InputGroup.Text>
                            <Form.Control
                              type="number"
                              className="border-0"
                              placeholder="Amount"
                              value={determineValue(cartItem)}
                              name={`customDonationInput${cartItem.cartItemId}`}
                              onChange={(e) => {
                                adjustRadioAppearance(null);
                                handleCustomAmountChange(
                                  cartItem.cartItemId,
                                  e
                                );
                              }}
                            />
                          </InputGroup>
                          {errors[
                            `customDonationInput${cartItem.cartItemId}`
                          ] && (
                            <Form.Text className="text-danger d-block">
                              {
                                errors[
                                  `customDonationInput${cartItem.cartItemId}`
                                ].message
                              }
                            </Form.Text>
                          )}
                        </div>
                      </div>
                    ))}
                    <div className="border mb-4 rounded-3 p-3">
                      <h5 className="title mb-0">
                        Help support our work at Benevolent [
                        <Button
                          variant="link"
                          title="Support Benevolent"
                          className="link-primary p-0 fs-5"
                          onClick={() =>
                            setHelpTextVisibility(!isHelpTextVisible)
                          }
                        >
                          ?
                        </Button>
                        ]
                      </h5>
                      <Collapse in={isHelpTextVisible}>
                        <div className="bg-tertiary text-dark-emphasis fs-small p-3 mt-3 helpText">
                          Your additional gift helps support credit card fees and
                          our overall mission. We encourage our Benevolent
                          donors to contribute at least 15% on top of their
                          donations to support the credit card fees and program
                          work we do to engage local validators, reach out to
                          donors, and expand the reach of our help and advocacy
                          for low income families across the country.
                        </div>
                      </Collapse>
                      {/* Display default tip options */}
                      {!showCustomTip && (
                        <Fragment>
                          <div className="d-flex align-items-center mt-4 mb-3">
                            <span className="fw-semibold text-light-emphasis me-3">
                              0%
                            </span>
                            {/* Default tip percentage slider */}
                            <TooltipSlider
                              dots
                              min={0}
                              max={20}
                              step={2}
                              value={contributionPercentage}
                              tipFormatter={(value) =>
                                calculateContribution(value, "default")
                              }
                              onChange={setContributionPercentage}
                              trackStyle={{
                                height: 6,
                                backgroundColor: "#d0dadb",
                              }}
                              railStyle={{ height: 6 }}
                              dotStyle={{
                                border: 0,
                                borderRadius: 0,
                                width: 2,
                                backgroundColor: "#fff",
                              }}
                              activeDotStyle={{ backgroundColor: "#fff" }}
                              handleStyle={{
                                width: 24,
                                height: 24,
                                borderWidth: 4,
                                marginTop: -10,
                                backgroundColor: "#d0dadb",
                                borderColor: "#fff",
                                boxShadow:
                                  "0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)",
                              }}
                            />
                            <span className="fw-semibold text-light-emphasis ms-3">
                              20%
                            </span>
                          </div>
                          <Button
                            variant="link"
                            className="link-secondary fs-small px-0"
                            onClick={() => resetContribution(true)}
                          >
                            Enter additional gift
                          </Button>
                        </Fragment>
                      )}
                      {/* Display custom tip input */}
                      {showCustomTip && (
                        <Fragment>
                          <div className="d-flex justify-content-between align-items-center my-4">
                            <span className="text-dark">Amount</span>
                            <Button
                              variant="link"
                              className="link-secondary fs-small px-0"
                              onClick={() => resetContribution(false)}
                            >
                              Back to default
                            </Button>
                          </div>
                          <InputGroup size="lg">
                            <InputGroup.Text className="rounded-start-4">
                              <i className="flaticon-dollar"></i>
                            </InputGroup.Text>
                            <InputGroup.Text className="border-start-0 p-1">
                              <div className="input-divider border h-50"></div>
                            </InputGroup.Text>

                            <Form.Control
                              type="number"
                              className="border-start-0 rounded-end-4"
                              id="txtCustomTip"
                              placeholder="Amount"
                              name="customTip"
                              defaultValue={contribution}
                              onChange={(e) => {
                                calculateContribution(e.target.value, "custom");
                              }}
                              ref={customContributionRef}
                            />
                          </InputGroup>
                          {errors.customTip && (
                            <Form.Text className="text-danger">
                              {errors.customTip.message}
                            </Form.Text>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={11} md={4} lg={3} xl={4} xxl={4}>
                <Card
                  className="border-0 shadow-sm p-4 rounded-4 mt-4 mt-md-0 text-white summary"
                  bg="secondary"
                >
                  <Card.Body>
                    <h4 className="title text-white mb-3">Summary</h4>
                    {cartItems.map((cartItem) => (
                      <div
                        className="d-flex justify-content-between fw-light my-3"
                        key={key(cartItem)}
                      >
                        <span>{cartItem.recipient}</span>
                        <span>${cartItem.amount}</span>
                      </div>
                    ))}
                    <div className="d-flex justify-content-between fw-light my-3">
                      <span>Additional Gift</span>
                      <span>${contribution}</span>
                    </div>
                    <hr className="my-3" />
                    <div className="d-flex justify-content-between fw-semibold my-3">
                      <span>Total</span>
                      <span>${calculateTotalTransactionAmount()}</span>
                    </div>
                    <Row className="mt-4">
                      <Col xs={6} md={12} xl={6}>
                        <Button
                          variant="primary"
                          type="submit"
                          className="w-100 mt-3"
                        >
                          Checkout
                        </Button>
                      </Col>
                      <Col xs={6} md={12} xl={6}>
                        <Link
                          to={-1}
                          title="Back"
                          className="btn btn-light w-100 btn-md mt-3"
                        >
                          Back
                        </Link>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
        </Row>
      )}

      {cartItems.length === 0 && !isPageLoading && (
        <Row className="justify-content-center">
          <Col xs={12}>
            <Card className="border-0 shadow p-4 rounded-4 text-center caution-message">
              <Card.Body>
                <Image src={noData} alt="No Data Found" fluid />
                <h4 className="title mt-3">Your cart is empty!</h4>
                <p className="mb-0 fw-light text-dark-emphasis">
                  Looks like you haven't chosen any needs yet.
                </p>
                {isWhiteLabel && (
                  <Link
                    to={`/giving-page/organization/${searchParams.get(
                      "code"
                    )}?frameLayout=plain`}
                    title="Back to Home"
                    className="btn btn-primary btn-md mt-3"
                  >
                    Back to Home
                  </Link>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      <TransactionCancellationConfirmation
        cartId={cartId}
        showTransactionConfirmation={showTransactionConfirmation}
        setShowTransactionConfirmation={setShowTransactionConfirmation}
        setIsPageLoading={setIsPageLoading}
      />
      {/* Display notification component */}
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner
            animation="border"
            role="status"
            variant={!isWhiteLabel ? "primary" : "light"}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </main>
  );
};
export default Checkout;
