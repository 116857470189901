import React, { Fragment } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Navbar,
  Image,
  Nav,
  Button,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import NeedsChosen from "../components/needsChosen";
import LogoutConfirmationPopup from "./_logoutConfirmationPopup";
import TokenExpirationPopup from "./_tokenExpirationPopup";
import { useAuthContext } from "../context/authProvider";
import useTokenExpiration from "../utils/tokenExpiration";
import { decodeToken } from "../utils/session";
import { Host, SITEURL } from "../constants";

import "../resources/scss/public-layout.scss";
import logo from "../resources/images/logo.svg";

/**
 * Component for rendering the public layout with the content provided by the routing outlet
 * @returns {React.Element} - Returns JSX for the public layout.
 */
const PublicLayout = () => {
  /**
   * Destructuring assignment to extract necessary variables and functions from the useAuthContext hook.
   * - saveToken: Function to save the JWT token in the client's browser using local storage.
   * - clearToken: Function to clear the JWT token from the client's browser.
   * - userSession: Session object containing user authentication information.
   * - setReturnURL: Function to set the return URL for redirection after authentication.
   */
  const { saveToken, clearToken, userSession, setReturnURL, isLoggedIn } =
    useAuthContext();
  // Decode the user session token to extract user information
  const user = decodeToken(userSession);
  // Get the current URL's query string
  const urlParams = new URLSearchParams(window.location.search);
  const location = useLocation();

  /**
   * Function to display a token expiration popup with a TokenExpirationPopup component.
   */
  const showTokenExpirationPopup = () => {
    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        setReturnURL(location.pathname);
        return (
          <TokenExpirationPopup
            onClose={onClose}
            onSaveToken={saveToken}
            onClearToken={clearToken}
            session={userSession}
          />
        );
      },
    });
  };

  // Call the useTokenExpiration hook, passing the user token expiration time and
  // the showTokenExpirationPopup function
  useTokenExpiration(user, showTokenExpirationPopup);

  /**
   * Function to handle user logout by displaying a confirmation popup.
   * It passes the following props to the component:
   * - onClose: Function to close the confirmation popup.
   * - onClearToken: Function to clear the JWT token from the client's browser.
   * - roles: User roles used to determine logout behavior based on role.
   * - code: Access code used to logout the recipient.
   */
  const handleLogout = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <LogoutConfirmationPopup
            onClose={onClose}
            onClearToken={clearToken}
            roles={user.roles}
            code={user.nameid}
          />
        );
      },
    });
  };

  return (
    <div className="public-layout">
      {/* Header */}
      {urlParams.get("frameLayout") !== "plain" && (
        <Navbar fixed="top" className="py-1 mini-header">
          <Container>
            <Nav className="social-media-icons">
              <Nav.Item>
                <Link to="https://www.facebook.com/benevolent.net" className="nav-link">
                  <i className="flaticon-facebook-outline"></i>
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="https://twitter.com/i/flow/login?redirect_after_login=%2Fbenevolentnet" className="nav-link">
                  <i className="flaticon-twitter"></i>
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to="https://www.instagram.com/benevolentdotnet/" className="nav-link">
                  <i className="flaticon-instagram-outline"></i>
                </Link>
              </Nav.Item>
            </Nav>
            <Nav className="ms-auto fs-small align-items-center">
              <Nav.Item>
                <Link
                  to={`${SITEURL}/donate`}
                  className="nav-link text-dark fs-small"
                >
                  Donate Now
                </Link>
              </Nav.Item>
              <Nav.Item>
                <span className="px-3 text-dark">|</span>
              </Nav.Item>
              <Nav.Item>
                <NeedsChosen userId={user?.nameid ?? ""} />
              </Nav.Item>
              <Nav.Item>
                <span className="px-3 text-dark">|</span>
              </Nav.Item>
              <Nav.Item>
                <Link to="/needs/search" className="nav-link text-dark">
                  <i className="flaticon-search"></i>
                </Link>
              </Nav.Item>
            </Nav>
          </Container>
        </Navbar>
      )}
      {urlParams.get("frameLayout") !== "plain" && (
        <Navbar expand="xl" fixed="top" className="header border-bottom">
          <Container>
            <Navbar.Brand href={SITEURL} title="Benevolent">
              <Image src={logo} alt="Benevolent" fluid />
            </Navbar.Brand>
            <Navbar.Collapse
              id="navbarScroll"
              className="order-2 order-xl-1 p-3 p-xl-0"
            >
              <Nav className="m-auto" navbarScroll>
                <Nav.Link href={SITEURL} className="text-dark fs-small">
                  Home
                </Nav.Link>
                <Nav.Link
                  href={`${SITEURL}/how-success-happens`}
                  className="text-dark fs-small"
                >
                  How Success Happens
                </Nav.Link>
                <Link
                  to="/needs/search"
                  className="text-dark fs-small nav-link"
                >
                  Needs
                </Link>
                <Nav.Link
                  href={`${SITEURL}/how-success-happens`}
                  className="text-dark fs-small"
                >
                  Become a Donor
                </Nav.Link>
                <Nav.Link
                  href={`${SITEURL}/become-a-donor`}
                  className="text-dark fs-small"
                >
                  Become a Validator
                </Nav.Link>
                <Nav.Link
                  href={`${SITEURL}/faqs`}
                  className="text-dark fs-small"
                >
                  FAQs
                </Nav.Link>
                <Nav.Link
                  href={`${SITEURL}/contact`}
                  className="text-dark fs-small"
                >
                  Contact
                </Nav.Link>
                <Nav.Link
                  href={`${SITEURL}/about`}
                  className="text-dark fs-small"
                >
                  About
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            <div className="order-1 order-xl-2">
              {!isLoggedIn && (
                <Fragment>
                  <Link
                    className="btn btn-md fw-semibold text-dark"
                    to="/"
                    title="Login"
                  >
                    Login
                  </Link>
                  <Link
                    className="rounded-pill btn btn-secondary btn-md"
                    to="/register"
                    title="Register"
                  >
                    Register
                  </Link>
                </Fragment>
              )}
              {isLoggedIn && (
                <Fragment>
                  <Link
                    className="btn btn-md fw-semibold text-dark"
                    to="/"
                    title="Go to Portal"
                  >
                    Go to Portal
                  </Link>
                  <Button
                    variant="secondary"
                    className="rounded-pill btn-md"
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </Fragment>
              )}
              <Navbar.Toggle className="ms-4" aria-controls="navbarScroll" />
            </div>
          </Container>
        </Navbar>
      )}
      {/* Component */}
      <Container fluid className="wrapper">
        <Outlet />
      </Container>
      {/* Footer */}
      {urlParams.get("frameLayout") !== "plain" && (
        <footer className="footer bg-dark">
          <Container fluid>
            <Row className="m-0 py-3 align-items-center">
              <Col xs={12} md={5} className="text-center text-md-start">
                <span>
                  {new Date().getFullYear()} &copy;{" "}
                  <a
                    href={Host}
                    title="Benevolent"
                    className="text-decoration-none link-primary"
                  >
                    Benevolent
                  </a>{" "}
                  All rights reserved.
                </span>
              </Col>
              <Col
                xs={12}
                md={7}
                className="text-center text-md-end mt-2 mt-md-0"
              >
                <span className="fs-small">
                  <a
                    href={Host}
                    title="Benevolent"
                    className="text-decoration-none link-dark fw-bold"
                  >
                    <Image
                      alt="Benevolent"
                      src={logo}
                      fluid
                      className="me-2"
                    ></Image>
                  </a>
                  A member of The Fedcap Group
                </span>
              </Col>
            </Row>
          </Container>
        </footer>
      )}
    </div>
  );
};

export default PublicLayout;
