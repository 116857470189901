/**
 * Function to import all cards from a specified context.
 * @param {Object} context - The context to import cards from
 * @returns {Array<Object>} Array containing information about each imported card
 */
const importAll = (context) => {
  const cards = [];
  context.keys().forEach((key) => {
    const avatarName = key.replace("./", "");
    cards.push({
      name: avatarName,
      image: context(key).default || context(key),
    });
  });
  return cards;
};

/**
 * Array containing information about imported cards (VISA, DISCOVER, etc...).
 * @type {Array<Object>}
 */
export const Cards = importAll(
  require.context("../resources/images/card", false, /\.png$/)
);
