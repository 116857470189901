import { Fragment, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import { postData } from "../../services/apiService";
import { PayPal_ClientId, DefaultOrigin } from "../../constants";

/**
 * Component for handling PayPal payment.
 * @param {number} cartId - The ID of the cart.
 * @param {function} setShowTransactionConfirmation - Function to control the visibility of the transaction cancellation confirmation.
 * @returns {React.Element} - Returns JSX for rendering PayPal payment element.
 */
const PayPalPaymentElement = ({ cartId, setShowTransactionConfirmation }) => {
  const options = {
    clientId: PayPal_ClientId,
    "disable-funding": "credit,card",
  };
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);
  // Create a reference object to store order-related data
  const orderRef = useRef({ orderId: null, referenceId: null, isRetry: false });

  /**
   * Function to creates a PayPal order.
   * @returns {string} - The ID of the created PayPal order.
   * @throws {Error} - If an error occurs during the process.
   */
  const createOrder = async () => {
    try {
      
      const request = {
        cartId: cartId,
        isRetry: orderRef.current.isRetry,
        // Determine the origin of the request
        // The origin is used to differentiate payments made from the benevolent site or any third-party site
        origin: window.self !== window.top ? "White-Label" : DefaultOrigin,
      };

      const response = await postData(
        `/api/payment/create-paypal-order`,
        request,
        null
      );

      orderRef.current = {
        ...orderRef.current,
        orderId: response.orderId,
        referenceId: response.referenceId,
        isRetry: false,
      };

      return response.orderId;
    } catch (error) {
      setErrorMessage(error);
      updateOrderRef();
      throw error;
    }
  };

  /**
   * Callback function to handle approval of the PayPal order.
   * @param {Object} data - Data related to the PayPal order.
   */
  const onApprove = async (data) => {
    try {
      await postData(
        `/api/payment/capture-paypal-order/${data.orderID}/${orderRef.current.referenceId}`,
        null,
        null
      );
      // Get the current URL's query string
      const urlParams = new URLSearchParams(window.location.search);
      const redirectURL = `/donation/status/${orderRef.current.referenceId}${
        urlParams.get("frameLayout") ? `?${urlParams.toString()}` : ""
      }`;
      navigate(redirectURL);
    } catch (error) {
      setErrorMessage(error);
      throw error;
    }
  };

  /**
   * Callback function to handle cancellation of the transaction.
   * @param {Object} data - Data related to the cancellation.
   */
  const onCancel = (data) => {
    updateOrderRef();
    setShowTransactionConfirmation(true);
  };

  /**
   * Callback function to handle errors during the transaction.
   * @param {Error} error - The error object representing the transaction error.
   */
  const onError = async (error) => {
    setErrorMessage(error);
    updateOrderRef();
  };

  /**
   * Function to Updates the order reference to indicate that a retry attempt should be made.
   */
  const updateOrderRef = () => {
    orderRef.current = {
      ...orderRef.current,
      isRetry: true,
    };
  };

  return (
    <Fragment>
      <PayPalScriptProvider options={options}>
        <PayPalButtons
          createOrder={createOrder}
          onApprove={onApprove}
          onError={onError}
          onCancel={onCancel}
        />
      </PayPalScriptProvider>
      {errorMessage && (
        <div className="text-danger fs-small text-center mt-2">
          {errorMessage.toString()}
          again.
        </div>
      )}
    </Fragment>
  );
};

export default PayPalPaymentElement;
