import React from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

import { Host } from "../../constants";

/**
 * Component for displaying the number of needs chosen by the user.
 * @returns {React.ReactElement} - Returns JSX for displaying needs chosen.
 */
const ExploreNeedsChosen = () => {
  const [cookies] = useCookies();
  const needsChosen = cookies["_benevolent_needs_chosen"] ?? 0;

  return (
    <Link
      to={`${Host}/donation/checkout`}
      target="_blank"
      className="text-decoration-none text-dark fs-small explore-needs-chosen"
      title="Needs Chosen"
    >
      <span className="cart-quantity d-inline-block me-2 rounded-circle text-center">
        {needsChosen}
      </span>
      Needs Chosen
    </Link>
  );
};

export default ExploreNeedsChosen;
