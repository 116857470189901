/**
 * Imports all SVG icons from a specified context.
 * @param {Object} context - The context to import icons from
 * @returns {Array<Object>} Array containing information about each imported icon
 */
const importAll = (context) => {
  const icons = [{ value: "", text: "Choose Icon", icon: null }];
  context.keys().forEach((key) => {
    const iconName = key.replace("./", "").replace(".svg", "");
    icons.push({
      value: iconName,
      text: iconName,
      icon: context(key).default || context(key),
    });
  });
  return icons;
};

/**
 * Array containing information about imported icons.
 * @type {Array<Object>}
 */
export const Icons = importAll(
  require.context("../resources/images/svg", false, /\.svg$/)
);
