import { Link } from "react-router-dom";
import { Image, Card } from "react-bootstrap";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";

import { ValidatorProfileURL } from "../../../constants";

// responsiveHOC is a Higher Order Component (HOC) used for responsive behavior,
// and LinesEllipsis is a component for truncating text with ellipsis.
const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

/**
 * Component for displaying a single validator.
 * @param {object} validator - The validator object containing information.
 * @returns {React.Element} - JSX for the validator card.
 */
const ValidatorCard = ({ validator, isEllipsisReady }) => {
  return (
    <Card className="border-0 shadow-sm rounded-4 h-100 validator">
      <Card.Body>
        <Image
          src={validator.profilePicture}
          alt="Profile Picture"
          className="profile-pic"
          fluid
          roundedCircle
        />
        <h5 className="mt-5 pt-3 mb-2 title">
          {validator.firstName} {validator.lastName}
        </h5>
        <div className="fs-small text-dark-emphasis">
          {isEllipsisReady && (
            <ResponsiveEllipsis
              text={validator.biography || ""}
              maxLine="3"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          )}
        </div>
      </Card.Body>
      <Card.Footer className="bg-transparent border-0 py-0 pe-0 text-end">
        <Link
          className="btn btn-secondary btn-arrow"
          to={`${ValidatorProfileURL}${validator.referenceId}`}
        >
          <i className="flaticon-line-arrow-right fs-5"></i>
        </Link>
      </Card.Footer>
    </Card>
  );
};

export default ValidatorCard;
