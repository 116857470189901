import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Image, Spinner } from "react-bootstrap";
import key from "weak-key";
import { transformProfilePicture } from "../../../components/transformFileURL";

import { getData } from "../../../services/apiService";
import { NeedProfileURL } from "../../../constants";

import NoData from "../../../resources/images/no-data.png";

/**
 * Component for displaying needs requiring attention.
 * This component displays the top 2 needs based on the user's role.
 * If the user is a Validator, only needs created by them are displayed.
 * If the user is a SuperAdmin or Staff, needs from the overall system are displayed.
 * @param {object} props - The properties passed to the component.
 * @returns {React.Element} - Returns JSX for displaying needs requiring attention.
 * @access Accessible by SuperAdmin, Staff, or Validator.
 */
const NeedsRequiringAttention = (props) => {
  const [needs, setNeeds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    /**
     * Function to fetch high-priority needs from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getHighPriorityNeeds = async () => {
      setIsLoading(true);
      try {
        const response = await getData(
          `/api/dashboard/getHighPriorityNeeds`,
          null,
          props.userSession
        );
        const updatedNeeds = transformProfilePicture(response);
        setNeeds(updatedNeeds);
        setIsLoading(false);
      } catch (error) {
        setNeeds([]);
        setIsLoading(false);
      }
    };
    getHighPriorityNeeds();
  }, [props]);

  return (
    <Card className="border-0 rounded-3 my-2">
      <Card.Body className="p-4 needs-requiring-attention">
        <h6 className="fw-semibold mb-4">Needs that need your attention</h6>
        {needs.map((need, index) => (
          <Fragment key={key(need)}>
            <div className="d-flex align-items-center mb-4">
              <Image
                src={need.profilePicture}
                alt="Profile Picture"
                fluid
                className="profile-pic rounded-4"
              ></Image>
              <div className="ms-3">
                <p className="mb-1 fs-small text-secondary">
                  <span className="fw-semibold">{need.displayName}</span>
                  <span className="fw-light">
                    {" "}
                    from {need.city}, {need.country}
                  </span>
                </p>
                <h5 className="title mb-0">
                  <Link
                    to={`${NeedProfileURL}${need.referenceId}/${need.needId}`}
                    className="text-decoration-none link-dark"
                  >
                    {need.title}
                  </Link>
                </h5>
              </div>
            </div>
            {/* Progress bar representing the completion percentage of fundraising for a specific need. */}
            <div
              className={`progress ${
                need.needStatus === "Funded" && need.percentageCompleted < 100
                  ? "bg-secondary-subtle"
                  : ""
              }`}
              role="progressbar"
              aria-label="Percentage of Need Funded"
            >
              <div
                className={`progress-bar ${
                  need.percentageCompleted > 100 ? "bg-primary" : "bg-secondary"
                }`}
                style={{ width: `${need.percentageCompleted}%` }}
              />
            </div>
            <p className="d-flex justify-content-between mt-2 mb-0">
              <span className="text-light-emphasis fw-light fs-small">
                Days Left:{" "}
                <span className="text-dark fw-semibold">{need.daysLeft}</span>
              </span>
              <span className="text-light-emphasis fw-light fs-small">
                <span className="text-dark fw-bold">${need.raised}</span> of $
                {need.amountNeeded}
              </span>
            </p>
            {index < needs.length - 1 && <hr className="my-4" />}
          </Fragment>
        ))}
        {needs.length === 0 && !isLoading && (
          <div className="text-center text-dark-emphasis">
            <Image src={NoData} alt="No Data Found" fluid />
            <p className="mb-0 fw-semibold">No Data Found</p>
          </div>
        )}
        {isLoading && (
          <div className="text-center">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default NeedsRequiringAttention;
