import React, { useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import Cropper from "react-easy-crop";
import getCroppedImg from "./crop";

/**
 * Component for cropping images.
 * @param {Object} props - Component props
 * @param {boolean} props.showCropModal - Indicates whether the crop modal is shown
 * @param {Function} props.closeCropModal - Function to close the crop modal
 * @param {Object} props.selectedFile - The selected image file
 * @param {Function} props.updateAvatar - Function to update the avatar with cropped image
 * @param {Function} props.resetFileInput - Function to reset the file input
 * @returns {React.Element} - Returns JSX for image cropping component
 */
const ImageCrop = ({
  showCropModal,
  closeCropModal,
  selectedFile,
  updateAvatar,
  resetFileInput,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  /**
   * Function to handle image cropping.
   */
  const handleCrop = async () => {
    try {
      const croppedImage = await getCroppedImg(
        selectedFile.url,
        croppedAreaPixels,
        rotation
      );
      updateAvatar(croppedImage, croppedAreaPixels, rotation);
      resetCrop();
      closeCropModal();
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Function to reset crop parameters.
   */
  const resetCrop = async () => {
    setCrop({ x: 0, y: 0 });
    setRotation(0);
    setZoom(1);
    setCroppedAreaPixels(null);
  };

  /**
   * Callback function for handling crop completion.
   * @param {Object} croppedArea - The cropped area
   * @param {Object} croppedAreaPixels - The cropped area in pixels
   */
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  return (
    <Modal
      show={showCropModal}
      aria-labelledby="Crop Modal"
      centered
      dialogClassName="modal-crop"
      contentClassName="bg-transparent border-0"
    >
      <Modal.Header className="border-0 px-0 justify-content-end">
        <div>
          <i className="flaticon-crop fs-2 p-1" onClick={handleCrop}></i>
          <i
            className="flaticon-zoom-in fs-3 p-1"
            onClick={() => setZoom(Math.min(zoom + 0.1, 3))}
          ></i>
          <i
            className="flaticon-zoom-out fs-3 p-1"
            onClick={() => setZoom(Math.max(zoom - 0.1, 1))}
          ></i>
          <i
            className="flaticon-rotate-left fs-4 p-1"
            onClick={() => setRotation((rotation - 90) % 360)}
          ></i>
          <i
            className="flaticon-rotate-right fs-4 p-1"
            onClick={() => setRotation((rotation + 90) % 360)}
          ></i>
          <i
            className="flaticon-close fs-5 p-1"
            onClick={() => {
              closeCropModal();
              resetCrop();
              resetFileInput();
            }}
          ></i>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="crop-container">
          <Cropper
            image={selectedFile?.src}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            aspect={4 / 3}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropSize={{ width: 400, height: 400 }}
            objectFit="cover"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ImageCrop;
