import React, { useState, useEffect, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Button,
  Spinner,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import key from "weak-key";

// import StripePaymentElement from "./stripePaymentElement";
import PayPalPaymentElement from "./paypalPaymentElement";
import SquarePaymentElement from "./squarePaymentElement";
import TransactionCancellationConfirmation from "./transactionCancellationConfirmation";

import { getData } from "../../services/apiService";
import { useAuthContext } from "../../context/authProvider";
import { decodeToken } from "../../utils/session";

import noData from "../../resources/images/no-data.png";

/**
 * Component for handling payment processing from multiple providers.
 * This component manages the payment process for completing transactions using PayPal, Stripe, or Square.
 * @returns {React.Element} - Returns JSX for handling payments.
 */
const Payment = () => {
  const { cartId } = useParams();
  // Get the current URL's query string
  const [searchParams] = useSearchParams();
  // This reference is used to interact with a element in component.
  const pageRef = useRef(null);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  // Decode the user session token
  const user = decodeToken(userSession);
  const [cookies, setCookie] = useCookies();

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [cart, setCart] = useState({});
  const [showTransactionConfirmation, setShowTransactionConfirmation] =
    useState(false);
  // To determine the origin of the iframe and whether it is a white-label integration.
  const [isWhiteLabel] = useState(window.self !== window.top);

  // frameContent refers to the children of the current pageRef,
  // which represents the content of the iframe.
  const frameContent = pageRef.current ? pageRef.current.children : null;

  /**
   * Function to apply styles to elements in the iframe based on search parameters.
   */
  const applyFrameStylesFromSearchParams = () => {
    Array.from(frameContent).forEach((content) => {
      const summary = content.querySelector("div.summary");
      if (summary) {
        const link = summary.querySelector(".link-secondary");
        if (link) applyColorProperty(link, "LINK", "secondaryColor");
      }

      // Update style for the caution message
      const cautionMessage = content.querySelector("div.caution-message");
      if (cautionMessage) {
        const link = cautionMessage.querySelector(".btn-primary");
        if (link) applyColorProperty(link, "BUTTON", "primaryColor");
      }
    });
  };

  /**
   * Function to applies color properties to the specified element based on the provided color parameter.
   * @param {HTMLElement} element - The HTML element to apply the color properties to.
   * @param {string} elementType - The type of element, e.g., "BUTTON".
   * @param {string} colorParam - The name of the search parameter containing the color value.
   */
  const applyColorProperty = (element, elementType, colorParam) => {
    const color = searchParams.get(colorParam);
    if (elementType === "BUTTON") {
      element.style.setProperty("background-color", color, "important");
      element.style.setProperty("border-color", color, "important");
    }
    if (elementType === "LINK")
      element.style.setProperty("color", color, "important");
  };

  useEffect(() => {
    /**
     * Hook to apply frame styles.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    if (frameContent && frameContent?.length > 0 && isWhiteLabel)
      applyFrameStylesFromSearchParams();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, frameContent, isWhiteLabel]);

  useEffect(() => {
    /**
     * Fetches cart from the backend API on component mount.
     */
    const getCart = async () => {
      setIsPageLoading(true);
      try {
        const response = await getData(
          `/api/cart/get/${cartId}/${user?.nameid ?? ""}`,
          null,
          null
        );

        setCart(response);
        setIsPageLoading(false);
      } catch (error) {
        // Set cart if an error occurs any.
        setCart({});
        setIsPageLoading(false);
      }
    };
    if (cartId > 0 || user?.nameid) getCart();
    else setIsPageLoading(false);
    // Unable to detect the browser back button using the pop state event.
    // Alternatively, we can utilize cookies to identify the origin page when a user navigates back,
    // allowing us to display the transaction cancellation confirmation accordingly.
    if (cookies["_benevolent_cartId"])
      setCookie("_benevolent_payment_origin", "Payment", {
        path: "/",
        sameSite: "none",
        secure: true,
      });

    // Return a cleanup function
    return () => {};
  }, [cartId, user?.nameid, setCookie]);

  return (
    <Container ref={pageRef}>
      {Object.keys(cart).length > 0 && (
        <Row className="justify-content-center my-4">
          <Col xs={12} lg={9} xl={8} xxl={7}>
            <Row className="align-items-center justify-content-center my-4 shadow-sm bg-white rounded-4 p-4">
              <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="border-end">
                <Card className="border-0 rounded-0 summary">
                  <Card.Body>
                    <Link
                      to={-1}
                      className="text-decoration-none link-secondary"
                    >
                      <i className="flaticon-line-arrow-left fs-4 pe-2"></i>
                      <span className="align-text-bottom">Back to Cart</span>
                    </Link>
                    <h4 className="title mt-4 mb-3">Summary</h4>
                    <hr />
                    {cart.cartItemInfo?.map((cartItem) => (
                      <div
                        className="d-flex justify-content-between my-3 text-dark-emphasis"
                        key={key(cartItem)}
                      >
                        <div>
                          <h5 className="title mb-0">{cartItem.recipient}</h5>
                          <small className="mb-2">{cartItem.needTitle}</small>
                        </div>
                        <span>${cartItem.amount}</span>
                      </div>
                    ))}
                    <div className="d-flex justify-content-between my-3 text-dark-emphasis">
                      <span>Additional Gift</span>
                      <span>${cart.contribution}</span>
                    </div>
                    <hr className="my-3" />
                    <div className="d-flex justify-content-between fw-semibold text-dark-emphasis my-3">
                      <span>TOTAL</span>
                      <span>${cart.totalAmount}</span>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="p-4">
                <PayPalPaymentElement
                  cartId={cartId}
                  setShowTransactionConfirmation={
                    setShowTransactionConfirmation
                  }
                />
                <hr className="my-4" />
                {/* {DefaultPaymentGateway === "Stripe" ? (
                  <StripePaymentElement
                    cartId={cartId}
                    userId={user?.nameid ?? ""}
                    totalAmount={cart?.totalAmount ?? 0}
                  />
                ) : ( */}
                <SquarePaymentElement
                  userSession={userSession}
                  userId={user?.nameid ?? ""}
                  cartId={cartId}
                  totalAmount={cart?.totalAmount ?? 0}
                />
                {/* )} */}
                <Button
                  variant="light"
                  className="btn-md w-100 mt-3"
                  onClick={() => setShowTransactionConfirmation(true)}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {Object.keys(cart).length === 0 && !isPageLoading && (
        <Row className="justify-content-center text-center">
          <Col xs={12} sm={9} md={7} lg={6} xl={5} xxl={4}>
            <Card className="border-0 shadow p-4 rounded-4 text-center caution-message">
              <Card.Body>
                <Image src={noData} alt="Invalid Cart" fluid />
                <h4 className="title mt-3">Invalid Cart</h4>
                <p className="mb-0 fw-light text-dark-emphasis">
                  It appears that the cart you are attempting to donate is
                  currently unavailable.
                </p>
                {isWhiteLabel && (
                  <Link
                    to={`/giving-page/organization/${searchParams.get(
                      "code"
                    )}?frameLayout=plain`}
                    title="Back to Home"
                    className="btn btn-primary btn-md mt-3"
                  >
                    Back to Home
                  </Link>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {Object.keys(cart).length === 0 && isPageLoading && (
        <div className="text-center">
          <Spinner
            animation="border"
            role="status"
            variant={!isWhiteLabel ? "primary" : "light"}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <TransactionCancellationConfirmation
        cartId={cartId}
        showTransactionConfirmation={showTransactionConfirmation}
        setShowTransactionConfirmation={setShowTransactionConfirmation}
        setIsPageLoading={setIsPageLoading}
      />
    </Container>
  );
};

export default Payment;
