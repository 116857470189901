import React, { useState, useEffect, useReducer, Fragment } from "react";
import {
  Container,
  Modal,
  Button,
  Spinner,
  Alert,
  Image,
  Card,
  Dropdown,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import DataTable from "../../components/table";
import { Cards } from "../../components/cardImporter";

import { putData, getData } from "../../services/apiService";
import { useAuthContext } from "../../context/authProvider";
import { decodeToken } from "../../utils/session";
import { notify, initialState } from "../../store/notification";
import { DefaultPaymentGateway } from "../../constants";

import confirmation from "../../resources/images/confirmation.png";
import CardAvatar from "../../resources/images/card/CARD.png";

/**
 * Component for managing payment options.
 * @returns {React.Element} - Returns JSX for managing payment options.
 */
const ManagePaymentOptions = () => {
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  // Decode the user session token
  const user = decodeToken(userSession);

  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState([]);

  // Columns configuration for payment option table
  const columns = [
    {
      header: "Card Type",
      cell: (cellProps) => {
        return (
          <Fragment>
            <div className="d-flex align-items-center">
              <Image
                src={
                  Cards.find((x) =>
                    x.name.includes(cellProps.row.original.cardType)
                  )?.image || CardAvatar
                }
                alt={cellProps.row.original.cardType}
                fluid
                className="avatar"
              />
              <div className="ms-1 text-dark">
                [{cellProps.row.original.cardType}]
              </div>
            </div>
          </Fragment>
        );
      },
    },
    {
      header: "Card's Last Four Digits",
      accessorKey: "cardLastFourDigit",
    },
    {
      header: "Expires On",
      accessorKey: "expiresOn",
    },
    {
      header: "Actions",
      accessorKey: "paymentMethod",
      cell: ({ getValue }) => (
        <Dropdown drop="down" className="more-menu">
          <Dropdown.Toggle variant="light" size="sm">
            <i className="flaticon-more"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => showConfirmation(getValue())}>
              <i className="flaticon-delete fs-6 me-1 align-text-bottom"></i>
              Detach
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    /**
     * Fetches all payment options for a user from the backend API.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getAll = async () => {
      setIsLoading(true);
      try {
        const response = await getData(
          `/api/paymentOption/getAll/${user.nameid}/${DefaultPaymentGateway}`,
          null,
          userSession
        );
        setPaymentOptions(response);
        setIsLoading(false);
      } catch (error) {
        showNotification("danger", error, 5000);
        setPaymentOptions([]);
        setIsLoading(false);
      }
    };

    getAll();

    // Return a cleanup function
    return () => {};
  }, [userSession, user.nameid]);

  /**
   * Function to detach a payment method from the user's account.
   * @param {string} paymentMethod - The ID of the payment method to detach.
   */
  const detach = async (paymentMethod) => {
    setIsPageLoading(true);
    try {
      const response = await putData(
        `/api/paymentOption/detach`,
        {
          userId: user.nameid,
          source: DefaultPaymentGateway,
          paymentMethod: paymentMethod,
        },
        userSession
      );
      setPaymentOptions(
        paymentOptions.filter((x) => x.paymentMethod !== paymentMethod)
      );
      setIsPageLoading(false);
      showNotification("success", response, 5000);
    } catch (error) {
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  /**
   * Function to display a confirmation dialog for detaching a payment method.
   * @param {string} paymentMethod - The ID of the payment method to detach.
   */
  const showConfirmation = (paymentMethod) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Card className="border-0 shadow rounded-3">
            <Card.Body className="p-4 text-center">
              <Image src={confirmation} alt="Delete Confirmation" fluid />
              <div className="mt-3 fw-light">
                Do you really want to detach this payment method? <br />
                This process can't be undone.
              </div>
            </Card.Body>
            <Card.Footer className="bg-transparent py-4 text-center">
              <Button
                title="Cancel"
                variant="link"
                className="link-danger fw-semibold"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                className="ms-4 btn-md"
                onClick={async () => {
                  try {
                    await detach(paymentMethod);
                  } catch (error) {
                    // showNotification("danger", error, 5000);
                  }
                  onClose();
                }}
              >
                Yes, Detach
              </Button>
            </Card.Footer>
          </Card>
        );
      },
    });
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main>
      <Container fluid>
        <h6 className="module-title fw-semibold mb-4">
          Manage Payment Options
        </h6>

        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <DataTable
              columns={columns}
              data={paymentOptions}
              totalRecords={paymentOptions.length}
              loadingState={isLoading}
            />
          </Card.Body>
        </Card>
      </Container>

      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default ManagePaymentOptions;
