import React, { useState, useEffect, useMemo, useRef, Fragment } from "react";
import {
  Form,
  InputGroup,
  Accordion,
  Button,
  Spinner,
  Navbar,
} from "react-bootstrap";
import { useForm } from "react-hook-form";

import { postData } from "../../../services/apiService";

/**
 * Component for rendering filters to search needs.
 * Manages filtering options for search text, sort by criteria, categories, and locations.
 * @param {object} props - Component properties.
 * @param {function} props.triggerSearch - Function to trigger search based on filters.
 * @returns {React.Element} - Returns JSX for rendering filters.
 */
const Filters = (props) => {
  const { register, reset } = useForm();

  const [page, setPage] = useState({
    category: { PageNumber: 1, PageSize: 10 },
    location: { PageNumber: 1, PageSize: 10 },
  });
  const [totalRecords, setTotalRecords] = useState({
    category: 0,
    location: 0,
  });
  const [loadingStates, setLoadingStates] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState(props.sortBy);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(
    props.categories ?? []
  );
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [locations, setLocations] = useState([]);

  /**
   * Hook to memoize form data.
   * useMemo is a technique used in programming to optimize performance by storing the results of
   * expensive function calls and returning the cached result when the same inputs occur again.
   * unnecessary re-renders can be avoided, resulting in improved performance.
   * @returns {Object} - Returns an object containing search term, sort by, selected categories, selected cities, and selected countries.
   */
  const formData = useMemo(() => {
    return {
      searchTerm,
      sortBy,
      categories: selectedCategories,
      cities: selectedCities,
      states: selectedStates,
      countries: selectedCountries,
    };
  }, [
    searchTerm,
    sortBy,
    selectedCategories,
    selectedCities,
    selectedStates,
    selectedCountries,
  ]);

  // Create a ref to maintain the previous form data
  const prevFormDataRef = useRef(formData);

  /**
   * Function to compare current formData with
   * previous formData to determine if any changes occurred
   * @returns {boolean} - Return true if it has changed, otherwise return false.
   */
  const formDataChanged = useMemo(() => {
    const prevFormData = prevFormDataRef.current;
    if (formData.searchTerm !== prevFormData.searchTerm) return true;
    if (formData.sortBy !== prevFormData.sortBy) return true;
    if (formData.categories.length !== prevFormData.categories.length)
      return true;
    if (formData.cities.length !== prevFormData.cities.length) return true;
    if (formData.states.length !== prevFormData.states.length) return true;
    if (formData.countries.length !== prevFormData.countries.length)
      return true;
    return false;
  }, [formData]);

  useEffect(() => {
    // Update the ref with the current form data
    prevFormDataRef.current = formData;
    // Executes when there is a change in the values of the dependencies in the useEffect hook.
    if (formDataChanged) props.triggerSearch(formData);
  }, [formData, formDataChanged]);

  /**
   * Function to handle the change event when a category checkbox is clicked.
   * Updates the selectedCategories array based on checkbox changes.
   * @param {object} event - The event object containing information about the checkbox click.
   */
  const handleCategoryChange = (event) => {
    const { value, checked } = event.target;
    // Update the selectedCategories array based on checkbox changes
    if (checked) {
      setSelectedCategories((prevSelectedCategories) => [
        ...prevSelectedCategories,
        value,
      ]);
    } else {
      setSelectedCategories((prevSelectedCategories) =>
        prevSelectedCategories.filter((item) => item !== value)
      );
    }
  };

  /**
   * Function to handle the change event when a location checkbox is clicked.
   * Updates the selectedCities and selectedCountries arrays based on checkbox changes.
   * @param {object} event - The event object containing information about the checkbox click.
   */
  const handleLocationChange = (event) => {
    const { value, checked } = event.target;
    // Extract city and country values from the checkbox value
    const [city, state, country] = value.split(",").map((item) => item.trim());
    if (checked) {
      setSelectedCities((prevSelectedCities) => [...prevSelectedCities, city]);
      setSelectedStates((prevSelectedStates) => [...prevSelectedStates, state]);
      setSelectedCountries((prevSelectedCountries) => [
        ...prevSelectedCountries,
        country,
      ]);
    } else {
      setSelectedCities((prevSelectedCities) =>
        prevSelectedCities.filter((item) => item !== city)
      );
      setSelectedStates((prevSelectedStates) =>
        prevSelectedStates.filter((item) => item !== state)
      );
      setSelectedCountries((prevSelectedCountries) =>
        prevSelectedCountries.filter((item) => item !== country)
      );
    }
  };

  useEffect(() => {
    /**
     * Fetches the categories from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getCategories = async () => {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        isLoadingCategory: true,
      }));
      try {
        const response = await postData(
          `/api/category/getByPage?searchTerm=${searchTerm}`,
          page.category,
          null
        );
        setCategories((prevCategories) => [
          ...prevCategories,
          ...response.categories,
        ]);
        setTotalRecords((prevTotalRecords) => ({
          ...prevTotalRecords,
          category:
            response.count !== null
              ? Math.max(0, response.count - page.category.PageSize)
              : Math.max(0, prevTotalRecords.category - page.category.PageSize),
        }));

        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          isLoadingCategory: false,
        }));
      } catch (error) {
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          isLoadingCategory: false,
        }));
        setCategories([]);
      }
    };
    getCategories();

    return () => {};
  }, [page.category]);

  useEffect(() => {
    /**
     * Fetches recipient's locations from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getLocations = async () => {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        isLoadingLocation: true,
      }));
      try {
        const response = await postData(
          `/api/need/getRecipientAddress?searchTerm=${searchTerm}&type=Recipient`,
          page.location,
          null
        );
        setLocations((prevCategories) => [
          ...prevCategories,
          ...response.address,
        ]);

        setTotalRecords((prevTotalRecords) => ({
          ...prevTotalRecords,
          location:
            response.count !== null
              ? Math.max(0, response.count - page.location.PageSize)
              : Math.max(0, prevTotalRecords.location - page.location.PageSize),
        }));
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          isLoadingLocation: false,
        }));
      } catch (error) {
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          isLoadingLocation: false,
        }));
        setLocations([]);
      }
    };
    getLocations();
    return () => {};
  }, [page.location]);

  /**
   * Function to load more categories by incrementing the page number.
   * Updates the page state to fetch the next page of categories.
   */
  const loadMoreCategories = () => {
    setPage((prevPage) => ({
      ...prevPage,
      category: {
        ...prevPage.category,
        PageNumber: prevPage.category.PageNumber + 1,
      },
    }));
  };

  /**
   * Function to load more recipient's locations by incrementing the page number.
   * Updates the page state to fetch the next page of locations.
   */
  const loadMoreLocations = () => {
    setPage((prevPage) => ({
      ...prevPage,
      location: {
        ...prevPage.location,
        PageNumber: prevPage.location.PageNumber + 1,
      },
    }));
  };

  /**
   * Function to reset all filters.
   * Resets form data, search term, sort by criteria, selected categories, cities, and countries.
   */
  const resetFilter = () => {
    reset();
    setSearchTerm("");
    setSortBy("All");
    setSelectedCategories([]);
    setSelectedCities([]);
    setSelectedStates([]);
    setSelectedCountries([]);
  };

  return (
    <Fragment>
      <Navbar variant="light" expand="lg">
        <Navbar.Toggle
          data-bs-toggle="collapse"
          data-bs-target="#filters"
          aria-controls="filters"
          aria-expanded={false}
          aria-label="Toggle filters"
          className="ms-auto mb-4"
        />

        <Navbar.Collapse id="filters">
          <Form>
            <div className="d-flex justify-content-between">
              <span className="text-secondary fw-semibold">FILTERS</span>
              <span
                className="text-light-emphasis fw-semibold"
                role="button"
                onClick={resetFilter}
              >
                CLEAR ALL
              </span>
            </div>
            <InputGroup className="my-4">
              <InputGroup.Text className="bg-transparent border-end-0">
                <i className="flaticon-search fs-5"></i>
              </InputGroup.Text>
              <Form.Control
                type="text"
                className="bg-transparent border-start-0"
                placeholder="Search..."
                {...register("searchTerm")}
                onKeyUp={(e) => {
                  if (e.target.value.length >= 2 || !e.target.value)
                    setSearchTerm(e.target.value);
                }}
              />
            </InputGroup>
            <Accordion defaultActiveKey={["0", "1", "2"]}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>SORT BY</Accordion.Header>
                <Accordion.Body className="p-0">
                  <Form.Select
                    aria-label="Filter Type"
                    className="bg-transparent"
                    {...register("sortBy")}
                    onChange={(e) => {
                      setSortBy(e.target.value);
                    }}
                    value={sortBy}
                  >
                    <option value="All">All</option>
                    <option value="Published">Ongoing Needs</option>
                    <option value="Newly Added">Newly Added</option>
                    <option value="Featured">Featured Needs</option>
                    <option value="Attention Required">
                      Attention Required Needs
                    </option>
                    <option value="Funded">Successful Needs</option>
                  </Form.Select>
                </Accordion.Body>
              </Accordion.Item>
              <hr />
              <Accordion.Item eventKey="1">
                <Accordion.Header>CATEGORIES</Accordion.Header>
                <Accordion.Body className="p-0">
                  <div className="categories scroll">
                    {categories.map((category, index) => (
                      <Form.Check
                        key={`"chkCategory_"${index}`}
                        className="mb-2 fw-light"
                        name={`"chkCategory_"${index}`}
                        label={category.name}
                        value={category.name}
                        type="checkbox"
                        defaultChecked={selectedCategories.includes(
                          category.name
                        )}
                        onClick={handleCategoryChange}
                      />
                    ))}
                  </div>
                  {loadingStates["isLoadingCategory"] && (
                    <div className="text-center my-3">
                      <Spinner
                        animation="border"
                        role="status"
                        variant="primary"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  )}
                  {totalRecords.category > 0 && (
                    <Button
                      variant="link"
                      className="link-primary mt-2"
                      onClick={loadMoreCategories}
                    >
                      +{totalRecords.category} More
                    </Button>
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <hr />
              <Accordion.Item eventKey="2">
                <Accordion.Header>LOCATIONS</Accordion.Header>
                <Accordion.Body className="p-0">
                  <div className="locations scroll">
                    {locations.map((location, index) => (
                      <Form.Check
                        key={`chkLocation_${index}`}
                        className="mb-2 fw-light"
                        name={`chkLocation_${index}`}
                        label={`${
                          location.state
                            ? `${location.city}, ${location.state}`
                            : `${location.city}, ${location.country}`
                        }`}
                        value={`${location.city}, ${location.state}, ${location.country}`}
                        type="checkbox"
                        defaultChecked={
                          selectedCities.includes(location.city) &&
                          (selectedStates.includes(location.state) ||
                            selectedCountries.includes(location.country))
                        }
                        onClick={handleLocationChange}
                      />
                    ))}
                  </div>
                  {loadingStates["isLoadingLocation"] && (
                    <div className="text-center my-3">
                      <Spinner
                        animation="border"
                        role="status"
                        variant="primary"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  )}
                  {totalRecords.location > 0 && (
                    <Button
                      variant="link"
                      className="link-primary mt-2"
                      onClick={loadMoreLocations}
                    >
                      +{totalRecords.location} More
                    </Button>
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <hr />
            </Accordion>
          </Form>
        </Navbar.Collapse>
      </Navbar>
    </Fragment>
  );
};

export default Filters;
