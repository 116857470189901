import React from "react";
import { Link } from "react-router-dom";
import { Container, Image } from "react-bootstrap";

import forbidden from "../../resources/images/forbidden.png";

/**
 * Component for displaying access denied message.
 * This component is rendered when a user attempts to access a restricted page.
 * @returns {React.Element} - Returns JSX for displaying access denied message.
 */
const AccessDenied = () => {
  return (
    <main className="d-flex align-items-center justify-content-center min-vh-100 flex-column bg-white">
      <Container className="text-center access-denied">
        <Image src={forbidden} alt="Forbidden" fluid />
        <h3 className="text-secondary fw-semibold mb-2 mt-3">
          You're not permitted to access this.
        </h3>
        <p className="mb-2">
          The page you're trying to access has restricted access.
        </p>
        <p className="mb-2">
          If you feel this is a mistake, contact your admin.
        </p>
        <Link
          to="/login"
          title="Login"
          className="btn btn-secondary btn-md fw-semibold mt-3"
        >
          RETURN HOME
        </Link>
      </Container>
    </main>
  );
};

export default AccessDenied;
