import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, Image } from "react-bootstrap";

import { SITEURL } from "../../constants";

import logo from "../../resources/images/logo.svg";
import emailVerified from "../../resources/images/email-verified.png";

/**
 * Component for displaying a password reset confirmation message indicating that the user's password has been successfully reset.
 * @returns {React.Element} - Returns JSX for displaying the password reset confirmation message.
 */
const ResetPasswordConfirmation = () => {
  return (
    <main className="d-flex align-items-center justify-content-center min-vh-100 flex-column">
      <Container>
        <Row className="justify-content-center">
          <Col xs={11} sm={10} md={8} lg={6} xl={5} xxl={4}>
            <div className="text-center mb-4">
              <Link to={SITEURL} title="Benevolent">
                <Image src={logo} alt="Benevolent" className="logo" fluid />
              </Link>
            </div>
            <Card className="border-0 shadow p-4 rounded-4 text-center">
              <Card.Body>
                <Image src={emailVerified} alt="Password Reset" fluid />
                <h4 className="title mt-4">Congratulations!</h4>
                <p className="mb-5 fw-light text-dark-emphasis">
                  Your password has been successfully reset. You can now login
                  with your new password.
                </p>
                <hr />
                <small className="text-dark-emphasis fw-light">
                  <Link
                    title="Login"
                    className="text-decoration-none link-secondary fw-bold"
                    to="/"
                  >
                    Click here{" "}
                  </Link>
                  to login
                </small>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default ResetPasswordConfirmation;
