import React, { useState, useReducer } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Form,
  Button,
  InputGroup,
  Spinner,
  Alert,
} from "react-bootstrap";
import { useForm } from "react-hook-form";

import { resetPassword } from "../../services/accountService";
import { notify, initialState } from "../../store/notification";
import { SITEURL } from "../../constants";

import logo from "../../resources/images/logo.svg";

/**
 * Component for resetting the password.
 * This component allows users to set a new password by submitting a form with their new password.
 * @returns {React.Element} - Returns JSX for resetting the password.
 */
const ResetPassword = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const password = watch("password");
  // Hook for accessing URL search parameters
  const [params] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);

  /**
   * Function to handle the form submission for resetting the password.
   * @param {Object} data - The form data containing the new password.
   */
  const onSubmit = async (data) => {
    setIsLoading(true);
    // Retrieves userId and token from URL search parameters.
    data.userId = params.get("userId");
    data.token = params.get("token");
    try {
      await resetPassword(data);
      setIsLoading(false);
      navigate("/reset-password-confirmation");
    } catch (error) {
      // Show error notification if password reset fails
      showNotification("danger", error, 5000);
      setIsLoading(false);
    }
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch, // Pass dispatch function to the payload for updating notification state
    });
  };

  return (
    <main className="d-flex align-items-center justify-content-center min-vh-100 flex-column">
      <Container>
        <Row className="justify-content-center">
          <Col xs={11} sm={10} md={8} lg={6} xl={5} xxl={4}>
            <div className="text-center mb-5">
              <Link to={SITEURL} title="Benevolent">
                <Image src={logo} alt="Benevolent" className="logo" fluid />
              </Link>
            </div>
            <Card className="border-0 shadow p-4 rounded-4">
              <Card.Body>
                <h4 className="title text-center mb-3">Set new password</h4>
                <p className="mb-4 fw-light text-dark-emphasis text-center">
                  Your new password must be different from previously used
                  passwords
                </p>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group className="mb-3">
                    <InputGroup>
                      <Form.Floating>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          {...register("password", {
                            required: "Password is required",
                          })}
                        />
                        <Form.Label>New Password</Form.Label>
                      </Form.Floating>
                      <InputGroup.Text>
                        <i className="flaticon-password fs-3"></i>
                      </InputGroup.Text>
                    </InputGroup>
                    {errors.password && (
                      <Form.Text className="text-danger">
                        {errors.password.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <InputGroup>
                      <Form.Floating>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          {...register("confirmPassword", {
                            required: "Confirm Password is required",
                            validate: (value) =>
                              value === password || "Passwords do not match",
                          })}
                        />
                        <Form.Label>Re-type Password</Form.Label>
                      </Form.Floating>
                      <InputGroup.Text>
                        <i className="flaticon-password fs-3"></i>
                      </InputGroup.Text>
                    </InputGroup>
                    {errors.confirmPassword && (
                      <Form.Text className="text-danger">
                        {errors.confirmPassword.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <div className="d-grid gap-2">
                    <Button
                      variant="primary"
                      disabled={isLoading}
                      type="submit"
                      size="lg"
                    >
                      {isLoading ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        "RESET PASSWORD"
                      )}
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
            <div className="text-center mt-4">
              <p className="mb-0 text-dark-emphasis">
                Back to{" "}
                <Link
                  to="/"
                  title="Login"
                  className="text-decoration-none link-secondary fw-semibold"
                >
                  Login
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Notification alerts */}
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
    </main>
  );
};

export default ResetPassword;
