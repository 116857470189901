import { Routes } from "react-router-dom";

import { RenderRoutes } from "./routes";
import { PrimaryRoutes, SecondaryRoutes } from "./routes/privateRoute";
import PublicRoutes from "./routes/publicRoute";

const App = () => {  
  const routes = [...PrimaryRoutes, ...SecondaryRoutes, ...PublicRoutes];

  return (
    <Routes>
      {/* Rendering routes using the RenderRoutes utility function */}
      {RenderRoutes(routes)}
    </Routes>
  );
};
export default App;
