import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Card,
  Modal,
  Spinner,
} from "react-bootstrap";

import { transformProfilePicture } from "../../components/transformFileURL";
import { Swiper, SwiperSlide } from "../../components/swiper";
import ValidatorCard from "./widgets/_validatorCard";
import NeedCard from "./widgets/_needCard";

import { getData, postData } from "../../services/apiService";

import noData from "../../resources/images/no-data.png";
import dot from "../../resources/images/dot.png";
import square from "../../resources/images/square.png";

/**
 * Component for displaying organization profile information.
 * This component renders information about the organization, including active validators and their needs.
 * @returns {React.Element} - Returns JSX for organization profile.
 */
const OrganizationProfile = () => {
  // Extract the 'referenceId' parameter from the URL
  const { referenceId } = useParams();
  const location = useLocation();
  const validatorSwiperRef = useRef(null);
  const needSwiperRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [organization, setOrganization] = useState({});
  const [validators, setValidators] = useState([]);
  const [needs, setNeeds] = useState([]);
  // This ensures that the ellipsis component initialization occurs after the content is fully rendered.
  const [isEllipsisReady, setIsEllipsisReady] = useState(false);

  // State to manage pagination for validators and needs
  const [page, setPage] = useState({
    validators: { PageNumber: 1, PageSize: 25 },
    needs: { PageNumber: 1, PageSize: 25 },
  });

  // State to store the total number of records for validators and needs
  const [totalRecords, setTotalRecords] = useState({
    validators: 0,
    needs: 0,
  });

  useEffect(() => {
    /**
     * Fetches the organization profile from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getOrgProfile = async () => {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        profile: true,
      }));
      try {
        const response = await getData(
          `/api/profile/organization/${referenceId}`,
          null
        );
        if (response) setOrganization(response);
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          profile: false,
        }));
        setIsMounted(true);
      } catch (error) {
        setIsMounted(true);
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          profile: false,
        }));
      }
    };
    getOrgProfile();
  }, [referenceId]);

  useEffect(() => {
    /**
     * Fetches the published and funded needs for a specific organization from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getValidatedNeeds = async () => {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        needs: true,
      }));
      try {
        const response = await postData(`/api/profile/getValidatedNeeds`, {
          referenceId,
          type: "Organization",
          page: page.needs,
          searchTerm: "",
          isPrivateProfile: false,
        });

        const updatedNeeds = transformProfilePicture(response.needs);
        setNeeds((prevNeeds) => [...prevNeeds, ...updatedNeeds]);
        // Update the swiper if it exists
        if (needSwiperRef.current) needSwiperRef.current.update();
        if (response.count != null) {
          setTotalRecords((prevCountStates) => ({
            ...prevCountStates,
            needs: response.count,
          }));
        }
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          needs: false,
        }));
      } catch (error) {
        setNeeds([]);
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          needs: false,
        }));
      }
    };

    if (referenceId && Object.keys(organization).length > 0)
      getValidatedNeeds();
  }, [page.needs, referenceId, organization]);

  /**
   * Function to navigate to the previous slide in the Swiper component.
   * If the Swiper reference exists, it slides to the previous slide.
   */
  const goToPreviousSlideForNeed = () => {
    if (needSwiperRef.current) needSwiperRef.current.slidePrev();
  };

  /**
   * Function to navigate to the next slide in the Swiper component.
   * If the Swiper reference exists, it slides to the next slide.
   * If the current slide is the last slide or the end of the Swiper, it updates the page number.
   */
  const goToNextSlideForNeed = () => {
    if (needSwiperRef.current) {
      needSwiperRef.current.slideNext();
      if (
        needSwiperRef.current.isEnd ||
        needSwiperRef.current.activeIndex ===
          needSwiperRef.current.slides.length - 1
      ) {
        const totalPages = Math.ceil(totalRecords.needs / page.needs.PageSize);

        if (page.needs.PageNumber < totalPages) {
          setPage((prevPageStates) => ({
            ...prevPageStates,
            needs: {
              ...prevPageStates.needs,
              PageNumber: prevPageStates.needs.PageNumber + 1,
            },
          }));
        }
      }
    }
  };

  useEffect(() => {
    /**
     * Fetches the validators for a specific organization from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getValidators = async () => {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        validators: true,
      }));
      try {
        const response = await postData(`/api/profile/getValidators`, {
          organizationId: organization.organizationId,
          status: "Active",
          page: page.validators,
          searchTerm: "",
        });

        const updatedValidators = transformProfilePicture(response.validators);
        setValidators((prevValidators) => [
          ...prevValidators,
          ...updatedValidators,
        ]);
        if (validatorSwiperRef.current) validatorSwiperRef.current.update();
        if (response.count != null) {
          setTotalRecords((prevCountStates) => ({
            ...prevCountStates,
            validators: response.count,
          }));
        }
        setTimeout(() => {
          setIsEllipsisReady(true);
        }, 100);        
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          validators: false,
        }));
      } catch (error) {
        setValidators([]);
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          validators: false,
        }));
      }
    };

    // Fetch validators if the organization ID is valid
    if (organization?.organizationId > 0) getValidators();
  }, [page.validators, organization]);

  /**
   * Function to navigate to the previous slide in the Swiper component.
   * If the Swiper reference exists, it slides to the previous slide.
   */
  const goToPreviousSlideForValidator = () => {
    if (validatorSwiperRef.current) validatorSwiperRef.current.slidePrev();
  };

  /**
   * Function to navigate to the next slide in the Swiper component.
   * If the Swiper reference exists, it slides to the next slide.
   * If the current slide is the last slide or the end of the Swiper, it updates the page number.
   */
  const goToNextSlideForValidator = () => {
    if (validatorSwiperRef.current) {
      validatorSwiperRef.current.slideNext();
      if (
        validatorSwiperRef.current.isEnd ||
        validatorSwiperRef.current.activeIndex ===
          validatorSwiperRef.current.slides.length - 1
      ) {
        const totalPages = Math.ceil(
          totalRecords.validators / page.validators.PageSize
        );

        if (page.validators.PageNumber < totalPages) {
          setIsEllipsisReady(false);
          setPage((prevPageStates) => ({
            ...prevPageStates,
            validators: {
              ...prevPageStates.validators,
              PageNumber: prevPageStates.validators.PageNumber + 1,
            },
          }));
        }
      }
    }
  };

  return (
    <div className="organization-profile">
      {Object.keys(organization).length > 0 && (
        <Container className="mt-4 mb-5">
          <Row className="align-items-center justify-content-center">
            {location.key !== "default" && (
              <Col xs={12} className="text-end">
                <Link
                  to={-1}
                  className="text-decoration-none fw-semibold"
                  title="Back"
                >
                  <i className="flaticon-back pe-1 fs-5"></i>
                  Back to Prior Page
                </Link>
              </Col>
            )}
            <Col xs={12} className="text-center mb-5 pb-4 page-title">
              <h1 className="title mt-1">
                <span className="position-relative fw-medium">
                  Organization<span className="title-divider"></span>
                </span>{" "}
                <span className="text-secondary">Profile</span>
              </h1>
            </Col>
            <Col xs={8} sm={8} md={6} lg={5} xl={4} xxl={3}>
              <div className="profile-picture bg-secondary rounded-5 position-relative ms-auto">
                <Image src={dot} alt="Dot" className="dot" />
                <Image src={square} alt="Square" className="square" />
                <Image
                  src={transformProfilePicture(organization.profilePicture, {
                    isThumbnail: false,
                  })}
                  alt="Organization"
                  fluid
                  className="rounded-5 ps-2"
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={5}>
              <div className="content p-4">
                <h4 className="title mb-0">{organization.name}</h4>
                <p className="mb-2">
                  <Link
                    to={organization.website}
                    title="Profile"
                    className="link-secondary text-decoration-none"
                  >
                    Visit Website
                  </Link>
                </p>
                <p className="mb-0 fs-small text-dark">
                  {organization.description}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      )}
      {validators.length > 0 && (
        <Container fluid className="py-5 bg-white our-validators">
          <Row className="align-items-center justify-content-center">
            <h2 className="title text-center mb-5">
              <span className="fw-medium">Our</span> Validators
            </h2>
            <Col xs={11} sm={11} md={10} lg={10} xl={10} xxl={10}>
              <Swiper
                loop={true}
                centerInsufficientSlides={true}
                slidesPerView={"auto"}
                spaceBetween={20}
                on={{
                  init: (swiper) => {
                    validatorSwiperRef.current = swiper;
                  },
                }}
              >
                {validators.map((validator) => (
                  // Map over the 'validators' array to render each validator as a SwiperSlide
                  <SwiperSlide key={validator.referenceId} gridClass="swiper-slide-grid">
                    {/* Render ValidatorCard component with validator data */}
                    <ValidatorCard validator={validator} isEllipsisReady={isEllipsisReady} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Col>
            <div
              className={`align-items-center justify-content-center ${
                validators.length > 0 ? "d-flex" : "d-none"
              }`}
            >
              <div
                className="swiper-button-prev border-secondary text-secondary"
                onClick={goToPreviousSlideForValidator}
              >
                <i className="flaticon-line-arrow-left fs-4"></i>
              </div>
              <Link
                to={`/validators/list/${referenceId}`}
                className="btn btn-secondary btn-md mx-3 rounded-0"
              >
                View All
              </Link>
              <div
                className="swiper-button-next border-secondary text-secondary"
                onClick={goToNextSlideForValidator}
              >
                <i className="flaticon-line-arrow-right fs-4"></i>
              </div>
            </div>
          </Row>
        </Container>
      )}
      {needs.length > 0 && (
        <Container fluid className="py-5">
          <Row className="align-items-center justify-content-center">
            <h2 className="title text-center mb-5">
              <span className="fw-medium">Needs</span> Validated
            </h2>
            <Col xs={11} sm={11} md={10} lg={10} xl={10} xxl={10}>
              <Swiper
                loop={true}
                centerInsufficientSlides={true}
                slidesPerView={"auto"}
                spaceBetween={20}
                on={{
                  init: (swiper) => {
                    needSwiperRef.current = swiper;
                  },
                }}
              >
                {needs.map((need) => (
                  // Map over the 'needs' array to render each need as a SwiperSlide
                  <SwiperSlide key={need.referenceId} gridClass="swiper-slide-grid">
                    {/* Render NeedCard component with need data */}
                    <NeedCard need={need} />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div
                className={`align-items-center justify-content-center ${
                  needs.length > 0 ? "d-flex" : "d-none"
                }`}
              >
                <div
                  className="swiper-button-prev border-secondary text-secondary"
                  onClick={goToPreviousSlideForNeed}
                >
                  <i className="flaticon-line-arrow-left fs-4"></i>
                </div>
                <Link
                  to={{
                    pathname: `/needs/list/${referenceId}`,
                    state: {
                      orgName: organization.name,
                      orgId: organization.id,
                    },
                  }}
                  className="btn btn-secondary btn-md mx-3 rounded-0"
                >
                  View All
                </Link>
                <div
                  className="swiper-button-next border-secondary text-secondary"
                  onClick={goToNextSlideForNeed}
                >
                  <i className="flaticon-line-arrow-right fs-4"></i>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}

      {isMounted && Object.keys(organization).length === 0 && (
        <Container>
          <Row className="justify-content-center text-center">
            <Col xs={10} sm={12} md={9} lg={7} xl={6} xxl={5}>
              <Card className="border-0 shadow p-4 rounded-4 text-center">
                <Card.Body>
                  <Image src={noData} alt="Invalid Profile" fluid />
                  <h4 className="title mt-3">Invalid Profile</h4>
                  <p className="mb-0 fw-light text-dark-emphasis">
                    It looks like the profile you are trying to reach is not
                    <br className="d-none d-sm-inline-block" />
                    active. If you believe you've reached this page in error,
                    <br className="d-none d-sm-inline-block" />
                    please contact the person who sent you the link.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}

      {/*  Determines whether to show the modal based on the loading states of profile, needs, and validators.*/}
      <Modal
        show={
          loadingStates["profile"] ||
          loadingStates["needs"] ||
          loadingStates["validators"]
        }
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default OrganizationProfile;
