import * as signalR from "@microsoft/signalr";

import { getData, postData } from "../services/apiService";
import { Job_APIURL } from "../constants";

/**
 * Creates and configures a SignalR connection.
 * @param {string} user - The user for whom the connection is created
 * @param {Function} handleNewFileExportUpdate - Callback function to handle new file export updates
 * @param {Function} onStatusUpdateCallback - Callback function to handle status updates
 * @param {Function} onProgressUpdateCallback - Callback function to handle progress updates
 * @returns {signalR.HubConnection} - Returns the SignalR connection object
 */
export const createSignalRConnection = (
  user,
  handleNewFileExportUpdate,
  onStatusUpdateCallback,
  onProgressUpdateCallback
) => {
  const connection = new signalR.HubConnectionBuilder()
    .configureLogging(signalR.LogLevel.Debug)
    .withUrl(`${Job_APIURL}/notificationHub`, {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets,
    })
    .build();

  // Set up event handlers
  connection.on("onNewFileExportUpdate", () => {
    // Handle the new file export update here or call a provided callback
    if (handleNewFileExportUpdate) {
      handleNewFileExportUpdate();
    }
  });

  connection.on("onStatusUpdate", (exportId, status) => {
    // Handle the status update here or call a provided callback
    if (onStatusUpdateCallback) {
      onStatusUpdateCallback(exportId, status);
    }
  });

  connection.on("onProgressUpdate", (exportId, percentageCompleted) => {
    // Handle the progress update here or call a provided callback
    if (onProgressUpdateCallback) {
      onProgressUpdateCallback(exportId, percentageCompleted);
    }
  });

  // Start the connection and join a group
  connection
    .start()
    .then(() => connection.invoke("addToGroup", user))
    .catch((err) => console.error("SignalR connection error:", err));

  return connection;
};

/**
 * Retrieves exported files for a user.
 * @param {string} userId - The ID of the user
 * @param {Function} setExportedFiles - Function to set the exported files
 */
export const getExportedFiles = async (
  userId,
  setExportedFiles  
) => {
  try {
    const response = await getData(`/job-api/export/get/${userId}`);
    setExportedFiles(response);
  } catch (error) {
  }
};

/**
 * Deletes an exported file.
 * @param {string} exportId - The ID of the exported file to delete
 * @param {Array<Object>} exportedFiles - The array of exported files
 * @param {Function} setExportedFiles - Function to set the exported files
 */
export const deleteExportedFile = async (
  exportId,
  exportedFiles,
  setExportedFiles
) => {
  try {
    await postData(`/job-api/export/delete/${exportId}`, null);
    setExportedFiles(
      exportedFiles.filter((item) => item.exportId !== exportId)
    );
  } catch (error) {
  }
};

/**
 * Updates the download status of an exported file and triggers download.
 * @param {string} userId - The ID of the user
 * @param {string} exportId - The ID of the exported file
 * @param {string} fileName - The name of the exported file
 */
export const updateFileDownloadStatus = async (
  userId,
  exportId,
  fileName
) => {
  try {
    const response = await postData(
      `/job-api/export/update-download-status/${exportId}`,
      null
    );
    if (response)
      window.location.href = `${Job_APIURL}/Export/${userId}/${fileName}.csv`;
  } catch (error) {
  }
};
