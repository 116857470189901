import React, { Fragment, useState, useEffect, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
  Alert,
  Spinner,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

import { transformProfilePicture } from "../../components/transformFileURL";

import { getData, postData } from "../../services/apiService";
import { notify, initialState } from "../../store/notification";
import { SITEURL } from "../../constants";

import logo from "../../resources/images/logo.svg";

/**
 * Component for handling validator enrollment requests.
 * Allows existing organizations in the system to request a validator account.
 * @returns {React.Element} - Returns JSX for validator enrollment.
 */
const ValidatorEnrollment = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getFieldState,
  } = useForm();
  const [fieldState, setFieldState] = useState(getFieldState("emailAddress"));

  const navigate = useNavigate();
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  const [loadingStates, setLoadingStates] = useState({});
  const [organizations, setOrganizations] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [isInputLoading, setIsInputLoading] = useState(false);

  /**
   * Function to handle the enrollment process for inviting a validator and
   * sends a confirmation email to verify the authenticity of the invitation.
   * @param {Object} data - The data containing validator invitation details.
   */
  const handleEnrollment = async (data) => {
    data.organizationId = data.organization.value;
    data.organizationName = data.organization.label;
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      isSubmitted: true,
    }));
    try {
      const response = await postData(
        "/api/invitation/request-validator-account",
        data,
        null
      );
      reset();
      showNotification("success", response, 5000);
      navigate("/partner/email-verification", {
        replace: true,
        state: { email: data.emailAddress, isValid: response?.isNewValidator },
      });
    } catch (error) {
      showNotification("danger", error, 5000);
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        isSubmitted: false,
      }));
    }
  };

  useEffect(() => {
    /**
     * Fetches the organizations from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const searchOrganization = async () => {
      setIsInputLoading(true);
      try {
        const response = await getData(
          `/api/organization/search?searchTerm=${searchTerm}`,
          null,
          null
        );
        setOrganizations(response);
        setIsInputLoading(false);
      } catch (error) {
        showNotification("danger", error, 5000);
        setOrganizations([]);
        setIsInputLoading(false);
      }
    };
    if (searchTerm) searchOrganization();
    else setOrganizations([]);
  }, [searchTerm]);

  /**
   * Function to update the search term state.
   * @param {string} newValue - The new value entered by the user in the search input.
   */
  const handleSearchInput = (newValue) => {
    setSearchTerm(newValue);
  };

  /**
   * Custom filter function for filtering options in the organization dropdown.
   * @param {Object} option - The option to be filtered.
   * @param {string} searchText - The text to search for.
   * @returns {boolean} - Indicates whether the option matches the search text.
   */
  const customFilterOption = (option, searchText) => {
    return option.data.label.toLowerCase().includes(searchText.toLowerCase());
  };

  /**
   * Updates the field state with the latest state of the 'emailAddress' field
   * whenever the 'getFieldState' function changes.
   * This effect ensures that the field state stays synchronized with the form state.
   */
  useEffect(() => {
    setFieldState(getFieldState("emailAddress"));
  }, [getFieldState]);

  /**
   * Function to check the availability of the provided email address using the backend API
   * @param {string} value - The email address to check availability for.
   * @returns {boolean|Error} - Returns true if the email is available, or an Error object if the email is not available or an error occurs.
   */
  const handleEmailAvailability = async (value) => {
    if (!fieldState.invalid) {
      try {
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          isValidEmail: true,
        }));
        await postData(
          `/api/invitation/verify-email-availability/${value}`,
          null,
          null
        );
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          isValidEmail: false,
        }));
        return true;
      } catch (error) {       
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          isValidEmail: false,
        }));
        return error;
      }
    }
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main className="d-flex align-items-center justify-content-center min-vh-100 flex-column">
      <Container>
        <Row className="justify-content-center my-4">
          <Col xs={11} sm={11} md={9} lg={7} xl={6} xxl={5}>
            <div className="text-center mb-4">
              <Link to={SITEURL} title="Benevolent">
                <Image src={logo} alt="Benevolent" className="logo" fluid />
              </Link>
              <h3 className="title mt-4 mb-3">Request your account</h3>
              <p className="text-dark-emphasis mb-1">
                My coworker is a validator and I'd like to start posting needs,
                too.
              </p>
            </div>
            <Card className="border-0 shadow rounded-4 p-4">
              <Card.Body>
                <Form onSubmit={handleSubmit(handleEnrollment)}>
                  <Row>
                    <Col className="mb-4">
                      <InputGroup>
                        <FloatingLabel
                          label="Firstname"
                          controlId="txtFirstname"
                          htmlFor="txtFirstname"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Firstname"
                            {...register("firstName", {
                              required: "First name is required",
                              pattern: {
                                value:
                                  /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                message:
                                  "First name shouldn't contain HTML or script tags",
                              },
                              maxLength: {
                                value: 50,
                                message:
                                  "First name cannot exceed 50 characters",
                              },
                            })}
                          />
                        </FloatingLabel>
                        <InputGroup.Text>
                          <i className="flaticon-profile fs-4"></i>
                        </InputGroup.Text>
                      </InputGroup>
                      {errors.firstName && (
                        <Form.Text className="text-danger">
                          {errors.firstName.message}
                        </Form.Text>
                      )}
                    </Col>
                    <Col className="mb-4">
                      <InputGroup>
                        <FloatingLabel
                          label="Lastname"
                          controlId="txtLastname"
                          htmlFor="txtLastname"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Lastname"
                            {...register("lastName", {
                              required: "Last name is required",
                              pattern: {
                                value:
                                  /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                message:
                                  "Last name shouldn't contain HTML or script tags",
                              },
                              maxLength: {
                                value: 50,
                                message:
                                  "Last name cannot exceed 50 characters",
                              },
                            })}
                          />
                        </FloatingLabel>
                        <InputGroup.Text>
                          <i className="flaticon-profile fs-4"></i>
                        </InputGroup.Text>
                      </InputGroup>
                      {errors.lastName && (
                        <Form.Text className="text-danger">
                          {errors.lastName.message}
                        </Form.Text>
                      )}
                    </Col>
                    <Col xs={12} className="mb-4">
                      <InputGroup>
                        <FloatingLabel
                          label="Email"
                          controlId="txtEmail"
                          htmlFor="txtEmail"
                        >
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            {...register("emailAddress", {
                              required: "Email is required",
                              maxLength: 256,
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address",
                              },
                              validate: handleEmailAvailability,
                            })}
                          />
                        </FloatingLabel>
                        {loadingStates["isValidEmail"] ? (
                          <InputGroup.Text>
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          </InputGroup.Text>
                        ) : (
                          <InputGroup.Text>
                            <i className="flaticon-mail fs-4"></i>
                          </InputGroup.Text>
                        )}
                      </InputGroup>
                      {errors.emailAddress && (
                        <Form.Text className="text-danger">
                          {errors.emailAddress.message}
                        </Form.Text>
                      )}
                    </Col>
                    {/* <Col xs={12} className="mb-4">
                      <InputGroup>
                        <FloatingLabel
                          label="Job Title"
                          controlId="txtJobTitle"
                          htmlFor="txtJobTitle"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Job Title"
                            {...register("jobTitle", {
                              required: "Job title is required",
                              pattern: {
                                value:
                                  /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                message:
                                  "Job title shouldn't contain HTML or script tags",
                              },
                              maxLength: {
                                value: 100,
                                message:
                                  "Job title cannot exceed 50 characters",
                              },
                            })}
                          />
                        </FloatingLabel>
                        <InputGroup.Text>
                          <i className="flaticon-suitcase fs-4"></i>
                        </InputGroup.Text>
                      </InputGroup>
                      {errors.jobTitle && (
                        <Form.Text className="text-danger">
                          {errors.jobTitle.message}
                        </Form.Text>
                      )}
                    </Col> */}
                    <Col xs={12} className="mb-4">
                      <Controller
                        control={control}
                        name="organization"
                        id="ddOrg"
                        rules={{ required: "Organization is required" }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            // menuIsOpen
                            isSearchable={true}
                            isClearable={true}
                            isLoading={isInputLoading}
                            placeholder="Organization"
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onInputChange={handleSearchInput}
                            options={organizations}
                            value={organizations.find((c) => c.value === value)}
                            onChange={(val) => onChange(val || "")}
                            noOptionsMessage={() => "No results found"}
                            filterOption={customFilterOption}
                            getOptionLabel={(e) => (
                              <Fragment>
                                <Image
                                  src={transformProfilePicture(
                                    e.profilePicture
                                  )}
                                  alt={e.label}
                                  roundedCircle
                                  fluid
                                  width="35"
                                  className="me-2 align-middle"
                                />
                                <span>{e.label}</span>
                              </Fragment>
                            )}
                          />
                        )}
                      />

                      {errors.organization && (
                        <Form.Text className="text-danger">
                          {errors.organization.message}
                        </Form.Text>
                      )}
                    </Col>
                    <Col className="d-grid mt-4">
                      <Button
                        type="submit"
                        variant="primary"
                        size="lg"
                        className="w-100"
                        disabled={loadingStates["isSubmitted"]}
                      >
                        {loadingStates["isSubmitted"] ? (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          "SUBMIT"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
            <div className="text-center mt-4 text-dark-emphasis">
              Already Registered?{" "}
              <Link
                to="/"
                title="Sign In"
                className="text-decoration-none link-secondary fw-semibold"
              >
                Sign In
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
    </main>
  );
};

export default ValidatorEnrollment;
