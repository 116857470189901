import { useState } from "react";
import { jwtDecode } from "jwt-decode";

/**
 * This custom hook simplifies the process of handling state stored in the browser's local storage
 * within a React application. It provides a convenient way to persist and access data across sessions
 * while ensuring that the stored data remains in sync with the component's state.
 *
 * With useLocalStorage, you can effortlessly store and retrieve data from the local storage,
 * making it ideal for scenarios where you need to maintain user preferences, authentication tokens,
 * or any other persistent data.It's reusable hook
 *
 * @param {string} key - The key under which the value is stored in local storage
 * @param {any} defaultValue - The default value to use if the key is not found in local storage
 * @returns {Array} Returns an array containing the stored value and a function to update it
 */
export const useLocalStorage = (key, defaultValue) => {
  // Initialize state with the value from local storage or the default value
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(key);
      // Parse and return the value if it exists, otherwise store the default value
      if (value) return JSON.parse(value);
      else {
        window.localStorage.setItem(key, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  // Function to update the stored value in local storage
  const setValue = (newValue) => {
    try {
      // Store the new value in local storage
      window.localStorage.setItem(key, JSON.stringify(newValue));
    } catch (err) {}
    // Update the stored value in state
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
};

/**
 * Utility function to decode a JSON Web Token (JWT)
 * @param {string} token - The JWT to decode
 * @returns {Object|null} Returns the decoded JWT payload or null if decoding fails
 */
export const decodeToken = (token) => {
  try {
    return jwtDecode(token);
  } catch (e) {
    return null;
  }
};
