import React, { useEffect, useRef } from "react";
import "rc-tooltip/assets/bootstrap.css";
import Slider from "rc-slider";
import raf from "rc-util/lib/raf";
import Tooltip from "rc-tooltip";

/**
 * Component to handle tooltip rendering for a Slider component.
 * @param {Object} props - The props object containing tooltip-related data.
 * @param {number} props.value - The value to be displayed in the tooltip.
 * @param {ReactNode} props.children - The child elements to be wrapped by the tooltip.
 * @param {boolean} props.visible - Flag indicating whether the tooltip is visible.
 * @param {Function} [props.tipFormatter=(val) => `${val} %`] - Function to format the tooltip content.
 * @param {Object} restProps - Additional props to be passed to the Tooltip component.
 * @returns {React.Element} - Returns JSX for the HandleTooltip component.
 */
const HandleTooltip = (props) => {
  const {
    value,
    children,
    visible,
    tipFormatter = (val) => `${val} %`,
    ...restProps
  } = props;

  const tooltipRef = useRef(null);
  const rafRef = useRef(null);

  function cancelKeepAlign() {
    raf.cancel(rafRef.current);
  }

  function keepAlign() {
    rafRef.current = raf(() => {
      tooltipRef.current?.forceAlign();
    });
  }

  useEffect(() => {
    if (visible) {
      keepAlign();
    } else {
      cancelKeepAlign();
    }

    return cancelKeepAlign;
  }, [value, visible]);

  return (
    <Tooltip
      placement="top"
      overlay={tipFormatter(value)}
      overlayInnerStyle={{ minHeight: "auto" }}
      ref={tooltipRef}
      visible={visible}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};

/**
 * Function to handle rendering for a Slider component with tooltip.
 * @param {ReactNode} node - The node to be wrapped by the HandleTooltip component.
 * @param {Object} props - The props object containing tooltip-related data.
 * @returns {React.Element} - Return JSX for the HandleTooltip component.
 */
export const handleRender = (node, props) => {
  return (
    <HandleTooltip value={props.value} visible={props.dragging}>
      {node}
    </HandleTooltip>
  );
};

/**
 * Component to render a Slider component with tooltip.
 * @param {Object} props - The props object containing Slider-related data.
 * @param {Function} [props.tipFormatter] - Function to format the tooltip content.
 * @param {Object} [props.tipProps] - Additional props to be passed to the HandleTooltip component.
 * @returns {React.Element} - Returns JSX for the TooltipSlider component.
 */
export const TooltipSlider = ({ tipFormatter, tipProps, ...props }) => {
  const tipHandleRender = (node, handleProps) => {
    return (
      <HandleTooltip
        value={handleProps.value}
        // visible={handleProps.dragging}
        visible={true}
        tipFormatter={tipFormatter}
        {...tipProps}
      >
        {node}
      </HandleTooltip>
    );
  };

  return <Slider {...props} handleRender={tipHandleRender} />;
};
