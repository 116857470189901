import React, { useState, useEffect, useReducer, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  FloatingLabel,
  InputGroup,
  Button,
  Spinner,
  Alert,
  Card,
  Table,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import key from "weak-key";
import { CKEditor } from "ckeditor4-react";
import { convert } from "html-to-text";

import { putData, getData } from "../../services/apiService";
import { useAuthContext } from "../../context/authProvider";
import { notify, initialState } from "../../store/notification";
import { toolbar, styles } from "../../components/editor";

/**
 * Component for managing email templates.
 * This component allows users to view a list of predefined email templates, edit them, and save them to create new ones.
 * @returns {React.Element} - Returns JSX for managing email templates.
 * @access Accessible by SuperAdmin.
 */
const ManageTemplate = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  // Reference for accessing the editor component
  const editorRef = useRef();
  // State for managing loading states of different buttons
  const [loadingStates, setLoadingStates] = useState({});
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [dataTags, setDataTags] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(0);

  /**
   * Function to handle the initialization of the CKEditor instance.
   * @param {Object} editor - The CKEditor instance.
   */
  const handleEditorInit = (editor) => {
    editorRef.current = editor;
  };

  /**
   * Function to handle the event when a template is selected from the dropdown.
   * Fetches the template details and updates the form fields accordingly.
   * @param {Event} event - The event object representing the selection change in the dropdown.
   */
  const getById = async (event) => {
    setSelectedTemplate(event.target.value ?? 0);
    if (!event.target.value) {
      resetForm();
      return;
    }
    setIsPageLoading(true);
    try {
      const response = await getData(
        `/api/emailTemplate/getById/${event.target.value}`,
        null,
        userSession
      );
      setDataTags(response.dataTags);
      // Access CKEditor instance and set content
      const editorInstance = editorRef.current.editor;
      editorInstance.setData(response.htmlBody);
      reset(response);
      setIsPageLoading(false);
    } catch (error) {
      setIsPageLoading(false);
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
    }
  };

  /**
   * Function to handle the update of an email template with the provided data.
   * @param {Object} data - The data object containing the details of the email template to be updated.
   */
  const handleUpdateTemplate = async (data) => {
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      updateTemplate: true,
    }));

    // Convert HTML body to text format
    data.textBody = convert(data.htmlBody, {
      selectors: [
        { selector: "img", format: "skip" },
        { selector: "a", options: { ignoreHref: true } },
      ],
    });
    try {
      const response = await putData(
        `/api/emailTemplate/update`,
        data,
        userSession
      );
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        updateTemplate: false,
      }));
      updateTemplates(data.emailTemplateId, response.emailTemplateId);
      resetForm();
      showNotification("success", response.message, 5000);
    } catch (error) {
      // Show a notification if an error occurs any
      showNotification("danger", error, 5000);
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        updateTemplate: false,
      }));
    }
  };

  /**
   * Function to update the templates list with a new template ID.
   * @param {number} existingId - The ID of the existing template.
   * @param {number} newId - The ID of the new template.
   */
  const updateTemplates = (existingId, newId) => {
    // Map through the templates list and update the ID of the matching template
    const updatedTemplates = templates.map((item) =>
      item.emailTemplateId === existingId
        ? { ...item, emailTemplateId: newId }
        : item
    );
    setTemplates(updatedTemplates);
  };

  /**
   * Function to send a trial email to the currently logged-in user
   * using the selected email template.
   */
  const sendTrialEmail = async () => {
    if (selectedTemplate === 0) return;
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      sendTrialEmail: true,
    }));
    try {
      const response = await putData(
        `/api/emailTemplate/send-trial-email/${selectedTemplate}`,
        null,
        userSession
      );
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        sendTrialEmail: false,
      }));
      showNotification("success", response, 5000);
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        sendTrialEmail: false,
      }));
    }
  };

  /**
   * Function to reset the form to its initial state.
   */
  const resetForm = () => {
    setDataTags([]);
    setSelectedTemplate(0);
    // Reset CKEditor content to default
    editorRef.current.editor.setData("<table></table>", function () {
      this.updateElement();
    });
    reset({
      emailTemplateId: "",
      name: "",
      subject: "",
      htmlBody: "",
    });
  };

  useEffect(() => {
    /**
     * Fetches email templates from the backend API.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getAll = async () => {
      setIsPageLoading(true);
      try {
        const response = await getData(
          `/api/emailTemplate/getEditable`,
          null,
          userSession
        );
        setTemplates(response.templates);
        setIsPageLoading(false);
      } catch (error) {
        // Show a notification if an error occurs
        showNotification("danger", error, 5000);
        setTemplates([]);
        setIsPageLoading(false);
      }
    };

    getAll();

    // Return a cleanup function
    return () => {};
  }, [userSession]);

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-semibold mb-0">Email Template</h6>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <Row className="align-items-center">
              <Col>
                <Form.Text>
                  You can customize your emails using the tag on the right.
                </Form.Text>
              </Col>
              <Col xs={12} sm={6} lg={4} xl={3}>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="flaticon-template fs-6 text-secondary"></i>
                  </InputGroup.Text>
                  <Form.Select size="sm" onChange={getById}>
                    <option value="">Select Template</option>
                    {templates.map((item) => (
                      <option key={key(item)} value={item.emailTemplateId}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
              </Col>
            </Row>
            <hr className="my-4" />
            <Form onSubmit={handleSubmit(handleUpdateTemplate)}>
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Control
                    type="hidden"
                    {...register("emailTemplateId")}
                  />
                  <div className="mb-4">
                    <FloatingLabel controlId="txtName" label="Template Name">
                      <Form.Control
                        type="text"
                        placeholder="Template Name"
                        disabled={true}
                        {...register("name", {
                          required: "Template name is required",
                          pattern: {
                            value:
                              /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                            message:
                              "Template name shouldn't contain HTML or Script tags",
                          },
                          maxLength: {
                            value: 100,
                            message:
                              "Template name cannot exceed 100 characters",
                          },
                        })}
                      />
                    </FloatingLabel>
                    {errors.name && (
                      <Form.Text className="text-danger">
                        {errors.name.message}
                      </Form.Text>
                    )}
                  </div>
                  <div className="mb-4">
                    <FloatingLabel controlId="txtSubject" label="Subject">
                      <Form.Control
                        as="textarea"
                        className="scroll"
                        placeholder="Subject"
                        {...register("subject", {
                          required: "Subject is required",
                          pattern: {
                            value:
                              /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                            message:
                              "Subject shouldn't contain HTML or Script tags",
                          },
                          maxLength: {
                            value: 225,
                            message: "Subject cannot exceed 225 characters",
                          },
                        })}
                      />
                    </FloatingLabel>
                    {errors.subject && (
                      <Form.Text className="text-danger">
                        {errors.subject.message}
                      </Form.Text>
                    )}
                  </div>
                  <p className="text-dark-emphasis">MESSAGE</p>
                  <div className="mb-4">
                    <Controller
                      name="htmlBody"
                      control={control}
                      rules={{
                        required: "Message is required",
                      }}
                      render={({ field: { onChange, value }, fieldState }) => (
                        <div>
                          {/* CKEditor component for rich text editing */}
                          <CKEditor
                            onChange={(event, editor) => {
                              onChange(event.editor.getData());
                            }}
                            onInstanceReady={handleEditorInit}
                            // CKEditor configuration options
                            config={{
                              versionCheck: false,
                              toolbar: toolbar,
                              extraPlugins:
                                "justify,font,colorbutton,colordialog",
                              stylesSet: styles,
                            }}
                            type="classic"
                          />
                          {fieldState.error && (
                            <Form.Text className="text-danger">
                              {fieldState.error.message}
                            </Form.Text>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div>
                    <Button
                      variant="primary"
                      disabled={loadingStates["updateTemplate"]}
                      type="submit"
                      className="btn-md mb-2"
                    >
                      {loadingStates["updateTemplate"] ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        "Save"
                      )}
                    </Button>
                    <Button
                      variant="outline-secondary"
                      disabled={loadingStates["sendTrialEmail"]}
                      type="button"
                      className="ms-2 btn-md mb-2"
                      onClick={sendTrialEmail}
                    >
                      {loadingStates["sendTrialEmail"] ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        "Send Test Email"
                      )}
                    </Button>
                    <Button
                      variant="secondary"
                      type="button"
                      className="ms-2 btn-md mb-2"
                      onClick={resetForm}
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
                <Col xs={12} lg={6}>
                  <Form.Group
                    as={Form.Fieldset}
                    className="position-relative mt-5 mt-lg-3 border rounded p-4 data-tags"
                  >
                    <Form.Label
                      as="h6"
                      className="p-2 position-absolute bg-light"
                    >
                      Data Tag
                    </Form.Label>
                    <p className="fs-small mt-2">
                      Using the Following tags to insert customized text into
                      your messages.
                    </p>
                    <Table responsive borderless>
                      <thead>
                        <tr>
                          <th>Tag</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataTags?.map((item) => (
                          <tr key={key(item)}>
                            <td>{item.tag}</td>
                            <td>{item.description}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>

      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default ManageTemplate;
