import React, { useState, useEffect, useRef } from "react";
import { Dropdown, Card, Image, Spinner } from "react-bootstrap";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import { getData } from "../../../services/apiService";
import NoData from "../../../resources/images/no-data.png";

/**
 * Component for displaying donation analytics.
 * This component renders donation analytics based on different time periods like
 * Today, Yesterday, Weekly, Last Month, and Last Yearly using a line chart.
 * @param {Object} props - Props object containing data required for rendering.
 * @returns {React.ReactElement} - JSX for displaying donation analytics with line chart.
 * @access Accessible by SuperAdmin and Staff.
 */
const DonationAnalytics = (props) => {
  // Use the chartRef to hold a reference to the chart element
  const chartRef = useRef(null);
  const [metrics, setMetrics] = useState([]);
  const [sortBy, setSortBy] = useState("Today");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    /**
     * Function to fetch the donation metrics from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getDonationMetrics = async () => {
      setIsLoading(true);
      try {
        const response = await getData(
          `/api/dashboard/getDonationMetrics/${sortBy}`,
          null,
          props.userSession
        );
        setMetrics(response);
        setIsLoading(false);
      } catch (error) {
        setMetrics([]);
        setIsLoading(false);
      }
    };
    getDonationMetrics();
  }, [props, sortBy]);

  // Configuration options for the chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text:
            sortBy === "Today" || sortBy === "Yesterday"
              ? "Hours of Day"
              : sortBy === "Weekly"
              ? "Week Days"
              : sortBy === "Last Month"
              ? "Month"
              : "Year",
        },
      },
      y: {
        title: {
          display: true,
          text: "Donations",
        },
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return "$" + value;
          },
        },
      },
    },
  };

  // Data for the chart
  const data = {
    labels: metrics.map((item) => item.label),
    datasets: [
      {
        label: "Donations",
        data: metrics.map((item) => item.value),
        borderColor: "#fadba4",
        backgroundColor: "#fadba4",
        borderWidth: 2,
      },
    ],
  };

  useEffect(() => {
    /**
     * Function to set up a listener for handling chart
     * responsiveness when the window resize.
     */
    const handleResize = () => {
      const chart = chartRef.current;
      if (chart) {
        setTimeout(() => {
          chart.resize();
        }, 1);
      }
    };
    window.addEventListener("resize", handleResize);
    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Card className="border-0 rounded-3 my-2">
      <Card.Body className="p-4 recent-submissions">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h6 className="fw-semibold mb-0">Donation Analytics</h6>
          <div>
            <Dropdown drop="down" className="more-menu">
              <Dropdown.Toggle variant="light" className="bg-transparent">
                <span>{sortBy}</span>
                <i className="flaticon-down fs-5 ps-1 lh-sm align-bottom"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setSortBy("Today")}>
                  Today
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setSortBy("Yesterday")}>
                  Yesterday
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setSortBy("Weekly")}>
                  Last 7 Days
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setSortBy("Last Month")}>
                  Last Month
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setSortBy("Last Year")}>
                  Last Year
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {isLoading && (
          <div className="text-center">
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        {metrics.length === 0 && !isLoading && (
          <div className="text-center text-dark-emphasis">
            <Image src={NoData} alt="No Data Found" fluid />
            <p className="mb-0 fw-semibold">No Data Found</p>
          </div>
        )}
        {metrics.length > 0 && !isLoading && (
          <div className="text-center text-dark-emphasis">
            <Line ref={chartRef} options={options} data={data} />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
export default DonationAnalytics;
