/**
 * Function to import all avatars from a specified context.
 * @param {Object} context - The context to import avatars from
 * @returns {Array<Object>} Array containing information about each imported avatar
 */
const importAll = (context) => {
  const avatars = [];
  context.keys().forEach((key) => {
    const avatarName = key.replace("./", "");
    avatars.push({
      name: avatarName,
      image: context(key).default || context(key),
    });
  });
  return avatars;
};

/**
 * Array containing information about imported avatars.
 * @type {Array<Object>}
 */
export const Avatars = importAll(
  require.context("../resources/images/avatar", false, /\.png$/)
);
