import React, { useState, useEffect, Fragment } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  Image,
  Dropdown,
  Button,
  ProgressBar,
  ListGroup,
  Badge,
} from "react-bootstrap";
import key from "weak-key";

import {
  createSignalRConnection,
  getExportedFiles,
  deleteExportedFile,
  updateFileDownloadStatus,
} from "../components/exportUtils";
import NeedsChosen from "../components/needsChosen";
import { transformProfilePicture } from "../components/transformFileURL";

import { PrimaryRoutes } from "../routes/privateRoute";
import { useAuthContext } from "../context/authProvider";
import { decodeToken } from "../utils/session";
import useTokenExpiration from "../utils/tokenExpiration";
import TokenExpirationPopup from "./_tokenExpirationPopup";
import LogoutConfirmationPopup from "./_logoutConfirmationPopup";
import { Host, SITEURL } from "../constants";
import "../resources/scss/home-layout.scss";
import logo from "../resources/images/logo.svg";

/**
 * Component for rendering the private layout with the content provided by the routing outlet
 * @returns {React.Element} - Returns JSX for the private layout.
 * @access Accessible by authenticated users
 */
const PrivateLayout = () => {
  /**
   * Destructuring assignment to extract necessary variables and functions from the useAuthContext hook.
   * - saveToken: Function to save the JWT token in the client's browser using local storage.
   * - clearToken: Function to clear the JWT token from the client's browser.
   * - userSession: Session object containing user authentication information.
   * - setReturnURL: Function to set the return URL for redirection after authentication.
   */
  const { saveToken, clearToken, userSession, setReturnURL } = useAuthContext();
  // Decode the user session token to extract user information
  const user = decodeToken(userSession);
  const profilePicture = transformProfilePicture(user.ProfilePicture);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [exportedFiles, setExportedFiles] = useState([]);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  /**
   * Function to display a token expiration popup with a TokenExpirationPopup component.
   */
  const showTokenExpirationPopup = () => {
    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        setReturnURL(location.pathname);
        return (
          <TokenExpirationPopup
            onClose={onClose}
            onSaveToken={saveToken}
            onClearToken={clearToken}
            session={userSession}
          />
        );
      },
    });
  };

  // Call the useTokenExpiration hook, passing the user token expiration time and
  // the showTokenExpirationPopup function
  useTokenExpiration(user, showTokenExpirationPopup);

  /**
   * Function to handle user logout by displaying a confirmation popup.
   * It passes the following props to the component:
   * - onClose: Function to close the confirmation popup.
   * - onClearToken: Function to clear the JWT token from the client's browser.
   * - roles: User roles used to determine logout behavior based on role.
   * - code: Access code used to logout the recipient.
   */
  const handleLogout = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <LogoutConfirmationPopup
            onClose={onClose}
            onClearToken={clearToken}
          />
        );
      },
    });
  };

  /**
   * useEffect hook to handle window resize events.
   * The menu is automatically closed on medium-sized devices (window width < 992 pixels).
   */
  useEffect(() => {
    const handleResize = () => {
      const isMediumDevice = window.innerWidth < 992;
      // Updates the state of isMenuOpen based on the window width.
      setIsMenuOpen(!isMediumDevice);
    };

    // Add event listener for resize events
    window.addEventListener("resize", handleResize);
    // Call handleResize once on component mount
    handleResize();

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Fetch the exported file
    getExportedFiles(user.nameid, setExportedFiles);

  /**
   * Function to retrieve exported files whenever a new export occurs
   */
    const handleNewFileExportUpdate = () => {
      getExportedFiles(user.nameid, setExportedFiles);
    };

    // Create the SignalR connection when the component mounts
    const connection = createSignalRConnection(
      user.nameid,
      handleNewFileExportUpdate,
      handleStatusUpdate,
      handleProgressUpdate
    );

    // Clean up the connection when the component unmounts
    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, [user.nameid]);



  /**
   * Function to handle status updates for an exported file.
   * @param {string} exportId - The unique identifier of the exported file.
   * @param {string} status - The new status of the file.
   */
  const handleStatusUpdate = (exportId, status) => {
    setExportedFiles((prevExportedFiles) => {
      const updatedFiles = [...prevExportedFiles];
      const fileToUpdate = updatedFiles.find(
        (file) => file.exportId === exportId
      );

      if (fileToUpdate) fileToUpdate.status = status;

      return updatedFiles;
    });
  };

  /**
   * Function to handle progress updates for an exported file.
   * @param {string} exportId - The unique identifier of the exported file.
   * @param {number} percentageCompleted - The percentage of completion for the export process.
   */
  const handleProgressUpdate = (exportId, percentageCompleted) => {
    setExportedFiles((prevExportedFiles) => {
      const updatedFiles = [...prevExportedFiles];
      const fileToUpdate = updatedFiles.find(
        (file) => file.exportId === exportId
      );

      if (fileToUpdate) fileToUpdate.percentageCompleted = percentageCompleted;

      return updatedFiles;
    });
  };

  return (
    <div className="private-layout">
      {/* Menu */}
      <aside className={`push-menu ${isMenuOpen ? "open" : ""}`}>
        <Link
          to={SITEURL}
          title="Benevolent"
          className="mt-3 mb-4 px-3 d-block"
        >
          <Image src={logo} alt="Benevolent" className="logo" fluid />
        </Link>
        <Nav className="flex-column">
          {PrimaryRoutes[0].children.map(
            (route) =>
              route.roles?.find((role) => user.roles.includes(role)) && (
                <Nav.Item key={key(route)}>
                  <Link to={route.path} className="nav-link">
                    <i className={route.icon}></i>
                    {route.name}
                  </Link>
                </Nav.Item>
              )
          )}
        </Nav>
      </aside>
      {/* Header */}
      <Navbar
        fixed="top"
        className={`header border-bottom ${isMenuOpen ? "pushed" : ""}`}
      >
        <Container fluid>
          <Button
            variant="link"
            onClick={toggleMenu}
            className={`menu-toggle ${isMenuOpen ? "open" : ""}`}
          >
            <span></span>
          </Button>
          <div className="d-flex ms-auto align-items-center">
            <Link
              to={SITEURL}
              target="_blank"
              title="Explore Site"
              className="link-primary fw-light fs-small me-2"
            >
              Explore Site
            </Link>
            <NeedsChosen userId={user?.nameid ?? ""} />
            {exportedFiles.length > 0 && (
              <Dropdown className="export">
                <Dropdown.Toggle size="sm" variant="link">
                  <span id="group">
                    <i className="flaticon-notification fs-4"></i>
                    <span className="count bg-secondary text-white">
                      {exportedFiles.length}
                    </span>
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="p-0 shadow scroll">
                  <ListGroup as="ul" className="position-relative">
                    {exportedFiles.map((item, index) => (
                      <ListGroup.Item
                        key={index}
                        as="li"
                        className="p-3 bg-white text-dark-emphasis"
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h6 className="mb-1 fw-light">
                              {" "}
                              {item.fileName.split("_")[0]}
                            </h6>
                            {item.status !== "Processing" && (
                              <Badge
                                bg={`${item.status === "Completed"
                                    ? "success"
                                    : "danger"
                                  }`}
                                className="fw-light text-dark"
                              >
                                {item.status.toUpperCase()}
                              </Badge>
                            )}
                          </div>
                          <div>
                            <Button
                              variant="link"
                              title="Download"
                              size="sm"
                              className={`${item.status !== "Completed" && item.status !== "No Data" ? "d-none" : ""
                                }`}
                              onClick={() =>
                                updateFileDownloadStatus(
                                  user.nameid,
                                  item.exportId,
                                  item.fileName
                                )
                              }
                            >
                              <i className="flaticon-downloads fs-5"></i>
                            </Button>
                            {item.status !== "Processing" && (
                              <Button
                                variant="link"
                                title="Delete"
                                size="sm"
                                onClick={() =>
                                  deleteExportedFile(
                                    item.exportId,
                                    exportedFiles,
                                    setExportedFiles
                                  )
                                }
                              >
                                <i className="flaticon-close fs-small"></i>
                              </Button>
                            )}
                          </div>
                        </div>
                        {item.status === "Processing" && (
                          <Fragment>
                            <ProgressBar now={item.percentageCompleted} />
                            <small>
                              Data file generation in progress:{" "}
                              {item.percentageCompleted}%{" "}
                            </small>
                          </Fragment>
                        )}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Dropdown.Menu>
              </Dropdown>
            )}
            <Dropdown align={{ xs: "start" }}>
              <Dropdown.Toggle
                variant="link"
                size="sm"
                className="d-flex align-items-center text-start"
              >
                <div className="flex-shrink-0">
                  <Image
                    src={profilePicture}
                    alt="Benevolent"
                    roundedCircle
                    thumbnail
                    fluid
                    className="avatar"
                  />
                </div>
                <div className="flex-grow-1 ms-3 text-dark d-none d-md-block">
                  <span className="d-block text-dark-emphasis fw-light">
                    Signed in as
                  </span>
                  {user == null ? "Admin" : user.sub}
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item to={`/profile/user`} as={Link}>
                  <i className="flaticon-profile fs-4 me-2"></i>Profile
                </Dropdown.Item>
                <Dropdown.Item to="/update-profile" as={Link}>
                  <i className="flaticon-user-edit fs-4 me-2"></i>Update Profile
                </Dropdown.Item>
                {user.roles.includes("Donor") && (
                  <Dropdown.Item to="/payment-options" as={Link}>
                    <i className="flaticon-card fs-4 me-2"></i>Payment Options
                  </Dropdown.Item>
                )}
                <Dropdown.Item to="/giving-page/user" as={Link}>
                  <i className="flaticon-invoice fs-4 me-2"></i>Giving Pages
                </Dropdown.Item>
                <Dropdown.Item to="/change-password" as={Link}>
                  <i className="flaticon-password fs-4 me-1"></i>Change Password
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleLogout}>
                  <i className="flaticon-logout fs-4 me-1"></i>Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Container>
      </Navbar>
      {/* Component */}
      <Container fluid className={`wrapper ${isMenuOpen ? "pushed" : ""}`}>
        <Outlet />
      </Container>
      {/* Footer */}
      <footer className={`footer border-top ${isMenuOpen ? "pushed" : ""}`}>
        <Container fluid>
          <Row className="m-0 py-3">
            <Col xs={12} className="text-center">
              <span className="text-dark-emphasis fs-small">
                {new Date().getFullYear()} &copy;{" "}
                <a
                  href={Host}
                  title="Benevolent"
                  className="text-decoration-none link-secondary fw-semibold"
                >
                  Benevolent
                </a>{" "}
                All rights reserved.
              </span>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default PrivateLayout;
