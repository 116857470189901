import React from "react";
import { Card, Button, Image } from "react-bootstrap";
import { useCookies } from "react-cookie";

import { logout, logoutRecipient } from "../services/accountService";
import logoutConfirmation from "../resources/images/logout-confirmation.png";

/**
 * Component to display confirmation popup when attempting to logout of the platform.
 * @param {Object} props - Component props.
 * @param {Function} props.onClose - Function to handle closing the popup.
 * @param {Function} props.onClearToken - Function to clear user token upon logout.
 * @param {Array} props.roles - Array of user roles.
 * @param {string} props.code - Code associated with the recipient (if recipient role).
 * @returns {React.Element} - Returns JSX for the logout confirmation popup.
 */
const LogoutConfirmationPopup = ({ onClose, onClearToken, roles, code }) => {
  const [cookies, , removeCookie] = useCookies();
  return (
    <Card className="border-0 shadow rounded-3">
      <Card.Body className="p-4 text-center">
        <Image src={logoutConfirmation} alt="Logout Confirmation" fluid />
        <div className="mt-4 fw-light">
          You're attempting to logout of <br /> Benevolent. Are you sure?
        </div>
      </Card.Body>
      <Card.Footer className="bg-transparent py-4">
        <Button
          title="Cancel"
          variant="link"
          className="link-danger fw-semibold"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          className="ms-4 btn-md"
          onClick={async () => {
            try {
              if (roles?.includes("Recipient")) await logoutRecipient(code);
              else {
                await logout();
                const cookieNames = Object.keys(cookies);
                cookieNames
                  .filter((cookieName) => cookieName.startsWith("_benevolent"))
                  .forEach((cookieName) =>
                    removeCookie(cookieName, {
                      path: "/",
                      sameSite: "none",
                      secure: true,
                    })
                  );
              }
              await onClearToken();
            } catch (error) {
              console.log(error);
            }
            onClose();
          }}
        >
          Yes, Logout
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default LogoutConfirmationPopup;
