import { Navigate } from "react-router-dom";

import { useAuthContext } from "../context/authProvider";
import { decodeToken } from "../utils/session";

/**
 * Component for handling routes accessible to anonymous users based on authentication status and roles.
 * @param {Object} children - The content to be rendered for anonymous users.
 * @returns {React.Element} - Returns JSX element representing the content to be rendered for anonymous users.
 */
export const Anonymous = ({ children }) => {
  // Accessing user session from authentication context
  const { userSession } = useAuthContext();
  // Decoding user session token
  const user = decodeToken(userSession);

  // Checking if user is not authenticated
  if (user === undefined || user === null) {
    // user is not authenticated
    return children;
  }

  // Checking user roles and redirecting based on roles
  if (user.roles.includes("SuperAdmin")) return <Navigate to="/dashboard" />;
  if (user.roles.includes("Staff")) return <Navigate to="/dashboard" />;
  if (user.roles.includes("Validator")) return <Navigate to="/dashboard" />;
  // if (user.roles.includes("Donor")) return <Navigate to="/giving-history" />;
  if (user.roles.includes("Donor")) return <Navigate to="/dashboard" />;
  if (user.roles.includes("Recipient"))
    return <Navigate to={`/update-my-story/${user.needId}`} />;
};
