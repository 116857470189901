import React, { useState, useReducer } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  Spinner,
  Alert,
} from "react-bootstrap";
import { useForm } from "react-hook-form";

import {
  updatePassword,
  validateFormerPassword,
} from "../../services/accountService";
import { notify, initialState } from "../../store/notification";
import { useAuthContext } from "../../context/authProvider";

/**
 * Component for changing password.
 * This component provides functionality for users to change their passwords.
 * @returns {React.Element} - Returns JSX for changing password.
 */
const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    getFieldState,
    reset,
    formState: { errors },
  } = useForm();

  // Get state of password field
  const fieldState = getFieldState("password");
  // Watch the value of the input field with the name "newPassword"
  const password = watch("newPassword");
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' and 'clearToken' object from the 'useAuthContext()' hook
  const { clearToken, userSession } = useAuthContext();
  const [loadingStates, setLoadingStates] = useState({});

  /**
   * Function to handle the update of user password.
   * @param {Object} data - The data containing the new password.
   */
  const handleUpdatePassword = async (data) => {
    try {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        isSubmitted: true,
      }));
      await updatePassword(data, userSession);
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        isSubmitted: false,
      }));
      // Clear user token after password update
      await clearToken();
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        isSubmitted: false,
      }));
    }
  };

  /**
   * Function to validate the former password entered by the user.
   * @param {string} value - The former password entered by the user.
   * @returns {boolean|string} - True if the password is valid, otherwise an error message.
   */
  const handleValidateFormerPassword = async (value) => {
    // Check if password field is not invalid
    if (!fieldState.invalid) {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        isValidPwd: true,
      }));
      try {
        await validateFormerPassword(value, userSession);
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          isValidPwd: false,
        }));
        return true;
      } catch (error) {
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          isValidPwd: false,
        }));
        return error;
      }
    }
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main>
      <Container fluid>
        <h6 className="module-title fw-semibold mb-4">Change Password</h6>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <Form onSubmit={handleSubmit(handleUpdatePassword)}>
              <Row>
                <Col xs={12} sm={8} md={6} lg={6} xl={5} xxl={4}>
                  <Form.Group className="mb-4">
                    <InputGroup>
                      <Form.Floating>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          {...register("password", {
                            required: "Password is required",
                            pattern: {
                              value:
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@@$!%*?&])[A-Za-z\d$@@$!%*?&]{6,}/,
                              message:
                                "Password must contain at least 6 characters, including at least one uppercase letter, one lowercase letter, one special character, and one number.",
                            },
                            validate: handleValidateFormerPassword,
                          })}
                        />
                        <Form.Label>Password</Form.Label>
                      </Form.Floating>
                      {loadingStates["isValidPwd"] ? (
                        <InputGroup.Text>
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </InputGroup.Text>
                      ) : (
                        <InputGroup.Text>
                          <i className="flaticon-password fs-3"></i>
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                    {errors.password && (
                      <Form.Text className="text-danger">
                        {errors.password.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <InputGroup>
                      <Form.Floating>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          {...register("newPassword", {
                            required: "Password is required",
                            pattern: {
                              value:
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@@$!%*?&])[A-Za-z\d$@@$!%*?&]{6,}/,
                              message:
                                "Password must contain at least 6 characters, including at least one uppercase letter, one lowercase letter, one special character, and one number.",
                            },
                          })}
                        />
                        <Form.Label>New Password</Form.Label>
                      </Form.Floating>
                      <InputGroup.Text>
                        <i className="flaticon-password fs-3"></i>
                      </InputGroup.Text>
                    </InputGroup>
                    {errors.newPassword && (
                      <Form.Text className="text-danger">
                        {errors.newPassword.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-5">
                    <InputGroup>
                      <Form.Floating>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          {...register("confirmPassword", {
                            required: "Confirm Password is required",
                            validate: (value) =>
                              value === password || "Passwords do not match",
                          })}
                        />
                        <Form.Label>Re-type Password</Form.Label>
                      </Form.Floating>
                      <InputGroup.Text>
                        <i className="flaticon-password fs-3"></i>
                      </InputGroup.Text>
                    </InputGroup>
                    {errors.confirmPassword && (
                      <Form.Text className="text-danger">
                        {errors.confirmPassword.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Button
                    variant="secondary"
                    type="button"
                    className="me-3 btn-md"
                    onClick={() => reset()}
                  >
                    CANCEL
                  </Button>
                  <Button
                    variant="primary"
                    disabled={loadingStates["isSubmitted"]}
                    type="submit"
                    className="btn-md"
                  >
                    {loadingStates["isSubmitted"] ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "UPDATE"
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
      {/* Notification alert */}
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
    </main>
  );
};

export default ChangePassword;
