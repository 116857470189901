import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import AuthProvider from "./context/authProvider";
import { ErrorBoundary } from "react-error-boundary";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import InternalServerError from "./features/error/internalServerError";
import "./resources/fonts/flaticon/flaticon.scss";
import "./resources/scss/theme.scss";
import "./resources/scss/base.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Rendering the App component within the React root
root.render(
  <Router>
    {/* ErrorBoundary for catching errors and displaying a fallback component */}
    <ErrorBoundary FallbackComponent={InternalServerError}>
      {/* AuthProvider for providing authentication context to the App */}
      <AuthProvider>
        {/* Main App component */}
        <App />
      </AuthProvider>
    </ErrorBoundary>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
