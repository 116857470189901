import React, {
  useState,
  useEffect,
  useReducer,
  Fragment,
  useRef,
} from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
  Image,
  Alert,
  Modal,
  Spinner,
  Container,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import {
  isPossiblePhoneNumber,
  isSupportedCountry,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import key from "weak-key";
import { useCookies } from "react-cookie";

import TransactionCancellationConfirmation from "./transactionCancellationConfirmation";

import { getData, postData } from "../../services/apiService";
import {
  checkEmailValidity,
  refreshToken,
} from "../../services/accountService";
import { notify, initialState } from "../../store/notification";
import { useAuthContext } from "../../context/authProvider";
import { decodeToken } from "../../utils/session";

import noData from "../../resources/images/no-data.png";

/**
 * Component for collecting donor information
 * This component provides the form to gather donor information, including account details for
 * setting up an account, and tribute details for making donations in honor of someone.
 * @returns {React.Element} - Returns JSX for collecting donor information.
 */
const Request = () => {
  const navigate = useNavigate();
  // Get the current URL's query string
  const [searchParams] = new useSearchParams();
  // This reference is used to interact with a element in component.
  const pageRef = useRef();
  const {
    control,
    register,
    unregister,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    trigger,
    setValue,
    getFieldState,
  } = useForm();

  // Get the value of the password field for confirmation
  const password = watch("password");
  // Watch for changes in the phoneNumber field
  const phoneNumber = watch("address.phoneNumber");
  const fieldState = getFieldState("email");
  const { cartId } = useParams();
  const [cookies, , removeCookie] = useCookies();

  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession, saveToken, isLoggedIn } = useAuthContext();
  // Decode the user session token
  const user = decodeToken(userSession);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(!isLoggedIn);
  const [hasTribute, setHasTribute] = useState(false);
  const [cart, setCart] = useState({});
  const [selectedCountry, setSelectedCountry] = useState("United States");
  const [selectedCountryCode, setSelectedCountryCode] = useState("US");
  const [showTransactionConfirmation, setShowTransactionConfirmation] =
    useState(false);
  const [maxLength, setMaxLength] = useState(20);
  // To determine the origin of the iframe and whether it is a white-label integration.
  const [isWhiteLabel] = useState(window.self !== window.top);

  // frameContent refers to the children of the current pageRef,
  // which represents the content of the iframe.
  const frameContent = pageRef.current ? pageRef.current.children : null;

  /**
   * Function to apply styles to elements in the iframe based on search parameters.
   */
  const applyFrameStylesFromSearchParams = () => {
    Array.from(frameContent).forEach((content) => {
      const donorInfo = content.querySelector("div.donor-info");
      if (donorInfo) {
        const checkboxes = donorInfo.querySelectorAll("input.form-check-input");
        if (checkboxes.length > 0) {
          checkboxes.forEach((checkbox) => {
            if (checkbox.checked)
              applyColorProperty(checkbox, "BUTTON", "primaryColor");
          });
        }
      }

      const summary = content?.querySelector("div.summary");
      if (summary) {
        const buttons = summary.querySelectorAll(".btn-primary");
        if (buttons) {
          buttons.forEach((buttonStyle) => {
            applyColorProperty(buttonStyle, "BUTTON", "primaryColor");
          });
        }

        if (summary.classList.contains("bg-secondary"))
          applyColorProperty(summary, "BACKGROUND", "secondaryColor");
      }

      // Update style for the caution message
      const cautionMessage = content.querySelector("div.caution-message");
      if (cautionMessage) {
        const link = cautionMessage.querySelector(".btn-primary");
        if (link) applyColorProperty(link, "BUTTON", "primaryColor");
      }
    });
  };

  /**
   * Function to applies color properties to the specified element based on the provided color parameter.
   * @param {HTMLElement} element - The HTML element to apply the color properties to.
   * @param {string} elementType - The type of element, e.g., "BUTTON".
   * @param {string} colorParam - The name of the search parameter containing the color value.
   */
  const applyColorProperty = (element, elementType, colorParam) => {
    const color = searchParams.get(colorParam);
    if (elementType === "BUTTON") {
      element.style.setProperty("background-color", color, "important");
      element.style.setProperty("border-color", color, "important");
    }

    if (elementType === "BACKGROUND")
      element.style.setProperty("background-color", color, "important");
  };

  const adjustCheckboxAppearance = (element) => {
    if (element && frameContent && frameContent?.length > 0 && isWhiteLabel) {
      if (element.checked)
        applyColorProperty(element, "BUTTON", "primaryColor");
      else {
        element.style.removeProperty("background-color");
        element.style.removeProperty("border-color");
      }
    }
  };

  useEffect(() => {
    /**
     * Hook to apply frame styles.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    if (frameContent && frameContent?.length > 0 && isWhiteLabel)
      applyFrameStylesFromSearchParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, frameContent, isWhiteLabel]);

  useEffect(() => {
    /**
     * Prevent the user from adding in more numbers to this field if they exceed the limit of required digits
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    if (phoneNumber) {
      const formattedPhone = formatPhoneNumberIntl(phoneNumber);

      // Update maxLength if the cleaned phone number is valid
      if (isPossiblePhoneNumber(formattedPhone))
        setMaxLength(formattedPhone.length);
    }
  }, [phoneNumber, maxLength]);

  /**
   * Function to handle the form submission by sending donor data to the backend API..
   * If successful, redirects to the payment page.
   * @param {Object} data - Donor data to be submitted.
   */
  const onSubmit = async (data) => {
    try {
      data.address.phoneNumber = formatPhoneNumberIntl(
        data.address.phoneNumber
      );
      await postData("/api/donor/upsert-donor", data, userSession);
      setIsLoading(false);
      if (user?.roles) {
        // Refresh token if user roles do not include "Donor"
        if (!user.roles.includes("Donor")) {
          const tokenResponse = await refreshToken(null, userSession);
          saveToken(tokenResponse.token);
        }
      }
      const redirectURL = `/donation/payment/${cartId}${isWhiteLabel ? `?${searchParams.toString()}` : ""
        }`;
      navigate(redirectURL);
    } catch (error) {
      // Show a notification if an error occurs any.
      showNotification("danger", error, 5000);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    /**
     * If hasTribute is true, trigger validation for all tribute-related fields
     * Otherwise, unregister tribute-related fields from the form
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    if (hasTribute) trigger();
    else {
      unregister("tributeName");
      unregister("tributeEmailAddress");
      unregister("tributeNote");
    }
  }, [hasTribute, trigger, unregister]);

  useEffect(() => {
    /**
     * If user is anonymous, unregister password and confirmPassword fields
     * Otherwise, trigger validation for all fields.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    if (isAnonymous) {
      unregister("password");
      unregister("confirmPassword");
    } else trigger();
  }, [isAnonymous, trigger, unregister]);

  /**
   * Function to handle email validation.
   * Validates email format and checks if it exists in the database.
   * @param {string} value - The email value to be validated.
   * @returns {boolean} - Returns true if email is valid, false if invalid, or error if validation fails.
   */
  const handleValidateEmail = async (value) => {
    if (!fieldState.inValid && !isAnonymous && userSession === null) {
      setIsValidEmail(true);
      try {
        await checkEmailValidity(value);
        setIsValidEmail(false);
        return true;
      } catch (error) {
        setIsValidEmail(false);
        return error;
      }
    }
  };

  useEffect(() => {
    /**
     * Function to fetch the country code
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const matchedCountry = CountryRegionData.find(
      (country) => country[0] === selectedCountry
    );

    setSelectedCountryCode(matchedCountry ? matchedCountry[1] : "");
  }, [selectedCountry]);

  /**
   * Function to fetch cart details from the backend API.
   */
  const getCart = async () => {
    setIsPageLoading(true);
    try {
      const response = await getData(
        `/api/cart/get/${cartId}/${user?.nameid ?? ""}`,
        null,
        null
      );
      setCart(response);
      setIsPageLoading(false);
    } catch (error) {
      // Show a notification if an error occurs any.
      showNotification("danger", error, 5000);
      setCart({});
      setIsPageLoading(false);
    }
  };

  /**
   * Function to fetch donor information from the backend API.
   * Sets selected country based on donor's address and sets tribute status.
   */
  const getDonorInfo = async () => {
    setIsPageLoading(true);
    try {
      let response = await getData(
        `/api/donor/get-donor-info/${cartId}/${user?.nameid ?? ""}`,
        null,
        null
      );

      if (response) {
        if (response.address !== null)
          setSelectedCountry(response.address.country);
        else response.address = { country: "United States" };
        setHasTribute(response.hasTribute);
      } else response = {};

      response.cartId = response?.cartId > 0 ? response?.cartId : cartId;
      response.userId = response?.userId ?? user?.nameid ?? null;
      response.donorId = response?.donorId ?? 0;

      reset(response);
      setIsPageLoading(false);
    } catch (error) {
      // Show a notification if an error occurs any.
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    /**
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    if (cookies["_benevolent_payment_origin"] === "Payment") {
      setShowTransactionConfirmation(true);

      // Unable to detect the browser back button using the pop state event.
      // Alternatively, we can utilize cookies to identify the origin page when a user navigates back,
      // allowing us to display the transaction cancellation confirmation accordingly.
      removeCookie("_benevolent_payment_origin", {
        path: "/",
        sameSite: "none",
        secure: true,
      });
    }

    // Return a cleanup function
    return () => { };
  }, [cookies, removeCookie]);

  useEffect(() => {
    /**
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */

    if (cartId > 0 || user?.nameid) {
      getCart();
      getDonorInfo();
    } else setIsPageLoading(false);

    // Return a cleanup function
    return () => { };
  }, [cartId, user?.nameid]);

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main className="my-4 mx-auto" ref={pageRef}>
      {Object.keys(cart).length > 0 && (
        <Fragment>
          <Row className="justify-content-center">
            <Col xs={12} className="text-center mb-5 page-title">
              <h1 className="title">
                <span className="text-secondary">Payment</span>{" "}
                <span className="position-relative fw-medium">
                  Details<span className="title-divider"></span>
                </span>
              </h1>
              <p className="mb-0 mt-3">
                Complete you donation by providing your payment details.
              </p>
            </Col>
          </Row>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className="row justify-content-center"
          >
            <Col xs={11} md={8} lg={7} xl={6} xxl={5}>
              <Card className="border-0 shadow-sm p-4 rounded-4 donor-info">
                <Card.Body>
                  <Row className="align-items-center mb-4">
                    <Col xs="auto">
                      <h5 className="title mb-0">Basic Info</h5>
                    </Col>
                    <Col>
                      <hr className="m-0" />
                    </Col>
                  </Row>
                  <Row>
                    <Form.Control type="hidden" {...register("donorId")} />
                    <Form.Control type="hidden" {...register("cartId")} />
                    <Form.Control type="hidden" {...register("userId")} />
                    <Col
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="mb-4"
                    >
                      <InputGroup>
                        <FloatingLabel
                          label="First Name"
                          controlId="txtFirstName"
                          htmlFor="txtFirstName"
                        >
                          <Form.Control
                            type="text"
                            placeholder="First Name"
                            {...register("firstName", {
                              required: "First Name is required",
                              pattern: {
                                value:
                                  /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                message:
                                  "First name shouldn't contain HTML or Script tags",
                              },
                              maxLength: {
                                value: 50,
                                message:
                                  "First Name cannot exceed 50 characters",
                              },
                            })}
                          />
                        </FloatingLabel>
                        <InputGroup.Text>
                          <i className="flaticon-profile fs-4"></i>
                        </InputGroup.Text>
                      </InputGroup>
                      {errors.firstName && (
                        <Form.Text className="text-danger">
                          {errors.firstName.message}
                        </Form.Text>
                      )}
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="mb-4"
                    >
                      <InputGroup>
                        <FloatingLabel
                          label="Last Name"
                          controlId="txtLastName"
                          htmlFor="txtLastName"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Last Name"
                            {...register("lastName", {
                              required: "Last name is required",
                              pattern: {
                                value:
                                  /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                message:
                                  "Last name shouldn't contain HTML or Script tags",
                              },
                              maxLength: {
                                value: 50,
                                message:
                                  "Last Name cannot exceed 50 characters",
                              },
                            })}
                          />
                        </FloatingLabel>
                        <InputGroup.Text>
                          <i className="flaticon-profile fs-4"></i>
                        </InputGroup.Text>
                      </InputGroup>
                      {errors.lastName && (
                        <Form.Text className="text-danger">
                          {errors.lastName.message}
                        </Form.Text>
                      )}
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="mb-4"
                    >
                      <InputGroup>
                        <FloatingLabel
                          label="Email Address"
                          controlId="txtEmail"
                          htmlFor="txtEmail"
                        >
                          <Form.Control
                            type="email"
                            placeholder="Email Address"
                            readOnly={userSession !== null}
                            {...register("email", {
                              required: "Email is required",
                              maxLength: 256,
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address",
                              },
                              validate: handleValidateEmail,
                            })}
                          />
                        </FloatingLabel>
                        {isValidEmail ? (
                          <InputGroup.Text>
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          </InputGroup.Text>
                        ) : (
                          <InputGroup.Text>
                            <i className="flaticon-mail fs-4"></i>
                          </InputGroup.Text>
                        )}
                      </InputGroup>
                      {errors.email && (
                        <Form.Text className="text-danger">
                          {errors.email.message}
                        </Form.Text>
                      )}
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-4">
                    <Col xs="auto">
                      <h5 className="title mb-0">Billing Address</h5>
                    </Col>
                    <Col>
                      <hr className="m-0" />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="mb-4"
                    >
                      <InputGroup>
                        <FloatingLabel
                          label="Street Address"
                          controlId="txtAddressLine1"
                          htmlFor="txtAddressLine1"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Street Address"
                            {...register("address.address1", {
                              required: "Street address is required",
                              pattern: {
                                value:
                                  /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                message:
                                  "Street address shouldn't contain HTML or Script tags",
                              },
                              maxLength: {
                                value: 50,
                                message:
                                  "Street address cannot exceed 50 characters",
                              },
                            })}
                          />
                        </FloatingLabel>
                        <InputGroup.Text>
                          <i className="flaticon-location fs-4"></i>
                        </InputGroup.Text>
                      </InputGroup>
                      {errors.address && errors.address.address1 && (
                        <Form.Text className="text-danger">
                          {errors.address.address1.message}
                        </Form.Text>
                      )}
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="mb-4"
                    >
                      <InputGroup>
                        <FloatingLabel
                          label="Address Line 2"
                          controlId="txtAddressLine2"
                          htmlFor="txtAddressLine2"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Address Line 2"
                            {...register("address.address2", {
                              pattern: {
                                value:
                                  /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                message:
                                  "Address Line 2 shouldn't contain HTML or Script tags",
                              },
                              maxLength: {
                                value: 50,
                                message:
                                  "Address Line 2 cannot exceed 50 characters",
                              },
                            })}
                          />
                        </FloatingLabel>
                        <InputGroup.Text>
                          <i className="flaticon-location fs-4"></i>
                        </InputGroup.Text>
                      </InputGroup>
                      {errors.address && errors.address.address2 && (
                        <Form.Text className="text-danger">
                          {errors.address.address2.message}
                        </Form.Text>
                      )}
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={4}
                      xxl={4}
                      className="mb-4"
                    >
                      <InputGroup>
                        <FloatingLabel
                          label="City"
                          controlId="txtCity"
                          htmlFor="txtCity"
                        >
                          <Form.Control
                            type="text"
                            placeholder="City"
                            {...register("address.city", {
                              required: "City is required",
                              pattern: {
                                value:
                                  /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                message:
                                  "City shouldn't contain HTML or Script tags",
                              },
                              maxLength: {
                                value: 50,
                                message: "City cannot exceed 50 characters",
                              },
                            })}
                          />
                        </FloatingLabel>
                        <InputGroup.Text>
                          <i className="flaticon-location fs-4"></i>
                        </InputGroup.Text>
                      </InputGroup>
                      {errors.address && errors.address.city && (
                        <Form.Text className="text-danger">
                          {errors.address.city.message}
                        </Form.Text>
                      )}
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={4}
                      xxl={4}
                      className="mb-4"
                    >
                      <FloatingLabel
                        label="Country"
                        controlId="ddCountry"
                        htmlFor="ddCountry"
                      >
                        <Controller
                          control={control}
                          name="address.country"
                          id="ddCountry"
                          rules={{ required: "Country is required" }}
                          render={({ field: { onChange, value } }) => (
                            <CountryDropdown
                              value={value || ""}
                              classes="form-select"
                              onChange={(val) => {
                                onChange(val);
                                setSelectedCountry(val);
                                setValue("address.phoneNumber", "");
                              }}
                            />
                          )}
                        />
                      </FloatingLabel>
                      {errors.address && errors.address.country && (
                        <Form.Text className="text-danger">
                          {errors.address.country.message}
                        </Form.Text>
                      )}
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={4}
                      xxl={4}
                      className="mb-4"
                    >
                      <FloatingLabel
                        label="State"
                        controlId="ddState"
                        htmlFor="ddState"
                      >
                        <Controller
                          control={control}
                          name="address.state"
                          id="ddState"
                          rules={{ required: "State is required" }}
                          render={({ field: { onChange, value } }) => (
                            <RegionDropdown
                              country={selectedCountry}
                              value={value || ""}
                              classes="form-select"
                              onChange={(val) => onChange(val)}
                            />
                          )}
                        />
                      </FloatingLabel>
                      {errors.address && errors.address.state && (
                        <Form.Text className="text-danger">
                          {errors.address.state.message}
                        </Form.Text>
                      )}
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="mb-4"
                    >
                      <InputGroup>
                        <FloatingLabel
                          label="Zip Code"
                          controlId="txtZipCode"
                          htmlFor="txtZipCode"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Zip Code"
                            {...register("address.zipCode", {
                              required: "Zip code is required",
                              pattern: {
                                value:
                                  /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                message:
                                  "Zip Code shouldn't contain HTML or Script tags",
                              },
                              maxLength: {
                                value: 20,
                                message: "Zip code cannot exceed 20 characters",
                              },
                            })}
                          />
                        </FloatingLabel>
                        <InputGroup.Text>
                          <i className="flaticon-location fs-4"></i>
                        </InputGroup.Text>
                      </InputGroup>
                      {errors.address && errors.address.zipCode && (
                        <Form.Text className="text-danger">
                          {errors.address.zipCode.message}
                        </Form.Text>
                      )}
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="mb-4"
                    >
                      <InputGroup>
                        <FloatingLabel
                          label="Phone Number"
                          controlId="txtPhoneNumber"
                          htmlFor="txtPhoneNumber"
                        >
                          <PhoneInput
                            name="address.phoneNumber"
                            id="txtPhoneNumber"
                            control={control}
                            country={
                              isSupportedCountry(selectedCountryCode)
                                ? selectedCountryCode
                                : ""
                            }
                            international={true}
                            withCountryCallingCode={true}
                            onChange={(val) => {
                              trigger("address.phoneNumber");
                            }}
                            maxLength={maxLength}
                            rules={{
                              required: "Phone number is required",
                              validate: (value) => {
                                if (!selectedCountry)
                                  return "Country is required";
                                if (
                                  !isPossiblePhoneNumber(
                                    value,
                                    selectedCountryCode
                                  )
                                ) {
                                  setMaxLength(20);
                                  return "Invalid phone number";
                                }
                                return true;
                              },
                            }}
                            className="form-control"
                          />
                        </FloatingLabel>
                        <InputGroup.Text>
                          <i className="flaticon-smartphone fs-3"></i>
                        </InputGroup.Text>
                      </InputGroup>
                      {errors.address && errors.address.phoneNumber && (
                        <Form.Text className="text-danger">
                          {errors.address.phoneNumber.message}
                        </Form.Text>
                      )}
                    </Col>
                    <Col xs={12} className="mb-3">
                      <Form.Check
                        type="switch"
                        name="status"
                        id="switchHasTribute"
                        label="I want to honor someone else with this gift"
                        variant="secondary"
                        className="text-muted"
                        {...register("hasTribute")}
                        checked={hasTribute}
                        onChange={(e) => {
                          adjustCheckboxAppearance(e.target);
                          setHasTribute(e.target.checked);
                        }}
                      />
                    </Col>
                    {hasTribute && (
                      <Fragment>
                        <Row className="align-items-center mb-4">
                          <Col xs="auto">
                            <h5 className="title mb-0">Tribute</h5>
                          </Col>
                          <Col>
                            <hr className="m-0" />
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            xxl={6}
                            className="mb-4"
                          >
                            <InputGroup>
                              <FloatingLabel
                                label="Honoree's Name"
                                controlId="txtTributeName"
                                htmlFor="txtTributeName"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Honoree's Name"
                                  {...register("tributeName", {
                                    required: "Honoree's name is required",
                                    pattern: {
                                      value:
                                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                      message:
                                        "Honoree's name shouldn't contain HTML or Script tags",
                                    },
                                    maxLength: {
                                      value: 50,
                                      message:
                                        "Honoree's name cannot exceed 50 characters",
                                    },
                                  })}
                                />
                              </FloatingLabel>
                              <InputGroup.Text>
                                <i className="flaticon-profile fs-4"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            {errors.tributeName && (
                              <Form.Text className="text-danger">
                                {errors.tributeName.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            xxl={6}
                            className="mb-4"
                          >
                            <InputGroup>
                              <FloatingLabel
                                label="Honoree's Email Address"
                                controlId="txtTributeEmail"
                                htmlFor="txtTributeEmail"
                              >
                                <Form.Control
                                  type="email"
                                  placeholder="Honoree's Email Address"
                                  {...register("tributeEmailAddress", {
                                    required: "Email is required",
                                    maxLength: 256,
                                    pattern: {
                                      value:
                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: "Invalid email address",
                                    },
                                  })}
                                />
                              </FloatingLabel>

                              <InputGroup.Text>
                                <i className="flaticon-mail fs-4"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            {errors.tributeEmailAddress && (
                              <Form.Text className="text-danger">
                                {errors.tributeEmailAddress.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={12} className="mb-4">
                            <FloatingLabel
                              controlId="txtTributeNote"
                              label="Note for Honoree's"
                            >
                              <Form.Control
                                as="textarea"
                                className="scroll"
                                placeholder="Note for Honoree's"
                                {...register("tributeNote", {
                                  required: "Note is required",
                                  pattern: {
                                    value:
                                      /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>)[\s\S])*$/i,
                                    message:
                                      "Note shouldn't contain HTML or Script tags",
                                  },
                                  maxLength: {
                                    value: 2500,
                                    message:
                                      "Note cannot exceed 2500 characters",
                                  },
                                })}
                              />
                            </FloatingLabel>
                            <Form.Text muted className="d-block">
                              Express the reason for which you are celebrating
                              or recognizing this person.
                            </Form.Text>
                            {errors.tributeNote && (
                              <Form.Text className="text-danger">
                                {errors.tributeNote.message}
                              </Form.Text>
                            )}
                          </Col>
                        </Row>
                      </Fragment>
                    )}
                    {userSession === null && (
                      <Col xs={12} className="mb-4">
                        <Form.Check
                          type="switch"
                          name="status"
                          id="switchIsAnonymous"
                          label="Donate as guest"
                          variant="secondary"
                          className="text-muted"
                          checked={isAnonymous}
                          {...register("isAnonymous")}
                          onChange={(e) => {
                            adjustCheckboxAppearance(e.target);
                            setIsAnonymous(e.target.checked);
                          }}
                        />
                      </Col>
                    )}
                  </Row>
                  {!isAnonymous && userSession === null && (
                    <Fragment>
                      <Row className="align-items-center mb-4">
                        <Col xs="auto">
                          <h5 className="title mb-0">
                            Create an Account{" "}
                            <span className="fw-normal text-light-emphasis">
                              (Optional)
                            </span>
                          </h5>
                        </Col>
                        <Col>
                          <hr className="m-0" />
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          xxl={6}
                          className="mb-4 mb-sm-0"
                        >
                          <InputGroup>
                            <FloatingLabel
                              label="Password"
                              controlId="txtPassword"
                              htmlFor="txtPassword"
                            >
                              <Form.Control
                                type="password"
                                placeholder="Password"
                                {...register("password", {
                                  required: "Password is required",
                                  pattern: {
                                    value:
                                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@@$!%*?&])[A-Za-z\d$@@$!%*?&]{6,}/,
                                    message:
                                      "Password must contain at least 6 characters, including at least one uppercase letter, one lowercase letter, one special character, and one number.",
                                  },
                                })}
                              />
                            </FloatingLabel>
                            <InputGroup.Text>
                              <i className="flaticon-password fs-3"></i>
                            </InputGroup.Text>
                          </InputGroup>
                          {errors.password && (
                            <Form.Text className="text-danger">
                              {errors.password.message}
                            </Form.Text>
                          )}
                        </Col>
                        <Col
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          xxl={6}
                          className="mb-4 mb-sm-0"
                        >
                          <InputGroup>
                            <FloatingLabel
                              label="Re-type Password"
                              controlId="txtConfirmPassword"
                              htmlFor="txtConfirmPassword"
                            >
                              <Form.Control
                                type="password"
                                placeholder="Password"
                                {...register("confirmPassword", {
                                  required: "Confirm Password is required",
                                  validate: (value) =>
                                    value === password ||
                                    "Passwords do not match",
                                })}
                              />
                            </FloatingLabel>
                            <InputGroup.Text>
                              <i className="flaticon-password fs-3"></i>
                            </InputGroup.Text>
                          </InputGroup>
                          {errors.confirmPassword && (
                            <Form.Text className="text-danger">
                              {errors.confirmPassword.message}
                            </Form.Text>
                          )}
                        </Col>
                      </Row>
                    </Fragment>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col xs={11} md={4} lg={4} xl={4} xxl={3}>
              <Card
                className="border-0 shadow-sm p-4 rounded-4 mt-4 mt-md-0 text-white summary"
                bg="secondary"
              >
                <Card.Body>
                  <h4 className="title text-white mb-3">Summary</h4>
                  {cart.cartItemInfo?.map((cartItem) => (
                    <div
                      className="d-flex justify-content-between fw-light my-3"
                      key={key(cartItem)}
                    >
                      <span>{cartItem.recipient}</span>
                      <span>${cartItem.amount}</span>
                    </div>
                  ))}
                  <div className="d-flex justify-content-between fw-light my-3">
                    <span>Additional Gift</span>
                    <span>${cart.contribution}</span>
                  </div>
                  <hr className="my-3" />
                  <div className="d-flex justify-content-between fw-semibold my-3">
                    <span>Total</span>
                    <span>${cart.totalAmount}</span>
                  </div>
                  <Row className="mt-4">
                    <Col xs={6} md={12} xl={6} xxl={7}>
                      <Button
                        variant="primary"
                        type="submit"
                        className="w-100 mb-2 btn-md"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          "Give Now"
                        )}
                      </Button>
                    </Col>
                    <Col xs={6} md={12} xl={6} xxl={5} className="ps-xxl-0">
                      <Link
                        to={-1}
                        title="Back"
                        className="btn btn-light w-100 btn-md"
                      >
                        Back
                      </Link>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Form>
        </Fragment>
      )}
      {Object.keys(cart).length === 0 && !isPageLoading && (
        <Container>
          <Row className="justify-content-center text-center">
            <Col xs={10} md={8}>
              <Card className="border-0 shadow p-4 rounded-4 text-center caution-message">
                <Card.Body>
                  <Image src={noData} alt="Invalid Cart" fluid />
                  <h4 className="title mt-3">Invalid Cart</h4>
                  <p className="mb-0 fw-light text-dark-emphasis">
                    It appears that the cart you are attempting to donate is
                    currently unavailable.
                  </p>
                  {isWhiteLabel && (
                    <Link
                      to={`/giving-page/organization/${searchParams.get(
                        "code"
                      )}?frameLayout=plain`}
                      title="Back to Home"
                      className="btn btn-primary btn-md mt-3"
                    >
                      Back to Home
                    </Link>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      <TransactionCancellationConfirmation
        cartId={cartId}
        showTransactionConfirmation={showTransactionConfirmation}
        setShowTransactionConfirmation={setShowTransactionConfirmation}
        setIsPageLoading={setIsPageLoading}
      />
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner
            animation="border"
            role="status"
            variant={!isWhiteLabel ? "primary" : "light"}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default Request;
