import React, { useState, useEffect, useReducer } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Card,
  Image,
  Button,
  Dropdown,
  Badge,
  Modal,
  Alert,
  Spinner,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import DataTable from "../../components/table";

import { getData, putData } from "../../services/apiService";
import { useAuthContext } from "../../context/authProvider";
import { notify, initialState } from "../../store/notification";
import confirmation from "../../resources/images/confirmation.png";

/**
 * Component for managing updates for a need.
 * @returns {React.Element} - Returns JSX for managing updates.
 */
const ManageNeedUpdates = () => {
  // Extract the need ID from the URL parameters for getting
  // the updates
  const { needId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();

  const [recipient] = useState(state?.recipient); // Read values passed on state
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [updates, setUpdates] = useState([]);

  // Columns configuration for the need update table
  const columns = [
    {
      header: "Subject",
      accessorKey: "subject",
    },
    {
      header: "Submitted By",
      accessorKey: "submittedBy",
    },
    {
      header: "Submitted On",
      accessorKey: "submittedOn",
    },
    {
      header: "Published By",
      accessorKey: "publishedBy",
    },
    {
      header: "Published On",
      accessorKey: "publishedOn",
    },
    {
      header: "Status",
      cell: (cellProps) => {
        let background;
        switch (cellProps.row.original.status) {
          case "Draft":
            background = "light";
            break;
          case "Published":
            background = "success";
            break;
          default:
            background = "dark";
            break;
        }
        return (
          <Badge className="fw-semibold text-dark" bg={background}>
            {cellProps.row.original.status.toUpperCase()}
          </Badge>
        );
      },
    },
    {
      header: "Actions",
      accessorKey: "id",
      cell: (cellProps) => {
        return (
          <Dropdown drop="down" className="more-menu">
            <Dropdown.Toggle variant="light" size="sm">
              <i className="flaticon-more"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => navigateToPostUpdates(cellProps.row.original.id)}
              >
                <i className="flaticon-edit fs-6 me-1"></i>
                Edit
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => showConfirmation(cellProps.row.original.id)}
              >
                <i className="flaticon-delete fs-6 me-1 align-text-bottom"></i>
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  /**
   * Function to navigate to the post updates page.
   * @param {number} updateId - The ID of the update, if available.
   */
  const navigateToPostUpdates = (updateId) => {
    const route =
      updateId && updateId > 0
        ? `/needs/updates/post/${needId}/${updateId}`
        : `/needs/updates/post/${needId}`;
    navigate(route, {
      replace: true,
      state: { recipient: recipient },
    });
  };

  /**
   *  Function to delete a update by its ID, including its associated files
   * @param {string} receiptId - The ID of the receipt to be deleted.
   */
  const deleteUpdate = async (id) => {
    let fileResideAt = "";
    const filteredData = updates.filter((update) => update.id === id);
    if (filteredData[0].attachments.length > 0) {
      const parts = filteredData[0].attachments[0].split("/");
      fileResideAt = parts[parts.length - 2];
    }
    setIsPageLoading(true);
    try {
      const response = await putData(
        `/api/need/delete-update/${id}/${fileResideAt}`,
        null,
        userSession
      );
      setUpdates(updates.filter((update) => update.id !== id));
      setIsPageLoading(false);
      showNotification("success", response, 5000);
    } catch (error) {
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  /**
   * Function to display a confirmation dialog for deleting a update.
   * @param {number} id - The ID of the update to be deleted.
   */
  const showConfirmation = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Card className="border-0 shadow rounded-3">
            <Card.Body className="p-4 text-center">
              <Image src={confirmation} alt="Delete Confirmation" fluid />
              <div className="mt-3 fw-light">
                Do you really want to delete this update? <br />
                This process can't be undone.
              </div>
            </Card.Body>
            <Card.Footer className="bg-transparent py-4 text-center">
              <Button
                title="Cancel"
                variant="link"
                className="link-danger fw-semibold"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                className="ms-4 btn-md"
                onClick={async () => {
                  try {
                    await deleteUpdate(id);
                  } catch (error) {
                    // showNotification("danger", error, 5000);
                  }
                  onClose();
                }}
              >
                Yes, Delete
              </Button>
            </Card.Footer>
          </Card>
        );
      },
    });
  };

  useEffect(() => {
    /**
     * Fetches the updates for a specific need from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getAll = async () => {
      setIsLoading(true);
      try {
        const response = await getData(
          `/api/need/updates/${needId}`,
          null,
          userSession
        );
        setUpdates(response);
        setIsLoading(false);
      } catch (error) {
        showNotification("danger", error, 5000);
        setUpdates([]);
        setIsLoading(false);
      }
    };

    getAll();

    // Return a cleanup function
    return () => {};
  }, [needId, userSession]);

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-semibold mb-0">
            Updates for {recipient}'s Contributors
          </h6>
          <div>
            <Link to={`/needs`} className="ms-3 btn btn-secondary" title="Back">
              <i className="flaticon-line-arrow-left pe-2"></i>
              Back
            </Link>
            <Button
              onClick={navigateToPostUpdates}
              variant="secondary"
              className="ms-3"
            >
              + Post Update
            </Button>
          </div>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <DataTable
              columns={columns}
              data={updates}
              totalRecords={updates.length}
              loadingState={isLoading}
            />
          </Card.Body>
        </Card>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default ManageNeedUpdates;
