import React, { useState, useEffect, useRef, Fragment } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Row, Col, Card, Image, Button, Modal, Spinner } from "react-bootstrap";

import Receipt from "./receipt";

import { getData } from "../../services/apiService";

import noData from "../../resources/images/no-data.png";

/**
 * Component for displaying transaction status.
 * @param {string} referenceId - The reference ID of the transaction.
 * @returns {JSX.Element} - Returns JSX for displaying transaction status.
 */
const StatusElement = ({ referenceId }) => {
  // Get the current URL's query string
  const [searchParams] = useSearchParams();
  const pageRef = useRef(null);
  const [hasError, setHasError] = useState(false);
  const [receipt, setReceipt] = useState({});
  const [isPageLoading, setIsPageLoading] = useState(false);
  // State to control whether the transaction receipt should be opened for printing
  const [hasToPrint, setHasToPrint] = useState(false);
  // To determine the origin of the iframe and whether it is a white-label integration.
  const [isWhiteLabel] = useState(window.self !== window.top);

  /**
   * Function to apply styles to elements based on search parameters.
   * @param {HTMLCollection} frameContent - The collection of elements inside the frame.
   */
  const applyFrameStylesFromSearchParams = (frameContent) => {
    Array.from(frameContent).forEach((content) => {
      if (content.className.includes("action-buttons")) {
        const btnPrimary = content.querySelector(".btn-primary");
        if (btnPrimary)
          applyColorProperty(btnPrimary, "BUTTON", "primaryColor");

        const btnSecondary = content.querySelector(".btn-secondary");
        if (btnSecondary)
          applyColorProperty(btnSecondary, "BUTTON", "secondaryColor");

        const link = content.querySelector(".link-secondary");
        if (link) applyColorProperty(link, "LINK", "secondaryColor");
      }

      // Update style for the caution message
      const cautionMessage = content.querySelector("div.caution-message");
      if (cautionMessage) {
        const link = content.querySelector(".btn-primary");
        if (link) applyColorProperty(link, "BUTTON", "primaryColor");
      }
    });
  };

  /**
   * Function to apply color properties to HTML elements.
   * @param {HTMLElement} element - The HTML element to apply the style.
   * @param {string} elementType - The type of element (BUTTON or LINK).
   * @param {string} colorParam - The color parameter from the search parameters.
   */
  const applyColorProperty = (element, elementType, colorParam) => {
    const color = searchParams.get(colorParam);
    if (elementType === "BUTTON") {
      element.style.setProperty("background-color", color, "important");
      element.style.setProperty("border-color", color, "important");
    }
    if (elementType === "LINK")
      element.style.setProperty("color", color, "important");
  };

  /**
   * Hook to apply frame styles.
   * Executes when there is a change in the values of the dependencies in the useEffect hook.
   */
  useEffect(() => {
    if (isWhiteLabel) {
      const frameContent = pageRef.current ? pageRef.current.children : null;
      if (frameContent && frameContent?.length > 0)
        applyFrameStylesFromSearchParams(frameContent);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receipt, isWhiteLabel]);

  useEffect(() => {
    /**
     * Fetches the transaction from the backend API.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getReceipt = async () => {
      try {
        const response = await getData(
          `/api/payment/get-receipt/${referenceId}`,
          null,
          null
        );
        setIsPageLoading(false);
        setReceipt(response);
      } catch (error) {
        setIsPageLoading(false);
        setHasError(true);
      }
    };
    getReceipt();
  }, [referenceId]);

  return (
    <Fragment>
      {Object.keys(receipt).length > 0 && (
        <div className="m-auto" ref={pageRef}>
          <Receipt
            receipt={receipt}
            hasToPrint={hasToPrint}
            setHasToPrint={setHasToPrint}
          />
          <div className="text-center mt-4 action-buttons">
            {!isWhiteLabel ? (
              <Fragment>
                <Link
                  to="/giving-history"
                  className="btn btn-secondary btn-md mb-2"
                >
                  Access My Giving Account
                </Link>
                <Link
                  to="/needs/search"
                  className="ms-3 btn btn-primary btn-md mb-2"
                >
                  View All Needs
                </Link>
              </Fragment>
            ) : (
              <Fragment>
                <Link
                  to={`/giving-page/organization/${searchParams.get(
                    "code"
                  )}?frameLayout=plain`}
                  className="ms-3 btn btn-primary btn-md mb-2"
                >
                  Back to Home
                </Link>
              </Fragment>
            )}
            <Button
              variant="link"
              onClick={() => setHasToPrint(true)}
              className="ms-3 link-secondary fw-semibold mb-2"
            >
              Print Receipt
            </Button>
          </div>
        </div>
      )}
      {hasError && (
        <Row
          className="justify-content-center text-center m-auto"
          ref={pageRef}
        >
          <Col xs={11} sm={8} md={8} lg={8} xl={8} xxl={8}>
            <Card className="border-0 shadow p-4 rounded-4 caution-message">
              <Card.Body>
                <Image src={noData} alt="Transaction Error" fluid />
                <h4 className="title mt-3">Transaction Error</h4>
                <p className="mb-0 fw-light text-dark-emphasis">
                  Encountered an unexpected error. Please try again or contact
                  support if the problem continues.
                </p>
                {isWhiteLabel && (
                  <Link
                    to={`/giving-page/organization/${searchParams.get(
                      "code"
                    )}?frameLayout=plain`}
                    title="Back to Home"
                    className="btn btn-primary btn-md mt-3"
                  >
                    Back to Home
                  </Link>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner
            animation="border"
            role="status"
            variant={!isWhiteLabel ? "primary" : "light"}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default StatusElement;
