import React, { useRef, useEffect, useState, Fragment } from "react";
import { Chart, ArcElement, Tooltip, Legend, defaults } from "chart.js";
import { Doughnut } from "react-chartjs-2";

// Set default font family for charts to "Poppins"
defaults.font.family = "Poppins";
Chart.register(ArcElement, Tooltip, Legend);

/**
 * Component for displaying fundraising progress using a Doughnut chart.
 * @param {number} raised - Amount raised
 * @param {number} needed - Amount needed
 * @param {string} size - Size of the chart (default: "lg")
 * @param {boolean} displayLegend - Whether to display legend (default: true)
 * @param {string} feature - Feature name (default: "profile")
 * @returns {React.Element} - JSX for fundraising progress component.
 */
const Fundraising = ({
  raised,
  needed,
  status,
  size = "lg",
  displayLegend = true,
  feature = "profile",
}) => {
  const chartRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);
  const [formattedValues, setFormattedValues] = useState({});

  useEffect(() => {
    /**
     * Format values when 'raised' or 'needed' changes
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    setFormattedValues((prev) => ({
      ...prev,
      type: raised >= needed || status === "Funded" ? "Raised" : "To Go",
      amount:
        raised >= needed || status === "Funded"
          ? raised
          : parseFloat((needed - raised).toFixed(2)),
    }));
  }, [raised, needed, status]);

  useEffect(() => {
    /**
     * To handle resizing of the chart
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    getChartWidth();
    const handleResize = () => {
      const chart = chartRef.current;
      if (chart) chart.resize();
      getChartWidth();
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup by removing the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [chartRef]);

  /**
   * Function to get the width of the chart
   */
  const getChartWidth = () => {
    // Access the canvas element using the ref
    if (chartRef.current !== null) {
      const canvas = chartRef.current.canvas;
      const style = canvas.getAttribute("style");

      // Use regular expressions to extract width and height values
      const widthMatch = style.match(/width:\s*([\d.]+)px/);
      setChartWidth(parseFloat(widthMatch[1] * 0.75));
    }
  };

  // Helper function to format a number with a dollar sign and commas
  const formatAmountWithDollarSign = (number) => {
    return `$${number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  const data = {
    labels: ["Raised", "To Go"],
    datasets: [
      {
        data: [
          raised,
          raised >= needed ? 0 : parseFloat((needed - raised).toFixed(2)),
        ],
        backgroundColor: ["#f49401", "#aec9d0"],
        borderColor: ["#f49401", "transparent"],
        borderWidth: [8, 0],
        hoverBackgroundColor: ["#f49401", "#aec9d0"],
      },
    ],
  };

  /**
   * Options for the Doughnut chart
   */
  const options = {
    cutout: "90%", // Controls the size of the hole in the center
    animation: {
      animateRotate: true,
      animateScale: true,
      onComplete: function () {
        getChartWidth();
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#333", // Tooltip background color
        titleFontColor: "#fff", // Title font color
        bodyFontColor: "#fff", // Body font color
        displayColors: false, // Hide color boxes
        borderColor: "#333", // Tooltip border color
        borderWidth: 1, // Tooltip border width
        cornerRadius: 4, // Tooltip border radius
        caretSize: 6, // Size of the arrow pointing to the tooltip
        z: 2, // Set the z-index here
        callbacks: {
          label: (tooltipItem, data) => {
            return formatAmountWithDollarSign(
              tooltipItem.dataset.data[tooltipItem.dataIndex]
            );
          },
        },
      },
    },
    onClick: (event, item) => {
      if (item.length > 0)
        item[0].index
          ? setFormattedValues({
              type: "To Go",
              amount: parseFloat((needed - raised).toFixed(2)),
            })
          : setFormattedValues({ type: "Raised", amount: raised });
    },
  };

  return (
    <Fragment>
      <div className="fundraising-progress m-auto">
        {/* Render the Doughnut chart */}
        <Doughnut
          data={data}
          width="100"
          height="100"
          options={options}
          ref={chartRef}
        />
        {/* Render the formatted values */}
        <div
          className="needed"
          style={{
            width: chartWidth + "px",
            height: chartWidth + "px",
          }}
        >
          {feature === "profile" ? (
            <Fragment>
              <div className={`fw-semibold lblValue ${size}`}>
                {formatAmountWithDollarSign(formattedValues.amount)}
              </div>
              <div className={`text-dark-emphasis lblTitle ${size}`}>
                {formattedValues.type}
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className={`fw-semibold lblValue ${size}`}>${raised}</div>
              <div className={`text-dark-emphasis lblTitle ${size}`}>
                out of
              </div>
              <div className={`fw-semibold lblValue ${size}`}>${needed}</div>
            </Fragment>
          )}
        </div>
      </div>
      {displayLegend && (
        <div className="text-center chart-legend mt-3">
          <span className="d-inline-block raised"></span>
          <small className="fw-light text-light-emphasis align-text-top">
            Raised
          </small>
          <span className="d-inline-block needed"></span>
          <small className="fw-light text-light-emphasis align-text-top">
            To Go
          </small>
        </div>
      )}
    </Fragment>
  );
};

export default Fundraising;
