import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";

import Overview from "./widgets/_overview";
import OrgSummaryReport from "./widgets/_orgSummaryReport";
import RecentDonations from "./widgets/_recentDonations";
import NeedsRequiringAttention from "./widgets/_needsRequiringAttention";
import RecentSubmissions from "./widgets/_recentSubmissions";
import DonationAnalytics from "./widgets/_donationAnalytics";
import DonationSummary from "./widgets/_donationSummary";

import { useAuthContext } from "../../context/authProvider";
import { decodeToken } from "../../utils/session";

/**
 * Component for rendering the dashboard.
 * This component displays various widgets and reports based on user roles.
 * @returns {React.Element} - Returns JSX for rendering the dashboard.
 * @access Accessible by SuperAdmin, Staff, or Validator.
 */
const Dashboard = () => {
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  // Decode the user session token
  const user = decodeToken(userSession);

  return (
    <Container fluid className="dashboard">
      <Row>
        {(user?.roles.includes("SuperAdmin") ||
          user?.roles.includes("Staff")) && (
          <Col xs={12}>
            <Overview userSession={userSession} />
          </Col>
        )}
        {user?.roles.includes("Donor") && (
          <Col xs={12}>
            <DonationSummary userSession={userSession} />
          </Col>
        )}
        {(user?.roles.includes("SuperAdmin") ||
          user?.roles.includes("Staff")) && (
          <Col xs={12} md={6}>
            <DonationAnalytics userSession={userSession} />
          </Col>
        )}
        {(user?.roles.includes("SuperAdmin") ||
          user?.roles.includes("Staff") ||
          user?.roles.includes("Validator")) && (
          <Fragment>
            <Col xs={12} md={6}>
              <OrgSummaryReport userSession={userSession} />
            </Col>
            <Col xs={12} md={6}>
              <RecentSubmissions
                userSession={userSession}
                roles={user?.roles}
              />
            </Col>
            <Col xs={12} md={6}>
              <NeedsRequiringAttention userSession={userSession} />
            </Col>
            <Col xs={12}>
              <RecentDonations userSession={userSession} roles={user.roles} />
            </Col>
          </Fragment>
        )}
      </Row>
    </Container>
  );
};

export default Dashboard;
