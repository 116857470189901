import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import { getData } from "../../../services/apiService";

/**
 * Component for displaying system overview.
 * This component provides an overview of the system including total active needs, funded needs,
 * donors, and raised funds.
 * @param {object} props - The properties passed to the component.
 * @returns {React.Element} - Returns JSX for displaying system overview.
 * @access Accessible by SuperAdmin and Staff
 */
const Overview = (props) => {
  const [report, setReport] = useState({
    totalActiveNeeds: 0,
    totalDonors: 0,
    totalRaised: 0,
    totalFundedNeeds: 0,
    needsFundedPercentage: 0,
    donorAverageContribution: 0,
  });

  useEffect(() => {
    /**
     * Fetches an overview of the system including total active needs, funded needs,
     * donors, and raised funds. from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getSystemSummaryReport = async () => {
      try {
        const response = await getData(`/api/dashboard/getOverview`);
        if (response) setReport(response);
      } catch (error) {
        // setReport({});
      }
    };
    getSystemSummaryReport();
  }, [props]);

  return (
    <Row>
      <h6 className="fw-semibold mb-2">Overview</h6>
      <Col xs={12} sm={6} md={4} xxl={3} className="my-2">
        <div className="h-100 d-flex align-items-center justify-content-between p-3 rounded-3 bg-white shadow-sm">
          <div>
            <small className="text-light-emphasis">ACTIVE NEEDS</small>
            <div className="fs-5 fw-semibold mt-1">
              {report.totalActiveNeeds}
            </div>
          </div>
          <div className="ms-2 icon active-needs">
            <i className="flaticon-star fs-1"></i>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={6} md={4} xxl={3} className="my-2">
        <div className="h-100 d-flex align-items-center justify-content-between p-3 rounded-3 bg-white shadow-sm">
          <div>
            <small className="text-light-emphasis">FUNDED NEEDS</small>
            <div className="fs-5 fw-semibold mt-1">
              {report.totalFundedNeeds}
            </div>
          </div>
          <div className="ms-2 icon funded-needs">
            <i className="flaticon-feature fs-1"></i>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={6} md={4} xxl={3} className="my-2">
        <div className="h-100 d-flex align-items-center justify-content-between p-3 rounded-3 bg-white shadow-sm">
          <div>
            <small className="text-light-emphasis">
              PERCENTAGE OF NEEDS FUNDED
            </small>
            <div className="fs-5 fw-semibold mt-1">
              {report.needsFundedPercentage}%
            </div>
          </div>
          <div className="ms-2 icon needs-funded-in-percentage">
            <i className="flaticon-dollar-circle fs-1"></i>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={6} md={4} xxl={3} className="my-2">
        <div className="h-100 d-flex align-items-center justify-content-between p-3 rounded-3 bg-white shadow-sm">
          <div>
            <small className="text-light-emphasis">
              AVERAGE AMOUNT GIVEN BY EACH DONOR
            </small>
            <div className="fs-5 fw-semibold mt-1">
              ${report.donorAverageContribution}
            </div>
          </div>
          <div className="ms-2 icon avg-amount">
            <i className="flaticon-money fs-1"></i>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={6} md={4} xxl={3} className="my-2">
        <div className="h-100 d-flex align-items-center justify-content-between p-3 rounded-3 bg-white shadow-sm">
          <div>
            <small className="text-light-emphasis">DONORS</small>
            <div className="fs-5 fw-semibold mt-1">{report.totalDonors}</div>
          </div>
          <div className="ms-2 icon donors">
            <i className="flaticon-user-list fs-2"></i>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={6} md={4} xxl={3} className="my-2">
        <div className="h-100 d-flex align-items-center justify-content-between p-3 rounded-3 bg-white shadow-sm">
          <div>
            <small className="text-light-emphasis">TOTAL RAISED AMOUNT</small>
            <div className="fs-5 fw-semibold mt-1">${report.totalRaised}</div>
          </div>
          <div className="ms-2 icon raised-amount">
            <i className="flaticon-dollar-circle fs-1"></i>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Overview;
