import React, { Fragment, useState, useEffect, useReducer } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
  Alert,
  Spinner,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

import { transformProfilePicture } from "../../components/transformFileURL";

import { getData, postData } from "../../services/apiService";
import { notify, initialState } from "../../store/notification";
import { useAuthContext } from "../../context/authProvider";

const CreateValidator = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getFieldState,
    setValue,
  } = useForm();

  const { state } = useLocation();
  const navigate = useNavigate();
  // Extract the code from the URL parameters for pre-populating
  // invitation details for editing
  const { code } = useParams();
  const [fieldState, setFieldState] = useState(getFieldState("emailAddress"));
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  const [loadingStates, setLoadingStates] = useState({});
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  // To avoid unnecessary email validation
  const [originalEmail, setOriginalEmail] = useState("");

  /**
   * Function to handle the enrollment (adding or updating) process for a validator invitation.
   * @param {Object} data - The data containing validator invitation details.
   * @param {Event} e - The event triggered by form submission.
   */
  const handleEnrollment = async (data, e) => {
    data.status = e.nativeEvent.submitter.dataset.invitationStatus;

    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [data.status]: true,
    }));
    try {
      const response = await postData(
        "/api/invitation/addOrUpdate",
        data,
        userSession
      );

      resetForm();
      showNotification("success", response, 5000);
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [data.status]: false,
      }));
    } catch (error) {
      showNotification("danger", error, 5000);
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [data.status]: false,
      }));
    }
  };

  /**
   * Function to reset the form fields and selected organization .
   */
  const resetForm = () => {
    reset({
      code: "",
      firstName: "",
      lastName: "",
      emailAddress: "",
      jobTitle: "",
    });
    setSelectedOrganization(null);
  };

  useEffect(() => {
    /**
     * Fetches the organizations from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const searchOrganization = async () => {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        inSearch: true,
      }));
      try {
        const response = await getData(
          `/api/organization/search?searchTerm=${searchTerm}`,
          null,
          null
        );
        setOrganizations(response);
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          inSearch: false,
        }));
      } catch (error) {
        showNotification("danger", error, 5000);
        setOrganizations([]);
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          inSearch: false,
        }));
      }
    };
    if (searchTerm) searchOrganization();
    else setOrganizations([]);
  }, [searchTerm]);

  /**
   * Function to update the search term state.
   * @param {string} newValue - The new value entered by the user in the search input.
   */
  const handleSearchInput = (newValue) => {
    setSearchTerm(newValue);
  };

  /**
   * Custom filter function for filtering options in the organization dropdown.
   * @param {Object} option - The option to be filtered.
   * @param {string} searchText - The text to search for.
   * @returns {boolean} - Indicates whether the option matches the search text.
   */
  const customFilterOption = (option, searchText) => {
    return option.data.label.toLowerCase().includes(searchText.toLowerCase());
  };

  /**
   * Updates the field state with the latest state of the 'emailAddress' field
   * whenever the 'getFieldState' function changes.
   * This effect ensures that the field state stays synchronized with the form state.
   */
  useEffect(() => {
    setFieldState(getFieldState("emailAddress"));
  }, [getFieldState]);

  /**
   * Function to check the availability of the provided email address using the backend API
   * @param {string} value - The email address to check availability for.
   * @returns {boolean|Error} - Returns true if the email is available, or an Error object if the email is not available or an error occurs.
   */
  const handleEmailAvailability = async (value) => {
    if (!fieldState.invalid && value !== originalEmail) {
      try {
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          isValidEmail: true,
        }));
        await postData(
          `/api/invitation/verify-email-availability/${value}`,
          null,
          null
        );
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          isValidEmail: false,
        }));
        return true;
      } catch (error) {
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          isValidEmail: false,
        }));
        return error;
      }
    }
  };

  useEffect(() => {
    /**
     * Fetches invitation details by code.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getByCode = async () => {
      try {
        const response = await getData(
          `/api/invitation/getByCode/${code}?isEdit=true`,
          null,
          userSession
        );
        reset(response);
        setOriginalEmail(response.emailAddress);
        setSelectedOrganization([
          {
            label: response.organizationName,
            value: response.organizationId,
          },
        ]);
      } catch (error) {
        showNotification("danger", error, 5000);
      }
    };
    if (code) getByCode();
  }, [code, userSession, reset]);

  useEffect(() => {
    /**
     * Updates form fields and `selected organization` state
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     * This effect is triggered when the state changes, typically when the user navigates to this page from the organization's private profile page.
     */
    if (state != null) {
      const { organizationId, organizationName, organizationProfilePicture } =
        state;
      setSelectedOrganization({
        value: organizationId,
        label: organizationName,
        profilePicture: organizationProfilePicture,
      });
      setValue("organizationId", organizationId);
      setValue("organizationName", organizationName);
    }
  }, [state, setValue]);

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-bold mb-0">
            {code ? "Edit Validator" : "Create Validator"}
          </h6>
          <Button
            onClick={() => navigate(-1)}
            variant="secondary"
            className="ms-3"
          >
            <i className="flaticon-line-arrow-left pe-2"></i>
            Back
          </Button>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <Form onSubmit={handleSubmit(handleEnrollment)}>
              <Row className="align-items-center mb-4">
                <Form.Control type="hidden" {...register("code")} />
                <Form.Control type="hidden" {...register("organizationName")} />
                <Col xs="auto">
                  <span className="fw-medium">Validator Info</span>
                </Col>
                <Col>
                  <hr className="m-0" />
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={6} md={6} lg={4} className="mb-4">
                  <InputGroup>
                    <FloatingLabel
                      label="Firstname"
                      controlId="txtFirstname"
                      htmlFor="txtFirstname"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Firstname"
                        {...register("firstName", {
                          required: "First name is required",
                          pattern: {
                            value:
                              /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                            message:
                              "First name shouldn't contain HTML or script tags",
                          },
                          maxLength: {
                            value: 50,
                            message: "First name cannot exceed 50 characters",
                          },
                        })}
                      />
                    </FloatingLabel>
                    <InputGroup.Text>
                      <i className="flaticon-profile fs-4"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.firstName && (
                    <Form.Text className="text-danger">
                      {errors.firstName.message}
                    </Form.Text>
                  )}
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} className="mb-4">
                  <InputGroup>
                    <FloatingLabel
                      label="Lastname"
                      controlId="txtLastname"
                      htmlFor="txtLastname"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Lastname"
                        {...register("lastName", {
                          required: "Last name is required",
                          pattern: {
                            value:
                              /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                            message:
                              "Last name shouldn't contain HTML or script tags",
                          },
                          maxLength: {
                            value: 50,
                            message: "Last name cannot exceed 50 characters",
                          },
                        })}
                      />
                    </FloatingLabel>
                    <InputGroup.Text>
                      <i className="flaticon-profile fs-4"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.lastName && (
                    <Form.Text className="text-danger">
                      {errors.lastName.message}
                    </Form.Text>
                  )}
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} className="mb-4">
                  <InputGroup>
                    <FloatingLabel
                      label="Email"
                      controlId="txtEmail"
                      htmlFor="txtEmail"
                    >
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        {...register("emailAddress", {
                          required: "Email is required",
                          maxLength: 256,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                          validate: handleEmailAvailability,
                        })}
                      />
                    </FloatingLabel>
                    {loadingStates["isValidEmail"] ? (
                      <InputGroup.Text>
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </InputGroup.Text>
                    ) : (
                      <InputGroup.Text>
                        <i className="flaticon-mail fs-4"></i>
                      </InputGroup.Text>
                    )}
                  </InputGroup>
                  {errors.emailAddress && (
                    <Form.Text className="text-danger">
                      {errors.emailAddress.message}
                    </Form.Text>
                  )}
                </Col>
                {/* <Col xs={12} sm={6} md={6} lg={4} className="mb-4">
                  <InputGroup>
                    <FloatingLabel
                      label="Job Title"
                      controlId="txtJobTitle"
                      htmlFor="txtJobTitle"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Job Title"
                        {...register("jobTitle", {
                          required: "Job title is required",
                          pattern: {
                            value:
                              /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                            message:
                              "Job title shouldn't contain HTML or script tags",
                          },
                          maxLength: {
                            value: 100,
                            message: "Job title cannot exceed 100 characters",
                          },
                        })}
                      />
                    </FloatingLabel>
                    <InputGroup.Text>
                      <i className="flaticon-suitcase fs-4"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.jobTitle && (
                    <Form.Text className="text-danger">
                      {errors.jobTitle.message}
                    </Form.Text>
                  )}
                </Col> */}
              </Row>
              <Row className="align-items-center mb-4">
                <Col xs="auto">
                  <span className="fw-medium">Partner Info</span>
                </Col>
                <Col>
                  <hr className="m-0" />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} md={6} lg={4} className="mb-4">
                  <Controller
                    control={control}
                    name="organizationId"
                    id="ddOrg"
                    rules={{ required: "Organization is required" }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        // menuIsOpen={true}
                        isSearchable={true}
                        isClearable={true}
                        isLoading={loadingStates["inSearch"]}
                        placeholder="Organization"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        onInputChange={handleSearchInput}
                        options={organizations}
                        value={selectedOrganization}
                        menuPortalTarget={document.body}
                        //value={organizations.find((c) => c.value === value)}
                        onChange={(val) => {
                          onChange(val?.value || "");
                          setSelectedOrganization(val || null);
                          setValue(`organizationName`, val?.label || "");
                        }}
                        noOptionsMessage={() => "No results found"}
                        filterOption={customFilterOption}
                        getOptionLabel={(e) => (
                          <Fragment>
                            <Image
                              src={transformProfilePicture(e.profilePicture)}
                              alt={e.label}
                              roundedCircle
                              fluid
                              width="35"
                              className="me-2 align-middle"
                            />

                            <span>{e.label}</span>
                          </Fragment>
                        )}
                      />
                    )}
                  />
                  {errors.organizationId && (
                    <Form.Text className="text-danger">
                      {errors.organizationId.message}
                    </Form.Text>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mt-4">
                  <Button
                    variant="primary"
                    data-invitation-status="Draft"
                    disabled={loadingStates["Draft"]}
                    type="submit"
                    className="btn-md"
                  >
                    {loadingStates["Draft"] ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "Save as Draft"
                    )}
                  </Button>
                  <Button
                    variant="outline-secondary"
                    data-invitation-status="Accepted"
                    disabled={loadingStates["Accepted"]}
                    type="submit"
                    className="ms-3 btn-md"
                  >
                    {loadingStates["Accepted"] ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "Approve"
                    )}
                  </Button>
                  <Link
                    className="ms-3 btn btn-md btn-secondary"
                    to="/validator/invitations"
                  >
                    Cancel
                  </Link>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
    </main>
  );
};

export default CreateValidator;
