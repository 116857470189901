import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Image, Modal, Spinner } from "react-bootstrap";
import Select from "react-select";
import {
  PaymentForm,
  CreditCard,
  GooglePay,
} from "react-square-web-payments-sdk";

import { Cards } from "../../components/cardImporter";

import { postData, getData } from "../../services/apiService";
import {
  Square_ApplicationId,
  Square_LocationId,
  DefaultPaymentGateway,
  DefaultOrigin,
} from "../../constants";

import CardAvatar from "../../resources/images/card/CARD.png";

/**
 * Component for handling Square payment.
 * @param {object} props.userSession - The user session object.
 * @param {number} props.totalAmount - The total amount to be paid.
 * @param {number} props.cartId - The ID of the cart.
 * @param {number} props.userId - The ID of the user.
 * @returns {React.Element} - Returns JSX for rendering Square payment element.
 */
const SquarePaymentElement = ({ userSession, userId, cartId, totalAmount }) => {
  const navigate = useNavigate();
  // Get the current URL's query string
  const [searchParams] = useSearchParams();
  // This reference is used to interact with a element in component.
  const pageRef = useRef(null);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [paymentOptions, setPaymentOptions] = useState([]);
  //State hook for managing the selected payment option.
  const [selectedPaymentOption, setSelectedPaymentOption] = useState({});
  //State hook for managing the display of the new payment method.
  const [displayNewPaymentMethod, setDisplayNewPaymentMethod] = useState(false);
  //State hook for managing the origin of the payment method.
  const [origin] = useState(window.self !== window.top ? "White-Label" : DefaultOrigin);
  // To determine the origin of the iframe and whether it is a white-label integration.
  const [isWhiteLabel] = useState(window.self !== window.top);

  // frameContent refers to the children of the current pageRef,
  // which represents the content of the iframe.
  const frameContent = pageRef.current ? pageRef.current.children : null;

  /**
   * Function to apply styles to elements in the iframe based on search parameters.
   */
  const applyFrameStylesFromSearchParams = () => {
    Array.from(frameContent).forEach((content) => {
      const button = content.querySelector(".btn-primary");
      if (button) applyColorProperty(button, "BUTTON", "primaryColor");

      const links = content.querySelectorAll(".link-secondary");
      if (links) {
        links.forEach((linkStyle) => {
          applyColorProperty(linkStyle, "LINK", "secondaryColor");
        });
      }
    });
  };

  /**
   * Function to applies color properties to the specified element based on the provided color parameter.
   * @param {HTMLElement} element - The HTML element to apply the color properties to.
   * @param {string} elementType - The type of element, e.g., "BUTTON".
   * @param {string} colorParam - The name of the search parameter containing the color value.
   */
  const applyColorProperty = (element, elementType, colorParam) => {
    const color = searchParams.get(colorParam);
    if (elementType === "BUTTON") {
      element.style.setProperty("background-color", color, "important");
      element.style.setProperty("border-color", color, "important");
    }
    if (elementType === "LINK")
      element.style.setProperty("color", color, "important");
  };

  useEffect(() => {
    /**
     * Hook to apply frame styles.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    if (frameContent && frameContent?.length > 0 && isWhiteLabel)
      applyFrameStylesFromSearchParams();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayNewPaymentMethod, paymentOptions, frameContent, isWhiteLabel]);

  /**
   *  Function to create a payment using the provided token.
   * @param {object} token - The payment token.
   */
  const createPayment = async (token) => {
    setIsPageLoading(true);
    try {
      const request = {
        ...token,
        cartId: cartId,
        userId: userId,
        origin: origin,
      };

      const response = await postData(
        "/api/payment/create-square-payment",
        request,
        null
      );
      setIsPageLoading(false);
      loadReceipt(response.referenceId);
    } catch (error) {
      setIsPageLoading(false);
      setErrorMessage(error); // Set error message
    }
  };

  /**
   * Function to initiates a payment using a stored card.
   */
  const makePaymentByStoredCard = async () => {
    // Check if a payment method is selected
    if (Object.keys(selectedPaymentOption).length === 0) {
      setErrorMessage("Select a Payment Method");
      return;
    }
    setErrorMessage(null);

    setIsPageLoading(true);
    try {
      const request = {
        token: selectedPaymentOption.paymentMethod,
        cartId: cartId,
        userId: userId,
        origin: origin,
      };

      const response = await postData(
        "/api/payment/make-square-payment-by-stored-card/",
        request,
        null
      );
      setIsPageLoading(false);
      loadReceipt(response.referenceId);
    } catch (error) {
      setIsPageLoading(false);
      setErrorMessage(error); // Set error message
    }
  };

  /**
   * Function to redirects the user to the receipt page after completing the payment.
   * @param {string} referenceId - The reference ID of the transaction.
   */
  const loadReceipt = (referenceId) => {
    const redirectURL = `/donation/status/${referenceId}${
      isWhiteLabel ? `?${searchParams.toString()}` : ""
    }`;
    navigate(redirectURL);
  };

  /**
   * Function for creating a payment request.
   * @returns {Object} - The payment request object.
   */
  const createPaymentRequest = () => {
    return {
      countryCode: "US",
      currencyCode: "USD",
      total: {
        amount: totalAmount.toString(),
        label: "Total",
      },
    };
  };

  /**
   * Function for toggling the payment method.
   * @param {boolean} isNewPaymentMode - Indicates whether it's a new payment mode.
   */
  const togglePaymentMethod = (isNewPaymentMode) => {
    if (isNewPaymentMode) setSelectedPaymentOption({});
    // A timeout has been employed as a precautionary measure
    // to prevent the PaymentMethodNotAttachedError.
    setTimeout(() => {
      setDisplayNewPaymentMethod(isNewPaymentMode);
    }, 500);
    setErrorMessage(null);
  };

  useEffect(() => {
    /**
     * Fetches stored card details for the user from the backend API.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getStoredCardDetails = async () => {
      setIsPageLoading(true);
      try {
        const response = await getData(
          `/api/paymentOption/getAll/${userId}/${DefaultPaymentGateway}`,
          null,
          userSession
        );
        setPaymentOptions(response);
        setDisplayNewPaymentMethod(!response.length > 0);
        setIsPageLoading(false);
      } catch (error) {
        setPaymentOptions([]);
        setDisplayNewPaymentMethod(true);
        setIsPageLoading(false);
      }
    };

    if (userId) getStoredCardDetails();
    else setDisplayNewPaymentMethod(true);

    // Return a cleanup function
    return () => {
      // Cleanup logic
      // You can add more cleanup actions here if needed
    };
  }, [userSession, userId]);

  return (
    <div ref={pageRef}>
      {/* Display existing payment methods */}
      {paymentOptions.length > 0 && !displayNewPaymentMethod && (
        <Fragment>
          <Select
            placeholder="Select a Payment Method"
            className="react-select-container"
            classNamePrefix="react-select"
            options={paymentOptions}
            onChange={(val) => {
              setSelectedPaymentOption(val);
            }}
            getOptionLabel={(e) => (
              <div className="d-flex align-items-center">
                <Image
                  src={
                    Cards.find((x) => x.name.includes(e.cardType))?.image ||
                    CardAvatar
                  }
                  alt={e.cardType}
                  fluid
                  className="me-2 align-middle avatar"
                />

                <div className="ms-2 text-dark fw-bold">
                  <p className="mb-0">{e.cardType}</p>
                  <span className="fw-normal text-light-emphasis">
                    Ending in {e.cardLastFourDigit} [Expires {e.expiresOn}]
                  </span>
                </div>
              </div>
            )}
          />
          <div className="text-end mt-2">
            <Button
              variant="link"
              className="link-secondary fs-small p-0"
              onClick={() => togglePaymentMethod(true)}
            >
              Use a new payment method
            </Button>
          </div>
          <div>
            <Button
              variant="primary"
              className="btn-md w-100 mt-4"
              onClick={() => makePaymentByStoredCard()}
            >
              Pay Now
            </Button>
          </div>
        </Fragment>
      )}
      {/** Form for attach new payment method */}
      {displayNewPaymentMethod && (
        <Fragment>
          <PaymentForm
            applicationId={Square_ApplicationId}
            cardTokenizeResponseReceived={async (token, verifiedBuyer) => {
              if (token.status === "OK") await createPayment(token);
              else {
                if (token.errors && token.errors.length > 0) {
                  console.error(
                    "Errors encountered during tokenization:",
                    token.errors
                  );
                  // Aggregate multiple error messages into a single string
                  const formattedErrors = token.errors.Errors.map(
                    (error) => `${error.code}: ${error.detail}`
                  ).join("; ");
                  setErrorMessage(formattedErrors);
                } else setErrorMessage(token.status);
              }
            }}
            locationId={Square_LocationId}
            createPaymentRequest={createPaymentRequest}
          >
            <GooglePay className="pb-3" />
            <CreditCard
              buttonProps={{
                isLoading: isPageLoading,
                css: {
                  padding: "8px",
                  backgroundColor: searchParams.has("primaryColor")
                    ? searchParams.get("primaryColor")
                    : "#f39400",
                  fontSize: "14px",
                  fontFamily: "Poppins, sans-serif",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: searchParams.has("primaryColor")
                      ? searchParams.get("primaryColor")
                      : "#f5a933",
                  },
                },
              }}
              style={{
                input: {
                  fontSize: "13px",
                  color: "#393939",
                  fontFamily: "helvetica neue, sans-serif",
                },
                "input::placeholder": {
                  color: "#aaaaaa",
                },
              }}
            >
              Pay Now
            </CreditCard>
          </PaymentForm>
          {paymentOptions.length > 0 && (
            <div className="text-end mt-2">
              <Button
                variant="link"
                className="link-secondary p-0 fs-small"
                onClick={() => togglePaymentMethod(false)}
              >
                View prior payment methods
              </Button>
            </div>
          )}
        </Fragment>
      )}
      {errorMessage && (
        <div className="text-danger fs-small text-center mt-2">
          {errorMessage}
        </div>
      )}

      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner
            animation="border"
            role="status"
            variant={!isWhiteLabel ? "primary" : "light"}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SquarePaymentElement;
