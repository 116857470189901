import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Card,
  Modal,
  Spinner,
} from "react-bootstrap";

import NeedCard from "./widgets/_needCard";
import Pagination from "../../components/pagination";
import { transformProfilePicture } from "../../components/transformFileURL";

import { getData, postData } from "../../services/apiService";
import { useAuthContext } from "../../context/authProvider";
import { decodeToken } from "../../utils/session";

/**
 * Component for displaying user profile information.
 * This component renders information about the user and their subscribed needs.
 * @returns {React.Element} - Returns JSX for user profile.
 */
const UserProfile = () => {
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  // Decode the user session token
  const userToken = decodeToken(userSession);

  const [isPageLoading, setIsPageLoading] = useState({});
  const [user, setUser] = useState({});
  const [needs, setNeeds] = useState([]);
  const [page, setPage] = useState({ PageNumber: 1, PageSize: 12 });
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    /**
     * Fetches user profile from the backend API.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getUserProfile = async () => {
      setIsPageLoading(true);
      try {
        const response = await getData(
          `/api/account/getMyProfile/${userToken.nameid}`,
          null,
          userSession
        );
        response.profilePicture = transformProfilePicture(
          response.profilePicture
        );
        response.emailAddress = userToken.email;
        setUser(response);
        setIsPageLoading(false);
      } catch (error) {
        setIsPageLoading(false);
      }
    };
    if (userToken.nameid) getUserProfile();
  }, [userSession, userToken.nameid, userToken.email]);

  useEffect(() => {
    /**
     * Fetches subscribed needs from the backend API.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getSubscribedNeeds = async () => {
      setIsPageLoading(true);
      try {
        const response = await postData(
          `/api/profile/getSubscribedNeeds/${userToken.nameid}`,
          page,
          userSession
        );
        const updatedNeeds = transformProfilePicture(response.needs);
        setNeeds(updatedNeeds);
        if (response.count != null) setTotalRecords(response.count);
        setIsPageLoading(false);
      } catch (error) {
        setNeeds([]);
        setIsPageLoading(false);
      }
    };
    if (userToken.nameid) getSubscribedNeeds();
  }, [page, userSession, userToken.nameid]);

  /**
   * Function to handle pagination change.
   * @param {number} pageNumber - The new page number.
   * @param {number} pageSize - The new page size.
   */
  const handlePageChange = (pageNumber, pageSize) => {
    setNeeds([]);
    setPage({
      PageNumber: pageNumber,
      PageSize: pageSize,
    });
  };

  return (
    <main>
      <Container fluid className="user-profile">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-bold mb-0">My Profile</h6>
          <Link
            to="/update-profile"
            className="ms-3 btn btn-secondary"
            title="Edit Profile"
          >
            Edit Profile
          </Link>
        </div>
        <Card className="border-0">
          <Card.Body className="p-4">
            <Row>
              <Col
                xs={12}
                className="bg-light p-5 position-relative basic-info"
              >
                <h4 className="title text-center mb-1">
                  {user.firstName} {user.lastName}{" "}
                </h4>
                <p className="mb-5 fs-small text-center">
                  <Link
                    className="text-decoration-none me-2"
                    href={`mailto:${user.emailAddress}`}
                  >
                    <i className="flaticon-envelope fs-6 me-1"></i>
                    {user.emailAddress}
                  </Link>
                  {user.phoneNumber && (
                    <Link
                      className="text-decoration-none"
                      href={`tel:${user.phoneNumber}`}
                    >
                      <i className="flaticon-smartphone fs-6 me-1 align-text-bottom"></i>
                      {user.phoneNumber}
                    </Link>
                  )}
                </p>

                <Image
                  src={user.profilePicture}
                  alt="Profile"
                  roundedCircle
                  fluid
                  className="position-absolute"
                />
              </Col>
              {user.biography && (
                <Col xs={12}>
                  <Row className="align-items-center mb-4">
                    <Col xs="auto">
                      <h4 className="title mb-0">About Me</h4>
                    </Col>
                    <Col>
                      <hr className="m-0" />
                    </Col>
                  </Row>
                  <div className="fw-light mb-3 fs-small biography">
                    {user.biography}
                  </div>
                </Col>
              )}
            </Row>
            {needs.length > 0 && (
              <Fragment>
                <Row className="align-items-center mb-3">
                  <Col xs="auto">
                    <h4 className="title mb-0">Subscribed Needs</h4>
                  </Col>
                  <Col>
                    <hr className="m-0" />
                  </Col>
                </Row>
                <Row className="justify-content-center justify-content-sm-start">
                  {needs.map((need) => (
                    <Col
                      xs={8}
                      sm={6}
                      md={6}
                      lg={4}
                      xl={3}
                      xxl={3}
                      key={need.referenceId}
                      className="grid-list"
                    >
                      <NeedCard need={need} />
                    </Col>
                  ))}
                </Row>
                <Pagination
                  totalRecords={totalRecords}
                  page={page}
                  onPageChange={handlePageChange}
                />
              </Fragment>
            )}
          </Card.Body>
        </Card>
      </Container>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default UserProfile;
