import React, { useState, useReducer } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Alert,
  Spinner,
  Modal,
} from "react-bootstrap";

import { forgotPassword } from "../../services/accountService";
import { notify, initialState } from "../../store/notification";
import { SITEURL } from "../../constants";

import logo from "../../resources/images/logo.svg";
import emailConfirmation from "../../resources/images/email-confirmation.png";

/**
 * Component for password recovery confirmation.
 * This component displays a message confirming that password reset instructions have been sent to the user's email address.
 * It also allows users to resend the password recovery email and shows success or error notifications related to it.
 * @returns {React.Element} - Returns JSX for password recovery confirmation.
 */
const RecoveryPasswordConfirmation = () => {
  const { state } = useLocation();
  const [email] = useState(state?.email); // Read values passed on state
  const [isLoading, setIsLoading] = useState(false);
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);

  /**
   * Function to resend the password reset email.
   */
  const resendEmail = async () => {
    setIsLoading(true);
    try {
      const response = await forgotPassword(email);
      // Show success notification if email is successfully resent
      showNotification("success", response, 5000);
      setIsLoading(false);
    } catch (error) {
      // Show error notification if resending email fails
      showNotification("danger", error, 5000);
      setIsLoading(false);
    }
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };
  return (
    <main className="d-flex align-items-center justify-content-center min-vh-100 flex-column">
      <Container>
        <Row className="justify-content-center">
          <Col xs={11} sm={11} md={9} lg={7} xl={6} xxl={5}>
            <div className="text-center mb-4">
              <Link to={SITEURL} title="Benevolent">
                <Image src={logo} alt="Benevolent" className="logo" fluid />
              </Link>
            </div>
            <Card className="border-0 shadow p-4 rounded-4 text-center">
              <Card.Body>
                <Image src={emailConfirmation} alt="Email Confirmation" fluid />
                <h4 className="title mt-4">Check your email</h4>
                <p className="mb-5 fw-light text-dark-emphasis">
                  Password reset instructions have been sent to{" "}
                  <span className="text-primary fw-bold">{email}</span>. Check
                  your Spam/Junk folders if not received in 10 minutes.
                </p>
                <hr />
                <small className="text-dark-emphasis fw-light">
                  Didn't receive the link?
                  <Link
                    title="Resend Verification Email"
                    className="text-decoration-none link-secondary fw-bold"
                    disabled={isLoading}
                    size="lg"
                    onClick={resendEmail}
                  >
                    {" "}
                    Resend
                  </Link>
                </small>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* Notification alerts */}
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Modal
        show={isLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default RecoveryPasswordConfirmation;
