import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Card,
  Modal,
  Spinner,
} from "react-bootstrap";

import NeedCard from "../profile/widgets/_needCard";
import { transformProfilePicture } from "../../components/transformFileURL";
import Pagination from "../../components/pagination";

import { postData } from "../../services/apiService";
import { useAuthContext } from "../../context/authProvider";

import noData from "../../resources/images/no-data.png";

/**
 * Component for displaying needs based on the selected category.
 * @returns {React.Element} - Returns JSX for displaying needs by category.
 */
const NeedsByCategory = () => {
  // Extract the category ID from the URL parameters
  const { categoryId } = useParams();
  const location = useLocation();
  const categoryName = location.state?.name;
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [needs, setNeeds] = useState([]);
  const [page, setPage] = useState({ PageNumber: 1, PageSize: 25 });
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    /**
     * Function to fetch needs based on a specific category ID from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getNeeds = async () => {
      setIsPageLoading(true);
      try {
        const response = await postData(
          `/api/category/getNeedsByCategory?id=${categoryId}`,
          page,
          userSession
        );

        const updatedNeeds = transformProfilePicture(response.needs);
        setNeeds(updatedNeeds);
        if (response.count != null) setTotalRecords(response.count);
        setIsPageLoading(false);
      } catch (error) {
        setIsPageLoading(false);
      }
    };

    if (categoryId > 0) getNeeds();
  }, [categoryId, page, userSession]);

  /**
   * Function to handle page change event.
   * @param {number} pageNumber - The new page number.
   * @param {number} pageSize - The new page size.
   */
  const handlePageChange = (pageNumber, pageSize) => {
    setNeeds([]);
    setPage({
      PageNumber: pageNumber,
      PageSize: pageSize,
    });
  };

  return (
    <Container fluid className="needs-by-category">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h6 className="module-title fw-semibold mb-0">
          Needs for {categoryName} Category
        </h6>
        <Link to="/category" className="ms-3 btn btn-secondary" title="Back">
          <i className="flaticon-line-arrow-left pe-2"></i>
          Back
        </Link>
      </div>

      <Card className="border-0 rounded-3">
        <Card.Body className="p-4">
          {needs.length > 0 && (
            <Row className="justify-content-center justify-content-sm-start">
              {needs.map((need) => (
                <Col
                  xs={8}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={3}
                  xxl={3}
                  key={need.referenceId}
                  className="grid-list"
                >
                  <NeedCard need={need} />
                </Col>
              ))}
              <Pagination
                totalRecords={totalRecords}
                page={page}
                onPageChange={handlePageChange}
              />
            </Row>
          )}
          {!isPageLoading && Object.keys(needs).length === 0 && (
            <div className="text-center">
              <Image src={noData} alt="No Data Found" fluid />
              <h4 className="title mt-3">No Data Found</h4>
              <p className="mb-0 fw-light text-dark-emphasis">
                There are no needs to show here right now.
              </p>
            </div>
          )}
        </Card.Body>
      </Card>

      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default NeedsByCategory;
