import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useReducer,
} from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Modal,
  Alert,
  Spinner,
} from "react-bootstrap";

import { logoutRecipient } from "../../services/accountService";
import { postData } from "../../services/apiService";
import { notify, initialState } from "../../store/notification";
import { Host } from "../../constants";
import Invitation from "../../resources/images/invitation-light.png";

/**
 * Component for handling recipient invitations.
 * @param {object} props - The props containing need information.
 * @returns {React.Element} - JSX for recipient invitation creation.
 */
const RecipientInvitation = (props) => {
  const { userSession } = props;
  const [notification, dispatch] = useReducer(notify, initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  const codeRef = useRef(null);

  useEffect(() => {
    // Check if the user has the role of "Validator" and if the need has an access code
    if (props.roles.includes("Validator") && props.need.accessCode)
      setAccessCode(props.need.accessCode);
  }, [props.need, props.roles]);

  /**
   * Function to copy an access code to clipboard
   */
  const copyToClipboard = () => {
    const range = document.createRange();
    range.selectNode(codeRef.current);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  };

  /**
   * Generates a new temproary one-time access code for the recipient login
   */
  const generateAccessCode = async () => {
    setIsLoading(true);
    try {
      const response = await postData(
        `/api/need/generate-access-code/${props.need.needId}`,
        null,
        userSession
      );
      setAccessCode(response);
      setIsLoading(false);
    } catch (error) {
      // Show a notification if an error occurs during generates an access code
      showNotification("danger", error, 5000);
      setIsLoading(false);
    }
  };

  /**
   * Sends recipient access details to a validator.
   * Notifies the validator that the recipient can access the story of the need page using the provided temporary code.
   */
  const sendRecipientAccessToValidator = async () => {
    setIsPageLoading(true);
    try {
      const response = await postData(
        `/api/need/send-recipient-access-to-validator/${accessCode}`,
        null,
        userSession
      );
      showNotification("success", response, 5000);
      setIsPageLoading(false);
    } catch (error) {
      // Show a notification if an error occurs any
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  /**
   * Logs out the recipient.
   */
  const logout = async () => {
    setIsPageLoading(true);
    try {
      await logoutRecipient(accessCode);
      // Reset the access code
      setAccessCode("");
      // Toggle invitation state
      props.toggleInvitation(false, true);
      setIsPageLoading(false);
    } catch (error) {
      // Show a notification if an error occurs during logout
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <Fragment>
      <Container className="recipient-invitation">
        <Row className="align-items-center justify-content-center mt-4">
          <Col xs={10} sm={6} md={6} lg={4} xl={4} xxl={4} className="p-0">
            <div className="bg-light border-0 p-4">
              <h5 className="title">Let the recipient fill in this form</h5>
              <p className="mb-0 fs-small text-dark-emphasis guidance-message">
                You can empower the recipient to type in their own story by
                creating a temporary login for them. They will be able to
                complete this page independently, while you complete the
                remaining pages. Once the recipient has finished telling their
                story, you will be able to review their entry and submit the
                need.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={6} lg={5} xl={4} xxl={3} className="p-0">
            {accessCode && (
              <div className="py-5 px-4 text-center rounded-4 box">
                <Image src={Invitation} alt="Invitation" fluid width="50" />
                <h4 className="mt-3 mb-2 title text-white">
                  Invitation Details
                </h4>
                <p>
                  <Link
                    to="#"
                    title="Recipient Login"
                    className="link-light text-decoration-none fw-light fs-small"
                  >
                    {`${Host}/recipient-login`}
                  </Link>
                </p>
                <div className="d-inline-block py-2 px-3 rounded-2 bg-primary text-white">
                  <span className="fw-light">Code: </span>
                  <span className="fw-semibold" ref={codeRef}>
                    {accessCode}
                  </span>
                  <i
                    role="button"
                    className="flaticon-copy fs-3 ms-1"
                    onClick={copyToClipboard}
                  ></i>
                </div>
                <p className="fw-light fs-small mt-3 mb-0 text-white">
                  <Link
                    to="#"
                    title="Recipient Login"
                    className="link-light fw-normal"
                    onClick={sendRecipientAccessToValidator}
                  >
                    Click here
                  </Link>{" "}
                  to email this details with instructions
                </p>
              </div>
            )}
            {!accessCode && (
              <div className="py-5 px-4 text-center rounded-4 box">
                <Image src={Invitation} alt="Invitation" fluid width="50" />
                <h4 className="mt-3 mb-2 title text-white">
                  Create Invitation
                </h4>
                <p className="text-light fw-light fs-small mb-4">
                  This implies a login that is valid only for a single use or a
                  limited period.
                </p>
                <Button
                  variant="primary"
                  className="btn-md"
                  onClick={generateAccessCode}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    "Create Temporary Access"
                  )}
                </Button>
                <Button
                  variant="link"
                  onClick={() => props.toggleInvitation(false)}
                  className="btn-md text-white mt-2"
                >
                  Cancel
                </Button>
              </div>
            )}
          </Col>
          {accessCode && (
            <Col xs={12} lg={9} xl={8} xxl={7} className="mt-5 p-0">
              <div className="border-start border-5 border-secondary fs-small p-3 bg-light">
                <div className="pb-1 fw-semibold text-dark-emphasis">Note:</div>
                <div className="fw-light">
                  Once the recipient finishes, you will be able to view or edit
                  their story. In the mean time you can skip ahead to next page.
                  If you want to edit the story now,{" "}
                  <Link
                    to="#"
                    title="Logout"
                    onClick={logout}
                    className="text-decoration-none fw-semibold link-secondary"
                  >
                    sign the recipient out.
                  </Link>{" "}
                  The recipient will lose any work they haven't saved.
                </div>
              </div>
            </Col>
          )}
        </Row>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default RecipientInvitation;
