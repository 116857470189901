import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Card,
  Modal,
  Spinner,
} from "react-bootstrap";

import OrganizationCard from "./widgets/_organizationCard";
import { transformProfilePicture } from "../../components/transformFileURL";

import { postData } from "../../services/apiService";

import noData from "../../resources/images/no-data.png";

/**
 * Component for listing all active organizations in the system.
 * The page is open to all users and can be viewed without the need for authentication.
 * @returns {React.Element} - Return JSX for viewing organizations.
 */
const OrganizationList = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [page, setPage] = useState({ PageNumber: 1, PageSize: 25 });
  const [totalRecords, setTotalRecords] = useState(0);  

  useEffect(() => {
    /**
     * Fetches the organization profiles from the backend API.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getOrgProfiles = async () => {
      try {
        const response = await postData(
          `/api/organization/getOrgProfiles`,
          page
        );

         const updatedOrganizations = transformProfilePicture(
          response.organizations
        );
        setOrganizations((prevOrganizations) => [
          ...prevOrganizations,
          ...updatedOrganizations,
        ]);
        if (response.count != null) setTotalRecords(response.count);

        if (page.PageNumber === 1) setIsPageLoading(false);
        else setIsLoading(false);
      } catch (error) {
        if (page.PageNumber === 1) setIsPageLoading(false);
        else setIsLoading(false);
      }
    };
    getOrgProfiles();
  }, [page]);

  useEffect(() => {
    /**
     * Handles scrolling to load more organization profiles if available.
     */
    const handleScroll = () => {
      const isAtBottom =
        window.innerHeight + document.documentElement.scrollTop + 100 >=
        document.documentElement.offsetHeight;

      if (isAtBottom && !isLoading) {
        const totalPages = Math.ceil(totalRecords / page.PageSize);
        if (page.PageNumber < totalPages) {
          setIsLoading(true);
          // Increment the page number to load more profiles
          setPage((prevPage) => ({
            ...prevPage,
            PageNumber: prevPage.PageNumber + 1,
          }));
        }
      }
    };
    // Add event listener for scroll events
    window.addEventListener("scroll", handleScroll);
    // Remove event listener on cleanup to avoid memory leaks
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page, totalRecords, isLoading]);

  return (
    <Container className="organization-list-view">
      {organizations.length > 0 && (
        <Row className="align-items-center justify-content-center">
          <Col xs={12} className="text-center pt-4 pb-5 page-title">
            <span className="text-primary tag-line">PARTNERS</span>
            <h1 className="title mt-1 fw-medium">ORGANIZATIONS</h1>
          </Col>
          {organizations.map((organization) => (
            <Col
              xs={8}
              sm={6}
              md={6}
              lg={4}
              xl={3}
              xxl={3}
              key={organization.referenceId}
              className="grid-list"
            >
              <OrganizationCard organization={organization} />
            </Col>
          ))}
          {isLoading && (
            <Col xs={12} className="text-center my-5" aria-labelledby="Loading">
              <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          )}
        </Row>
      )}

      {!isPageLoading && Object.keys(organizations).length === 0 && (
        <Row className="justify-content-center text-center">
          <Col xs={10} sm={12} md={9} lg={7} xl={6} xxl={5}>
            <Card className="border-0 shadow p-4 rounded-4 text-center">
              <Card.Body>
                <Image src={noData} alt="No Data Found" fluid />
                <h4 className="title mt-3">No Data Found</h4>
                <p className="mb-0 fw-light text-dark-emphasis">
                  There are no organizations to show here right now.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default OrganizationList;
