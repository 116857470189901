import React from "react";
import { Outlet } from "react-router-dom";
import "../resources/scss/default-layout.scss";

/**
 * Component for rendering the default layout with the content provided by the routing outlet
 * @returns {React.Element} - Returns JSX for the default layout.
 */
function DefaultLayout() {
  return <Outlet />;
}

export default DefaultLayout;
