import React, { Fragment, useState, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Card,
  Dropdown,
  Button,
  ListGroup,
  Alert,
  Modal,
  Spinner,
  Image,
  Badge,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import DataTable from "../../components/table";

import { postData } from "../../services/apiService";
import { useAuthContext } from "../../context/authProvider";
import { decodeToken } from "../../utils/session";
import { notify, initialState } from "../../store/notification";
import { ValidatorProfileURL } from "../../constants";

import confirmation from "../../resources/images/confirmation.png";

/**
 * Component for managing validators.
 * @returns {React.Element} - Returns JSX for managing validators.
 * @access Accessible by SuperAdmin and Staff.
 */
const ManageValidators = () => {
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  // Decode the user session token to extract user information
  const user = decodeToken(userSession);

  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [page, setPage] = useState({ PageNumber: 1, PageSize: 25 });
  const [validators, setValidators] = useState([]);
  const [validator, setValidator] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const [toggleAdditionalDetailsModal, setToggleAdditionalDetailsModal] =
    useState(false);

  // Columns configuration for the validator table
  const columns = [
    {
      header: "Name",
      accessorKey: "name",
      cell: (cellProps) => {
        return (
          <Fragment>
            <Link
              to={`${ValidatorProfileURL}${cellProps.row.original.referenceId}`}
              className="text-decoration-none"
              title="Validator Profile"
            >
              {cellProps.row.original.firstName}{" "}
              {cellProps.row.original.lastName}
            </Link>
          </Fragment>
        );
      },
    },
    {
      header: "EmailAddress",
      accessorKey: "emailAddress",
    },
    {
      header: "Phone Number",
      accessorKey: "phoneNumber",
    },
    {
      header: "Organization",
      accessorKey: "organization",
      cell: (cellProps) => {
        return (
          <Fragment>
            <Link
              to={`/organization/profile/${cellProps.row.original.orgReferenceId}`}
              className="text-decoration-none"
              title="Organization Profile"
            >
              {cellProps.row.original.organization}
            </Link>
          </Fragment>
        );
      },
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: (cellProps) => {
        return (
          <Fragment>
            {cellProps.row.original.status === "Inactive" && (
              <Badge className="fw-semibold text-dark" bg="warning">
                INACTIVE
              </Badge>
            )}
            {cellProps.row.original.status === "Active" && (
              <Badge className="fw-semibold text-dark" bg="success">
                ACTIVE
              </Badge>
            )}
            {cellProps.row.original.status === "Deleted" && (
              <Badge className="fw-semibold text-dark" bg="danger">
                DELETED
              </Badge>
            )}
          </Fragment>
        );
      },
    },
    {
      header: "Actions",
      accessorKey: "referenceId",
      cell: (cellProps) => (
        <Dropdown drop="down" className="more-menu">
          <Dropdown.Toggle variant="light" size="sm">
            <i className="flaticon-more"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => showAdditionalDetails(cellProps.row.original)}
            >
              <i className="flaticon-invoice fs-4 me-2"></i>Additional Details
            </Dropdown.Item>
            <Dropdown.Item
              to={`/user/edit/${cellProps.row.original.userId}`}
              as={Link}
            >
              <i className="flaticon-edit fs-4 me-2"></i>Edit
            </Dropdown.Item>
            <Dropdown.Item
              to={`${ValidatorProfileURL}${cellProps.row.original.referenceId}`}
              as={Link}
            >
              <i className="flaticon-profile fs-4 me-2"></i>View Profile
            </Dropdown.Item>
            {cellProps.row.original.userId !== user.nameid && (
              <Fragment>
                {cellProps.row.original.status === "Active" && (
                  <Dropdown.Item
                    onClick={() =>
                      showConfirmation(
                        cellProps.row.original.validatorId,
                        "Deactivate",
                        "Inactive"
                      )
                    }
                  >
                    <i className="flaticon-block-user fs-4 me-2"></i>
                    Deactivate
                  </Dropdown.Item>
                )}
                {cellProps.row.original.status === "Inactive" && (
                  <Dropdown.Item
                    onClick={() =>
                      showConfirmation(
                        cellProps.row.original.validatorId,
                        "Activate",
                        "Active"
                      )
                    }
                  >
                    <i className="flaticon-approve-user fs-4 me-2"></i>
                    Activate
                  </Dropdown.Item>
                )}
                {cellProps.row.original.status !== "Delete" && (
                  <Dropdown.Item
                    onClick={() =>
                      showConfirmation(
                        cellProps.row.original.validatorId,
                        "Delete",
                        "Deleted"
                      )
                    }
                  >
                    <i className="flaticon-delete fs-4 me-2"></i>
                    Delete
                  </Dropdown.Item>
                )}
              </Fragment>
            )}
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];

  /**
   * Function to handle page change event.
   * @param {number} pageNumber - The new page number.
   * @param {number} pageSize - The new page size.
   */
  const handlePageChange = (pageNumber, pageSize) => {
    setValidators([]);
    setPage({
      PageNumber: pageNumber,
      PageSize: pageSize,
    });
  };

  /**
   * Function to handle search action.
   * @param {string} value - The search term entered by the user.
   */
  const handleSearch = (value) => {
    handlePageChange(1, page.PageSize);
    setSearchTerm(value);
  };

  /**
   * Function to display additional details for a validator using a modal.
   * @param {object} validator - The validator object for which additional details are to be displayed.
   */
  const showAdditionalDetails = (validator) => {
    setValidator(validator);
    setToggleAdditionalDetailsModal(true);
  };

  /**
   * Function to show a confirmation dialog for updating the validator status.
   * @param {string} id - The ID of the validator.
   * @param {string} action - The action to perform (e.g., "Delete", "Activate", "Deactivate").
   * @param {string} status - The status to set for the validator (e.g., "Active", "Inactive", "Deleted").
   */
  const showConfirmation = (id, action, status) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Card className="border-0 shadow rounded-3">
            <Card.Body className="p-4 text-center">
              <Image src={confirmation} alt="Status Confirmation" fluid />
              <div className="mt-3 fw-light">
                Do you really want to {action.toLowerCase()} this user? <br />
                {status === "Deleted" && "This process can't be undone."}
              </div>
            </Card.Body>
            <Card.Footer className="bg-transparent py-4 text-center">
              <Button
                title="Cancel"
                variant="link"
                className="link-danger fw-semibold"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                className="ms-4 btn-md"
                onClick={async () => {
                  try {
                    await handleUpdateStatus(id, status);
                  } catch (error) {
                    // showNotification("danger", error, 5000);
                  }
                  onClose();
                }}
              >
                Yes, {action}
              </Button>
            </Card.Footer>
          </Card>
        );
      },
    });
  };

  /**
   * Function to update the validator status.
   * @param {number} id - The ID of the validator.
   * @param {string} status - The status to set for the validator (e.g., "Active", "Inactive", "Deleted").
   */
  const handleUpdateStatus = async (id, status) => {
    setIsPageLoading(true);
    try {
      const response = await postData(
        `/api/validator/update-status/${id}/${status}`,
        null,
        userSession
      );
      setIsPageLoading(false);
      showNotification("success", response, 5000);
      setPage((prevPage) => ({
        ...prevPage,
        pageNumber: prevPage.pageNumber,
      }));
    } catch (error) {
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    /**
     * Fetches the validators from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getAll = async () => {
      setIsLoading(true);
      try {
        const response = await postData(
          `/api/validator/getByPage?searchTerm=${searchTerm}`,
          page,
          userSession
        );
        setValidators(response.validators);
        if (response.count != null) setTotalRecords(response.count);
        setIsLoading(false);
      } catch (error) {
        showNotification("danger", error, 5000);
        setValidators([]);
        setIsLoading(false);
      }
    };

    getAll();

    // Return a cleanup function
    return () => {};
  }, [searchTerm, page, userSession]);

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-semibold mb-0">Manage Validators</h6>
          <div>
            <Link
              to="/validator/create"
              className="ms-3 btn btn-secondary"
              title="Create Validator"
            >
              + Add Validator
            </Link>
            <Link
              to="/validator/invitations"
              className="ms-3 btn btn-primary"
              title="Invitations"
            >
              Invitations
            </Link>
          </div>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <DataTable
              columns={columns}
              data={validators}
              totalRecords={totalRecords}
              page={page}
              onPageChange={handlePageChange}
              searchRecords={handleSearch}
              loadingState={isLoading}
            />
          </Card.Body>
        </Card>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
      <Modal
        show={toggleAdditionalDetailsModal}
        aria-labelledby="Additional Details Modal"
        className="modal-additional-details"
        dialogClassName="modal-feature"
        centered
      >
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="fw-semibold mb-0">Additional Details</h6>
            <Button
              className="btn-close fs-small"
              variant="link"
              aria-label="Close"
              onClick={() => {
                setToggleAdditionalDetailsModal(false);
              }}
            ></Button>
          </div>
          <hr />
          <ListGroup as="ul" className="mt-4">
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-center bg-transparent p-3"
            >
              <span>Request Type:</span>
              <span>{validator.type}</span>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-center bg-transparent p-3"
            >
              <span>Suggested By:</span>
              <span>{validator.suggestedBy}</span>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-center bg-transparent p-3"
            >
              <span>Created On:</span>
              <span>{validator.createdOn}</span>
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default ManageValidators;
