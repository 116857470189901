import React, { Fragment } from "react";
import { Button } from "react-bootstrap";
import { useCookies } from "react-cookie";

import { logout, logoutRecipient } from "../../services/accountService";
import { useAuthContext } from "../../context/authProvider";
import { decodeToken } from "../../utils/session";

/**
 * Component for displaying buttons based on authentication status.
 * @returns {React.ReactElement} - Returns JSX for displaying authentication buttons.
 */
const ExploreAuthentication = () => {
  const [cookies, , removeCookie] = useCookies();
  /**
   * Destructuring assignment to extract necessary variables and functions from the useAuthContext hook.
   * - clearToken: Function to clear the JWT token from the client's browser.
   * - userSession: Session object containing user authentication information.
   * - isLoggedIn: Flag indicating whether the user is logged in or not.
   */
  const { clearToken, userSession, isLoggedIn } = useAuthContext();

  // Decode the user session token to extract user information
  const user = decodeToken(userSession);

  /**
   * Function to handle user logout.
   */
  const handleLogout = async () => {
    try {
      if (user.roles?.includes("Recipient")) await logoutRecipient(user.nameid);
      else {
        await logout();
        const cookieNames = Object.keys(cookies);
        cookieNames
          .filter((cookieName) => cookieName.startsWith("_benevolent"))
          .forEach((cookieName) =>
            removeCookie(cookieName, {
              path: "/",
              sameSite: "none",
              secure: true,
            })
          );
      }
      await clearToken();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="explore-authentication text-end">
      {!isLoggedIn && (
        <Fragment>
          <a
            className="btn fw-semibold text-dark rounded-pill fs-small btn-md btn-login"
            href="/"
            title="Login"
            target="_blank"
          >
            Login
          </a>
          <a
            className="btn btn-secondary rounded-pill ms-1 fs-small btn-md btn-register"
            href="/register"
            title="Register"
            target="_blank"
          >
            Register
          </a>
        </Fragment>
      )}
      {isLoggedIn && (
        <Fragment>
          <a
            className="btn fw-semibold text-dark rounded-pill fs-small btn-portal btn-md"
            href="/"
            title="Go to Portal"
            target="_blank"
          >
            Go to Portal
          </a>
          <Button
            variant="secondary"
            className="rounded-pill ms-1 fs-small btn-logout btn-md"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Fragment>
      )}
    </div>
  );
};

export default ExploreAuthentication;
