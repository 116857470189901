import React, { Fragment, useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { Image, Card, Spinner } from "react-bootstrap";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";

import { Swiper, SwiperSlide } from "../../components/swiper";
import { transformProfilePicture } from "../../components/transformFileURL";

import { postData } from "../../services/apiService";

import {
  NeedProfileURL,
  ValidatorProfileURL,
  OrgProfileURL,
} from "../../constants";

// responsiveHOC is a Higher Order Component (HOC) used for responsive behavior,
// and LinesEllipsis is a component for truncating text with ellipsis.
const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

/**
 * Component for displaying needs.
 * This component lists the needs based on the given type and coordinates.
 * @param {string} type - The type of needs to display.
 * @param {object} coordinates - The coordinates for user.
 * @returns {React.Element} - JSX for displaying needs to support.
 */
const NeedsToSupport = ({ type, coordinates }) => {
  const [loadMore, setLoadMore] = useState(true);
  const [needs, setNeeds] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isLocationAccessible, setIsLocationAccessible] = useState(null); // Indicates if user's location is accessible
  const [page, setPage] = useState({ PageNumber: 1, PageSize: 25 });
  const swiperRef = useRef(null); // Reference for the Swiper instance

  useEffect(() => {
    /**
     * Fetches needs from the backend API.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    if (type === "NearBy") {
      if (Object.keys(coordinates).length === 0) {
        // If coordinates are not available, set location accessibility to false
        setIsLocationAccessible(false);
        return;
      } else setIsLocationAccessible(true); // If coordinates are available, set location accessibility to true
    }
    const getNeeds = async () => {
      setIsPageLoading(true);
      try {
        const response = await postData(
          "/api/need/getNeedsByType/",
          {
            type,
            page,
            latitude: coordinates?.latitude,
            longitude: coordinates?.longitude,
          },
          null
        );
        // Transform the profile pictures in the response to appropriate sizes
        const updatedNeeds = transformProfilePicture(response.needs);
        // Determine if more needs can be loaded
        setLoadMore(updatedNeeds.length < page.PageSize ? false : true);
        setNeeds((prevNeeds) => [...prevNeeds, ...updatedNeeds]);
        setIsPageLoading(false);
        if (swiperRef.current) swiperRef.current.update(); // Update Swiper instance
      } catch (error) {
        setIsPageLoading(false);
        setNeeds([]);
      }
    };
    if (loadMore) getNeeds();
  }, [page, loadMore, coordinates, type]);

  /**
   * Function to navigate to the previous slide in the Swiper component.
   * If the Swiper reference exists, it slides to the previous slide.
   */
  const goToPreviousSlide = () => {
    if (swiperRef.current) swiperRef.current.slidePrev();
  };

  /**
   * Function to navigate to the next slide in the Swiper component.
   * If the Swiper reference exists, it slides to the next slide.
   * If the current slide is the last slide or the end of the Swiper, it updates the page number.
   */
  const goToNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
      const { isEnd, activeIndex, slides } = swiperRef.current;
      if (isEnd || activeIndex === slides.length - 1) {
        setPage((prevPageStates) => ({
          ...prevPageStates,
          PageNumber: prevPageStates.PageNumber + 1,
        }));
      }
    }
  };

  /**
   * Function to compute the height of the widget
   */
  const computeWidgetHeight = useCallback(() => {
    // Wait for elements with the specified class name to be rendered
    // Get the height of the widget content
    // const tab = document.getElementsByClassName("explore-active-needs");
    // const tabHeight = computeElementHeight(tab);
    const tabContainer = document.getElementsByClassName(
      "explore-active-needs-container"
    );
    const tabContainerHeight = computeElementHeight(tabContainer);
    // const activeNeedsWidgetHeight = tabHeight + tabContainerHeight + 10;
    const activeNeedsWidgetHeight = tabContainerHeight + 10;

    // Send the height to the parent window
    window.parent.postMessage({ activeNeedsWidgetHeight }, "*");
  }, []);

  useEffect(() => {
    /**
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    computeWidgetHeight();
    // Event listener for window resize
    window.addEventListener("resize", computeWidgetHeight);
    return () => {
      // Cleanup logic
      // You can add more cleanup actions here if needed
      window.removeEventListener("resize", computeWidgetHeight);
    };
  }, [needs, computeWidgetHeight]);


  /**
   * Function to compute the total height of an element including padding, margin, and borders
   */
  const computeElementHeight = (element) => {
    // Calculate total height including padding, margin, and borders
    let totalHeight = 0;
    if (element.length > 0) {
      const computedStyles = window.getComputedStyle(element[0]);
      const marginTop = parseFloat(computedStyles.marginTop);
      const marginBottom = parseFloat(computedStyles.marginBottom);
      const paddingTop = parseFloat(computedStyles.paddingTop);
      const paddingBottom = parseFloat(computedStyles.paddingBottom);
      const borderTopWidth = parseFloat(computedStyles.borderTopWidth);
      const borderBottomWidth = parseFloat(computedStyles.borderBottomWidth);

      totalHeight =
        element[0].offsetHeight +
        marginTop +
        marginBottom +
        paddingTop +
        paddingBottom +
        borderTopWidth +
        borderBottomWidth;
    }
    return totalHeight;
  };

  return (
    <Fragment>
      <Swiper
        loop={false}
        centerInsufficientSlides={true}
        slidesPerView={"auto"}
        spaceBetween={15}
        on={{
          init: (swiper) => {
            swiperRef.current = swiper;
          },
        }}
      >
        {needs.map((need) => (
          <SwiperSlide key={need.referenceId} gridClass="swiper-slide-grid">
            <Card className="border-0 shadow-sm rounded-4 h-100 need">
              <Card.Body>
                <Link
                  to={`${NeedProfileURL}${need.referenceId}/${need.needId}`}
                  title="Need Profile"
                  className="profile-pic"
                  target="_blank"
                >
                  <Image
                    src={need.profilePicture}
                    alt="Profile Picture"
                    fluid
                    className="rounded-circle"
                  ></Image>
                </Link>
                <p className="mt-5 pt-2 mb-2 fs-small location">
                  <Link
                    to={`${NeedProfileURL}${need.referenceId}/${need.needId}`}
                    className="text-decoration-none text-secondary fw-semibold"
                    title="Need Profile"
                    target="_blank"
                  >
                    {need.displayName}
                  </Link>
                  <span className="text-secondary fw-light">
                    {" "}
                    from {need.city}, {need.country}
                  </span>
                </p>
                <h5 className="title mb-3">
                  <Link
                    to={`${NeedProfileURL}${need.referenceId}/${need.needId}`}
                    className="text-decoration-none link-dark"
                    target="_blank"
                    title="Need Profile"
                  >
                    <ResponsiveEllipsis
                      text={need.title}
                      maxLine="3"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                  </Link>
                </h5>
                <p className="mb-2">
                  <span className="fw-bold fs-5">${need.raised}</span>
                  <span className="text-dark fs-6">
                    {" "}
                    of ${need.amountNeeded}
                  </span>
                </p>
                <div
                  className={`progress ${need.needStatus === "Funded" &&
                    need.percentageCompleted < 100
                    ? "bg-secondary-subtle"
                    : ""
                    }`}
                  role="progressbar"
                  aria-label="Percentage of Need Funded"
                >
                  <div
                    className={`progress-bar ${need.percentageCompleted > 100
                      ? "bg-primary"
                      : "bg-secondary"
                      }`}
                    style={{ width: `${need.percentageCompleted}%` }}
                  />
                </div>
                <p className="d-flex justify-content-between mt-2">
                  <small className="text-dark fw-light">
                    <span className="text-secondary fw-semibold">
                      {need.totalSupporters}
                    </span>{" "}
                    Supporters
                  </small>
                  <small className="text-dark fw-light">
                    {need.needStatus !== "Funded" && (
                      <span className="text-secondary fw-semibold">
                        {need.daysLeft}
                      </span>
                    )}
                    {need.needStatus === "Funded"
                      ? "Funded On: "
                      : " Days Left"}
                    {need.needStatus === "Funded" && (
                      <span className="text-secondary fw-semibold">
                        {need.fundedOn}
                      </span>
                    )}
                  </small>
                </p>
                <p className="fs-small text-dark-emphasis mb-2">
                  Validated by{" "}
                  <Link
                    to={`${ValidatorProfileURL}${need.validatorReferenceId}`}
                    title="Validator Profile"
                    className="link-dark fw-semibold text-decoration-none"
                    target="_blank"
                  >
                    {need.validatorName}
                  </Link>{" "}
                  from{" "}
                  <Link
                    to={`${OrgProfileURL}${need.organizationReferenceId}`}
                    title="Organization Profile"
                    className="link-dark fw-semibold text-decoration-none"
                    target="_blank"
                  >
                    {need.organizationName}
                  </Link>
                </p>
              </Card.Body>
              <Card.Footer className="bg-transparent border-0 pb-3 pt-0">
                <Link
                  to={`${NeedProfileURL}${need.referenceId}/${need.needId}`}
                  className="btn btn-primary btn-md"
                  target="_blank"
                >
                  Support
                </Link>
              </Card.Footer>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className={`text-center ${needs.length > 0 ? "d-block" : "d-none"}`}>
        <div
          className="swiper-button-prev me-3 border-secondary text-secondary"
          onClick={goToPreviousSlide}
        >
          <i className="flaticon-line-arrow-left fs-4"></i>
        </div>
        <div
          className="swiper-button-next border-secondary text-secondary"
          onClick={goToNextSlide}
        >
          <i className="flaticon-line-arrow-right fs-4"></i>
        </div>
      </div>
      {type !== "NearBy" ? (
        <Fragment>
          {needs.length === 0 && !isPageLoading && (
            <p className="text-light-emphasis my-4 text-center">
              Currently, there are no matches for your criteria.
            </p>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {!isLocationAccessible && isLocationAccessible !== null && (
            <p className="text-light-emphasis my-4 text-center">
              Your current location is unavailable. To view nearby needs, please{" "}
              <br className="d-none d-sm-inline-block" />
              grant permission for location access.
            </p>
          )}
          {isLocationAccessible && needs.length === 0 && (
            <p className="text-light-emphasis my-4 text-center">
              Currently, there are no matches for your criteria.
            </p>
          )}
        </Fragment>
      )}
      <div
        className={`modal-backdrop loading-indicator-widget ${isPageLoading || "d-none"
          }`}
        aria-labelledby="Loading"
      >
        <div className="d-flex align-items-center justify-content-center vh-100">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </div>
    </Fragment>
  );
};

export default NeedsToSupport;
