import React, { Fragment, useEffect, useState } from "react";
import { Image, Card, Modal, Spinner } from "react-bootstrap";

import { getData } from "../../../services/apiService";
import noData from "../../../resources/images/no-data.png";

/**
 * Component for displaying a list of recent donations.
 * @param {object} props - The props containing the needId. The ID of the need for which recent donations are displayed.
 * @returns {React.Element} - JSX for displaying recent donations.
 */
const RecentDonations = (props) => {
  const [donations, setDonations] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);

  useEffect(() => {
    /**
     * Fetches recent donation amounts from the backend API.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    
    const getRecentDonationAmounts = async () => {
      try {
        const response = await getData(
          `/api/payment/getRecentDonationAmounts/${props.needId}`,
          null,
          null
        );
        setDonations(response);
        setIsPageLoading(false);
      } catch (error) {
        setDonations([]);
        setIsPageLoading(false);
      }
    };
    if (props.needId > 0 && props.needStatus === "Published"){
      setIsPageLoading(true);
      getRecentDonationAmounts();
    }
  }, [props]);

  return (
    <Fragment>
      <Card className="bg-tertiary border-0">
        <Card.Body>
          <h4 className="title">Recent Donations</h4>
          <div>
            {donations.length > 0 ? (
              donations.map((donation, index) => (
                <span
                  key={index}
                  className="recent-donation shadow-sm bg-white"
                >
                  {`$${donation}`}
                </span>
              ))
            ) : (
              <div className="text-center">
                <Image
                  src={noData}
                  alt="No Data Found"
                  fluid
                  className="my-3"
                />
                <small className="d-block">
                  No recent donation activity found.
                </small>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
export default RecentDonations;
