import React, { useState, useReducer } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  FloatingLabel,
  Button,
  Alert,
  Spinner,
} from "react-bootstrap";
import { useForm } from "react-hook-form";

import { postData } from "../../services/apiService";
import { useAuthContext } from "../../context/authProvider";
import { decodeToken } from "../../utils/session";
import { notify, initialState } from "../../store/notification";

/**
 * Component for sending a note from a donor to a validator regarding the recipient.
 * @returns {React.Element} - Returns JSX for sending a note.
 */

const SendNote = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  // Extract the need ID from the URL parameters
  // to identify the specific need for which the donor is sending a note
  const { needId } = useParams();
  const { state } = useLocation();
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  // Decodes the user session token to extract user information.
  const user = decodeToken(userSession);
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);

  const [need] = useState({
    displayName: state?.displayName,
    validatorName: state?.validatorName,
  });
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Function to reset the form fields to their initial values.
   */
  const resetForm = () => {
    reset({
      subject: "",
      body: "",
    });
  };

  /**
   * Function to send a note via the backend API.
   * @param {object} data - The form data containing the note and other details.
   */
  const onSubmit = async (data) => {
    if (needId === 0) return;
    setIsLoading(true);
    const updatedData = {
      ...data,
      needId: needId,
      donorName: user?.sub,
      validatorName: need.validatorName,
    };
    try {
      const response = await postData(
        `/api/donor/send-note`,
        updatedData,
        userSession
      );
      setIsLoading(false);
      resetForm();
      showNotification("success", response, 5000);
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setIsLoading(false);
    }
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-bold mb-0">
            Send a Note to {need.displayName}
          </h6>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <Row>
              <Col xs={12} md={6}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group className="mb-4">
                    <FloatingLabel controlId="txtSubject" label="Subject">
                      <Form.Control
                        type="text"
                        placeholder="Subject"
                        {...register("subject", {
                          required: "Subject is required",
                          maxLength: {
                            value: 250,
                            message: "Subject cannot exceed 250 characters",
                          },
                        })}
                      />
                    </FloatingLabel>
                    {errors.subject && (
                      <Form.Text className="text-danger">
                        {errors.subject.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <FloatingLabel
                      controlId="txtBody"
                      label="Share a Recipient Update"
                    >
                      <Form.Control
                        as="textarea"
                        className="scroll"
                        placeholder="Share a Recipient Update"
                        {...register("body", {
                          required: "Message is required",
                          maxLength: {
                            value: 3000,
                            message: "Message cannot exceed 3000 characters",
                          },
                        })}
                      />
                    </FloatingLabel>
                    {errors.body && (
                      <Form.Text className="text-danger">
                        {errors.body.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Col xs={12}>
                    <Button
                      variant="primary"
                      disabled={isLoading}
                      type="submit"
                      className="btn-md"
                    >
                      {isLoading ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                    <Button
                      variant="secondary"
                      type="button"
                      className="ms-3 btn-md"
                      onClick={resetForm}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Form>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
    </main>
  );
};

export default SendNote;
