import React, { useEffect, useState } from "react";
import {
  Form,
  InputGroup,
  FloatingLabel,
  Button,
  Modal,
  Spinner,
} from "react-bootstrap";
import { useForm } from "react-hook-form";

import { postData } from "../services/apiService";
import { useAuthContext } from "../context/authProvider";
import { decodeToken } from "../utils/session";

/**
 * Component for exporting data.
 * @param {Object} props - Component props
 * @param {string} props.featureName - The name of the feature being exported
 * @param {string} props.orgReferenceId - The reference ID of the organization
 * @param {boolean} props.showExportModal - Indicates whether the export modal is shown
 * @param {Function} props.closeExportModal - Function to close the export modal
 * @param {Function} props.showNotification - Function to show notifications
 * @returns {React.Element} - Returns JSX for exporting data
 */
const Export = ({
  featureName,
  searchParams,
  orgReferenceId,
  showExportModal,
  closeExportModal,
  showNotification,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  // Decode the user session token
  const user = decodeToken(userSession);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    reset({
      userId: user.nameid,
      featureName: featureName,
      orgReferenceId: orgReferenceId,
      searchParams: searchParams,
    });
  }, [searchParams, user.nameid, featureName, orgReferenceId, reset]);

  /**
   * Function to handle the form submission for exporting data.
   * @param {Object} data - The form data containing the export parameters.
   */
  const onSubmit = async (data) => {
    data.fileType = ["csv"];
    setIsLoading(true);
    try {
      const response = await postData(
        "/job-api/export/generate",
        data,
        userSession
      );
      setIsLoading(false);
      closeExportModal();
      reset();
      showNotification("success", response, 5000);
    } catch (error) {
      showNotification("danger", error, 5000);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={showExportModal}
      aria-labelledby="Export Modal"
      centered
      dialogClassName="modal-feature"
      size="sm"
    >
      <Modal.Header>
        <Modal.Title as="h6" className="fw-bold m-auto module-title">
          Export
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-4 text-dark-emphasis">
          Please note that exporting larger files may require some time. You can
          monitor the progress of file generation and download the file when
          it's completed by clicking on the Download status icon in the upper
          right corner.
        </p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Control type="hidden" {...register("userId")} />
          <Form.Control type="hidden" {...register("featureName")} />
          <Form.Control type="hidden" {...register("orgReferenceId")} />
          <Form.Control type="hidden" {...register("searchParams")} />
          <Form.Group className="mb-4">
            <InputGroup>
              <FloatingLabel
                label="File Name"
                controlId="txtFileName"
                htmlFor="txtFileName"
              >
                <Form.Control
                  type="text"
                  defaultValue=""
                  placeholder="File Name"
                  {...register("fileName", {
                    required: "File name is required",
                    maxLength: {
                      value: 100,
                      message: "File name cannot exceed 100 characters",
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9\s]*$/,
                      message:
                        "File names must not contain special characters.",
                    },
                  })}
                />
              </FloatingLabel>
              <InputGroup.Text>
                <i className="flaticon-invoice fs-4"></i>
              </InputGroup.Text>
            </InputGroup>
            {errors.fileName && (
              <Form.Text className="text-danger">
                {errors.fileName.message}
              </Form.Text>
            )}
          </Form.Group>
          <div className="text-end mt-5">
            <Button
              type="submit"
              variant="primary"
              className="btn-md me-2"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Export"
              )}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                reset();
                closeExportModal();
              }}
              className="btn-md"
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Export;
