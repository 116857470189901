import React from "react";
import { Link } from "react-router-dom";
import { Container, Image } from "react-bootstrap";

import error from "../../resources/images/bad-request.png";

/**
 * Component for displaying a bad request error message.
 * This component informs the user that their request resulted in a bad request error.
 * @returns {React.Element} - Returns JSX for displaying the bad request error message.
 */
const BadRequest = () => {
  return (
    <main className="d-flex align-items-center justify-content-center min-vh-100 flex-column bg-white">
      <Container className="text-center unauthorized">
        <Image src={error} alt="Unauthorized" fluid />
        <h3 className="text-secondary fw-semibold mb-2 mt-4">Bad Request</h3>
        <p className="mb-2">
          The requested feature is not enabled in this portal.
        </p>
        <p className="mb-2">Your request resulted in an error.</p>
        <Link
          to="/login"
          title="Login"
          className="btn btn-secondary btn-md fw-semibold mt-3"
        >
          RETURN HOME
        </Link>
      </Container>
    </main>
  );
};

export default BadRequest;
