import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, Image, Spinner } from "react-bootstrap";

import { confirmEmail } from "../../services/accountService";
import { SupportTeamEmail, SITEURL } from "../../constants";

import logo from "../../resources/images/logo.svg";
import emailVerified from "../../resources/images/email-verified.png";

/**
 * Component for validator's email confirmation.
 * This component verifies validator's email with the provided code.
 * Displays different messages based on the verification status of the email.
 * @returns {React.Element} - Returns JSX for email confirmation.
 */
const PartnerEmailConfirmation = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(null);

  useEffect(() => {
    // Get the query string from the current URL
    const queryParams = new URLSearchParams(window.location.search);

    /**
     * Function to verify validator's email from the backend API
     * with the query parameters on component mount
     */
    const verifyAccount = async (code) => {
      try {
        await confirmEmail(`/api/invitation/confirm-email/?code=${code}`);
        setIsLoading(false);
        setIsVerified(true);
      } catch (error) {
        setIsLoading(false);
        setIsVerified(false);
      }
    };
    verifyAccount(queryParams.get("code"));
  }, []);

  return (
    <main className="d-flex align-items-center justify-content-center min-vh-100 flex-column">
      <Container>
        <Row className="justify-content-center">
          <Col xs={11} sm={11} md={9} lg={9} xl={6} xxl={5}>
            <div className="text-center mb-4">
              <Link to={SITEURL} title="Benevolent">
                <Image src={logo} alt="Benevolent" className="logo" fluid />
              </Link>
            </div>
            <Card className="border-0 shadow p-4 rounded-4 text-center">
              <Card.Body>
                <Image src={emailVerified} alt="Email Confirmation" fluid />
                {isVerified && (
                  <Fragment>
                    <h4 className="title mt-4"> Email Verified </h4>
                    <p className="mb-5 fw-light text-dark-emphasis">
                      Your email has been successfully verified. The initial
                      step towards becoming a partner has been successfully
                      fulfilled. Benevolent will now evaluate your application
                      and inform you once your partnership status is confirmed.
                      This evaluation process might take around 5 days to
                      complete.
                    </p>
                    <hr />
                    <small className="text-dark-emphasis fw-light">
                      Don't hesitate to{" "}
                      <Link
                        title="Login"
                        to="/"
                        className="text-decoration-none link-secondary fw-bold"
                      >
                        get in touch
                      </Link>{" "}
                      with us. We're here and ready to provide the assistance
                      you need.
                    </small>
                  </Fragment>
                )}
                {!isVerified && !isLoading && (
                  <Fragment>
                    <h4 className="title mt-4">Verification Failed</h4>
                    <p className="mb-5 fw-light text-dark-emphasis">
                      Something went wrong. Verification process <br /> couldn't
                      be completed.
                    </p>
                    <hr />
                    <small className="text-dark-emphasis fw-light">
                      To verify your account, contact our support team at{" "}
                      <Link
                        title="Support"
                        to={`mailto:${SupportTeamEmail}`}
                        className="text-decoration-none link-secondary fw-bold"
                      >
                        {SupportTeamEmail}
                      </Link>
                      .
                    </small>
                  </Fragment>
                )}
                {isLoading && (
                  <div className="mt-4">
                    <Spinner animation="border" role="status" variant="primary">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <p className="mb-0 mt-3 text-dark-emphasis fw-light">
                      Please wait while your account is being verified...
                    </p>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default PartnerEmailConfirmation;
