import React, {
  Fragment,
  useState,
  useEffect,
  useReducer,
  useRef,
  useCallback,
} from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  InputGroup,
  Form,
  Card,
  Accordion,
  useAccordionButton,
  Button,
  Alert,
  Modal,
  Spinner,
  CloseButton,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import key from "weak-key";

import Fundraising from "../profile/widgets/_fundraising";
import Export from "../../components/export";

import { getData, postData } from "../../services/apiService";
import { useAuthContext } from "../../context/authProvider";
import { notify, initialState } from "../../store/notification";
import { NeedProfileURL } from "../../constants";
import confirmation from "../../resources/images/confirmation.png";
import noData from "../../resources/images/no-data.png";

/**
 * Component for managing reallocation of donations.
 * This component displays an interface for reallocating donations from one need to another.
 * @returns {React.Element} - Returns JSX for managing reallocation.
 * @access Accessible by SuperAdmin and Staff.
 */
const Reallocation = () => {
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);

  const [isPageLoading, setIsPageLoading] = useState(false);
  // State variable to determine whether a data reload from the API is needed
  const [isReload, setIsReload] = useState(false);
  const [expiredNeeds, setExpiredNeeds] = useState([]);
  const [needs, setNeeds] = useState([]);
  const [selectedNeeds, setSelectedNeeds] = useState({});
  const [reallocations, setReallocations] = useState([]);
  const [targetNeedId, setTargetNeedId] = useState(null);
  const [amountsToBeAllocated, setAmountsToBeAllocated] = useState(0);
  const [amountsAllocated, setAmountsAllocated] = useState(0);

  // Create a reference to track the currently dragged item
  const draggedItem = useRef();

  // State and functions for controlling the Export Modal
  const [showExportModal, setShowExportModal] = useState(false);
  const closeExportModal = () => setShowExportModal(false);
  const openExportModal = () => setShowExportModal(true);

  /**
   * Fetches a list of needs based on the specified type and the order by which to sort the results.
   * @param {string} type - The type of needs to fetch (e.g., "sourceNeeds" or "targetNeeds").
   * @param {string} orderBy - The criteria by which to order the needs by (e.g., "MostNeeded", "LeastNeeded", "EndingSoon").
   */
  const getNeeds = useCallback(
    async (type, orderBy) => {
      setIsPageLoading(true);
      try {
        const response = await getData(
          `/api/need/getNeedsByPriority/${type}/${orderBy}`,
          null,
          userSession
        );

        if (type === "SourceNeeds") {
          // Set expired needs with raised amount greater than 0
          setExpiredNeeds(response);
        } else setNeeds(response);
        setIsPageLoading(false);
      } catch (error) {
        setExpiredNeeds([]);
        setIsPageLoading(false);
      }
    },
    [userSession]
  );

  /**
   * Function to fetch donors for a specific need.
   * @param {string} needId - The ID of the need to fetch donors for.
   */
  const getDonors = async (needId) => {
    setIsPageLoading(true);
    try {
      const response = await getData(
        `/api/payment/getDonors/${needId}`,
        null,
        userSession
      );

      // Check if response contains data
      if (response.length > 0) {
        // Update expired needs array with response data for the specified needId
        const updatedExpiredNeeds = expiredNeeds.map((item) => {
          if (item.needId === needId) {
            // Merge response data with the corresponding expired need item
            return { ...item, donors: response };
          }
          return item;
        });
        setExpiredNeeds(updatedExpiredNeeds);
        // Calculate amounts to be allocated based on the updated donors data
        computeAmountsToBeAllocated(response, true);
      }
      setIsPageLoading(false);
    } catch (error) {
      setIsPageLoading(false);
    }
  };

  /**
   * Function to update the status of a need to "Closed".
   * @param {string} needId - The ID of the need to update.
   */
  const updateStatus = async (needId) => {
    setIsPageLoading(true);
    try {
      const response = await postData(
        `/api/need/update-status`,
        {
          id: needId,
          status: "Closed",
        },
        userSession
      );
      setNeeds(needs.filter((x) => x.needId !== needId));
      // To determine whether a needs reload from the API
      setIsReload(true);
      setIsPageLoading(false);
      showNotification("success", response, 5000);
    } catch (error) {
      // Show a  notification if an error occurs during the status update
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  /**
   * Function to save reallocations if there are any.
   */
  const saveReallocations = async () => {
    if (reallocations.length > 0) {
      setIsPageLoading(true);
      try {
        const response = await postData(
          `/api/payment/reallocate-transaction`,
          reallocations,
          userSession
        );
        showNotification("success", response, 5000);
        setIsPageLoading(false);
      } catch (error) {
        // Show a notification if an error occurs during saving reallocations
        showNotification("danger", error, 5000);
        setIsPageLoading(false);
      }
    } else {
      // Show a notification if there are no reallocations to save
      showNotification(
        "danger",
        "Something went wrong. Please refresh and try again or contact administrator.",
        5000
      );
    }
  };

  // useEffect to fetch needs when the component mounts or when 'isReload' changes
  useEffect(() => {
    getNeeds("SourceNeeds", "EndingSoon");
    getNeeds("TargetNeeds", "EndingSoon");
  }, [isReload, getNeeds]);

  /**
   * Handler for dropdown selection change event.
   * @param {Event} event - The dropdown selection change event object.
   */
  const handleFilterChange = (event) => {
    setSelectedNeeds([]);
    getNeeds("TargetNeeds", event.target.value);
  };

  /**
   * Function to compute the total amounts to be allocated.
   * @param {Array} donors - The array of donors containing net amounts.
   * @param {boolean} hasToAdd - Indicates whether to add or subtract from the existing total allocation.
   */
  const computeAmountsToBeAllocated = (donors, hasToAdd) => {
    let sum = 0;

    // Calculate the total net amount by summing up all donors' donation amounts
    donors.forEach((item) => {
      sum += item.donationAmount;
    });

    hasToAdd
      ? setAmountsToBeAllocated(amountsToBeAllocated + sum)
      : setAmountsToBeAllocated(amountsToBeAllocated - sum);
  };

  /**
   * Custom toggle component for an accordion.
   * @param {Object} props - The component props.
   * @param {string} props.children - The child elements of the component.
   * @param {string} props.eventKey - The event key of the accordion item.
   */
  const CustomToggle = ({ children, eventKey }) => {
    // Find the index of the need in the 'expiredNeeds' array
    const index = expiredNeeds.findIndex((x) => x.needId === eventKey);

    // Get the toggle function from useAccordionButton hook
    const toggleAccordion = useAccordionButton(eventKey, async () => {
      // Check if the need has donors, if not, fetch donors
      if (!expiredNeeds[index].hasOwnProperty("donors")) getDonors(eventKey);
    });

    /**
     * Handler for checkbox selection change event.
     * @param {Event} event - The change event object.
     */
    const handleNeedSelection = (event) => {
      const { name, checked } = event.target;
      // Update the selected needs state
      setSelectedNeeds({ ...selectedNeeds, [name]: checked });
      // If the need has donors, compute amounts to be allocated
      if (expiredNeeds[index].hasOwnProperty("donors"))
        computeAmountsToBeAllocated(expiredNeeds[index].donors, checked);
    };

    return (
      <Fragment>
        <Form.Check
          type="checkbox"
          name={`chk_${eventKey}`}
          checked={selectedNeeds[`chk_${eventKey}`]}
          className="me-2"
          aria-label="Toggle Accordion"
          onClick={toggleAccordion}
          onChange={handleNeedSelection}
        />
        {children}
      </Fragment>
    );
  };

  /**
   * Allocate a donation from a source need to a target need.
   * @param {string} needId - The ID of the source need.
   */
  const allocateDonationFromSourceToTarget = async (needId) => {
    // If the user drags the need but doesn't drop it, the targetNeedId is not present.
    // this condition prevents further processing and returns an error.
    if (!targetNeedId) return;
    // If the needId matches the targetNeedId, exit the function
    if (needId === targetNeedId) return;

    // Find the source need and its corresponding donor
    const draggedNeed = expiredNeeds.find((x) => x.needId === needId);
    const draggedDonor = draggedNeed.donors.splice(draggedItem.current, 1)[0];

    // Find the index of the target need in the 'needs' array
    const targetNeedIndex = needs.findIndex((x) => x.needId === targetNeedId);
    const targetNeed = needs.find((x) => x.needId === targetNeedId);

    // Update the target need with the dragged donor
    const updatedTargetNeed = {
      ...targetNeed,
      donors:
        "donors" in targetNeed
          ? [...targetNeed.donors, draggedDonor]
          : [draggedDonor],
      raised: targetNeed.raised + draggedDonor.donationAmount,
    };

    // Update the 'needs' state with the updated target need
    setNeeds((prevNeeds) => {
      const clonedNeeds = [...prevNeeds];
      clonedNeeds[targetNeedIndex] = updatedTargetNeed;
      return clonedNeeds;
    });

    // Add a new reallocation entry to the 'reallocations' state
    setReallocations((prevReallocations) => [
      ...prevReallocations,
      {
        sourceNeedId: needId,
        targetNeedId: targetNeedId,
        allocatedAmount: draggedDonor.donationAmount,
        transactionAllocationId: draggedDonor.transactionAllocationId,
        targetOrgId: targetNeed.organizationId,
      },
    ]);

    // Update amountsAllocated state by adding the net amount of the dragged donor
    setAmountsAllocated(amountsAllocated + draggedDonor.donationAmount);
    // Update amountsToBeAllocated state by subtracting the net amount of the dragged donor
    setAmountsToBeAllocated(amountsToBeAllocated - draggedDonor.donationAmount);

    // Reset the draggedItem ref
    draggedItem.current = null;
  };

  /**
   * Function to allocate donation from target to source.
   * @param {string} needId - The ID of the target need.
   */
  const allocateDonationFromTargetToSource = (needId) => {
    // Find the index and the details of the dragged need
    const draggedNeedIndex = needs.findIndex((x) => x.needId === needId);
    const draggedNeed = needs.find((x) => x.needId === needId);

    // Remove the dragged donor from the dragged need's donors list
    const draggedDonor = draggedNeed.donors.splice(draggedItem.current, 1)[0];

    // Find the index and details of the target need in the 'expiredNeeds' state
    const targetNeedIndex = expiredNeeds.findIndex(
      (x) => x.needId === draggedDonor.needId
    );
    const targetNeed = expiredNeeds.find(
      (x) => x.needId === draggedDonor.needId
    );

    // Update the target need's donors list with the dragged donor
    const updatedTargetNeed = {
      ...targetNeed,
      donors:
        "donors" in targetNeed
          ? [...targetNeed.donors, draggedDonor]
          : [draggedDonor],
    };

    // Update the 'expiredNeeds' state with the updated target need
    setExpiredNeeds((prevNeeds) => {
      const clonedNeeds = [...prevNeeds];
      clonedNeeds[targetNeedIndex] = updatedTargetNeed;
      return clonedNeeds;
    });

    // Update the source need by deducting the allocated amount
    const updatedSourceNeed = {
      ...draggedNeed,
      raised: draggedNeed.raised - draggedDonor.donationAmount,
    };

    // Update the needs state with the updated source need
    setNeeds((prevNeeds) => {
      const clonedNeeds = [...prevNeeds];
      clonedNeeds[draggedNeedIndex] = updatedSourceNeed;
      return clonedNeeds;
    });

    // Remove the allocation from the reallocations list
    setReallocations((allocations) =>
      allocations.filter(
        (x) =>
          x.transactionAllocationId !== draggedDonor.transactionAllocationId
      )
    );

    // Update amountsAllocated and amountsToBeAllocated states
    setAmountsAllocated(amountsAllocated - draggedDonor.donationAmount);
    setAmountsToBeAllocated(amountsToBeAllocated + draggedDonor.donationAmount);

    // Reset the dragged item reference
    draggedItem.current = null;
  };

  /**
   * Function to display a confirmation dialog for closing a need.
   * @param {string} needId - The ID of the need to be closed.
   */
  const showConfirmation = (needId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Card className="border-0 shadow rounded-3">
            <Card.Body className="p-4 text-center">
              <Image src={confirmation} alt="Delete Confirmation" fluid />
              <div className="mt-3 fw-light">
                Do you really want to close this need? <br />
                This process can't be undone.
              </div>
            </Card.Body>
            <Card.Footer className="bg-transparent py-4 text-center">
              <Button
                title="Cancel"
                variant="link"
                className="link-danger fw-semibold"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                className="ms-4 btn-md"
                onClick={async () => {
                  try {
                    await updateStatus(needId);
                  } catch (error) {
                    // showNotification("danger", error, 5000);
                  }
                  onClose();
                }}
              >
                Yes, Close Need
              </Button>
            </Card.Footer>
          </Card>
        );
      },
    });
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <Container fluid>
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="module-title fw-semibold mb-0">Reallocations</h6>
        <div className="chart-legend mt-1">
          <span className="d-inline-block published"></span>
          <small className="fw-light text-light-emphasis align-text-top">
            Active
          </small>
          <span className="d-inline-block ms-3 expired"></span>
          <small className="fw-light text-light-emphasis align-text-top">
            Expired
          </small>
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div>
          <span className="text-secondary fw-semibold">
            Amounts to be allocated: <span>${amountsToBeAllocated}</span>
          </span>
          <span className="text-secondary fw-semibold ms-3">
            Amounts allocated: <span>${amountsAllocated}</span>
          </span>
        </div>
        <div className="d-flex">
          <InputGroup size="sm" className="me-3">
            <InputGroup.Text className="bg-transparent py-0">
              <i className="flaticon-need fs-4 text-secondary"></i>
            </InputGroup.Text>
            <Form.Select
              className="bg-transparent"
              onChange={handleFilterChange}
              defaultValue="EndingSoon"
            >
              <option value="MostNeeded">Most Needed</option>
              <option value="LeastNeeded">Least Needed</option>
              <option value="EndingSoon">Ending Soon</option>
            </Form.Select>
          </InputGroup>
          <Button variant="primary" onClick={openExportModal}>
            <i className="flaticon-downloads me-2"></i>Export
          </Button>
        </div>
      </div>
      {needs.length > 0 && (
        <Row className="reallocation">
          <Col xs={12} lg={4} className="expired-needs mb-3">
            <div
              className={`sticky-top rounded-4 p-4 ${
                expiredNeeds.length > 0 ? "border" : ""
              }`}
            >
              {/* Map over expiredNeeds array to render each need. By clicking on each need will reveal a list of donors associated with that particular need. */}
              <Accordion alwaysOpen>
                {expiredNeeds.map((need) => (
                  <Card
                    key={key(need)}
                    bg="transparent"
                    className="mb-3 border-0 rounded-0"
                  >
                    <div className="d-flex">
                      <CustomToggle eventKey={need.needId}>
                        <div className="text-dark-emphasis fw-light fs-small">
                          <span className="fw-semibold text-black">
                            ${need.raised}:
                          </span>{" "}
                          [{need.needId}] {need.title}
                        </div>
                      </CustomToggle>
                    </div>
                    <Accordion.Collapse eventKey={need.needId} className="pt-3">
                      <Fragment>
                        {need?.donors?.map((donor, index) => (
                          <div
                            key={key(donor)}
                            draggable
                            className="tag bg-white fw-light text-dark-emphasis"
                            onDragStart={(e) => (draggedItem.current = index)}
                            onDragEnd={() =>
                              allocateDonationFromSourceToTarget(need?.needId)
                            }
                          >
                            <span className="fw-semibold">
                              ${donor.donationAmount}
                            </span>{" "}
                            {donor.firstName} {donor.lastName}
                          </div>
                        ))}
                      </Fragment>
                    </Accordion.Collapse>
                    <hr />
                  </Card>
                ))}
              </Accordion>
            </div>
          </Col>
          {/* This section will dynamically display needs based on the dropdown selection change event, such as "most needed," "least needed," and "ending soon." */}
          <Col xs={12} lg={8} className="priority-needs mb-3">
            <div className="scroll overflow-auto">
              {needs.map((need) => (
                <Row
                  className={`position-relative align-items-center box bg-white ${
                    need.needStatus === "Expired" ? "expired" : "published"
                  }`}
                  key={key(need)}
                  onDragOver={() => {
                    setTargetNeedId(need.needId);
                  }}
                >
                  {need.needStatus === "Expired" && need.raised === 0 && (
                    <CloseButton
                      aria-label="Close Need"
                      className="position-absolute end-0 top-0 pt-4 pe-4"
                      onClick={() => showConfirmation(need.needId)}
                    />
                  )}
                  <Col xs={12} sm={4} md={4}>
                    <div className="fw-semibold mb-1">
                      Need Id: {need.needId}
                    </div>
                    <div className="text-light-emphasis fw-light fs-small mb-3 mb-sm-0">
                      {need.title}
                    </div>
                  </Col>
                  <Col xs={3} sm={3} md={3} lg={2}>
                    <Fundraising
                      raised={need?.raised}
                      needed={need?.amountNeeded}
                      status={need.needStatus}
                      displayLegend={false}
                      size="sm"
                      feature="reallocation"
                    />
                  </Col>
                  <Col xs={8} sm={4} md={4} lg={5}>
                    <div className="mb-1 fs-small">
                      <span className="text-light-emphasis fw-light">
                        Recipient:
                      </span>{" "}
                      <span>{need.displayName}</span>
                    </div>
                    <div className="mb-1 fs-small">
                      <span className="text-light-emphasis fw-light">
                        Validated By:
                      </span>{" "}
                      <span>{need.validatorName}</span>
                    </div>
                    <div className="fs-small">
                      <span className="text-light-emphasis fw-light">
                        Needed By:
                      </span>{" "}
                      <span>{need.neededBy}</span>
                    </div>
                  </Col>
                  <Col xs={1} sm={1} md={1}>
                    <Link
                      to={`${NeedProfileURL}${need.referenceId}/${need.needId}`}
                      title="Need Profile"
                      className="text-decoration-none fs-4 link-secondary"
                    >
                      <i className="flaticon-line-arrow-right"></i>
                    </Link>
                  </Col>
                  <Col xs={12}>
                    {need?.donors?.map((donor, index) => (
                      <div
                        key={key(donor)}
                        className="tag bg-light fw-light text-dark-emphasis"
                      >
                        <span className="fw-semibold">
                          ${donor.donationAmount}
                        </span>{" "}
                        {donor.firstName} {donor.lastName}
                        <CloseButton
                          aria-label="Undo Allocation"
                          className="ps-3"
                          onClick={() => {
                            draggedItem.current = index;
                            allocateDonationFromTargetToSource(need.needId);
                          }}
                        />
                      </div>
                    ))}
                  </Col>
                </Row>
              ))}
            </div>
          </Col>
          <Col xs={12}>
            <Button
              variant="primary"
              className="btn-md"
              disabled={reallocations.length > 0 ? false : true}
              onClick={saveReallocations}
            >
              Save
            </Button>
            <Link className="ms-3 btn btn-secondary btn-md" to="/reallocation">
              Cancel
            </Link>
          </Col>
        </Row>
      )}
      {needs.length === 0 && !isPageLoading && (
        <Row className="justify-content-center">
          <Col xs={12}>
            <Card className="border-0 mt-4 p-4 rounded-4 text-center">
              <Card.Body>
                <Image src={noData} alt="No Data Found" fluid />
                <h4 className="title mt-3">Allocation Status</h4>
                <p className="mb-0 fw-light text-dark-emphasis">
                  Looks like currently there are no expired needs at the moment
                  <br className="d-none d-sm-block" />
                  to allocate donations toward active needs.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {/** Component for exporting reallocations */}
      <Export
        featureName="Reallocations"
        showExportModal={showExportModal}
        closeExportModal={closeExportModal}
        showNotification={showNotification}
      />
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Reallocation;
