import React, { useState, useEffect, useRef, useReducer, useCallback } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
  ListGroup,
  Image,
  Carousel,
  Alert,
  Spinner,
} from "react-bootstrap";
import { useForm } from "react-hook-form";

import { transformAttachments } from "../../components/transformFileURL";

import { getData, postData } from "../../services/apiService";
import { useAuthContext } from "../../context/authProvider";
import { notify, initialState } from "../../store/notification";

/**
 * Component for posting an update for a specific need.
 * @returns {React.Element} - Returns JSX for posting an update.
 */
const PostUpdate = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm();
  // Extract the need ID and update ID from the URL parameters
  const { needId, updateId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);

  const [recipient] = useState(state?.recipient); // Read values passed on state
  const [isLoading, setIsLoading] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const numSlides = 4;
  const [index, setIndex] = useState(0); // State to track the active slide index
  // File Upload
  const fileInputRef = useRef(null); // Create a ref for the file input
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  // State is used to keep track of files that need to be deleted.
  const [filesToDelete, setFilesToDelete] = useState([]);
  // State is used to store the location of a file
  const [fileResideAt, setFileResideAt] = useState("");

  /**
   * Function to upload selected files via the backend API.
   * @returns {Array|null} - Returns an array of file locations or null if upload fails.
   */
  const upload = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await postData(
        `/api/image/bulk-Upload/Updates/${needId}/${fileResideAt}`,
        formData,
        userSession
      );
      return response;
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setIsLoading(false);
      resetFileInput();
      return null;
    }
  };

  /**
   * Function to submit the form data via the backend API after uploading attachments
   * Uploads selected files, merges uploaded files with existing attachments.
   * This API is also capable of delivering updates to donors via email
   * @param {Object} data - The form data containing update details.
   */
  const onSubmit = async (data) => {
    setIsLoading(true);
    if (selectedFiles.length > 0) {
      const uploadResponse = await upload();
      if (uploadResponse && uploadResponse.length > 0)
        data.attachments = [...attachments, ...uploadResponse];
      else return;
    } else data.attachments = attachments;
    data.filesToDelete = filesToDelete;

    try {
      const response = await postData(
        `/api/need/save-update`,
        data,
        userSession
      );
      setIsLoading(false);
      resetForm();
      showNotification("success", response, 5000);
    } catch (error) {
      showNotification("danger", error, 5000);
      setIsLoading(false);
    }
  };

  /**
   * Function to handle the change event when files are selected for upload.
   * Validates selected files based on size and format, and updates the selected files array.
   * @param {Event} e - The file change event.
   */
  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    const validFiles = [];
    const largeFiles = [];
    const unsupportedFiles = [];

    newFiles.forEach((file) => {
      if (file.size > 1024 * 1024 * 5) {
        // File is too large
        largeFiles.push(file.name);
      } else if (!["image/jpeg", "image/png"].includes(file.type)) {
        // Unsupported file format
        unsupportedFiles.push(file.name);
      } else {
        // Valid file
        validFiles.push(file);
      }
    });
    // Update the selected files array with valid files
    setSelectedFiles([...selectedFiles, ...validFiles]);
    clearErrors("attachments");

    // Construct error messages
    let errorMessage = "";
    if (largeFiles.length > 0) {
      errorMessage += `${largeFiles.join(", ")} are too large. `;
    }
    if (unsupportedFiles.length > 0) {
      errorMessage += `${unsupportedFiles.join(
        ", "
      )} have an unsupported format. `;
    }

    // Show a notification if an error occurs
    if (errorMessage) {
      showNotification("danger", errorMessage, 5000);
    }
  };

  /**
   * Resets the form fields to their initial state.
   */
  const resetForm = useCallback(() => {
    reset({
      needId: needId,
      updateId: 0,
      status: "Draft",
      subject: "",
      body: "",
    });
    // Clear attachments and files to delete
    setAttachments([]);
    setFilesToDelete([]);
    // Reset file input and set public status to false
    resetFileInput();
    setIsPublic(false);
  }, [reset, needId]);

  useEffect(() => {
    /**
     * Retrieves data for a specific update via the backend API using
     * ID of the update and ID of the need associated with the update.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getById = async () => {
      try {
        const response = await getData(
          `/api/need/getUpdate/${needId}/${updateId}`,
          null,
          userSession
        );
        if (response.attachments.length > 0) {
          setAttachments(response.attachments);
          const parts = response.attachments[0].split("/");
          setFileResideAt(parts[parts.length - 2]);
        }
        reset({
          needId: needId,
          updateId: updateId,
          status: response.status,
          subject: response.subject,
          body: response.body,
        });
        setIsPublic(response.status === "Published");
      } catch (error) {
        showNotification("danger", error, 5000);
      }
    };
    if (needId > 0 && updateId > 0) getById();
    else resetForm();
  }, [needId, updateId, userSession, reset, resetForm]);

  /**
   * Removes a file from the selected files array.
   * @param {number} index - Index of the file to be removed.
   */
  const removeFile = (index) => {
    const cloneSelectedFiles = [...selectedFiles];
    cloneSelectedFiles.splice(index, 1);
    setSelectedFiles(cloneSelectedFiles);
  };

  /**
   * Deletes an attachment from the attachments array at the specified index.
   * @param {number} index - The index of the attachment to delete.
   */
  const deleteAttachment = (index) => {
    const cloneDeletedFiles = [...filesToDelete];
    cloneDeletedFiles.push(attachments[index]);
    setFilesToDelete(cloneDeletedFiles);

    const cloneAttachments = [...attachments];
    cloneAttachments.splice(index, 1);
    setAttachments(cloneAttachments);
  };


  /**
   * Resets the file input field and clears the selected files.
   */
  const resetFileInput = () => {
    if (fileInputRef.current) fileInputRef.current.value = null; // Reset the file input value
    setSelectedFiles([]);
  };

  /**
   * Function to navigate to the updates page.
   */
  const navigateToUpdates = () => {
    navigate(`/needs/updates/${needId}`, {
      replace: true,
      state: { recipient: recipient },
    });
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-bold mb-0">
            Post Updates for {recipient}'s Contributors
          </h6>
          <Button
            onClick={navigateToUpdates}
            variant="secondary"
            className="ms-3"
          >
            <i className="flaticon-line-arrow-left pe-2"></i>
            Back
          </Button>
        </div>
        <Card className="border-0 rounded-3 need-updates">
          <Card.Body className="p-4">
            <Row>
              <Col xs={12} md={6}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Control type="hidden" {...register("needId")} />
                  <Form.Control type="hidden" {...register("updateId")} />
                  <Form.Control type="hidden" {...register("status")} />
                  <Form.Group className="mb-4">
                    <FloatingLabel controlId="txtSubject" label="Subject">
                      <Form.Control
                        type="text"
                        placeholder="Subject"
                        {...register("subject", {
                          required: "Subject is required",
                          pattern: {
                            value:
                              /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                            message:
                              "Subject shouldn't contain HTML or Script tags",
                          },
                          maxLength: {
                            value: 250,
                            message: "Subject cannot exceed 250 characters",
                          },
                        })}
                      />
                    </FloatingLabel>
                    {errors.subject && (
                      <Form.Text className="text-danger">
                        {errors.subject.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <FloatingLabel
                      controlId="txtBody"
                      label="Share a Recipient Update"
                    >
                      <Form.Control
                        as="textarea"
                        className="scroll"
                        placeholder="Share a Recipient Update"
                        {...register("body", {
                          required: "Message is required",
                          pattern: {
                            value:
                              /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>)[\s\S])*$/i,
                            message:
                              "Message shouldn't contain HTML or Script tags",
                          },
                          maxLength: {
                            value: 2500,
                            message: "Message cannot exceed 2500 characters",
                          },
                        })}
                      />
                    </FloatingLabel>
                    {errors.body && (
                      <Form.Text className="text-danger">
                        {errors.body.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Make it Public"
                      className="text-muted"
                      checked={isPublic}
                      onChange={(e) => {
                        setValue(
                          "status",
                          e.target.checked ? "Published" : "Draft"
                        );
                        setIsPublic(e.target.checked);
                      }}
                    />
                    {isPublic && (
                      <Form.Text muted className="d-block mt-2">
                        It will send an email to the supporters who have
                        provided support for the need.
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <label className="text-light-emphasis mb-2">
                      Attachments:
                    </label>
                    <InputGroup>
                      <Form.Control
                        type="file"
                        multiple
                        id="fileInput"
                        onChange={handleFileChange}
                        className="d-none"
                        ref={fileInputRef}
                        accept="image/jpeg, image/png"
                      />
                      <Form.Control
                        type="text"
                        placeholder={
                          selectedFiles.length > 0
                            ? `${selectedFiles.length} ${selectedFiles.length === 1 ? "file" : "files"
                            }`
                            : "No file chosen"
                        }
                        readOnly
                        className="rounded-start"
                        {...register("attachments")}
                      />
                      <InputGroup.Text className="p-0 border-0">
                        <Button
                          as="label"
                          variant="light"
                          htmlFor="fileInput"
                          className="btn-md rounded-0"
                        >
                          Browse
                        </Button>
                        <Button
                          variant="dark"
                          className="btn-md rounded-start-0"
                          onClick={resetFileInput}
                        >
                          Remove
                        </Button>
                      </InputGroup.Text>
                    </InputGroup>
                    <Form.Text muted className="d-block mt-1">
                      Each file must not exceed 5MB in size, and supported file
                      formats include JPG and PNG. You can upload multiple files
                      at a time.
                    </Form.Text>
                    {errors.attachments && (
                      <Form.Text className="text-danger">
                        {errors.attachments.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  {(selectedFiles.length > 0 || attachments.length) > 0 && (
                    <Form.Group className="mb-4">
                      <ListGroup as="ul" className="attachments scroll">
                        {selectedFiles.map((file, index) => (
                          <ListGroup.Item
                            as="li"
                            key={file.name}
                            className="d-flex justify-content-between align-items-center bg-transparent"
                          >
                            <div className="d-inline-flex align-items-center">
                              <Image
                                src={URL.createObjectURL(file)}
                                alt="Preview"
                                className="preview"
                                thumbnail
                                fluid
                              />
                              <div className="ms-3">
                                <small className="fs-small text-dark-emphasis">
                                  {file.name}
                                </small>
                                <br />
                                <small className="text-light-emphasis fw-light">
                                  {(file.size / (1024 * 1024)).toFixed(2)} MB
                                </small>
                              </div>
                            </div>
                            <Button
                              onClick={() => removeFile(index)}
                              variant="link"
                              className="btn-close fs-small"
                            ></Button>
                          </ListGroup.Item>
                        ))}
                        {attachments.map((attachment, index) => (
                          <ListGroup.Item
                            as="li"
                            key={attachment.substring(
                              attachment.lastIndexOf("/") + 1
                            )}
                            className="d-flex justify-content-between align-items-center bg-transparent"
                          >
                            <div className="d-inline-flex align-items-center">
                              <Image
                                src={transformAttachments(attachment)}
                                alt="Preview"
                                className="preview"
                                thumbnail
                                fluid
                              />
                              <div className="ms-3">
                                <small className="fs-small text-dark-emphasis">
                                  {attachment.substring(
                                    attachment.lastIndexOf("/") + 1
                                  )}
                                  {index}
                                </small>
                              </div>
                            </div>
                            <Button
                              onClick={() => deleteAttachment(index)}
                              variant="link"
                              className="btn-close fs-small"
                            ></Button>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </Form.Group>
                  )}

                  <Col xs={12}>
                    <Button
                      variant="primary"
                      disabled={isLoading}
                      type="submit"
                      className="btn-md"
                    >
                      {isLoading ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                    <Button
                      variant="secondary"
                      type="button"
                      className="ms-3 btn-md"
                      onClick={resetForm}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Form>
              </Col>
              <Col xs={12} md={6}>
                <Card className="mt-4 mt-md-0 mb-4 story-carousel">
                  <Card.Header className="mx-3 px-0 bg-transparent text-dark-emphasis fw-semibold">
                    Examples
                  </Card.Header>
                  <Card.Body>
                    <Carousel
                      variant="dark"
                      interval={null}
                      indicators={false}
                      controls={false}
                      activeIndex={index}
                    >
                      <Carousel.Item>
                        <Carousel.Caption className="text-dark-emphasis fs-small scroll">
                          <h6 className="text-dark">
                            Al says, “I have a great job and a place to live”
                          </h6>
                          <p className="mb-2">
                            I can’t believe that with the help of Benevolent and
                            Inspiration Corporation I was able to turn my life
                            completely around. I have a great job and a place to
                            live where I pay my own rent. What a feeling! It’s
                            nice to see that there are still people and programs
                            out there that care. For those people who donated,
                            thank you so much. For those who are still out on
                            the street, don’t ever give up.
                          </p>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <Carousel.Caption className="text-dark-emphasis fs-small scroll">
                          <h6 className="text-dark">Al got a job!</h6>
                          <p className="mb-2">
                            We are so thrilled that Al has had his food-service
                            clothing funded! Since receiving the uniform, Al has
                            started working as a waiter at Terra American Bistro
                            in Evanston, where he has received exceptional
                            reviews from the managing team, the owners, and the
                            customers. We are so thankful to all who helped make
                            this possible by funding his work attire.
                          </p>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <Carousel.Caption className="text-dark-emphasis fs-small scroll">
                          <h6 className="text-dark">
                            A thank you message from Monique
                          </h6>
                          <p className="mb-2">
                            I want to thank you all for giving me and my child
                            the funds for furniture! My daughter is going to
                            love her bed and it’s all she’s been talking about.
                            We are going to love our dining room set. Thank you
                            again for your donations!
                          </p>
                        </Carousel.Caption>
                      </Carousel.Item>
                      <Carousel.Item>
                        <Carousel.Caption className="text-dark-emphasis fs-small scroll">
                          <h6 className="text-dark">
                            William has his pressure washer!
                          </h6>
                          <p className="mb-2">
                            Thank you generous donors! William was able to
                            purchase the pressure washer, thanks to your
                            generous support. He was able to find it on sale for
                            a total cost of $70 less than the projected price --
                            this left money for accessories and gasoline.
                          </p>
                        </Carousel.Caption>
                      </Carousel.Item>
                    </Carousel>
                  </Card.Body>
                  <Card.Footer className="bg-transparent">
                    <Button
                      type="button"
                      variant="link"
                      size="sm"
                      onClick={() =>
                        setIndex((prevIndex) =>
                          prevIndex === 0 ? numSlides - 1 : prevIndex - 1
                        )
                      }
                    >
                      <i className="flaticon-back fs-5 me-1"></i>PREVIOUS
                    </Button>
                    <Button
                      type="button"
                      variant="link"
                      size="sm"
                      onClick={() =>
                        setIndex((prevIndex) =>
                          prevIndex === numSlides - 1 ? 0 : prevIndex + 1
                        )
                      }
                    >
                      NEXT
                      <i className="flaticon-next fs-5 ms-1 align-middle"></i>
                    </Button>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
    </main>
  );
};

export default PostUpdate;
