import React, {
  Fragment,
  useState,
  useEffect,
  useReducer,
  useCallback,
} from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Image,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
  Card,
  ListGroup,
  Spinner,
  Alert,
  Carousel,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

import { getData, postData } from "../../../services/apiService";
import { notify, initialState } from "../../../store/notification";
import { Icons } from "../../../components/iconImporter";

import { SITEURL } from "../../../constants";

/**
 * Component for rendering need information form.
 * @param {object} props - Component props.
 * @param {object} props.userSession - User session object.
 * @param {array} props.roles - Array of user roles.
 * @param {object} props.need - Object containing need information.
 * @param {function} props.processResult - Function to process form result.
 * @param {function} props.handleActiveTab - Function to handle active tab.
 * @returns {React.Element} - Returns JSX for need information form
 */
const NeedInfo = (props) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm();
  const neededOn = watch("neededOn");
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  const { userSession } = props;
  const [loadingStates, setLoadingStates] = useState({});
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const numSlides = 3;
  const [index, setIndex] = useState(0); // State to track the active slide index

  /**
   * Function to filter fields from the need object.
   * @param  {...string} keys - Keys to be included in the filtered object.
   * @returns {object} - Filtered object containing specified keys.
   */
  const filterFields = useCallback(
    (...keys) => {
      const targetObject = Object.assign({});
      Object.keys(props.need).forEach((key) => {
        if (keys.includes(key)) {
          targetObject[key] = props.need[key];
        }
      });
      return targetObject;
    },
    [props.need]
  );

  /**
   * Function to handle the change event for the category dropdown.
   * Updates the selected categories state and form value.
   * @param {Array} selectedOptions - The selected options from the dropdown.
   */
  const handleSelectedCategories = useCallback(
    (selectedOptions) => {
      const selectedValues = selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [];
      setSelectedCategories(selectedOptions);

      setValue("categories", selectedValues);
    },
    [setValue]
  );

  /**
   * Function to calculate and set the neededOn field value.
   */
  const calculateNeededOn = useCallback(() => {
    let date = new Date(props.need?.neededOn);
    if (isNaN(date) || date.getFullYear() === 1) {
      date = new Date();
      date.setDate(date.getDate() + 90);
    }

    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    setValue("neededOn", `${year}-${month}-${day}`);
  }, [props.need?.neededOn, setValue]);

  useEffect(() => {
    // Reset form fields based on user roles
    if (props.roles.includes("SuperAdmin") || props.roles.includes("Staff")) {
      reset(
        filterFields(
          "needId",
          "needStatus",
          "title",
          "summary",
          "neededOn",
          "categories",
          "amountNeeded",
          "biography",
          "goals",
          "aboutNeed",
          "reasonForNeed",
          "userAgreement"
        )
      );

      // To avoid encountering "icon undefined" errors after submitting the data,
      // it's necessary to reset the state based on the existing selected categories.
      // This is because the props.need.categories only contains an array of category IDs,
      // lacking the necessary object structure including names and icons.
      handleSelectedCategories(
        selectedCategories?.length > 0
          ? selectedCategories
          : props.need?.categories
      );
    } else if (props.roles.includes("Recipient")) {
      reset(
        filterFields(
          "needId",
          "needStatus",
          "neededOn",
          "accessCode",
          "amountNeeded",
          "biography",
          "goals",
          "aboutNeed",
          "reasonForNeed",
          "userAgreement"
        )
      );
    } else
      reset(
        filterFields(
          "needId",
          "needStatus",
          "neededOn",
          "amountNeeded",
          "biography",
          "goals",
          "aboutNeed",
          "reasonForNeed",
          "userAgreement"
        )
      );
    calculateNeededOn();
  }, [
    reset,
    props.need,
    props.roles,
    filterFields,
    handleSelectedCategories,
    calculateNeededOn,
  ]);

  useEffect(() => {
    /**
     * Fetches all the categories from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getCategories = async () => {
      try {
        const response = await getData(
          `/api/category/getAll`,
          null,
          userSession
        );
        setCategories(response);
      } catch (error) {
        // Show a notification if an error occurs
        showNotification("danger", error, 5000);
        setCategories([]);
      }
    };
    if (props.roles.includes("SuperAdmin") || props.roles.includes("Staff"))
      getCategories();
  }, [userSession]);

  /**
   * Custom filter function for filtering options in the category dropdown.
   * @param {Object} option - The option to be filtered.
   * @param {string} searchText - The text to search for.
   * @returns {boolean} - Indicates whether the option matches the search text.
   */
  const customFilterOption = (option, searchText) => {
    return option.data.label.toLowerCase().includes(searchText.toLowerCase());
  };

  /**
   * Function to update the need information using the backend API
   * @param {Object} data - The form data.
   * @param {Object} e - The event object containing information about the form submission.
   */
  const onSubmit = async (data, e) => {
    // Extract the button type from the submit event
    const buttonType = e.nativeEvent.submitter.dataset.buttonType;
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [buttonType]: true,
    }));
    try {
      // Determine the API URL based on user roles
      const apiURL = props.roles.includes("Recipient")
        ? "/api/need/update-recipient-story"
        : "/api/need/upsert-need-info";
      const response = await postData(apiURL, data, userSession);
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [buttonType]: false,
      }));

      // Update the need data and submission status
      setValue("needId", response.needId);
      data.needId = response.needId;
      props.processResult({
        need: data,
        hasBeenSubmitted: false,
      });

      // Determine the next active tab based on button type and user roles
      if (buttonType === "Draft")
        showNotification("success", response.message, 5000);
      else
        props.handleActiveTab(
          !props.roles.includes("Recipient") ? "recipient-info" : "preview"
        );
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [buttonType]: false,
      }));
    }
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Control type="hidden" {...register("needId")} />
        <Form.Control type="hidden" {...register("needStatus")} />
        {props.roles.includes("SuperAdmin") && (
          <Row>
            <Col md={6} className="mb-4">
              <FloatingLabel
                label="Title"
                controlId="txtTitle"
                htmlFor="txtTitle"
              >
                <Form.Control
                  type="text"
                  placeholder="Title"
                  {...register("title", {
                    required: "Title is required",
                    pattern: {
                      value:
                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                      message: "Title shouldn't contain HTML or Script tags",
                    },
                    maxLength: {
                      value: 85,
                      message: "Title cannot exceed 85 characters",
                    },
                  })}
                />
              </FloatingLabel>
              {errors.title && (
                <Form.Text className="text-danger">
                  {errors.title.message}
                </Form.Text>
              )}
            </Col>
            <Col md={6} className="mb-4">
              <FloatingLabel
                label="Date Needed By"
                controlId="txtTitle"
                htmlFor="txtTitle"
              >
                <Form.Control
                  type="date"
                  placeholder="Date Needed By"
                  {...register("neededOn", {
                    required: "Date needed by is required",
                  })}
                />
              </FloatingLabel>
              {errors.neededOn && (
                <Form.Text className="text-danger">
                  {errors.neededOn.message}
                </Form.Text>
              )}
            </Col>
            <Col md={6} className="mb-4">
              <FloatingLabel controlId="txtSummary" label="Summary">
                <Form.Control
                  as="textarea"
                  className="scroll"
                  placeholder="Summary"
                  {...register("summary", {
                    required: "Summary is required",
                    pattern: {
                      value:
                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>)[\s\S])*$/i,
                      message: "Summary shouldn't contain HTML or Script tags",
                    },
                    maxLength: {
                      value: 1000,
                      message: "Summary cannot exceed 1000 characters",
                    },
                  })}
                />
              </FloatingLabel>
              {errors.summary && (
                <Form.Text className="text-danger">
                  {errors.summary.message}
                </Form.Text>
              )}
            </Col>
            <Col md={6} className="mb-4">
              <Form.Label htmlFor="ddCategory">Category</Form.Label>
              <Controller
                control={control}
                name="categories"
                id="ddCategory"
                rules={{ required: "Category is required" }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    // menuIsOpen
                    isSearchable={true}
                    isLoading={loadingStates["inSearch"]}
                    placeholder="Category"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={categories}
                    value={selectedCategories}
                    onChange={handleSelectedCategories}
                    noOptionsMessage={() => "No results found"}
                    filterOption={customFilterOption}
                    isMulti
                    getOptionLabel={(e) => {
                      const index = Icons.findIndex((x) => x.value === e.icon);
                      return (
                        <Fragment>
                          <Image
                            src={Icons[index].icon}
                            alt={Icons[index].value}
                            width="20"
                            className="me-2 align-text-bottom"
                          />
                          <span>{e.label}</span>
                        </Fragment>
                      );
                    }}
                  />
                )}
              />
              {errors.categories && (
                <Form.Text className="text-danger">
                  {errors.categories.message}
                </Form.Text>
              )}
            </Col>
          </Row>
        )}
        <Row className="align-items-center mb-4">
          <Col xs="auto">
            <h6>Story of the need, as told by the recipient</h6>
          </Col>
          <Col>
            <hr className="m-0" />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p className="fs-small text-light-emphasis">
              The story should be told in the first person from the point of
              view of the recipient, but it can be entered by the validator or
              the recipient. See Tips and examples for help in telling the story
              of the need.
            </p>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Group className="mb-4">
              <FloatingLabel
                controlId="txtBiography"
                label="Tell us about yourself*"
              >
                <Form.Control
                  as="textarea"
                  className="scroll"
                  placeholder="Tell us about yourself*"
                  {...register("biography", {
                    required: "Biography is required",
                    pattern: {
                      value:
                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>)[\s\S])*$/i,
                      message:
                        "Biography shouldn't contain HTML or Script tags",
                    },
                    maxLength: {
                      value: 3000,
                      message: "Biography cannot exceed 3000 characters",
                    },
                  })}
                />
              </FloatingLabel>
              {errors.biography && (
                <Form.Text className="text-danger">
                  {errors.biography.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-4">
              <FloatingLabel
                controlId="txtGoals"
                label="What are your goals, plans, and ambitions?*"
              >
                <Form.Control
                  as="textarea"
                  className="scroll"
                  placeholder="What are your goals, plans, and ambitions?*
                  "
                  {...register("goals", {
                    required: "This field is required",
                    pattern: {
                      value:
                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>)[\s\S])*$/i,
                      message:
                        "The content shouldn't contain HTML or Script tags",
                    },
                    maxLength: {
                      value: 3000,
                      message: "The content cannot exceed 3000 characters",
                    },
                  })}
                />
              </FloatingLabel>
              {errors.goals && (
                <Form.Text className="text-danger">
                  {errors.goals.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-4">
              <FloatingLabel controlId="txtNeed" label="What is your need?*">
                <Form.Control
                  as="textarea"
                  className="scroll"
                  placeholder="What is your need?*"
                  {...register("aboutNeed", {
                    required: "This field is required",
                    pattern: {
                      value:
                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>)[\s\S])*$/i,
                      message:
                        "The content shouldn't contain HTML or Script tags",
                    },
                    maxLength: {
                      value: 3000,
                      message: "The content cannot exceed 3000 characters",
                    },
                  })}
                />
              </FloatingLabel>
              {errors.aboutNeed && (
                <Form.Text className="text-danger">
                  {errors.aboutNeed.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-4">
              <FloatingLabel
                controlId="txtNeedReason"
                label="Why will meeting this need help move you forward?*"
              >
                <Form.Control
                  as="textarea"
                  className="scroll"
                  placeholder="Why will meeting this need help move you forward?*"
                  {...register("reasonForNeed", {
                    required: "Reason for need is required",
                    pattern: {
                      value:
                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>)[\s\S])*$/i,
                      message:
                        "Reason for need shouldn't contain HTML or Script tags",
                    },
                    maxLength: {
                      value: 3000,
                      message: "Reason for need cannot exceed 3000 characters",
                    },
                  })}
                />
              </FloatingLabel>
              {errors.reasonForNeed && (
                <Form.Text className="text-danger">
                  {errors.reasonForNeed.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} lg={6}>
            <Card className="border-0 mb-4">
              <ListGroup
                variant="flush"
                className="border-start border-5 border-secondary fs-small"
              >
                <ListGroup.Item className="border-0 pt-3 pb-1 fw-semibold text-dark-emphasis">
                  Tips:
                </ListGroup.Item>
                <ListGroup.Item className="border-0 py-1">
                  <Link
                    to={`${SITEURL}/faq/#client-support?q=5`}
                    title="FAQ"
                    className="text-decoration-none"
                  >
                    What makes a need successful?
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item className="border-0 pt-1 pb-3">
                  <Link
                    to={`${SITEURL}/faq/#client-support?q=8`}
                    title="FAQ"
                    className="text-decoration-none"
                  >
                    What type of needs can I ask donors to support?
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </Card>
            <Card className="mb-4 story-carousel">
              <Card.Header className="mx-3 px-0 bg-transparent text-dark-emphasis fw-semibold">
                Examples
              </Card.Header>
              <Card.Body>
                <Carousel
                  variant="dark"
                  interval={null}
                  indicators={false}
                  controls={false}
                  activeIndex={index}
                >
                  <Carousel.Item>
                    <Carousel.Caption className="text-dark-emphasis fs-small scroll">
                      <p className="mb-2">
                        I am a 51-year-old waiter. I’ve waited tables for 28
                        years. I’ve been trying to get a job, and I went through
                        Inspiration Corporation’s employment program.
                      </p>
                      <p className="mb-2">
                        My goals are to find employment, preferably in food
                        service, and get permanent housing. I am eligible for
                        housing from a lot of places or I could pay for rent at
                        the Y.
                      </p>
                      <p className="mb-2">
                        I need a uniform to get a job as a waiter. The banquet
                        company I’ve been in touch with hires regularly, and
                        with my experience I would have an excellent chance to
                        get hired. They are hiring now, but I would need to have
                        my own tuxedo shirt, bow tie, vest, and comfortable
                        shoes.
                      </p>
                      <p className="mb-2">
                        Meeting this need will help me because getting this
                        uniform is the only thing standing between me and a job.
                        I have all the experience necessary and if I go to an
                        interview and I have the uniform the employer will see
                        that I’m the ideal candidate. Once I’m employed, I’ll be
                        able to secure housing for myself.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Carousel.Caption className="text-dark-emphasis fs-small scroll">
                      <p className="mb-2">
                        I am a female working in a male-dominated industry. I
                        went through JARC’s welding training program and was
                        able to land a job in manufacturing. I am one of only 4
                        women in the plant and the only woman of color. I love
                        that I am paving the way for more women to enter this
                        field.
                      </p>
                      <p className="mb-2">
                        My goals are to ensure my son is getting the highest
                        level of care and education, to make sure I can get to
                        work on time, and get to the top of my new career!
                      </p>
                      <p className="mb-2">
                        I need assistance with a security deposit. I am
                        currently homeless and looking to move into an apartment
                        for myself and my son. Right now I live house to house.
                        With my job, I can afford rent, just not the security
                        deposit.
                      </p>
                      <p className="mb-2">
                        Meeting this need will help me because my son and I can
                        move into an apartment that will be our own. It will be
                        easier for me to get to work and ensure my son’s
                        education. I will be able to start the new year with a
                        better outlook on life!
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Carousel.Caption className="text-dark-emphasis fs-small scroll">
                      <p className="mb-2">
                        I am a 34-year-old single mother of three working two
                        days a week. I am trying to complete my state CNA exam
                        so that I can move forward in my education to become a
                        Registered Nurse. I’ve been working in home care since I
                        was 18 and want to continue on in my career.
                      </p>
                      <p className="mb-2">
                        My goals are to become a Registered Trauma Nurse. I have
                        a job waiting for me so taking the exam and getting this
                        job would enable me to go back to college and finish my
                        studies.
                      </p>
                      <p className="mb-2">
                        I need to take the state CNA exam so I can get my
                        Nursing Aide License. My license expired 3 years ago
                        when the agency I worked for went out of business. Since
                        then, it’s been tough trying to make ends meet, and I’m
                        only working part time right now. When I get my license
                        back, I’ll have a full-time job waiting for me.
                      </p>
                      <p className="mb-2">
                        Meeting this need would help me because having my
                        Nursing Aide License would allow me to take a job and do
                        simple things like keep gas in my car, and afford new
                        shoes and uniforms for my children for school.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </Card.Body>
              <Card.Footer className="bg-transparent">
                <Button
                  type="button"
                  variant="link"
                  size="sm"
                  onClick={() =>
                    setIndex((prevIndex) =>
                      prevIndex === 0 ? numSlides - 1 : prevIndex - 1
                    )
                  }
                >
                  <i className="flaticon-back fs-5 me-1"></i>PREVIOUS
                </Button>
                <Button
                  type="button"
                  variant="link"
                  size="sm"
                  onClick={() =>
                    setIndex((prevIndex) =>
                      prevIndex === numSlides - 1 ? 0 : prevIndex + 1
                    )
                  }
                >
                  NEXT<i className="flaticon-next fs-5 ms-1 align-middle"></i>
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row className="align-items-center mb-4">
          <Col xs="auto">
            <h6>
              Fund Details{" "}
              <Link
                to="/"
                title="FAQ"
                className="text-decoration-none fw-light"
              >
                <small>[This is more urgent need]</small>
              </Link>
            </h6>
          </Col>
          <Col>
            <hr className="m-0" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6} className="my-3">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <Form.Label className="my-1">Amount Needed*</Form.Label>
                <p className="fs-small text-light-emphasis">
                  Need will expire on {neededOn}{" "}
                  <Link to="/" title="FAQ" className="text-decoration-none">
                    [Why?]
                  </Link>
                </p>
              </div>
              <div className="flex-grow-1 ms-3">
                <InputGroup className="border rounded">
                  <InputGroup.Text className="border-0">
                    <i className="fs-5 flaticon-dollar"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="0"
                    className="fw-semibold border-0 border-start my-2 py-0 text-dark-emphasis"
                    {...register("amountNeeded", {
                      required: "Amount is required",
                      validate: (value) =>
                        (parseFloat(value) > 0 && parseFloat(value) <= 700) ||
                        "Amount must be within the range of 1 to 700.",
                    })}
                  />
                </InputGroup>
                {errors.amountNeeded && (
                  <Form.Text className="text-danger">
                    {errors.amountNeeded.message}
                  </Form.Text>
                )}
              </div>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <Card className="border-0 mb-4">
              <ListGroup
                variant="flush"
                className="border-start border-5 border-secondary fs-small"
              >
                <ListGroup.Item className="border-0 pt-3 pb-1 fw-semibold text-dark-emphasis">
                  Tips:
                </ListGroup.Item>
                <ListGroup.Item className="border-0 py-1">
                  A need is more likely to be funded if it is less than $450.
                </ListGroup.Item>
                <ListGroup.Item className="border-0 py-1">
                  Benevolent accepts needs up to $700
                </ListGroup.Item>
                <ListGroup.Item className="border-0 pb-3 pt-1">
                  <Link
                    to={`${SITEURL}/faq/#client-support?q=10`}
                    title="FAQ"
                    className="text-decoration-none"
                  >
                    How do I price my client’s needs?
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
          <Col xs={12} className="mb-4">
            <Form.Check
              type="checkbox"
              label={
                <Fragment>
                  I agree to the{" "}
                  <Link
                    to={`${SITEURL}/about/terms-of-use`}
                    target="_blank"
                    title="Terms of Use"
                    className="text-decoration-none fw-semibold text-dark-emphasis"
                  >
                    Terms of Use
                  </Link>{" "}
                  set forth by Benevolent
                </Fragment>
              }
              className="fs-small fw-light"
              {...register("userAgreement", {
                required: "Agree to the terms and conditions.",
              })}
            />
            {errors.userAgreement && (
              <Form.Text className="text-danger">
                {errors.userAgreement.message}
              </Form.Text>
            )}
          </Col>
          <Col xs={12}>
            <Button
              type="submit"
              variant="primary"
              data-button-type="Continue"
              disabled={loadingStates["Continue"]}
              className="btn-md me-3"
            >
              {loadingStates["Continue"] ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Save & Continue"
              )}
            </Button>
            <Button
              variant="secondary"
              type="submit"
              data-button-type="Draft"
              disabled={loadingStates["Draft"]}
              className="btn-md"
            >
              {loadingStates["Draft"] ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Save & Finish Later"
              )}
            </Button>
          </Col>
        </Row>
      </Form>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
    </Fragment>
  );
};

export default NeedInfo;
