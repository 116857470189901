import React, { useState, useEffect, useReducer, useRef, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  TabContainer,
  Tab,
  Nav,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
  Alert,
  Spinner,
  Modal,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useForm } from "react-hook-form";
import {
  isPossiblePhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";

import ImageCrop from "../../components/imageCrop";

import { getData, postData } from "../../services/apiService";
import { notify, initialState } from "../../store/notification";
import { SITEURL } from "../../constants";

import logo from "../../resources/images/logo.svg";
import avatar from "../../resources/images/avatar.png";
import validator from "../../resources/images/validator.png";

/**
 * Component for creating an account.
 * Handles the creation of an organization in the system along with the creation of a validator account.
 * @returns {React.Element} - Returns JSX for creating an account.
 */
const CreateAccount = () => {
  const {
    register: registerForm1,
    handleSubmit: handleForm1,
    formState: { errors: errorsForm1 },
    reset: resetForm1,
  } = useForm();
  const {
    register: registerForm2,
    handleSubmit: handleForm2,
    formState: { errors: errorsForm2, isValid: isForm2Valid },
    watch,
    control: controlForm2,
    trigger: triggerForm2,
    reset: resetForm2,
  } = useForm();
  const {
    register: registerForm3,
    handleSubmit: handleForm3,
    formState: { errors: errorsForm3, isValid: isForm3Valid },
    reset: resetForm3,
  } = useForm();
  // Get the value of the password field for confirmation
  const password = watch("password");
  // Watch for changes in the phoneNumber field
  const phoneNumber = watch("phoneNumber");
  // Extract the code from the URL parameters for pre-populating
  // invitation details for setting up an account
  const { code } = useParams();
  const navigate = useNavigate();

  const [notification, dispatch] = useReducer(notify, initialState);
  const [activeTab, setActiveTab] = useState("profile-info");
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isReadyForSubmission, setIsReadyForSubmission] = useState(false);
  const [isNewPartner, setIsNewPartner] = useState(false);
  const [maxLength, setMaxLength] = useState(20);

  // File Upload
  const fileInputRef = useRef(null); // Create a ref for the file input
  const [selectedFile, setSelectedFile] = useState({});
  const [showCropModal, setShowCropModal] = useState(false);
  const [cropInfo, setCropInfo] = useState({});

  // Functions for controlling the Crop Modal
  const closeCropModal = () => setShowCropModal(false);
  const openCropModal = () => setShowCropModal(true);

  useEffect(() => {
    /**
     * Prevent the user from adding in more numbers to this field if they exceed the limit of required digits
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    if (phoneNumber) {
      const formattedPhone = formatPhoneNumberIntl(phoneNumber);

      // Update maxLength if the cleaned phone number is valid
      if (isPossiblePhoneNumber(formattedPhone))
        setMaxLength(formattedPhone.length);
    }
  }, [phoneNumber, maxLength]);

  /**
   * Function to handle the change event of the file input element.
   * @param {object} event - The change event object.
   */
  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];
      const maxSize = 1024 * 1024; // 1 MB in bytes
      if (!allowedTypes.includes(file.type)) {
        showNotification(
          "danger",
          "Image format is incompatible. Only JPEG and PNG formats are supported.",
          5000
        );
        return;
      }
      if (file.size > maxSize) {
        showNotification(
          "danger",
          "Your file is too large, maximum allowed size is: 1 MB",
          5000
        );
        return;
      }
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedFile({
            name: event.target.files?.[0].name,
            file: event.target.files[0],
            src: reader.result,
            url: URL.createObjectURL(event.target.files[0]),
          });
          openCropModal();
        };
        reader.readAsDataURL(file);
      }
    }
  };

  /**
   * Function to update the state with cropped image information and removes the existing profile picture.
   * @param {string} croppedImage - The cropped image data.
   * @param {object} croppedAreaPixels - The position of the cropped area in pixels.
   * @param {number} rotation - The rotation angle of the cropped image.
   */
  const updateAvatar = (croppedImage, croppedAreaPixels, rotation) => {
    setCropInfo({
      image: croppedImage,
      position: croppedAreaPixels,
      rotation: rotation,
    });
  };

  /**
   * Function to reset the file input and clears the state related to the selected file
   * and profile picture.
   */
  const resetFileInput = () => {
    setCropInfo({});
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset the file input value
    }
    setSelectedFile({});
  };

  /**
   * Function to upload a cropped image.
   * @returns {string|null} Returns the URL of the uploaded file if successful, or null if an error occurs.
   */
  const upload = async () => {
    const formData = new FormData();
    formData.append("File", selectedFile.file);
    formData.append("cropX", parseInt(cropInfo.position.x));
    formData.append("cropY", parseInt(cropInfo.position.y));
    formData.append("cropWidth", parseInt(cropInfo.position.width));
    formData.append("cropHeight", parseInt(cropInfo.position.height));
    formData.append("cropRotation", parseInt(cropInfo.rotation));
    formData.append("type", "Profile");
    // formData.append("profilePicture", null);
    try {
      const response = await postData("/api/image/crop", formData, null);
      return response;
    } catch (error) {
      showNotification("danger", error, 5000);
      setIsLoading(false);
      resetFileInput();
      return null;
    }
  };

  /**
   * Function to update the formData state and switches to the next tab if specified
   *  or sets the flag indicating readiness for submission.
   * @param {Object} data - The form data to be submitted.
   * @param {string} tab - The name of the tab to move to after submission (optional).
   */
  const onSubmit = (data, tab) => {
    // Move to the next tab if validation passes
    setFormData((prevData) => ({ ...prevData, ...data }));
    if (tab) setActiveTab(tab);
    else setIsReadyForSubmission(true);
  };

  /**
   * Function to display an alert upon completion of account setup
   * @param {string} message - Message to be displayed in the alert
   */
  const alertAccountSetupCompleted = useCallback((message) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Card className="border-0 shadow rounded-3 modal-sm">
            <Card.Body className="p-4 text-center">
              <Image src={validator} alt="Account Setup" fluid />
              <div className="mt-3 fw-light">{message}</div>
            </Card.Body>
            <Card.Footer className="bg-transparent py-4 text-center">
              <a
                href={SITEURL}
                title="Benevolent"
                className="btn btn-primary fw-semibold btn-md"
                onClick={() => {
                  onClose();
                }}
              >
                Home
              </a>
              <Button
                variant="secondary"
                className="fw-semibold btn-md ms-3"
                onClick={() => {
                  navigate("/");
                  onClose();
                }}
              >
                Login
              </Button>
            </Card.Footer>
          </Card>
        );
      },
    });
  }, [navigate]);

  useEffect(() => {
    /**
     * Function to handle the creation of an organization in the system
     * along with the creation of validator account.
     */
    const handleAccountSetup = async () => {
      setIsLoading(true);
      if (selectedFile.file) {
        const response = await upload();
        if (response) formData.profilePicture = response;
        else return;
      }
      formData.phoneNumber = formatPhoneNumberIntl(formData.phoneNumber);
      try {
        const response = await postData(
          `/api/validator/create-account/${code}`,
          formData,
          null
        );
        setIsLoading(false);
        resetFileInput();
        resetForm1();
        resetForm2();
        resetForm3();
        alertAccountSetupCompleted(response);
      } catch (error) {
        // Show a notification if an error occurs
        showNotification("danger", error, 5000);
        setIsLoading(false);
        setIsReadyForSubmission(false);
      }
    };

    /**
     * Checks if the form data is valid based on the type of invitation and readiness for submission,
     * then proceeds to handle the account setup if conditions are met.
     */
    const isValid = !isNewPartner ? isForm2Valid : isForm3Valid;
    if (isValid && isReadyForSubmission) handleAccountSetup();
  }, [formData, code, resetForm1, resetForm2, resetForm3, alertAccountSetupCompleted, isNewPartner]);

  useEffect(() => {
    /**
     * Function to fetch invitation details by code and populate form data accordingly
     * on component mount.
     */
    const getInvitation = async () => {
      setIsPageLoading(true);
      try {
        const response = await getData(
          `/api/invitation/getByCode/${code}`,
          null,
          null
        );
        setIsNewPartner(response.type === "New Partner");
        resetForm1({
          firstName: response.firstName,
          lastName: response.lastName,
          isNewPartner: response.type === "New Partner",
        });
        resetForm2({
          jobTitle: response.jobTitle,
          email: response.emailAddress,
        });
        setIsPageLoading(false);
      } catch (error) {
        // Show a notification if an error occurs
        showNotification("danger", error, 5000);
        setIsPageLoading(false);
      }
    };

    getInvitation();
  }, [code, resetForm1, resetForm2]);

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main className="d-flex align-items-center justify-content-center min-vh-100 flex-column">
      <Container>
        <Row className="justify-content-center my-4">
          <Col xs={11} sm={11} md={9} lg={7} xl={6} xxl={5}>
            <div className="text-center mb-4">
              <Link to={SITEURL} title="Benevolent">
                <Image src={logo} alt="Benevolent" className="logo" fluid />
              </Link>
              <h3 className="title mt-4 mb-3">Complete Account Setup</h3>
            </div>
            <Card className="border-0 shadow rounded-4">
              <Card.Body className="px-0">
                <TabContainer
                  id="account-tabs"
                  defaultActiveKey="profile-info"
                  activeKey={activeTab}
                  onSelect={(tab) => setActiveTab(tab)}
                >
                  <Nav variant="tabs" fill className="border-bottom">
                    <Nav.Item>
                      <Nav.Link eventKey="profile-info" className="fw-semibold">
                        <i className="flaticon-id-card pe-2 fs-4"></i>
                        <span className="align-text-bottom">Profile Info</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="account-info"
                        className="fw-semibold"
                        disabled={activeTab !== "account-info"}
                      >
                        <i className="flaticon-user-info pe-2 fs-4"></i>
                        <span className="align-text-bottom">Account Info</span>
                      </Nav.Link>
                    </Nav.Item>
                    {isNewPartner && (
                      <Nav.Item>
                        <Nav.Link
                          eventKey="associate-info"
                          className="fw-semibold"
                          disabled={activeTab !== "associate-info"}
                        >
                          <i className="flaticon-user-info pe-2 fs-4"></i>
                          <span className="align-text-bottom">
                            Associate Info
                          </span>
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  </Nav>
                  <Tab.Content className="p-4">
                    <Tab.Pane eventKey="profile-info">
                      <Form
                        onSubmit={handleForm1((data) =>
                          onSubmit(data, "account-info")
                        )}
                      >
                        <Row>
                          <Col xs={6} className="mb-4">
                            <InputGroup>
                              <FloatingLabel
                                label="Firstname"
                                controlId="txtFirstname"
                                htmlFor="txtFirstname"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Firstname"
                                  {...registerForm1("firstName", {
                                    required: "First name is required",
                                    pattern: {
                                      value:
                                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                      message:
                                        "First name shouldn't contain HTML or script tags",
                                    },
                                    maxLength: {
                                      value: 50,
                                      message:
                                        "First name cannot exceed 50 characters",
                                    },
                                  })}
                                />
                              </FloatingLabel>
                              <InputGroup.Text>
                                <i className="flaticon-profile fs-4"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            {errorsForm1.firstName && (
                              <Form.Text className="text-danger">
                                {errorsForm1.firstName.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={6} className="mb-4">
                            <InputGroup>
                              <FloatingLabel
                                label="Lastname"
                                controlId="txtLastname"
                                htmlFor="txtLastname"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Lastname"
                                  {...registerForm1("lastName", {
                                    required: "Last name is required",
                                    pattern: {
                                      value:
                                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                      message:
                                        "Last name shouldn't contain HTML or script tags",
                                    },
                                    maxLength: {
                                      value: 50,
                                      message:
                                        "Last name cannot exceed 50 characters",
                                    },
                                  })}
                                />
                              </FloatingLabel>
                              <InputGroup.Text>
                                <i className="flaticon-profile fs-4"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            <Form.Text muted className="d-block">
                              Your surname won't be viewable by the public
                            </Form.Text>
                            {errorsForm1.lastName && (
                              <Form.Text className="text-danger">
                                {errorsForm1.lastName.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={12} className="mb-4">
                            <InputGroup>
                              <Form.Control
                                type="file"
                                id="fileInput"
                                onChange={handleFileChange}
                                className="d-none"
                                ref={fileInputRef}
                                accept="image/jpeg, image/png"
                              />
                              <Form.Control
                                type="text"
                                placeholder="No file chosen"
                                defaultValue={selectedFile.name}
                                readOnly
                                className="rounded-start"
                              />
                              <InputGroup.Text className="p-0 border-0">
                                <Button
                                  as="label"
                                  variant="light"
                                  htmlFor="fileInput"
                                  className="btn-md rounded-0"
                                >
                                  Browse
                                </Button>
                                <Button
                                  variant="dark"
                                  className="btn-md rounded-start-0"
                                  onClick={resetFileInput}
                                >
                                  Remove
                                </Button>
                              </InputGroup.Text>
                            </InputGroup>
                            <Form.Text muted className="d-block mt-1">
                              Recommended size: 1024x576. Max file size: 1MB.
                              Supported file formats: JPG and PNG
                            </Form.Text>
                          </Col>
                          <Col xs={12} md={6} className="mb-4">
                            <Form.Label>Preview: </Form.Label>
                            <Image
                              fluid
                              src={!cropInfo.image ? avatar : cropInfo.image}
                              alt="Profile Picture"
                              className="ms-3 align-middle avatar"
                              roundedCircle
                              thumbnail
                              onError={(e) => {
                                e.target.src = avatar;
                              }}
                            />
                          </Col>
                          <Col xs={12} className="mb-4">
                            <FloatingLabel
                              controlId="txtBiography"
                              label="Summarize your role at your organization"
                            >
                              <Form.Control
                                as="textarea"
                                className="scroll"
                                placeholder="Summarize your role at your organization"
                                {...registerForm1("biography", {
                                  required: "Biography is required",
                                  pattern: {
                                    value:
                                      /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>)[\s\S])*$/i,
                                    message:
                                      "Biography shouldn't contain HTML or script tags",
                                  },
                                  maxLength: {
                                    value: 2500,
                                    message:
                                      "Biography cannot exceed 2500 characters",
                                  },
                                })}
                              />
                            </FloatingLabel>
                            <Form.Text muted className="d-block">
                              Describe how long you've been there, what
                              motivates you, and your aspirations.
                            </Form.Text>
                            {errorsForm1.biography && (
                              <Form.Text className="text-danger">
                                {errorsForm1.biography.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col className="d-grid mt-4">
                            <Button variant="primary" type="submit" size="lg">
                              NEXT
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="account-info">
                      <Form
                        onSubmit={handleForm2((data) =>
                          onSubmit(data, isNewPartner ? "associate-info" : null)
                        )}
                      >
                        <Row>
                          <Col xs={6} className="mb-4">
                            <InputGroup>
                              <FloatingLabel
                                label="Email"
                                controlId="txtEmail"
                                htmlFor="txtEmail"
                              >
                                <Form.Control
                                  type="email"
                                  placeholder="Email"
                                  {...registerForm2("email", {
                                    required: "Email is required",
                                    maxLength: 256,
                                    pattern: {
                                      value:
                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: "Invalid email address",
                                    },
                                  })}
                                />
                              </FloatingLabel>
                              <InputGroup.Text>
                                <i className="flaticon-mail fs-4"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            {errorsForm2.email && (
                              <Form.Text className="text-danger">
                                {errorsForm2.email.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={6} className="mb-4">
                            <InputGroup>
                              <FloatingLabel
                                label="Phone Number"
                                controlId="txtPhoneNumber"
                                htmlFor="txtPhoneNumber"
                              >
                                <PhoneInputWithCountry
                                  name="phoneNumber"
                                  id="txtPhoneNumber"
                                  defaultCountry="US"
                                  international={true}
                                  withCountryCallingCode={true}
                                  control={controlForm2}
                                  onChange={(val) => {
                                    triggerForm2("phoneNumber");
                                  }}
                                  maxLength={maxLength}
                                  rules={{
                                    required: "Phone number is required",
                                    validate: (value) => {
                                      if (!isPossiblePhoneNumber(value)) {
                                        setMaxLength(20);
                                        return "Invalid phone number";
                                      }
                                      return true;
                                    },
                                  }}
                                  className="form-control d-flex"
                                />
                              </FloatingLabel>
                              <InputGroup.Text>
                                <i className="flaticon-smartphone fs-3"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            {errorsForm2.phoneNumber && (
                              <Form.Text className="text-danger">
                                {errorsForm2.phoneNumber.message}
                              </Form.Text>
                            )}
                          </Col>
                          {/* <Col xs={12} className="mb-4">
                            <InputGroup>
                              <FloatingLabel
                                label="Job Title"
                                controlId="txtJobTitle"
                                htmlFor="txtJobTitle"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Job Title"
                                  {...registerForm2("jobTitle", {
                                    required: "Job title is required",
                                    pattern: {
                                      value:
                                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                      message:
                                        "Job title shouldn't contain HTML or script tags",
                                    },
                                    maxLength: {
                                      value: 100,
                                      message:
                                        "Job title cannot exceed 100 characters",
                                    },
                                  })}
                                />
                              </FloatingLabel>
                              <InputGroup.Text>
                                <i className="flaticon-suitcase fs-4"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            {errorsForm2.jobTitle && (
                              <Form.Text className="text-danger">
                                {errorsForm2.jobTitle.message}
                              </Form.Text>
                            )}
                          </Col> */}
                          <Col xs={6} className="mb-4">
                            <InputGroup>
                              <FloatingLabel
                                label="Password"
                                controlId="txtPassword"
                                htmlFor="txtPassword"
                              >
                                <Form.Control
                                  type="password"
                                  placeholder="Password"
                                  {...registerForm2("password", {
                                    required: "Password is required",
                                    pattern: {
                                      value:
                                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@@$!%*?&])[A-Za-z\d$@@$!%*?&]{6,}/,
                                      message:
                                        "Password must contain at least 6 characters, including at least one uppercase letter, one lowercase letter, one special character, and one number.",
                                    },
                                  })}
                                />
                              </FloatingLabel>
                              <InputGroup.Text>
                                <i className="flaticon-password fs-3"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            {errorsForm2.password && (
                              <Form.Text className="text-danger">
                                {errorsForm2.password.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={6} className="mb-4">
                            <InputGroup>
                              <FloatingLabel
                                label="Re-type Password"
                                controlId="txtConfirmPassword"
                                htmlFor="txtConfirmPassword"
                              >
                                <Form.Control
                                  type="password"
                                  placeholder="Password"
                                  {...registerForm2("confirmPassword", {
                                    required: "Confirm Password is required",
                                    validate: (value) =>
                                      value === password ||
                                      "Passwords do not match",
                                  })}
                                />
                              </FloatingLabel>
                              <InputGroup.Text>
                                <i className="flaticon-password fs-3"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            {errorsForm2.confirmPassword && (
                              <Form.Text className="text-danger">
                                {errorsForm2.confirmPassword.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={6} className="mt-4">
                            <Button
                              variant="primary"
                              type="button"
                              size="lg"
                              className="w-100"
                              onClick={() => setActiveTab("profile-info")}
                            >
                              BACK
                            </Button>
                          </Col>
                          <Col xs={6} className="mt-4">
                            <Button
                              type="submit"
                              variant="primary"
                              size="lg"
                              className="w-100"
                              disabled={isLoading}
                            >
                              {!isNewPartner ? (
                                isLoading ? (
                                  <Spinner animation="border" role="status">
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </Spinner>
                                ) : (
                                  "SUBMIT"
                                )
                              ) : (
                                "NEXT"
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="associate-info">
                      <Form
                        onSubmit={handleForm3((data) => onSubmit(data, null))}
                      >
                        <Row>
                          <Col xs={12} className="mb-4">
                            <small className="text-light-emphasis">
                              In case we need to reach out to an another
                              individual at your organization, kindly provide
                              the contact details of a colleague.
                            </small>
                          </Col>
                          <Col xs={6} className="mb-4">
                            <InputGroup>
                              <FloatingLabel
                                label="Associate Firstname"
                                controlId="txtAssociateFirstname"
                                htmlFor="txtAssociateFirstname"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Associate Firstname"
                                  {...registerForm3("associateFirstName", {
                                    required: "Associate firstname is required",
                                    pattern: {
                                      value:
                                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                      message:
                                        "Associate firstname shouldn't contain HTML or script tags",
                                    },
                                    maxLength: {
                                      value: 50,
                                      message:
                                        "Associate firstname cannot exceed 50 characters",
                                    },
                                  })}
                                />
                              </FloatingLabel>
                              <InputGroup.Text>
                                <i className="flaticon-profile fs-4"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            {errorsForm3.associateFirstName && (
                              <Form.Text className="text-danger">
                                {errorsForm3.associateFirstName.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={6} className="mb-4">
                            <InputGroup>
                              <FloatingLabel
                                label="Associate Lastname"
                                controlId="txtAssociateLastname"
                                htmlFor="txtAssociateLastname"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Associate Lastname"
                                  {...registerForm3("associateLastName", {
                                    required: "Associate lastname is required",
                                    pattern: {
                                      value:
                                        /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                                      message:
                                        "Associate lastname shouldn't contain HTML or script tags",
                                    },
                                    maxLength: {
                                      value: 50,
                                      message:
                                        "Associate lastname cannot exceed 50 characters",
                                    },
                                  })}
                                />
                              </FloatingLabel>
                              <InputGroup.Text>
                                <i className="flaticon-profile fs-4"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            {errorsForm3.associateLastName && (
                              <Form.Text className="text-danger">
                                {errorsForm3.associateLastName.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={12} className="mb-4">
                            <InputGroup>
                              <FloatingLabel
                                label="Associate Email"
                                controlId="txtAssociateEmail"
                                htmlFor="txtAssociateEmail"
                              >
                                <Form.Control
                                  type="email"
                                  placeholder="Email"
                                  {...registerForm3("associateEmail", {
                                    required: "Associate email is required",
                                    maxLength: 256,
                                    pattern: {
                                      value:
                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: "Invalid email address",
                                    },
                                  })}
                                />
                              </FloatingLabel>
                              <InputGroup.Text>
                                <i className="flaticon-mail fs-4"></i>
                              </InputGroup.Text>
                            </InputGroup>
                            {errorsForm3.associateEmail && (
                              <Form.Text className="text-danger">
                                {errorsForm3.associateEmail.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={12}>
                            <Form.Check
                              type="checkbox"
                              label={
                                <span>
                                  By registering, you agree to our{" "}
                                  <Link
                                    to={`${SITEURL}/about/terms-of-use`}
                                    target="_blank"
                                    title="Terms of Use"
                                    className="text-decoration-none fw-semibold"
                                  >
                                    terms of use
                                  </Link>{" "}
                                  and{" "}
                                  <Link
                                    to={`${SITEURL}/about/privacy-policy`}
                                    target="_blank"
                                    title="Privacy Policy"
                                    className="text-decoration-none fw-semibold"
                                  >
                                    privacy policy
                                  </Link>
                                  .
                                </span>
                              }
                              className="fw-light text-dark-emphasis fs-small"
                              {...registerForm3("privacy", {
                                required: "Agree to the terms and conditions.",
                              })}
                            />
                            {errorsForm3.privacy && (
                              <Form.Text className="text-danger d-block">
                                {errorsForm3.privacy.message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={12} className="mt-3">
                            <Form.Check
                              type="checkbox"
                              label={
                                <span>
                                  Becoming a Benevolent Validator requires
                                  reading and agreeing to the{" "}
                                  <Link
                                    to={`${SITEURL}/about/organizational-agreement`}
                                    target="_blank"
                                    title="Organization Grant Agreement"
                                    className="text-decoration-none fw-semibold"
                                  >
                                    Organization Grant Agreement
                                  </Link>
                                  .
                                </span>
                              }
                              className="fw-light text-dark-emphasis fs-small"
                              {...registerForm3("organization-agreement", {
                                required:
                                  "Agree to the Organization Grant Agreement.",
                              })}
                            />
                            {errorsForm3["organization-agreement"] && (
                              <Form.Text className="text-danger d-block">
                                {errorsForm3["organization-agreement"].message}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={6} className="mt-4">
                            <Button
                              variant="primary"
                              type="button"
                              size="lg"
                              className="w-100"
                              onClick={() => setActiveTab("account-info")}
                            >
                              BACK
                            </Button>
                          </Col>
                          <Col xs={6} className="mt-4">
                            <Button
                              type="submit"
                              variant="primary"
                              size="lg"
                              className="w-100"
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <Spinner animation="border" role="status">
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </Spinner>
                              ) : (
                                "SUBMIT"
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Tab.Pane>
                  </Tab.Content>
                </TabContainer>
              </Card.Body>
            </Card>
            <div className="text-center mt-4 text-dark-emphasis">
              Already Registered?{" "}
              <Link
                to="/"
                title="Sign In"
                className="text-decoration-none link-secondary fw-semibold"
              >
                Sign In
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <ImageCrop
        showCropModal={showCropModal}
        closeCropModal={closeCropModal}
        selectedFile={selectedFile}
        updateAvatar={updateAvatar}
        resetFileInput={resetFileInput}
      />
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default CreateAccount;
