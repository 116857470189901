import React, { useState, useEffect, useReducer, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Modal,
  Form,
  FloatingLabel,
  InputGroup,
  Button,
  Spinner,
  Alert,
  Image,
  Card,
  Dropdown,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { postData, putData, getData } from "../../services/apiService";
import { useAuthContext } from "../../context/authProvider";
import { notify, initialState } from "../../store/notification";
import { Icons } from "../../components/iconImporter";
import DataTable from "../../components/table";
import confirmation from "../../resources/images/confirmation.png";

/**
 * Component for managing categories.
 * @returns {React.ReactElement} - Returns JSX for managing categories.
 * @access Accessible by SuperAdmin and Staff.
 */
const ManageCategory = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState({ PageNumber: 1, PageSize: 25 });
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);

  // Columns configuration for the category table
  const columns = [
    {
      header: "Name",
      accessorKey: "name",
      cell: (cellProps) => {
        const index = Icons.findIndex(
          (x) => x.value === cellProps.row.original.icon
        );
        return (
          <Fragment>
            <Image
              src={Icons[index].icon}
              alt={Icons[index].value}
              width="20"
              className="me-2 align-text-bottom"
            />
            <span>{cellProps.row.original.name}</span>
          </Fragment>
        );
      },
    },
    {
      header: "No Of Needs",
      cell: (cellProps) => {
        return (
          <Fragment>
            {cellProps.row.original.totalNeeds === 0 ? (
              0
            ) : (
              <Link
                to={`/category/needs/${cellProps.row.original.id}`}
                state={{
                  name: cellProps.row.original.name,
                }}
                className="text-decoration-none fw-semibold"
              >
                {cellProps.row.original.totalNeeds}
              </Link>
            )}
          </Fragment>
        );
      },
    },
    {
      header: "Created On",
      accessorKey: "createdOn",
    },
    {
      header: "Actions",
      accessorKey: "id",
      cell: ({ getValue }) => (
        <Dropdown drop="down" className="more-menu">
          <Dropdown.Toggle variant="light" size="sm">
            <i className="flaticon-more"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => getById(getValue())}>
              <i className="flaticon-edit fs-6 me-1"></i>Edit
            </Dropdown.Item>
            <Dropdown.Item onClick={() => showConfirmation(getValue())}>
              <i className="flaticon-delete fs-6 me-1 align-text-bottom"></i>
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];

  /**
   * Function to open modal
   */
  const openModal = () => {
    setShowModal(true);
  };

  /**
   * Function to close modal and reset form
   */
  const closeModal = () => {
    reset({
      id: "",
      name: "",
      icon: "",
    });
    setIsLoading(false);
    setShowModal(false);
  };

  /**
   * Hook to fetch the categories
   * Executes when there is a change in the values of the dependencies in the useEffect hook.
   */
  useEffect(() => {
    /**
     * Function to fetch the categories from the backend API
     */
    const getAll = async () => {
      setIsLoading(true);
      try {
        const response = await postData(
          `/api/category/getByPage?searchTerm=${searchTerm}`,
          page,
          userSession
        );
        setCategories(response.categories);
        if (response.count != null) setTotalRecords(response.count);
        setIsLoading(false);
      } catch (error) {
        // Show a notification if an error occurs
        showNotification("danger", error, 5000);
        setCategories([]);
        setIsLoading(false);
      }
    };

    getAll();

    // Return a cleanup function
    return () => {
      // Cleanup logic
      // You can add more cleanup actions here if needed
    };
  }, [searchTerm, page, userSession]);


  /**
   * Function to add a new category or update an existing one based on the provided data.
   * @param {Object} data - The data of the category to add or update.
   */
  const addOrUpdate = async (data) => {
    setIsLoading(true);
    try {
      let response;
      if (!data.id)
        // If data has no ID, it's a new category, so create it
        response = await postData("/api/category/create", data, userSession);
      // Otherwise, it's an existing category, so update it
      else response = await putData("/api/category/update", data, userSession);
      closeModal();
      showNotification("success", response, 5000);

      setPage((prevPage) => ({
        ...prevPage,
        pageNumber: prevPage.pageNumber,
      }));
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setIsLoading(false);
    }
  };

  /**
   * Function to fetch the category data by its ID and opens the modal for editing.
   * @param {number} id - The ID of the category to fetch.
   */
  const getById = async (id) => {
    setIsPageLoading(true);
    try {
      const response = await getData(
        `/api/category/getById`,
        { id: id },
        userSession
      );
      setIsPageLoading(false);
      openModal();
      // Reset form fields with fetched category data
      reset(response);
    } catch (error) {
      setIsPageLoading(false);
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
    }
  };

  /**
   * Function to handle deletion of a category by updating their status to "Deleted".
   * @param {number} id - The ID of the category to be deleted.
   */
  const updateStatus = async (id) => {
    setIsPageLoading(true);
    try {
      const response = await putData(
        `/api/category/update-status/${id}`,
        null,
        userSession
      );
      setIsPageLoading(false);
      showNotification("success", response, 5000);
      // Update page number to trigger re-fetching of categories
      setPage((prevPage) => ({
        ...prevPage,
        pageNumber: prevPage.pageNumber,
      }));
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  /**
   * Function to display a confirmation dialog for deleting a category.
   * @param {number} id - The ID of the category to be deleted.
   */
  const showConfirmation = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Card className="border-0 shadow rounded-3">
            <Card.Body className="p-4 text-center">
              <Image src={confirmation} alt="Delete Confirmation" fluid />
              <div className="mt-3 fw-light">
                Do you really want to delete this category? <br />
                This process can't be undone.
              </div>
            </Card.Body>
            <Card.Footer className="bg-transparent py-4 text-center">
              <Button
                title="Cancel"
                variant="link"
                className="link-danger fw-semibold"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                className="ms-4 btn-md"
                onClick={async () => {
                  try {
                    await updateStatus(id);
                  } catch (error) {
                    // showNotification("danger", error, 5000);
                  }
                  onClose();
                }}
              >
                Yes, Delete
              </Button>
            </Card.Footer>
          </Card>
        );
      },
    });
  };

  /**
   * Function to handle pagination change.
   * @param {number} pageNumber - The new page number.
   * @param {number} pageSize - The new page size.
   */
  const handlePageChange = (pageNumber, pageSize) => {
    setCategories([]);
    setPage({
      PageNumber: pageNumber,
      PageSize: pageSize,
    });
  };

  /**
   * Function to handle search action.
   * @param {string} value - The search term entered by the user.
   */
  const handleSearch = (value) => {
    handlePageChange(1, page.PageSize);
    setSearchTerm(value);
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-semibold mb-0">Manage Categories</h6>
          <Link onClick={openModal} className="ms-3 btn btn-secondary">
            + Add
          </Link>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            {/* DataTable component for listing categories */}
            <DataTable
              columns={columns}
              data={categories}
              totalRecords={totalRecords}
              page={page}
              onPageChange={handlePageChange}
              searchRecords={handleSearch}
              loadingState={isLoading}
            />
          </Card.Body>
        </Card>
      </Container>
      {/* Modal for adding/editing categories */}
      <Modal
        show={showModal}
        aria-labelledby="Manage Category"
        centered
        className="modal-feature"
      >
        <Modal.Header>
          <Modal.Title as="h6" className="fw-bold m-auto module-title">
            Create a Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-5">
          <Form onSubmit={handleSubmit(addOrUpdate)}>
            <Form.Control type="hidden" {...register("id")} />
            <Form.Group className="mb-4">
              <InputGroup>
                <FloatingLabel
                  label="Name"
                  controlId="txtName"
                  htmlFor="txtName"
                >
                  <Form.Control
                    type="text"
                    defaultValue=""
                    placeholder="Name"
                    {...register("name", {
                      required: "Name is required",
                      pattern: {
                        value:
                          /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                        message:
                          "Category name shouldn't contain HTML or script tags",
                      },
                      maxLength: {
                        value: 100,
                        message: "Category name cannot exceed 100 characters",
                      },
                    })}
                  />
                </FloatingLabel>
                <InputGroup.Text>
                  <i className="flaticon-category fs-4"></i>
                </InputGroup.Text>
              </InputGroup>
              {errors.name && (
                <Form.Text className="text-danger">
                  {errors.name.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-5">
              <Form.Label htmlFor="ddIcon">Icon</Form.Label>
              <Controller
                control={control}
                name="icon"
                id="ddIcon"
                rules={{ required: "Icon is required" }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    // menuIsOpen
                    isClearable={true}
                    placeholder="Choose Icon"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={Icons}
                    value={Icons.find((c) => c.value === value)}
                    onChange={(val) => onChange(val?.value || "")}
                    getOptionLabel={(e) => (
                      <Fragment>
                        {e.icon && (
                          <Image
                            src={e.icon}
                            alt={e.value}
                            width="16"
                            className="me-2 align-top"
                          />
                        )}
                        <span>{e.text}</span>
                      </Fragment>
                    )}
                  />
                )}
              />
              {errors.icon && (
                <Form.Text className="text-danger">
                  {errors.icon.message}
                </Form.Text>
              )}
            </Form.Group>

            <div className="text-end">
              <Button
                type="submit"
                variant="primary"
                className="btn-md me-3"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  "Save"
                )}
              </Button>
              <Button
                variant="secondary"
                onClick={closeModal}
                className="btn-md"
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* Notification alert */}
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default ManageCategory;
