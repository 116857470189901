import React, { useState, useEffect, useRef, useReducer, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Button,
  Container,
  Spinner,
  Alert,
  ListGroup,
  Modal,
  Image,
} from "react-bootstrap";

import { transformProfilePicture } from "../../components/transformFileURL";

import { postData } from "../../services/apiService";
import { NeedProfileURL } from "../../constants";
import { notify, initialState } from "../../store/notification";
import { useAuthContext } from "../../context/authProvider";

/**
 * Component for managing featured needs.
 * Allows reordering and saving the display order of featured needs.
 * @returns {React.Element} - Returns JSX for managing featured needs.
 */
const ManageFeaturedNeeds = () => {
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  const { userSession } = useAuthContext();

  // Reference for the dragged item during drag and drop.
  const dragItem = useRef();
  // Reference for the item over which the dragged item is being dragged.
  const dragOverItem = useRef();

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [needs, setNeeds] = useState([]);
  const [needIds, setNeedIds] = useState([]);
  // State to indicate whether an item is being dragged.
  const [isDragged, setIsDragged] = useState(false);

  /**
   * Function to handle reordering of needs when a drag and drop operation is performed to reorder the needs.
   */
  const handleReordering = async () => {
    // Create a copy of the 'needs' array to avoid modifying the original data.
    let copiedNeeds = [...needs];
    // Remove the dragged item from its original position.
    const draggedItemContent = copiedNeeds.splice(dragItem.current, 1)[0];
    // Insert the dragged item into its new position.
    copiedNeeds.splice(dragOverItem.current, 0, draggedItemContent);
    // Reset the drag item references.
    dragItem.current = null;
    dragOverItem.current = null;
    // Update the 'needs' state with the new order.
    setNeeds(copiedNeeds);
    const needIds = copiedNeeds.map(function (need) {
      return need.needId;
    });
    setNeedIds(needIds);
    // Set a flag to indicate that a drag operation has occurred.
    setIsDragged(true);
  };

  /**
   * Function to save the display order of the featured needs 
   * after reordering via the backend API.
   */
  const saveDisplayOrder = async () => {
      if (needIds.length > 0) {
          setIsPageLoading(true);
          try {
              const response = await postData(
                  "/api/need/reorder-featured-needs",
                  { needIds },
                  userSession
              );
              setIsDragged(false);
              setIsPageLoading(false);
              showNotification("success", response, 5000);
          } catch (error) {
              setNeeds([]);
              setIsPageLoading(false);
          }
      }
      else
          showNotification("danger", "Currently, there have been no modifications to the order of the needs", 5000);
  };

  /**
 * Function to fetch the featured needs from the backend API.
 */
  const getFeaturedNeeds = useCallback(async () => {
    try {
      const response = await postData(
        "/api/need/getNeedsByType",
        { type: "Featured" },
        userSession
      );
      const updatedNeeds = transformProfilePicture(response.needs);
      setNeeds(updatedNeeds);
    } catch (error) {
      setNeeds([]);
    }
  },[userSession]);

  useEffect(() => {
    /**
     * Hook to fetch the featured needs when the component mounts.
     */
    getFeaturedNeeds();
  }, [getFeaturedNeeds]);

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-semibold mb-0">
            Reorder Featured Needs
          </h6>
          <Link
            to="/needs"
            className="ms-3 btn btn-secondary"
            title="Back to Needs"
          >
            Back
          </Link>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <Row className="align-items-center mb-4">
              <Col>
                <p className="mb-0 text-dark-emphasis">
                  Rearrange the page titles below to change their order on the
                  need listing pages.
                </p>
              </Col>
              <Col className="text-end">
                <Button
                  variant="primary"
                  onClick={saveDisplayOrder}
                  disabled={!isDragged && "disabled"}
                  className="btn-md"
                >
                  Save
                </Button>
                <Button
                  variant="secondary"
                  onClick={getFeaturedNeeds}
                  className="btn-md ms-3"
                >
                  Reset
                </Button>
              </Col>
            </Row>
            <ListGroup className="featured-needs">
              {needs.map((need, index) => (
                <ListGroup.Item
                  as="li"
                  key={need.referenceId}
                  className="bg-transparent border rounded-3 my-1 p-3"
                  draggable
                  onDragStart={(e) => (dragItem.current = index)}
                  onDragEnter={(e) => (dragOverItem.current = index)}
                  onDragEnd={handleReordering}
                >
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <Image
                        src={need.profilePicture}
                        alt="Benevolent"
                        roundedCircle
                        thumbnail
                        fluid
                      />
                    </div>
                    <div className="ms-3 flex-grow-1">
                      <p className="mb-1 fw-semibold need-title">
                        <Link
                          to={`${NeedProfileURL}${need.referenceId}/${need.needId}`}
                          className="text-decoration-none link-dark"
                        >
                          {need.title}
                        </Link>
                      </p>
                      <p className="mb-0 fs-small text-dark-emphasis fw-light">
                        <span className="me-2">
                          REFERENCE ID:{" "}
                          <span className="fw-semibold">
                            {need.referenceId}
                          </span>
                        </span>
                        |
                        <span className="mx-2">
                          RECIPIENT:{" "}
                          <span className="fw-semibold">
                            {need.displayName}
                          </span>
                        </span>
                        |
                        <span className="ms-2">
                          VALIDATED BY:{" "}
                          <span className="fw-semibold">
                            {need.validatorName}
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
        </Card>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default ManageFeaturedNeeds;
