import React, { Fragment, useState, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import { Card, Image, Button, Badge, Alert } from "react-bootstrap";

import DataTable from "../../../components/table";
import Export from "../../../components/export";
import { transformProfilePicture } from "../../../components/transformFileURL";

import { postData } from "../../../services/apiService";
import { notify, initialState } from "../../../store/notification";
import { ValidatorProfileURL } from "../../../constants";

/**
 * Component for managing needs associated with an organization.
 * This component is utilized to showcase needs specifically tailored for organization's private profiles.
 * @param {Object} props - The props object.
 * @param {string} props.referenceId - The reference ID of the organization whose needs are managed.
 * @returns {React.Element} - Returns JSX for managing needs.
 */
const ManageNeeds = (props) => {
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState({ PageNumber: 1, PageSize: 25 });
  const [needs, setNeeds] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  // State and functions for controlling the Export Modal
  const [showExportModal, setShowExportModal] = useState(false);
  const closeExportModal = () => setShowExportModal(false);
  const openExportModal = () => setShowExportModal(true);

  // Columns configuration for the need table
  const columns = [
    {
      header: "Recipient",
      cell: (cellProps) => {
        return (
          <Fragment>
            <div className="d-flex align-items-center">
              <Image
                src={cellProps.row.original.profilePicture}
                alt="Benevolent"
                roundedCircle
                thumbnail
                fluid
                className="avatar"
              />
              <div className="ms-3 text-dark">
                {cellProps.row.original.firstName}{" "}
                {cellProps.row.original.lastName}
                <p className="mb-0 text-dark-emphasis">
                  <small className="fw-semibold">Alias: </small>
                  <small>{cellProps.row.original.displayName}</small>
                </p>
              </div>
            </div>
          </Fragment>
        );
      },
    },
    {
      header: "Need",
      cell: (cellProps) => {
        return (
          <Fragment>
            <h6 className="text-dark">
              {cellProps.row.original.title
                ? cellProps.row.original.title
                : "[Untitled]"}
            </h6>
            <div className="fw-light fs-small">
              <span>Need Id: {cellProps.row.original.needId}</span>
              <span className="d-block">
                By{" "}
                <Link
                  to={`${ValidatorProfileURL}${cellProps.row.original.validatorReferenceId}`}
                  className="text-decoration-none fw-semibold"
                >
                  {cellProps.row.original.validatorName}
                </Link>
              </span>
            </div>
          </Fragment>
        );
      },
    },
    {
      header: "Days Left",
      accessorKey: "daysLeft",
    },
    {
      header: "Progress",
      cell: (cellProps) => {
        return (
          <Fragment>
            <div className="fw-light fs-small">
              <span className="fw-semibold">
                ${cellProps.row.original.raised}
              </span>
              <span className="fw-light px-1">of</span>
              <span className="fw-semibold">
                ${cellProps.row.original.amountNeeded}
              </span>
            </div>
          </Fragment>
        );
      },
    },
    {
      header: "Status",
      cell: (cellProps) => {
        let background;
        switch (cellProps.row.original.needStatus) {
          case "Draft":
            background = "light";
            break;
          case "Awaiting Approval":
            background = "warning";
            break;
          case "Published":
            background = "info";
            break;
          case "Funded":
            background = "success";
            break;
          case "Expired":
          case "Closed":
            background = "danger";
            break;
          default:
            background = "dark";
            break;
        }
        return (
          <Badge className="fw-semibold text-dark" bg={background}>
            {cellProps.row.original.needStatus.toUpperCase()}
          </Badge>
        );
      },
    },
  ];

  /**
   * Function to handle pagination change.
   * @param {number} pageNumber - The new page number.
   * @param {number} pageSize - The new page size.
   */
  const handlePageChange = (pageNumber, pageSize) => {
    setNeeds([]);
    setPage({
      PageNumber: pageNumber,
      PageSize: pageSize,
    });
  };

  /**
   * Function to handle search action.
   * @param {string} value - The search term entered by the user.
   */
  const handleSearch = (value) => {
    handlePageChange(1, page.PageSize);
    setSearchTerm(value);
  };

  useEffect(() => {
    /**
     * Fetches the needs with statuses other than 'draft' based on the organization reference ID from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getAll = async () => {
      setIsLoading(true);
      try {
        const response = await postData(`/api/profile/getValidatedNeeds`, {
          referenceId: props.referenceId,
          type: "Organization",
          page: page,
          searchTerm: searchTerm,
          isPrivateProfile: true,
        });

        const updatedNeeds = transformProfilePicture(response.needs);
        setNeeds(updatedNeeds);
        if (response.count != null) setTotalRecords(response.count);
        setIsLoading(false);
      } catch (error) {
        setNeeds([]);
        setIsLoading(false);
      }
    };
    if (props.referenceId) getAll();

    // Return a cleanup function
    return () => {};
  }, [searchTerm, page, props.referenceId]);

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <Fragment>
      <div className="text-end mb-4 me-3">
        <Button variant="secondary" onClick={openExportModal}>
          Export
        </Button>
      </div>
      <Card className="border-0 rounded-3">
        <Card.Body className="px-0">
          <DataTable
            columns={columns}
            data={needs}
            totalRecords={totalRecords}
            page={page}
            onPageChange={handlePageChange}
            searchRecords={handleSearch}
            loadingState={isLoading}
          />
        </Card.Body>
      </Card>

      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Export
        featureName="Need"
        orgReferenceId={props.referenceId}
        showExportModal={showExportModal}
        closeExportModal={closeExportModal}
        showNotification={showNotification}
      />
    </Fragment>
  );
};

export default ManageNeeds;
