import React, { useState, useEffect, Fragment } from "react";
import { Row, Col, Card, Image, Spinner } from "react-bootstrap";

import { getData } from "../../services/apiService";

import Donors from "../../resources/images/active-donors.png";
import Donation from "../../resources/images/donation.png";

/**
 * Component for exploring system overview.
 * This component provides an overview of the system including total funded needs,
 * donors, and raised funds.
 * @returns {React.Element} - Returns JSX for displaying system overview.
 */
const ExploreInsights = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [report, setReport] = useState({
    totalDonors: 0,
    totalRaised: 0,
    totalFundedNeeds: 0,
  });

  useEffect(() => {
    /**
     * Fetches an overview of the system from the backend API
     */
    const getSystemSummaryReport = async () => {
      try {
        const response = await getData(`/api/dashboard/getOverview`);
        setIsLoading(false);
        if (response) animateNumbers(response);
      } catch (error) {
        setIsLoading(false);
      }
    };
    getSystemSummaryReport();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Function to format an amount to thousands (K), millions (M), or billions (B)
   * @param {number} amount - The amount to be formatted
   * @returns {string} - A string representing the formatted amount.
   */
  const formatAmount = (amount) => {
    let formattedAmount = amount;
    let suffix = "";

    if (amount >= 1_000_000_000) {
      // Convert to billions
      formattedAmount = Math.round(amount / 1_000_000_000);
      suffix = "B";
    } else if (amount >= 1_000_000) {
      // Convert to millions
      formattedAmount = Math.round(amount / 1_000_000);
      suffix = "M";
    } else if (amount >= 1_000) {
      // Convert to thousands
      formattedAmount = Math.round(amount / 1_000);
      suffix = "K";
    } else {
      // No conversion needed for amounts less than 1,000
      formattedAmount = Math.round(amount);
    }

    // Return the formatted amount with suffix
    return `$${formattedAmount} ${suffix}`;
  };

  /**
   * Function to formats a number in the US numbering style (using commas as thousands separators).
   * @param {number} number - The input number to be formatted.
   * @returns {string} - A string representing the formatted number in US style.
   */
  const formatCountUSStyle = (number) => {
    // Create a formatter for US locale
    const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: 0, // Minimum number of decimal places
      maximumFractionDigits: 0, // Maximum number of decimal places
    });

    // Return the formatted count
    return formatter.format(number);
  };

  /**
   * Function to animate the values in the `report` state from the current values
   * to the target values specified in the `data` parameter.
   * @param {Object} data - The target data containing the final values to animate to.
   */
  const animateNumbers = (data) => {
    const animationDuration = 2000; // Duration in milliseconds
    const frameRate = 16; // Interval between updates in milliseconds
    const totalFrames = animationDuration / frameRate;

    // Calculate the increments
    const incrementDonors = data.totalDonors / totalFrames;
    const incrementRaised = data.totalRaised / totalFrames;
    const incrementFundedNeeds = data.totalFundedNeeds / totalFrames;

    let currentFrame = 0;
    // Set an interval to update the values
    const updateValues = () => {
      currentFrame += 1;

      // Calculate the new values
      const newTotalDonors =
        report.totalDonors + incrementDonors * currentFrame;
      const newTotalRaised =
        report.totalRaised + incrementRaised * currentFrame;
      const newTotalFundedNeeds =
        report.totalFundedNeeds + incrementFundedNeeds * currentFrame;

      // Update the state with the new values
      setReport({
        totalDonors: Math.round(newTotalDonors),
        totalRaised: newTotalRaised,
        totalFundedNeeds: Math.round(newTotalFundedNeeds),
      });

      // Stop the animation when the target frame count is reached
      if (currentFrame < totalFrames) requestAnimationFrame(updateValues);
      else setReport(data); // Ensure the final values are accurate
    };
    // It's specifically designed to synchronize animations with the browser's refresh rate, 
    // typically around 60 times per second (60fps), resulting in smoother and more efficient animations 
    // compared to other methods like setTimeout or setInterval.
    requestAnimationFrame(updateValues);
  };

  return (
    <Row className="explore-insights m-0 justify-content-center">
      <Col
        xs={"auto"}
        aria-labelledby="Loading"
        className={`p-3 text-center ${isLoading || "d-none"}`}
      >
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Col>
      {!isLoading && (
        <Fragment>
          <Col xs={12} md={4} lg={3} xl={3} xxl={2} className="my-3">
            <Card className="border-0 p-4 text-center rounded-4">
              <Image src={Donation} alt="Donated So Far" className="m-auto" />
              <h2 className="fw-semibold mt-3 mb-1 text-secondary">
                {formatAmount(report.totalRaised)}
              </h2>
              <span className="text-dark-emphasis">Donated So Far</span>
            </Card>
          </Col>
          <Col xs={12} md={4} lg={3} xl={3} xxl={2} className="my-3">
            <Card className="border-0 p-4 text-center rounded-4">
              <Image src={Donors} alt="Active Donors" className="m-auto" />
              <h2 className="fw-semibold mt-3 mb-1 text-secondary">
                {formatCountUSStyle(report.totalDonors)}
              </h2>
              <span className="text-dark-emphasis">Active Donors</span>
            </Card>
          </Col>
          <Col xs={12} md={4} lg={3} xl={3} xxl={2} className="my-3">
            <Card className="border-0 p-4 text-center rounded-4">
              <Image src={Donation} alt="Needs Met" className="m-auto" />
              <h2 className="fw-semibold mt-3 mb-1 text-secondary">
                {formatCountUSStyle(report.totalFundedNeeds)}
              </h2>
              <span className="text-dark-emphasis">Needs Met</span>
            </Card>
          </Col>
        </Fragment>
      )}
    </Row>
  );
};

export default ExploreInsights;
