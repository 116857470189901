// Styles configuration for the text editor
export const styles = [
  {
    name: "Layout",
    element: "table",
    attributes: {
      style: "margin: 0 auto;border: 0;border-collapse: collapse;width: 600px;",
    },
    isInline: true,
  },
  {
    name: "Layout Gutter",
    element: "td",
    attributes: {
      style: "padding: 30px; border: 1px solid #f0f0f0;",
    },
    isInline: true,
  },
  {
    name: "Logo",
    element: "td",
    attributes: {
      style: "padding: 30px 0;",
    },
    isInline: true,
  },
  {
    name: "Title",
    element: "h3",
    attributes: {
      style:
        'margin: 0;color: #294661;font-family:"Poppins",Helvetica,Arial,sans-serif;font-weight:bold',
    },
    isInline: true,
  },
  {
    name: "Content",
    element: "p",
    attributes: {
      style:
        'margin: 0;font-size: 15px;line-height: 1.5;color: #294661;font-family:"Poppins",Helvetica,Arial,sans-serif',
    },
    isInline: true,
  },
  {
    name: "Gutter",
    element: "td",
    attributes: {
      style: "padding: 10px 0 10px 0; border: 0",
    },
    isInline: true,
  },
  {
    name: "Closing",
    element: "td",
    attributes: {
      style: "padding: 10px 0 0 0; border: 0",
    },
    isInline: true,
  },
  {
    name: "td Borderless",
    element: "td",
    attributes: {
      style: "border: 0",
    },
    isInline: true,
  },
  {
    name: "Table Borderless",
    element: "table",
    attributes: {
      style: "border-collapse: collapse;border: 0px;",
    },
    isInline: true,
  },
  {
    name: "Call to Action",
    element: "a",
    attributes: {
      style:
        "height:36px;line-height:36px;text-decoration:none;display:inline-block;color:#fff !important;background-color:#f39400;border-radius:2px;border:0px;font-size:15px;padding:0 32px !important",
    },
    isInline: true,
  },
  {
    name: "Link",
    element: "a",
    attributes: {
      style: "color:#348eda;text-decoration:none;",
    },
    isInline: true,
  },
];

// Toolbar configuration for the text editor
export const toolbar = [
  {
    name: "document",
    items: ["Source"],
  },
  {
    name: "clipboard",
    items: ["Cut", "Copy", "Paste", "-", "Undo", "Redo"],
  },
  {
    name: "forms",
    items: ["Button"],
  },
  "/",
  {
    name: "styles",
    items: ["Styles", "Format", "Font", "FontSize", "TextColor", "BGColor"],
  },
  {
    name: "basicStyles",
    items: ["Bold", "Italic", "Underline", "RemoveFormat"],
  },
  {
    name: "paragraph",
    items: [
      "NumberedList",
      "BulletedList",
      "-",
      "Outdent",
      "Indent",
      "-",
      "Blockquote",
      "-",
      "JustifyLeft",
      "JustifyCenter",
      "JustifyRight",
      "JustifyBlock",
    ],
  },
  {
    name: "links",
    items: ["Link", "Unlink"],
  },
  {
    name: "insert",
    items: ["Image", "Table", "HorizontalRule", "SpecialChar"],
  },
  "/",
  { name: "tools", items: ["Maximize", "ShowBlocks"] },
];
