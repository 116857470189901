import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Badge } from "react-bootstrap";

import DataTable from "../../../components/table";

import { getData } from "../../../services/apiService";

/**
 * Component displays a list of recent donations.
 * This component displays the top 5 donations based on the user's role.
 * If the user is a Validator,  only donations pertaining to the needs created by them are displayed.
 * If the user is a SuperAdmin or Staff, donations from the overall system are displayed.
 * @param {Object} props - The properties passed to the component.
 * @returns {React.ReactElement} - Returns JSX for displaying recent donations.
 * @access Accessible by SuperAdmin, Staff, or Validator.
 */
const RecentDonations = (props) => {
  const [donations, setDonations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Columns Configuration for recent donations table
  const columns = [
    {
      header: "Date",
      accessorKey: "paidOn",
    },
    {
      header: "Order Id",
      accessorKey: "transactionId",
    },
    {
      header: "Source",
      accessorKey: "source",
    },
    {
      header: "Donor",
      accessorKey: "donor",
    },
    {
      header: "Donation Amount",
      cell: (cellProps) => {
        return <Fragment> ${cellProps.row.original.donationAmount}</Fragment>;
      },
    },
    {
      header: "Additional Gift",
      cell: (cellProps) => {
        return <Fragment> ${cellProps.row.original.contribution}</Fragment>;
      },
    },
    {
      header: "Processing Fee",
      cell: (cellProps) => {
        return <Fragment> ${cellProps.row.original.processingFee}</Fragment>;
      },
    },
    {
      header: "Transaction Amount",
      cell: (cellProps) => {
        return (
          <Fragment> ${cellProps.row.original.transactionAmount}</Fragment>
        );
      },
    },
    {
      header: "Net Amount",
      cell: (cellProps) => {
        return <Fragment> ${cellProps.row.original.netAmount}</Fragment>;
      },
    },

    {
      header: "Tribute",
      cell: (cellProps) => {
        return (
          <Fragment>
            {cellProps.row.original.hasTribute && (
              <i className="flaticon-tick fs-3 d-inline-block text-center bg-secondary text-white rounded-circle hasTribute"></i>
            )}
          </Fragment>
        );
      },
    },
    {
      header: "Status",
      cell: (cellProps) => {
        let background;
        switch (cellProps.row.original.status) {
          case "Initiated":
            background = "light";
            break;
          case "Success":
            background = "success";
            break;
          case "Processing":
            background = "info";
            break;
          case "Failed":
            background = "danger";
            break;
          case "Canceled":
            background = "warning";
            break;
          default:
            background = "dark";
            break;
        }
        return (
          <Badge className="fw-semibold text-dark" bg={background}>
            {cellProps.row.original.status.toUpperCase()}
          </Badge>
        );
      },
    },
  ];

  useEffect(() => {
    /**
     * Fetches the recent donations from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getRecentDonations = async () => {
      setIsLoading(true);
      try {
        const response = await getData(
          `/api/dashboard/getRecentDonations`,
          null,
          props.userSession
        );
        setDonations(response);
        setIsLoading(false);
      } catch (error) {
        setDonations([]);
        setIsLoading(false);
      }
    };
    getRecentDonations();
    // Return a cleanup function
    return () => {
    };
  }, [props]);

  return (
    <Card className="border-0 rounded-3 my-2 transactions">
      <Card.Body className="p-4">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="fw-semibold">Recent Donations</h6>
          {(props.roles.includes("SuperAdmin") ||
            props.roles.includes("Staff")) && (
            <Link
              to="/transactions"
              title="View All"
              className="btn btn-light btn-sm"
            >
              VIEW ALL
            </Link>
          )}
        </div>
        {/* DataTable component for listing recent donations of needs */}
        <DataTable
          columns={columns}
          data={donations}
          totalRecords={donations.length}
          loadingState={isLoading}
          page={{ PageNumber: 1, PageSize: 5 }}
        />
      </Card.Body>
    </Card>
  );
};

export default RecentDonations;
