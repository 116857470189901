import React, {
  Fragment,
  useState,
  useEffect,
  useReducer,
  useRef,
  useCallback,
} from "react";
import { Link, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Form,
  Button,
  FloatingLabel,
  Spinner,
  Alert,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

import { transformProfilePicture } from "../../components/transformFileURL";
import { getData, postData } from "../../services/apiService";
import { notify, initialState } from "../../store/notification";
import { useAuthContext } from "../../context/authProvider";

// By defining defaultValues outside the component, they remain constant and do not cause re-renders.
// This approach ensures that our defaultValues are static and the useEffect dependencies are correctly set, 
// avoiding any unnecessary warnings or re-renders.
const defaultValues = {
  id: 0,
  status: "Active",
  needType: "All",
  primaryColor: "#f39400",
  secondaryColor: "#85355f",
};

/**
 * Component for creating embed codes.
 * @returns {JSX.Element} - Returns JSX for creating embed codes.
 */
const CreateEmbedCode = () => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    trigger,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();  

  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  // Hook extracts the value of the 'id' parameter from the URL.
  // `id` used to fetch the embed code data
  var { id } = useParams();
  id = id ? parseInt(id) : 0;
  const [loadingStates, setLoadingStates] = useState({});
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isPreview, setIsPreview] = useState(false);
  const [isPublic, setIsPublic] = useState(false);

  const givingPageURL = "/preview/";
  // Reference for iframe
  const iframeRef = useRef();

  /**
   * Callback function to handle the onLoad event of an iframe.
   * @param {Event} event - The event object representing the iframe's onLoad event.
   */
  const handleIframeLoad = (event) => {
    iframeRef.current = event.target;
  };

  /**
   * Function to update the search term state.
   * @param {string} newValue - The new value entered by the user in the search input.
   */
  const handleSearchInput = (newValue) => {
    setSearchTerm(newValue);
  };

  /**
   * Custom filter function for filtering options in the organization dropdown.
   * @param {Object} option - The option to be filtered.
   * @param {string} searchText - The text to search for.
   * @returns {boolean} - Indicates whether the option matches the search text.
   */
  const customFilterOption = (option, searchText) => {
    return option.data.label.toLowerCase().includes(searchText.toLowerCase());
  };

  useEffect(() => {
    /**
     * Fetches the organizations from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const searchOrganization = async () => {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        inSearch: true,
      }));
      try {
        const response = await getData(
          `/api/organization/search?searchTerm=${searchTerm}`,
          null,
          null
        );
        setOrganizations(response);
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          inSearch: false,
        }));
      } catch (error) {
        // Show error notification if an error occurs
        showNotification("danger", error, 5000);
        setOrganizations([]);
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          inSearch: false,
        }));
      }
    };
    if (searchTerm) searchOrganization();
    else setOrganizations([]);
  }, [searchTerm]);

  /**
   * Adds or updates an organization's giving page.
   * @param {Object} data - The data representing the giving page to be added or updated.
   */
  const addOrUpdate = async (data) => {
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      isSubmitted: true,
    }));
    try {
      const response = await postData(
        "/api/fundraising/upsertOrgGivingPage",
        data,
        userSession
      );
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        isSubmitted: false,
      }));

      if (data.id === 0) resetForm();
      // Show success notification
      showNotification("success", response, 5000);
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        isSubmitted: false,
      }));
    }
  };


  /**
   * Function to create a preview of the organization's giving page based on the form data.
   */
  const createPreview = useCallback(() => {
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      isPreview: true,
    }));

    try {
      // Delay execution to simulate loading time
      setTimeout(() => {
        // Get form values
        const formData = getValues();
        if (iframeRef.current) {
          // Update iframe source based on form data
          iframeRef.current.src = `${givingPageURL}${formData.organizationId}/${
            formData.needType
          }/${encodeURIComponent(formData.primaryColor)}/${encodeURIComponent(
            formData.secondaryColor
          )}?frameLayout=plain`;
        }
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          isPreview: false,
        }));
      }, 100);
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        isPreview: false,
      }));
    }
  },[getValues]);

  useEffect(() => {
    /**
     * Function to trigger the generation of a preview solely when the preview mode is activated.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    if (isPreview) createPreview();
  }, [isPreview, selectedOrganization, createPreview]);

  useEffect(() => {
    /**
     * Fetches details of an organization's giving page by its ID from the backend API
     * on component mount
     */
    const getById = async () => {
      try {
        const response = await getData(
          `/api/fundraising/getOrgGivingPageById/${id.toString()}`,
          null,
          userSession
        );
        setSelectedOrganization([
          {
            value: response.organizationId,
            label: response.organizationName,
            profilePicture: response.organizationProfilePicture,
          },
        ]);
        reset(response);
        setIsPreview(true); // Enable preview mode
        setIsPublic(response.status === "Active");
      } catch (error) {
        // Show error notification if an error occurs
        showNotification("danger", error, 5000);
      }
    };
    if (id > 0) getById();
    else reset(defaultValues);
  }, [userSession, id, reset]);

  /**
   * Resets the form state.
   * @param {boolean} hasToResetOrg - Indicates whether to reset the selected organization. Default is true.
   */
  const resetForm = (hasToResetOrg = true) => {
    reset(defaultValues);
    if (hasToResetOrg) {
      iframeRef.current.src = "about:blank";
      setSelectedOrganization([]);
    }
    setIsPreview(false); // Disable preview mode
    setIsPublic(false);
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };
  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-bold mb-0">
            {id > 0 ? "Edit Embed Code" : "Create Embed Code"}
          </h6>
          <Link
            to="/embed-code"
            className="ms-3 btn btn-secondary"
            title="Back"
          >
            <i className="flaticon-line-arrow-left pe-2"></i>
            Back
          </Link>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <Form onSubmit={handleSubmit(addOrUpdate)}>
              <Form.Control type="hidden" {...register("id")} />
              <Form.Control type="hidden" {...register("status")} />
              <Row>
                <Col>
                  <Row>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={10}
                      xxl={10}
                      className="mb-4"
                    >
                      <Controller
                        control={control}
                        name="organizationId"
                        id="ddOrg"
                        rules={{ required: "Organization is required" }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            // menuIsOpen
                            isSearchable={id > 0 ? false : true}
                            isClearable={id > 0 ? false : true}
                            isLoading={loadingStates["inSearch"]}
                            placeholder="Organization"
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onInputChange={handleSearchInput}
                            options={organizations}
                            value={selectedOrganization}
                            onChange={(val) => {
                                resetForm(false);
                                setTimeout(() => {
                                  setSelectedOrganization(val);
                                  onChange(val?.value || "");
                                  setIsPreview(true);
                                }, 100);
                            }}
                            noOptionsMessage={() => "No results found"}
                            filterOption={customFilterOption}
                            getOptionLabel={(e) => (
                              <Fragment>
                                <Image
                                  src={transformProfilePicture(
                                    e.profilePicture
                                  )}
                                  alt={e.label}
                                  roundedCircle
                                  fluid
                                  width="35"
                                  className="me-2 align-middle"
                                />
                                <span>{e.label}</span>
                              </Fragment>
                            )}
                          />
                        )}
                      />
                      {errors.organizationId && (
                        <Form.Text className="text-danger">
                          {errors.organizationId.message}
                        </Form.Text>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={10}
                      xxl={10}
                      className="mb-4"
                    >
                      <FloatingLabel controlId="ddNeedType" label="Need Type">
                        <Form.Select
                          name="needType"
                          aria-label="Need Type"
                          {...register("needType", {
                            required: "Need type is required",
                          })}
                        >
                          {" "}
                          <option value="All">All</option>
                          <option value="Newly Added">Newly Added (20)</option>
                          <option value="Featured">Featured Needs(20)</option>
                          <option value="Attention Required">
                            Attention Required Needs (20)
                          </option>
                        </Form.Select>
                      </FloatingLabel>
                      {errors.needType && (
                        <Form.Text className="text-danger">
                          {errors.needType.message}
                        </Form.Text>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={4}
                      xxl={4}
                      className="mb-4"
                    >
                      <Form.Label htmlFor="txtPrimaryColor">
                        PRIMARY COLOR
                      </Form.Label>
                      <Form.Control
                        type="color"
                        id="txtPrimaryColor"
                        size="lg"
                        title="Primary Color"
                        defaultValue={defaultValues.primaryColor}
                        {...register("primaryColor")}
                      />
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={4}
                      xxl={4}
                      className="mb-4"
                    >
                      <Form.Label htmlFor="txtSecondaryColor">
                        SECONDARY COLOR
                      </Form.Label>
                      <Form.Control
                        type="color"
                        id="txtSecondaryColor"
                        size="lg"
                        title="Secondary Color"
                        defaultValue={defaultValues.secondaryColor}
                        {...register("secondaryColor")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="d-flex align-items-center h-100">
                        <Form.Check
                          type="switch"
                          name="status"
                          id="custom-switch"
                          label="Make it Public"
                          variant="secondary"
                          className="text-muted"
                          checked={isPublic}
                          onChange={(e) => {
                            setValue(
                              "status",
                              e.target.checked ? "Active" : "Inactive"
                            );
                            setIsPublic(e.target.checked);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  {/* Embed code represented by an iframe for an organization's Giving Page. */}
                  <div className="d-flex align-items-center justify-content-center h-100 shadow">
                    {isPreview ? (
                      <iframe
                        src="about:blank"
                        width="100%"
                        height="100%"
                        onLoad={handleIframeLoad}
                        loading="lazy"
                        title="Giving Page"
                        ref={iframeRef}
                      ></iframe>
                    ) : (
                      <span className="text-light-emphasis fw-semibold">
                        You can preview your embed code here.
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mt-4">
                  <Button
                    variant="primary"
                    disabled={loadingStates["isSubmitted"]}
                    type="submit"
                    className="btn-md"
                  >
                    {loadingStates["isSubmitted"] ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "Save"
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    disabled={loadingStates["isPreview"]}
                    type="button"
                    className="ms-3 btn-md"
                    onClick={async () => {
                      const isValidOrganization = await trigger(
                        "organizationId"
                      );
                      if (isValidOrganization) createPreview();
                    }}
                  >
                    {loadingStates["isPreview"] ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "Preview"
                    )}
                  </Button>
                  <Link
                    className="ms-3 btn btn-md btn-secondary"
                    to="/embed-code"
                  >
                    Cancel
                  </Link>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
      {/* Notification alert */}
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
    </main>
  );
};

export default CreateEmbedCode;
