import React, { useEffect, useState } from "react";
import { Tabs, Tab, Image } from "react-bootstrap";

import NeedsToSupport from "./_needsToSupport";

import featured from "../../resources/images/featured.png";
import nearBy from "../../resources/images/near-by.png";
import attentionRequired from "../../resources/images/attention-required.png";
import all from "../../resources/images/all.png";

/**
 * Component for exploring active needs.
 * This component allows users to browse and interact with different types of active needs,
 * such as newly added needs, featured needs, attention required needs, needs near the user,
 * and all available needs.
 * @returns {React.Element} - Returns JSX for exploring active needs.
 */
const ExploreActiveNeeds = () => {
  const [coordinates, setCoordinates] = useState({}); // Stores user's current coordinates

  useEffect(() => {
    /**
     * useEffect hook to fetch user's current location on component mount
     */
    if ("geolocation" in navigator) {
      // Get the user's current position
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoordinates({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.log(error.message);
          setCoordinates({});
        }
      );
    } else {
      console.log("Geolocation is not available in this browser.");
      setCoordinates({});
    }
  }, []);

  const handleActiveTab = (tab) => {
    if (tab === "All")
      window.open("/needs/search", "_blank", "noopener,noreferrer");
  };

  return (
    <div className="explore-active-needs-container">
      <Tabs
        defaultActiveKey="Featured"
        unmountOnExit={true}
        fill
        className="border-0"
        onSelect={(tab) => handleActiveTab(tab)}
      >
        <Tab
          eventKey="Featured"
          title={
            <>
              <Image
                src={featured}
                alt="Featured Needs"
                fluid
                className="d-none d-md-inline-block"
              />
              <h4 className="title mt-md-3 mb-0 fw-normal text-dark-emphasis">
                Featured Needs
              </h4>
            </>
          }
        >
          <NeedsToSupport type="Featured" />
        </Tab>
        <Tab
          eventKey="NearBy"
          title={
            <>
              <Image
                src={nearBy}
                alt="Needs Near You"
                fluid
                className="d-none d-md-inline-block"
              />
              <h4 className="title mt-md-3 mb-0 fw-normal text-dark-emphasis">
                Needs Near You
              </h4>
            </>
          }
        >
          <NeedsToSupport type="NearBy" coordinates={coordinates} />
        </Tab>
        <Tab
          eventKey="Attention Required"
          title={
            <>
              <Image
                src={attentionRequired}
                alt="Needs that Need your Attention"
                fluid
                className="d-none d-md-inline-block"
              />
              <h4 className="title mt-md-3 mb-0 fw-normal text-dark-emphasis">
                Needs that Need <br /> your Attention"
              </h4>
            </>
          }
        >
          <NeedsToSupport type="Attention Required" />
        </Tab>
        <Tab
          eventKey="All"
          title={
            <>
              <Image
                src={all}
                alt="All Need"
                fluid
                className="d-none d-md-inline-block"
              />
              <h4 className="title mt-md-3 mb-0 fw-normal text-dark-emphasis">
                All
              </h4>
            </>
          }
        ></Tab>
      </Tabs>
    </div>
    // <TabContainer
    //   unmountOnExit={true}
    //   defaultActiveKey="Featured"
    //   onSelect={(tab) => handleActiveTab(tab)}
    // >
    //   <Nav
    //     variant="tabs"
    //     fill
    //     className="bg-white shadow-sm rounded-top-5 border-0 explore-active-needs"
    //   >
    //     <Nav.Item
    //       className={`py-3 ${activeTab === "Featured" ? "active" : ""}`}
    //     >
    //       <Nav.Link
    //         eventKey="Featured"
    //         className="fw-semibold border-bottom-0 border-end"
    //       >
    //         <Image
    //           src={featured}
    //           alt="Featured Needs"
    //           fluid
    //           className="d-none d-md-inline-block"
    //         />
    //         <h4 className="title mt-md-3 mb-0 fw-normal text-dark-emphasis">
    //           Featured Needs
    //         </h4>
    //       </Nav.Link>
    //     </Nav.Item>
    //     <Nav.Item className={`py-3 ${activeTab === "NearBy" ? "active" : ""}`}>
    //       <Nav.Link
    //         eventKey="NearBy"
    //         className="fw-semibold border-bottom-0 border-end"
    //       >
    //         <Image
    //           src={nearBy}
    //           alt="Needs Near You"
    //           fluid
    //           className="d-none d-md-inline-block"
    //         />
    //         <h4 className="title mt-md-3 mb-0 fw-normal text-dark-emphasis">
    //           Needs Near You
    //         </h4>
    //       </Nav.Link>
    //     </Nav.Item>
    //     <Nav.Item
    //       className={`py-3 ${
    //         activeTab === "Attention Required" ? "active" : ""
    //       }`}
    //     >
    //       <Nav.Link
    //         eventKey="Attention Required"
    //         className="fw-semibold border-bottom-0 border-end"
    //       >
    //         <Image
    //           src={attentionRequired}
    //           alt="Needs that Need your Attention"
    //           fluid
    //           className="d-none d-md-inline-block"
    //         />
    //         <h4 className="title mt-md-3 mb-0 fw-normal text-dark-emphasis">
    //           Needs that Need <br />
    //           your Attention
    //         </h4>
    //       </Nav.Link>
    //     </Nav.Item>
    //     <Nav.Item className={`py-3 ${activeTab === "All" ? "active" : ""}`}>
    //       <a
    //         eventKey="All"
    //         href="/needs/search"
    //         className="nav-link fw-semibold border-bottom-0 text-decoration-none"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //         onClick={() => handleActiveTab("All")}
    //       >
    //         <Image
    //           src={all}
    //           alt="All Need"
    //           fluid
    //           className="d-none d-md-inline-block"
    //         />
    //         <h4 className="title mt-md-3 mb-0 fw-normal text-dark-emphasis">
    //           All
    //         </h4>
    //       </a>
    //     </Nav.Item>
    //   </Nav>
    //   <Tab.Content className="explore-active-needs-container">
    //     <Tab.Pane eventKey="Featured" className="p-4">
    //       <NeedsToSupport type="Featured" />
    //     </Tab.Pane>
    //     <Tab.Pane eventKey="NearBy" className="p-4">
    //       <NeedsToSupport type="NearBy" coordinates={coordinates} />
    //     </Tab.Pane>
    //     <Tab.Pane eventKey="Attention Required" className="p-4">
    //       <NeedsToSupport type="Attention Required" />
    //     </Tab.Pane>
    //   </Tab.Content>
    // </TabContainer>
  );
};
export default ExploreActiveNeeds;
