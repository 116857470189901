import { Link } from "react-router-dom";
import { Image, Card } from "react-bootstrap";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";

import {
  OrgProfileURL,
  ValidatorProfileURL,
  NeedProfileURL,
} from "../../../constants";

// responsiveHOC is a Higher Order Component (HOC) used for responsive behavior,
// and LinesEllipsis is a component for truncating text with ellipsis.
const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

/**
 * Component for displaying a single need.
 * @param {object} need - The need object containing information.
 * @returns {React.Element} - JSX for the need card.
 */
const NeedCard = ({ need }) => {
  return (
    <Card className="border-light shadow-sm rounded-4 need h-100">
      <Card.Body>
        <Link
          to={`${NeedProfileURL}${need.referenceId}/${need.needId}`}
          title="Need Profile"
          className="profile-pic"
        >
          <Image
            src={need.profilePicture}
            alt="Profile Picture"
            fluid
            className="rounded-circle"
          ></Image>
        </Link>
        <p className="mt-5 pt-2 mb-2 fs-small">
          <Link
            to={`${NeedProfileURL}${need.referenceId}/${need.needId}`}
            className="text-decoration-none text-secondary fw-semibold"
            title="Need Profile"
          >
            {need.displayName}
          </Link>
          <span className="text-secondary fw-light">
            {" "}
            from {need.city}, {need.country}
          </span>
        </p>
        <h5 className="title mb-3">
          <Link
            to={`${NeedProfileURL}${need.referenceId}/${need.needId}`}
            className="text-decoration-none link-dark"
            title="Need Profile"
          >
            <ResponsiveEllipsis
              text={need.title}
              maxLine="3"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          </Link>
        </h5>
        <p className="mb-2">
          <span className="fw-bold fs-5">${need.raised}</span>
          <span className="text-dark fs-6"> of ${need.amountNeeded}</span>
        </p>
        <div
          className={`progress ${
            need.needStatus === "Funded" && need.percentageCompleted < 100
              ? "bg-secondary-subtle"
              : ""
          }`}
          role="progressbar"
          aria-label="Percentage of Need Funded"
        >
          <div
            className={`progress-bar ${
              need.percentageCompleted > 100 ? "bg-primary" : "bg-secondary"
            }`}
            style={{ width: `${need.percentageCompleted}%` }}
          />
        </div>
        <p className="d-flex justify-content-between mt-2">
          <small className="text-dark fw-light">
            <span className="text-secondary fw-semibold">
              {need.totalSupporters}
            </span>{" "}
            Supporters
          </small>
          <small className="text-dark fw-light">
            {need.needStatus !== "Funded" && (
              <span className="text-secondary fw-semibold">
                {need.daysLeft}
              </span>
            )}
            {need.needStatus === "Funded" ? "Funded On: " : " Days Left"}
            {need.needStatus === "Funded" && (
              <span className="text-secondary fw-semibold">
                {need.fundedOn}
              </span>
            )}
          </small>
        </p>
        <p className="fs-small text-dark-emphasis mb-2">
          Validated by{" "}
          <Link
            to={`${ValidatorProfileURL}${need.validatorReferenceId}`}
            title="Validator Profile"
            className="link-dark fw-semibold text-decoration-none"
          >
            {need.validatorName}
          </Link>{" "}
          from{" "}
          <Link
            to={`${OrgProfileURL}${need.organizationReferenceId}`}
            title="Organization Profile"
            className="link-dark fw-semibold text-decoration-none"
          >
            {need.organizationName}
          </Link>
        </p>
      </Card.Body>
      <Card.Footer className="bg-transparent border-0 pb-3 pt-0">
        {need.needStatus === "Funded" ? (
          <Link
            to={`${NeedProfileURL}${need.referenceId}/${need.needId}`}
            className="btn btn-secondary btn-md"
          >
            View Profile
          </Link>
        ) : (
          <Link
            to={`${NeedProfileURL}${need.referenceId}/${need.needId}`}
            className="btn btn-primary btn-md"
          >
            Support
          </Link>
        )}
      </Card.Footer>
    </Card>
  );
};

export default NeedCard;
