import { Anonymous } from "./anonymous";

import PublicLayout from "../layout/publicLayout";
import SearchNeeds from "../features/need/";

import DefaultLayout from "../layout/defaultLayout";
import LoginType from "../features/account";
import Login from "../features/account/login";
import AccountType from "../features/account/createAccount";
import RecoveryPassword from "../features/account/recoveryPassword";
import RecoveryPasswordConfirmation from "../features/account/recoveryPasswordConfirmation";
import ResetPassword from "../features/account/resetPassword";
import ResetPasswordConfirmation from "../features/account/resetPasswordConfirmation";
import DonorLogin from "../features/donor/login";
import DonorRegistration from "../features/donor/register";
import EmailConfirmation from "../features/account/emailConfirmation";
import EmailVerification from "../features/account/emailVerification";
import TwoFactorAuthentication from "../features/account/twoFactorAuthentication";
import PasswordChangeRequest from "../features/account/passwordChangeRequest";
import PartnerEnrollment from "../features/validator/partnerEnrollment";
import ValidatorEnrollment from "../features/validator/validatorEnrollment";
import PartnerEmailVerification from "../features/validator/emailVerification";
import PartnerEmailConfirmation from "../features/validator/emailConfirmation";
import CreateAccount from "../features/validator/validatorAccountSetup";
import RecipientLogin from "../features/invitation/recipientLogin";
import Unauthorized from "../features/error/unauthorized";
import PageNotFound from "../features/error/pageNotFound";
import BadRequest from "../features/error/badRequest";
import InternalServerError from "../features/error/internalServerError";
import AccessDenied from "../features/error/accessDenied";

import NeedProfile from "../features/profile/need";
import ValidatorProfile from "../features/profile/validator";
import OrganizationProfile from "../features/profile/organization";
import OrgGivingPage from "../features/givingPage/organization";
import PreviewOrgGivingPage from "../features/givingPage/preview";
import UserGivingPage from "../features/givingPage/user";
import ValidatorList from "../features/validator/list";
import NeedsByOrganization from "../features/need/needsByOrganization";
import OrganizationList from "../features/organization/list";
import ExploreActiveNeeds from "../features/widgets/exploreActiveNeeds";
import ExploreFundedNeeds from "../features/widgets/exploreFundedNeeds";
import ExploreCategoryBasedNeeds from "../features/widgets/exploreCategoryBasedNeeds";
import ExploreCategories from "../features/widgets/exploreCategories";
import ExploreNeedsChosen from "../features/widgets/exploreNeedsChosen";
import ExploreAuthentication from "../features/widgets/exploreAuthentication";
import ExploreOverview from "../features/widgets/exploreOverview";
import ExploreInsights from "../features/widgets/exploreInsights";

import Checkout from "../features/donation/checkout";
import Request from "../features/donation/request";
import Payment from "../features/donation/payment";
import Status from "../features/donation/status";

// PublicRoutes represent routes accessible to anyone, authentication is not necessary.
const PublicRoutes = [
  {
    element: <DefaultLayout />,
    path: "/",
    children: [
      {
        index: true,
        element: (
          <Anonymous>
            <LoginType />
          </Anonymous>
        ),
      },
      {
        path: "login",
        element: (
          <Anonymous>
            <Login />
          </Anonymous>
        ),
      },
      {
        path: "register",
        element: (
          <Anonymous>
            <AccountType />
          </Anonymous>
        ),
      },
      {
        path: "email-verification",
        element: (
          <Anonymous>
            <EmailVerification />
          </Anonymous>
        ),
      },
      {
        path: "account/confirm-email",
        element: (
          <Anonymous>
            <EmailConfirmation />
          </Anonymous>
        ),
      },
      {
        path: "recovery-password",
        element: (
          <Anonymous>
            <RecoveryPassword />
          </Anonymous>
        ),
      },
      {
        path: "recovery-password-confirmation",
        element: (
          <Anonymous>
            <RecoveryPasswordConfirmation />
          </Anonymous>
        ),
      },
      {
        path: "account/reset-password",
        element: (
          <Anonymous>
            <ResetPassword />
          </Anonymous>
        ),
      },
      {
        path: "reset-password-confirmation",
        element: (
          <Anonymous>
            <ResetPasswordConfirmation />
          </Anonymous>
        ),
      },
      {
        path: "verify-account",
        element: (
          <Anonymous>
            <TwoFactorAuthentication />
          </Anonymous>
        ),
      },
      {
        path: "password-change-request",
        element: (
          <Anonymous>
            <PasswordChangeRequest />
          </Anonymous>
        ),
      },
      {
        path: "donor",
        children: [
          {
            path: "login",
            element: (
              <Anonymous>
                <DonorLogin />
              </Anonymous>
            ),
          },
          {
            path: "register",
            element: (
              <Anonymous>
                <DonorRegistration />
              </Anonymous>
            ),
          },
        ],
      },
      {
        path: "partner",
        children: [
          {
            path: "enrollment",
            element: (
              <Anonymous>
                <PartnerEnrollment />
              </Anonymous>
            ),
          },
          {
            path: "email-verification",
            element: (
              <Anonymous>
                <PartnerEmailVerification />
              </Anonymous>
            ),
          },
          {
            path: "confirm-email",
            element: (
              <Anonymous>
                <PartnerEmailConfirmation />
              </Anonymous>
            ),
          },
          {
            path: "complete-account-setup/:code",
            element: (
              <Anonymous>
                <CreateAccount />
              </Anonymous>
            ),
          },
        ],
      },
      {
        path: "validator/request-account",
        element: (
          <Anonymous>
            <ValidatorEnrollment />
          </Anonymous>
        ),
      },
      {
        path: "external/giving-page/organization/:code",
        element: <OrgGivingPage />,
      },
      {
        path: "external/explore-active-needs",
        element: <ExploreActiveNeeds />,
      },
      {
        path: "external/explore-funded-needs",
        element: <ExploreFundedNeeds />,
      },
      {
        path: "external/explore-category-based-needs",
        element: <ExploreCategoryBasedNeeds />,
      },
      {
        path: "external/explore-categories",
        element: <ExploreCategories />,
      },
      {
        path: "external/explore-needs-chosen",
        element: <ExploreNeedsChosen />,
      },
      {
        path: "external/explore-authentication",
        element: <ExploreAuthentication />,
      },
      {
        path: "external/explore-overview",
        element: <ExploreOverview />,
      },
      {
        path: "external/explore-insights",
        element: <ExploreInsights />,
      },
      {
        path: "preview/:id/:needType/:primaryColor/:secondaryColor",
        element: <PreviewOrgGivingPage />,
      },
      {
        path: "unauthorized",
        element: <Unauthorized />,
      },
      {
        path: "access-denied",
        element: <AccessDenied />,
      },
      {
        path: "bad-request",
        element: <BadRequest />,
      },
      {
        path: "error",
        element: <InternalServerError />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
  {
    element: <PublicLayout />,
    path: "/",
    children: [
      {
        path: "needs",
        element: null,
        children: [
          {
            path: "search",
            element: <SearchNeeds />,
          },
          {
            path: "list/:referenceId",
            element: <NeedsByOrganization />,
          },
        ],
      },
      {
        path: "validators",
        element: null,
        children: [
          {
            path: "list/:referenceId",
            element: <ValidatorList />,
          },
        ],
      },
      {
        path: "organizations",
        element: null,
        children: [
          {
            path: "list",
            element: <OrganizationList />,
          },
        ],
      },
      {
        path: "recipient-login",
        element: (
          <Anonymous>
            <RecipientLogin />
          </Anonymous>
        ),
      },
      {
        path: "profile",
        children: [
          {
            path: "need/:referenceId/:needId?",
            element: <NeedProfile />,
          },
          {
            path: "validator/:referenceId",
            element: <ValidatorProfile />,
          },
          {
            path: "organization/:referenceId",
            element: <OrganizationProfile />,
          },
        ],
      },
      {
        path: "giving-page",
        children: [
          {
            path: "organization/:code",
            element: <OrgGivingPage />,
          },
          {
            path: "user/:referenceId",
            element: <UserGivingPage />,
          },
        ],
      },
      {
        path: "donation",
        children: [
          {
            path: "checkout",
            element: <Checkout />,
          },
          {
            path: "request/:cartId",
            element: <Request />,
          },
          {
            path: "payment/:cartId",
            element: <Payment />,
          },
          {
            path: "status/:referenceId",
            element: <Status />,
          },
        ],
      },
    ],
  },
];

export default PublicRoutes;
