import React from "react";
import { Link } from "react-router-dom";
import { Container, Image } from "react-bootstrap";

import unauthorized from "../../resources/images/unauthorized.png";

/**
 * Component for displaying an unauthorized access message.
 * This component is rendered when a user attempts to access a page without proper authorization.
 * @returns {React.Element} - Returns JSX for displaying the unauthorized access message.
 */
const Unauthorized = () => {
  return (
    <main className="d-flex align-items-center justify-content-center min-vh-100 flex-column bg-white">
      <Container className="text-center unauthorized">
        <Image src={unauthorized} alt="Unauthorized" fluid />
        <h3 className="text-secondary fw-semibold mb-2 mt-4">
          No authorization found.
        </h3>
        <p className="mb-2">This page is not publically visible.</p>
        <p className="mb-2">To access it please login first.</p>
        <Link
          to="/login"
          title="Login"
          className="btn btn-secondary btn-md fw-semibold mt-3"
        >
          SIGN IN
        </Link>
      </Container>
    </main>
  );
};

export default Unauthorized;
