import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import key from "weak-key";

/**
 * Function to render nested routes recursively.
 * @param {Array} routes - Array of route objects.
 * @returns {React.Element[]} Returns an array of JSX elements representing the rendered routes.
 */
export const RenderRoutes = (routes) => {
  // Mapping over each route object
  return routes.map((route) => {
    const { path, children, element } = route;
    // Checking if the route has children
    if (children && children.length > 0) {
      return (
        /* Using Fragment to group multiple elements without adding extra nodes to the DOM */
        <Fragment key={key(route)}>
          {element ? (
            // Rendering a route with nested child routes if 'element' prop is provided
            <Route path={path} element={element}>
              {/* Recursively rendering nested routes */}
              {RenderRoutes(children)}
            </Route>
          ) : (
            // Rendering a route without 'element' prop but with nested child routes
            <Route path={path}>{RenderRoutes(children)}</Route>
          )}
        </Fragment>
      );
    }
    // If the route does not have children
    return (
      <Fragment key={key(route)}>
        {route.index ? (
          // Rendering a route with 'index' prop
          <Route index={true} element={element} />
        ) : (
          // Rendering a regular route
          <Route path={path} element={element} />
        )}
      </Fragment>
    );
  });
};
