import {
  Row,
  Col,
  Table,
  InputGroup,
  Form,
  Spinner,
  Image,
} from "react-bootstrap";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { Fragment } from "react";

import NoData from "../resources/images/no-data.png";

/**
 * Component to render a data table with pagination and search functionality.
 * @param {Object} props - The props object containing table data and functionalities.
 * @param {Array} props.columns - The array of column definitions for the table.
 * @param {Array} props.data - The array of data rows to be displayed in the table.
 * @param {number} props.totalRecords - The total number of records in the dataset.
 * @param {Object} props.page - The current page data containing PageNumber and PageSize.
 * @param {number} props.page.PageNumber - The current page number.
 * @param {number} props.page.PageSize - The number of records per page.
 * @param {Function} props.onPageChange - Function to handle page change.
 * @param {Function} props.searchRecords - Function to handle search functionality.
 * @param {boolean} props.loadingState - Flag indicating whether the table data is loading.
 * @returns {JSX.Element} - Returns JSX for the data table component.
 */
const DataTable = ({
  columns,
  data,
  totalRecords,
  page,
  onPageChange,
  searchRecords,
  loadingState,
}) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    state: { page },
    pageCount:
      totalRecords > page?.PageSize
        ? Math.ceil(totalRecords / page?.pageSize)
        : 1,
  });
  return (
    <Fragment>
      <Row className="mx-0">
        <Col xs={7} md={9} lg={9} xl={10} xxl={10}>
          {onPageChange && (
            <Fragment>
              <Form.Label>Show</Form.Label>
              <Form.Select
                className="w-auto d-inline-block mx-2"
                onChange={(e) => {
                  onPageChange(1, e.target.value);
                }}
                size="sm"
              >
                {[25, 50, 75, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Form.Select>
              <Form.Label>entries</Form.Label>
            </Fragment>
          )}
        </Col>
        <Col xs={5} md={3} lg={3} xl={2} xxl={2}>
          {searchRecords && (
            <InputGroup>
              <InputGroup.Text className="bg-transparent border-end-0">
                <i className="flaticon-search fs-6"></i>
              </InputGroup.Text>
              <Form.Control
                type="text"
                className="bg-transparent border-start-0"
                placeholder="Search..."
                onKeyUp={(e) => {
                    if (e.target.value.length >= 2 || !e.target.value) searchRecords(e.target.value);
                }}
              />
            </InputGroup>
          )}
        </Col>
      </Row>
      <Table hover responsive className="mt-4">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      {totalRecords === 0 && !loadingState && (
        <div className="text-center text-dark-emphasis">
          <Image src={NoData} alt="No Data Found" fluid />
          <p className="mb-0 fw-semibold">No Data Found</p>
        </div>
      )}
      {loadingState && (
        <div className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {totalRecords > page?.PageSize && (
        <PaginationControl
          page={table.getState().page?.PageNumber}
          between={3}
          total={totalRecords}
          limit={table.getState().page?.PageSize}
          changePage={(pageNumber) => {
            onPageChange(pageNumber, page?.PageSize);
          }}
          last
          ellipsis={2}
        />
      )}
    </Fragment>
  );
};

export default DataTable;
