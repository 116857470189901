import React, { useState, useEffect, useReducer, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Form,
  Button,
  InputGroup,
  FloatingLabel,
  Spinner,
  Alert,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import {
  isPossiblePhoneNumber,
  isSupportedCountry,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/react-hook-form-input";

import ImageCrop from "../../components/imageCrop";
import { transformProfilePicture } from "../../components/transformFileURL";

import { getData, postData } from "../../services/apiService";
import { notify, initialState } from "../../store/notification";
import { useAuthContext } from "../../context/authProvider";

/**
 * Component for creating or editing organization details.
 * @returns {React.Element} - Returns JSX for creating or editing organization.
 */
const CreateOrganization = () => {
  const defaultValues = {
    id: 0,
    name: "",
    website: "",
    description: "",
    address: {
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "United States",
      zipCode: "",
      phoneNumber: "",
    },
    primaryContact: "",
    primaryEmail: "",
    accountantName: "",
    accountantEmail: "",
  };
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      "address.country": "United States",
    },
  });
  // Watch for changes in the phoneNumber field
  const phoneNumber = watch("address.phoneNumber");
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  // Extract the organization ID from the URL parameters for pre-populating
  // organization details for editing
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("United States");
  const [selectedCountryCode, setSelectedCountryCode] = useState("US");
  const [maxLength, setMaxLength] = useState(20);

  // File Upload
  const [profilePicture, setProfilePicture] = useState(null);
  const fileInputRef = useRef(null); // Create a ref for the file input
  const [selectedFile, setSelectedFile] = useState({});
  const [showCropModal, setShowCropModal] = useState(false);
  const [cropInfo, setCropInfo] = useState({});
  const [fileToDelete, setFileToDelete] = useState(null);

  // Functions for controlling the Crop Modal
  const closeCropModal = () => setShowCropModal(false);
  const openCropModal = () => setShowCropModal(true);

  useEffect(() => {
    /**
     * Prevent the user from adding in more numbers to this field if they exceed the limit of required digits
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    if (phoneNumber) {
      const formattedPhone = formatPhoneNumberIntl(phoneNumber);

      // Update maxLength if the cleaned phone number is valid
      if (isPossiblePhoneNumber(formattedPhone))
        setMaxLength(formattedPhone.length);
    }
  }, [phoneNumber, maxLength]);

  /**
   * Function to handle changes in the website input field.
   * It appends 'https://' to the input value if it doesn't start with 'http://' or 'https://'.
   * @param {Object} event - The event object containing information about the input field change.
   */
  const handleWebsiteChange = (event) => {
    let inputWebsite = event.target.value.trim();
    if (!inputWebsite) {
      setValue("website", "");
      return;
    }
    // Check if the inputWebsite starts with 'http://' or 'https://'
    if (!inputWebsite.match(/^(ftp|http|https):\/\//)) {
      // If not, prepend 'https://'
      inputWebsite = "https://" + inputWebsite;
      // Update the form value using setValue
      setValue("website", inputWebsite);
    }
  };

  useEffect(() => {
    /**
     * Function to fetch the country code
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const matchedCountry = CountryRegionData.find(
      (country) => country[0] === selectedCountry
    );

    setSelectedCountryCode(matchedCountry ? matchedCountry[1] : "");
  }, [selectedCountry]);

  /**
   * Function to handle the change event of the file input element.
   * @param {object} event - The change event object.
   */
  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];
      const maxSize = 1024 * 1024; // 1 MB in bytes
      if (!allowedTypes.includes(file.type)) {
        showNotification(
          "danger",
          "Image format is incompatible. Only JPEG and PNG formats are supported.",
          5000
        );
        return;
      }
      if (file.size > maxSize) {
        showNotification(
          "danger",
          "Your file is too large, maximum allowed size is: 1 MB",
          5000
        );
        return;
      }
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedFile({
            name: event.target.files?.[0].name,
            file: event.target.files[0],
            src: reader.result,
            url: URL.createObjectURL(event.target.files[0]),
          });
          openCropModal();
        };
        reader.readAsDataURL(file);
      }
    }
  };

  /**
   * Function to update the state with cropped image information and removes the existing profile picture.
   * @param {string} croppedImage - The cropped image data.
   * @param {object} croppedAreaPixels - The position of the cropped area in pixels.
   * @param {number} rotation - The rotation angle of the cropped image.
   */
  const updateAvatar = (croppedImage, croppedAreaPixels, rotation) => {
    setCropInfo({
      image: croppedImage,
      position: croppedAreaPixels,
      rotation: rotation,
    });
    removeProfilePicture();
  };

  /**
   * Function to reset the file input and clears the state related to the selected file
   * and profile picture.
   */
  const resetFileInput = () => {
    setCropInfo({});
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset the file input value
    }
    setSelectedFile({});
    setProfilePicture(null);
  };

  /**
   * Function is responsible for preparing the removal of the profile picture.
   * It sets the fileToDelete state to the current profile picture URL
   * so that it can be deleted when the form is submitted or updated.
   */
  const removeProfilePicture = () => {
    setFileToDelete(profilePicture);
    setValue("profilePicture", "");
  };

  /**
   * Function to upload a cropped image.
   * @returns {string|null} Returns the URL of the uploaded file if successful, or null if an error occurs.
   */
  const upload = async () => {
    const formData = new FormData();
    formData.append("File", selectedFile.file);
    formData.append("cropX", parseInt(cropInfo.position.x));
    formData.append("cropY", parseInt(cropInfo.position.y));
    formData.append("cropWidth", parseInt(cropInfo.position.width));
    formData.append("cropHeight", parseInt(cropInfo.position.height));
    formData.append("cropRotation", parseInt(cropInfo.rotation));
    formData.append("type", "Profile");
    if (profilePicture != null)
      formData.append("profilePicture", profilePicture);
    try {
      const response = await postData("/api/image/crop", formData, userSession);
      return response;
    } catch (error) {
      showNotification("danger", error, 5000);
      setIsLoading(false);
      resetFileInput();
      return null;
    }
  };

  /**
   * Function to add or update organization details.
   * @param {Object} data - The organization data to be added or updated.
   */
  const addOrUpdate = async (data) => {
    setIsLoading(true);
    if (selectedFile.file) {
      const response = await upload();
      if (response) {
        data.profilePicture = response;
        setProfilePicture(response);
      } else return;
    }
    data.fileToDelete = fileToDelete;
    data.address.phoneNumber = formatPhoneNumberIntl(data.address.phoneNumber);

    try {
      const response = await postData(
        "/api/organization/addOrUpdate",
        data,
        userSession
      );
      setIsLoading(false);
      reset(defaultValues);
      resetFileInput();
      showNotification("success", response, 5000);
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    /**
     * Function to fetch organization details by ID from the backend API
     * on component mount
     */
    const getById = async () => {
      try {
        const response = await getData(
          `/api/organization/getByIdOrReferenceId/${id.toString()}`,
          null,
          userSession
        );
        setProfilePicture(response.profilePicture);
        setSelectedCountry(response.address.country);
        reset(response);
      } catch (error) {
        // Show a notification if an error occurs
        showNotification("danger", error, 5000);
      }
    };
    if (id > 0) getById();
  }, [id, userSession, reset]);

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };
  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-bold mb-0">
            {id > 0 ? "Edit Organization" : "Create Organization"}
          </h6>
          <Link
            to="/organization"
            className="ms-3 btn btn-secondary"
            title="Back"
          >
            <i className="flaticon-line-arrow-left pe-2"></i>
            Back
          </Link>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <Form onSubmit={handleSubmit(addOrUpdate)}>
              <Form.Control type="hidden" {...register("id")} />
              <Form.Control type="hidden" {...register("profilePicture")} />
              <Row className="align-items-center mb-4">
                <Col xs="auto">
                  <span className="fw-medium">Basic Info</span>
                </Col>
                <Col>
                  <hr className="m-0" />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={4}
                  className="mb-4"
                >
                  <InputGroup>
                    <FloatingLabel
                      label="Name"
                      controlId="txtOrgName"
                      htmlFor="txtOrgName"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        {...register("name", {
                          required: "Organization name is required",
                          pattern: {
                            value:
                              /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                            message:
                              "Organization name shouldn't contain HTML or Script tags",
                          },
                          maxLength: {
                            value: 250,
                            message:
                              "Organization name cannot exceed 250 characters",
                          },
                        })}
                      />
                    </FloatingLabel>
                    <InputGroup.Text>
                      <i className="flaticon-layers fs-4"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.name && (
                    <Form.Text className="text-danger">
                      {errors.name.message}
                    </Form.Text>
                  )}
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={4}
                  className="mb-4"
                >
                  <InputGroup>
                    <FloatingLabel
                      label="Website"
                      controlId="txtWebsite"
                      htmlFor="txtWebsite"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Website"
                        {...register("website", {
                          required: "Website is required",
                          pattern: {
                            value: /^(ftp|http|https):\/\/[^ "]+$/i,
                            message: "Enter a valid URL",
                          },
                          maxLength: {
                            value: 1000,
                            message: "Website cannot exceed 1000 characters",
                          },
                          onChange: (e) => {
                            handleWebsiteChange(e);
                          },
                        })}
                      />
                    </FloatingLabel>
                    <InputGroup.Text>
                      <i className="flaticon-link fs-4"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.website && (
                    <Form.Text className="text-danger">
                      {errors.website.message}
                    </Form.Text>
                  )}
                </Col>
                <Col xs={12} lg={6} xl={4} xxl={4} className="mb-4">
                  <InputGroup>
                    <Form.Control
                      type="file"
                      id="fileInput"
                      onChange={handleFileChange}
                      className="d-none"
                      ref={fileInputRef}
                      accept="image/jpeg, image/png"
                    />
                    <Form.Control
                      type="text"
                      placeholder="No file chosen"
                      defaultValue={selectedFile.name}
                      readOnly
                      className="rounded-start"
                    />
                    <InputGroup.Text className="p-0 border-0">
                      <Button
                        as="label"
                        variant="light"
                        htmlFor="fileInput"
                        className="btn-md rounded-0"
                      >
                        Browse
                      </Button>
                      <Button
                        variant="dark"
                        className="btn-md rounded-start-0"
                        onClick={() => {
                          resetFileInput();
                          removeProfilePicture();
                        }}
                      >
                        Remove
                      </Button>
                    </InputGroup.Text>
                  </InputGroup>
                  <Form.Text muted className="d-block mt-1">
                    Recommended size: 1024x576. Max file size: 1MB. Supported
                    file formats: JPG and PNG
                  </Form.Text>
                </Col>
                <Col xs={12} lg={6} xl={8} xxl={8} className="mb-4">
                  <FloatingLabel controlId="txtDescription" label="Description">
                    <Form.Control
                      as="textarea"
                      className="scroll"
                      placeholder="Description"
                      {...register("description", {
                        required: "Description is required",
                        pattern: {
                          value:
                            /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>)[\s\S])*$/i,
                          message:
                            "Description shouldn't contain HTML or Script tags",
                        },
                        maxLength: {
                          value: 2500,
                          message: "Description cannot exceed 2500 characters",
                        },
                      })}
                    />
                  </FloatingLabel>
                  {errors.description && (
                    <Form.Text className="text-danger">
                      {errors.description.message}
                    </Form.Text>
                  )}
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={4}
                  className="mb-4 d-flex align-items-center"
                >
                  <Form.Label>Preview: </Form.Label>
                  <Image
                    fluid
                    src={
                      !cropInfo.image
                        ? transformProfilePicture(profilePicture)
                        : cropInfo.image
                    }
                    alt="Profile Picture"
                    className="ms-3 align-middle avatar"
                    roundedCircle
                    thumbnail
                  />
                </Col>
              </Row>
              <Row className="align-items-center my-4">
                <Col xs="auto">
                  <span className="fw-medium">Contact Info</span>
                </Col>
                <Col>
                  <hr className="m-0" />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={4}
                  className="mb-4"
                >
                  <InputGroup>
                    <FloatingLabel
                      label="Street Address"
                      controlId="txtAddressLine1"
                      htmlFor="txtAddressLine1"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Street Address"
                        {...register("address.address1", {
                          required: "Street address is required",
                          pattern: {
                            value:
                              /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                            message:
                              "Street address shouldn't contain HTML or Script tags",
                          },
                          maxLength: {
                            value: 50,
                            message:
                              "Street address cannot exceed 50 characters",
                          },
                        })}
                      />
                    </FloatingLabel>
                    <InputGroup.Text>
                      <i className="flaticon-location fs-4"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.address && errors.address.address1 && (
                    <Form.Text className="text-danger">
                      {errors.address.address1.message}
                    </Form.Text>
                  )}
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={4}
                  className="mb-4"
                >
                  <InputGroup>
                    <FloatingLabel
                      label="Address Line 2"
                      controlId="txtAddressLine2"
                      htmlFor="txtAddressLine2"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Address Line 2"
                        {...register("address.address2", {
                          pattern: {
                            value:
                              /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                            message:
                              "Address Line 2 shouldn't contain HTML or Script tags",
                          },
                          maxLength: {
                            value: 50,
                            message:
                              "Address Line 2 cannot exceed 50 characters",
                          },
                        })}
                      />
                    </FloatingLabel>
                    <InputGroup.Text>
                      <i className="flaticon-location fs-4"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.address && errors.address.address2 && (
                    <Form.Text className="text-danger">
                      {errors.address.address2.message}
                    </Form.Text>
                  )}
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={4}
                  className="mb-4"
                >
                  <InputGroup>
                    <FloatingLabel
                      label="City"
                      controlId="txtCity"
                      htmlFor="txtCity"
                    >
                      <Form.Control
                        type="text"
                        placeholder="City"
                        {...register("address.city", {
                          required: "City is required",
                          pattern: {
                            value:
                              /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                            message:
                              "City shouldn't contain HTML or Script tags",
                          },
                          maxLength: {
                            value: 50,
                            message: "City cannot exceed 50 characters",
                          },
                        })}
                      />
                    </FloatingLabel>
                    <InputGroup.Text>
                      <i className="flaticon-location fs-4"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.address && errors.address.city && (
                    <Form.Text className="text-danger">
                      {errors.address.city.message}
                    </Form.Text>
                  )}
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={4}
                  className="mb-4"
                >
                  <FloatingLabel
                    label="Country"
                    controlId="ddCountry"
                    htmlFor="ddCountry"
                  >
                    <Controller
                      control={control}
                      name="address.country"
                      id="ddCountry"
                      rules={{ required: "Country is required" }}
                      render={({ field: { onChange, value } }) => (
                        <CountryDropdown
                          value={value || ""}
                          classes="form-select"
                          onChange={(val) => {
                            onChange(val);
                            setSelectedCountry(val);
                            setValue("address.phoneNumber", "");
                          }}
                        />
                      )}
                    />
                  </FloatingLabel>
                  {errors.address && errors.address.country && (
                    <Form.Text className="text-danger">
                      {errors.address.country.message}
                    </Form.Text>
                  )}
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={4}
                  className="mb-4"
                >
                  <FloatingLabel
                    label="State"
                    controlId="ddState"
                    htmlFor="ddState"
                  >
                    <Controller
                      control={control}
                      name="address.state"
                      id="ddState"
                      rules={{ required: "State is required" }}
                      render={({ field: { onChange, value } }) => (
                        <RegionDropdown
                          country={selectedCountry}
                          value={value || ""}
                          classes="form-select"
                          onChange={(val) => onChange(val)}
                        />
                      )}
                    />
                  </FloatingLabel>
                  {errors.address && errors.address.state && (
                    <Form.Text className="text-danger">
                      {errors.address.state.message}
                    </Form.Text>
                  )}
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={4}
                  className="mb-4"
                >
                  <InputGroup>
                    <FloatingLabel
                      label="Zip Code"
                      controlId="txtZipCode"
                      htmlFor="txtZipCode"
                    >
                      <Form.Control
                        type="number"
                        placeholder="Zip Code"
                        {...register("address.zipCode", {
                          required: "Zip code is required",
                          pattern: {
                            value:
                              /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                            message:
                              "Zip code shouldn't contain HTML or Script tags",
                          },
                          maxLength: {
                            value: 20,
                            message: "Zip code cannot exceed 20 characters",
                          },
                        })}
                      />
                    </FloatingLabel>
                    <InputGroup.Text>
                      <i className="flaticon-location fs-4"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.address && errors.address.zipCode && (
                    <Form.Text className="text-danger">
                      {errors.address.zipCode.message}
                    </Form.Text>
                  )}
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={4}
                  className="mb-4"
                >
                  <InputGroup>
                    <FloatingLabel
                      label="Phone Number"
                      controlId="txtPhoneNumber"
                      htmlFor="txtPhoneNumber"
                    >
                      <PhoneInput
                        name="address.phoneNumber"
                        id="txtPhoneNumber"
                        control={control}
                        country={
                          isSupportedCountry(selectedCountryCode)
                            ? selectedCountryCode
                            : ""
                        }
                        international={true}
                        withCountryCallingCode={true}
                        onChange={(val) => {
                          trigger("address.phoneNumber");
                        }}
                        maxLength={maxLength}
                        rules={{
                          required: "Phone number is required",
                          validate: (value) => {
                            if (!selectedCountry) return "Country is required";
                            if (
                              !isPossiblePhoneNumber(value, selectedCountryCode)
                            ) {
                              setMaxLength(20);
                              return "Invalid phone number";
                            }
                            return true;
                          },
                        }}
                        className="form-control"
                      />
                    </FloatingLabel>
                    <InputGroup.Text>
                      <i className="flaticon-smartphone fs-3"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.address && errors.address.phoneNumber && (
                    <Form.Text className="text-danger">
                      {errors.address.phoneNumber.message}
                    </Form.Text>
                  )}
                </Col>
              </Row>
              <Row className="align-items-center my-4">
                <Col xs="auto">
                  <span className="fw-medium">Primary Contact Info</span>
                </Col>
                <Col>
                  <hr className="m-0" />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={4}
                  className="mb-4"
                >
                  <InputGroup>
                    <FloatingLabel
                      label="Primary Contact Name"
                      controlId="txtPrimaryContactName"
                      htmlFor="txtPrimaryContactName"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Primary Contact Name"
                        {...register("primaryContact", {
                          required: "Primary contact name is required",
                          pattern: {
                            value:
                              /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                            message:
                              "Primary contact name shouldn't contain HTML or Script tags",
                          },
                          maxLength: {
                            value: 100,
                            message:
                              "Primary contact name cannot exceed 100 characters",
                          },
                        })}
                      />
                    </FloatingLabel>
                    <InputGroup.Text>
                      <i className="flaticon-profile fs-4"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.primaryContact && (
                    <Form.Text className="text-danger">
                      {errors.primaryContact.message}
                    </Form.Text>
                  )}
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={4}
                  className="mb-4"
                >
                  <InputGroup>
                    <FloatingLabel
                      label="Primary Contact Email"
                      controlId="txtPrimaryContactEmail"
                      htmlFor="txtPrimaryContactEmail"
                    >
                      <Form.Control
                        type="email"
                        placeholder="Primary Contact Email"
                        {...register("primaryEmail", {
                          required: "Primary contact email is required",
                          maxLength: 256,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                      />
                    </FloatingLabel>

                    <InputGroup.Text>
                      <i className="flaticon-mail fs-4"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.primaryEmail && (
                    <Form.Text className="text-danger">
                      {errors.primaryEmail.message}
                    </Form.Text>
                  )}
                </Col>
              </Row>
              <Row className="align-items-center my-4">
                <Col xs="auto">
                  <span className="fw-medium">Accounting Contact Info</span>
                </Col>
                <Col>
                  <hr className="m-0" />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={4}
                  className="mb-4"
                >
                  <InputGroup>
                    <FloatingLabel
                      label="Accounting Contact Name"
                      controlId="txtAccountantName"
                      htmlFor="txtAccountantName"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Accounting Contact Name"
                        {...register("accountantName", {
                          required: "Accounting contact name is required",
                          pattern: {
                            value:
                              /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                            message:
                              "Accounting contact name shouldn't contain HTML or Script tags",
                          },
                          maxLength: {
                            value: 100,
                            message:
                              "Accounting contact name cannot exceed 100 characters",
                          },
                        })}
                      />
                    </FloatingLabel>
                    <InputGroup.Text>
                      <i className="flaticon-profile fs-4"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.accountantName && (
                    <Form.Text className="text-danger">
                      {errors.accountantName.message}
                    </Form.Text>
                  )}
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={4}
                  className="mb-4"
                >
                  <InputGroup>
                    <FloatingLabel
                      label="Accounting Contact Email"
                      controlId="txtAccountingContactEmail"
                      htmlFor="txtAccountingContactEmail"
                    >
                      <Form.Control
                        type="email"
                        placeholder="Accounting Contact Email"
                        {...register("accountantEmail", {
                          required: "Accounting contact email is required",
                          maxLength: {
                            value: 256,
                            message:
                              "Accounting contact email cannot exceed 256 characters",
                          },
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                      />
                    </FloatingLabel>

                    <InputGroup.Text>
                      <i className="flaticon-mail fs-4"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.accountantEmail && (
                    <Form.Text className="text-danger">
                      {errors.accountantEmail.message}
                    </Form.Text>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mt-4">
                  <Button
                    variant="primary"
                    disabled={isLoading}
                    type="submit"
                    className="btn-md"
                  >
                    {isLoading ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "Save"
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    type="button"
                    className="ms-3 btn-md"
                    onClick={() => {
                      reset();
                      resetFileInput();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <ImageCrop
        showCropModal={showCropModal}
        closeCropModal={closeCropModal}
        selectedFile={selectedFile}
        updateAvatar={updateAvatar}
        resetFileInput={resetFileInput}
      />
    </main>
  );
};

export default CreateOrganization;
