import React, { useState, useReducer } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Button,
  Spinner,
  Alert,
  Modal,
} from "react-bootstrap";
import OtpInput from "react-otp-input";

import {
  sendVerificationCode,
  verifyCode,
} from "../../services/accountService";
import { useAuthContext } from "../../context/authProvider";
import { notify, initialState } from "../../store/notification";
import { SITEURL } from "../../constants";

import logo from "../../resources/images/logo.svg";
import verificationSuccess from "../../resources/images/verification-success.png";

/**
 * Component for two-factor authentication.
 * This component handles the verification of the user's account using a verification code sent to their email.
 * It allows the user to enter the verification code and resend it if necessary.
 * @returns {JSX.Element} - Returns JSX for the two-factor authentication component.
 */
const TwoFactorAuthentication = () => {
  const { state } = useLocation();
  const [email] = useState(state?.email); // Read values passed on state
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'saveToken' object from the 'useAuthContext()' hook
  const { saveToken } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setPageLoading] = useState(false);
  const [code, setCode] = useState("");

  /**
   * Function to verify account with provided code
   */
  const verifyAccount = async () => {
    setIsLoading(true);
    try {
      const response = await verifyCode(code);
      setIsLoading(false);
      // Persist the JWT token in the client's browser using local storage.
      // The second parameter indicates whether it is from the login page.
      saveToken(response, true);
    } catch (error) {
      // Show error notification if verification fails
      showNotification("danger", error, 5000);
      setIsLoading(false);
    }
  };

  /**
   * Function to resend the verification code
   */
  const resendCode = async () => {
    setPageLoading(true);
    try {
      if (email) {
        const response = await sendVerificationCode(email);
        showNotification("success", response, 5000);
      } else {
        showNotification(
          "danger",
          "Something went wrong, please try again later. ",
          5000
        );
      }
      setPageLoading(false);
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setPageLoading(false);
    }
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main className="d-flex align-items-center justify-content-center min-vh-100 flex-column">
      <Container>
        <Row className="justify-content-center">
          <Col xs={11} sm={10} md={8} lg={6} xl={5} xxl={4}>
            <div className="text-center mb-5">
              <Link to={SITEURL} title="Benevolent">
                <Image src={logo} alt="Benevolent" className="logo" fluid />
              </Link>
            </div>
            <Card className="border-0 shadow p-4 rounded-4">
              <Card.Body className="text-center">
                <Image
                  src={verificationSuccess}
                  alt="Verification Success"
                  fluid
                />
                <h4 className="title mt-4">Authenticate Your Account</h4>
                <p className="mb-4 fw-light text-dark-emphasis">
                  To keep your account safe, please confirm your account by
                  entering the authorization code sent to{" "}
                  <span className="text-primary fw-bold">{email}</span>. This
                  code will be valid for 5 minutes.
                </p>
                {/* Render OTP input component for entering verification code. */}
                <OtpInput
                  value={code}
                  onChange={setCode}
                  numInputs={6}
                  renderSeparator={<span className="m-1"></span>}
                  renderInput={(props) => <input {...props} />}
                  inputType="text"
                  inputStyle="form-control"
                  containerStyle="d-flex align-items-center justify-content-center mb-5 otp-container"
                  shouldAutoFocus={true}
                />

                <div className="d-grid gap-2">
                  <Button
                    variant="primary"
                    disabled={isLoading || code.length < 6}
                    type="submit"
                    size="lg"
                    onClick={verifyAccount}
                  >
                    {isLoading ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "VERIFY"
                    )}
                  </Button>
                </div>
              </Card.Body>
            </Card>
            <div className="text-center mt-4">
              <p className="mb-2 text-dark-emphasis">
                Haven't received it?{" "}
                <Link
                  title="Resend Verification Code"
                  className="text-decoration-none link-secondary fw-semibold"
                  onClick={resendCode}
                >
                  Resend a new code
                </Link>
              </p>
              <p className="mb-0 text-dark-emphasis">
                Already Registered?{" "}
                <Link
                  to="/"
                  title="Sign In"
                  className="text-decoration-none link-secondary fw-semibold"
                >
                  Sign In
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Notification alerts */}
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default TwoFactorAuthentication;
