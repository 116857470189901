import React, { useState, useRef, useReducer } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  InputGroup,
  FloatingLabel,
  Button,
  ListGroup,
  Alert,
  Spinner,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import key from "weak-key";

import { postData } from "../../services/apiService";
import { useAuthContext } from "../../context/authProvider";
import { notify, initialState } from "../../store/notification";

/**
 * Component for uploading a receipt for a need.
 * @returns {React.Element} - Returns JSX for uploading a receipt.
 */
const UploadReceipt = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm();
  // Extract the need ID from the URL parameters
  const { needId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);

  const [need] = useState({
    title: state?.title,
    recipient: state?.recipient,
  });
  const [isLoading, setIsLoading] = useState(false);
  // File Upload
  const fileInputRef = useRef(null); // Create a ref for the file input
  const [selectedFiles, setSelectedFiles] = useState([]);

  /**
   * Function to upload selected files via the backend API.
   * @returns {Array|null} - Returns an array of file locations or null if upload fails.
   */
  const upload = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await postData(
        `/api/image/bulk-Upload/Receipts/${needId}`,
        formData,
        userSession
      );
      return response;
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setIsLoading(false);
      resetFileInput();
      return null;
    }
  };

  /**
   * Function to submit the form data via the backend API after uploading receipts
   * @param {Object} data - The form data.
   */
  const onSubmit = async (data) => {
    setIsLoading(true);
    const response = await upload();
    if (response && response.length > 0) {
      data.receipts = response;
    } else return;

    try {
      const response = await postData(
        `/api/need/save-receipt`,
        data,
        userSession
      );
      setIsLoading(false);
      reset();
      resetFileInput();
      showNotification("success", response, 5000);
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setIsLoading(false);
    }
  };

  /**
   * Function to handle the change event when files are selected for upload.
   * Validates selected files based on size and format, and updates the selected files array.
   * @param {Event} e - The file change event.
   */
  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    const validFiles = [];
    const largeFiles = [];
    const unsupportedFiles = [];

    newFiles.forEach((file) => {
      if (file.size > 1024 * 1024 * 5) {
        // File is too large
        largeFiles.push(file.name);
      } else if (
        ![
          "image/jpeg",
          "image/png",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/pdf",
        ].includes(file.type)
      ) {
        // Unsupported file format
        unsupportedFiles.push(file.name);
      } else {
        // Valid file
        validFiles.push(file);
      }
    });

    // Update the selected files array with valid files
    setSelectedFiles([...selectedFiles, ...validFiles]);
    clearErrors("receipts");

    // Construct error messages
    let errorMessage = "";
    if (largeFiles.length > 0) {
      errorMessage += `${largeFiles.join(", ")} are too large. `;
    }
    if (unsupportedFiles.length > 0) {
      errorMessage += `${unsupportedFiles.join(
        ", "
      )} have an unsupported format. `;
    }

    // Show error message
    if (errorMessage) {
      showNotification("danger", errorMessage, 5000);
    }
  };

  /**
   * Removes a file from the selected files array.
   * @param {number} index - Index of the file to be removed.
   */
  const removeFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  /**
   * Resets the file input field and clears the selected files.
   */
  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset the file input value
    }
    setSelectedFiles([]);
  };

  /**
   * Function to navigate to the receipts page.
   */
  const navigateToReceipts = () => {
    navigate(`/needs/receipts/${needId}`, {
      replace: true,
      state: { title: need.title, recipient: need.recipient },
    });
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-bold mb-0">
            Upload Receipts for {need.recipient}'s Need
          </h6>
          <Button
            onClick={navigateToReceipts}
            variant="secondary"
            className="ms-3"
          >
            <i className="flaticon-line-arrow-left pe-2"></i>
            Back
          </Button>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Control
                type="hidden"
                defaultValue={needId}
                {...register("needId")}
              />
              <Row>
                <Col xs={12} md={8} xl={7} className="mb-4">
                  <label className="text-light-emphasis mb-2">Receipts:</label>
                  <InputGroup>
                    <Form.Control
                      type="file"
                      multiple
                      id="fileInput"
                      onChange={handleFileChange}
                      className="d-none"
                      ref={fileInputRef}
                      accept="image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf"
                    />
                    <Form.Control
                      type="text"
                      placeholder={
                        selectedFiles.length > 0
                          ? `${selectedFiles.length} ${
                              selectedFiles.length === 1 ? "file" : "files"
                            }`
                          : "No file chosen"
                      }
                      readOnly
                      className="rounded-start"
                      {...register("receipts", {
                        validate: () =>
                          selectedFiles.length > 0 ||
                          "At least one file is required",
                      })}
                    />
                    <InputGroup.Text className="p-0 border-0">
                      <Button
                        as="label"
                        variant="light"
                        htmlFor="fileInput"
                        className="btn-md rounded-0"
                      >
                        Browse
                      </Button>
                      <Button
                        variant="dark"
                        className="btn-md rounded-start-0"
                        onClick={resetFileInput}
                      >
                        Remove
                      </Button>
                    </InputGroup.Text>
                  </InputGroup>
                  <Form.Text muted className="d-block mt-1">
                    Each file must not exceed 5MB in size, and supported file
                    formats include JPG, PNG, Word documents (Doc, Docx), and
                    PDF. You can upload multiple files at a time.
                  </Form.Text>
                  {errors.receipts && (
                    <Form.Text className="text-danger">
                      {errors.receipts.message}
                    </Form.Text>
                  )}
                </Col>
                {selectedFiles.length > 0 && (
                  <Col xs={12} md={8} xl={7} className="mb-4">
                    <ListGroup as="ul">
                      {selectedFiles.map((file, index) => (
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-center bg-transparent"
                          key={key(file)}
                        >
                          <div className="text-start">
                            <small className="fs-small text-dark-emphasis">
                              {file.name}
                            </small>
                            <br />
                            <small className="text-light-emphasis fw-light">
                              {(file.size / (1024 * 1024)).toFixed(2)} MB
                            </small>
                          </div>
                          <Button
                            onClick={() => removeFile(index)}
                            variant="link"
                            className="btn-close fs-small"
                          ></Button>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Col>
                )}
                <Col xs={12} md={8} xl={7} className="mb-4">
                  <FloatingLabel controlId="txtComments" label="Comments">
                    <Form.Control
                      as="textarea"
                      className="scroll"
                      placeholder="Comments"
                      {...register("description", {
                        pattern: {
                          value:
                            /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>)[\s\S])*$/i,
                          message:
                            "Comments shouldn't contain HTML or Script tags",
                        },
                        maxLength: {
                          value: 2500,
                          message: "Comments cannot exceed 2500 characters",
                        },
                      })}
                    />
                  </FloatingLabel>
                  {errors.comments && (
                    <Form.Text className="text-danger">
                      {errors.comments.message}
                    </Form.Text>
                  )}
                </Col>
                <Col xs={12}>
                  <Button
                    variant="primary"
                    disabled={isLoading}
                    type="submit"
                    className="btn-md"
                  >
                    {isLoading ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    type="button"
                    className="ms-3 btn-md"
                    onClick={() => {
                      reset();
                      resetFileInput();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
    </main>
  );
};

export default UploadReceipt;
