import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, Image } from "react-bootstrap";

import { useAuthContext } from "../../context/authProvider";
import { SITEURL } from "../../constants";

import logo from "../../resources/images/logo.svg";
import password from "../../resources/images/password-change-request.png";

/**
 * Component for password change request.
 * This component prompts the user to change their password for security reasons.
 * It provides a link to reset the password.
 * @returns {JSX.Element} - Returns JSX for password change request.
 */
const PasswordChangeRequest = () => {
  // Destructure the 'returnURL' object from the 'useAuthContext()' hook
  const { returnURL } = useAuthContext();

  return (
    <main className="d-flex align-items-center justify-content-center min-vh-100 flex-column">
      <Container>
        <Row className="justify-content-center">
          <Col xs={11} sm={11} md={8} lg={7} xl={6} xxl={5}>
            <div className="text-center mb-4">
              <Link to={SITEURL} title="Benevolent">
                <Image src={logo} alt="Benevolent" className="logo" fluid />
              </Link>
            </div>
            <Card className="border-0 shadow py-3 rounded-4 text-center">
              <Card.Body>
                <Image src={password} alt="Email Confirmation" fluid />
                <h4 className="title mt-4">Password Change Required</h4>
                <p className="mb-5 fw-light text-dark-emphasis">
                  For your account's security, we require you to change your
                  password before you can continue using Benevolent. This is a
                  one-time requirement to ensure the safety of your account.
                </p>
                <hr />
                <small className="text-dark-emphasis fw-light">
                  Please{" "}
                  <Link
                    title="Reset Password"
                    to={
                      returnURL
                        ? `/recovery-password?returnUrl=${returnURL}`
                        : "/recovery-password"
                    }
                    className="text-decoration-none link-secondary fw-bold"
                  >
                    click here
                  </Link>{" "}
                  to begin the process of resetting your password.
                </small>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default PasswordChangeRequest;
