import { Fragment } from "react";
import { PaginationControl } from "react-bootstrap-pagination-control";

/**
 * Component to render pagination controls.
 * Renders pagination controls if the total number of records exceeds the page size.
 * @param {Object} props - The props object containing pagination data.
 * @param {number} props.totalRecords - The total number of records.
 * @param {Object} props.page - The current page data containing PageNumber and PageSize.
 * @param {number} props.page.PageNumber - The current page number.
 * @param {number} props.page.PageSize - The number of records per page.
 * @param {Function} props.onPageChange - Function to handle page change.
 * @returns {JSX.Element} - Returns JSX for pagination controls.
 */
const Pagination = ({ totalRecords, page, onPageChange }) => {
  return (
    <Fragment>
      {totalRecords > page.PageSize && (
        <PaginationControl
          page={page.PageNumber}
          between={3}
          total={totalRecords}
          limit={page.PageSize}
          changePage={(pageNumber) => {
            onPageChange(pageNumber, page.PageSize);
          }}
          last
          ellipsis={2}
        />
      )}
    </Fragment>
  );
};

export default Pagination;
