import React, { useState, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  FloatingLabel,
  Button,
  InputGroup,
  Spinner,
  Alert,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  isPossiblePhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";

import { postData } from "../../services/apiService";
import { notify, initialState } from "../../store/notification";
import { useAuthContext } from "../../context/authProvider";

/**
 * Component for creating a new staff.
 * @returns {JSX.Element} - Returns JSX for creating a staff.
 */
const CreateUser = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    trigger,
    formState: { errors },
  } = useForm();
  // Get the value of the password field for confirmation
  const password = watch("password");
  // Watch for changes in the phoneNumber field
  const phoneNumber = watch("phoneNumber");
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [maxLength, setMaxLength] = useState(20);

  useEffect(() => {
    /**
     * Prevent the user from adding in more numbers to this field if they exceed the limit of required digits
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    if (phoneNumber) {
      const formattedPhone = formatPhoneNumberIntl(phoneNumber);

      // Update maxLength if the cleaned phone number is valid
      if (isPossiblePhoneNumber(formattedPhone))
        setMaxLength(formattedPhone.length);
    }
  }, [phoneNumber, maxLength]);

  /**
   * Function to handle the creation of a new user.
   * @param {Object} data - The data of the new user to be created.
   */
  const handleCreateUser = async (data) => {
    setIsLoading(true);
    data.phoneNumber = formatPhoneNumberIntl(data.phoneNumber);
    try {
      const response = await postData("/api/user/create", data, userSession);
      setIsLoading(false);
      reset();
      showNotification("success", response, 5000);
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setIsLoading(false);
    }
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };
  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-bold mb-0">Create User</h6>
          <Link to="/user" className="ms-3 btn btn-secondary" title="Back">
            <i className="flaticon-line-arrow-left pe-2"></i>
            Back
          </Link>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <Form onSubmit={handleSubmit(handleCreateUser)}>
              <Row className="align-items-center mb-4">
                <Col xs="auto">
                  <span className="fw-medium">Basic Info</span>
                </Col>
                <Col>
                  <hr className="m-0" />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={4}
                  className="mb-4"
                >
                  <InputGroup>
                    <Form.Floating>
                      <Form.Control
                        type="text"
                        placeholder="FirstName"
                        {...register("firstName", {
                          required: "First name is required",
                          pattern: {
                            value:
                              /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                            message:
                              "First name shouldn't contain html or script tags",
                          },
                          maxLength: {
                            value: 50,
                            message: "First name cannot exceed 50 characters",
                          },
                        })}
                      />
                      <Form.Label>First Name</Form.Label>
                    </Form.Floating>
                    <InputGroup.Text>
                      <i className="flaticon-profile fs-4"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.firstName && (
                    <Form.Text className="text-danger">
                      {errors.firstName.message}
                    </Form.Text>
                  )}
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={4}
                  className="mb-4"
                >
                  <InputGroup>
                    <Form.Floating>
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        {...register("lastName", {
                          required: "Last name is required",
                          pattern: {
                            value:
                              /^(?:(?!<\/?[a-z0-9]+(?:\s+[a-z0-9]+\s*=\s*""[^""]*"")*\s*\/?>).)*$/i,
                            message:
                              "Last name shouldn't contain html or script tags",
                          },
                          maxLength: {
                            value: 50,
                            message: "Last name cannot exceed 50 characters",
                          },
                        })}
                      />
                      <Form.Label>Last Name</Form.Label>
                    </Form.Floating>
                    <InputGroup.Text>
                      <i className="flaticon-profile fs-4"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.lastName && (
                    <Form.Text className="text-danger">
                      {errors.lastName.message}
                    </Form.Text>
                  )}
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={4} xxl={4}>
                  <InputGroup>
                    <Form.Floating>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        {...register("email", {
                          required: "Email is required",
                          maxLength: 256,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      <Form.Label>Email</Form.Label>
                    </Form.Floating>
                    <InputGroup.Text>
                      <i className="flaticon-mail fs-4"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.email && (
                    <Form.Text className="text-danger">
                      {errors.email.message}
                    </Form.Text>
                  )}
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={4} xxl={4}>
                  <InputGroup>
                    <FloatingLabel
                      label="Phone Number"
                      controlId="txtPhoneNumber"
                      htmlFor="txtPhoneNumber"
                    >
                      <PhoneInputWithCountry
                        name="phoneNumber"
                        id="txtPhoneNumber"
                        defaultCountry="US"
                        international={true}
                        withCountryCallingCode={true}
                        control={control}
                        onChange={(val) => {
                          trigger("phoneNumber");
                        }}
                        maxLength={maxLength}
                        rules={{
                          required: "Phone number is required",
                          validate: (value) => {
                            if (!isPossiblePhoneNumber(value)) {
                              setMaxLength(20);
                              return "Invalid phone number";
                            }
                            return true;
                          },
                        }}
                        className="form-control d-flex"
                      />
                    </FloatingLabel>
                    <InputGroup.Text>
                      <i className="flaticon-smartphone fs-3"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.phoneNumber && (
                    <Form.Text className="text-danger">
                      {errors.phoneNumber.message}
                    </Form.Text>
                  )}
                </Col>
              </Row>
              <Row className="align-items-center my-4">
                <Col xs="auto">
                  <span className="fw-medium">Account Info</span>
                </Col>
                <Col>
                  <hr className="m-0" />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={4}
                  className="mb-4"
                >
                  <InputGroup>
                    <Form.Floating>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        {...register("password", {
                          required: "Password is required",
                          pattern: {
                            value:
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@@$!%*?&])[A-Za-z\d$@@$!%*?&]{6,}/,
                            message:
                              "Password must contain at least 6 characters, including at least one uppercase letter, one lowercase letter, one special character, and one number.",
                          },
                        })}
                      />
                      <Form.Label>Password</Form.Label>
                    </Form.Floating>
                    <InputGroup.Text>
                      <i className="flaticon-password fs-3"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.password && (
                    <Form.Text className="text-danger">
                      {errors.password.message}
                    </Form.Text>
                  )}
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={4}
                  xxl={4}
                  className="mb-4"
                >
                  <InputGroup>
                    <Form.Floating>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        {...register("confirmPassword", {
                          required: "Confirm Password is required",
                          validate: (value) =>
                            value === password || "Passwords do not match",
                        })}
                      />
                      <Form.Label>Re-type Password</Form.Label>
                    </Form.Floating>
                    <InputGroup.Text>
                      <i className="flaticon-password fs-3"></i>
                    </InputGroup.Text>
                  </InputGroup>
                  {errors.confirmPassword && (
                    <Form.Text className="text-danger">
                      {errors.confirmPassword.message}
                    </Form.Text>
                  )}
                </Col>
                <Col xs={12} className="my-3">
                  <Form.Check
                    type="checkbox"
                    label="Designate as SuperAdmin"
                    className="fw-light text-dark-emphasis d-inline-block"
                    {...register("hasSuperAdminPrivileges", {
                      disabled: false,
                    })}
                  />
                </Col>
                <Col xs={12} className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label="Always take me to the dashboard after signing in"
                    className="fw-light text-dark-emphasis d-inline-block"
                    {...register("autoRedirectToDashboard", {
                      disabled: false,
                    })}
                  />
                </Col>
                <Col xs={12} className="mb-5">
                  <Form.Check
                    type="checkbox"
                    label="Force user to change their password the next time they sign in"
                    className="fw-light text-dark-emphasis d-inline-block"
                    {...register("isForceToChangePwd", {
                      disabled: false,
                    })}
                  />
                </Col>
                <div className="d-block">
                  <Button
                    variant="primary"
                    disabled={isLoading}
                    type="submit"
                    className="btn-md"
                  >
                    {isLoading ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "Save"
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    type="button"
                    className="ms-3 btn-md"
                    onClick={() => reset()}
                  >
                    Cancel
                  </Button>
                </div>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
    </main>
  );
};

export default CreateUser;
