import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Card,
  Modal,
  Spinner,
} from "react-bootstrap";

import Pagination from "../../components/pagination";
import { transformProfilePicture } from "../../components/transformFileURL";
import NeedCard from "../profile/widgets/_needCard";

import { getData } from "../../services/apiService";
import noData from "../../resources/images/no-data.png";
import dot from "../../resources/images/dot.png";
import square from "../../resources/images/square.png";

/**
 * Component for displaying user giving page.
 * @returns {React.Element} - JSX for user giving page.
 */
const UserGivingPage = () => {
  const { referenceId } = useParams();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [givingPage, setGivingPage] = useState({});
  const [needs, setNeeds] = useState([]);
  const [page, setPage] = useState({ PageNumber: 1, PageSize: 25 });

  useEffect(() => {
    /**
     * Fetches the user giving page from the backend API.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getGivingPage = async () => {
      try {
        const response = await getData(
          `/api/fundraising/getUserPreferences/${referenceId}`,
          null
        );
        if (response) setGivingPage(response);
        setIsPageLoading(false);
      } catch (error) {
        setIsPageLoading(false);
      }
    };
    getGivingPage();
  }, [referenceId]);

   /**
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
  useEffect(() => {     
    // Calculate the start and end index for the current page
    const startIndex = (page.PageNumber - 1) * page.PageSize;
    const endIndex = startIndex + page.PageSize;

    // Slice the data to display only the items for the current page
    const filteredNeeds = givingPage.hasOwnProperty("needs")
      ? givingPage.needs.slice(startIndex, endIndex)
      : [];
    const updatedNeeds = transformProfilePicture(filteredNeeds);
    setNeeds(updatedNeeds);
  }, [page, givingPage]);

   /**
   * Function to handle pagination change.
   * @param {number} pageNumber - The new page number.
   * @param {number} pageSize - The new page size.
   */
  const handlePageChange = (pageNumber, pageSize) => {
    setPage({
      PageNumber: pageNumber,
      PageSize: pageSize,
    });
  };

  return (
    <Container className="user-giving-page">
      {Object.keys(givingPage).length > 0 && (
        <Row className="align-items-center justify-content-center">
          {location.key !== "default" && (
            <Col xs={12} className="text-end">
              <Link
                to={-1}
                className="text-decoration-none fw-semibold"
                title="Back"
              >
                <i className="flaticon-back pe-1 fs-5"></i>
                Back to Prior Page
              </Link>
            </Col>
          )}
          <Col xs={12} className="text-center mt-4 mb-5 pb-4 page-title">
            <span className="text-primary tag-line">SUPPORT WITH US</span>
            <h1 className="title mt-1">{givingPage.title}</h1>
          </Col>
          <Col xs={8} sm={8} md={6} lg={5} xl={4} xxl={3} className="p-0">
            <div className="profile-picture bg-secondary rounded-5 position-relative ms-auto">
              <Image src={dot} alt="Dot" className="dot" />
              <Image src={square} alt="Square" className="square" />
              <Image
                src={transformProfilePicture(givingPage.profilePicture, {
                  isThumbnail: false,
                })}
                alt="Giving Page"
                fluid
                className="rounded-5 ps-2"
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={5} className="p-0">
            <div className="content p-4">
              <p className="mb-0 text-dark">{givingPage.description}</p>
              <h5 className="title mt-2 mb-0">
                <span className="fw-normal">By </span>
                <span className="text-secondary"> {givingPage.createdBy}</span>
              </h5>
            </div>
          </Col>
          {needs.length > 0 && (
            <Col xs={12} className="mt-5">
              <h2 className="title text-center mb-5 pb-5">
                <span className="fw-medium">Make Your</span> Donation
              </h2>
              <Row className="justify-content-center">
                {needs.map((need) => (
                  <Col
                    xs={8}
                    sm={6}
                    md={6}
                    lg={4}
                    xl={3}
                    xxl={3}
                    key={need.referenceId}
                  >
                    <NeedCard need={need} />
                  </Col>
                ))}
              </Row>
              <Pagination
                totalRecords={
                  givingPage.hasOwnProperty("needs")
                    ? givingPage.needs.length
                    : 0
                }
                page={page}
                onPageChange={handlePageChange}
              />
            </Col>
          )}
        </Row>
      )}
      {!isPageLoading && Object.keys(givingPage).length === 0 && (
        <Row className="justify-content-center text-center">
          <Col xs={10} sm={12} md={9} lg={7} xl={6} xxl={5}>
            <Card className="border-0 shadow p-4 rounded-4 text-center">
              <Card.Body>
                <Image src={noData} alt="Invalid Profile" fluid />
                <h4 className="title mt-3">Invalid Giving Page</h4>
                <p className="mb-0 fw-light text-dark-emphasis">
                  It looks like the giving page you are trying to visit is
                  temporarily
                  <br className="d-none d-sm-inline-block" />
                  unavailable. If you believe you've reached this page in error,
                  <br className="d-none d-sm-inline-block" />
                  please contact the person who sent you the link.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default UserGivingPage;
