import React from "react";
import { Link } from "react-router-dom";
import { Container, Image } from "react-bootstrap";

import pageNotFound from "../../resources/images/page-not-found.png";

/**
 * Component for displaying a page not found message.
 * This component is rendered when a user attempts to access a non-existent page.
 * @returns {React.Element} - Returns JSX for displaying the page not found message.
 */
const PageNotFound = () => {
  return (
    <main className="d-flex align-items-center justify-content-center min-vh-100 flex-column bg-white">
      <Container className="text-center internal-server-error">
        <h3 className="text-secondary fw-semibold mb-3 mt-4">
          Oops, why you're here?
        </h3>

        <p className="mb-2">
          We're very sorry for inconvenience. It looks like you're trying to
          access a page <br className="d-none d-md-block" /> that either has been deleted or never been
          existed.
        </p>
        <div>
          <Link
            to="/login"
            title="Login"
            className="btn btn-secondary btn-md fw-semibold mt-3"
          >
            RETURN HOME
          </Link>        
        </div>
        <Image src={pageNotFound} alt="PageNotFound" fluid />
      </Container>
    </main>
  );
};

export default PageNotFound;
