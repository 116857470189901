import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  ListGroup,
  ProgressBar,
  Spinner,
} from "react-bootstrap";
import { useForm } from "react-hook-form";

import { postData } from "../../../services/apiService";
import { useAuthContext } from "../../../context/authProvider";
import { NeedProfileURL } from "../../../constants";

/**
 * Component for managing user preferences.
 * Renders a user interface displaying a list of needs. Users can select any number of needs to add to their giving pages.
 * @param {object} props - The props passed to the component.
 * @returns {React.Element} - JSX for managing user preferences.
 */
const UserPreferences = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();

  const [isLazyLoading, setIsLazyLoading] = useState(false);
  const [page, setPage] = useState({ PageNumber: 1, PageSize: 25 });
  const [needs, setNeeds] = useState([]);
  const [selectedNeeds, setSelectedNeeds] = useState([]);
  const [loadMore, setLoadMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  // Reference to the scrollable element
  const scrollRef = useRef(null);

  useEffect(() => {
    /**
     * Update selected needs if preferences are not reset
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    if (!props.preferences.reset) setSelectedNeeds(props.preferences.data);
    else {
      setNeeds([...needs, ...selectedNeeds]);
      setSelectedNeeds([]);
    }
  }, [props.preferences]);

  useEffect(() => {
    /**
     * Filter out selected needs from all needs.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    if (selectedNeeds.length > 0) {
      const filteredNeeds = needs.filter(
        (need) =>
          !selectedNeeds.some(
            (selectedNeed) => need.needId === selectedNeed.needId
          )
      );
      setNeeds(filteredNeeds);
    }
    reset({
      needs: selectedNeeds.map((need) => need.needId),
    });
  }, [selectedNeeds, reset]);

  /**
   * Function to handle pagination change.
   * @param {number} pageNumber - The new page number.
   * @param {number} pageSize - The new page size.
   */
  const handlePageChange = (pageNumber, pageSize) => {
    setPage({
      PageNumber: pageNumber,
      PageSize: pageSize,
    });
  };

  /**
   * Function to handle search action.
   * @param {string} value - The search term entered by the user.
   */
  const handleSearch = (value) => {
    setNeeds([]);
    handlePageChange(1, page.PageSize);
    setSearchTerm(value);
  };

  /**
   * useEffect hook to handle lazy loading of more data when scrolling.
   * Attaches a scroll event listener to the provided element and fetches more data
   * when the user scrolls to the bottom of the element.
   * Removes the event listener when the component unmounts.
   * Executes when there is a change in the values of the dependencies in the useEffect hook.
   */
  useEffect(() => {
    // Reference to the scrollable element
    const element = scrollRef.current;
    // Event handler for scrolling
    const handleScroll = () => {
      if (element) {
        // Check if the user has scrolled to the bottom of the element
        const isAtBottom =
          element.scrollHeight - element.scrollTop <=
          element.clientHeight + 100;

        // Fetch more data here
        if (isAtBottom && !isLazyLoading && loadMore)
          handlePageChange(page.PageNumber + 1, page.PageSize);
      }
    };
    // Add event listener for scroll events
    if (element) element.addEventListener("scroll", handleScroll);

    return () => {
      // Remove event listener on cleanup to avoid memory leaks
      if (element) element.removeEventListener("scroll", handleScroll);
    };
  }, [loadMore, page]);

  useEffect(() => {
    /**
     * Fetches the published needs from the backend API.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getPublishedNeeds = async () => {
      setIsLazyLoading(true);
      try {
        const response = await postData(
          "/api/need/getNeedsByType",
          { searchTerm, page, type: "Published" },
          userSession
        );
        if (response.needs.length > 0)
          setNeeds((prevNeeds) => [...prevNeeds, ...response.needs]);
        else setLoadMore(false);
        setIsLazyLoading(false);
      } catch (error) {
        setNeeds([]);
        setIsLazyLoading(false);
      }
    };

    getPublishedNeeds();

    // Return a cleanup function
    return () => {
      // Cleanup logic
      // You can add more cleanup actions here if needed
    };
  }, [searchTerm, page.PageNumber, userSession]);

  /**
   * Function to toggle the selection of a need.
   */
  const toggleNeed = (need, type) => {
    if (type === "add") {
      setNeeds(needs.filter((n) => n.needId !== need.needId));
      setSelectedNeeds([...selectedNeeds, need]);
    } else {
      setSelectedNeeds(selectedNeeds.filter((n) => n.needId !== need.needId));
      setNeeds([...needs, need]);
    }
  };

  return (
    <Form onSubmit={handleSubmit((data) => props.onSubmit(data))}>
      <Row>
        <Col xs={12} sm={6}>
          <h6 className="mb-2 fw-semibold">Needs</h6>
          <p className="text-light-emphasis fs-small">
            Select a need you wish to showcase on your page.
          </p>
          <ListGroup className="need border p-3 scroll" ref={scrollRef}>
            <InputGroup className="mb-3 w-50">
              <InputGroup.Text className="bg-transparent border-end-0">
                <i className="flaticon-search fs-6"></i>
              </InputGroup.Text>
              <Form.Control
                type="text"
                className="bg-transparent border-start-0"
                placeholder="Find needs..."
                onKeyUp={(e) => {
                  if (e.target.value.length > 3 || !e.target.value)
                    handleSearch(e.target.value);
                }}
              />
            </InputGroup>
            {needs.map((need) => (
              <ListGroup.Item
                key={need.referenceId}
                as="li"
                className="d-flex justify-content-between align-items-start mb-2 border-0 p-3 rounded-3"
              >
                <Button
                  variant="secondary"
                  className="fs-5 add"
                  onClick={() => toggleNeed(need, "add")}
                >
                  +
                </Button>
                <div className="ms-3 w-100">
                  <h5 className="title mb-1">
                    <Link
                      to={`${NeedProfileURL}${need.referenceId}/${need.needId}`}
                      className="text-decoration-none link-dark"
                    >
                      {need.title}
                    </Link>
                  </h5>
                  <p className="text-light-emphasis fs-small mb-2">
                    For {need.displayName} | Validated By {need.validatorName} |
                    Needed By {need.neededOn}
                  </p>
                  <p className="mb-1 text-dark fw-light">
                    <span className="fw-bold">${need.raised}</span> Raised
                  </p>
                  <ProgressBar
                    variant="secondary"
                    now={need.percentageCompleted}
                  />
                  <p className="d-flex justify-content-between mt-1 mb-0">
                    <small className="text-dark fw-light">
                      <span className="text-secondary fw-semibold">
                        {need.percentageCompleted}
                      </span>{" "}
                      Donated
                    </small>
                    <small className="text-dark fw-light">
                      Goal{" "}
                      <span className="text-secondary fw-semibold">
                        ${need.amountNeeded}
                      </span>
                    </small>
                  </p>
                </div>
              </ListGroup.Item>
            ))}
            {needs.length === 0 && (
              <div className="d-flex h-100 align-items-center justify-content-center text-light-emphasis fw-light">
                Currently, there are no needs.
              </div>
            )}
            {isLazyLoading && (
              <div className="text-center my-3">
                <Spinner animation="border" role="status" variant="primary">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
          </ListGroup>
        </Col>
        <Col xs={12} sm={6}>
          <h6 className="mb-2 fw-semibold">Selected Needs</h6>
          <p className="text-light-emphasis fs-small">
            Your chosen needs will be displayed on your giving page.
          </p>
          <ListGroup className="need border p-3 scroll">
            {selectedNeeds.map((need) => (
              <ListGroup.Item
                key={need.referenceId}
                as="li"
                className="d-flex justify-content-between align-items-start mb-2 border-0 p-3 rounded-3"
              >
                <Button
                  variant="secondary"
                  className="fs-5 remove"
                  onClick={() => toggleNeed(need, "remove")}
                >
                  -
                </Button>
                <div className="ms-3 w-100">
                  <h5 className="title mb-1">
                    <Link
                      to={`${NeedProfileURL}${need.referenceId}/${need.needId}`}
                      className="text-decoration-none link-dark"
                    >
                      {need.title}
                    </Link>
                  </h5>
                  <p className="text-light-emphasis fs-small mb-2">
                    For {need.displayName} | Validated By {need.validatorName} |
                    Needed By {need.neededOn}
                  </p>
                  <p className="mb-1 text-dark fw-light">
                    <span className="fw-bold">${need.raised}</span> Raised
                  </p>
                  <ProgressBar
                    variant="secondary"
                    now={need.percentageCompleted}
                  />
                  <p className="d-flex justify-content-between mt-1 mb-0">
                    <small className="text-dark fw-light">
                      <span className="text-secondary fw-semibold">
                        {need.percentageCompleted}
                      </span>{" "}
                      Donated
                    </small>
                    <small className="text-dark fw-light">
                      Goal{" "}
                      <span className="text-secondary fw-semibold">
                        ${need.amountNeeded}
                      </span>
                    </small>
                  </p>
                </div>
              </ListGroup.Item>
            ))}
            {selectedNeeds.length === 0 && (
              <div className="d-flex h-100 align-items-center justify-content-center text-light-emphasis fw-light">
                Currently, there are no chosen needs.
              </div>
            )}
          </ListGroup>
        </Col>
      </Row>
      <Row className="justify-content-end">
        <Col xs={12} className="mt-3">
          <input
            name="needs"
            type="hidden"
            {...register("needs", {
              required: "Select a need you wish to showcase on your page",
            })}
          />
          {errors.needs && (
            <Form.Text className="text-danger">
              {errors.needs.message}
            </Form.Text>
          )}
        </Col>
        <Col xs={3} xl={2} className="mt-4 d-grid">
          <Button
            variant="secondary"
            className="btn-md"
            onClick={() => props.handleActiveTab("basic-info")}
          >
            BACK
          </Button>
        </Col>
        <Col xs={3} xl={2} className="mt-4 d-grid">
          <Button
            type="submit"
            variant="primary"
            className="btn-md"
            disabled={props.isLoading}
          >
            {props.isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "SUBMIT"
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default UserPreferences;
