import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Image,
  ListGroup,
  Modal,
  Button,
  Carousel,
  Collapse,
} from "react-bootstrap";

import {
  transformAttachments,
  transformProfilePicture,
} from "../../../components/transformFileURL";

import {
  SITEURL,
  OrgProfileURL,
  ValidatorProfileURL,
} from "../../../constants";

/**
 * Component for displaying information about a need.
 * This component includes sections for title, summary, recipient's information, validation statement, and attachments, etc.
 * @param {object} props - The props containing information about the need.
 * @returns {React.Element} - JSX for displaying need information.
 */
const NeedInfo = (props) => {
  const navigate = useNavigate();
  const [numSlides, setNumSlides] = useState(0);
  const [index, setIndex] = useState(0); // State to track the active slide index
  const [attachments, setAttachments] = useState([]);
  const [isHelpTextVisible, setHelpTextVisibility] = useState(false);

  const [showAttachmentModal, setShowAttachmentModal] = useState(false);

  /**
   * Function to close the attachment modal and resets state related to attachments
   */
  const closeModal = () => {
    setAttachments([]);
    setNumSlides(0);
    setIndex(0);
    setShowAttachmentModal(false);
  };

  /**
   * Function to displays attachments in a modal
   * @param {Array} files - Array of attachment files
   */
  const showcaseAttachments = (files, index) => {
    // Set the carousel to the clicked image's slide
    setIndex(index);

    setAttachments(files);
    setNumSlides(files.length);
    setShowAttachmentModal(true);
  };

  /**
   * Function to navigate to the note page.
   */
  const navigateToNote = () => {
    navigate(`/needs/send-note/${props.need?.needId}`, {
      state: {
        displayName: props.need.displayName,
        validatorName: props.need?.validatorName,
      },
    });
  };

  return (
    <Fragment>
      <h1 className="title fs-3">{props.need?.title}</h1>
      <p className="text-dark-emphasis mb-0">{props.need?.summary}</p>
      <h4 className="title mt-4 mb-2">
        {props.need?.displayName && `${props.need.displayName}'s Story`}{" "}
        {props.isPreview && (
          <i
            className="flaticon-edit fs-5"
            role="button"
            onClick={() => props.handleActiveTab("need-info")}
          ></i>
        )}
      </h4>
      {!props.isPreview &&
        props.isLoggedIn &&
        props.roles?.includes("Donor") && (
          <p className="mb-2">
            <Button
              variant="link"
              className="btn-md p-0 link-primary"
              onClick={navigateToNote}
            >
              Send a Note to {props.need?.displayName}
            </Button>
          </p>
        )}
      <p className="text-dark-emphasis">{props.need?.biography}</p>
      <p className="text-dark-emphasis">{props.need?.goals}</p>
      <p className="text-dark-emphasis">{props.need?.aboutNeed}</p>
      <p className="text-dark-emphasis">{props.need?.reasonForNeed}</p>
      {props.need?.validatorName && props.need?.organizationName && (
        <Fragment>
          <div className="d-flex align-items-center p-3 rounded-4 my-4 bg-tertiary">
            <Image
              fluid
              src={transformProfilePicture(props.need?.validatorProfilePicture)}
              alt={props.need?.validatorName}
              className="avatar"
              roundedCircle
              thumbnail
            />
            <div className="ms-3 text-dark-emphasis fs-small">
              <div className="mb-1">Validated By</div>
              <Link
                to={`${ValidatorProfileURL}${props.need?.validatorReferenceId}`}
                title="Profile"
                className="text-black fw-semibold text-decoration-none"
              >
                {props.need?.validatorName}
              </Link>{" "}
              from{" "}
              <Link
                to={`${OrgProfileURL}${props.need?.organizationReferenceId}`}
                title="Profile"
                className="link-secondary fw-semibold text-decoration-none"
              >
                {props.need?.organizationName}
              </Link>{" "}
              [
              <Button
                variant="link"
                title="Support Benevolent"
                className="link-primary p-0"
                onClick={() => setHelpTextVisibility(!isHelpTextVisible)}
              >
                ?
              </Button>
              ]
            </div>
          </div>
          <Collapse in={isHelpTextVisible}>
            <div className="bg-tertiary text-dark-emphasis fs-small p-3 my-3 helpText">
              Each need is validated by a nonprofit that knows the person and
              can verify that the help is necessary. Funds flow from Benevolent
              to the nonprofit, which then gets them to the person in need and
              lets us know how it went.{" "}
              <Link
                to={`${SITEURL}/how-it-works`}
                title="How It Works"
                className="link-primary text-decoration-none"
                target="blank"
              >
                Learn more {">>"}
              </Link>
            </div>
          </Collapse>
        </Fragment>
      )}
      <h4 className="title">
        Validation Statement{" "}
        {props.isPreview && !props.roles?.includes("Recipient") && (
          <i
            className="flaticon-edit fs-5"
            role="button"
            onClick={() => props.handleActiveTab("validation-statement")}
          ></i>
        )}
      </h4>
      <p className="text-dark-emphasis">{props.need?.validationStatement}</p>
      {props.need.needUpdates?.length > 0 && (
        <Fragment>
          <h4 className="title">Updates </h4>
          <ListGroup as="ul" id="needUpdates" variant="flush">
            {props.need.needUpdates.map((updates, index) => (
              <ListGroup.Item
                as="li"
                className="bg-transparent px-0 py-3 text-dark-emphasis mb-3"
                key={index}
              >
                <h6 className="fw-semibold mb-1">{updates.subject}</h6>
                <p className="fw-light mb-2">{updates.publishedOn}</p>
                <p className="my-2 text-pre-line">{updates.body}</p>
                {updates.attachments.length > 0 && (                  
                  <ListGroup horizontal>
                    {updates.attachments.map((attachment, index) => (
                      <ListGroup.Item
                        className="border-0 ps-0 pe-2"
                        key={attachment}
                      >
                        <Image
                          src={transformAttachments(attachment)}
                          alt="Attachment"
                          fluid
                          className="attachment-preview"
                          onClick={() =>
                            showcaseAttachments(updates.attachments, index)
                          }
                        />
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
          <Modal
            show={showAttachmentModal}
            aria-labelledby="Loading"
            className="modal-loading"
            centered
          >
            <Modal.Body className="p-0 text-center">
              <div className="text-end mb-3" data-bs-theme="dark">
                <Button
                  className="btn-close"
                  variant="link"
                  onClick={closeModal}
                ></Button>
              </div>
              <Carousel
                variant="dark"
                interval={null}
                indicators={false}
                controls={false}
                activeIndex={index}
              >
                {attachments.map((picture, index) => (
                  <Carousel.Item key={picture}>
                    <Image
                      fluid
                      src={transformAttachments(picture)}
                      alt="Attachments"
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            </Modal.Body>
            <Modal.Footer className="border-0 p-0 justify-content-center">
              {attachments.length > 1 && (
                <Fragment>
                  <Button
                    type="button"
                    variant="link"
                    size="sm"
                    className="link-light opacity-75"
                    onClick={() =>
                      setIndex((prevIndex) =>
                        prevIndex === 0 ? numSlides - 1 : prevIndex - 1
                      )
                    }
                  >
                    <i className="flaticon-back fs-1"></i>
                  </Button>
                  <Button
                    type="button"
                    variant="link"
                    size="sm"
                    className="link-light opacity-75"
                    onClick={() =>
                      setIndex((prevIndex) =>
                        prevIndex === numSlides - 1 ? 0 : prevIndex + 1
                      )
                    }
                  >
                    <i className="flaticon-next fs-1"></i>
                  </Button>
                </Fragment>
              )}
            </Modal.Footer>
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
};
export default NeedInfo;
