import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, Image } from "react-bootstrap";

import { SITEURL } from "../../constants";

import logo from "../../resources/images/logo.svg";
import partner from "../../resources/images/partner.png";
import validator from "../../resources/images/validator.png";
import donor from "../../resources/images/donor.png";

/**
 * Component for choosing account type for registration.
 * This component provides functionality for users to choose between different types of accounts to register.
 * @returns {React.Element} - Returns JSX for choosing account type for registration.
 */
const AccountType = () => {
  return (
    <main className="d-flex align-items-center justify-content-center min-vh-100 flex-column account">
      <Container>
        <Row className="justify-content-center">
          <Col xs={11} sm={12} md={9} lg={7} xl={6} xxl={5}>
            <div className="text-center mb-4">
              <Link to={SITEURL} title="Benevolent">
                <Image src={logo} alt="Benevolent" className="logo" fluid />
              </Link>
            </div>
            <Card className="border-0 shadow p-4 rounded-4">
              <Card.Body>
                <h3 className="text-center mb-4 title">Register</h3>
                <Link
                  to="/partner/enrollment"
                  title="Become a Partner"
                  className="d-flex align-items-center p-3 mb-4 border rounded text-decoration-none"
                >
                  <div className="flex-shrink-0">
                    <Image src={partner} alt="Validator" fluid />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="title">Apply to become a Partner</h5>
                    <p className="mb-0 text-dark-emphasis fs-small">
                      If you want to become a Benevolent partner. To begin, we
                      need to learn more about you and your organization.
                    </p>
                  </div>
                </Link>
                <Link
                  to="/validator/request-account"
                  title="Request Validator Account"
                  className="d-flex align-items-center p-3 mb-4 border rounded text-decoration-none"
                >
                  <div className="flex-shrink-0">
                    <Image src={validator} alt="Validator" fluid />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="title">Register your Validator Account</h5>
                    <p className="mb-0 text-dark-emphasis fs-small">
                      My coworker is a validator and I'd like to start posting
                      needs, too.
                    </p>
                  </div>
                </Link>
                <Link
                  to="/donor/register"
                  title="Register as a Donor"
                  className="d-flex align-items-center p-3 border rounded text-decoration-none"
                >
                  <div className="flex-shrink-0">
                    <Image src={donor} alt="Validator" fluid />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="title">Register as a Donor</h5>
                    <p className="mb-0 text-dark-emphasis fs-small">
                      Use this form to register an account to become a donor.
                    </p>
                  </div>
                </Link>
              </Card.Body>
            </Card>
            <div className="text-center mt-4 text-dark-emphasis">
              Already Registered?{" "}
              <Link
                to="/"
                title="Sign In"
                className="text-decoration-none link-secondary fw-semibold"
              >
                Sign In
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default AccountType;
