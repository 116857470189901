import { Navigate } from "react-router-dom";

import { useAuthContext } from "../context/authProvider";
import { decodeToken } from "../utils/session";

/**
 * Component for protecting routes based on user authentication and roles.
 * @param {Object} children - The content to be rendered within the protected route.
 * @param {Array} allowedRoles - An array of roles allowed to access the route.
 * @returns {React.Element} - Returns JSX element representing the protected route.
 */
export const ProtectedRoute = ({ children, allowedRoles }) => {
  const { userSession } = useAuthContext();
  const user = decodeToken(userSession);

  // If the user session is null, indicating that the user is not authenticated, 
  // redirect to the "/unauthorized" route.
  if (user === null) return <Navigate to="/unauthorized" />;
  else {
    if (allowedRoles.length > 0) {
      // Check if the user's role is included in the allowed roles. 
      // If user's role is not allowed, redirect to the "/access-denied" route.
      if (!allowedRoles.find((role) => user.roles.includes(role)))
        return <Navigate to="/access-denied" />;
    }
  }

  // If the user is authenticated and has the required role (if specified), render the component.
  return children;
};
