import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { Image, Spinner } from "react-bootstrap";
import key from "weak-key";

import { postData } from "../../services/apiService";

import { Swiper, SwiperSlide } from "../../components/swiper";
import { Icons } from "../../components/iconImporter";

/**
 * Component for exploring categories.
 * @returns {React.Element} - Returns JSX for exploring categories.
 */
const ExploreCategories = () => {
  const [loadMore, setLoadMore] = useState(true);
  const [categories, setCategories] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [page, setPage] = useState({ PageNumber: 1, PageSize: 25 });
  const swiperRef = useRef(null); // Reference for the Swiper instance

  useEffect(() => {
    /**
     * Fetches categories from the backend API.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getCategories = async () => {
      setIsPageLoading(true);
      try {
        const response = await postData(`/api/category/getByPage`, page, null);
        // Find and replace icon value with corresponding icon from Icons array
        const updatedCategories = response.categories.map((item) => {
          return {
            ...item,
            icon: Icons.find((x) => x.value === item.icon)?.icon,
          };
        });
        // Determine if more categories can be loaded
        setLoadMore(updatedCategories.length < page.PageSize ? false : true);
        setCategories((prevCategories) => [
          ...prevCategories,
          ...updatedCategories,
        ]);
        setIsPageLoading(false);

        if (swiperRef.current) swiperRef.current.update(); // Update Swiper instance
      } catch (error) {
        setIsPageLoading(false);
        setCategories([]);
      }
    };
    if (loadMore) getCategories();
  }, [page, loadMore]);

  /**
   * Function to navigate to the previous slide in the Swiper component.
   * If the Swiper reference exists, it slides to the previous slide.
   */
  const goToPreviousSlide = () => {
    if (swiperRef.current) swiperRef.current.slidePrev();
  };

  /**
   * Function to navigate to the next slide in the Swiper component.
   * If the Swiper reference exists, it slides to the next slide.
   * If the current slide is the last slide or the end of the Swiper, it updates the page number.
   */
  const goToNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
      const { isEnd, activeIndex, slides } = swiperRef.current;
      if (isEnd || activeIndex === slides.length - 1) {
        setPage((prevPageStates) => ({
          ...prevPageStates,
          PageNumber: prevPageStates.PageNumber + 1,
        }));
      }
    }
  };

  /**
   * Function to compute the height of the widget
   */
  const computeWidgetHeight = useCallback(() => {
    // Wait for elements with the specified class name to be rendered
    // Get the height of the widget content
    const element = document.getElementsByClassName("explore-categories");
    const elementHeight = computeElementHeight(element);
    const categoryWidgetHeight = elementHeight + 10;
    // Send the height to the parent window
    window.parent.postMessage({ categoryWidgetHeight }, "*");
  }, []);

  useEffect(() => {
    /**
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    computeWidgetHeight();

    // Event listener for window resize
    window.addEventListener("resize", computeWidgetHeight);
    return () => {
      // Cleanup logic
      // You can add more cleanup actions here if needed
      window.removeEventListener("resize", computeWidgetHeight);
    };
  }, [categories, loadMore, computeWidgetHeight]);


  /**
   * Function to compute the total height of an element including padding, margin, and borders
   */
  const computeElementHeight = (element) => {
    // Calculate total height including padding, margin, and borders
    let totalHeight = 0;
    if (element.length > 0) {
      const computedStyles = window.getComputedStyle(element[0]);
      const marginTop = parseFloat(computedStyles.marginTop);
      const marginBottom = parseFloat(computedStyles.marginBottom);
      const paddingTop = parseFloat(computedStyles.paddingTop);
      const paddingBottom = parseFloat(computedStyles.paddingBottom);
      const borderTopWidth = parseFloat(computedStyles.borderTopWidth);
      const borderBottomWidth = parseFloat(computedStyles.borderBottomWidth);

      totalHeight =
        element[0].offsetHeight +
        marginTop +
        marginBottom +
        paddingTop +
        paddingBottom +
        borderTopWidth +
        borderBottomWidth;
    }
    return totalHeight;
  };

  return (
    <div className="explore-categories">
      <Swiper
        loop={false}
        centerInsufficientSlides={true}
        slidesPerView={"auto"}
        spaceBetween={15}
        on={{
          init: (swiper) => {
            swiperRef.current = swiper;
          },
        }}
      >
        {categories.map((category) => (
          <SwiperSlide key={key(category)}>
            <div className="d-flex align-items-center justify-content-center border box">
              <Link
                to={`/needs/search?category=${category.name}`}
                className="text-dark text-center text-decoration-none"
                target="_blank"
              >
                <Image
                  src={category.icon}
                  alt={category.name}
                  width="25"
                  fluid
                ></Image>
                <span className="mt-2 mb-0 fs-small d-block">{category.name}</span>
              </Link>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div
        className={`text-center ${categories.length > 0 ? "d-block" : "d-none"
          }`}
      >
        <div
          className="swiper-button-prev me-3 border-secondary text-secondary"
          onClick={goToPreviousSlide}
        >
          <i className="flaticon-line-arrow-left fs-4"></i>
        </div>
        <div
          className="swiper-button-next border-secondary text-secondary"
          onClick={goToNextSlide}
        >
          <i className="flaticon-line-arrow-right fs-4"></i>
        </div>
      </div>

      {categories.length === 0 && !isPageLoading && (
        <p className="text-light-emphasis my-4 text-center">
          At the moment, there are no categories.
        </p>
      )}
      <div
        className={`modal-backdrop loading-indicator-widget ${isPageLoading || "d-none"
          }`}
        aria-labelledby="Loading"
      >
        <div className="d-flex align-items-center justify-content-center vh-100">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </div>
    </div>
  );
};

export default ExploreCategories;
