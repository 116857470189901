import React, { Fragment, useState, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Card,
  Dropdown,
  Button,
  Alert,
  Modal,
  Spinner,
  Image,
  Form,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import DataTable from "../../../components/table";
import { transformProfilePicture } from "../../../components/transformFileURL";

import { postData } from "../../../services/apiService";
import { useAuthContext } from "../../../context/authProvider";
import { notify, initialState } from "../../../store/notification";
import { Host } from "../../../constants";
import confirmation from "../../../resources/images/confirmation.png";

/**
 * Component for managing user giving pages.
 * @returns {React.Element} - JSX for managing user giving pages.
 */
const ManageUserGivingPages = () => {
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [page, setPage] = useState({ PageNumber: 1, PageSize: 25 });
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [givingPages, setGivingPages] = useState([]);

  // Columns configuration for the table
  const columns = [
    {
      header: "Title",
      accessorKey: "organizationName",
      cell: (cellProps) => {
        return (
          <Fragment>
            <Link
              to={`/giving-page/user/${cellProps.row.original.urlReferenceId}`}
              className="text-decoration-none d-flex align-items-center text-start"
              title="Giving Page"
            >
              <div className="flex-shrink-0">
                <Image
                  src={transformProfilePicture(
                    cellProps.row.original.profilePicture
                  )}
                  alt={cellProps.row.original.title}
                  roundedCircle
                  thumbnail
                  fluid
                  className="avatar"
                />
              </div>
              <div className="flex-grow-1 ms-3 text-dark">
                {cellProps.row.original.title}
              </div>
            </Link>
          </Fragment>
        );
      },
    },
    {
      header: "Needs Count",
      accessorKey: "needsCount",
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: (cellProps) => {
        return (
          <Form.Check
            type="switch"
            name={`chkStatus_${cellProps.row.original.id}`}
            id={`chkStatus_${cellProps.row.original.id}`}
            variant="secondary"
            defaultChecked={
              cellProps.row.original.status === "Active" ? true : false
            }
            onClick={(e) => updateStatus(e, cellProps.row.original.id)}
          />
        );
      },
    },
    {
      header: "Created On",
      accessorKey: "createdOn",
    },
    {
      header: "Actions",
      accessorKey: "id",
      cell: (cellProps) => {
        return (
          <Dropdown drop="down" className="more-menu">
            <Dropdown.Toggle variant="light" size="sm">
              <i className="flaticon-more"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                to={`/giving-page/user/${cellProps.row.original.urlReferenceId}`}
                as={Link}
              >
                <i className="flaticon-need fs-4 me-1"></i>View Giving Page
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                onClick={() =>
                  copyToClipboard(
                    `${Host}/giving-page/user/${cellProps.row.original.urlReferenceId}`
                  )
                }
              >
                <i className="flaticon-copy fs-4 me-1"></i>Copy URL
              </Dropdown.Item>
              <Dropdown.Item
                to={`/giving-page/user/edit/${cellProps.row.original.id}`}
                as={Link}
              >
                <i className="flaticon-edit fs-6 me-1"></i>Edit
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => showConfirmation(cellProps.row.original.id)}
              >
                <i className="flaticon-delete fs-6 me-1 align-text-bottom"></i>
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  /**
   * Fucntion to copy the specified text to the clipboard.
   * @param {string} textToCopy - The text to be copied.
   */
  const copyToClipboard = (textToCopy) => {
    // Create a new text area element to hold the text
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;

    // Make the text area invisible
    textArea.style.position = "fixed";
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.opacity = "0";

    // Append the text area to the DOM
    document.body.appendChild(textArea);

    // Select the text in the text area
    textArea.select();

    try {
      // Attempt to copy the selected text
      document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy to clipboard:", err);
    } finally {
      // Remove the text area from the DOM
      document.body.removeChild(textArea);
    }
  };

  /**
   * Function to update the status of a user giving page.
   * @param {object} event - The event object representing the checkbox toggle (optional).
   * @param {string} id - The ID of the user giving page.
   */
  const updateStatus = async (event, id) => {
    setIsPageLoading(true);
    try {
      let status = event
        ? event.target.checked
          ? "Active"
          : "Inactive"
        : "Deleted";
      const response = await postData(
        `/api/fundraising/updateUserGivingPageStatus/${id}/${status}`,
        null,
        userSession
      );
      setIsPageLoading(false);
      showNotification("success", response, 5000);
      if (status === "Deleted") {
        setPage((prevPage) => ({
          ...prevPage,
          pageNumber: prevPage.pageNumber,
        }));
      } else {
        const updatedGivingPages = [...givingPages];
        updatedGivingPages.map((item) => {
          if (item.id === id) item.status = status;
          return item;
        });
        setGivingPages(updatedGivingPages);
      }
    } catch (error) {
      // Show a notification if an error occurs during update the status
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  /**
   * Function to handle pagination change.
   * @param {number} pageNumber - The new page number.
   * @param {number} pageSize - The new page size.
   */
  const handlePageChange = (pageNumber, pageSize) => {
    setGivingPages([]);
    setPage({
      PageNumber: pageNumber,
      PageSize: pageSize,
    });
  };

  /**
   * Function to handle search action.
   * @param {string} value - The search term entered by the user.
   */
  const handleSearch = (value) => {
    handlePageChange(1, page.PageSize);
    setSearchTerm(value);
  };

  useEffect(() => {
    /**
     * Function to fetch list of giving pages associated with user from the backend API.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getAll = async () => {
      setIsLoading(true);
      try {
        const response = await postData(
          `/api/fundraising/getUserGivingPagesByPage?searchTerm=${searchTerm}`,
          page,
          userSession
        );
        setGivingPages(response.givingPages);
        if (response.count != null) setTotalRecords(response.count);
        setIsLoading(false);
      } catch (error) {
        // Show a notification if an error occurs during fetch the user giving page data
        showNotification("danger", error, 5000);
        setGivingPages([]);
        setIsLoading(false);
      }
    };
    getAll();
    // Return a cleanup function
    return () => {
      // Cleanup logic
      // You can add more cleanup actions here if needed
    };
  }, [searchTerm, page, userSession]); // Empty dependency array means the effect runs only once (on mount)

  /**
   * Function to display a confirmation dialog for deleting the giving page.
   * @param {string} id - The ID of the giving page to be deleted.
   */
  const showConfirmation = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Card className="border-0 shadow rounded-3">
            <Card.Body className="p-4 text-center">
              <Image src={confirmation} alt="Delete Confirmation" fluid />
              <div className="mt-3 fw-light">
                Do you really want to delete this giving page? <br />
                This process can't be undone.
              </div>
            </Card.Body>
            <Card.Footer className="bg-transparent py-4 text-center">
              <Button
                title="Cancel"
                variant="link"
                className="link-danger fw-semibold"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                className="ms-4 btn-md"
                onClick={async () => {
                  try {
                    await updateStatus(null, id);
                  } catch (error) {
                    // showNotification("danger", error, 5000);
                  }
                  onClose();
                }}
              >
                Yes, Delete
              </Button>
            </Card.Footer>
          </Card>
        );
      },
    });
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="w-75">
            <h6 className="module-title fw-semibold mb-0">
              Manage Giving Pages
            </h6>
            <p className="mt-2 mb-0 fs-small">
              Build sharing pages to unite friends and family in giving.
              Highlight chosen needs with <br className="d-none d-md-block" />{" "}
              photos and descriptions, and invite friends to join in, for events
              or pure generosity.
            </p>
          </div>
          <Link
            to="/giving-page/user/create"
            className="ms-3 btn btn-secondary"
            title="Create User Giving Page"
          >
            + Create
          </Link>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <DataTable
              columns={columns}
              data={givingPages}
              totalRecords={totalRecords}
              page={page}
              onPageChange={handlePageChange}
              searchRecords={handleSearch}
              loadingState={isLoading}
            />
          </Card.Body>
        </Card>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </main>
  );
};
export default ManageUserGivingPages;
