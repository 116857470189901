import React, { useState, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Form,
  Button,
  InputGroup,
  Spinner,
  Alert,
  Modal,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";

import {
  authenticate,
  authenticateWithSocialProvider,
} from "../../services/accountService";
import { notify, initialState } from "../../store/notification";
import { useAuthContext } from "../../context/authProvider";
import { Facebook_AppId, Google_ClientId, SITEURL } from "../../constants";

import logo from "../../resources/images/logo.svg";
import google from "../../resources/images/google.png";

/**
 * Component for donor login.
 * @returns {React.Element} - Returns JSX for donor login.
 * @access Accessible by Donor.
 */
const DonorLogin = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'saveToken' and 'returnURL' object from the 'useAuthContext()' hook
  const { saveToken, returnURL } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setPageLoading] = useState(false);

  /**
   * Function to handle user login and redirect to their appropriate pages.
   * @param {object} data - The user login data.
   */
  const handleLogin = async (data) => {
    setIsLoading(true);
    try {
      const response = await authenticate(data);
      setIsLoading(false);
      if (response.isRequiresTwoFactor) {
        // If two-factor authentication is required, navigate to verify account page
        navigate(
          returnURL
            ? `/verify-account?returnUrl=${returnURL}`
            : "/verify-account",
          {
            replace: true,
            state: { email: data.email },
          }
        );
      } else if (response.isForceToChangePwd)
        // If user is forced to change password, navigate to password change request page
        navigate(
          returnURL
            ? `/password-change-request?returnUrl=${returnURL}`
            : "/password-change-request"
        );
      else {
        // Persist the JWT token in the client's browser using local storage.
        // True parameter indicates it is from the login page
        saveToken(response.token, true);
      }
    } catch (error) {
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
      setIsLoading(false);
    }
  };

  /**
   * Function to login a user using social media provider
   * @param {object} data - The data object containing user information from the social media provider.
   */
  const loginWithSocialProvider = async (data) => {
    setPageLoading(true);
    // Extract user data from the response and format it
    var user = {
      email: data.data.email,
      provider: data.provider,
      firstName:
        data.provider === "google"
          ? data.data.given_name
          : data.data.first_name,
      lastName:
        data.provider === "google"
          ? data.data.family_name
          : data.data.last_name,
      providerKey: data.provider === "google" ? data.data.sub : data.data.id,
      accessToken:
        data.provider === "google"
          ? data.data.access_token
          : data.data.accessToken,
    };
    try {
      const result = await authenticateWithSocialProvider(user);
      // Persist the JWT token in the client's browser using local storage.
      saveToken(result);
    } catch (error) {
      setPageLoading(false);
      // Show a notification if an error occurs
      showNotification("danger", error, 5000);
    }
  };

  /**
   * Function to handle authentication rejection from social media provider.
   * @param {object} err - The error object received from the authentication process.
   */
  const handleAuthReject = (err) => {
    console.log(err);
    if (err.authResponse != null) {
      showNotification(
        "danger",
        "An error occurred during the login process.",
        5000
      );
    }
  };

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main className="d-flex align-items-center justify-content-center min-vh-100 flex-column">
      <Container>
        <Row className="justify-content-center">
          <Col xs={11} sm={11} md={9} lg={7} xl={6} xxl={5}>
            <div className="text-center mb-5">
              <Link to={SITEURL} title="Benevolent">
                <Image src={logo} alt="Benevolent" className="logo" fluid />
              </Link>
            </div>
            <Card className="border-0 shadow p-4 rounded-4">
              <Card.Body>
                <div className="d-flex gap-2 mb-4 justify-content-between">
                  {/* Login via Facebook */}
                  <LoginSocialFacebook
                    appId={Facebook_AppId}
                    onResolve={loginWithSocialProvider}
                    onReject={handleAuthReject}
                  >
                    <Button className="d-flex align-items-center btn btn-facebook">
                      <div className="flex-shrink-0 icon">
                        <i className="flaticon-facebook-filled fs-4"></i>
                      </div>
                      <div className="flex-grow-1 ms-3 text-start">
                        Sign in with Facebook
                      </div>
                    </Button>
                  </LoginSocialFacebook>
                  {/* Login via Google */}
                  <LoginSocialGoogle
                    client_id={Google_ClientId}
                    scope="profile email"
                    onResolve={loginWithSocialProvider}
                    onReject={handleAuthReject}
                  >
                    <Button className="d-flex align-items-center btn btn-google">
                      <div className="flex-shrink-0 icon">
                        <Image src={google} alt="Sign in with Google" fluid />
                      </div>
                      <div className="flex-grow-1 ms-3 text-start">
                        Sign in with Google
                      </div>
                    </Button>
                  </LoginSocialGoogle>
                </div>
                <Row className="align-items-center">
                  <Col>
                    <hr />
                  </Col>
                  <Col xs={4} className="text-center p-0">
                    <small className="text-light-emphasis fw-light">
                      or Sign in with Email
                    </small>
                  </Col>
                  <Col>
                    <hr />
                  </Col>
                </Row>
                <Form onSubmit={handleSubmit(handleLogin)}>
                  <Form.Group className="my-4">
                    <InputGroup>
                      <Form.Floating>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid email address",
                            },
                          })}
                        />
                        <Form.Label>Email</Form.Label>
                      </Form.Floating>
                      <InputGroup.Text>
                        <i className="flaticon-mail fs-4"></i>
                      </InputGroup.Text>
                    </InputGroup>
                    {errors.email && (
                      <Form.Text className="text-danger">
                        {errors.email.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <InputGroup>
                      <Form.Floating>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          {...register("password", {
                            required: "Password is required",
                          })}
                        />
                        <Form.Label>Password</Form.Label>
                      </Form.Floating>
                      <InputGroup.Text>
                        <i className="flaticon-password fs-3"></i>
                      </InputGroup.Text>
                    </InputGroup>
                    {errors.password && (
                      <Form.Text className="text-danger">
                        {errors.password.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Row className="mb-5">
                    {/* <Col>
                                            <Form.Check
                                                type="checkbox"
                                                label="Remember Me"
                                                className="fw-light"
                                            />
                                        </Col> */}
                    <Col className="text-end">
                      <Link
                        to="/recovery-password"
                        title="Recovery Password"
                        className="text-decoration-none link-secondary fw-light"
                      >
                        Recovery Password?
                      </Link>
                    </Col>
                  </Row>
                  <div className="d-grid gap-2">
                    <Button
                      variant="primary"
                      disabled={isLoading}
                      type="submit"
                      size="lg"
                    >
                      {isLoading ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        "SIGN IN"
                      )}
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
            <div className="text-center mt-5">
              <p className="mb-0 text-dark-emphasis">
                Not a Member Yet?{" "}
                <Link
                  to="/register"
                  title="Register Now"
                  className="text-decoration-none link-secondary fw-semibold"
                >
                  Register Now
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="notification show migration-alert text-center">
        <Alert variant="dark" dismissible>
          For existing system users, kindly utilize the{" "}
          <Link
            to="/recovery-password"
            title="Recovery Password"
            className="text-decoration-none link-secondary fw-bold"
          >
            password recovery
          </Link>{" "}
          feature to initiate the process of resetting your password on the new
          system.
        </Alert>
      </div>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default DonorLogin;
