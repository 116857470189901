import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { Image, Card, Spinner } from "react-bootstrap";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";

import { postData } from "../../services/apiService";

import { Swiper, SwiperSlide } from "../../components/swiper";
import { transformProfilePicture } from "../../components/transformFileURL";

import {
  NeedProfileURL,
  ValidatorProfileURL,
  OrgProfileURL,
} from "../../constants";

// responsiveHOC is a Higher Order Component (HOC) used for responsive behavior,
// and LinesEllipsis is a component for truncating text with ellipsis.
const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

/**
 * Component for exploring funded needs.
 * @returns {React.Element} - Returns JSX for exploring funded needs.
 */
const ExploreFundedNeeds = () => {
  const [loadMore, setLoadMore] = useState(true);
  const [needs, setNeeds] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  // const [page, setPage] = useState({ PageNumber: 1, PageSize: 25 });
  const [page] = useState({ PageNumber: 1, PageSize: 10 });
  const swiperRef = useRef(null); // Reference for the Swiper instance

  useEffect(() => {
    /**
     * Fetches funded needs from the backend API.
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getNeeds = async () => {
      setIsPageLoading(true);
      try {
        const response = await postData(
          "/api/need/getNeedsByType/",
          {
            type: "Funded",
            page,
          },
          null
        );

        // Transform the profile pictures in the response to appropriate sizes
        const updatedNeeds = transformProfilePicture(response.needs);
        // Determine if more needs can be loaded
        setLoadMore(updatedNeeds.length < page.PageSize ? false : true);
        setNeeds((prevNeeds) => [...prevNeeds, ...updatedNeeds]);
        setIsPageLoading(false);

        if (swiperRef.current) swiperRef.current.update();
      } catch (error) {
        setIsPageLoading(false);
        setNeeds([]);
      }
    };
    if (loadMore) getNeeds();
  }, [page, loadMore]);

  /**
   * Function to navigate to the previous slide in the Swiper component.
   * If the Swiper reference exists, it slides to the previous slide.
   */
  const goToPreviousSlide = () => {
    if (swiperRef.current) swiperRef.current.slidePrev();
  };

  /**
   * Function to navigate to the next slide in the Swiper component.
   * If the Swiper reference exists, it slides to the next slide.
   * If the current slide is the last slide or the end of the Swiper, it updates the page number.
   */
  const goToNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
      // if (
      //   swiperRef.current.isEnd ||
      //   swiperRef.current.activeIndex === swiperRef.current.slides.length - 1
      // ) {
      //   setPage((prevPageStates) => ({
      //     ...prevPageStates,
      //     PageNumber: prevPageStates.PageNumber + 1,
      //   }));
      // }
    }
  };

  /**
  * Function to compute the height of the widget
  */
  const computeWidgetHeight = useCallback(() => {
    // Wait for elements with the specified class name to be rendered
    // Get the height of the widget content
    const element = document.getElementsByClassName("explore-funded-needs");
    const elementHeight = computeElementHeight(element);
    const fundedNeedsWidgetHeight = elementHeight + 6;
    // Send the height to the parent window
    window.parent.postMessage({ fundedNeedsWidgetHeight }, "*");
  },[]);

  useEffect(() => {
    /**
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    computeWidgetHeight();

    // Event listener for window resize
    window.addEventListener("resize", computeWidgetHeight);
    return () => {
      // Cleanup logic
      // You can add more cleanup actions here if needed
      window.removeEventListener("resize", computeWidgetHeight);
    };
  }, [needs, loadMore, computeWidgetHeight]);



  /**
   * Function to compute the total height of an element including padding, margin, and borders
   */
  const computeElementHeight = (element) => {
    // Calculate total height including padding, margin, and borders
    let totalHeight = 0;
    if (element.length > 0) {
      const computedStyles = window.getComputedStyle(element[0]);
      const marginTop = parseFloat(computedStyles.marginTop);
      const marginBottom = parseFloat(computedStyles.marginBottom);
      const paddingTop = parseFloat(computedStyles.paddingTop);
      const paddingBottom = parseFloat(computedStyles.paddingBottom);
      const borderTopWidth = parseFloat(computedStyles.borderTopWidth);
      const borderBottomWidth = parseFloat(computedStyles.borderBottomWidth);

      totalHeight =
        element[0].offsetHeight +
        marginTop +
        marginBottom +
        paddingTop +
        paddingBottom +
        borderTopWidth +
        borderBottomWidth;
    }
    return totalHeight;
  };

  return (
    <div className="explore-funded-needs">
      <Swiper
        loop={true}
        centerInsufficientSlides={true}
        slidesPerView={"auto"}
        spaceBetween={15}
        on={{
          init: (swiper) => {
            swiperRef.current = swiper;
          },
        }}
      >
        {needs.map((need) => (
          <SwiperSlide key={need.referenceId} gridClass="swiper-slide-grid">
            <Card className="border-0 shadow-sm rounded-4 h-100 need">
              <Card.Body>
                <Link
                  to={`${NeedProfileURL}${need.referenceId}/${need.needId}`}
                  title="Need Profile"
                  className="profile-pic"
                  target="_blank"
                >
                  <Image
                    src={need.profilePicture}
                    alt="Profile Picture"
                    fluid
                    className="rounded-circle"
                  ></Image>
                </Link>
                <p className="mt-5 pt-2 mb-2 fs-small location">
                  <Link
                    to={`${NeedProfileURL}${need.referenceId}/${need.needId}`}
                    className="text-decoration-none text-secondary fw-semibold"
                    title="Need Profile"
                    target="_blank"
                  >
                    {need.displayName}
                  </Link>
                  <span className="text-secondary fw-light">
                    {" "}
                    from {need.city}, {need.country}
                  </span>
                </p>
                <h5 className="title mb-3">
                  <Link
                    to={`${NeedProfileURL}${need.referenceId}/${need.needId}`}
                    className="text-decoration-none link-dark"
                    target="_blank"
                    title="Need Profile"
                  >
                    <ResponsiveEllipsis
                      text={need.title}
                      maxLine="3"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                  </Link>
                </h5>
                <p className="mb-2">
                  <span className="fw-bold fs-5">${need.raised}</span>
                  <span className="text-dark fs-6">
                    {" "}
                    of ${need.amountNeeded}
                  </span>
                </p>
                <div
                  className={`progress ${need.needStatus === "Funded" &&
                      need.percentageCompleted < 100
                      ? "bg-secondary-subtle"
                      : ""
                    }`}
                  role="progressbar"
                  aria-label="Percentage of Need Funded"
                >
                  <div
                    className={`progress-bar ${need.percentageCompleted > 100
                        ? "bg-primary"
                        : "bg-secondary"
                      }`}
                    style={{ width: `${need.percentageCompleted}%` }}
                  />
                </div>
                <p className="d-flex justify-content-between mt-2">
                  <small className="text-dark fw-light">
                    <span className="text-secondary fw-semibold">
                      {need.totalSupporters}
                    </span>{" "}
                    Supporters
                  </small>
                  <small className="text-dark fw-light">
                    {need.needStatus !== "Funded" && (
                      <span className="text-secondary fw-semibold">
                        {need.daysLeft}
                      </span>
                    )}
                    {need.needStatus === "Funded"
                      ? "Funded On: "
                      : " Days Left"}
                    {need.needStatus === "Funded" && (
                      <span className="text-secondary fw-semibold">
                        {need.fundedOn}
                      </span>
                    )}
                  </small>
                </p>
                <p className="fs-small text-dark-emphasis mb-2">
                  Validated by{" "}
                  <Link
                    to={`${ValidatorProfileURL}${need.validatorReferenceId}`}
                    title="Validator Profile"
                    className="link-dark fw-semibold text-decoration-none"
                    target="_blank"
                  >
                    {need.validatorName}
                  </Link>{" "}
                  from{" "}
                  <Link
                    to={`${OrgProfileURL}${need.organizationReferenceId}`}
                    title="Organization Profile"
                    className="link-dark fw-semibold text-decoration-none"
                    target="_blank"
                  >
                    {need.organizationName}
                  </Link>
                </p>
              </Card.Body>
              <Card.Footer className="bg-transparent border-0 py-0 pe-0 text-end">
                <Link
                  className="btn btn-secondary btn-arrow"
                  to={`${NeedProfileURL}${need.referenceId}/${need.needId}`}
                  target="_blank"
                >
                  <i className="flaticon-line-arrow-right fs-5"></i>
                </Link>
              </Card.Footer>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className={`text-center ${needs.length > 0 ? "d-block" : "d-none"}`}>
        <div
          className="swiper-button-prev me-3 border-secondary text-secondary"
          onClick={goToPreviousSlide}
        >
          <i className="flaticon-line-arrow-left fs-4"></i>
        </div>
        <div
          className="swiper-button-next border-secondary text-secondary"
          onClick={goToNextSlide}
        >
          <i className="flaticon-line-arrow-right fs-4"></i>
        </div>
      </div>

      {needs.length === 0 && !isPageLoading && (
        <p className="text-light-emphasis my-4 text-center">
          At the moment, there are no needs that have been successfully funded.
        </p>
      )}
      <div
        className={`modal-backdrop loading-indicator-widget ${isPageLoading || "d-none"
          }`}
        aria-labelledby="Loading"
      >
        <div className="d-flex align-items-center justify-content-center vh-100">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </div>
    </div>
  );
};

export default ExploreFundedNeeds;
