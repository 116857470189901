import React, { useState, useEffect, Fragment } from "react";
import {
  Link,
  useLocation,
  useParams,
} from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Card,
  Modal,
  Spinner,
} from "react-bootstrap";

import Need from "./_orgPreferences";
import Pagination from "../../components/pagination";
import { transformProfilePicture } from "../../components/transformFileURL";

import { getData, postData } from "../../services/apiService";

import noData from "../../resources/images/no-data.png";

/**
 * Component for displaying organization giving page.
 * This component is intended to be embedded in an iframe on third-party websites. This is called as `white label` feature. 
 * It displays the needs for organization based on their ` unique code`. The purpose of this feature is to collects donations from the wide range of people.
 * We categorized the transaction as white-label to distinguish donations made from benevolent.
 * @returns {React.Element} - JSX for organization giving page.
 */
const OrgGivingPage = () => {
  const { code } = useParams();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [needs, setNeeds] = useState([]);
  const [page, setPage] = useState({ PageNumber: 1, PageSize: 25 });
  const [totalRecords, setTotalRecords] = useState(0);
  const [givingPage, setGivingPage] = useState({});
  const [isMounted, setIsMounted] = useState(false);
  // To determine the origin of the iframe and whether it is a white-label integration.
  const [isWhiteLabel] = useState(window.self !== window.top);

  
  useEffect(() => {
    /**
     * Function to fetch the giving page data from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getGivingPage = async () => {
      setIsPageLoading(true);
      try {
        const response = await getData(`/api/fundraising/getByCode/${code}`);
        if (response) setGivingPage(response);
        else setIsMounted(true);
        setIsPageLoading(false);
      } catch (error) {
        setIsPageLoading(false);
        setIsMounted(true);
      }
    };
    getGivingPage();
  }, [code]);

  useEffect(() => {
    /**
     * Function to fetch the needs for giving page from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getNeeds = async () => {
      setIsPageLoading(true);
      try {
        const response = await postData(
          "/api/need/getNeedsByType",
          {
            organizationId: givingPage?.organizationId,
            type: givingPage?.needType,
            page,
          },
          null
        );
        const updatedNeeds = transformProfilePicture(response.needs);
        setNeeds(updatedNeeds);
        if (response.count != null) setTotalRecords(response.count);
        setIsPageLoading(false);
        setIsMounted(true);
      } catch (error) {
        setIsMounted(true);
        setIsPageLoading(false);
      }
    };
    if (code && Object.keys(givingPage).length > 0) getNeeds();
  }, [code, page, givingPage]);

  /**
   * Function to handle pagination change.
   * @param {number} pageNumber - The new page number.
   * @param {number} pageSize - The new page size.
   */
  const handlePageChange = (pageNumber, pageSize) => {
    setNeeds([]);
    setPage({
      PageNumber: pageNumber,
      PageSize: pageSize,
    });
  };

  return (
    <Container>
      {location.key !== "default" && !isWhiteLabel && (
        <Row>
          <Col xs={12} className="text-end">
            <Link
              to={-1}
              className="text-decoration-none fw-semibold"
              title="Back"
            >
              <i className="flaticon-back pe-1 fs-5"></i>
              Back to Prior Page
            </Link>
          </Col>
        </Row>
      )}
      {needs.length > 0 && (
        <Fragment>
          <Need
            needs={needs}
            givingPage={givingPage}
            isWhiteLabel={isWhiteLabel}
            isPreview={false}
          />
          <Pagination
            totalRecords={totalRecords}
            page={page}
            onPageChange={handlePageChange}
          />
        </Fragment>
      )}
      {isMounted &&
        Object.keys(givingPage).length > 0 &&
        needs.length === 0 && (
          <Row className="justify-content-center text-center">
            <Col xs={10} sm={12} md={9} lg={7} xl={6} xxl={5}>
              <Card className="border-0 shadow p-4 rounded-4 text-center">
                <Card.Body>
                  <Image src={noData} alt="No Data" fluid />
                  <h4 className="title mt-3">Giving Page</h4>
                  <p className="mb-0 fw-light text-dark-emphasis">
                    At the moment, there are no needs associated with this
                    giving page. If you believe you've reached this page in
                    error, please contact the person who sent you the link.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      {isMounted && Object.keys(givingPage).length === 0 && (
        <Container>
          <Row className="justify-content-center text-center">
            <Col xs={10} sm={12} md={9} lg={7} xl={6} xxl={5}>
              <Card className="border-0 shadow p-4 rounded-4 text-center">
                <Card.Body>
                  <Image src={noData} alt="Invalid Profile" fluid />
                  <h4 className="title mt-3">Invalid Giving Page</h4>
                  <p className="mb-0 fw-light text-dark-emphasis">
                    It looks like the giving page you are trying to visit is
                    temporarily unavailable. If you believe you've reached this
                    page in error, please contact the person who sent you the
                    link.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="light">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
export default OrgGivingPage;
