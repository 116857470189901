const initialState = {
  variant: "",
  message: "",
  timeoutId: null,
};

/**
 * Function to clear the notification
 * @param {Object} state - The current state of the notification
 * @param {Object} action - The action dispatched
 * @returns {number|null} Returns a timeout ID if a new timeout is set, otherwise null
 */
const clearNotification = (state, action) => {
  if (state.timeoutId) clearTimeout(state.timeoutId);

  if (action.payload.timeout > 0) {
    const timeoutId = setTimeout(() => {
      action.dispatch({ type: "CLEAR_NOTIFICATION" });
    }, action.payload.timeout);
    return timeoutId;
  }
  return null;
};

/**
 * Reducer function for managing notification state
 * @param {Object} state - The current state
 * @param {Object} action - The action dispatched
 * @returns {Object} Returns the new state
 */
const notify = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_NOTIFICATION":
      const timeoutId = clearNotification(state, action);
      return {
        variant: action.payload.variant,
        message: action.payload.message,
        timeoutId,
      };
    case "CLEAR_NOTIFICATION":
      if (state.timeoutId) clearTimeout(state.timeoutId);
      return {
        variant: "",
        message: "",
        timeoutId: null,
      };
    default:
      return state;
  }
};

export { notify, initialState };
