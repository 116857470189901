import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import { getData } from "../../../services/apiService";

/**
 * Component for displaying donation summary.
 * This component provides an summary of the donation made by donor including total active needs, funded needs, donations
 * and contributions.
 * @param {object} props - The properties passed to the component.
 * @returns {React.Element} - Returns JSX for displaying donation summary.
 * @access Donor
 */
const DonationSummary = (props) => {
  const [report, setReport] = useState({
    totalDonations: 0,
    totalContributions: 0,
    totalActiveNeeds: 0,
    totalFundedNeeds: 0,
  });

  useEffect(() => {
    /**
     * Fetches an summary of the donations made by donor including total active needs, funded needs, donations
     * and contributions from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getDonationSummaryReport = async () => {
      try {
        const response = await getData(
          `/api/dashboard/getDonationSummary`,
          null,
          props.userSession
        );
        if (response) setReport(response);
      } catch (error) {
        // setReport({});
      }
    };
    getDonationSummaryReport();
  }, [props]);

  return (
    <Row>
      <h6 className="fw-semibold mt-3 mb-1">Contribution Summary</h6>
      <p className="mb-2 text-light-emphasis"><small>It allows donors to track their impact and engagement within the Benevolent</small></p>
      <Col xs={12} sm={6} md={4} xxl={3} className="my-2">
        <div className="h-100 d-flex align-items-center justify-content-between p-3 rounded-3 bg-white shadow-sm">
          <div>
            <small className="text-light-emphasis">ACTIVE NEEDS</small>
            <div className="fs-5 fw-semibold mt-1">
              {report.totalActiveNeeds}
            </div>
          </div>
          <div className="ms-2 icon active-needs">
            <i className="flaticon-star fs-1"></i>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={6} md={4} xxl={3} className="my-2">
        <div className="h-100 d-flex align-items-center justify-content-between p-3 rounded-3 bg-white shadow-sm">
          <div>
            <small className="text-light-emphasis">FUNDED NEEDS</small>
            <div className="fs-5 fw-semibold mt-1">
              {report.totalFundedNeeds}
            </div>
          </div>
          <div className="ms-2 icon funded-needs">
            <i className="flaticon-feature fs-1"></i>
          </div>
        </div>
      </Col>

      <Col xs={12} sm={6} md={4} xxl={3} className="my-2">
        <div className="h-100 d-flex align-items-center justify-content-between p-3 rounded-3 bg-white shadow-sm">
          <div>
            <small className="text-light-emphasis">TOTAL DONATIONS</small>
            <div className="fs-5 fw-semibold mt-1">
              ${report.totalDonations}
            </div>
          </div>
          <div className="ms-2 icon raised-amount">
            <i className="flaticon-dollar-circle fs-1"></i>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={6} md={4} xxl={3} className="my-2">
        <div className="h-100 d-flex align-items-center justify-content-between p-3 rounded-3 bg-white shadow-sm">
          <div>
            <small className="text-light-emphasis">TOTAL CONTRIBUTIONS</small>
            <div className="fs-5 fw-semibold mt-1">
              ${report.totalContributions}
            </div>
          </div>
          <div className="ms-2 icon contributions">
            <i className="flaticon-dollar-circle fs-1"></i>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default DonationSummary;
