import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Badge } from "react-bootstrap";

import DataTable from "../../../components/table";

import { postData } from "../../../services/apiService";
import { ValidatorProfileURL } from "../../../constants";

/**
 * Component for managing validators associated with an organization.
 * This component is utilized to showcase validators specifically tailored for organization's private profiles.
 * @param {Object} props - The props object.
 * @param {number} props.organizationId - The ID of the organization whose validators are managed.
 * @returns {JSX.Element} - Returns JSX for managing validators.
 */
const ManageValidators = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState({ PageNumber: 1, PageSize: 25 });
  const [validators, setValidators] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  // Columns configuration for the validator table
  const columns = [
    {
      header: "Name",
      accessorKey: "name",
      cell: (cellProps) => {
        return (
          <Fragment>
            <Link
              to={`${ValidatorProfileURL}${cellProps.row.original.referenceId}`}
              className="text-decoration-none"
              title="Validator Profile"
            >
              {cellProps.row.original.firstName}{" "}
              {cellProps.row.original.lastName}
            </Link>
          </Fragment>
        );
      },
    },
    {
      header: "EmailAddress",
      accessorKey: "emailAddress",
    },
    {
      header: "Phone Number",
      accessorKey: "phoneNumber",
    },
    {
      header: "Suggested By",
      accessorKey: "suggestedBy",
    },
    {
      header: "Created On",
      accessorKey: "createdOn",
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: (cellProps) => {
        return (
          <Fragment>
            {cellProps.row.original.status === "Inactive" && (
              <Badge className="fw-semibold text-dark" bg="warning">
                INACTIVE
              </Badge>
            )}
            {cellProps.row.original.status === "Active" && (
              <Badge className="fw-semibold text-dark" bg="success">
                ACTIVE
              </Badge>
            )}
            {cellProps.row.original.status === "Deleted" && (
              <Badge className="fw-semibold text-dark" bg="danger">
                DELETED
              </Badge>
            )}
          </Fragment>
        );
      },
    },
  ];

  /**
   * Function to handle pagination change.
   * @param {number} pageNumber - The new page number.
   * @param {number} pageSize - The new page size.
   */
  const handlePageChange = (pageNumber, pageSize) => {
    setValidators([]);
    setPage({
      PageNumber: pageNumber,
      PageSize: pageSize,
    });
  };

  /**
   * Function to handle search action.
   * @param {string} value - The search term entered by the user.
   */
  const handleSearch = (value) => {
    handlePageChange(1, page.PageSize);
    setSearchTerm(value);
  };

  useEffect(() => {
    /**
     * Fetches validators based on the organization ID from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getValidators = async () => {
      setIsLoading(true);
      try {
        const response = await postData(`/api/profile/getValidators`, {
          organizationId: props.organizationId,
          status: "All",
          page: page,
          searchTerm: searchTerm,
        });

        setValidators(response.validators);
        if (response.count != null) setTotalRecords(response.count);
        setIsLoading(false);
      } catch (error) {
        setValidators([]);
        setIsLoading(false);
      }
    };
    if (props.organizationId > 0) getValidators();

    // Return a cleanup function
    return () => {
      // Cleanup logic
      // You can add more cleanup actions here if needed
    };
  }, [searchTerm, page, props.organizationId]);

  return (
    <Fragment>
      <div className="text-end mb-4 me-2">
        <Link
          to="/validator/create"
          state={props}
          className="btn btn-secondary"
          title="Create Validator"
        >
          + Add Validator
        </Link>
      </div>
      <Card className="border-0 rounded-3">
        <Card.Body className="px-0">
          <DataTable
            columns={columns}
            data={validators}
            totalRecords={totalRecords}
            page={page}
            onPageChange={handlePageChange}
            searchRecords={handleSearch}
            loadingState={isLoading}
          />
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default ManageValidators;
